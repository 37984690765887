import axios, { AxiosError, AxiosInstance } from "axios";
import { getSession } from "@auth0/nextjs-auth0";
import { AxiosResponse } from "axios";

import { toast } from "react-hot-toast";

import { WEBSOCKET_CONNECTION_TYPES } from "lib/websockets/constants";
import { getAuthToken } from "lib/auth/token";
import {
  ERRORS,
  GLOBALLY_HANDLED_ERRORS,
  SOCKET_ERRORS,
} from "@/constants/errors";

const applyInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => {
      console.log(`${response.config.baseURL}/${response.config.url}`);
      console.log(response.data);

      return response;
    },
    (e) => {
      const response = e?.response;
      const errorCodeFromResponse = response?.data?.error_code as number;

      if (
        errorCodeFromResponse &&
        !Object.hasOwn(ERRORS, errorCodeFromResponse) &&
        !Object.hasOwn(SOCKET_ERRORS, errorCodeFromResponse) &&
        !Object.hasOwn(GLOBALLY_HANDLED_ERRORS, errorCodeFromResponse) &&
        response?.status === 401
      ) {
        toast.error(`You are not authorized or your session has expired`);
        // setTimeout(() => {
        //   if (checkIfClient()) {
        //     window.location.href = Router.logout();
        //   }
        // }, 3000);
      }

      console.log(response?.statusText);

      console.log(`${e?.config.baseURL}${e?.config.url}`);
      console.log(`method:${e?.config.method}`);
      console.log(`responseType:${e?.config.responseType}`);

      console.log(e?.config.headers);

      console.log(e?.data);
      console.log(e?.response?.data);

      return e;
    }
  );
};

export interface DefaultResponseI {
  status: 0;
}

export interface TwilioAccessTokenResponseI extends DefaultResponseI {
  token: string;
  identity: string;
}

export interface CallerProfileI {
  auto_dial: boolean;
}

export interface CallerProfileResponseI extends DefaultResponseI {
  profile: CallerProfileI;
}

export interface StartCallResponseI extends DefaultResponseI {
  call_id: string;
  to_phone_number: string;
}

export enum WSMessageType {
  SUBSCRIBE = "subscribe",
  AUTH = "auth",
  CALL_SETUP = "call_setup",
  END_SESSION = "end_session",
}

export enum WSMessagesSubscribeIdType {
  USER = "user",
}

export interface WSSubscribeMsg {
  user_id: string;
  id_type: WSMessagesSubscribeIdType;
}

export interface WSCallSetupMsg {
  campaign_id: string;
}

export interface WSAuthTokenMsg {
  auth_token: string;
}

export interface WSMessageI {
  msg_type: WSMessageType;
  msg_subscribe?: WSSubscribeMsg;
  msg_call_setup?: WSCallSetupMsg;
  msg_auth_token?: WSAuthTokenMsg;
}

export interface APII {
  Instance: AxiosInstance;

  getWebSocketConnection(
    errorCallback: (error: Event) => any,
    closeCallback: (close: CloseEvent) => any,
    openCallback: (ws: WebSocket) => any,
    msgCallback: (msg: MessageEvent) => any
  ): WebSocket | null;

  /**
   * Used for getting twilio identity access token
   * for Auto Dilaer, Targeted Dialer, Catchup Dialer, Direct Calls
   */
  getTwilioAccessToken(): Promise<
    AxiosResponse<TwilioAccessTokenResponseI, AxiosError>
  >;
  /**
   * Used for getting twilio identity access token
   * for Glibal Dialer (List Dialer)
   */
  getTwilioAccessTokenV3(): Promise<
    AxiosResponse<TwilioAccessTokenResponseI, AxiosError>
  >;
  startCalls(campaignId: string): Promise<AxiosResponse<DefaultResponseI>>;

  setAutoDial(autodial: boolean): Promise<AxiosResponse<DefaultResponseI>>;
  getCallerProfile(): Promise<AxiosResponse<CallerProfileResponseI>>;
  dequeueCaller(campaignId: string): Promise<AxiosResponse<DefaultResponseI>>;
  startDirectCall(
    campaignId: string,
    params: {
      contact_id?: string;
      item_id?: string;
    }
  ): Promise<AxiosResponse<StartCallResponseI>>;
  startLiveTransfer(
    campaignId: string,
    params: {
      call_id: string;
    }
  ): Promise<AxiosResponse<DefaultResponseI>>;
}

const requests = (API: AxiosInstance): APII => ({
  Instance: API,
  getWebSocketConnection(
    errorCallback: (error: Event) => any,
    closeCallback: (close: CloseEvent) => any,
    openCallback: (ws: WebSocket) => any,
    msgCallback: (msg: MessageEvent) => any
  ) {
    if (process.env.NEXT_PUBLIC_SOCKET_DOMAIN) {
      const ws = new WebSocket(WEBSOCKET_CONNECTION_TYPES.DIALER);

      ws.onclose = closeCallback;
      ws.onopen = async () => {
        AuthenticateWS(ws);
        await openCallback(ws);
      };
      ws.onerror = errorCallback;
      ws.onmessage = msgCallback;

      return ws;
    }
    return null;
  },
  /**
   * Used for getting twilio identity access token
   * for Auto Dilaer, Targeted Dialer, Catchup Dialer, Direct Calls
   */
  getTwilioAccessToken: () => API.get(`/v2/calling/token`),
  /**
   * Used for getting twilio identity access token
   * for Glibal Dialer (List Dialer)
   */
  getTwilioAccessTokenV3: () => API.get(`/v3/calling/token`),
  startCalls: (campaignId) =>
    API.post(`/v1/calling/campaign/${campaignId}/start`),
  startDirectCall: (campaignId, params) => {
    return API.post(`/v1/calling/campaign/${campaignId}/call`, params);
  },
  startLiveTransfer: (campaignId, params) => {
    return API.post(`v1/calling/campaign/${campaignId}/live_transfer`, params);
  },

  setAutoDial: (autodial) =>
    API.post(`/v1/calling/autodial`, {
      auto_dial: autodial,
    }),
  getCallerProfile: () => API.get(`v1/calling/profile`),
  dequeueCaller: (campaignId) =>
    API.post(`/v1/calling/campaign/${campaignId}/dequeue`),
});

export const glenSocketServerAPI = ({
  token,
  req,
  res,
}: {
  token?: string;
  req?: any;
  res?: any;
}) => {
  let auth0Token;

  if (token) {
    auth0Token = token;
  } else if (req && res) {
    const auth0Session = getSession(req, res);

    auth0Token = auth0Session?.accessToken;
  }

  const API = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SOCKET_API_DOMAIN,
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 10000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "accept-encoding": "*",
      Authorization: `Bearer ${auth0Token}`,
    },
  });

  applyInterceptors(API);

  return requests(API);
};

export const glenSocketClientAPI = () => {
  const authToken = getAuthToken();

  const API = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SOCKET_API_DOMAIN,
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 10000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  applyInterceptors(API);

  return requests(API);
};

export const SendWSMessage = (message: WSMessageI, ws: WebSocket) => {
  ws.send(JSON.stringify(message));
};

export const CallingService = (ws: WebSocket) => ({
  authenticate: () => {
    const authToken = getAuthToken();

    SendWSMessage(
      {
        msg_type: WSMessageType.AUTH,
        msg_auth_token: {
          auth_token: authToken,
        },
      },
      ws
    );
  },
  setup: (campaignId?: string) => {
    SendWSMessage(
      {
        msg_type: WSMessageType.CALL_SETUP,
        msg_call_setup: {
          campaign_id: campaignId ?? "",
        },
      },
      ws
    );
  },
  endSession: () => {
    SendWSMessage(
      {
        msg_type: WSMessageType.END_SESSION,
      },
      ws
    );
  },
});

export const AuthenticateWS = (ws: WebSocket) => {
  CallingService(ws).authenticate();
};
