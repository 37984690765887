import { useState } from "react";

import { fixUrl } from "shared/lib/helpers";
import { useEffectOnce } from "shared/lib/hooks";
import clsx from "clsx";

import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

import { ConnectionQualitySection } from "@/components/modules/calling/cards/lead-card/v1/connection-quality";
import Speaker from "@/components/modules/calling/cards/lead-card/v1/speaker";

import LeadDescription from "@/components/modules/calling/cards/lead-card/v1/lead-description";

import { LeadCardActionsSection as ActionsSection } from "@/components/modules/calling/cards/lead-card/v1/actions";
import { DialerNextLeadSection as NextLeadSection } from "@/components/modules/calling/cards/lead-card/v1/next-lead/section";
import { LinkedinBadge } from "@/modules/calling/cards/lead-card/v1/linkedin-badge";

const LeadCard = ({
  isCallEnded,
  hideConnection,
  actionsSection: ActionsSectionReplacement,
  hideNextLeadSection,
}: {
  isCallEnded?: boolean;
  hideConnection?: boolean;
  actionsSection?: any;
  hideNextLeadSection?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { contact, account } = useInCallContext();

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <>
      <div
        className={clsx(
          "caller lead-card",
          "w-full max-w-[430px]",
          "transition-[opacity,transform] duration-500",
          {
            "scale-50 opacity-0": !isVisible,
            "scale-100 opacity-100": isVisible,
          }
        )}
      >
        <div className="rounded-lg bg-white p-4 shadow-[0px_4px_60px_rgba(0,0,0,0.1)]">
          <div className="mb-3 flex items-center">
            {!hideConnection && (
              <ConnectionQualitySection isCallEnded={isCallEnded} />
            )}
          </div>

          <div className={clsx("rounded-lg bg-black/5 px-3 py-6")}>
            <div className="mb-8">
              <label className="ae-typography-h2 mb-1 flex items-center">
                <span className="mr-2 flex items-center">
                  {contact?.first_name} {contact?.last_name} <LinkedinBadge />
                </span>
                <span className="relative top-[1px]">
                  {!isCallEnded && <Speaker />}
                </span>
              </label>
              <p className="ae-typography-body3 text-black/60">
                <span>{contact?.title}</span>
                {!!account?.name && (
                  <span>
                    {" "}
                    @{" "}
                    {account?.website ? (
                      <a
                        href={fixUrl(account?.website)}
                        target="_blank"
                        rel="noreferrer"
                        className="ae-link cursor-pointer"
                      >
                        {account?.name}
                      </a>
                    ) : (
                      account?.name
                    )}
                  </span>
                )}
              </p>
            </div>

            <LeadDescription />
          </div>

          {ActionsSectionReplacement ? (
            ActionsSectionReplacement
          ) : (
            <ActionsSection isCallEnded={isCallEnded} />
          )}
        </div>

        {!hideNextLeadSection && <NextLeadSection />}
      </div>
    </>
  );
};

export default LeadCard;
