import {
  CalendarIcon,
  EnvelopeOpenIcon,
  PhoneArrowDownLeftIcon,
  PhoneXMarkIcon,
  StarIcon,
} from "@heroicons/react/24/outline";

export const PIPELINE_SYSTEM_LISTS = {
  CALLBACKS: "callbacks",
  MISSED_CALLS: "missed_calls",
  BOOKED_MEETINGS: "booked_meetings",
  EMAIL_ACTIONS: "email_actions",
  FEEDBACK_RECEIVED: "feedback_received",
} as const;

// Fixed, pre-defined icons only for lists generated/maintained by the system
export const LISTS_ICON_MAP = {
  [PIPELINE_SYSTEM_LISTS.BOOKED_MEETINGS]: <CalendarIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.CALLBACKS]: <PhoneArrowDownLeftIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.EMAIL_ACTIONS]: <EnvelopeOpenIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.FEEDBACK_RECEIVED]: <StarIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.MISSED_CALLS]: <PhoneXMarkIcon className="w-4" />,
};
