export const WEBSOCKET_DIALER_LIST_EVENT_TYPES = {
  DIALER_LIST_SEND_START_DIALING_SESSION: "start_list_dialing_sessions",
  DIALER_LIST_SEND_START_LIST_CALLS: "start_list_calls",

  DIALER_LIST_SEND_KILL_LIST_CALLS: "kill_list_calls",
  DIALER_LIST_SEND_KILL_LIST_CALL: "kill_list_call",
  DIALER_LIST_SEND_IGNORE_LIST_CALL: "ignored_list_call",

  DIALER_LIST_RECEIVE_CALL_STATUS_UPDATE: "list_call_status",
} as const;
