import { CallbackButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/callback-button";
import { BookMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/book-meeting-button";

export const ActionsDuringCall = () => {
  return (
    <div className="grid grid-cols-1 gap-y-2">
      <CallbackButton />
      <BookMeetingButton />
    </div>
  );
};
