import { ShortCampaignI } from "@/api/routes/dialer";

import { LoadingComponent } from "@/components/modules/loading";
import { clsxMerge } from "shared/lib/helpers";
import { Avatar } from "shared/ui";
import { useFetchDataStartCallingModal } from "../../queries";
import { useEffect, useMemo, useState } from "react";

export const useStartCallingModalGeneralDialerSection = () => {
  const queryApi = useFetchDataStartCallingModal();

  const data = useMemo(
    () => queryApi.data?.data?.general_dialing_campaigns,
    [queryApi.data?.data?.general_dialing_campaigns]
  );

  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (data) {
      setSelected(data?.[0].campaign_id);
    }
  }, [data]);

  return {
    isLoading: queryApi?.isLoading,
    data,
    selected,
    setSelected,
  };
};

export const StartCallingModalGeneralDialerSection = ({
  data,
  isLoading,
  selected,
  onChange,
}: {
  data?: ShortCampaignI[];
  isLoading?: boolean;
  selected?: string;
  onChange?: (listId: string) => void;
}) => {
  const handleSeletion = (campaignId: string) => () => {
    onChange?.(campaignId);
  };

  return (
    <div className="flex flex-grow animate-fadein flex-col">
      <div>
        <h6 className="mb-1 typography-body-5-medium">Shared pool</h6>
        <p className="text-[#999999] typography-body-5">
          Work leads from a shared pool with other callers.
        </p>
      </div>

      {isLoading && (
        <div className="flex-grow">
          <LoadingComponent delay={1000} />
        </div>
      )}

      {!isLoading && !data?.length && (
        <div className="mt-3 animate-fadein rounded-[4px] bg-[#F0F0F0] px-3 py-3">
          <p className="typography-body-4-bold">No campaigns available</p>
        </div>
      )}

      {!!data?.length && (
        <div className="mt-4 flex max-h-[250px] flex-grow flex-col gap-y-[2px] overflow-y-auto">
          {data?.map((campaign, i) => (
            <button
              key={i}
              className={clsxMerge(
                "btn rounded-[4px] pl-2",
                "flex w-full flex-nowrap items-center justify-start",
                {
                  "btn-ghost border-[#4474E3] bg-[#E8F3FF]":
                    selected === campaign.campaign_id,
                  "btn-ghost": selected !== campaign.campaign_id,
                }
              )}
              onClick={handleSeletion(campaign.campaign_id)}
            >
              <span className="mr-2">
                <Avatar
                  src={campaign?.campaign_logo_url}
                  placeholderText={campaign?.campaign_name}
                  alt={campaign?.campaign_name}
                  className={clsxMerge(
                    "mr-1 h-4 min-h-[16px] w-4 min-w-[16px]"
                  )}
                />
              </span>
              <span className="flex flex-col items-start">
                <span
                  className={clsxMerge("text-start", {
                    "typography-body-4-bold": selected === campaign.campaign_id,
                    "typography-body-4": selected !== campaign.campaign_id,
                  })}
                >
                  {campaign.campaign_name}
                </span>
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
