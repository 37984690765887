import { useCallback, useEffect, useMemo, useState } from "react";

import { clsxMerge } from "shared/lib/helpers";

// Use this hook to dynamically load an image with a fade-in effect.
export const useDynamicImage = (src: string) => {
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const imageClassName = useMemo(
    () =>
      clsxMerge("duration-400 transition-opacity", {
        "opacity-0": isLoadingImage,
        "opacity-100": isImageLoaded,
      }),
    [isLoadingImage, isImageLoaded]
  );

  const onImageLoad = useCallback(() => {
    setIsLoadingImage(false);
    setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    setIsLoadingImage(true);
    setIsImageLoaded(false);

    let image: HTMLImageElement;

    if (src) {
      image = new Image();
      image.src = src;

      image.addEventListener("load", onImageLoad);
    }

    return () => {
      if (image) {
        image.removeEventListener("load", onImageLoad);
      }
    };
  }, [src]);

  return {
    isLoadingImage,
    isImageLoaded,
    imageClassName,
    onImageLoad,
    src: src,
  };
};
