import { useRef, useCallback, useState, FC } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { TextField } from "shared/ui/ae-user-input";
import {
  AddNextLeadRequestParamsI,
  AddNextLeadResponseI,
  glencocoClientAPI,
} from "@/api/glencoco";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { toast } from "react-hot-toast";
import { CloseIcon } from "shared/ui/icons";
import { CallContactI } from "@/interfaces/dialer/contact";
import { AxiosResponse } from "axios";
import {
  yupPhone,
  yupPhoneCyclicDependencyWorkaround,
} from "shared/lib/helpers/yup";

const EnhancedTextField = TextField(Field);

export interface AddNextLeadFormI extends CallContactI {
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  work_email: string;
}

const AddNextLeadFormValidation = Yup.object().shape(
  {
    first_name: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(64, "Maximum 64 characters")
      .required("First Name is required"),
    last_name: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(64, "Maximum 64 characters")
      .required("Last Name is required"),
    title: Yup.string()
      .required("Please provide a title")
      .min(2, "Minimum 2 characters")
      .max(64, "Maximum 64 characters"),
    phone: yupPhone("phone"),
    work_email: Yup.string()
      .email("Please provide a valid email")
      .min(10, "Email address is too short")
      .max(150, "Email address is too long")
      .required("Please provide a valid email"),
  },
  yupPhoneCyclicDependencyWorkaround("phone")
);

interface AddNextLeadModalI {
  modalId?: string;
}

export const ADD_NEXT_LEAD_MODAL_ID = "add-next-lead-modal";

const AddNextLeadModal: FC<AddNextLeadModalI> = ({
  modalId = ADD_NEXT_LEAD_MODAL_ID,
}) => {
  const [disabled, disable] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const context = useCallingContext();
  const inCallContext = useInCallContext();
  const { leadQueue } = context;
  const callId = context?.call?.customParameters.get("call_id") as string;
  const formikRef = useRef<FormikProps<AddNextLeadFormI>>(null);

  const form: AddNextLeadFormI = {
    first_name: leadQueue[0]?.name?.split(" ")[0] || "",
    last_name: leadQueue[0]?.name?.split(" ")[1] || "",
    title: leadQueue[0]?.title || "",
    phone: leadQueue[0]?.company_phone || "",
    work_email: leadQueue[0]?.company_email || "",
  };

  const handleOnClick = useCallback(() => {
    const node = checkboxref.current;
    if (node) {
      node?.focus();
      node?.click();
    }
  }, [checkboxref]);

  const addNextLead = (
    apiHandler: (
      campaignId: string,
      callId: string,
      params: AddNextLeadRequestParamsI
    ) => Promise<AxiosResponse<AddNextLeadResponseI, any>>,
    data: AddNextLeadFormI,
    errorMsg: string,
    successMsg?: string,
    onSuccess = () => {}
  ) => {
    if (!context.campaignId) {
      toast.error("Invalid campaign");
      disable(false);
      return;
    }

    apiHandler(context.campaignId, callId, {
      first_name: data.first_name,
      last_name: data.last_name,
      title: data.title,
      phone: data.phone,
      email: data.work_email,
    })
      .then((resp) => {
        if (resp?.status === 200) {
          if (successMsg) {
            toast.success(successMsg);
          } else {
            toast.success(
              `${data.first_name} ${data.last_name} will be called next`
            );
          }
          context.setLeadQueue(() => [resp.data.contact]);

          onSuccess();
        } else {
          if ((resp as any)?.response?.data?.error_code === 1037) {
            toast.error(`This lead already exists in our system`);
          } else {
            toast.error(errorMsg);
          }
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error_code === 1037) {
          toast.error(`This lead already exists in our system`);
        } else {
          console.log("e: ", e);
          toast.error("Error updating lead. Please contact Glencoco.");
        }
      });
  };

  const onFormSubmitHandler = (data: AddNextLeadFormI) => {
    disable(true);
    const API = glencocoClientAPI();

    setTimeout(() => {
      if (context.leadQueue.length > 0) {
        // Edit
        addNextLead(
          API.editNextLead,
          data,
          "Error updating lead!",
          "Updates have been saved",
          () => {
            handleOnClick();
          }
        );
      } else {
        // Add
        addNextLead(API.addNextLead, data, "Error adding lead!", "", () => {
          handleOnClick();
        });
      }

      disable(false);
    }, 1000);
  };

  const handleCloseButton = () => {
    if (context.leadQueue.length === 0) {
      formikRef.current?.resetForm();
    }
  };

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) =>
          onToogleHandler((event.target as HTMLInputElement)?.checked)
        }
      />

      <div className="modal">
        {isVisible && (
          <div className="add-next-lead-modal modal-box relative min-w-[520px] p-6">
            <div className="mb-2 flex items-center justify-between">
              <h3 className="ae-typography-h3">Create New Lead</h3>
              <button
                className="btn btn-circle btn-ghost btn-sm"
                onClick={handleCloseButton}
              >
                <label
                  htmlFor={modalId}
                  className="flex h-full w-full cursor-pointer items-center justify-center"
                >
                  <CloseIcon className="h-4 w-4" />
                </label>
              </button>
            </div>

            <p className="ae-typography-body2 mb-6 text-base-content/60">
              The following lead will be called after your current call
              (*indicates required field)
            </p>

            <Formik
              initialValues={form}
              validationSchema={AddNextLeadFormValidation}
              onSubmit={onFormSubmitHandler}
              innerRef={formikRef}
            >
              {({ errors, touched }) => (
                <Form>
                  <EnhancedTextField
                    name="company"
                    placeholder={inCallContext?.account?.name as string}
                    disabled
                  />
                  <EnhancedTextField
                    name="first_name"
                    placeholder="First Name*"
                    errors={errors.first_name}
                    touched={touched.first_name}
                  />
                  <EnhancedTextField
                    name="last_name"
                    placeholder="Last Name*"
                    errors={errors.last_name}
                    touched={touched.last_name}
                  />
                  <EnhancedTextField
                    name="title"
                    placeholder="Title*"
                    errors={errors.title}
                    touched={touched.title}
                  />
                  <EnhancedTextField
                    name="phone"
                    placeholder="Phone*"
                    errors={errors.phone}
                    touched={touched.phone}
                  />
                  <EnhancedTextField
                    name="work_email"
                    placeholder="Work Email*"
                    errors={errors.work_email}
                    touched={touched.work_email}
                  />

                  <div className="mt-8 flex justify-end">
                    <button
                      type={context.leadQueue.length > 0 ? "button" : "reset"}
                      className="btn-ae-text mr-2"
                    >
                      <label
                        htmlFor={modalId}
                        className="flex h-full cursor-pointer items-center px-4 text-black/40"
                      >
                        Cancel
                      </label>
                    </button>

                    <button
                      type="submit"
                      disabled={disabled}
                      className={clsx("btn-ae-default w-[140px]", {
                        loading: disabled,
                      })}
                    >
                      {context.leadQueue.length > 0 ? "Save" : "Add"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default AddNextLeadModal;
