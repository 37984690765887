import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface ControlledDropdownPropsI {
  children: (isDropdownVisible?: boolean) => ReactNode;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

// TODO GLE-4339 - Remove it and use the shared one
export const ControlledDropdown: FC<ControlledDropdownPropsI> = ({
  children,
  className,
  onOpen,
  onClose,
}) => {
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const wrapper = dropdownRef.current as HTMLDivElement;
    const label = dropdownRef?.current?.childNodes?.[0] as ChildNode;
    const content = dropdownRef?.current?.childNodes?.[1] as ChildNode;

    const blur = () => {
      if (wrapper.matches(":focus-within")) {
        setTimeout(() => {
          (document.activeElement as any).blur();
          setIsVisible(false);

          if (onClose) {
            onClose();
          }
        }, 50);
      }
    };

    const open = () => {
      if (!wrapper.matches(":focus-within")) {
        setTimeout(() => {
          setIsVisible(true);
          if (onOpen) {
            onOpen();
          }
        }, 50);
      }
    };

    const mouseDownHandler = () => {
      blur();
      open();
    };

    const handleLabelBlur = (ev: any) => {
      if (!ev.relatedTarget) {
        setIsVisible(false);
        if (onClose) {
          onClose();
        }
      }
    };

    const handleContentBlur = () => {
      setIsVisible(false);
      if (onClose) {
        onClose();
      }
    };

    label.addEventListener("mousedown", mouseDownHandler);
    label.addEventListener("touchstart", mouseDownHandler);
    label.addEventListener("blur", handleLabelBlur);
    content.addEventListener("blur", handleContentBlur);

    return () => {
      label.removeEventListener("mousedown", mouseDownHandler);
      label.removeEventListener("touchstart", mouseDownHandler);
      label.removeEventListener("blur", handleLabelBlur);
      content.removeEventListener("blur", handleContentBlur);
    };
  }, [dropdownRef]);

  return (
    <>
      <div
        ref={dropdownRef}
        className={clsxMerge("dropdown", className, {
          "dropdown-top": !className?.includes("dropdown-"),
        })}
      >
        {children(isVisible)}
      </div>
    </>
  );
};
