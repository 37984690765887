import { FC, useMemo } from "react";
import dayjs from "dayjs";
import { ClockIcon } from "@heroicons/react/24/outline";

import { clsxMerge } from "shared/lib/helpers";

interface AccountSummarySubStatusPropsI {
  className?: string;
  missedCallContactName?: string | null;
  missedCallTime?: string | null;
}

export const AccountSummarySubStatus: FC<AccountSummarySubStatusPropsI> = ({
  className,
  missedCallContactName,
  missedCallTime,
}) => {
  const missedCallData = useMemo(
    () =>
      missedCallTime && missedCallContactName
        ? {
            missedCallContactName,
            missedCallTime,
          }
        : null,
    [missedCallContactName, missedCallTime]
  );

  if (!missedCallData) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "flex items-center gap-1.5 text-[#CA3C27]",
        className
      )}
    >
      <ClockIcon className="w-4" />

      <span className="typography-body-5-medium">
        You have missed call from {missedCallData?.missedCallContactName}{" "}
        {dayjs(missedCallData?.missedCallTime).fromNow()}
      </span>
    </div>
  );
};
