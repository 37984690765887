import { Cookies } from "shared/lib/helpers";
import { alert } from "shared/ui";
import { useEffectOnce } from "shared/lib/hooks";

export const CookieMessageNotifier = () => {
  useEffectOnce(() => {
    const cookies = Cookies();

    const messages = cookies.getOnLaunchMessages();

    messages.reverse().forEach((m) => {
      switch (m.type) {
        case "success":
          alert({
            type: "success",
            title: m.text || "Success",
            description: m.description,
          });
          break;

        case "error":
          alert({
            type: "error",
            title: m.text || "Error",
            description: m.description,
          });
          break;

        case "warning":
          alert({
            type: "warning",
            title: m.text || "Warning",
            description: m.description,
          });
          break;

        default:
          alert({
            type: "info",
            title: m.text || "Info",
            description: m.description,
          });
      }
    });

    cookies.setOnLaunchMessages([]);
  });

  return null;
};
