import { createIcon } from "./icon-factory";

const GaugeIcon = createIcon(
  <path
    fill="currentColor"
    d="M6.418 18.667a.43.43 0 0 0 .222-.062c1.55-.932 3.746-1.467 6.026-1.467s4.477.535 6.028 1.467c.182.11.416.07.554-.093a8.872 8.872 0 0 0 2.085-5.719C21.333 7.945 17.446 4 12.667 4 7.889 4 4 7.945 4 12.793c0 2.096.741 4.127 2.086 5.718a.433.433 0 0 0 .332.156Zm1.78-2.687-.996.587a.436.436 0 0 1-.597-.16.447.447 0 0 1 .158-.607l.996-.587a.433.433 0 0 1 .598.16c.12.212.05.484-.159.607Zm10.688.393a.435.435 0 0 1-.596.164l-.999-.582a.447.447 0 0 1-.161-.605.434.434 0 0 1 .596-.164l1 .582c.208.122.28.392.16.605Zm.802-3.58a.44.44 0 0 1-.437.443H18.1a.44.44 0 0 1-.437-.443.44.44 0 0 1 .437-.444h1.152c.242.001.437.2.437.444Zm-2.535-3.117.997-.587a.433.433 0 0 1 .598.16.447.447 0 0 1-.159.606l-.996.588a.431.431 0 0 1-.598-.16.447.447 0 0 1 .158-.607Zm-2.14-1.828.57-1.017a.434.434 0 0 1 .597-.165c.21.121.282.392.163.604l-.572 1.017a.435.435 0 0 1-.596.165.447.447 0 0 1-.163-.604ZM12.23 6.11a.44.44 0 0 1 .437-.443.44.44 0 0 1 .437.443v1.17a.44.44 0 0 1-.437.443.44.44 0 0 1-.437-.443v-1.17Zm-.642 5.769c.502-.455 4.203-2.247 4.36-2.323a.16.16 0 0 1 .19.04.166.166 0 0 1 .016.195c-.092.15-2.263 3.68-2.764 4.134a1.34 1.34 0 0 1-1.91-.11 1.388 1.388 0 0 1 .108-1.936Zm-2.33-5.195a.433.433 0 0 1 .597.162l.576 1.013c.121.212.05.483-.16.605a.435.435 0 0 1-.596-.162L9.1 7.29a.446.446 0 0 1 .16-.605ZM6.706 9.283a.435.435 0 0 1 .597-.164l1 .583c.208.122.281.393.16.604a.436.436 0 0 1-.595.164l-1-.582a.446.446 0 0 1-.162-.605Zm-1.06 3.51a.44.44 0 0 1 .437-.444h1.152c.241 0 .437.2.437.444a.44.44 0 0 1-.437.443H6.082a.44.44 0 0 1-.437-.443Z"
  />
);

export default GaugeIcon;
