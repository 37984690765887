import { useEffect } from "react";

import { useDisclosure } from "./use-disclosure";
import { modalHelpers } from "../helpers/modalHelpers";

export const useLazyModal = (modalId: string) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => modalHelpers.open(modalId), 0);
    } else {
      modalHelpers.close(modalId);
    }
  }, [isOpen, modalId]);

  return {
    isOpen,
    onOpen,
    onClose,
  };
};
