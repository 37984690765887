import {
  ACCOUNT_DISQUALIFY_DISPOSITIONS,
  CONTACT_DISQUALIFY_DISPOSITIONS,
  DISQUALIFY_DISPOSITION_LABELS,
  DISQUALIFY_DISPOSITIONS,
} from "shared/lib/constants/dispositions";

const createDropdownOption = (value: string) => ({
  label:
    DISQUALIFY_DISPOSITION_LABELS[
      value as keyof typeof DISQUALIFY_DISPOSITIONS
    ],
  value,
});

export const ACCOUNT_DQ_DISPOSITIONS = Object.values(
  ACCOUNT_DISQUALIFY_DISPOSITIONS
);

export const CONTACT_DQ_DISPOSITIONS = Object.values(
  CONTACT_DISQUALIFY_DISPOSITIONS
);

export const ACCOUNT_DQ_DISPOSITIONS_OPTIONS =
  ACCOUNT_DQ_DISPOSITIONS.map(createDropdownOption);

export const CONTACT_DQ_DISPOSITIONS_OPTIONS =
  CONTACT_DQ_DISPOSITIONS.map(createDropdownOption);
