import { FC, ReactNode, useEffect, useState } from "react";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { clsxMerge } from "shared/lib/helpers";

interface AILoaderPropsI {
  title?: string;
  className?: string;
  actions?: ReactNode;
  lengthyLoadingDelay?: number;
  onLengthyLoading?: () => void;
}

/**
 * @param array
 * @returns the origional array but with all of the elements shifted to the right by one index
 */
const shiftRightByOne = (array: number[]) => {
  if (!array || array.length <= 1) {
    return array;
  }

  const lastElement = array.pop(); // Get the last element
  return [lastElement, ...array]; // Return array with the last element first and the rest following
};

/**
 * @param title title at top of loader
 * @param className Use a custom height and width classname to adjust the size of the component
 * @param actions Provide a React element with buttons to give user alternative actions (like closing the loader)
 * @param lengthyLoadingDelay milliseconds after which we execute the onLenghtyLoading callback. Default 5000ms.
 * @param onLengthyLoading a function to run if the AI is taking too long
 * @returns A UI loading component (typically for AI computational tasks)
 */
export const AILoader: FC<AILoaderPropsI> = ({
  title = "AI building...",
  className,
  actions,
  lengthyLoadingDelay = 5000,
  onLengthyLoading = () => {},
}) => {
  const [shufflingBars, setShufflingBars] = useState<any[]>([1, 2, 3]);

  useEffect(() => {
    const animationIntervalId = setInterval(() => {
      setShufflingBars((prevBars) => shiftRightByOne(prevBars));
    }, 700);

    const lengthyLoadingTimeoutId = setTimeout(
      () => onLengthyLoading(),
      lengthyLoadingDelay
    );

    return () => {
      clearInterval(animationIntervalId);
      clearTimeout(lengthyLoadingTimeoutId);
    };
  }, [lengthyLoadingDelay]);

  return (
    <div
      className={clsxMerge(
        "flex h-[260px] w-[266px] flex-col typography-header-6",
        className
      )}
    >
      <div className="relative mb-3 flex justify-center">
        <span className="gradient-text relative">
          <SparklesIcon className="absolute -left-4 -top-4 w-6 text-[#714CC9]" />
          {title}
        </span>
      </div>

      <div className="flex flex-1 flex-col">
        {shufflingBars.map((v, i) => (
          <div
            key={i}
            className={clsxMerge(
              "h-full rounded-2xl bg-white transition-[transform,opacity] duration-500 ease-in",
              {
                "scale-x-75 scale-y-50 opacity-30": v !== 1,
              }
            )}
          />
        ))}
      </div>

      <div className="mt-7">{actions}</div>
    </div>
  );
};
