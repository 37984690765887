import dayjs from "dayjs";

import { PerformanceFilterI } from "@/modules/performance/dashboard/interfaces";
import { PERFORMANCE_DASHBOARD_TIME_PERIODS } from "@/modules/performance/dashboard/constants";

export const preparePerformanceFiltersForAPICall = ({
  selectedCampaign,
  timePeriod,
}: PerformanceFilterI) => {
  const startDateConfigMap = {
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.TODAY]: () => dayjs().startOf("day"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.YESTERDAY]: () =>
      dayjs().subtract(1, "day").startOf("day"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.THIS_WEEK]: () =>
      dayjs().startOf("week"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_WEEK]: () =>
      dayjs().subtract(1, "week").startOf("week"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.THIS_MONTH]: () =>
      dayjs().startOf("month"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_MONTH]: () =>
      dayjs().subtract(1, "month").startOf("month"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_30_DAYS]: () =>
      dayjs().subtract(30, "day").startOf("day"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_3_MONTHS]: () =>
      dayjs().subtract(3, "month").startOf("month"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.YEAR_TO_DATE]: () =>
      dayjs().startOf("year"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LIFETIME]: () =>
      dayjs().subtract(3, "year"),
  };

  return {
    campaignId: selectedCampaign?.id,
    startDate: Object.hasOwn(startDateConfigMap, timePeriod)
      ? startDateConfigMap[timePeriod]().toISOString()
      : dayjs().startOf("day").toISOString(),
    endDate:
      timePeriod === PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_MONTH
        ? dayjs().subtract(1, "month").endOf("month").toISOString()
        : undefined,
  };
};
