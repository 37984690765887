import { MouseEventHandler, ReactElement } from "react";
import { clsxMerge } from "shared/lib/helpers";

export interface NotificationBaseGenericCompositionLayoutI {
  avatarReact?: ReactElement;
  descriptionReact?: ReactElement;
  messageReact?: ReactElement;
  actionsReact?: ReactElement;
}

export interface NotificationBaseGenericI {
  isUnread?: boolean;
  className?: string;
  children?: any;

  onClick?: MouseEventHandler<HTMLElement>;
}

export interface NotificationGenericI
  extends NotificationBaseGenericCompositionLayoutI,
    NotificationBaseGenericI {}

/**
 * NOTE
 * The reason to have props as a react components
 * is to ensure ability of extending with ease.
 * The content of notifications should be any
 * react object but base behavior should remain
 * the same
 */
export const NotificationBaseCompositionLayout = ({
  avatarReact,
  descriptionReact,
  messageReact,
  actionsReact,
}: NotificationBaseGenericCompositionLayoutI) => {
  return (
    <div className="flex">
      <div className="mr-4">{avatarReact}</div>
      <div>
        <div>{descriptionReact}</div>
        {messageReact && <div className="mt-2">{messageReact}</div>}
        {actionsReact && <div className="mt-3">{actionsReact}</div>}
      </div>
    </div>
  );
};

export const NotificationBase = ({
  isUnread,
  className,
  children,

  onClick,
}: NotificationBaseGenericI) => {
  return (
    <div
      className={clsxMerge(
        "w-full p-6",
        "flex items-center justify-between",
        "transition-color duration-300",
        "cursor-pointer",
        {
          "hover:bg-black/4": !isUnread,
          "bg-[#E4ECFF] hover:bg-[#C7D5F6]": isUnread,
        },
        className
      )}
      onClick={onClick}
    >
      <div>{children}</div>

      <div>
        {isUnread && (
          <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <circle r="8" cx="8" cy="8" fill="#F88167" />
          </svg>
        )}
      </div>
    </div>
  );
};

export const NotificationGeneric = (props: NotificationGenericI) => (
  <NotificationBase {...props}>
    <NotificationBaseCompositionLayout {...props} />
  </NotificationBase>
);
