import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { useEffect, useState } from "react";

export const useWSNotifications = () => {
  const [isNewNotifications, setIsNewNotifications] = useState(false);

  const eventHandlerNewNotifications = () => {
    setIsNewNotifications(true);
  };

  const clearNotifications = (): void => {
    setIsNewNotifications(false);
  };

  useEffect(() => {
    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.NOTIFICATIONS.NEW,
      eventHandlerNewNotifications
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.NOTIFICATIONS.NEW,
        eventHandlerNewNotifications
      );
    };
  }, []);

  return { isNewNotifications, clearNotifications };
};
