import { useEffect } from "react";

export declare function useLongPollingDeclaration(
  asyncCallback: () => Promise<void>,
  intervalMs: number,
  dependenciesArray?: Array<any>
): void;

type useLongPollingI = (
  asyncCallback: (intervalId: number) => void,
  intervalMs: number,
  dependenciesArray?: Array<any>
) => void;

export const useLongPolling: useLongPollingI = (
  asyncCallback = async () => {},
  intervalMs = 1000,
  dependenciesArray = []
) => {
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      void asyncCallback(intervalId);
    }, intervalMs);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesArray);
};
