import { FC } from "react";
import { UserIcon } from "@heroicons/react/24/outline";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";

interface HistoryCardCallerInfoPropsI {
  callerName: string;
}

export const HistoryCardCallerInfo: FC<HistoryCardCallerInfoPropsI> = ({
  callerName,
}) => (
  <HistoryCardTextInfoItem
    icon={<UserIcon className="h-6 w-6" />}
    subtitle="Caller"
    info={[
      {
        title: callerName,
      },
    ]}
  />
);
