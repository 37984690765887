import { clsxMerge } from "shared/lib/helpers";
import { Dropdown } from "shared/ui";
import { DropdownOptionI } from "shared/ui/user-input/dropdown";
import { Field } from "formik";
import {
  PROSPECT_DISPOSITION_TYPES,
  PROSPECT_DISPOSITION_TYPES_MAP,
} from "shared/lib/constants/dispositions";

const EnhancedDropdown = Dropdown(Field);

const PROSPECT_DISPOSITION_TYPES_DROPDOWN_OPTIONS: DropdownOptionI[] =
  Object.values(PROSPECT_DISPOSITION_TYPES).map((key) => ({
    label: PROSPECT_DISPOSITION_TYPES_MAP[key],
    value: key,
  }));

export const DispositionDecisionMakerRow = ({
  name,
  className,
  errors,
  touched,
}: {
  name: string;
  errors?: string;
  touched?: boolean;
  className?: string;
}) => {
  return (
    <div className={clsxMerge("w-full animate-fadein", className)}>
      <div
        className={clsxMerge(
          "flex w-full flex-wrap items-center gap-x-10",
          className
        )}
      >
        <div className="mb-2 min-w-[316px]">
          <label className="typography-body-4">
            Which best describes the prospect?
          </label>
        </div>
        <div className="flex-grow">
          <EnhancedDropdown
            name={name}
            placeholder="Select"
            options={PROSPECT_DISPOSITION_TYPES_DROPDOWN_OPTIONS}
            errors={errors}
            touched={touched}
            className="relative mb-0 w-full max-w-[240px]"
            labelContentClassName="brand-typography-body2"
            inputClassName="text-black"
            errorsClassName="absolute -bottom-[22px] whitespace-nowrap"
          />
        </div>
      </div>
    </div>
  );
};
