import { ActivityI } from "@/interfaces/dialer/activity";
import { CallContactI } from "@/interfaces/dialer";
import { FC, RefObject, useRef } from "react";
import { PhoneRingingIcon } from "shared/ui/icons";
import Activity from "./activity";
import { useEffectOnce } from "shared/lib/hooks";
import { ACCOUNT_ACTIVITY_TYPES_WITHOUT_CONTACT } from "shared/lib/constants/account";

interface AccountActivityPropsI {
  activityGroups: ActivityI[];
  activeContact: CallContactI;
  setAccountActivityScrollRef: (el: RefObject<HTMLDivElement>) => void;
}

const AccountActivity: FC<AccountActivityPropsI> = ({
  activityGroups,
  activeContact,
  setAccountActivityScrollRef,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }, 100);
  };

  useEffectOnce(() => {
    setAccountActivityScrollRef(scrollContainerRef);
    scrollToBottom();
  });

  return (
    <div
      ref={scrollContainerRef}
      className="absolute left-full h-full w-full overflow-y-auto scroll-smooth px-4"
    >
      <div className="flex min-h-full flex-col justify-end">
        {activityGroups && activityGroups.length > 0 && (
          <div className="flex flex-col gap-4">
            {activityGroups.map((activity, i) => (
              <div key={i} className="w-full rounded-lg bg-white/80 py-4">
                {!ACCOUNT_ACTIVITY_TYPES_WITHOUT_CONTACT.includes(
                  activity.activity_type
                ) && (
                  <>
                    <div className="px-4">
                      <div className="flex items-center justify-between">
                        <p className="ae-typography-body1 mb-1">
                          {`${activity?.contact?.first_name} ${activity?.contact?.last_name}`}
                        </p>

                        {activeContact?.id === activity?.contact?.id && (
                          <PhoneRingingIcon className="h-4 w-4" />
                        )}
                      </div>
                      <p className="ae-typography-detail1 text-black/40">
                        {activity?.contact?.title}
                      </p>
                    </div>

                    <div className="divider my-3" />
                  </>
                )}

                <div className="flex flex-col gap-8 px-4">
                  <Activity activity={activity} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountActivity;
