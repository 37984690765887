import React, { FC } from "react";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_STATES } from "@/constants/dialer";

import {
  BackgroundBlur,
  SVGBorderElement,
} from "@/modules/calling/control-menu/v1/shared";

import { DialingWarningBlock } from "@/modules/calling/dialing-state/dialing-warning-block";

import { MuteButton } from "./buttons/mute-button";
import { DialpadButton } from "./buttons/dialpad-button";
import { ConfigButton } from "./buttons/config-button";
import { CallButton } from "./buttons/call-button";

import { CreateLeadLink } from "./create-lead-link";
import { clsxMerge } from "shared/lib/helpers";

interface ControlMenuI {
  className?: string;
  hideExtras?: boolean;
  buttonClassName?: string;
  buttonsClassNames?: {
    configButton?: { dropdownClassName?: string };
  };
}

/**
 *
 * @param hideExtras - boolean props that responsible for preserving old behvaiour
 *                     and encorporates abiltiy to hide all components that surround
 *                     control panel:
 *                     Create New Lead & Text Warning during call & 8 second hold
 */
const ControlMenu: FC<ControlMenuI> = ({
  className,
  hideExtras,
  buttonClassName,
  buttonsClassNames,
}) => {
  const callContext = useCallingContext();

  return (
    <div className={className}>
      {!hideExtras && <CreateLeadLink />}

      <div className={"relative w-fit"}>
        <BackgroundBlur />
        <SVGBorderElement />

        <div
          className={clsxMerge(
            "relative",
            "px-[2px] py-[1px]",
            "h-[70px] transition-[width,border-radius] delay-100 duration-500"
          )}
        >
          {!hideExtras &&
            DIALER_STATES.DIALING === callContext.callingState && (
              <DialingWarningBlock className="absolute top-[-160px] w-full animate-fadein" />
            )}

          <div
            className={clsxMerge(
              "flex justify-between",
              "h-full opacity-100 transition-opacity delay-500 duration-1000",
              "px-1"
            )}
          >
            <div className="relative flex items-center gap-2">
              <MuteButton className={buttonClassName} />
              <DialpadButton className={buttonClassName} />
              <ConfigButton
                className={buttonClassName}
                dropdownClassName={
                  buttonsClassNames?.configButton?.dropdownClassName
                }
              />
              <CallButton className={buttonClassName} />
            </div>
          </div>
        </div>

        {!hideExtras && callContext.callingState === DIALER_STATES.CALL && (
          <span
            className={clsxMerge(
              "ae-typography-detail1",
              "absolute bottom-[-32px] left-[-80px] w-[516px]",
              "animate-fadein",
              "font-normal text-white opacity-90"
            )}
          >
            We do an 8 second hold before ending a call to comply with partner &
            FCC requirements.
          </span>
        )}
      </div>
    </div>
  );
};

export default ControlMenu;
