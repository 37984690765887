export const ASSESSMENT_STATUS_TYPES = {
  BLOCKED: "blocked",
  IN_PROGRESS: "in_progress",
  PENDING: "pending",
  RETRY: "retry",
  SUCCESS: "success",
} as const;

export const ASSESSMENT_STATUS_TYPES_MAP = {
  [ASSESSMENT_STATUS_TYPES.BLOCKED]: "Not Eligible",
  [ASSESSMENT_STATUS_TYPES.IN_PROGRESS]: "In Progress",
  [ASSESSMENT_STATUS_TYPES.PENDING]: "Pending",
  [ASSESSMENT_STATUS_TYPES.RETRY]: "Retry",
  [ASSESSMENT_STATUS_TYPES.SUCCESS]: "Approved",
};

export const ASSESSMENT_ANSWER_TYPES = {
  DEFAULT: "default",
  INTRO: "intro",
  ROLEPLAY: "roleplay",
};
