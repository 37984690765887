import { LeadGeneralInfo } from "@/modules/calling/cards/lead-card/v2/lead-general-info";
import { CurrentCallerLabel } from "./current-caller-label";
import { clsxMerge } from "shared/lib/helpers";

const LeadCardWidget = ({ className }: { className?: string }) => {
  return (
    <div className={clsxMerge("w-full", className)}>
      <LeadGeneralInfo
        hideMetadata
        className="w-full"
        avatarClassName="h-[100px] scale-50"
        connectionLabelClassName="top-[65px] scale-90"
        primaryContactInfoClassName="items-center text-center"
      />
      <CurrentCallerLabel className="absolute left-1/2 top-3 -translate-x-1/2" />
    </div>
  );
};

export default LeadCardWidget;
