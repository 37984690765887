// Formats hours to hours and minutes readable string like 5.3333 -> 5hrs 20mins.
import {
  convertTimeToNormalizedDuration,
  TimeDurationsI,
} from "shared/lib/helpers/date";

export const formatDurationToHoursAndMinutesString = (
  params: TimeDurationsI
) => {
  const { hours, minutes } = convertTimeToNormalizedDuration(params);

  let resultingString = "";

  if (hours > 0) {
    resultingString += `${hours}hrs `;
  }

  if (minutes > 0) {
    resultingString += `${minutes}mins`;
  }

  return resultingString ? resultingString.trim() : "0hrs";
};
