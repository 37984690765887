import { PerformanceCardI } from "./interface";
import PerformanceCardV1 from "./variations/v1";
import PerformanceCardV2 from "./variations/v2";

export const PERFORMANCE_CARD_TYPES = {
  DEFAULT: "default",
  V1: "v1",
  V2: "v2",
} as const;

const PerformanceCard = ({
  type,
  ...props
}: PerformanceCardI & {
  type?: (typeof PERFORMANCE_CARD_TYPES)[keyof typeof PERFORMANCE_CARD_TYPES];
}) => {
  const { DEFAULT, V1, V2 } = PERFORMANCE_CARD_TYPES;

  return (
    <>
      {(!type || [DEFAULT as string, V1].includes(type)) && (
        <PerformanceCardV1 {...props} />
      )}

      {V2 === type && <PerformanceCardV2 {...props} />}
    </>
  );
};

export default PerformanceCard;
