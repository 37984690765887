import { DIALER_MODES } from "@/constants/dialer";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge } from "shared/lib/helpers";

import { BGs } from "./backgrounds";

export const DialerWidgetContainer = ({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) => {
  const { mode, callingState } = useCallingContext();

  return (
    <div className={clsxMerge(className)}>
      <div
        className={clsxMerge(
          "rounded-xl",
          "relative",
          "flex flex-col",
          "h-full",
          "transition-[background] duration-1000",

          BGs[mode || DIALER_MODES.DEFAULT][callingState as string],
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
