import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import clsx from "clsx";
import { InputHTMLAttributes } from "react";

export interface TextFieldI {
  name: string;
  label?: string;
  placeholder: string;
  className?: string;
  errors?: string;
  touched?: boolean;
  disabled?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputClassName?: string;
  labelClassName?: string;
  labelContentClassName?: string;
  isErrorsAbsolutePosition?: boolean;
}

const DefaultInput = (props: any) => <input {...props} />;

const TextField =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    label,
    placeholder,
    errors,
    touched,
    className,
    disabled,
    inputClassName,
    inputProps = {},
    isErrorsAbsolutePosition,
    labelClassName,
    labelContentClassName,
  }: TextFieldI) => {
    return (
      <div className={twMerge(clsx("form-control mb-4 w-full", className))}>
        {!!label && (
          <label className={twMerge(clsx("label", labelClassName))}>
            <span
              className={twMerge(
                clsx("label-text font-bold", labelContentClassName)
              )}
            >
              {label}
            </span>
          </label>
        )}

        <Field
          name={name}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          className={clsx(
            `input input-bordered grow`,
            `transition-all duration-300`,
            `outline-none`,
            {
              "border-error-content": errors && touched,
            },
            inputClassName
          )}
          {...inputProps}
        />

        {errors && touched && (
          <div className="relative">
            <div
              className={clsx("mt-1 flex items-center text-xs", {
                absolute: isErrorsAbsolutePosition,
              })}
            >
              <ExclamationCircleIcon className="text-error-content mr-1 h-5 w-5 bg-none" />
              <div className="text-error-content">{errors}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

export default TextField;
