import { createIcon } from "./icon-factory";

const SalesRepIcon = createIcon(
  <path
    fill="currentColor"
    d="M2.803 17.669a15.667 15.667 0 0 0-.044 3.756c.103.704.728 1.231 1.473 1.241h15.582c.741-.009 1.36-.54 1.447-1.243a21.94 21.94 0 0 0-.017-3.754c-.22-3.279-4.735-3.967-4.735-3.967-1.153-.197-1.624-1.141-1.81-1.753a8.362 8.362 0 0 0 1.85-3.364c.133-.457.2-.928.202-1.401-.001-.264-.027-.527-.077-.786.576-.92.407-1.946.27-2.44a.348.348 0 0 0-.36-.223l-.051.003c-.065.006-.13.008-.194.008-1.031 0-2.735-.653-2.735-.653a5.313 5.313 0 0 0-1.9-.423v-.003h-.186v.001c-1.22.032-2.36.588-3.1 1.513-1.04.332-1.215 1.608-1.154 2.725a3.906 3.906 0 0 0-.011.278 7.637 7.637 0 0 0 1.914 4.877c.035.038.07.073.105.109-.227.597-.712 1.358-1.734 1.533 0 0-4.515.688-4.735 3.966Zm10.124 2.541-.783.57a.13.13 0 0 1-.111 0l-.783-.57a.21.21 0 0 1-.065-.165l.72-3.316h.209l.869 3.335a.185.185 0 0 1-.057.146h.001Zm-.387-5.73h-.001a.765.765 0 0 0-.14.218h-.796a.765.765 0 0 0-.138-.218l-1.871-1.49c.074-.123.141-.25.2-.381.621.437 1.372.676 2.144.683a3.335 3.335 0 0 0 2.243-.849h.001c.072.18.157.356.257.524l-1.9 1.513Zm.09 1.244-.218.38h-.789l-.237-.4.094-.147c.06-.07.107-.149.139-.234h.766a.764.764 0 0 0 .138.234l.108.167Zm2.124-2.193.24.253.057.06.072.045.21.13-1.617 2.206-.69-1.07a.222.222 0 0 1-.014-.238l1.742-1.386ZM8.163 8.88c.032-.193.09-.38.172-.56.166-.355.443-.653.793-.851.098-.06.743-.453.926-.453a.06.06 0 0 1 .038.011c.867.793 1.927 1.014 2.836 1.014a6.355 6.355 0 0 0 2.027-.341 4.001 4.001 0 0 0 1.135-.64c.002.04.006.081.006.123-.001.418-.06.833-.178 1.236-.439 1.526-1.285 2.867-2.321 3.68a2.664 2.664 0 0 1-1.659.566 3.11 3.11 0 0 1-2.279-1.017 7.026 7.026 0 0 1-1.496-2.768Zm.718 5.008.072-.044.057-.061.24-.253 1.742 1.386a.222.222 0 0 1-.014.238l-.691 1.07L8.67 14.02l.21-.13Z"
  />
);

export default SalesRepIcon;
