import { ComponentProps, forwardRef } from "react";

type SvgProps = ComponentProps<"svg">;

const DotsHorizontalIcon = (props: SvgProps, svgRef: any) => (
  <svg
    className="h-6 w-6"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
  </svg>
);

const ForwardedDotsHorizontalIcon = forwardRef(DotsHorizontalIcon);

export default ForwardedDotsHorizontalIcon;
