import { useMemo } from "react";
import { CampaignCardLabelValueI } from "shared/ui/cards/campaign-card/campaign-card-default";

export const CampaignCardStats = ({
  stats,
  hideBasicStats,
}: {
  stats?: Array<CampaignCardLabelValueI>;
  hideBasicStats?: boolean;
}) => {
  const accentStats = useMemo(() => stats?.filter((s) => s.accent), [stats]);
  const basicStats = useMemo(() => stats?.filter((s) => !s.accent), [stats]);

  return (
    <div className="flex gap-2">
      <div className="flex gap-8 rounded-md bg-[#F2F5FC] p-3">
        {accentStats?.map((stat, i) => (
          <span key={i} className="ae-typography-body2">
            <p className="ae-typography-body3 mb-2">{stat.label}</p>
            <p>{stat.value}</p>
          </span>
        ))}
      </div>

      {!hideBasicStats && (
        <div className="flex gap-8 p-3">
          {basicStats?.map((stat, i) => (
            <span key={i} className="ae-typography-body2">
              <p className="mb-2">{stat.label}</p>
              <p>{stat.value}</p>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
