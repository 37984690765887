import { clsxMerge } from "shared/lib/helpers";

import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";

import { DisqualifyButton } from "./buttons/disqualify-button";
import { EmailButton } from "./buttons/email-button";
import { ListButton } from "./buttons/list-button";
import { MeetingButton } from "./buttons/meeting-button";
import { CallButton } from "./buttons/call-button";
import { MuteButton } from "./buttons/mute-button";
import { DialpadButton } from "./buttons/dialpad-button";
import { ConfigButton } from "./buttons/config-button";

export const AccountDetailsControlMenu = () => {
  const callStatus = useDialerCallStatus();

  return (
    <div
      className={clsxMerge(
        "w-max",
        "fixed bottom-5 left-1/2 -translate-x-1/2",
        "rounded-xl bg-black/10 p-3 backdrop-blur-sm",
        "flex items-center gap-x-2"
      )}
    >
      {callStatus === DIALER_CALL_STATUS.BEFORE && <DisqualifyButton />}

      <EmailButton />
      <ListButton />
      <MeetingButton />

      {callStatus === DIALER_CALL_STATUS.DURING && (
        <>
          <MuteButton />
          <DialpadButton />
          <ConfigButton />
        </>
      )}

      <CallButton />
    </div>
  );
};
