import { useCallback, useEffect, useReducer, useRef } from "react";
import { toast } from "react-hot-toast";
import type { AxiosResponse } from "axios";

interface UseApiPropsI<DataI, ApiI> {
  showToastOnError?: boolean;
  showToastOnSuccess?: boolean;
  apiFactory: () => ApiI;
  fetcher:
    | ((api: ApiI, ...args: any[]) => Promise<AxiosResponse<DataI>>)
    | null;
  errorBuilder?: (response: AxiosResponse<DataI>) => string;
  successBuilder?: (response: AxiosResponse<DataI>) => string;

  /*
   * This flag set to `false` allows to not call the API automatically on mount
   * and further updates of fetcher, but rather call it manually with the
   * loadData function.
   */
  shouldCallAutomatically?: boolean;
  shouldResetDataOnRequest?: boolean;
  shouldResetDataOnFailure?: boolean;
  shouldFailOnError?: boolean;
  isInitiallyLoading?: boolean;
  onSuccess?: (data: DataI, ...args: any[]) => void;
  onError?: (error: string, ...args: any[]) => void;
}

export interface StateI<DataI> {
  isLoading: boolean;

  /*
   * This flag is used to determine if the data has been fetched at least once.
   * May be super useful for showing a loading spinner on the first load.
   */
  isInitialized: boolean;

  data: DataI | null;
  error: string | null;
}

enum ActionType {
  Request = "request",
  Success = "success",
  Failure = "failure",
}

interface ActionMetaI {
  resetData: boolean;
}

type ActionI<PayloadI> =
  | { type: ActionType.Request; meta: ActionMetaI }
  | { type: ActionType.Success; payload: PayloadI }
  | { type: ActionType.Failure; error: string; meta: ActionMetaI };

const dataLoadingReducer = <DataI>(
  state: StateI<DataI>,
  action: ActionI<DataI>
): StateI<DataI> => {
  switch (action.type) {
    case ActionType.Request:
      return {
        data: action.meta.resetData ? null : state.data,
        error: null,
        isLoading: true,
        isInitialized: state.isInitialized,
      };
    case ActionType.Success:
      return {
        isLoading: false,
        data: action.payload,
        error: null,
        isInitialized: true,
      };
    case ActionType.Failure:
      return {
        isLoading: false,
        data: action.meta.resetData ? null : state.data,
        error: action.error,
        isInitialized: true,
      };
    default:
      throw Error("An unknown action has been dispatched.");
  }
};

const getInitialState = (isInitiallyLoading?: boolean) => ({
  isLoading: isInitiallyLoading !== undefined ? isInitiallyLoading : false,
  isInitialized: false,
  data: null,
  error: null,
});

export const useApi = <DataI, ApiI>({
  showToastOnError = true,
  showToastOnSuccess = false,
  apiFactory,

  /*
   * This is extremely important for fetcher to be memoized and change when it's
   * argument change, otherwise fetch will happen every time fetcher is recreated
   * in case when shouldCallAutomatically is set to true.
   */
  fetcher,

  errorBuilder = (response) => response.statusText,
  successBuilder,
  shouldCallAutomatically = true,
  shouldResetDataOnRequest = true,
  shouldResetDataOnFailure = true,
  shouldFailOnError = true,
  isInitiallyLoading = false,
  onSuccess,
  onError,
}: UseApiPropsI<DataI, ApiI>) => {
  const API = useRef(apiFactory());
  const [state, dispatch] = useReducer(
    dataLoadingReducer<DataI>,
    getInitialState(isInitiallyLoading)
  );

  const internalDataLoader = useCallback(
    async (...args: any[]) => {
      if (!fetcher) {
        return;
      }

      dispatch({
        type: ActionType.Request,
        meta: { resetData: shouldResetDataOnRequest },
      });

      const response = await fetcher(API.current, ...args);

      if (response.status === 200 || !shouldFailOnError) {
        dispatch({
          type: ActionType.Success,
          payload: response.data,
        });

        if (onSuccess) {
          onSuccess(response.data, ...args);
        }

        if (successBuilder && showToastOnSuccess) {
          toast.success(successBuilder(response));
        }

        return Promise.resolve({
          data: response.data,
          args,
        });
      } else {
        const error = errorBuilder(response);

        dispatch({
          type: ActionType.Failure,
          error,
          meta: { resetData: shouldResetDataOnFailure },
        });

        if (onError) {
          onError(error, ...args);
        }

        if (showToastOnError) {
          toast.error(error);
        }

        return Promise.reject({
          error,
          args,
        });
      }
    },
    [fetcher]
  );

  const loadData = useCallback(
    (...args: any[]) => internalDataLoader(...args),
    [internalDataLoader]
  );

  useEffect(() => {
    if (shouldCallAutomatically) {
      void internalDataLoader();
    }
  }, [internalDataLoader]);

  return [state, loadData] as const;
};
