import {
  getScrollElement,
  useOnPageScroll,
} from "@/hooks/homepage-layout/use-on-page-scroll";
import clsx from "clsx";

export const SCROLL_DIRECTIONS = {
  UP: "up",
  DOWN: "down",
};

export const InfiniteScroll = ({
  children,
  isMore,
  isLoading,
  isDefaultMessageHidden,
  isDefaultLoadingComponentHidden,
  onMore = () => {},
  customScrollElementId,
  scrollDirection = SCROLL_DIRECTIONS.DOWN,
}: {
  isLoading?: boolean;
  isMore?: boolean;
  isDefaultLoadingComponentHidden?: boolean;
  isDefaultMessageHidden?: boolean;
  onMore?: () => void;
  customScrollElementId?: string;
  scrollDirection?: (typeof SCROLL_DIRECTIONS)[keyof typeof SCROLL_DIRECTIONS];
  children?: any;
}) => {
  const handleInfiniteScroll = () => {
    const scrollEl = getScrollElement(customScrollElementId);

    if (isLoading || !isMore || !scrollEl) {
      return;
    }

    switch (scrollDirection) {
      case SCROLL_DIRECTIONS.DOWN:
        if (
          scrollEl?.scrollTop + scrollEl.clientHeight >
          scrollEl.scrollHeight - 10
        ) {
          onMore();
        }
        break;

      case SCROLL_DIRECTIONS.UP:
        if (scrollEl.scrollTop < 10) {
          onMore();
        }
        break;

      default:
        break;
    }
  };

  useOnPageScroll(handleInfiniteScroll, [], customScrollElementId);

  return (
    <>
      {SCROLL_DIRECTIONS.UP === scrollDirection &&
        !isDefaultLoadingComponentHidden && (
          <InfiniteScrollLoadingComponent
            isMore={isMore}
            isLoading={isLoading}
            isDefaultMessageHidden={isDefaultMessageHidden}
          />
        )}

      {children}

      {SCROLL_DIRECTIONS.DOWN === scrollDirection &&
        !isDefaultLoadingComponentHidden && (
          <InfiniteScrollLoadingComponent
            isMore={isMore}
            isLoading={isLoading}
            isDefaultMessageHidden={isDefaultMessageHidden}
          />
        )}
    </>
  );
};

const InfiniteScrollLoadingComponent = ({
  isMore,
  isLoading,
  isDefaultMessageHidden,
}: {
  isLoading?: boolean;
  isMore?: boolean;
  isDefaultMessageHidden?: boolean;
}) => {
  return (
    <div className="flex w-full justify-center">
      {typeof isMore === "boolean" && !isMore && !isDefaultMessageHidden && (
        <p className="ae-typography-detail1 py-6 text-black/40">
          You've reached the bottom, it&apos;s only up from here!
        </p>
      )}

      {isLoading && (
        <button
          className={clsx(
            "btn btn-link cursor-default bg-transparent text-[#0F35FF]",
            {
              loading: isLoading,
            }
          )}
        />
      )}
    </div>
  );
};
