import { useEffect, useRef, useState } from "react";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { CampaignI, QualificationCriteriaI } from "@/interfaces/campaign";

import { toast } from "react-hot-toast";

import clsx from "clsx";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { MeetingBookedCardContent } from "./meeting-booked-card";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";

export const ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID =
  "account-submit-booked-meeting-modal";

const SubmitBookedMeetingModal = ({
  campaign,
  calendlyEvent,
  calendlyUri,
  qualificationCriterias,
  selectedQualificationCriteria,

  notes: _notes = "",
  modalId = ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID,

  hideCloseAction,

  onSubmit = () => {},
}: {
  calendlyUri: string;
  campaign: CampaignI;
  calendlyEvent: CalendlyEventI;
  qualificationCriterias: Array<QualificationCriteriaI>;
  selectedQualificationCriteria: Array<SelectedQualificationCriteriaQuestionI>;

  notes?: string;
  modalId?: string;

  hideCloseAction?: boolean;

  onSubmit?: (notes: string) => void;
}) => {
  const { modalAccount } = useAccountsContext();

  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState(_notes);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setNotes(_notes);
  }, [_notes]);

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!modalAccount?.accountId || !campaign.id) {
      toast.error("Invalid Id.");
      setIsLoading(false);
      return;
    }

    onSubmit(notes);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) => {
          onToogleHandler((event.target as HTMLInputElement)?.checked);
        }}
      />

      <div className="modal">
        {isVisible && calendlyUri ? (
          <div className="modal-box relative w-[520px] rounded-md p-6">
            {!hideCloseAction && (
              <label
                htmlFor={modalId}
                className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 text-black/40"
              >
                ✕
              </label>
            )}

            <div
              className={clsx(
                "max-h-[calc(100vh-2vh)] overflow-y-auto scroll-smooth"
              )}
            >
              <MeetingBookedCardContent
                isWrapperNoStyles
                isVisible={isVisible}
                calendlyEvent={calendlyEvent}
                qualificationCriterias={qualificationCriterias}
                selectedQualificationCriteria={selectedQualificationCriteria}
                notes={notes}
                loading={isLoading}
                onNotesUpdate={setNotes}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SubmitBookedMeetingModal;
