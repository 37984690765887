export const ACCOUNT_LIST_TYPES = {
  CUSTOM: "custom",
  EXCLUSIVE: "top_caller_dedicated",
  BOOKED_MEETINGS: "booked_meetings",
  FEEDBACK_RECEIVED: "feedback_received",
  CALLBACKS: "callbacks",
  MISSED_CALLS: "missed_calls",
  EMAIL_ACTIONS: "email_actions",
  TODO_LIST: "todo_list",
};

// System lists, which are considered nurture lists (former Callbacks and missed calls).
export const SYSTEM_NURTURE_LIST_TYPES = [
  ACCOUNT_LIST_TYPES.CALLBACKS,
  ACCOUNT_LIST_TYPES.MISSED_CALLS,
];
