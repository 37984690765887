import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface NavButtonWrapperI {
  isActive?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  btnClassName?: string;
  children?: any;
}

/**
 *
 * @returns a wrapper that applies common styling for the nav bar buttons
 */
export const NavButtonWrapper: FC<NavButtonWrapperI> = ({
  isActive,
  disabled,
  containerClassName,
  btnClassName,
  children,
}) => {
  return (
    <div
      className={clsxMerge(
        "relative flex h-full items-center",
        containerClassName
      )}
    >
      <div
        className={clsxMerge(
          "btn btn-ghost relative",
          "group p-0",
          "brand-typography-h6 text-white",
          "cursor-pointer",
          "rounded-md border-[1px] border-[transparent]",
          "hover:border-[#F88167]/50 hover:bg-[#F88167]/20",
          {
            "border-[#F88167]/50 bg-[#F88167]/10": isActive,
            "pointer-events-none": disabled,
          },
          btnClassName
        )}
      >
        {children}
      </div>

      {isActive && (
        <div className="absolute bottom-0 left-0 h-1 w-full rounded-full bg-[#F88167]" />
      )}
    </div>
  );
};
