import { SendEmailButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/send-email-button";
import { CallbackButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/callback-button";
import { BookMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/book-meeting-button";
import { MarkDoneButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/mark-done-button";
import { clsxMerge } from "shared/lib/helpers";

export const ActionsAfterCall = () => {
  return (
    <div className={clsxMerge("grid grid-cols-2 gap-x-1 gap-y-2")}>
      <SendEmailButton />
      <CallbackButton />

      <MarkDoneButton />
      <BookMeetingButton />
    </div>
  );
};
