import { AnchorHTMLAttributes, FC } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import { InfoRecordI } from "shared/ui/label-value-list";

interface LabelValuePropsI {
  info?: InfoRecordI;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
}

export const VARIATIONS = {
  LINK: "link",
  LINK_HIGHLIGHT: "link-highlight",
} as const;

const Value = ({
  className,
  children,
  variation,
  ...linkProps
}: {
  className?: string;
  children: any;
  variation?: (typeof VARIATIONS)[keyof typeof VARIATIONS];
} & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <div
      className={twMerge(
        clsx("inline-block rounded", className, {
          "ae-typography-detail1": !className?.includes("typography"),
          "text-base-content": !variation,
          "bg-[#0F35FF]/10 px-2 py-1 text-[#0F35FF]":
            variation === VARIATIONS.LINK_HIGHLIGHT,
          "text-[#0F35FF]": variation === VARIATIONS.LINK,
        })
      )}
    >
      {linkProps?.href ? <a {...linkProps}>{children}</a> : children}
    </div>
  );
};

const LabelValue: FC<LabelValuePropsI> = ({
  info,
  className,
  labelClassName,
  valueClassName,
}) => (
  <div className={clsx("leading-tight", className)}>
    {info?.label && (
      <p
        className={twMerge(
          clsx("ae-typography-detail1 mb-1 text-black/60", labelClassName)
        )}
      >
        {info?.label}
      </p>
    )}

    <Value
      variation={info?.variation}
      {...(info?.config || {})}
      className={valueClassName}
    >
      <div className="flex items-center">
        {info?.icon}
        {info?.value}
      </div>
    </Value>
  </div>
);

export default LabelValue;
