import clsx from "clsx";

export const CampaignCardTrainingPill = ({
  steps_complete,
  total_steps,
}: {
  steps_complete?: number;
  total_steps?: number;
}) => {
  const percentComplete =
    steps_complete && total_steps ? (steps_complete / total_steps) * 100 : 0;

  return (
    <div
      className={clsx(
        "badge ae-typography-detail1",
        "rounded-[4px] border-none",
        "px-3",
        "bg-black/80 text-[white]",
        "relative overflow-hidden"
      )}
    >
      <div className="z-[1]">
        <span className="mr-3">Training</span>
        <span>{`${steps_complete}/${total_steps}`}</span>
      </div>

      <div
        className="absolute left-0 h-full min-w-1 bg-[#F88167]"
        style={{ width: `${percentComplete}%` }}
      />
    </div>
  );
};
