import { FC, useMemo, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { useOnClickOutside } from "shared/lib/hooks/use-on-click-outside";
import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { ListPillI } from "@/api/routes/list";
import { clsxMerge } from "shared/lib/helpers";
import { ListSelectorMenu } from "./menu";
import {
  getListContactCountString,
  getListIcon,
} from "@/modules/pipeline/lists/list/activities/selector/utils";
import { getListName } from "@/components/modules/pipeline/utils";

interface ListsSelectorPropsI {
  className?: string;
  menuClassName?: string;

  items: ListPillI[] | undefined;
  activeListId: string | undefined | null;
  activeList: ListPillI | undefined;
  setActiveListId: (newActiveListId: string) => void;
}

export const ListsSelector: FC<ListsSelectorPropsI> = ({
  className,
  menuClassName,

  items,
  activeListId,
  activeList,
  setActiveListId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  const listPickerContainer = useRef<HTMLDivElement>(null);

  useOnClickOutside(listPickerContainer, onClose);

  const listName = useMemo(() => getListName(activeList), [activeList]);

  if (!activeList) {
    return null;
  }

  return (
    <div
      ref={listPickerContainer}
      className={clsxMerge("relative z-50 w-max", className)}
    >
      <div
        role="button"
        onClick={onOpen}
        className={clsxMerge(
          "flex w-max cursor-pointer gap-1.5 p-2 transition-colors",
          "rounded border-[2px] border-[#4474E3] bg-[#E8F3FF] hover:bg-[#dce8f5]"
        )}
      >
        <div className="text-[#F88167]">
          {getListIcon({
            list_type: activeList?.list_type,
            isExclusive: activeList?.is_exclusive_list,
          })}
        </div>

        <div className="flex w-[220px] flex-col gap-1.5">
          <span
            title={activeList?.name}
            className={clsxMerge(
              "line-clamp-2 typography-body-4-bold",
              activeList?.is_exclusive_list && "gradient-text"
            )}
          >
            {listName}
          </span>

          <span className="text-black typography-body-5-medium">
            {getListContactCountString(activeList)}
          </span>
        </div>

        <div>
          <ChevronDownIcon className="h-4 w-4" />
        </div>
      </div>

      {isOpen && (
        <ListSelectorMenu
          className={clsxMerge(menuClassName, "mt-1.5")}
          activeListId={activeListId}
          setActiveListId={setActiveListId}
          items={items}
          onClose={onClose}
        />
      )}
    </div>
  );
};
export { PIPELINE_SYSTEM_LISTS } from "@/modules/pipeline/lists/list/activities/selector/constants";
