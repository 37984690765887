import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export const CampaignCardDescription = ({
  text,
  className,
  closedContainerHeight = 50,
}: {
  text?: string;
  className?: string;
  closedContainerHeight?: number;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const triggerIsExpanded = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const boxHeight = textRef.current?.getBoundingClientRect().height;

    if (boxHeight) {
      setContentHeight(boxHeight);
    }
  }, [text]);

  return (
    <div>
      <div
        style={{
          maxHeight: isExpanded ? contentHeight : `${closedContainerHeight}px`,
        }}
        className={clsx(
          "relative",
          "b-typography-body3 overflow-hidden whitespace-pre-wrap",
          "transition-[max-height] ease-in-out",
          className
        )}
      >
        <p ref={textRef}>{text}</p>

        {contentHeight > closedContainerHeight && !isExpanded && (
          <span className="absolute bottom-0 h-[40px] w-full bg-gradient-to-b from-transparent to-[white]" />
        )}
      </div>

      {contentHeight > closedContainerHeight && (
        <div>
          <button
            className={twMerge(
              "b-typography-detail3 ae-link btn-md relative -left-4 -top-2 text-[12px]"
            )}
            onClick={triggerIsExpanded}
          >
            {isExpanded ? "SHOW LESS" : "SHOW MORE"}
          </button>
        </div>
      )}
    </div>
  );
};
