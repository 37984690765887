import { useMemo } from "react";
import _find from "lodash/find";

import { PhoneIcon } from "shared/ui/icons";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";

import {
  fixUrl,
  formatNumbers,
  formatUSPhoneNumber,
  safeGetDate,
} from "shared/lib/helpers";
import {
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/solid";
import LogoLinkedinIcon from "shared/ui/icons/logo-linkedin-icon";
import { useGlobalContext } from "@/hooks/use-global-context";
import Router from "@/helpers/router";
import {
  LeadDescriptionContactItem,
  LeadDescriptionInfo,
  LeadDescriptionInfoItemLarge,
  LeadDescriptionInfoItemSmall,
} from "@/modules/calling/cards/lead-card/v2/lead-description/content";
import { usePersistedCatchupsData } from "@/modules/calling/hooks/use-persisted-catchups-data";

const LeadDescription = () => {
  const { campaignId } = useCallingContext();
  const { campaigns } = useGlobalContext();

  const { account = {}, contact = {}, metadata } = usePersistedCatchupsData();

  const campaignName = useMemo(
    () => _find(campaigns, ["id", campaignId])?.name || "N/A",
    [campaignId, campaigns]
  );

  const {
    id: accountId,
    name,
    employee_count,
    industry,
    revenue,
    hq_state,
    hq_city,
    website,
  } = account || {};

  const { lastCalledTime, previousAttempts } = metadata;

  const { phone, mobile, email, linkedin } = contact || {};

  return (
    <div className="flex flex-col gap-4">
      <LeadDescriptionInfo className="w-full" contentClassName="gap-1.5">
        <p className="brand-typography-detail1">Contact Info</p>

        <LeadDescriptionContactItem
          icon={<DevicePhoneMobileIcon className="h-3 w-3" />}
          content={
            mobile ? (formatUSPhoneNumber(mobile) as string) : "No mobile phone"
          }
        />

        <LeadDescriptionContactItem
          icon={<PhoneIcon className="h-3 w-3" />}
          content={phone ? (formatUSPhoneNumber(phone) as string) : "No phone"}
        />

        <LeadDescriptionContactItem
          isLink={!!email}
          url={`mailto:${email}`}
          icon={<AtSymbolIcon className="h-3 w-3" />}
          content={email || "No email"}
        />

        <LeadDescriptionContactItem
          isLink={!!linkedin}
          url={fixUrl(linkedin)}
          icon={<LogoLinkedinIcon className="h-3 w-3" />}
          content={linkedin || "No linkedin"}
        />

        <LeadDescriptionContactItem
          isLink={!!website}
          url={fixUrl(website)}
          icon={<GlobeAltIcon className="h-3 w-3" />}
          content={website || "No website"}
        />
      </LeadDescriptionInfo>

      <div className="flex">
        <LeadDescriptionInfo className="w-1/2" contentClassName="gap-2">
          <LeadDescriptionInfoItemLarge
            title={campaignName}
            subtitle="Campaign"
          />
          {/* TODO Account Details page is depricated use widget instead */}
          <LeadDescriptionInfoItemLarge
            isLink
            url={`${Router.accounts()}?${Router.searchQueryParams.accountDetails(
              campaignId as string,
              accountId as string
            )}`}
            title={name || "N/A"}
            subtitle="Account"
          />

          <LeadDescriptionInfoItemLarge
            title={industry || "N/A"}
            subtitle="Industry"
          />
        </LeadDescriptionInfo>

        <LeadDescriptionInfo className="w-1/2" contentClassName="gap-2">
          <LeadDescriptionInfoItemLarge
            title={revenue ? `$${formatNumbers(revenue)}` : "N/A"}
            subtitle="Revenue"
          />

          <LeadDescriptionInfoItemLarge
            title={hq_state || hq_city || "N/A"}
            subtitle="HQ State"
          />

          <LeadDescriptionInfoItemLarge
            title={`${formatNumbers(employee_count)}`}
            subtitle="Employees"
          />
        </LeadDescriptionInfo>
      </div>

      <div className="flex">
        <LeadDescriptionInfo className="w-1/2">
          <LeadDescriptionInfoItemSmall
            title={
              lastCalledTime
                ? safeGetDate(lastCalledTime, "MM/DD/YY, hh:mm A")
                : "N/A"
            }
            subtitle="Last Called"
          />
        </LeadDescriptionInfo>

        <LeadDescriptionInfo className="w-1/2">
          <LeadDescriptionInfoItemSmall
            title={previousAttempts || "N/A"}
            subtitle="Previous Attempts"
          />
        </LeadDescriptionInfo>
      </div>
    </div>
  );
};

export default LeadDescription;
