import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { glenSocketClientAPI } from "@/api/glen-socket";
import { Device, Call } from "@twilio/voice-sdk";
import CallingHelper, { askPermissionMic } from "@/helpers/calling";

import { toast } from "react-hot-toast";

import { getS3AudioPath } from "shared/lib/helpers";
import { useEffect } from "react";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { asyncGet, asyncWaitFor } from "@/helpers/context";

import { SessionStorage } from "@/helpers/session-storage";
import { TwilioSetupConfigI } from "@/interfaces/dialer/twilio";
import { TWILIO_TOKEN_ENDPOINT_VERSIONS } from "@/constants/twilio";
import { dd } from "@/helpers/datadog";
import { LOG_CATEGORIES } from "@/constants/logs";

/**
 * Make sure to disable the reactStrictMode when testing the call feature.
 * You will register the same device twice and the call context will be confused about the state of the twilio device
 */

/**
 * Sets up the Twilio device for handling calls.
 *
 * This function performs the following steps:
 * 1. Requests microphone permissions.
 * 2. Initializes the API client.
 * 3. Fetches the Twilio access token.
 * 4. If a device already exists, updates its token.
 * 5. If no device exists, creates and configures a new Twilio device with preferred codecs and custom sounds.
 * 6. Sets the new device in the call context.
 * 7. Configures audio devices.
 * 8. Sets the identity in the call context.
 * 9. Adds necessary event listeners to the device.
 */
export const TwilioProvider = ({ campaignId }: { campaignId?: string }) => {
  const dialerGlobalContext = useDialerGlobalContext();
  const callContext = useCallingContext();
  const inCallContext = useInCallContext();

  const { isEnabled: isGlobalDialer, setTwilioIdentity } = dialerGlobalContext;

  const twilioSetup = async (config?: TwilioSetupConfigI) => {
    const SS = new SessionStorage();

    askPermissionMic();

    const API = glenSocketClientAPI();

    /**
     * We need to use identity from the endpoint for each socket message to start dialer list call
     */
    const getTwilioAccessToken =
      config?.tokenEndpointVersion === TWILIO_TOKEN_ENDPOINT_VERSIONS.V3
        ? API.getTwilioAccessTokenV3
        : API.getTwilioAccessToken;

    /**
     * If the device doesn't exist, create it and register it
     **/
    const accessTokenResponse = await getTwilioAccessToken().catch((e) => {
      toast.error(
        `Failed to establish connection to calling network. Please refresh the page.`
      );

      return e;
    });

    if (accessTokenResponse.status !== 200) {
      return;
    }

    if (callContext.device) {
      /**
       * If the device already exists, just update the token
       **/
      callContext.device.updateToken(accessTokenResponse.data.token);
      SS.dialerGlobalTwilioTokenUpdatedAt = new Date().toISOString();

      /**
       * In global dialer we need identity to make calls
       */
      setTwilioIdentity?.(accessTokenResponse.data.identity);

      dd.log(`${LOG_CATEGORIES.TWILIO} - updated twilio identity`, {
        data: {
          accessTokenResponseData: accessTokenResponse.data,
        },
      });

      return;
    }

    /**
     * https://www.twilio.com/docs/voice/sdks/javascript/best-practices#debugging
     *
     * logLevel: 1, // "DEBUG"
     * logLevel: 0, // "TRACE"
     *
     * codecPreferences
     *
     * Set Opus as our preferred codec. Opus generally performs better,
     * requiring less bandwidth and providing better audio quality
     * in restrained network conditions.
     */
    const twDevice = new Device(accessTokenResponse.data.token, {
      enableImprovedSignalingErrorPrecision: true,
      logLevel: 0,
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      sounds: {
        incoming: getS3AudioPath("connected.wav"),
        disconnect: getS3AudioPath("hangup.wav"),
        outgoing: getS3AudioPath("silence.wav"),
      },
    });
    SS.dialerGlobalTwilioTokenUpdatedAt = new Date().toISOString();

    callContext.setDevice(twDevice);
    CallingHelper.setAudioDevices(callContext, twDevice);
    /**
     * Right now we don't identity in any way for v2 endpoint
     */
    callContext.setIdentity(accessTokenResponse.data.identity);
    /**
     * In global dialer we need identity to make calls
     */
    setTwilioIdentity?.(accessTokenResponse.data.identity);

    dd.log(`${LOG_CATEGORIES.TWILIO} - set initial twilio identity`, {
      data: {
        accessTokenResponseData: accessTokenResponse.data,
      },
    });

    CallingHelper.addDeviceListeners(
      {
        context: callContext,
        inCallContext,
        dialerGlobalContext: isGlobalDialer ? dialerGlobalContext : undefined,
      },
      twDevice
    );

    /**
     * Wait until device is set
     */
    await asyncWaitFor(callContext.setDevice, {
      isTruthful: true,
      timeout: 1000,
    });
    await CallingHelper.safeUnregisterDevice(callContext);
    await CallingHelper.safeRegisterDevice(callContext);
  };

  /**
   * NOTE
   *
   * When global dialer is on we want to setup Twilio
   *
   */
  useEffect(() => {
    if (isGlobalDialer) {
      twilioSetup({ tokenEndpointVersion: "v3" });
    }
  }, [isGlobalDialer]);

  /**
   * NOTE
   *
   * On every campaignId change we want to re-initalize twilio setup
   * basically to avoid regression
   */
  useEffect(() => {
    if (!isGlobalDialer) {
      twilioSetup();
    }
  }, [campaignId]);

  useEffect(() => {
    return () => {
      (async () => {
        const twDevice = await asyncGet(callContext.setDevice);
        twDevice?.destroy();

        callContext.setDevice(undefined);
      })();
    };
  }, []);

  return null;
};
