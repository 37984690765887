import { getCookie } from "cookies-next";

import {
  COOKIES_GLCC_ACCESS_TOKEN,
  COOKIES_USE_GLCC_NATIVE_TOKEN,
  COOKIES_GLCC_NATIVE_ACCESS_TOKEN,
} from "@/constants/cookies";

export const getAuthToken = () => {
  const isNativeToken = getCookie(COOKIES_USE_GLCC_NATIVE_TOKEN);
  const nativeAccessToken = getCookie(COOKIES_GLCC_NATIVE_ACCESS_TOKEN);
  const accessToken = getCookie(COOKIES_GLCC_ACCESS_TOKEN);

  return isNativeToken ? `${nativeAccessToken}` || "" : `${accessToken}` || "";
};
