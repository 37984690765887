import { FC, useEffect, useRef } from "react";
import clsx from "clsx";

import { CheckmarkIcon, ChevronDownIcon } from "../../icons";

export interface CustomSelectFieldOptionI {
  label: string;
  bgClassname?: string;
  hoverBgClassname?: string;
}

interface SelectFieldCustom {
  options: Array<CustomSelectFieldOptionI>;
  selectedValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  dropdownClassNames?: string;
}

const SelectFieldCustom: FC<SelectFieldCustom> = ({
  selectedValue = "",
  options,
  placeholder,
  onChange,
  dropdownClassNames,
}) => {
  const dropdownRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!dropdownRef?.current) {
      return;
    }

    const wrapper = dropdownRef.current as HTMLDivElement;
    const label = dropdownRef?.current?.childNodes?.[0] as ChildNode;

    const blur = () => {
      if (wrapper.matches(":focus-within")) {
        setTimeout(() => {
          (document.activeElement as any).blur();
        }, 200);
      }
    };

    label.addEventListener("mousedown", blur);
    label.addEventListener("touchstart", blur);
    label.addEventListener("blur", blur);

    return () => {
      label.removeEventListener("mousedown", blur);
      label.removeEventListener("touchstart", blur);
      label.removeEventListener("blur", blur);
    };
  }, [dropdownRef]);

  const handleSelect = (opt: CustomSelectFieldOptionI) => {
    if (onChange) {
      onChange(opt.label);
    }
  };

  return (
    <>
      <div
        ref={dropdownRef}
        className="dropdown-bottom dropdown w-full rounded-lg border-none"
      >
        <label
          tabIndex={0}
          className="focus-outline-none :focus-border-none flex h-[48px] cursor-pointer items-center justify-between rounded-lg border-none bg-black/5 px-4"
        >
          <div>
            {selectedValue ? (
              <span className="ae-typography-body1">{selectedValue}</span>
            ) : (
              <span className="ae-typography-body1 text-black/40">
                {placeholder}
              </span>
            )}
          </div>
          <ChevronDownIcon className="relative top-[2px] w-3 text-black/40" />
        </label>
        <div
          tabIndex={0}
          className={clsx(
            `dropdown-content flex ${dropdownClassNames} w-full translate-y-[4px] flex-col gap-1 overflow-y-auto rounded-lg bg-white p-2 shadow-md`
          )}
        >
          {options &&
            options.map((opt, i) => (
              <div
                key={i}
                className={clsx(
                  `flex cursor-pointer items-center justify-between rounded-lg p-2 ${opt.hoverBgClassname} ${opt.bgClassname}`
                )}
                onClick={() => handleSelect(opt)}
              >
                <span>{opt.label}</span>
                {selectedValue === opt.label && (
                  <CheckmarkIcon className="h-4 w-4" />
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SelectFieldCustom;
