import clsx from "clsx";
import { FC } from "react";
import {
  EVENT_PILL_STRING_MAP,
  EVENT_PILL_TYPES,
} from "shared/lib/constants/events";

export const EVENT_PILL_CSS_PROPERTIES = {
  [EVENT_PILL_TYPES.OVERDUE_CALLBACK]: {
    color: "text-black/80",
    bg: "bg-[#FFEFEB]",
  },
  [EVENT_PILL_TYPES.COMPLETED_CALLBACK]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [EVENT_PILL_TYPES.UPCOMING_CALLBACK]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [EVENT_PILL_TYPES.ALERT_RETURNED_CALL]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [EVENT_PILL_TYPES.UPCOMING_MEETING]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
  [EVENT_PILL_TYPES.PENDING_FEEDBACK]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [EVENT_PILL_TYPES.NO_SHOW_MEETING]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [EVENT_PILL_TYPES.UNQUALIFIED_MEETING]: {
    color: "text-black/80",
    bg: "bg-[#FFEFEB]",
  },
  [EVENT_PILL_TYPES.QUALIFIED_MEETING]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
  [EVENT_PILL_TYPES.RESCHEDULED_MEETING]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [EVENT_PILL_TYPES.CANCELLED_MEETING]: {
    color: "text-black/40",
    bg: "bg-black/5",
  },
} as const;

interface EventPillI {
  eventType: (typeof EVENT_PILL_TYPES)[keyof typeof EVENT_PILL_TYPES];
  className?: string;
}

const EventPill: FC<EventPillI> = ({ eventType, className }) => {
  return (
    <div
      className={clsx(
        "ae-typography-detail1 w-fit rounded px-2 py-1 text-center",
        EVENT_PILL_CSS_PROPERTIES[eventType]?.bg,
        EVENT_PILL_CSS_PROPERTIES[eventType]?.color,
        className
      )}
    >
      {EVENT_PILL_STRING_MAP[eventType]}
    </div>
  );
};

export default EventPill;
