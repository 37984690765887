import { FC, useCallback, useMemo } from "react";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import _capitalize from "lodash/capitalize";
import toast from "react-hot-toast";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { ContactDetailI } from "@/interfaces/accounts";
import { RadioButton, TextArea } from "shared/ui/ae-user-input";
import { getFullEntityName } from "shared/lib/helpers";
import Dropdown from "shared/ui/user-input/dropdown";
import { useDisqualifyAccountOrContact } from "@/api/routes/account/mutations";
import {
  ACCOUNT_DQ_DISPOSITIONS,
  ACCOUNT_DQ_DISPOSITIONS_OPTIONS,
  CONTACT_DQ_DISPOSITIONS,
  CONTACT_DQ_DISPOSITIONS_OPTIONS,
} from "@/modals/disqualify-modal/constants";
import { DISQUALIFY_DISPOSITIONS } from "shared/lib/constants/dispositions";
import { DisqualifyDispositionI } from "shared/lib/interfaces/dispositions";
import { AccountI } from "shared/lib/interfaces/account";

type DisqualifyModalContentPropsI = {
  campaignId: string;
  account: AccountI;
  contacts: ContactDetailI[] | undefined;
  focusedContactId?: string;
  onSuccess: () => void;
};

interface DisqualifyFormValuesI {
  contactId: string;
  disqualificationDisposition: DisqualifyDispositionI;
  notes: string;
}

const EnhancedRadioButton = RadioButton(Field);
const EnhancedTextarea = TextArea(Field);
const EnhancedDropdown = Dropdown(Field);

export const DISQUALIFY_MODAL_ID = "disqualify-modal-id";

const RADIO_COMMON_CLASS_NAMES = {
  className: "rounded-lg border px-4 py-2 w-full",
  labelClassName: "typography-body-4-medium truncate",
};

const DisqualifyAccountFormValidationSchema = Yup.object().shape({
  notes: Yup.string().required("A note is required."),
  accountContactId: Yup.string(),
  disqualificationDisposition: Yup.string().required(),
});

const DisqualifyModalContent: FC<
  DisqualifyModalContentPropsI & ModalChildrenFnPropsI
> = ({
  handleClose,
  campaignId,
  account,
  contacts: contactsData,
  onSuccess,
  focusedContactId,
}) => {
  const { mutateAsync: disqualifyContactOrAccount, isPending } =
    useDisqualifyAccountOrContact();

  // This calculation is needed to put a contact a first one in the list in case
  // if there are more than 2 contacts, because the list is limited by height,
  // making a focused contact invisible sometimes, which may confuse users.
  const contacts = useMemo(() => {
    if (!contactsData) {
      return [];
    }

    // Don't put a contact first if it's in the first 2 contacts. It'll be visible
    // anyway, OR if there is no focused contact.
    if (
      !focusedContactId ||
      contactsData.length < 3 ||
      [contactsData[0].id, contactsData[1].id].includes(focusedContactId)
    ) {
      return contactsData;
    }

    // This is always set.
    const focusedContact = contactsData.find(
      ({ id }) => id === focusedContactId
    ) as ContactDetailI;

    if (focusedContact) {
      return [
        focusedContact,
        ...contactsData.filter((contact) => contact.id !== focusedContactId),
      ];
    }

    return contactsData.filter((contact) => contact.id !== focusedContactId);
  }, [contactsData, focusedContactId]);

  const initialValues: DisqualifyFormValuesI = {
    contactId: focusedContactId ? focusedContactId : "account",
    disqualificationDisposition:
      DISQUALIFY_DISPOSITIONS.ACCOUNT_NO_VIABLE_CONTACTS,
    notes: "",
  };

  const handleDisqualifyAccount = useCallback(
    async (formValues: DisqualifyFormValuesI) => {
      if (!account?.id) {
        return;
      }

      try {
        const isAccountSelected = formValues.contactId === "account";
        const disqualifiedResourceLabel = isAccountSelected
          ? "account"
          : "lead";
        let disposition = formValues.disqualificationDisposition;

        if (
          isAccountSelected &&
          !ACCOUNT_DQ_DISPOSITIONS.includes(
            formValues.disqualificationDisposition
          )
        ) {
          disposition = ACCOUNT_DQ_DISPOSITIONS[0];
        }

        if (
          !isAccountSelected &&
          !CONTACT_DQ_DISPOSITIONS.includes(
            formValues.disqualificationDisposition
          )
        ) {
          disposition = CONTACT_DQ_DISPOSITIONS[0];
        }

        await disqualifyContactOrAccount(
          {
            ...formValues,
            contactId: isAccountSelected ? undefined : formValues.contactId,
            accountId: account.id,
            disqualificationDisposition: disposition,
            campaignId,
          },
          {
            onSuccess: () => {
              toast.success(
                `${_capitalize(
                  disqualifiedResourceLabel
                )} has been disqualified successfully.`
              );
            },
            onError: () => {
              toast.error(
                `Failed to disqualify ${disqualifiedResourceLabel}. Please contact Glencoco.`
              );
            },
          }
        );

        onSuccess();
        handleClose();
      } catch (error) {
        console.error(error);
      }
    },
    [account, campaignId, disqualifyContactOrAccount, onSuccess, handleClose]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={DisqualifyAccountFormValidationSchema}
      onSubmit={handleDisqualifyAccount}
    >
      {({ values, errors, touched, isValid }) => (
        <Form className="flex flex-col gap-6">
          <section className="flex max-h-[220px] flex-col gap-3 overflow-y-scroll">
            <EnhancedRadioButton
              name="contactId"
              value="account"
              label={`Disqualify entire account - ${account.name}`}
              {...RADIO_COMMON_CLASS_NAMES}
            />

            {contacts?.map((contact) => (
              <EnhancedRadioButton
                key={contact.id}
                {...RADIO_COMMON_CLASS_NAMES}
                name="contactId"
                value={contact.id}
                label={`Disqualify lead - ${getFullEntityName(contact)}`}
              />
            ))}

            {errors.contactId}
          </section>

          <div className="app-gradient h-[1px] w-full" />

          <section className="flex flex-col">
            <EnhancedDropdown
              label="Disqualification reason"
              name="disqualificationDisposition"
              labelClassName="pt-0"
              options={
                values.contactId === "account"
                  ? ACCOUNT_DQ_DISPOSITIONS_OPTIONS
                  : CONTACT_DQ_DISPOSITIONS_OPTIONS
              }
            />

            <EnhancedTextarea
              inputClassName="bg-white border-black/20"
              name="notes"
              placeholder="Type your notes here"
              errors={errors.notes}
              touched={touched.notes}
            />

            <section className="flex w-full justify-end gap-2">
              <button
                type="button"
                className="btn-ae-text"
                onClick={handleClose}
              >
                Cancel
              </button>

              <button
                className={clsx("btn-ae-default w-[140px]", {
                  disabled: isPending || !isValid,
                  loading: isPending,
                })}
              >
                Submit
              </button>
            </section>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export const DisqualifyModal: FC<DisqualifyModalContentPropsI> = (props) => (
  <Modal
    id={DISQUALIFY_MODAL_ID}
    title="Disqualify"
    description="Select your disqualification options"
    modalContainerClassName="w-[520px]"
  >
    {(modalProps) => <DisqualifyModalContent {...props} {...modalProps} />}
  </Modal>
);
