import { useFrequentlyChangingCallingContext } from "@/hooks/dialer/use-dialer-context";
import clsx from "clsx";

const LeadCardSpeaker = () => {
  const callingContext = useFrequentlyChangingCallingContext();

  //From 0 - 10
  //Safelisted tailwind classes
  // Fix colors
  const isTalking = Math.round(callingContext.outputVol * 10 || 0) > 0;
  const randomValues = Array(5)
    .fill(0)
    .map(() => (isTalking ? Math.floor(Math.random() * 10) + 1 : 0));

  return (
    <div className="flex items-center">
      {[0, 1, 2, 3, 4].map((v, i) => (
        <div
          key={i}
          className={clsx(
            "mr-[1px] w-[2px] bg-black last:mr-0 odd:bg-black/50",
            "transition[height] duration-200",
            `h-[${randomValues[i]}px]`
          )}
        />
      ))}
    </div>
  );
};

export default LeadCardSpeaker;
