import * as Yup from "yup";
import "yup-phone";

const VALIDATION_ERROR_MESSAGE =
  "Please provide a valid US or Canadian phone number";

/**
 *
 * @param field_name - Schema field name
 * @returns yup conditional function checking on either US or Canadian phone number
 */
export const yupPhone = (field_name: string) =>
  Yup.string()
    .phone("US", false, VALIDATION_ERROR_MESSAGE)
    .required(VALIDATION_ERROR_MESSAGE)
    .when(field_name, {
      is: false,
      then: (schema) =>
        schema
          .phone("CA", false, VALIDATION_ERROR_MESSAGE)
          .required(VALIDATION_ERROR_MESSAGE),
    });

/**
 * Cyclic dependency workaround https://github.com/jquense/yup/issues/193#issuecomment-1835575391
 * @param field_name
 * @returns [[field_name, field_name]]
 */
export const yupPhoneCyclicDependencyWorkaround = (
  field_name: string
): [string, string][] => [[field_name, field_name]];
