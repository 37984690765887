import { FC } from "react";

import { NOTIFICATION_TYPES } from "@/constants/notifications";

import { NotificationEmailOpened } from "./types/email-opened";
import { NotificationMissedCall } from "./types/missed-call";
import { NotificationCampaignApproved } from "./types/campaign-approved";
import { NotificationCampaignRejected } from "./types/campaign-rejected";
import { NotificationAuditionRetry } from "./types/audition-retry";
import { NotificationMeetingQualified } from "./types/meeting-qualified";
import { NotificationMeetingUnqualified } from "./types/meeting-unqualified";
import { NotificationMeetingRescheduled } from "./types/meeting-rescheduled";
import { NotificationMeetingCanceled } from "./types/meeting-canceled";
import { NotificationMeetingNoShow } from "./types/meeting-no-show";
import { NotificationEmailReceived } from "./types/email-received";
import { NotificationI } from "@/interfaces/notification";
import { ASSESSMENT_STATUS_TYPES } from "@/constants/assessment";
import { DISPOSITIONS } from "shared/lib/constants/dispositions";
import { NotificationExclusiveListReceived } from "@/modules/notifications/notification/types/exclusive-list-received";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { NotificationCustomerForumPost } from "@/modules/notifications/notification/types/customer-forum-post";

type NotificationPropsI = NotificationI & PropsWithClassNameI;

export const Notification: FC<NotificationPropsI> = (props) => {
  const { notification_type, audition_status, meeting_feedback } = props || {};

  switch (notification_type) {
    case NOTIFICATION_TYPES.CUSTOMER_FORUM_POST:
      return <NotificationCustomerForumPost {...props} />;
    case NOTIFICATION_TYPES.EMAIL_OPENED:
      return <NotificationEmailOpened {...props} />;
    case NOTIFICATION_TYPES.EMAIL_RECEIVED:
      return <NotificationEmailReceived {...props} />;
    case NOTIFICATION_TYPES.MISSED_CALL:
      return <NotificationMissedCall {...props} />;
    case NOTIFICATION_TYPES.AUDITION:
      switch (audition_status) {
        case ASSESSMENT_STATUS_TYPES.SUCCESS:
          return <NotificationCampaignApproved {...props} />;
        case ASSESSMENT_STATUS_TYPES.BLOCKED:
          return <NotificationCampaignRejected {...props} />;
        case ASSESSMENT_STATUS_TYPES.RETRY:
          return <NotificationAuditionRetry {...props} />;
      }
      break;
    case NOTIFICATION_TYPES.LIST_RECEIVED:
      return <NotificationExclusiveListReceived {...props} />;
    case NOTIFICATION_TYPES.MEETING:
      switch (meeting_feedback) {
        case DISPOSITIONS.MEETING_QUALIFIED:
          return <NotificationMeetingQualified {...props} />;
        case DISPOSITIONS.MEETING_NOT_QUALIFIED:
          return <NotificationMeetingUnqualified {...props} />;
        case DISPOSITIONS.MEETING_RESCHEDULED:
          return <NotificationMeetingRescheduled {...props} />;
        case DISPOSITIONS.CANCELLED_MEETING:
          return <NotificationMeetingCanceled {...props} />;
        case DISPOSITIONS.MEETING_NO_SHOW:
          return <NotificationMeetingNoShow {...props} />;
      }
      break;
  }

  return null;
};
