export const USA_STATES = [
  { value: "Alabama", label: "Alabama", abbr: "AL" },
  { value: "Alaska", label: "Alaska", abbr: "AK" },
  { value: "Arizona", label: "Arizona", abbr: "AZ" },
  { value: "Arkansas", label: "Arkansas", abbr: "AR" },
  { value: "California", label: "California", abbr: "CA" },
  { value: "Colorado", label: "Colorado", abbr: "CO" },
  { value: "Connecticut", label: "Connecticut", abbr: "CT" },
  { value: "Delaware", label: "Delaware", abbr: "DE" },
  { value: "Florida", label: "Florida", abbr: "FL" },
  { value: "Georgia", label: "Georgia", abbr: "GA" },
  { value: "Hawaii", label: "Hawaii", abbr: "HI" },
  { value: "Idaho", label: "Idaho", abbr: "ID" },
  { value: "Illinois", label: "Illinois", abbr: "IL" },
  { value: "Indiana", label: "Indiana", abbr: "IN" },
  { value: "Iowa", label: "Iowa", abbr: "IA" },
  { value: "Kansas", label: "Kansas", abbr: "KS" },
  { value: "Kentucky", label: "Kentucky", abbr: "KY" },
  { value: "Louisiana", label: "Louisiana", abbr: "LA" },
  { value: "Maine", label: "Maine", abbr: "ME" },
  { value: "Maryland", label: "Maryland", abbr: "MD" },
  { value: "Massachusetts", label: "Massachusetts", abbr: "MA" },
  { value: "Michigan", label: "Michigan", abbr: "MI" },
  { value: "Minnesota", label: "Minnesota", abbr: "MN" },
  { value: "Mississippi", label: "Mississippi", abbr: "MS" },
  { value: "Missouri", label: "Missouri", abbr: "MO" },
  { value: "Montana", label: "Montana", abbr: "MT" },
  { value: "Nebraska", label: "Nebraska", abbr: "NE" },
  { value: "Nevada", label: "Nevada", abbr: "NV" },
  { value: "NewHampshire", label: "New Hampshire", abbr: "NH" },
  { value: "NewJersey", label: "New Jersey", abbr: "NJ" },
  { value: "NewMexico", label: "New Mexico", abbr: "NM" },
  { value: "NewYork", label: "New York", abbr: "NY" },
  { value: "NorthCarolina", label: "North Carolina", abbr: "NC" },
  { value: "NorthDakota", label: "North Dakota", abbr: "ND" },
  { value: "Ohio", label: "Ohio", abbr: "OH" },
  { value: "Oklahoma", label: "Oklahoma", abbr: "OK" },
  { value: "Oregon", label: "Oregon", abbr: "OR" },
  { value: "Pennsylvania", label: "Pennsylvania", abbr: "PA" },
  { value: "RhodeIsland", label: "Rhode Island", abbr: "RI" },
  { value: "SouthCarolina", label: "South Carolina", abbr: "SC" },
  { value: "SouthDakota", label: "South Dakota", abbr: "SD" },
  { value: "Tennessee", label: "Tennessee", abbr: "TN" },
  { value: "Texas", label: "Texas", abbr: "TX" },
  { value: "Utah", label: "Utah", abbr: "UT" },
  { value: "Vermont", label: "Vermont", abbr: "VT" },
  { value: "Virginia", label: "Virginia", abbr: "VA" },
  { value: "Washington", label: "Washington", abbr: "WA" },
  { value: "WestVirginia", label: "West Virginia", abbr: "WV" },
  { value: "Wisconsin", label: "Wisconsin", abbr: "WI" },
  { value: "Wyoming", label: "Wyoming", abbr: "WY" },
];
