import { FC, ReactElement } from "react";
import clsx from "clsx";
import {
  TrendUpIcon,
  ClockOutlineIcon,
  CircleStarIcon,
  PhoneChatIcon,
  PersonasChatIcon,
  ProtectedDataIcon,
} from "shared/ui/icons";
import { formatNumbers } from "shared/lib/helpers/numbers";
import { pluralizeNoun } from "shared/lib/helpers/utils";
import { twMerge } from "tailwind-merge";

interface SalesResumeStatsPropsI {
  className?: string;
  isPublic?: boolean;
  qualifiedMeetings?: number;
  totalCalls?: number;
  showUpRate?: string | number;
  hoursOnCampaign?: number;
}

interface StatsItemPropsI {
  className?: string;
  isPublic?: boolean;
  icon: ReactElement;
  value?: string | number;
  valueClassName?: string;
  label: string;
}

const StatsItem: FC<StatsItemPropsI> = ({
  className,
  isPublic = true,
  icon,
  value,
  valueClassName,
  label,
}) => (
  <div
    className={clsx(
      "flex h-[143px] min-w-[155px] flex-col justify-between rounded-[20px] p-[22px] lg:h-[167px] lg:p-[22px]",
      className
    )}
  >
    {icon}

    <div className="flex flex-col">
      <span
        className={clsx("mt-3 font-semibold", valueClassName, {
          "blur-md": !isPublic,
        })}
      >
        {value ? value : "N/A"}
      </span>
      <span className="text-[12px] font-semibold lg:text-[14px]">{label}</span>
    </div>
  </div>
);

const SalesResumeStats: FC<SalesResumeStatsPropsI> = ({
  className,
  isPublic = true,
  qualifiedMeetings,
  totalCalls,
  showUpRate,
  hoursOnCampaign,
}) => (
  <div
    className={twMerge(
      clsx(
        "inline-block w-[calc(100%+48px)] bg-black px-6 py-[27px] sm:rounded-[20px] sm:max-lg:w-full lg:w-[max-content] lg:px-[23px]",
        className
      )
    )}
  >
    <div className="flex justify-between">
      <span className="text-[18px] font-bold leading-none text-white">
        Sales Performance
      </span>
      <TrendUpIcon color="#fff" width={30} />
    </div>

    <div className="mt-5 grid grid-cols-2 grid-rows-2 gap-4 lg:flex lg:gap-0">
      <StatsItem
        isPublic={isPublic}
        icon={<PersonasChatIcon color="#fff" width={23} />}
        value={formatNumbers(qualifiedMeetings || 0)}
        valueClassName="text-[36px] lg:text-[48px] leading-none"
        label={`Qualified ${pluralizeNoun("Meeting", qualifiedMeetings || 0)}`}
        className="bg-gradient-to-b from-[#EB9B75] to-[#FC95BA] text-white lg:w-[196px]"
      />

      <StatsItem
        isPublic={isPublic}
        icon={<PhoneChatIcon color="#000" width={23} />}
        value={formatNumbers(totalCalls || 0, 3, { notation: "compact" })}
        valueClassName="text-[36px] leading-none lowercase"
        label={`${pluralizeNoun("Call", totalCalls || 0)} Made`}
        className="bg-white text-black lg:ml-6 lg:w-[138px]"
      />

      <StatsItem
        isPublic={isPublic}
        icon={<CircleStarIcon color="#000" width={22} />}
        value={formatNumbers(showUpRate || 0, 2, { style: "percent" })}
        valueClassName="text-[36px] leading-none"
        label="Show Up Rate"
        className="bg-white text-black lg:ml-6 lg:w-[138px]"
      />

      <StatsItem
        isPublic={isPublic}
        icon={<ClockOutlineIcon color="#000" width={22} />}
        value={formatNumbers(hoursOnCampaign || 0, 3, { notation: "compact" })}
        valueClassName="text-[36px] leading-none lowercase"
        label={`${pluralizeNoun("Hour", hoursOnCampaign || 0)} on Campaigns`}
        className="bg-white text-black lg:ml-6 lg:w-[213px]"
      />
    </div>

    <div className="mt-[22px] flex text-[12px] font-medium text-[#999] lg:mt-[16px]">
      <ProtectedDataIcon width={10} className="mr-1 hidden lg:block" />
      Powered by Glencoco sales performance analytics
    </div>
  </div>
);

export default SalesResumeStats;
