import { FC } from "react";

import { CampaignCardActionsHorizontal } from "shared/ui/cards/campaign-card/shared/campaign-card-actions/campaign-card-actions-horizontal";
import { CampaignCardActionsVertical } from "./campaign-card-actions-vertical";
import { CampaignUserStatusI } from "shared/lib/interfaces/campaign";

export interface CampaignCardActionsI {
  campaign_user_status?: CampaignUserStatusI;
  startCallingUrl?: string;
  assessmentUrl?: string;
  salesFloorUrl?: string;
  onClickTraining?: () => Promise<void> | undefined;
  stacked?: boolean;
}

export const CampaignCardActions: FC<CampaignCardActionsI> = ({
  campaign_user_status,
  startCallingUrl,
  assessmentUrl,
  onClickTraining,
  salesFloorUrl,
  stacked = false,
}) => {
  return stacked ? (
    <CampaignCardActionsVertical
      campaignUserStatus={campaign_user_status}
      startCallingUrl={startCallingUrl}
      assessmentUrl={assessmentUrl}
      onClickTraining={onClickTraining}
      salesFloorUrl={salesFloorUrl}
    />
  ) : (
    <CampaignCardActionsHorizontal
      campaignUserStatus={campaign_user_status}
      startCallingUrl={startCallingUrl}
      assessmentUrl={assessmentUrl}
      onClickTraining={onClickTraining}
      salesFloorUrl={salesFloorUrl}
    />
  );
};
