import { FC, useState } from "react";

import { formatNumbers } from "shared/lib/helpers/numbers";
import { clsxMerge } from "shared/lib/helpers/styles";
import { SalesResumeExperienceItemI } from "shared/ui/sales-resume/interfaces/resume";
import CollapsibleText from "shared/ui/collapsible-text";

export interface SalesResumeExperiencePropsI {
  className?: string;
  items: Array<SalesResumeExperienceItemI> | null;
  isInternal?: boolean;
}

export interface SalesResumeCampaignStatsPropsI {
  item: SalesResumeExperienceItemI;
  className?: string;
}

const INITIALLY_VISIBLE_CAMPAIGNS = 4;

const FALLBACK_CAMPAIGN_LOGO_URL =
  "https://glencoco-assets.s3.amazonaws.com/landing/version-2/gcc_no_logo.webp";

const formatValueForStat = (value: number) =>
  value ? formatNumbers(value) : "-";

export const SalesResumeCampaignStats: FC<SalesResumeCampaignStatsPropsI> = ({
  item,
  className,
}) => (
  <div
    className={clsxMerge(
      "grid grid-cols-2 grid-rows-3 gap-4 sm:grid-cols-8",
      "sm:grid-rows-2 sm:gap-x-[38px] sm:gap-y-[15px]",
      className
    )}
  >
    <div className="flex flex-col justify-between border-b pb-3 leading-none sm:col-span-3">
      <p className="text-[12px] font-semibold">Qualified Meetings Booked</p>
      <p className="mt-[3px] text-[24px] font-bold sm:text-[16px]">
        {formatValueForStat(item.qualified_meetings_booked)}
      </p>
    </div>

    <div className="flex flex-col justify-between border-b pb-3 leading-none sm:col-span-2">
      <p className="text-[12px] font-semibold">Calls Made</p>
      <p className="mt-[3px] text-[24px] font-bold sm:text-[16px]">
        {formatValueForStat(item.calls_made)}
      </p>
    </div>

    <div className="flex flex-col justify-between border-b pb-3 leading-none sm:col-span-3">
      <p className="text-[12px] font-semibold">Hours Spent On Campaign</p>
      <p className="mt-[3px] text-[24px] font-bold sm:text-[16px]">
        {formatValueForStat(item.hours_spent_on_campaign)}
      </p>
    </div>

    <div className="flex flex-col justify-between border-b pb-3 leading-none sm:col-span-3 sm:border-none">
      <p className="text-[12px] font-semibold">Average Rating</p>
      <p className="mt-[3px] text-[24px] font-bold sm:text-[16px]">
        {item.average_rating
          ? `${formatNumbers(item.average_rating, 3)}/10`
          : "-"}
      </p>
    </div>

    <div className="flex flex-col justify-between pb-3 leading-none sm:col-span-2">
      <p className="text-[12px] font-semibold">Show Up Rate</p>
      <p className="mt-[3px] text-[24px] font-bold sm:text-[16px]">
        {item.show_up_rate
          ? formatNumbers(item.show_up_rate, 2, {
              style: "percent",
            })
          : "-"}
      </p>
    </div>
  </div>
);

const SalesResumeExperience: FC<SalesResumeExperiencePropsI> = ({
  className,
  items,
  isInternal = true,
}) => {
  const itemsCollection = items || [];

  const [isAllListVisible, setIsAllListVisible] = useState(
    itemsCollection.length <= INITIALLY_VISIBLE_CAMPAIGNS
  );

  const itemsToDisplay = isAllListVisible
    ? itemsCollection
    : itemsCollection.slice(0, INITIALLY_VISIBLE_CAMPAIGNS);

  const onShowAllCampaigns = () => {
    setIsAllListVisible(true);
  };

  if (!isInternal && itemsCollection.length === 0) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "align-center flex flex-col rounded-[20px] bg-white px-6 py-[30px]",
        className
      )}
    >
      <div className="w-full">
        <span className="text-[18px] font-bold leading-none text-black">
          Glencoco Experience
        </span>
      </div>

      <div className="flex w-full flex-col">
        {itemsToDisplay.length === 0 && (
          <span className="mt-2 text-[14px] text-black">
            Add your experience within Glencoco to increase your visibility to
            companies, AE’s and more.
          </span>
        )}
        {itemsToDisplay.map((item, idx) => (
          <div
            key={idx}
            className="flex w-full flex-col items-start border-b pb-[15px] pt-[30px] first:mt-0 last:border-b-0 sm:flex-row"
          >
            <img
              className="w-[50px] rounded-[20px]"
              src={item.campaign_image_url || FALLBACK_CAMPAIGN_LOGO_URL}
              alt={item.campaign_name}
            />

            <div className="mt-[18px] flex w-full flex-col sm:ml-2 sm:mt-0">
              <div className="color-[#333] text-[14px] leading-none">
                <p className="font-semibold">{item.campaign_name}</p>
                <CollapsibleText
                  visibleLines={2}
                  className="mt-[9px] max-w-full leading-[140%]"
                >
                  {item.description}
                </CollapsibleText>
              </div>

              {item.show_campaign_stats && (
                <SalesResumeCampaignStats className="mt-5" item={item} />
              )}
            </div>
          </div>
        ))}
      </div>

      {itemsToDisplay.length > 0 && !isAllListVisible && (
        <div className="flex w-full justify-center">
          <button onClick={onShowAllCampaigns} className="btn-sm-outline-black">
            Show all {itemsCollection.length} Experiences
          </button>
        </div>
      )}
    </div>
  );
};

export default SalesResumeExperience;
