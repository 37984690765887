import Draggable from "react-draggable";

/* NOTE
 * Children's the first div should have a position set as absolute
 * and predefined position on the screen
 */
export const FreeDnD = ({
  handlerName = "dnd-handle",
  disabled,
  children,
  position,
}: {
  handlerName: string;
  disabled?: boolean;
  children: any;
  position?: { x: number; y: number };
}) => {
  return (
    <Draggable
      handle={`.${handlerName}`}
      scale={1}
      bounds="body"
      cancel="button, .control-bar, .dropdown, .cursor-pointer, input, textarea"
      disabled={disabled}
      position={position}
    >
      {children}
    </Draggable>
  );
};
