import { AxiosInstance, AxiosResponse } from "axios";

export interface getGlencocoTokenResponseI {
  token: string;
}

export interface ImpersonateApiI {
  getGlencocoToken: (
    email: string
  ) => Promise<AxiosResponse<getGlencocoTokenResponseI>>;

  checkAdminAccess: () => Promise<AxiosResponse>;
}

export const ImpersonateAPIEndpoints = (
  API: AxiosInstance
): ImpersonateApiI => ({
  getGlencocoToken: (email) => API.post(`/v1/admin/get_token`, { email }),

  checkAdminAccess: () => API.post(`/v1/admin/check_access`),
});
