import { checkIfClient } from "shared/lib/helpers";

export const createKeyValuePair = (key: string, value: string) => ({
  key,
  value,
});

export const triggerModal = (modalId: string) => {
  if (checkIfClient()) {
    (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
  }
};
