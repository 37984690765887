import { GetForumPostsConfigI } from "@/api/glencoco";
import { SALES_FLOOR_ENDPOINT_TYPES } from "@/constants/forum";
import { FORUM_POST_TYPES } from "shared/lib/constants/forum";

export const getEndpointParamPostType = (config?: GetForumPostsConfigI) => {
  const endpoint_type = config?.endpoint_type;

  if (!endpoint_type || SALES_FLOOR_ENDPOINT_TYPES.GENERAL === endpoint_type) {
    return config?.category;
  }

  if (SALES_FLOOR_ENDPOINT_TYPES.CAMPAIGN_SPECIFIC === endpoint_type) {
    return config?.category === FORUM_POST_TYPES.QUALIFIED_MEETIGNS
      ? FORUM_POST_TYPES.QUALIFIED_MEETIGNS
      : FORUM_POST_TYPES.DEFAULT;
  }
};
