import { FC, PropsWithChildren } from "react";
import { toast } from "react-hot-toast";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { clsxMerge } from "shared/lib/helpers/styles";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface CopyToClipboardPropsI extends PropsWithClassNameI, PropsWithChildren {
  textToCopy?: string;
  showCopyIcon?: boolean;
  toastMessage?: string;
}

const TEXT_TO_IGNORE = ["-"];

const CopyToClipboard: FC<CopyToClipboardPropsI> = ({
  textToCopy,
  children,
  className,
  showCopyIcon = false,
  toastMessage,
}) => {
  const handleClick = () => {
    if (textToCopy && !TEXT_TO_IGNORE.includes(textToCopy)) {
      navigator.clipboard.writeText(textToCopy || "").then(() => {
        toast.success(toastMessage || `Copied to clipboard "${textToCopy}"`);
      });
    }
  };

  return (
    <div
      className={clsxMerge(
        "flex w-fit cursor-pointer",
        {
          "items-center gap-2": showCopyIcon,
        },
        className
      )}
      onClick={handleClick}
    >
      {children}
      {showCopyIcon && <DocumentDuplicateIcon className="h-4 w-4 text-black" />}
    </div>
  );
};

export default CopyToClipboard;
