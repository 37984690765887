import { Fragment, useMemo } from "react";
import { useRouter } from "next/router";

import { ChatBubbleEllipsisIcon } from "shared/ui/icons";
import { NavButtonWrapper } from "@/components/primary-navigation/v3/nav-bar/nav-items-row/components/nav-button-wrapper";
import {
  NOTIFICATION_MAPPED_IDS,
  NotificationLabel,
} from "@/components/primary-navigation/shared/notification-label";
import { ControlledDropdown } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";
import {
  NAV_GROUP_NAMES,
  NAV_V3_ITEMS,
} from "@/components/primary-navigation/v3/constants";
import { NavDropdownMenuItem } from "@/components/primary-navigation/v3/nav-bar/nav-items-row/components/nav-dropdown-menu-item";
import { useGlobalNotificationsContext } from "@/context/notifications-context";

export const MessagesButton = ({ className }: { className?: string }) => {
  const router = useRouter();
  const { notificationCounters } = useGlobalNotificationsContext();

  const message_count = notificationCounters?.message_count || 0;
  const coaching_count = notificationCounters?.coaching_count || 0;
  const navItem = NAV_V3_ITEMS[NAV_GROUP_NAMES.MESSAGES];

  // TODO: Test for long routes with query params in the url
  const isActive = useMemo(
    () =>
      navItem.href === router.route ||
      navItem.children?.some((child) =>
        router.route.includes(child.href as string)
      ),
    [router.route, navItem]
  );

  return (
    <div className="flex h-full items-center">
      <ControlledDropdown
        className={clsxMerge(
          "dropdown-end dropdown-bottom flex h-full items-center",
          className
        )}
        closeOnContentClick={true}
      >
        {() => (
          <>
            {/* DROPDOWN TRIGGER */}
            <div tabIndex={0} className="h-full">
              <NavButtonWrapper
                btnClassName={clsxMerge("p-3", {
                  "opacity-100": message_count > 0 || coaching_count > 0,
                })}
                isActive={isActive}
              >
                <ChatBubbleEllipsisIcon className="w-6 text-white group-hover:animate-wiggle" />

                <NotificationLabel
                  id={NOTIFICATION_MAPPED_IDS.TOTAL_MESSAGES}
                  className="absolute -right-1 bottom-0.5 border-white"
                  isPulsating
                />
              </NavButtonWrapper>
            </div>

            {/* DROPDOWN CONTENT */}
            <div tabIndex={0} className="dropdown-content mt-1.5 min-w-[230px]">
              {navItem?.children?.length && (
                <ul className="rounded-box menu-compact w-full bg-white p-2 shadow-lg">
                  {navItem.children.map((subItem, idx) => (
                    <Fragment key={idx}>
                      <li>
                        <NavDropdownMenuItem subItem={subItem} />
                      </li>
                    </Fragment>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </ControlledDropdown>
    </div>
  );
};
