import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

import {
  AccountExecutiveI,
  AccountHistoryItemI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { CampaignI } from "@/interfaces/campaign";
import { useInfinitePagination } from "shared/lib/hooks/index";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { UseInfinitePaginationReturnI } from "shared/lib/hooks/infinite-scrolling/use-infinite-pagination";
import { UseFetchAccountDetailsApiI } from "@/api/routes/account/queries";
import { getLeadStateFlags } from "@/modules/pipeline/account-details/utils";
import {
  AccountDetailsStatusI,
  AccountDetailsSummaryI,
  AccountDetailsTabI,
  AccountI,
  AccountUserListI,
} from "shared/lib/interfaces/account";
import { ACCOUNT_DETAILS_TABS } from "shared/lib/constants/account";

export interface AccountDetailsContextI {
  account?: AccountI;
  accountSummary?: AccountDetailsSummaryI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  contactsForContextMenu: Array<ContactDetailI>;
  accountStatus?: AccountDetailsStatusI;
  accountExecutiveData?: AccountExecutiveI | null;
  accountUserLists?: AccountUserListI[];
  accountDetailsApi?: UseFetchAccountDetailsApiI;

  accountHistoryData: UseInfinitePaginationReturnI<AccountHistoryItemI>;

  focusedContact: ContactDetailI | undefined;
  setFocusedContact: (contact?: ContactDetailI) => void;

  onAccountUpdate: () => void;

  tab: AccountDetailsTabI | undefined;
  setTab: (tab: AccountDetailsTabI) => void;
}

export interface AccountDetailsProviderI {
  account?: AccountI;
  accountSummary?: AccountDetailsSummaryI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  accountStatus?: AccountDetailsStatusI;
  accountExecutiveData?: AccountExecutiveI | null;
  accountUserLists?: AccountUserListI[];
  accountDetailsApi?: UseFetchAccountDetailsApiI;

  accountHistory?: Array<AccountHistoryItemI>;

  onAccountUpdate: () => void;
  tab?: AccountDetailsTabI;
}

const accountHistoryFetcher = (
  campaignId: string,
  accountId: string,
  nextToken: string | undefined
) => glencocoClientAPI().getAccountHistoryV2(campaignId, accountId, nextToken);

const AccountDetailsContext = createContext<AccountDetailsContextI>({
  focusedContact: undefined,
  setFocusedContact: () => {},
  onAccountUpdate: () => {},
  tab: ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY,
  setTab: () => {},
  accountHistoryData: {
    isLoading: false,
    isReachedEnd: false,
    data: [],
    reloadData: () => {},
    loadMore: () => {},
  },
  accountDetailsApi: {} as UseFetchAccountDetailsApiI,
  contactsForContextMenu: [],
});

export const AccountDetailsProvider: FC<
  AccountDetailsProviderI & PropsWithChildren
> = ({
  campaign: campaignData,
  account: accountData,
  contacts: contactsData,
  accountUserLists: accountUserListsData,
  accountStatus: accountStatusData,
  accountSummary: accountSummaryData,

  onAccountUpdate,
  accountExecutiveData,
  tab: accountDetailsTab,
  accountDetailsApi,
  children,
}) => {
  const [focusedContact, setFocusedContact] = useState<ContactDetailI>();

  const [campaign, setCampaign] = useState(campaignData);
  useEffect(() => setCampaign(campaignData), [campaignData]);

  const [account, setAccount] = useState(accountData);
  useEffect(() => setAccount(accountData), [accountData]);

  const [contacts, setContacts] = useState(contactsData);
  useEffect(() => setContacts(contactsData), [contactsData]);

  const [accountStatus, setAccountStatus] = useState(accountStatusData);
  useEffect(() => setAccountStatus(accountStatusData), [accountStatusData]);

  const [accountSummary, setAccountSummary] = useState(accountSummaryData);
  useEffect(() => setAccountSummary(accountSummaryData), [accountSummaryData]);

  const [accountExecutive, setAccountExecutive] =
    useState(accountExecutiveData);
  useEffect(
    () => setAccountExecutive(accountExecutive),
    [accountExecutiveData]
  );

  const [accountUserLists, setAccountUserLists] =
    useState(accountUserListsData);
  useEffect(
    () => setAccountUserLists(accountUserListsData),
    [accountUserListsData]
  );

  const accountHistoryData = useInfinitePagination<AccountHistoryItemI, APII>({
    isInitiallyLoading: true,
    apiFactory: glencocoClientAPI,
    collectionKeyInResponse: "activity_logs",
    fetchMore: (nextToken) =>
      accountHistoryFetcher(
        campaign?.id as string,
        account?.id as string,
        nextToken
      ),
    errorMessage: "Failed to load account history. Please contact Glencoco.",
  });

  const [tab, setTab] = useState<AccountDetailsTabI>(
    accountDetailsTab || ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY
  );

  useEffect(() => {
    if (accountDetailsTab) {
      setTab(accountDetailsTab);
    }
  }, [accountDetailsTab]);

  // As `contacts` represent a full collection of contacts including ones that
  // are disqualified or marked as "do not call", we need to filter them out for
  // Disqualify, Email, Add to List, Book/Reschedule Meeting and Call buttons.
  const contactsForContextMenu = useMemo(() => {
    if (!contacts) {
      return [];
    }

    return contacts.filter((contact) => {
      const { isLeadDisqualified, isLeadMarkedDNC } =
        getLeadStateFlags(contact);

      return !isLeadDisqualified && !isLeadMarkedDNC;
    });
  }, [contacts]);

  return (
    <AccountDetailsContext.Provider
      value={{
        campaign,
        account,
        accountSummary,
        contacts,
        contactsForContextMenu,
        accountStatus,
        accountHistoryData,
        focusedContact,
        setFocusedContact,
        onAccountUpdate,
        accountExecutiveData,
        accountUserLists,
        tab,
        setTab,
        accountDetailsApi,
      }}
    >
      {children}
    </AccountDetailsContext.Provider>
  );
};

export const useAccountDetailsContext = (): AccountDetailsContextI =>
  useContext(AccountDetailsContext);
