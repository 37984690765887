import { FC, createContext, useContext, ReactNode } from "react";
import _noop from "lodash/noop";

import { useDisclosure } from "shared/lib/hooks/use-disclosure";

type AddUserToListSidebarContextI = Omit<
  ReturnType<typeof useDisclosure>, // isOpen, onOpen, onClose
  "onToggle" // no need for onToggle
>;

interface AddUserToListProviderPropsI {
  children: ReactNode;
}

export const AddUserToListSidebarContext =
  createContext<AddUserToListSidebarContextI>({
    isOpen: false,
    onOpen: _noop,
    onClose: _noop,
  });

export const useAddUserToListSidebarContext = () =>
  useContext(AddUserToListSidebarContext);

export const AddUserToListSidebarProvider: FC<AddUserToListProviderPropsI> = ({
  children,
}) => {
  const disclosure = useDisclosure();

  return (
    <AddUserToListSidebarContext.Provider value={{ ...disclosure }}>
      {children}
    </AddUserToListSidebarContext.Provider>
  );
};
