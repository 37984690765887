import Script from "next/script";

export const GoogleTagManager = () => {
  const GTAG_ID = "GTM-5KQPXP53";

  return (
    <>
      {/* Setup in Google Analytics in Web stream details */}
      <Script id="gtm-initialization" strategy="afterInteractive" async={true}>
        {/* eslint-disable-next-line */}
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTAG_ID}');
        `}
      </Script>
    </>
  );
};
