import { ReactElement, useEffect, useState } from "react";

import { clsxMerge } from "shared/lib/helpers";

export interface TabI {
  id?: string;
  title?: string | ReactElement;
  href?: string;
}

export const createTab = (
  id?: string,
  title?: string | ReactElement,
  href?: string
): TabI => ({
  id,
  title,
  href,
});

const tabClassName = ({
  className,
  selectedClassName,
  isSelected,
}: {
  className?: string;
  selectedClassName?: string;
  isSelected?: boolean;
}) =>
  clsxMerge(
    "b-typography-body1 pb-2",
    "min-h-[29px]",
    "animate-fadein",
    "transition-all duration-300",
    className,
    {
      "border-b border-black text-black": isSelected,
      "border-b border-black/0 text-black/40": !isSelected,
      [selectedClassName as string]: isSelected,
    }
  );

const Tab = ({
  id,
  title,
  href,
  className,
  selectedClassName,
  isSelected,
  isSoftRouting,

  onClick,
}: TabI & {
  className?: string;
  selectedClassName?: string;
  isSelected?: boolean;
  isSoftRouting?: boolean;
  onClick?: (id: string) => void;
}) => {
  const softRedirect = (route: string) => {
    window.history.pushState({}, "", route);
  };

  const onLinkClick = () => onClick?.(id as string);
  const onButtonClick = () => {
    softRedirect(href as string);
    onClick?.(id as string);
  };

  return !isSoftRouting ? (
    <a
      id={id}
      href={href as string}
      className={tabClassName({ className, selectedClassName, isSelected })}
      onClick={onLinkClick}
    >
      {title}
    </a>
  ) : (
    <button
      type="button"
      id={id}
      className={tabClassName({ className, selectedClassName, isSelected })}
      onClick={onButtonClick}
    >
      {title}
    </button>
  );
};

export const Tabs = ({
  items,
  selected: _selected,
  className,
  tabClassName,
  tabSelectedClassName,
  isSoftRouting,
  onChange,
}: {
  items?: Array<TabI>;
  selected?: string;
  className?: string;
  tabClassName?: string;
  tabSelectedClassName?: string;
  isSoftRouting?: boolean;
  onChange?: (id?: string) => void;
}) => {
  const [selected, setSelected] = useState(_selected ? _selected : items?.[0]);

  const handleOnSelect = (id: string) => {
    setSelected(id);
  };

  useEffect(() => {
    onChange?.(selected as string);
  }, [selected]);

  useEffect(() => {
    setSelected(_selected);
  }, [_selected]);

  return (
    <div className={clsxMerge("flex items-center", className)}>
      {items?.map((t) => (
        <Tab
          key={t?.id}
          className={clsxMerge("mr-10", tabClassName)}
          selectedClassName={tabSelectedClassName}
          isSelected={t.id === selected}
          isSoftRouting={isSoftRouting}
          onClick={handleOnSelect}
          {...t}
        />
      ))}
    </div>
  );
};
