import DangerousActionConfirmationModal from "@/components/modals/dangerous-action-confirmation-modal";

export const EMAIL_TEMPLATE_DELETE_MODAL_ID = "email_template_delete_modal";

export const EmailTemplateDeleteModal = ({
  name,
  onConfirm = () => {},
}: {
  name?: string;
  onConfirm?: () => void;
}) => {
  return (
    <DangerousActionConfirmationModal
      modalId={EMAIL_TEMPLATE_DELETE_MODAL_ID}
      title="Are you sure you want to delete this template?"
      description={name}
      mainButtonText="Delete"
      callback={onConfirm}
    />
  );
};
