import { CallAccountI } from "@/interfaces/dialer";
import { AccountI } from "shared/lib/interfaces/account";

export const convertAccountToCallAccount = (
  account?: AccountI
): CallAccountI => {
  return {
    id: account?.id,
    name: account?.name,
    employee_count: account?.employee_count,
    industry: account?.industry,
    revenue: account?.revenue,
    hq_state: account?.hq_state,
    hq_city: account?.hq_city,
    website: account?.website,
    misc: account?.misc,
  };
};
