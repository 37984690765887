export const FORUM_POST_SORT_ORDERS = {
  RECENTLY_ADDED: "recently_added",
  UPVOTES: "upvotes",
} as const;

export const SHARED_FORUM_API_METHODS = {
  CREATE_POST: "createForumPost",
  GET_POSTS: "getForumPosts",
  EDIT_POST: "editForumPost",
  UPVOTE_POST: "upvoteForumPost",
  DELETE_POST: "deleteForumPost",
  PIN_POST: "pinForumPost",
  UNPIN_POST: "unpinForumPost",

  GET_POST_DETAILS: "getForumPostDetails",
  CREATE_COMMENT: "createForumPostComment",
  EDIT_COMMENT: "editForumPostComment",
  UPVOTE_COMMENT: "upvoteForumPostComment",
  DELETE_COMMENT: "deleteForumPostComment",
} as const;

/**
 * BE values from post_type. For now in the shared folder
 * however in the future we might have to split
 * into each project separately
 */
export const FORUM_POST_TYPES = {
  DEFAULT: "default",
  QUALIFIED_MEETIGNS: "qualified_meeting_alert",
} as const;

export const GENERAL_FORUM_POST_TYPES = {
  DEFAULT: "default",
  HELP: "help",
  FEATURE_REQUEST: "feature_request",
  COACHING_AND_TIPS: "coaching_and_tips",
} as const;

export const GENERAL_FORUM_POST_TYPES_MAP = {
  [GENERAL_FORUM_POST_TYPES.DEFAULT]: "General chat",
  [GENERAL_FORUM_POST_TYPES.HELP]: "Ask for help",
  [GENERAL_FORUM_POST_TYPES.FEATURE_REQUEST]: "Feature requests",
  [GENERAL_FORUM_POST_TYPES.COACHING_AND_TIPS]: "Sales coaching & tips",
} as const;
