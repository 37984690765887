import {
  NOTIFICATION_ACTION_TYPES,
  createNotificationAction,
} from "shared/ui/notifications/notification-generic/base-parts/actions";
import { NotificationBase } from "@/modules/notifications/notification/base";
import { NotificationI } from "@/interfaces/notification";
import Router from "@/helpers/router";

export const NotificationExclusiveListReceived = (
  props: NotificationI & { className?: string }
) => {
  const { className, id, campaign_name, is_seen, created_at, list_id } = props;

  return (
    <NotificationBase
      id={id}
      isUnread={!is_seen}
      className={className}
      avatar={props}
      description={{
        text: (
          <>
            <b>{campaign_name}</b> has given you an exclusive list for being a
            top caller on their campaign.
          </>
        ),
        date: created_at,
        campaignName: campaign_name,
      }}
      actions={[
        createNotificationAction(
          NOTIFICATION_ACTION_TYPES.PRIMARY,
          "View account",
          () => {
            window.location.href = Router.lists(list_id);
          }
        ),
      ]}
    />
  );
};
