import { useMemo } from "react";
import { ListBulletIcon } from "@heroicons/react/24/outline";

import { clsxMerge } from "shared/lib/helpers";

import { useDisclosure } from "shared/lib/hooks";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { convertContactToContactDetail } from "@/helpers/contact";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import { AddUserToListSidebar } from "@/modules/pipeline/shared/add-user-to-list-sidebar";

export const AddToListButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { campaign } = useCallingContext();
  const { contact } = useInCallContext();

  const contacts = useMemo(
    () => [convertContactToContactDetail(contact)],
    [contact]
  );

  const handleOnSuccess = () => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
    });
  };

  return (
    <>
      <button
        className={clsxMerge(
          "btn-b-white-outline flex items-center border-[0.5px] px-2",
          "disabled:border-white/40 disabled:text-white/40"
        )}
        onClick={onOpen}
      >
        <ListBulletIcon className="mr-2 w-4" />
        Add To List
      </button>

      <AddUserToListSidebar
        isOpen={isOpen}
        campaign={campaign}
        contacts={contacts}
        onAccountUpdate={handleOnSuccess}
        onClose={onClose}
      />
    </>
  );
};
