import { Fragment } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";

import { clsxMerge } from "shared/lib/helpers";
import { ControlledDropdown } from "shared/ui";
import { CloseIcon } from "shared/ui/icons";
import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/primary-navigation/v3/interfaces";
import { NavDropdownMenuItem } from "@/components/primary-navigation/v3/nav-bar/nav-items-row/components/nav-dropdown-menu-item";
import {
  MY_CAMPAIGNS_ITEM_ID,
  REFERRALS_ITEM_ID,
} from "@/components/primary-navigation/v3/constants";

// dropdown menu items that should have the grey separator line below them
const DROPDOWN_ITEM_IDS_WITH_DIVIDER = [
  MY_CAMPAIGNS_ITEM_ID,
  REFERRALS_ITEM_ID,
];

/**
 *
 * @param data menu items
 * @returns Hamburger menu dropdown (for small width screens)
 */

export const MobileDropdownMenu = ({
  data,
}: {
  data: Array<PrimaryNavigationItemCollapsableWithIconI>;
}) => {
  return (
    <ControlledDropdown className="dropdown-end" closeOnContentClick>
      {(isDropdownVisible) => (
        <>
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            {isDropdownVisible ? (
              <CloseIcon className="w-6 text-white" />
            ) : (
              <Bars3Icon className="w-6 text-white" />
            )}
          </label>

          <div
            tabIndex={0}
            className={clsxMerge(
              "dropdown-content menu menu-compact",
              "rounded-b-lg bg-white shadow-lg",
              "mt-2 p-5"
            )}
          >
            <p className="brand-typography-h2 mb-4">Navigation</p>

            <div className="flex flex-nowrap gap-4">
              {data.map((item, i) => {
                return (
                  <div key={i} className="w-[210px]">
                    {/* PARENT ITEM */}
                    <div
                      className={clsxMerge("group flex items-center p-3", {
                        "pointer-events-none": item.disabled,
                        "opacity-30": item.disabled,
                      })}
                    >
                      <span className="w-4 text-black group-hover:animate-wiggle">
                        {item.icon}
                      </span>
                      <span className="brand-typography-h6 ml-2">
                        {item.text}
                      </span>
                    </div>
                    {/* --------------- */}

                    {/* CHILDREN ITEMS */}
                    <ul key={i}>
                      {item?.children?.map((subItem, idx) => (
                        <Fragment key={idx}>
                          <li className="ml-1">
                            <NavDropdownMenuItem subItem={subItem} />
                          </li>

                          {DROPDOWN_ITEM_IDS_WITH_DIVIDER.includes(
                            subItem.id as string
                          ) && <div className="divider my-0" />}
                        </Fragment>
                      ))}
                    </ul>
                    {/* --------------- */}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
