import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

import SBHiddenState from "./hidden-state";
import SBActiveState from "./active-state";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { CampaignI, CampaignV3I } from "@/interfaces/campaign";
import { DIALER_MODES } from "@/constants/dialer";

export const DialerSidebar = ({
  className,
  minimizedClassName,

  campaign,
  isActive,

  onOpen,
  onClose,
}: {
  className?: string;
  minimizedClassName?: string;

  isActive?: boolean;
  campaign: CampaignI | CampaignV3I;

  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const open = () => onOpen?.();
  const close = () => onClose?.();

  const handleHiddenSBAction = (index?: number) => {
    if (typeof index === "number") {
      setTabIndex(index);
    }

    open();
  };

  return (
    <div
      className={twMerge(clsx("relative bg-black pr-2", className))}
      data-theme="dark"
    >
      <SBActiveState
        isVisible={isActive}
        onClick={() => close()}
        activeIndex={tabIndex}
        campaign={campaign as CampaignI}
      />

      <SBHiddenState
        isVisible={!isActive}
        onClick={handleHiddenSBAction}
        className={minimizedClassName}
      />
    </div>
  );
};

const DialerSidebarWithContext = ({ className }: { className?: string }) => {
  const callingContext = useCallingContext();
  const { mode } = callingContext;

  const [campaign, setCampaign] = useState<CampaignI | undefined>(undefined);
  const [isActive, setIsActive] = useState(false);
  // const [tabIndex, setTabIndex] = useState(0);

  const open = () => setIsActive(true);
  const close = () => setIsActive(false);

  useEffect(() => {
    if (callingContext?.campaign?.id) {
      setCampaign(callingContext?.campaign);
    }

    if (callingContext?.campaign?.id && mode !== DIALER_MODES.CATCHUP) {
      open();
    }
  }, [callingContext?.campaign]);

  // const handleHiddenSBAction = (index?: number) => {
  //   if (typeof index === "number") {
  //     setTabIndex(index);
  //   }

  //   open();
  // };

  return (
    <DialerSidebar
      className={className}
      campaign={campaign as CampaignI}
      isActive={isActive}
      onOpen={open}
      onClose={close}
    />

    // <div
    //   className={twMerge(clsx("relative bg-black pr-2", className))}
    //   data-theme="dark"
    // >
    //   <SBActiveState
    //     isVisible={isActive}
    //     onClick={() => close()}
    //     activeIndex={tabIndex}
    //     campaign={campaign}
    //   />

    //   <SBHiddenState isVisible={!isActive} onClick={handleHiddenSBAction} />
    // </div>
  );
};

export default DialerSidebarWithContext;
