import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { CALLING_MODULE_BOOK_MEETING_MODAL_ID } from "@/modules/calling/modals/booking-meeting-modal";

import { CalendarIcon } from "shared/ui/icons";

import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface BookMeetingButtonPropsI {
  className?: string;
}

export const BookMeetingButton: FC<BookMeetingButtonPropsI> = ({
  className,
}) => {
  const { calendlyEvent } = useInCallContext();

  return (
    <label
      htmlFor={!calendlyEvent ? CALLING_MODULE_BOOK_MEETING_MODAL_ID : ""}
      className={clsxMerge("btn-ae-default book-meeting-btn", className, {
        disabled: calendlyEvent,
      })}
    >
      <CalendarIcon className="mr-3 h-4" /> Book Meeting
    </label>
  );
};
