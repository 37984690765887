import clsx from "clsx";
import { FC } from "react";
import {
  STATUS_TYPES,
  STATUS_TYPES_STRING_MAP,
} from "shared/lib/constants/statuses";

export const STATUS_PILL_CSS_PROPERTIES = {
  [STATUS_TYPES.UPCOMING]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.COMPLETED]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.OVERDUE]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.PENDING]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.PENDING_FEEDBACK]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.NO_SHOW]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [STATUS_TYPES.RESCHEDULED]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.QUALIFIED]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
  [STATUS_TYPES.UNQUALIFIED as string]: {
    color: "text-black/80",
    bg: "bg-[#FFEFEB]",
  },
  [STATUS_TYPES.ALERT]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [STATUS_TYPES.CANCELLED]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [STATUS_TYPES.MORE_MATERIALS]: {
    color: "text-[white]",
    bg: "bg-black",
  },
  [STATUS_TYPES.MATERIALS_SENT]: {
    color: "text-[white]",
    bg: "bg-black",
  },
} as const;

interface StatusPillI {
  status: (typeof STATUS_TYPES)[keyof typeof STATUS_TYPES];
  className?: string;
}

const StatusPill: FC<StatusPillI> = ({ status, className }) => {
  return (
    <div
      className={clsx(
        "ae-typography-detail1 w-fit rounded px-2 py-1 text-center",
        STATUS_PILL_CSS_PROPERTIES[status]?.bg,
        STATUS_PILL_CSS_PROPERTIES[status]?.color,
        className
      )}
    >
      {STATUS_TYPES_STRING_MAP[status]}
    </div>
  );
};

export default StatusPill;
