import { PersonasIcon } from "shared/ui/icons";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "shared/ui/icons";
import { MailIcon } from "shared/ui/icons";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { DescList } from "shared/ui";
import { InfoRecordI } from "shared/ui/desc-list";
import {
  clsxMerge,
  formatNumbers,
  formatUSPhoneNumber,
} from "shared/lib/helpers";
import { CallContactMetaI } from "@/interfaces/dialer/contact";
import { LeadCardMetaDataBlock } from "./meta-data-block";

const createInfoRecord = (
  icon: JSX.Element,
  data?: string,
  config?: any
): InfoRecordI => ({
  icon,
  data,
  config,
});

const getLocationString = (hq_city?: string, hq_state?: string) => {
  if (hq_city && !hq_state) return hq_city;
  if (!hq_city && hq_state) return hq_state;
  if (hq_city && hq_state) return `${hq_city}, ${hq_state}`;

  return "";
};

const MetasBlock = ({ data = [] }: { data?: Array<CallContactMetaI> }) => {
  return data?.length && data.some((meta) => meta.label || meta.value) ? (
    <div
      className={clsxMerge(
        "lead-card-metas-block rounded-lg bg-black/5",
        "flex flex-col gap-3",
        "mb-6 mt-4 p-3"
      )}
    >
      {data?.map((meta, i) => {
        return meta.label || meta.value ? (
          <LeadCardMetaDataBlock key={i} meta={meta} />
        ) : null;
      })}
    </div>
  ) : null;
};

const LeadDesciprion = () => {
  const { account = {}, contact = {} } = useInCallContext();
  const { name, employee_count, industry, revenue, hq_state, hq_city } =
    account || {};
  const { phone, email, meta } = contact || {};
  const metaData = meta?.values || [];

  const accountInfo = [
    createInfoRecord(
      <PersonasIcon className="mr-1 h-2" />,
      employee_count ? `${formatNumbers(employee_count)} employees` : ""
    ),
    createInfoRecord(<BuildingOfficeIcon className="mr-1 h-3" />, industry),
    createInfoRecord(
      <BanknotesIcon className="mr-1 h-3" />,
      revenue ? `$${formatNumbers(revenue)}` : ""
    ),
    createInfoRecord(
      <MapPinIcon className="mr-1 h-3" />,
      getLocationString(hq_city, hq_state)
    ),
  ];

  const contactInfo = [
    createInfoRecord(
      <PhoneIcon className="mr-1 h-3" />,
      formatUSPhoneNumber(phone)
    ),
    createInfoRecord(<MailIcon className="mr-1 h-3" />, email, {
      isAccent: true,
      href: `mailto:${email}`,
      target: "_blank",
      rel: "noreferrer",
    }),
  ];

  return (
    <div>
      <MetasBlock data={metaData} />

      <div className="grid grid-cols-[fit-content(50%)_1fr] gap-x-10">
        <div className="about-company">
          <h6 className="ae-typography-detail1 mb-3 text-base-content/40">
            About {name}
          </h6>

          <DescList infoList={accountInfo} />
        </div>
        <div className="contact-info">
          <h6 className="ae-typography-detail1  mb-3 text-base-content/40">
            Contact Info
          </h6>

          <DescList infoList={contactInfo} />
        </div>
      </div>
    </div>
  );
};

export default LeadDesciprion;
