import { DIALER_STATES } from "@/constants/dialer";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { convertAccountToCallAccount } from "@/helpers/account";
import { convertPipelineListContactToCallContact } from "@/helpers/contact";
import { asyncGet } from "@/helpers/context";
import { dd } from "@/helpers/datadog";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { GetAccountDetailsDataI } from "@/interfaces/account-details";

import { PipelineListContactI } from "@/interfaces/pipeline/list";
import { useEffect } from "react";
import wait from "wait";

/**
 * Custom hook that handles setting up the connected lead and updating the call context when a lead is prefetched.
 *
 * As part of 0 wait time before opening a widget from dialing list
 *
 * This hook performs the following actions:
 * 1. If a `connectedLead` is provided, it sets the calling state to `CALL` in the dialer context.
 * 2. If there is no contact already set in the in-call context, it converts the provided `connectedLead`
 *    into a format suitable for a call contact using `convertPipelineListContactToCallContact` and updates the contact in the context.
 *
 * 3. Verification that call has actually happened
 *
 * Dependencies:
 * - `connectedLead` (optional): The lead to be set as the connected contact, typically from a pipeline list.
 *
 * @param {PipelineListContactI} [connectedLead] - The prefetched connected lead from the pipeline list.
 */
export const usePrefetchedConnectedLead = (
  connectedLead?: PipelineListContactI,
  accountDetails?: GetAccountDetailsDataI
) => {
  const { setTwilioIdentity } = useDialerGlobalContext();
  const { setCallingState, setCall, campaignId, setCampaignID } =
    useCallingContext();
  const { contact, account, setContact, setAccount } = useInCallContext();

  useEffect(() => {
    if (connectedLead) {
      setCallingState(DIALER_STATES.CALL);

      if (!contact?.id) {
        setContact(convertPipelineListContactToCallContact(connectedLead));
      }

      if (campaignId !== connectedLead?.campaign_id) {
        setCampaignID(connectedLead?.campaign_id);
      }

      (async () => {
        await wait(2000);
        const call = await asyncGet(setCall);
        const twilioIdentity = await asyncGet(setTwilioIdentity);

        if (!call?.customParameters.get("call_id")) {
          const message = `${ERROR_CATEGORIES.DIALER_LIST} - call has not started`;
          const ddContext = { data: { connectedLead, twilioIdentity } };

          dd.rum.error(message, ddContext);
        }
      })();
    }

    if (accountDetails) {
      if (!account?.id) {
        setAccount(convertAccountToCallAccount(accountDetails?.account));
      }
    }
  }, []);
};
