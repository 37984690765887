import { Fragment, useMemo } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { MapPinIcon, PhoneIcon, AtSymbolIcon } from "@heroicons/react/24/solid";
import { LogoLinkedInIcon } from "../../icons";
import { ResumeI } from "../interfaces/resume";
import { fixUrl, trimString } from "shared/lib/helpers/utils";
import {
  getPublicResumeName,
  getYearsExperienceByValue,
} from "shared/ui/sales-resume/helper";

export const RESUME_PLACEHOLDER_IMAGE =
  "https://glencoco-assets.s3.amazonaws.com/landing/version-2/profile-image-placeholder.webp";

interface InfoRecordI {
  icon: JSX.Element;
  data?: string;
  config?: any;
}

const createInfoRecord = (
  icon: JSX.Element,
  data?: string,
  config?: any
): InfoRecordI => ({
  icon,
  data,
  config,
});

const OnlineStatusIcon = ({ isOnline }: { isOnline?: boolean }) => {
  return (
    <div
      className={clsx(`h-4 w-4 rounded-full`, {
        "bg-[#2AAB2F]": isOnline,
        "bg-black/40": !isOnline,
      })}
    />
  );
};

const SalesResumeProfile = ({
  isInternal,
  resumeData,
  buttonSection,
  className,
  obfuscateInfo,
}: {
  isInternal: boolean;
  resumeData?: ResumeI;
  buttonSection?: JSX.Element;
  className?: string;
  obfuscateInfo?: boolean;
}) => {
  const shouldShowDetails = isInternal || resumeData?.is_profile_public;

  const email = useMemo(
    () =>
      obfuscateInfo
        ? resumeData?.email?.slice(0, 3) + "xxx@xxxx.xx"
        : resumeData?.email,
    [resumeData?.email, obfuscateInfo]
  );

  const phone = useMemo(
    () =>
      obfuscateInfo
        ? resumeData?.phone?.slice(0, 2) + "-XXX-XXXX"
        : resumeData?.phone,
    [resumeData?.phone]
  );

  const profileInfo = [
    createInfoRecord(
      <OnlineStatusIcon isOnline={resumeData?.is_online} />,
      resumeData?.is_online ? "Online" : "Offline"
    ),
    createInfoRecord(
      <LogoLinkedInIcon className="h-4 w-4 text-[#0B65C2]" />,
      resumeData?.linkedin_url?.includes("linkedin.com/")
        ? trimString(resumeData?.linkedin_url?.split(".com")[1] || "", 20)
        : trimString(resumeData?.linkedin_url || "", 20),
      {
        href: fixUrl(resumeData?.linkedin_url),
        target: "_blank",
        rel: "noreferrer",
      }
    ),
    createInfoRecord(
      <MapPinIcon className="h-4 w-4 text-[#F88167]" />,
      resumeData?.location
    ),
    createInfoRecord(<PhoneIcon className="h-4 w-4 text-[#F88167]" />, phone),
    createInfoRecord(
      <AtSymbolIcon className="h-4 w-4 text-[#F88167]" />,
      trimString(email || "", 20),
      {
        href: `mailto:${resumeData?.email}`,
        target: "_blank",
        rel: "noreferrer",
      }
    ),
  ];

  return (
    <section
      className={twMerge(
        clsx(
          "relative rounded-[20px] px-4 py-6 lg:w-[225px]",
          "flex flex-col items-center",
          "mo:w-full mo:p-0 mo:items-start",
          {
            "mo:items-center": !shouldShowDetails,
          },
          className
        )
      )}
    >
      <img
        src={resumeData?.profile_pic_url || RESUME_PLACEHOLDER_IMAGE}
        alt={
          resumeData?.profile_pic_url
            ? `${resumeData?.first_name}'s avatar`
            : ""
        }
        className={clsx(
          "mb-4 rounded-full object-cover shadow-md",
          "h-[166px] w-[166px]"
        )}
      />

      <div className="w-full">
        {!!resumeData && (
          <h3
            className={clsx("mb-1 text-[24px] font-bold leading-none", {
              "text-center": !shouldShowDetails,
            })}
          >
            {getPublicResumeName(resumeData)}
          </h3>
        )}

        {shouldShowDetails && (
          <p className="text-[14px] font-normal leading-[140%] text-black/60">
            {`${resumeData?.first_name} 
          ${resumeData?.last_name} 
          is a sales professional 
          ${
            getYearsExperienceByValue(resumeData?.years_of_experience)
              ? `with ${getYearsExperienceByValue(
                  resumeData?.years_of_experience
                )} of experience`
              : ""
          } `}
          </p>
        )}
      </div>

      {shouldShowDetails && (
        <>
          {buttonSection}

          <div className="divider opacity-60" />

          <div
            className={clsx(
              "w-full items-center",
              "grid gap-x-4 gap-y-3 lg:grid-cols-[16px_1fr]",
              "grid-cols-[16px_1fr_16px_1fr]"
            )}
          >
            {profileInfo.map(
              (info, i) =>
                info.data && (
                  <Fragment key={i}>
                    <div>{info.icon}</div>

                    {info?.config?.href ? (
                      <a
                        href={info?.config?.href}
                        rel={info?.config?.rel}
                        target={info?.config?.target}
                        className="ae-typography-body1"
                      >
                        {info.data}
                      </a>
                    ) : (
                      <div className="ae-typography-body1">{info.data}</div>
                    )}
                  </Fragment>
                )
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default SalesResumeProfile;
