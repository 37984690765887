import { useEffect } from "react";
import { checkIfClient } from "../helpers";

export const useOnTabFocus = (callback = () => {}) =>
  useEffect(() => {
    if (checkIfClient()) {
      const windowFocusHandler = () => {
        if (typeof callback === "function") {
          callback();
        }
      };

      window.addEventListener("focus", windowFocusHandler);

      return () => {
        window.removeEventListener("focus", windowFocusHandler);
      };
    }
  }, [callback]);
