import { clsxMerge } from "shared/lib/helpers";

import { DraggableIcon } from "shared/ui/icons";

export const DialerWidgetDragButton = () => {
  return (
    <span
      className={clsxMerge(
        "btn btn-circle btn-ghost btn-sm",
        "absolute right-[58px] top-1",
        "hover:cursor-grab",
        "active:cursor-grabbing"
      )}
    >
      <DraggableIcon className="w-3 text-white/60" />
    </span>
  );
};
