import { FC, useMemo } from "react";

import { ButtonMenu } from "shared/ui";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import {
  MARK_DONE_DISQUALIFY_LEAD_MODAL_ID,
  MARK_DONE_MODAL_ID,
  MARK_DONE_SEND_EMAIL_MODAL_ID,
} from "@/modules/calling/modals/mark-done-modal";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { MARK_DONE_BUTTON_ID } from "@/modules/calling/modes/catchup/walkthrough";

interface MarkDoneButtonPropsI {
  withEmail?: boolean;
  className?: string;
}

interface MarkDoneOptionI {
  label: string;
  actionModalId: string;
}

const getMarkDoneOptions = (withEmail: boolean) => {
  return [
    {
      label: "Mark Completed",
      actionModalId: MARK_DONE_MODAL_ID,
    },
    withEmail && {
      label: "Mark Completed & Send Email",
      actionModalId: MARK_DONE_SEND_EMAIL_MODAL_ID,
    },
    {
      label: "Disqualify Lead",
      actionModalId: MARK_DONE_DISQUALIFY_LEAD_MODAL_ID,
    },
  ].filter(Boolean) as MarkDoneOptionI[];
};

// NOTE refactor interface as each action option is a boolean
//      where all by default are true
export const MarkDoneButton: FC<MarkDoneButtonPropsI> = ({
  className,
  withEmail = false,
}) => {
  const options = useMemo(
    () => getMarkDoneOptions(Boolean(withEmail)),
    [withEmail]
  );

  return (
    <ButtonMenu
      id={MARK_DONE_BUTTON_ID}
      position="start"
      className="btn-ae-default btn-ae-l w-full"
      containerClassName={className}
      text="Mark Completed"
      items={options.map(({ label, actionModalId }) => (
        <ButtonMenuItem
          className="font-normal"
          text={label}
          onClick={() => modalHelpers.open(actionModalId)}
        />
      ))}
    />
  );
};
