import { DND_HANDLER_CLASSNAME } from "@/components/shared/widgets/dialer/constants";
import { useDialerWidgetContext } from "@/context/dialer-v2/widget";
import { ArrowDownCircleIcon } from "@heroicons/react/24/solid";
import { clsxMerge } from "shared/lib/helpers";

export const DialerWidgetScrollIndicator = () => {
  const { isScrollbar } = useDialerWidgetContext();

  const handleScrollToBottom = () => {
    const widgetContainer = document?.querySelector(
      `.${DND_HANDLER_CLASSNAME}`
    );
    widgetContainer?.scroll({
      top: widgetContainer?.scrollHeight,
      behavior: "smooth",
    });
  };

  return isScrollbar ? (
    <button className="relative w-full animate-fadein">
      <div
        className={clsxMerge(
          "absolute top-3 z-[1] flex w-full items-center justify-center",
          "text-white opacity-60",
          "group"
        )}
        onClick={handleScrollToBottom}
      >
        <ArrowDownCircleIcon
          className={clsxMerge(
            "w-8 transition-all duration-300 group-hover:scale-110"
          )}
        />
      </div>

      <div
        className={clsxMerge(
          "absolute top-11 z-[1] flex w-full items-center justify-center",
          "text-white opacity-60"
        )}
      >
        <p className="brand-typography-body2 text">Scroll down</p>
      </div>
    </button>
  ) : null;
};
