import { clsxMerge } from "shared/lib/helpers";
import { PhoneChatIcon } from "shared/ui/icons";

export const DialingWarningBlock = ({ className }: { className?: string }) => {
  return (
    <div className={clsxMerge("relative ", className)}>
      <div
        className={clsxMerge(
          "flex items-start",
          "rounded-xl bg-black p-6",
          "text-white"
        )}
      >
        <div>
          <PhoneChatIcon className="w-14" />
        </div>
        <div className="ml-3 flex grow justify-center">
          <p className="max-w-[300px]">
            <span className="typography-subtitle font-semibold">
              You will not hear the first 3 seconds
            </span>
            <br />
            <span className="b-typography-body1 font-normal">
              of the phone call because of our AI voicemail recognition. Please
              start your pitch immediately when you see the caller card.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
