import { useEffect } from "react";
import { autoUpdate, flip, useFloating } from "@floating-ui/react";

import { useDisclosure } from "./use-disclosure";

// Use this hook to determine if a floating element, for example dropdown,
// should be displayed above or below the target element to be fully visible.

const useFloatingPosition = () => {
  const { isOpen, onToggle: onToggleVisibility } = useDisclosure();

  const { refs, floatingStyles, elements, update } = useFloating({
    placement: "bottom-start",
    strategy: "absolute",
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
    open: false,
    transform: true,
  });

  useEffect(() => {
    // whileElementsMounted prop might be used if we'd mount / unmount dropdown
    // content, which is not our case, as we use a purely CSS-based dropdown
    // content visibility definition, so we use call autoUpdate directly based on
    // the isOpen state.
    if (isOpen && elements.reference && elements.floating) {
      return autoUpdate(elements.reference, elements.floating, update);
    }
  }, [isOpen, elements, update]);

  return { refs, floatingStyles, isOpen, onToggleVisibility };
};

export default useFloatingPosition;
