import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_STATES } from "@/constants/dialer";

import { StartCallButton } from "./start-call-button";
import { EndCallButton } from "./end-call-button";

export const CallButton = ({
  isDisabled,
  className,
  onStartCall,
}: {
  isDisabled?: boolean;
  className?: string;
  onStartCall?: () => Promise<void>;
}) => {
  const { callingState } = useCallingContext();

  return (
    <>
      {callingState === DIALER_STATES.CALL && (
        <EndCallButton isDisabled={isDisabled} className={className} />
      )}
      {callingState !== DIALER_STATES.CALL && (
        <StartCallButton
          isDisabled={isDisabled}
          className={className}
          onClick={onStartCall}
        />
      )}
    </>
  );
};
