import { FC, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { SparklesIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import {
  AttachmentI,
  EmailTemplateI,
  GetEmailGenerationStatusResponseI,
  SendEmailWithAIRequestParamsI,
  SendEmailRequestParamsI,
} from "@/api/routes/email";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { AILoader } from "@/components/base/loaders/ai-loader";
import {
  useGenerateAIEmail,
  useSendEmailWithAI,
  useSendEmail,
} from "@/modules/email-templates-sidebar/queries";
import { useEmailTemplatesSidebarContext } from "@/modules/email-templates-sidebar/context";
import PollEmailGenerationStatus from "@/modules/email-templates-sidebar/shared/poll-email-generation-status";
import { EmailForm, QUICK_SEND_AI_EMAIL_BUTTON_ID } from "./email-form";

interface EmailTemplatePropsI {
  template: EmailTemplateI;
  emailId: string;
  senderEmail?: string;
  campaignAttachments?: AttachmentI[];
}

export const EmailTemplate: FC<EmailTemplatePropsI> = ({
  template,
  emailId,
  senderEmail,
  campaignAttachments,
}) => {
  const { onClose } = useEmailTemplatesSidebarContext();
  const {
    setFocusedContact,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const { mutateAsync: generateAIEmailAsync } = useGenerateAIEmail();
  const { mutateAsync: sendEmailAsync, isPending: isSendEmailPending } =
    useSendEmail();

  const {
    mutateAsync: sendEmailWithAIAsync,
    isPending: isSendEmailWithAIPending,
  } = useSendEmailWithAI();

  const [isGeneratingEmail, setIsGeneratingEmail] = useState(false);
  const [isAIGenerationComplete, setIsAIGenerationComplete] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const [emailData, setEmailData] =
    useState<GetEmailGenerationStatusResponseI>();

  // These actions are rendered in the <AILoader /> when loading goes on for too long
  const lengthyAILoadingActions = useMemo(
    () => (
      <div className="flex justify-center gap-2">
        <button
          className="btn-b-white"
          onClick={() =>
            document.getElementById(QUICK_SEND_AI_EMAIL_BUTTON_ID)?.click()
          }
        >
          <SparklesIcon className="mr-2 w-4" />
          QUICK SEND
        </button>

        <button
          className="btn-b-white"
          onClick={() => handleAIEmailGenerationCompleted(null)}
        >
          OR BUILD EMAIL MANUALLY
        </button>
      </div>
    ),
    []
  );

  const handleComposeWithAI = useCallback(async () => {
    if (template.id && emailId) {
      const resp = await generateAIEmailAsync({
        templateId: template.id,
        parameters: { email_id: emailId },
      });

      if (resp.status === 200) {
        setIsGeneratingEmail(true);
        return true;
      }
    }

    return false;
  }, [template.id, emailId]);

  const handleAIEmailGenerationCompleted = (
    generatedEmailData: GetEmailGenerationStatusResponseI | null
  ) => {
    setIsGeneratingEmail(false);

    if (generatedEmailData) {
      setIsAIGenerationComplete(true);
    }

    setEmailData({
      ...generatedEmailData,
      attachments: generatedEmailData?.attachments || [],
      subject: generatedEmailData?.subject || "",
      body: generatedEmailData?.body || "",
    });
  };

  const handleEmailSend = useCallback(
    async (data: SendEmailRequestParamsI) => {
      if (emailId) {
        const resp = await sendEmailAsync({
          emailId: emailId,
          parameters: data,
        });

        if (resp.status === 200) {
          onEmailSuccess();
          return true;
        }
      }

      return false;
    },
    [emailId, onClose]
  );

  const handleQuickSendWithAI = useCallback(
    async (data: SendEmailWithAIRequestParamsI) => {
      if (emailId) {
        const resp = await sendEmailWithAIAsync({
          emailId: emailId,
          parameters: data,
        });

        if (resp.status === 200) {
          if (!isGeneratingEmail) {
            // user has chosen to Quick Send without first having clicked "Compose with AI", therefore backend hasn't started the AI generation yet.
            // frontend has to call handleComposeWithAI() so backend can start the AI generation process before we close out of the email flow.
            handleComposeWithAI();
          }

          onEmailSuccess();
          return true;
        }
      }

      return false;
    },
    [emailId, onClose, isGeneratingEmail]
  );

  const onEmailSuccess = () => {
    setIsEmailSent(true);
    reloadAccountHistory();
    setFocusedContact(undefined);
    onClose();
  };

  useEffect(() => {
    // reset/initialize content for selected template
    setEmailData(undefined);
    setIsGeneratingEmail(false);
    setIsEmailSent(false);
    setIsAIGenerationComplete(false);
  }, [template.id]);

  return (
    <div className="relative flex h-full flex-col justify-center">
      <div
        className={clsxMerge("h-full overflow-y-scroll transition-opacity", {
          "opacity-10": isGeneratingEmail,
        })}
      >
        <EmailForm
          //  key is used to force entire component to re-render when template changes so that all form fields can be reset
          key={template.id}
          template={template}
          senderEmail={senderEmail}
          attachmentOptions={campaignAttachments}
          subject={emailData?.subject}
          initialBody={emailData?.body}
          onComposeWithAI={handleComposeWithAI}
          onSend={handleEmailSend}
          onQuickSendWithAI={handleQuickSendWithAI}
          isSendingEmail={isSendEmailPending || isSendEmailWithAIPending}
          isSendEmailSuccess={isEmailSent}
          isAIGenerationComplete={isAIGenerationComplete}
        />
      </div>

      {isGeneratingEmail && emailId && (
        <>
          <div className="absolute flex h-full w-full items-center justify-center">
            <AILoader
              title="AI generating email"
              actions={lengthyAILoadingActions}
              lengthyLoadingDelay={30000}
              onLengthyLoading={() => {
                toast.error(
                  "AI is slow, we suggest writing the email this time."
                );
                handleAIEmailGenerationCompleted(null);
              }}
            />
          </div>

          <PollEmailGenerationStatus
            emailId={emailId}
            onComplete={handleAIEmailGenerationCompleted}
            onFailed={() => handleAIEmailGenerationCompleted(null)}
          />
        </>
      )}
    </div>
  );
};
