import {
  WEBSOCKET_CONNECTION_TYPES,
  WEBSOCKET_EVENT_TYPES,
} from "lib/websockets/constants";
import { getWebsocket } from "@/helpers/websockets";

/**
 * SOCKET MESSAGES SEND TYPE ONLY
 */
export const messagingSocketActions = {
  /**
   * The function enterChat attempts to enter a chat session for a given aud_id.
   * It establishes a WebSocket connection using a general connection type.
   * If the WebSocket is open and ready, it sends a message to enter the chat,
   * formatted as a JSON string containing the event type and the aud_id.
   * The function returns true if the message is successfully sent,
   * and false if the WebSocket is not ready.
   *
   * @param aud_id
   * @returns boolean
   */
  enterChat: (aud_id: string) => {
    const ws = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    // On MessagingChat, we try entering chat on page load.
    // It's possible that enterChat() is called before WS is ready.
    if (ws?.readyState === ws?.OPEN) {
      ws?.send(
        JSON.stringify({
          msg_type: WEBSOCKET_EVENT_TYPES.ENTER_CHAT,
          msg_enter_chat: {
            aud_id: aud_id,
          },
        })
      );

      return true;
    }

    return false;
  },

  /**
   * This function sends a chat message via a WebSocket connection.
   * It establishes a WebSocket connection using a general connection type and sends
   * a message formatted as a JSON string. The JSON object includes the event type
   * for a chat message and the content of the message itself.
   *
   * @param message - sring
   */
  sendMessage: (message: string) => {
    const ws = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.CHAT,
        msg_chat: {
          message: message,
        },
      })
    );
  },
};
