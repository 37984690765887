import { DIALER_BG_CLASSES } from "@/components/modules/calling/main-container/backgrounds";
import { clsxMerge } from "shared/lib/helpers";
import { LeadCard } from "./lead-card";
import { LeadAdditionalInfo } from "./additional-info";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const ConnectedLeadSection = () => {
  const callStatus = useDialerCallStatus();
  return (
    callStatus !== DIALER_CALL_STATUS.BEFORE && (
      <div className={clsxMerge("h-[200px] w-full ")}>
        <div
          className={clsxMerge(
            "h-full w-full",
            "flex items-center justify-between",
            DIALER_BG_CLASSES.LIGHT,
            "bg-cover bg-fixed",
            "animate-maxdialerbg"
          )}
        >
          <LeadCard />
          <LeadAdditionalInfo />
        </div>
      </div>
    )
  );
};
