import { checkIfClient } from "./utils";

const getModalCheckboxControl = (modalId: string) => {
  if (!checkIfClient()) {
    return null;
  }

  return document.querySelector<HTMLInputElement>(`#${modalId}`);
};

export const modalHelpers = {
  trigger: (modalId: string) => getModalCheckboxControl(modalId)?.click(),
  open: (modalId: string) => {
    const modalCheckboxControl = getModalCheckboxControl(modalId);

    if (modalCheckboxControl && !modalCheckboxControl.checked) {
      modalCheckboxControl.click();
    }
  },
  lazyOpen: (modalId: string, delayMilliseconds = 100) => {
    // That's a common use case for modals within our app to open modals with a
    // delay
    setTimeout(() => modalHelpers.open(modalId), delayMilliseconds);
  },
  close: (modalId: string) => {
    const modalCheckboxControl = getModalCheckboxControl(modalId);

    if (modalCheckboxControl && modalCheckboxControl.checked) {
      modalCheckboxControl.click();
    }
  },
};
