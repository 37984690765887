import { clsxMerge } from "shared/lib/helpers";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import {
  DIALER_CALL_STATUS,
  DIALER_WIDGET_VARIATIONS,
} from "@/constants/dialer";

import { DialerModule } from "@/components/modules/calling";
import { DialerWidgetCloseButton as CloseButton } from "./buttons/close-button";
import { DialerWidgetExpandButton as ExpandButton } from "./buttons/expand-button";
import { DialerWidgetDragButton as DragButton } from "./buttons/drag-button";
import { CallState } from "./call-state";
import { useDialerWidgetContext } from "@/context/dialer-v2/widget";
import { FreeDnD } from "./free-dnd";
import { useEffect, useMemo, useState } from "react";
import { useOnPageScroll } from "@/hooks/homepage-layout/use-on-page-scroll";
import { HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID } from "@/constants/element-ids";

import throttle from "lodash/throttle";
import { DND_HANDLER_CLASSNAME, PRIMARY_NAV_HIEGHT } from "./constants";
import { dialerWidgetDetectIsScrollbar } from "./utils";

export const DialerWidgetCard = ({
  onChangeCallStatus,
}: {
  onChangeCallStatus?: (
    callStatus?: ValueOfObjectFields<typeof DIALER_CALL_STATUS>
  ) => void;
}) => {
  const [isAnimated, setIsAnimated] = useState(true);
  const { variation, setIsScrollbar } = useDialerWidgetContext();
  const [topOffset, setTopOffset] = useState(PRIMARY_NAV_HIEGHT);

  const handlePageScroll = throttle(() => {
    const container = window.document.querySelector(
      `#${HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID}`
    );

    const newOffset = PRIMARY_NAV_HIEGHT - (container?.scrollTop || 0);

    setTopOffset(newOffset > 0 ? newOffset : 0);
    setIsScrollbar?.(
      dialerWidgetDetectIsScrollbar(`.${DND_HANDLER_CLASSNAME}`)
    );
  }, 300);

  useOnPageScroll(
    handlePageScroll,
    [],
    HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID
  );

  const isSidebar = useMemo(
    () => DIALER_WIDGET_VARIATIONS.SIDEBAR === variation,
    [variation]
  );

  useEffect(() => {
    setIsAnimated(variation === DIALER_WIDGET_VARIATIONS.SIDEBAR);
  }, [variation]);

  useEffect(() => {
    setTimeout(() => {
      setIsScrollbar?.(
        dialerWidgetDetectIsScrollbar(`.${DND_HANDLER_CLASSNAME}`)
      );
    }, 300);
  }, [variation]);

  useEffect(() => {
    setTimeout(() => {
      setIsScrollbar?.(
        dialerWidgetDetectIsScrollbar(`.${DND_HANDLER_CLASSNAME}`)
      );
    }, 2000);
  }, []);

  return (
    <FreeDnD
      handlerName={DND_HANDLER_CLASSNAME}
      disabled={isSidebar}
      position={isSidebar ? { x: 0, y: topOffset } : undefined}
    >
      <div
        className={clsxMerge(
          DND_HANDLER_CLASSNAME,
          "absolute",
          "flex flex-col",
          {
            // We want animations only the call start
            "duration-500 animate-in slide-in-from-left-60": isAnimated,
            "bottom-10 left-10 h-[730px] w-[440px]": !isSidebar,
            "left-0 top-0 w-[440px]": isSidebar,
            "overflow-auto": isSidebar,

            "h-[100vh]": isSidebar && topOffset < PRIMARY_NAV_HIEGHT,
            "h-[calc(100vh-64px)]":
              isSidebar && topOffset >= PRIMARY_NAV_HIEGHT,
          }
        )}
      >
        <DialerModule />

        <CloseButton />
        <ExpandButton />
        {variation === DIALER_WIDGET_VARIATIONS.DEFAULT && <DragButton />}

        <CallState onChange={onChangeCallStatus} />
      </div>
    </FreeDnD>
  );
};
