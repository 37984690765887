export const NotificationsPanelEmpty = () => {
  return (
    <div className="w-full rounded-lg bg-[#FAFAFA] p-6 text-center text-[#999999]">
      <h6 className="brand-typography-h5 mb-2">You’re all caught up</h6>
      <p className="brand-typography-detail2">
        You have no outstanding notifications
      </p>
    </div>
  );
};
