import { ComponentProps, forwardRef } from "react";

type SvgProps = ComponentProps<"svg">;

const CrownIcon = (props: SvgProps, svgRef: any) => (
  <svg
    className="h-6 w-6"
    fill="currentColor"
    viewBox="0 0 10 11"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      d="M9.64003 2.33023C9.34348 2.22898 9.03135 2.33067 8.84432 2.58909L7.45212 4.51758L6.02334 1.24067C5.90645 0.972364 5.65654 0.806284 5.37136 0.806919C5.08617 0.807554 4.83701 0.975216 4.72131 1.24357L3.3076 4.52587L1.90637 2.60501C1.71864 2.34743 1.40605 2.24758 1.10951 2.3497C0.813403 2.45181 0.622414 2.72585 0.623131 3.04766L0.633891 7.87894C0.635995 8.82372 1.38409 9.591 2.30113 9.58896L8.48026 9.57519C9.39731 9.57315 10.142 8.80253 10.1399 7.85776L10.1295 3.02602C10.1288 2.70421 9.93658 2.43103 9.64003 2.33023Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardedCrownIconIcon = forwardRef(CrownIcon);

export default ForwardedCrownIconIcon;
