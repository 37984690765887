import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import { MessagesWebsocketEventI } from "@/interfaces/messaging";

export const messagingSocketEventHandler = {
  receiveMessage: (data: MessagesWebsocketEventI) => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.MESSAGING.MESSAGE_RECEIVE,
      data: data.data,
    });
  },
};
