import { useEffect, useState, useMemo } from "react";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import { useEffectOnce } from "shared/lib/hooks";
import { InfiniteScrollWithObserver } from "shared/ui";

import { HistoryList } from "./history-list";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { AccountHistoryItemI } from "shared/lib/interfaces/account";
import {
  aiEmailGenerationIsCompletedCondition,
  aiIsGeneratingEmailCondition,
} from "@/modules/pipeline/account-details/primary-content/sections/account-history-section/utils";
import { usePollActivityLogs } from "@/modules/pipeline/account-details/primary-content/sections/account-history-section/hooks";

export const AccountHistorySection = () => {
  const {
    accountHistoryData: {
      data: accountHistoryRaw,
      loadMore: loadMoreAccountHistory,
      reloadData: reloadAccountHistory,
      isReachedEnd: isReachAccountHistoryEnd,
      isLoading: isLoadingAccountHistory,
    },
  } = useAccountDetailsContext();

  const [accountHistory, setAccountHistory] =
    useState<AccountHistoryItemI[]>(accountHistoryRaw);

  useEffect(() => {
    setAccountHistory(accountHistoryRaw);
  }, [accountHistoryRaw]);

  const logsToPoll = useMemo(() => {
    if (!accountHistory || accountHistory.length === 0) {
      return [];
    }

    return accountHistory
      .filter(aiIsGeneratingEmailCondition)
      .map((activity) => ({
        activityLogId: activity.id,
        until: aiEmailGenerationIsCompletedCondition,
      }));
  }, [accountHistory]);

  usePollActivityLogs({ config: logsToPoll, reducer: setAccountHistory });

  useEffectOnce(() => {
    reloadAccountHistory();
  });

  // Need to reload account history if user adds a note from the widget dialer
  useEffect(() => {
    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
      reloadAccountHistory
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
        reloadAccountHistory
      );
    };
  }, []);

  return (
    <section>
      <InfiniteScrollWithObserver
        isLoading={isLoadingAccountHistory}
        isMore={!isReachAccountHistoryEnd}
        onMore={loadMoreAccountHistory}
        isDefaultMessageHidden={accountHistory.length === 0}
      >
        <HistoryList
          isLoading={isLoadingAccountHistory}
          historyData={accountHistory}
        />
      </InfiniteScrollWithObserver>
    </section>
  );
};
