import { FC, cloneElement, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperI } from "swiper/types";
import "swiper/css";

import { clsxMerge } from "shared/lib/helpers";
import { SidebarLayout } from "shared/ui/sidebarV2/layout";
import { BackgroundBlur } from "shared/ui/sidebarV2/components/background-blur";

interface SidebarV2I {
  isOpen: boolean;
  onClose?: () => void;

  children: any;
  activeStepIndex?: number;
  onChangeStep?: (sidebarRef: SwiperI) => void;

  isFullPage?: boolean;
  allowPageInteraction?: boolean; //if we want to keep the background page useable while sidebar is open
  containerClassName?: string;
  backgroundBlurClassName?: string;
}

// NOTE: Parent component using this <SidebarV2/> component is responsible for maintaining the activeSlideIndex and the open/close states.
// This component only receives it.
// Try using hook `useStepsManager()` to main activeSlideIndex and use hook `useDisclosure()` to maintain open/close states.

const SidebarV2: FC<SidebarV2I> = ({
  isOpen: isOpenProp,
  onClose = () => {},

  children,
  activeStepIndex = 0,
  onChangeStep = () => {},

  isFullPage = true,
  allowPageInteraction,

  containerClassName,
  backgroundBlurClassName,
}) => {
  const sidebarContentRef = useRef<SwiperI>();
  const [isOpenState, setIsOpenState] = useState(false);
  const [visible, setVisible] = useState(false);

  const initializeSwiper = (swiper: SwiperI) => {
    sidebarContentRef.current = swiper;
  };

  const handleChangeStep = () => {
    if (sidebarContentRef.current) {
      onChangeStep(sidebarContentRef.current);
    }
  };

  const jumpToSlide = (targetIndex: number) => {
    sidebarContentRef.current?.slideTo(targetIndex);
  };

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  useEffect(() => {
    jumpToSlide(activeStepIndex);
  }, [activeStepIndex]);

  useEffect(() => {
    if (isOpenProp) {
      setIsOpenState(true);
      setTimeout(() => {
        setVisible(true);
        //jump to activeStepIndex incase activeStepIndex was not reset in parent prop after the sidebar was last closed
        jumpToSlide(activeStepIndex);
      }, 100);
    } else {
      setVisible(false);
      // give visiblity animation time to complete before removing component from DOM
      setTimeout(() => setIsOpenState(false), 500);
    }
  }, [isOpenProp]);

  return isOpenState ? (
    <SidebarLayout
      isVisible={visible}
      allowPageInteraction={allowPageInteraction}
      isFullPage={isFullPage}
      onClickOverlay={handleClose}
    >
      <SidebarLayout.Drawer className={containerClassName} isVisible={visible}>
        <Swiper
          className="swiper-no-swiping h-full w-full"
          onSwiper={initializeSwiper}
          onSlideChange={handleChangeStep}
          effect="fade"
          speed={10}
        >
          {/* NOTE: the below `onClose` will only be passed to the child if the child accepts an `onClose` prop */}
          {Array.isArray(children) ? (
            children.map((child: any, i: number) => (
              <SwiperSlide
                key={i}
                className={clsxMerge("w-fit", {
                  hidden: activeStepIndex !== i,
                })}
              >
                {() => {
                  return cloneElement(child, { onClose: handleClose });
                }}
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide className="w-fit">
              {cloneElement(children, { onClose: handleClose })}
            </SwiperSlide>
          )}
        </Swiper>

        <BackgroundBlur
          showBlur={allowPageInteraction}
          className={backgroundBlurClassName}
        />
      </SidebarLayout.Drawer>
    </SidebarLayout>
  ) : null;
};

export default SidebarV2;
