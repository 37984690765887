import { BookMeetingResponseI, glencocoClientAPI } from "@/api/glencoco";
import { useRef, useState } from "react";
import clsx from "clsx";

import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { DATE_FORMAT } from "shared/lib/constants/time";

import LeadDesciprion from "@/components/modules/calling/cards/lead-card/v1/lead-description";
import { safeGetDate } from "shared/lib/helpers";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { CampaignI } from "@/interfaces/campaign";
import { Sidebar } from "shared/ui";
import SidebarContent from "./sidebar-content";
import { handleErrorMessage } from "@/helpers/error";

import { getGlobalOpenModals, setGlobalOpenModals } from "@/components/modals";

import remove from "lodash/remove";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const CALENDLY_MODAL_ID = "calendly-modal";

interface CalendlyModalI {
  calendlyUri: string;
  callId: string;
  contact: CallContactI;
  account: CallAccountI;
  campaign: CampaignI;
  modalId?: string;
  onSuccess?: (event: CalendlyEventI) => void;
}

/**
 * NOTE - is widely used but onSuccess is handled differently depending on the context
 * @param props
 * @param props.calendlyUri
 * @param props.callId - callingContext.call.customParameters.get("call_id")
 * @param props.campaign
 * @param props.contact
 * @param props.account
 * @param props.modalId - use new modalId to avoid potential bugs
 * @param props.onSuccess - In the context of Dialer on success should invoke useCalendly().handleCalendlyBookingSuccess(calendlyEvent: CalendlyEventI)
 */
const CalendlyModal = ({
  calendlyUri,
  callId,
  campaign,
  contact,
  account,
  modalId = CALENDLY_MODAL_ID,
  onSuccess = () => {},
}: CalendlyModalI) => {
  const campaignId = campaign?.id;

  const [isVisible, setIsVisible] = useState(true);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      (async () => {
        const isOpen = getGlobalOpenModals()?.includes(modalId);

        //NOTE: multiple Calendly modals can be present in the same time
        //     current usecase imply that only one should be actively reacting
        //     to the webhook
        if (!isOpen) {
          return;
        }

        const API = glencocoClientAPI();

        const payload = e.data.payload;
        const eventUri = payload?.event?.uri;

        const BookMeetingResponse = await API.bookMeeting(
          campaignId as string,
          callId,
          eventUri
        ).catch((e) => e);

        if (BookMeetingResponse?.status === 200) {
          const bookedMeeting =
            BookMeetingResponse.data as BookMeetingResponseI;

          onSuccess(bookedMeeting?.event);

          checkboxref.current.click();
          toast.success(
            `Meeting has been booked with ${
              contact?.first_name
            } for ${safeGetDate(bookedMeeting.event.start_time, DATE_FORMAT)}
            `
          );
        } else {
          handleErrorMessage(
            BookMeetingResponse?.response?.data?.error_code,
            "Failed to book a meeting"
          );
        }
      })();
    },
  });

  const onFailedBooking = () => {
    toast.error("Failed to book a meeting due to internal error.");
  };

  const handleCheckboxClick = (event: any) => {
    const isOpen = (event.target as HTMLInputElement)?.checked;
    setIsVisible(isOpen);

    const openModals = getGlobalOpenModals();

    if (isOpen) {
      !openModals?.includes(modalId) && openModals.push(modalId);
    } else {
      openModals?.includes(modalId) &&
        remove(openModals, (id: string) => id === modalId);
    }

    setGlobalOpenModals(openModals);
  };

  return (
    <>
      <div className="w-[calc(100vw-320px)]">
        <input
          type="checkbox"
          id={modalId}
          className="modal-toggle"
          ref={checkboxref}
          onClick={handleCheckboxClick}
        />

        <div className="calendly-modal modal w-[calc(100vw-320px)]">
          {isVisible && calendlyUri ? (
            <div className="relative z-[1] h-full w-full max-w-[1000px] overflow-auto rounded-md border-none bg-transparent shadow-none scrollbar-none">
              <label
                htmlFor={modalId}
                className="btn btn-circle btn-ghost btn-sm absolute left-6 top-4 text-[20px] text-white/90"
              >
                ✕
              </label>

              <InlineWidget
                url={calendlyUri}
                prefill={{
                  name: `${contact?.first_name} ${contact?.last_name}`,
                  email: contact?.email,
                }}
                styles={{
                  width: "100%",
                  height: "700px",
                  zIndex: "2",
                }}
              />
            </div>
          ) : (
            <div className="modal-box relative min-w-[520px] rounded-md p-6">
              <h3 className="ae-typography-h3 mb-2">
                Unfortunately we failed to provide you with Calendar link
              </h3>

              <p className="ae-typography-detail1 mb-6 text-base-content/60">
                Please{" "}
                <a
                  href="mailto:help@glencoco.io"
                  target="_blank"
                  rel="noreferrer"
                  className="ae-link"
                >
                  contact Glencoco
                </a>{" "}
                with the following data
              </p>

              <div className={clsx("rounded-lg bg-black/5 px-3 py-6")}>
                <div className="mb-8">
                  <label className="ae-typography-h2 mb-1 flex items-center">
                    <span className="mr-2 flex items-center">
                      {contact?.first_name} {contact?.last_name}
                    </span>
                  </label>

                  <p className="ae-typography-body3 text-black/60">
                    <span>
                      {contact?.title} @ {account?.name}
                    </span>
                  </p>
                </div>

                <LeadDesciprion />
              </div>

              <div className="mt-8 flex justify-end">
                <label
                  htmlFor={modalId}
                  className="btn-ae-default"
                  onClick={onFailedBooking}
                >
                  Understood
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {isVisible && calendlyUri && checkboxref?.current?.checked && (
        <Sidebar hideCloseIcon>
          {contact?.id ? (
            <SidebarContent account={account} contact={contact} />
          ) : (
            <div className="flex h-full w-full items-center justify-center" />
          )}
        </Sidebar>
      )}
    </>
  );
};

export default CalendlyModal;
