import { clsxMerge } from "shared/lib/helpers";

export const BackgroundBlur = ({
  showBlur,
  className,
}: {
  showBlur?: boolean;
  className?: string;
}) => (
  <div
    className={clsxMerge(
      "absolute left-0 top-0 -z-10 h-full w-full bg-[#F2F2F2]",
      {
        "backdrop-blur-lg": showBlur,
      },
      className
    )}
  />
);
