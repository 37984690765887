import { AxiosInstance } from "axios";

import {
  AccountI,
  AccountStatusI,
  ContactDetailI,
} from "shared/lib/interfaces/account";
import {
  MessagingChatMember,
  MessagingChatMessageHistoricI,
} from "@/interfaces/messaging";
import { LastOnlineStatusI } from "shared/lib/interfaces/chat";
import { DefaultResponseI } from "@/api/glencoco";

export interface ChatInboxItemI {
  account?: AccountI;
  aud_id?: string;
  campaign_id?: string;
  contact?: ContactDetailI;
  customer?: {
    first_name: string;
    last_name: string;
    id: string;
    profile_pic_url: string;
  };
  last_message?: string;
  last_status_major?: AccountStatusI;
  last_status_minor?: AccountStatusI;
  notification_count: number;
  timestamp?: string;
}

export interface GetChatInboxResponseI {
  chat_inbox: Array<ChatInboxItemI>;
  next_token?: string;
}

export interface GetMessagingChatHistoryResponseI {
  customers_in_chat?: Array<any>;
  callers_in_chat?: Array<MessagingChatMember>;
  chat_history?: Array<MessagingChatMessageHistoricI>;
  next_token?: string;
}

export interface GetChatInboxOnlineStatusesResponseI extends DefaultResponseI {
  online_statuses: LastOnlineStatusI[];
}

export interface GetMessagesUnreadCountResponseI {
  message_count: number;
}

export const ChatsAPIEndpoints = (API: AxiosInstance) => ({
  getChatInbox: (
    nextToken: string | undefined,
    searchTerm: string | undefined
  ) =>
    API.post<GetChatInboxResponseI>("/v1/chat_inbox", {
      next_token: nextToken,
      search_term: searchTerm,
    }),

  getMessagingChatHistory: (
    audId: string,
    params: {
      next_token?: string;
    }
  ) =>
    API.post<GetMessagingChatHistoryResponseI>(
      `/v1/aud/${audId}/chat_history`,
      { ...params }
    ),

  getChatInboxOnlineStatuses: (userIds: string[]) =>
    API.post<GetChatInboxOnlineStatusesResponseI>("/v1/online_statuses", {
      ids: userIds,
    }),

  getMessagesUnreadCount: (audId: string) =>
    API.get<GetMessagesUnreadCountResponseI>(
      `/v1/aud/${audId}/notification_count`
    ),
});
