import { CAMPAIGN_USER_STATUS } from "shared/lib/constants/campaign";
import { CampaignUserStatusI } from "shared/lib/interfaces/campaign";

export const CampaignCardActionsHorizontal = ({
  campaignUserStatus,
  startCallingUrl,
  assessmentUrl,
  onClickTraining,
  salesFloorUrl,
}: {
  campaignUserStatus?: CampaignUserStatusI;
  startCallingUrl?: string;
  assessmentUrl?: string;
  onClickTraining?: () => Promise<void> | undefined;
  salesFloorUrl?: string;
}) => {
  const handleTraining = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickTraining?.();
  };

  return (
    <>
      {CAMPAIGN_USER_STATUS.TRAINING_IN_PROGRESS === campaignUserStatus && (
        <div className="flex items-center justify-between">
          <div></div>

          <div className="flex gap-3">
            <button
              className="btn-ae-default min-w-[190px]"
              onClick={handleTraining}
            >
              Continue Training
            </button>
          </div>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.TRAINING_SUBMITTED === campaignUserStatus && (
        <div className="flex items-center justify-between">
          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTraining}
            >
              Review Training
            </button>
          </div>

          <div className="flex gap-3">
            <a
              href={assessmentUrl}
              target="_blank"
              rel="noreferrer"
              className="btn-ae-default min-w-[190px]"
              onClick={(e) => e.stopPropagation()}
            >
              Start Audition
            </a>
          </div>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.AUDITION_IN_PROGRESS === campaignUserStatus && (
        <div className="flex items-center justify-between">
          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTraining}
            >
              Review Training
            </button>
          </div>

          <div className="flex gap-3">
            <a
              href={assessmentUrl}
              target="_blank"
              rel="noreferrer"
              className="btn-ae-default min-w-[190px]"
              onClick={(e) => e.stopPropagation()}
            >
              Continue Audition
            </a>
          </div>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.AUDITION_IN_REVIEW === campaignUserStatus && <></>}

      {CAMPAIGN_USER_STATUS.AUDITION_RETRY === campaignUserStatus && (
        <div className="flex items-center justify-between">
          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTraining}
            >
              Review Training
            </button>
          </div>

          <div className="flex gap-3">
            <a
              href={assessmentUrl}
              target="_blank"
              rel="noreferrer"
              className="btn-ae-default min-w-[190px]"
              onClick={(e) => e.stopPropagation()}
            >
              Retry Audition
            </a>
          </div>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.AUDITION_REJECTED === campaignUserStatus && <></>}

      {CAMPAIGN_USER_STATUS.AUDITION_APPROVED === campaignUserStatus && (
        <div className="flex items-center justify-between">
          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTraining}
            >
              Review Training
            </button>
          </div>

          <div className="flex gap-3">
            <a href={salesFloorUrl} className="btn-nofill min-w-[190px]">
              Go To Sales Floor
            </a>

            <a
              href={startCallingUrl}
              className="btn-ae-default min-w-[190px]"
              onClick={(e) => e.stopPropagation()}
            >
              Start Calling
            </a>
          </div>
        </div>
      )}
    </>
  );
};
