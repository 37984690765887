import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { checkIfClient } from "shared/lib/helpers";

import { websocketEvents } from "./events";
import { WEBSOCKET_CONNECTION_TYPES, WEBSOCKET_EVENT_TYPES } from "./constants";
import { getWebsocket } from "@/helpers/websockets";
import { getAuthToken } from "lib/auth/token";
import { dispatchCustomEvent } from "@/helpers/events";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";

const establishWSConnection = () => {
  console.log(`Try establish socket connection`);
  const ws = new WebSocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

  return ws;
};

const closeWSGeneralConnection = () => {
  const ws = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

  if (ws?.readyState === WebSocket.OPEN) {
    ws.close();
    return true;
  } else {
    // Websocket is not open, or already closed.
    return false;
  }
};

const wsAuth = (ws: WebSocket) => {
  const authToken = getAuthToken();

  ws.send(
    JSON.stringify({
      msg_type: WEBSOCKET_EVENT_TYPES.AUTH,
      msg_auth_token: {
        auth_token: authToken,
      },
    })
  );
};

const setupSocketEventHandlers = (ws: WebSocket) => {
  ws.onopen = () => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.AUTH_REQUESTED,
    });

    wsAuth(ws);
  };

  ws.onclose = (event) => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      data: { event },
    });
  };

  ws.onerror = (err) =>
    dd.rum.error(`${ERROR_CATEGORIES.WS} - ws on error event`, {
      data: { error: err },
    });

  ws.onmessage = (msg) => {
    try {
      const data = JSON.parse(msg.data as string);

      const eventType =
        data?.error_code || data?.error_str
          ? WEBSOCKET_EVENT_TYPES.ERROR
          : data?.type;

      websocketEvents({ data, eventType });

      return false;
    } catch (e) {
      dd.error(`${ERROR_CATEGORIES.WS} - failed parsing ws message`, {
        data: { msg, e },
      });
    }
  };
};

const initWebSockets = () => {
  const currentWs = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

  if (
    !currentWs ||
    [WebSocket.CLOSING as number, WebSocket.CLOSED].includes(
      currentWs.readyState
    )
  ) {
    // only create new ws if old one is closed or in process of closing
    const ws = establishWSConnection();
    setupSocketEventHandlers(ws);
    (<any>window).WSInstance = ws;

    return ws;
  }
};

export const websocketListener = () => {
  let removeEventListeners = () => {};

  if (process.env.NEXT_PUBLIC_SOCKET_DOMAIN && checkIfClient()) {
    initWebSockets();

    setInterval(() => {
      if ((<any>window).WSInstance?.readyState === 1) {
        try {
          // console.log(`Websoket PING`);
          (<any>window).WSInstance?.send(JSON.stringify({ message: "ping" }));
        } catch {
          console.log("Failed to send websoket ping");
        }
      }
    }, 10000);

    /**
     *  don't bother trying to reconnect if client is offline
     *  this floods the client with numerous websocket attempts
     */
    const eventHandlerWSClosed = () => {
      if (window.navigator.onLine) {
        initWebSockets();
      }
    };

    /**
     * setup websocket connection again when client comes back online after having lost connection.
     */
    const eventHandlerWindowOnline = () => {
      initWebSockets();
    };

    /**
     * Close existing websocket connection so that current uses of it in the app don't continue trying to use it.
     */
    const eventHandlerWindowOffline = () => {
      closeWSGeneralConnection();
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      eventHandlerWSClosed
    );
    window.addEventListener("online", eventHandlerWindowOnline);
    window.addEventListener("offline", eventHandlerWindowOffline);

    removeEventListeners = () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
        eventHandlerWSClosed
      );
      window.removeEventListener("online", eventHandlerWindowOnline);
      window.removeEventListener("offline", eventHandlerWindowOffline);
    };
  }

  return removeEventListeners;
};
