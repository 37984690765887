import { DIALER_MODES, DIALER_STATES } from "@/constants/dialer";

export const DIALER_BG_CLASSES = {
  LIGHT: `bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-1-medium.png')]`,
  MEDIUM_LIGHT: `bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-5-medium.png')]`,
  MEDIUM: `bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-2-medium.png')]`,
  DARK: `bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')]`,
  EXRTRA_DARK: `bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')]`,
} as const;

export const DEFAULT_BG = {
  [DIALER_STATES.PRE]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-1-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.LEAD_SELECTION]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-2-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.DIALING]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-2-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.CALL]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.LOADING]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.UNAUTHORIZED]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.POST]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.DISPOSITION]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
};

export const CATCHUP_BG = {
  [DIALER_STATES.PRE]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-5-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.LEAD_SELECTION]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-5-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.DIALING]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-2-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.CALL]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.LOADING]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.UNAUTHORIZED]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.POST]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
  [DIALER_STATES.DISPOSITION]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-fixed bg-center bg-no-repeat",
};

export const WIDGET_BG = {
  [DIALER_STATES.PRE]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-1-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.LEAD_SELECTION]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-2-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.DIALING]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-2-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.CALL]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.LOADING]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-3-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.UNAUTHORIZED]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.POST]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-center bg-no-repeat",
  [DIALER_STATES.DISPOSITION]:
    "bg-[url('https://glencoco-assets.s3.amazonaws.com/platform/calling/calling-bg-4-medium.png')] bg-cover bg-center bg-no-repeat",
};

export const BGs = {
  [DIALER_MODES.DEFAULT as string]: DEFAULT_BG,
  [DIALER_MODES.WIDGET_MINI_DIALER as string]: WIDGET_BG,
  [DIALER_MODES.DEMO as string]: DEFAULT_BG,
  [DIALER_MODES.CATCHUP]: CATCHUP_BG,
  [DIALER_MODES.CATCHUP_WALKTHROUGH]: CATCHUP_BG,
} as const;
