import { useRef, useState } from "react";
import clsx from "clsx";

export const QUALIFICATION_MODAL_ID = "qualification-modal";

import UICheckbox from "shared/ui/ae-user-input/checkbox";
import {
  CampaignI,
  SelectableQualificationCriteriaI,
} from "@/interfaces/campaign";
import { CallAccountI } from "@/interfaces/dialer";

const Checkbox = UICheckbox();

interface QualificationModalI {
  modalId?: string;
  campaign?: CampaignI;
  account?: CallAccountI;
  onSuccess?: (
    selectedCriterias?: Array<SelectableQualificationCriteriaI>
  ) => void;
}

const QualificationModal = ({
  modalId = QUALIFICATION_MODAL_ID,
  campaign,
  account,
  onSuccess = () => {},
}: QualificationModalI) => {
  const { qualification_criterias } = campaign || {};

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [checked, setChecked] = useState(
    new Array(qualification_criterias?.length || 0).fill(false)
  );
  const [skipCounter, setSkipCounter] = useState(0);

  const close = () => {
    (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
  };

  const cleanup = () => {
    setChecked(new Array(qualification_criterias?.length || 0).fill(false));
    setSkipCounter(0);
  };

  const handleCheckbox = (index: number) => () => {
    setChecked(checked.map((check, i) => (i === index ? !check : check)));
  };

  const handleSkip = () => {
    setSkipCounter(skipCounter + 1);
  };

  const submitHandler = () => {
    const selectedCriterias = qualification_criterias?.map((qc, i) => ({
      id: qc.id,
      is_checked: checked[i],
    }));

    onSuccess(selectedCriterias || []);
    cleanup();
    close();
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={cleanup}
      />

      <div className="qualification-criteria-modal modal">
        <div className="modal-box relative min-w-[570px] rounded-md p-6">
          <label
            htmlFor={modalId}
            className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 text-black/40"
            onClick={cleanup}
          >
            ✕
          </label>

          <div>
            <h3 className="ae-typography-h3 mb-2">
              Is {account?.name} qualified?
            </h3>

            <p className="ae-typography-detail1 mb-6 text-base-content/60">
              Select the questions that are true - note that you may not be paid
              if the criteria are not met
            </p>

            <div>
              <ul>
                {qualification_criterias?.map((qc, i) => (
                  <li className="mb-6" key={i}>
                    <div className="flex items-center">
                      <div>
                        <Checkbox
                          id={`checkbox-${i}`}
                          name={`checkbox-${i}`}
                          inputProps={{
                            onChange: handleCheckbox(i),
                            checked: checked[i] || false,
                          }}
                        />
                      </div>
                      <label
                        className="ae-typography-body2"
                        htmlFor={`checkbox-${i}`}
                      >
                        {qc.question}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex items-center justify-between">
              <div
                className={clsx(
                  "ae-typography-detail1 pr-6 text-error-content",
                  "transition-opacity duration-300",
                  {
                    "opacity-0": skipCounter === 0 || checked.every((c) => c),
                    "opacity-100": skipCounter > 0 && checked.some((c) => !c),
                  }
                )}
              >
                Are you sure? You may not be paid if all qualification criteria
                are not met
              </div>
              <div className="flex justify-end">
                <button
                  className={clsx(
                    "btn-ae-text mr-2",
                    "transition-opacity duration-300",
                    {
                      "opacity-0": checked.every((c) => c),
                      "opacity-100": checked.some((c) => !c),
                    }
                  )}
                  onClick={skipCounter > 0 ? submitHandler : handleSkip}
                >
                  Skip {skipCounter > 0 && "anyways"}
                </button>
                <button
                  className={clsx("btn-ae-default w-36", {
                    disabled: checked.some((c) => !c),
                  })}
                  onClick={submitHandler}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualificationModal;
