import { ChevronLeftIcon, CloseIcon } from "shared/ui/icons";
import { clsxMerge } from "shared/lib/helpers/styles";

/**
 *
 * @param id
 * @returns
 */
export const SidebarCloseButton = ({
  id,
  isParentSidebar,
  hideCloseIcon,
  className,

  onClick,
}: {
  id?: string;
  isParentSidebar?: boolean;
  hideCloseIcon?: boolean;
  className?: string;

  onClick?: () => void;
}) => {
  return isParentSidebar ? (
    !hideCloseIcon ? (
      <button
        id={id}
        className={clsxMerge("btn-ghost btn-sm btn-circle btn", className)}
        onClick={onClick}
      >
        <CloseIcon className="h-4 w-4 text-black/60" />
      </button>
    ) : null
  ) : (
    <button className="btn-ghost btn-sm btn-circle btn" onClick={onClick}>
      <ChevronLeftIcon className="h-4 w-4 text-black/60" />
    </button>
  );
};
