import { FC } from "react";
import clsx from "clsx";
import find from "lodash/find";
import { INDUSTRIES, INDUSTRIES_MAP } from "shared/lib/constants/industry";
import { IndustriesI } from "shared/lib/interfaces/industry";

export interface SalesResumeIndustryPropsI {
  className?: string;
  industries: IndustriesI | null;
  isInternal?: boolean;
}

const SalesResumeIndustry: FC<SalesResumeIndustryPropsI> = ({
  className,
  industries,
  isInternal = true,
}) => {
  const industriesToDisplay = (industries || []).filter(
    (industry) => industry !== INDUSTRIES_MAP.NONE
  );

  if (!isInternal && industriesToDisplay.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(
        "align-center flex flex-col rounded-[20px] bg-white px-6 py-[30px]",
        className
      )}
    >
      <div className="w-full">
        <span className="text-[18px] font-bold leading-none text-black">
          Industry
        </span>
      </div>

      {industriesToDisplay.length > 0 ? (
        <div
          className={clsx(
            "grid gap-x-[100px] sm:grid-cols-3 sm:grid-rows-[repeat(3,_min-content)]",
            "grid-rows-9",
            "mt-4"
          )}
        >
          {industriesToDisplay.map((industry) => (
            <span
              key={industry}
              className="text-[14px] leading-tight text-black"
            >
              <span className="mr-2 text-[18px] font-bold">•</span>
              {find(INDUSTRIES, ["value", industry])?.label}
            </span>
          ))}
        </div>
      ) : (
        <span className="mt-3 text-[14px] text-black">
          Add your industries to increase your visibility to companies, AE’s and
          more.
        </span>
      )}
    </div>
  );
};

export default SalesResumeIndustry;
