import { meetingsToastEmitter } from "@/components/shared/custom-meetings-toaster";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import { LocalStorage } from "@/helpers/local-storage";
import { CustomMeetingsToastI } from "@/interfaces/custom-toasts/meetings";
import { messagingSocketEventHandler } from "./messaging/event-handlers";
import { dialerListSocketEventHandler } from "./dialer/event-handlers";
import { handleError } from "./error";

const MAX_MEETING_TOASTS = 15;

export const socketEventHandlers = {
  websocket: {
    authSuccess: () => {
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
      });
    },

    handleError,
  },
  globalToasts: {
    meetings: (data: CustomMeetingsToastI) => {
      const LS = new LocalStorage();
      const toasts = LS.customMeetingsToasts || [];

      if (toasts.length > MAX_MEETING_TOASTS) {
        toasts.shift();
      }

      const updatedToasts = [...toasts, data];
      LS.customMeetingsToasts = updatedToasts;

      meetingsToastEmitter.show();
    },
  },
  notifications: {
    /**
     * NOTE: This might be updated in the future
     *       for now it only sets triggers window
     *       event
     */
    update: () => {
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.WEBSOCKETS.NOTIFICATIONS.NEW,
      });
    },
  },
  messaging: {
    ...messagingSocketEventHandler,
  },
  dialer: {
    list: {
      ...dialerListSocketEventHandler,
    },
  },
};
