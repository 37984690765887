import { useEffect } from "react";

import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

import { AccountDetailsNavigation as Navigation } from "./navigation";
import { AccountHistorySection } from "./sections/account-history-section";
import { AccountMessagesSection } from "./sections/messages-section";

import { AccountSummarySection } from "./sections/account-summary-section";
import { DispositionSection } from "./sections/disposition-section";
import { AccountDetailsV2NoteEditor } from "./sections/note-editor-section";

import { useAccountDetailsContext } from "../context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { ADPrimaryContentProvider } from "./context";
import {
  ACCOUNT_DETAILS_TABS,
  ACCOUNT_DISPOSITION_STATUSES,
} from "shared/lib/constants/account";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

type TabI = ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;

export const PrimaryContent = () => {
  const { tab, setTab, accountStatus } = useAccountDetailsContext();
  const { clear: clearCallContext } = useInCallContext();
  const callStatus = useDialerCallStatus();

  const handleTabChange = (tabId?: string) => {
    void setTab(tabId as TabI);
  };

  /**
   * Note
   * Do not require disposition if meeting is already booked
   */
  useEffect(() => {
    if (
      callStatus === DIALER_CALL_STATUS.AFTER &&
      accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED
    ) {
      clearCallContext();
    }
  }, [callStatus, accountStatus]);

  useEffect(() => {
    return () => {
      void setTab(ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY);
    };
  }, []);

  return (
    <ADPrimaryContentProvider>
      <div className="px-2 pb-40 xl:px-12">
        <div className="mx-auto max-w-[1100px]">
          <AccountSummarySection className="mt-3" />

          {DIALER_CALL_STATUS.AFTER === callStatus && <DispositionSection />}

          {DIALER_CALL_STATUS.AFTER !== callStatus && (
            <AccountDetailsV2NoteEditor className="mt-5" />
          )}

          <Navigation selected={tab} onChange={handleTabChange} />

          {tab === ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY && (
            <AccountHistorySection />
          )}

          {tab === ACCOUNT_DETAILS_TABS.MESSAGES && <AccountMessagesSection />}
        </div>
      </div>
    </ADPrimaryContentProvider>
  );
};
