import { useEffect, useState, JSX, FC } from "react";
import clsx from "clsx";
import { Checkbox } from "shared/ui/ae-user-input";
import { ControlledDropdown } from "shared/ui";

import { isArrayEqual } from "shared/lib/helpers/utils";

const EnhancedCheckbox = Checkbox();

export interface FilterItemI {
  key: string;
  item: any;
}

export interface FilterDropdownPropsI {
  values?: FilterItemI[];
  className?: string;
  listClassName?: string;
  label?: JSX.Element | string;
  filtersListData?: FilterItemI[];
  onFilterChange?: (selectedData: FilterItemI[]) => void;
  disabled?: boolean;
}

const FilterDropdown: FC<FilterDropdownPropsI> = ({
  values,
  className,
  listClassName,
  label = "",
  filtersListData,
  onFilterChange = () => {},
  disabled = false,
}) => {
  const [filtersList, setFiltersList] = useState<FilterItemI[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<FilterItemI[]>(
    values || []
  );

  const handleChange = (filter: FilterItemI) => {
    if (selectedFilters.map((f) => f.key)?.includes(filter.key)) {
      const updatedFilters = selectedFilters.filter(
        (f) => f.key !== filter.key
      );

      setSelectedFilters([...updatedFilters]);
    } else {
      setSelectedFilters([...(selectedFilters || []), filter]);
    }
  };

  const selectAllFilters = () => {
    setSelectedFilters([...filtersList]);
  };

  const clearAllFilters = () => {
    setSelectedFilters([]);
  };

  useEffect(() => {
    if (filtersListData) {
      setFiltersList(filtersListData);
    }
  }, [filtersListData]);

  useEffect(() => {
    if (selectedFilters) {
      onFilterChange(selectedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setSelectedFilters((selectedFilters) => {
      return isArrayEqual(selectedFilters, values as FilterItemI[])
        ? selectedFilters
        : values || [];
    });
  }, [values]);

  return (
    <div className={clsx("cursor-pointer", className)}>
      {/* prettier-ignore */}
      <ControlledDropdown className="dropdown-end">
        {() => (
          <>
            <label
              tabIndex={1}
              className="btn-ae btn-ae-neutral mr-1 text-black/40 flex-nowrap text-nowrap"
            >
              <span className="ae-typography-detail1 mr-1">{label}:</span>

              <span className="ae-typography-detail1 text-black/80">
                {selectedFilters?.length
                  ? selectedFilters.length > 1
                    ? "Multiple"
                    : selectedFilters[0].item
                  : "All"}
              </span>
            </label>

            <div
              tabIndex={1}
              className={clsx("dropdown-content menu bg-base-100 w-[240px] rounded-lg p-4 shadow-lg")}
            >
              <div className="mb-3 flex justify-between">
                <button
                  className="ae-link ae-typography-body2"
                  onClick={selectAllFilters}
                >
                  Select all
                </button>
                <button
                  className="ae-link ae-typography-body2"
                  onClick={clearAllFilters}
                >
                  Clear all
                </button>
              </div>

              <ul className={clsx("list-none", listClassName)}>
                {filtersList?.map((filter, i) => (
                  <li key={i} className="hover:bg-transparent">
                    <label className={clsx("label w-fit cursor-pointer hover:bg-transparent px-1 py-2", {
                      "pointer-events-none opacity-50": disabled,
                    })}>
                      <EnhancedCheckbox
                        name={filter.key}
                        inputProps={{
                          checked: selectedFilters?.some(_filter => filter.key === _filter.key) || false,
                          onChange: () => handleChange(filter),
                        }}
                      />
                      <span className="-ml-2.5 ae-typography-body2">{filter.item}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </ControlledDropdown>
    </div>
  );
};

export default FilterDropdown;
