import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge, formatUSPhoneNumber } from "shared/lib/helpers";

import { CopyToClipboard } from "shared/ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

export const CurrentCallerLabel = ({
  className,
  optionalTextClassName,
}: {
  className?: string;
  optionalTextClassName?: string;
}) => {
  const { caller_phone } = useInCallContext();

  return (
    <Tooltip placement="bottom">
      <TooltipTrigger className={className}>
        <CopyToClipboard
          textToCopy={formatUSPhoneNumber(caller_phone)}
          className={clsxMerge(
            "cursor-pointer",
            "rounded-xl bg-black/50",
            "flex items-center px-2 py-2",
            "scale-100 active:scale-90",

            "transition-transform duration-500"
          )}
        >
          <span className="brand-typography-body2 whitespace-nowrap text-white">
            <span className={clsxMerge("mr-1", optionalTextClassName)}>
              Your # is
            </span>

            <span>{formatUSPhoneNumber(caller_phone)}</span>
          </span>
        </CopyToClipboard>
      </TooltipTrigger>
      <TooltipContent className="z-[100]">
        <div className="brand-typography-body2  text-white">Click to Copy</div>
      </TooltipContent>
    </Tooltip>
  );
};
