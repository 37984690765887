import clsx from "clsx";
import { ChangeEvent, useRef, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";

export const MessageInput = ({
  onSubmit = () => {},
  textInputClassName,
  btnClassName,
  disabled,
}: {
  onSubmit?: (messageText: string, cleanUp: () => void) => void;
  textInputClassName?: string;
  btnClassName?: string;
  disabled?: boolean;
}) => {
  const [text, setText] = useState<string>("");
  const inputRef = useRef<HTMLParagraphElement>(null);

  const handleChange = (ev: ChangeEvent<HTMLParagraphElement>) => {
    const value = ev?.target?.innerText;
    setText(value);
  };

  const cleanUp = () => {
    setText("");

    if (inputRef?.current) {
      inputRef.current.innerHTML = "";
    }
  };

  return (
    <div
      className={clsxMerge(
        "flex cursor-text items-center gap-2 rounded-xl border-2 p-3 pl-5",
        { "pointer-events-none": disabled }
      )}
    >
      <p
        ref={inputRef}
        contentEditable
        role="textbox"
        className={clsx(
          "b-typography-body3 w-full",
          "focus:outline-none",
          "empty:text-black/40 empty:before:content-['Write_a_message']",
          { "opacity-50": disabled },
          textInputClassName
        )}
        onInput={handleChange}
      ></p>

      <button
        className={clsx("btn-ae-default w-[125px]", btnClassName, {
          disabled: !text || text?.trim().length == 0 || disabled,
        })}
        onClick={() => onSubmit(text, cleanUp)}
      >
        Send
      </button>
    </div>
  );
};
