import { useState } from "react";

import { useEffectOnce } from "./use-effect-once";

// This hook may be useful in checking if a component is mounted or not.
// For example, when you want to check if it's a first call of the render function
// or not (some libraries like nuqs don't provide a required value on the first render).
export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffectOnce(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  });

  return isMounted;
};
