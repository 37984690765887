import { getS3AssetPath } from "shared/lib/helpers";

const getS3FaviconPath = (faviconFileName: string) =>
  getS3AssetPath(`favicon/${faviconFileName}`);

const createAppleHeadLink = (rel: string, sizes: string, href: string) => ({
  rel,
  sizes,
  href: getS3FaviconPath(href),
});

export const getFaviconLinks = () => [
  createAppleHeadLink("apple-touch-icon", "180x180", "apple-touch-icon.png"),
  {
    rel: "shortcut icon",
    href: getS3FaviconPath("favicon.ico"),
  },
  {
    rel: "mask-icon",
    color: "#000000",
    href: getS3FaviconPath("safari-pinned-tab.svg"),
  },
];

const createFaviconMetaTag = (name: string, content: string) => ({
  name,
  content,
});

export const getFaviconMetaTags = () => [
  createFaviconMetaTag("msapplication-TileColor", "#000000"),
  createFaviconMetaTag(
    "msapplication-TileImage",
    getS3FaviconPath("mstile-150x150.png")
  ),
  createFaviconMetaTag("theme-color", "#ffffff"),
];
