import { AxiosResponse } from "axios";

import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";

import { dd } from "@/helpers/datadog";

import { routeResponseInterceptor } from "./routes";

const isProduction = process.env.NEXT_PUBLIC_ENV !== "production";

export const handleOnResponseInterceptor = (
  response: AxiosResponse<any, any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  routeResponseInterceptor(response);

  const startedAt = response?.config?.meta?.requestStartedAt;
  const executionTime =
    new Date().getTime() - (response?.config?.meta?.requestStartedAt || 0);

  /**
   * Log Endpoints that takes more than 1 second to receive response
   */
  if (startedAt) {
    if (isProduction && executionTime > 1000)
      dd.warn(
        `Execution time for: ${response.config.url} - ${executionTime}ms`,
        {
          execution_time: executionTime,
          endpoint_url: response.config.url,
        }
      );
  } else {
    console.log("Execution time undefined");
  }

  return response;
};
