import { useCallback, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

import { clsxMerge } from "shared/lib/helpers";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useGenerateEmailExample } from "@/modules/email-templates-sidebar/queries";
import { AILoader } from "@/components/base/loaders/ai-loader";
import { GetEmailGenerationStatusResponseI } from "@/api/routes/email";
import { TextField } from "shared/ui";
import { EmailEditor } from "shared/ui/email-editor";
import { EmailAttachmentsSection } from "@/modules/email-templates-sidebar/shared/email-attachments-section";
import PollEmailGenerationStatus from "@/modules/email-templates-sidebar/shared/poll-email-generation-status";

const EnhancedTextField = TextField();

export const EmailTemplateExample = ({
  className,
  prompt,
}: {
  className?: string;
  prompt?: string;
}) => {
  const { campaign, account, contacts } = useAccountDetailsContext();
  const { mutateAsync: generateEmailExampleAsync } = useGenerateEmailExample();

  // this ID is used to fetch updates for the Ai generation of the email example
  const [exampleAiEmailId, setExampleAiEmailId] = useState<string>();

  const [isGeneratingExample, setIsGeneratingExample] = useState(false);
  const [emailData, setEmailData] =
    useState<GetEmailGenerationStatusResponseI>();

  const generatePreview = useCallback(async () => {
    const contactId = contacts?.[0]?.id;

    if (!prompt) {
      toast.error("Prompt is required to generate preview.");
      return;
    }

    if (campaign?.id && account?.id && contactId) {
      const resp = await generateEmailExampleAsync({
        prompt: prompt,
        campaign_id: campaign.id,
        account_id: account.id,
        contact_id: contactId,
      });

      if (resp.status === 200) {
        setExampleAiEmailId(resp.data.email_id);
        setIsGeneratingExample(true);
      }
    }
  }, [prompt]);

  const handleAIEmailGenerationCompleted = (
    generatedEmailData: GetEmailGenerationStatusResponseI | null
  ) => {
    setIsGeneratingExample(false);

    setEmailData({
      attachments: generatedEmailData?.attachments || [],
      subject: generatedEmailData?.subject || "",
      body: generatedEmailData?.body || "",
    });
  };

  return (
    <section className={className}>
      <div className="mb-11 flex">
        <div className="flex w-full items-center justify-between">
          <div className="typography-body-3-medium">View email example</div>

          <button
            type="button"
            className={clsxMerge(
              "ae-link flex cursor-pointer items-center typography-body-4-medium",
              { "opacity-60": isGeneratingExample }
            )}
            disabled={isGeneratingExample}
            onClick={generatePreview}
          >
            <ArrowPathIcon className="mr-1 w-4" />
            Generate preview
          </button>
        </div>
      </div>

      {emailData && (
        <div>
          <p className="mb-1">Subject</p>
          <EnhancedTextField
            className="mb-2 rounded-lg border border-[#CCC]"
            name="subject"
            placeholder=""
            inputProps={{ value: emailData.subject }}
            disabled
          />

          <EmailEditor
            placeholder=""
            initialContent={emailData.body}
            disabled={true}
            className="border border-[#CCC] bg-transparent"
            menuClassName="hidden"
          />

          <EmailAttachmentsSection />
        </div>
      )}

      {isGeneratingExample && exampleAiEmailId && (
        <>
          <div>
            <AILoader className="mx-auto" title="AI building template" />
          </div>

          <PollEmailGenerationStatus
            emailId={exampleAiEmailId}
            onComplete={handleAIEmailGenerationCompleted}
            onFailed={() => handleAIEmailGenerationCompleted(null)}
          />
        </>
      )}
    </section>
  );
};
