import { EffectCallback, useEffect, useRef } from "react";

// This hook is used to run a useEffect only once when a condition is met
// Condition is any to simplify DX - developers are able to pass any truthy/falsy
// value, which is then casted to boolean
export const useEffectOnceWhen = (
  callback: EffectCallback,
  rawCondition: any
) => {
  const condition = Boolean(rawCondition);
  const isEffectExecutedRef = useRef<boolean>(false);

  useEffect(() => {
    if (condition && !isEffectExecutedRef.current) {
      isEffectExecutedRef.current = true;
      callback();
    }
  }, [condition, callback]);
};
