import { LocalStorage } from "@/helpers/local-storage";
import { findAudioDeviceByLabel } from "@/helpers/media-devices";
import { Device } from "@twilio/voice-sdk";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { checkIfClient } from "shared/lib/helpers";

const getDefaultDevice = (
  outputDevices?: [string, MediaDeviceInfo][]
): MediaDeviceInfo | undefined => {
  if (!checkIfClient()) return undefined;

  const LS = new LocalStorage();
  const defaultDevice = outputDevices?.[0]?.[1];

  if (!LS.preferredAudioOutput) return defaultDevice;

  return (
    findAudioDeviceByLabel(outputDevices, LS.preferredAudioOutput) ||
    defaultDevice
  );
};

/**
 *
 * NOTE
 *
 * You can also pass properties that are not specifically Twilio Device
 * but original implementation implied that you use Twilio Device after initiaing
 * it with access token
 *
 * @param outputDevices
 * @param currentDevice
 * @returns
 */
export const useSpeakers = (
  outputDevices?: [string, MediaDeviceInfo][],
  currentDevice?: Device
) => {
  const [selectedSpeaker, setSelectedSpeaker] = useState<
    MediaDeviceInfo | undefined
  >(getDefaultDevice(outputDevices));

  const speakerLabels = useMemo(
    () => outputDevices?.map((device) => device[1].label),
    [outputDevices]
  );

  /**
   * NOTE
   *
   * As soon as input Devices are available set default value
   * if is not already selected
   */
  useEffect(() => {
    const LS = new LocalStorage();

    if (LS.preferredAudioOutput && !selectedSpeaker) {
      setSelectedSpeaker(getDefaultDevice(outputDevices));
    }
  }, [outputDevices]);

  useEffect(() => {
    const prevDeviceLabel = (
      Array.from(
        currentDevice?.audio?.speakerDevices.get() || new Set()
      )?.[0] as MediaDeviceInfo
    )?.label;

    if (prevDeviceLabel !== selectedSpeaker?.label && selectedSpeaker?.deviceId)
      currentDevice?.audio?.speakerDevices
        .set(selectedSpeaker?.deviceId)
        .then(() => {
          if (prevDeviceLabel) toast.success("Speaker output device updated");
        })
        .catch((err: any) => console.error("err: ", err));
  }, [selectedSpeaker?.deviceId, currentDevice]);

  const handleSpeakerChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const LS = new LocalStorage();

    const selectedOutputDevice = outputDevices?.find(
      (d) => d[1].label === ev.target.value
    );
    if (selectedOutputDevice) {
      const mediaDeviceInfo = selectedOutputDevice[1];

      LS.preferredAudioOutput = mediaDeviceInfo.label;
      setSelectedSpeaker(mediaDeviceInfo);
    }
  };

  return {
    selectedSpeaker,
    speakerLabels,

    setSelectedSpeaker,
    handleSpeakerChange,
  };
};
