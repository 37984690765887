import { GlobalContextI } from "@/context/global";
import { useGlobalContext } from "@/hooks/use-global-context";

export const useCampaigns = () => {
  const { campaigns, setCampaigns, asyncGet, requestAndMergeCampaigns } =
    useGlobalContext() as GlobalContextI;

  /**
   * If you want to request missing campaigns and update globalContext.
   * Will request only those that are not in globalContext
   * @param campaignsIds
   */
  const refillCampaignsByIds = async (campaignsIds: Array<string>) => {
    const campaigns = await asyncGet(setCampaigns);

    const globalContentCampaignIds = (campaigns || []).map((c) => c.id);

    // Only those that is not in globalContextCampaigns
    const missing_campaign_ids = campaignsIds.filter(
      (cid) => !globalContentCampaignIds.find((_cid) => _cid === cid)
    );

    /** NOTE Requirements to request additional campaigns
     *      At least 1 - campaign from the open accounts endpoint
     * AND at least 1 - Filter Campaigns from the open accounts endpoint
     *     that is not present in globalContext
     */
    if (missing_campaign_ids.length) {
      return requestAndMergeCampaigns?.({
        campaign_ids: missing_campaign_ids,
      });
    }
  };

  return { campaigns, refillCampaignsByIds, setCampaigns };
};
