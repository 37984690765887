import { createContext, FC, ReactNode, useContext, useState } from "react";

import { LocalStorage } from "@/helpers/local-storage";

type LocalStorageContextI = LocalStorage;

interface LocalStorageProviderPropsI {
  children?: ReactNode;
}

const LocalStorageContext = createContext<LocalStorageContextI>(
  {} as LocalStorage
);

export const useLocalStorage = () => useContext(LocalStorageContext);

// There is no reason to create instances of LocalStorage every time we need to
// use it. This context aims to resolve this issue by creating a single instance
// and sharing as a stable state variable across the app.
export const LocalStorageProvider: FC<LocalStorageProviderPropsI> = ({
  children,
}) => {
  const [storage] = useState(new LocalStorage());

  return (
    <LocalStorageContext.Provider value={storage}>
      {children}
    </LocalStorageContext.Provider>
  );
};
