import { FC, useEffect, useState } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import { clsxMerge } from "shared/lib/helpers";
import { TextArea, TextField, ToggleField } from "shared/ui/ae-user-input";
import { EmailTemplateExample } from "@/modules/email-templates-sidebar/content/email-template-workspace/edit-template/example";
import {
  EmailTemplateI,
  UpdateEmailTemplateRequestParamsI,
} from "@/api/routes/email";

const EnhancedTextField = TextField(Field);
const EnhancedTextArea = TextArea(Field);
const ToggleButton = ToggleField();

const EditEmailTemplateFormValidation = Yup.object().shape({
  name: Yup.string()
    .required("Required field")
    .max(64, "Template name exceed 64 characters"),
  prompt: Yup.string()
    .required("Required field")
    .max(500, "Prompt cannot exceed 500 characters"),
});

export interface EditEmailTemplateFormI {
  name: string;
  prompt: string;
}

interface EditTemplatePropsI {
  template?: EmailTemplateI;
  onCancel?: () => void;
  onSave?: (data: UpdateEmailTemplateRequestParamsI) => void;
}

/**
 * @param templateId Id of the template to edit. If no templateId is provided, this component understands that as creating a NEW template.
 * @returns Component for editing a email template for the given `templateId` or creating a new template entirely.
 */
export const EditTemplate: FC<EditTemplatePropsI> = ({
  template,
  onCancel = () => {},
  onSave = () => {},
}) => {
  const [attachOnePager, setAttachOnePager] = useState(
    template?.attach_one_pager
  );

  const form: EditEmailTemplateFormI = {
    name: template?.name || "",
    prompt: template?.prompt || "",
  };

  const onFormSubmitHandler = (formData: EditEmailTemplateFormI) => {
    const preparedData: UpdateEmailTemplateRequestParamsI = {
      ...formData,
      attach_one_pager: !!attachOnePager,
    };

    onSave(preparedData);
  };

  useEffect(() => {
    setAttachOnePager(template?.attach_one_pager);
  }, [template?.attach_one_pager]);

  return (
    <div className="flex h-full flex-col">
      <div className="mb-4 typography-header-6">
        {template ? template.name : "New email template id"}
      </div>

      <Formik
        initialValues={form}
        validationSchema={EditEmailTemplateFormValidation}
        onSubmit={onFormSubmitHandler}
        enableReinitialize
      >
        {({ errors, touched, values }) => (
          <Form className="flex h-full flex-col justify-between">
            <section>
              <div className="mb-5">
                <EnhancedTextField
                  name="name"
                  label="Template name"
                  placeholder="Enter name"
                  labelClassName="typography-body-3-medium"
                  inputClassName="bg-white"
                  errors={errors.name}
                  touched={touched.name}
                />
              </div>

              <div className="mb-5">
                <EnhancedTextArea
                  name="prompt"
                  label="Template prompt"
                  placeholder="Tell our AI what you want the email to include"
                  labelClassName="typography-body-3-medium"
                  inputClassName="bg-white h-fit h-[110px]"
                  errors={errors.prompt}
                  touched={touched.prompt}
                />
              </div>

              <div>
                <EnhancedTextArea
                  name=""
                  placeholder=""
                  label="Here's a prompt example"
                  labelClassName="typography-body-3"
                  inputClassName="bg-white h-fit h-[110px]"
                  inputProps={{
                    disabled: true,
                    value:
                      "Send {first name} an email reviewing pricing and avg cost savings for customers. Include a one-page and be super positive. Also ask for a good time to follow-up.",
                  }}
                />
              </div>

              <ToggleButton
                checked={attachOnePager}
                className="w-fit"
                label="Attach one pager"
                labelClassName="typography-body-3-medium"
                onChange={() => setAttachOnePager(!attachOnePager)}
              />

              <div className="divider" />

              <EmailTemplateExample className="mt-5" prompt={values.prompt} />
            </section>

            <section
              className={clsxMerge(
                "mt-11 flex items-center justify-between",
                "rounded-md bg-black/10",
                "p-4"
              )}
            >
              <button type="button" className="btn-b-white" onClick={onCancel}>
                CANCEL
              </button>

              <button type="submit" className="btn-ae-default min-w-[120px]">
                SAVE
              </button>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  );
};
