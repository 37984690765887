import { FC, useState } from "react";
import clsx from "clsx";

import { useTimeout } from "shared/lib/hooks";

import LeadDescription from "@/components/modules/calling/cards/lead-card/v2/lead-description";
import { LeadGeneralInfo } from "@/components/modules/calling/cards/lead-card/v2/lead-general-info";
import { LeadLabels } from "./lead-labels";

interface LeadCardV2PropsI {
  actionsSection?: any;
}

export interface LeadCardV2MetadataI {
  todoId?: string;
  contactLocalTimeZone?: string;
  previousAttempts?: string;
  lastCalledTime?: string;
  meetingTime?: string;
  isMissedCall?: boolean;
}

const LeadCardV2: FC<LeadCardV2PropsI> = ({
  actionsSection: ActionsSectionReplacement,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useTimeout(() => {
    setIsVisible(true);
  }, 100);

  // NOTE do not handle empty data inside the card
  //      control if card needs to be rendered outside

  return (
    <div
      className={clsx(
        "w-[675px]",
        "transition-[opacity,transform] duration-500",
        isVisible ? "scale-100 opacity-100" : "scale-50 opacity-0"
      )}
    >
      <div
        className={clsx(
          "relative flex gap-5 rounded-xl border-[1px] border-white/40",
          "bg-lead-card-gradient p-5 backdrop-blur-[25px]"
        )}
      >
        <LeadLabels />
        <LeadGeneralInfo className="relative w-1/2 max-w-[264px]" />

        <div
          className={clsx(
            "relative z-10 w-1/2 grow gap-5 rounded-xl bg-white/80 p-4",
            "shadow-[0px_4px_60px_rgba(0,0,0,0.1)]"
          )}
        >
          <LeadDescription />

          {!!ActionsSectionReplacement && ActionsSectionReplacement}
        </div>
      </div>
    </div>
  );
};

export default LeadCardV2;
