import { INDUSTRIES } from "shared/lib/constants/industry";
import { clsxMerge } from "shared/lib/helpers";

export const CampaignCardIndustryPill = ({
  industry,
  className,
}: {
  industry: string;
  className?: string;
}) => {
  return industry ? (
    <div
      className={clsxMerge(
        "ae-typography-detail1 px-2 py-0.5",
        "rounded-[4px] border-none",
        "mx-1",
        "bg-[#014DFE1A] text-[#014DFE]",
        "line-clamp-2",
        className
      )}
    >
      {INDUSTRIES.find(({ value }) => value === industry)?.label || industry}
    </div>
  ) : null;
};
