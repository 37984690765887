const AccountHistoryEmptyState = () => {
  return (
    <div className="flex h-[300px] flex-col items-center justify-center">
      <h3 className="ae-typography-h3 mb-2">No account history... yet!</h3>
      <p className="ae-typography-body1">
        Come back later to view activity that has been happening with this
        account
      </p>
    </div>
  );
};

export default AccountHistoryEmptyState;
