import { FC, FormEvent, useMemo, useState } from "react";
import clsx from "clsx";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { TextArea } from "shared/ui/ae-user-input";
import { useApi } from "shared/lib/hooks";
import { APII, glencocoClientAPI } from "@/api/glencoco";

const EnhancedTextArea = TextArea();

interface LeadQualityFeedbackModalPropsI {
  campaignId?: string;
  onClose?: () => void;
}

export const LEAD_QUALITY_FEEDBACK_MODAL_ID = "lead_quality_feedback_modal";

const ModalContent: FC<
  ModalChildrenFnPropsI & LeadQualityFeedbackModalPropsI
> = ({ campaignId, handleClose }) => {
  const [note, setNote] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFeedbackFetcher = useMemo(
    () =>
      campaignId
        ? (api: APII, feedback: string) =>
            api.submitLeadQualityFeedback(campaignId, { feedback })
        : null,
    [campaignId]
  );

  const [, fetchSubmitFeedback] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: submitFeedbackFetcher,
    shouldCallAutomatically: false,
    successBuilder: () => "Thank you for the feedback",
    showToastOnSuccess: true,
    errorBuilder: () => "Failed to submit feedback, please try again.",
  });

  const handleSubmit = async () => {
    if (campaignId && note) {
      setIsSubmitting(true);
      await fetchSubmitFeedback(note);

      setIsSubmitting(false);
      handleClose();
    }
  };

  return (
    <form
      onSubmit={(ev: FormEvent) => {
        ev.preventDefault();
        handleSubmit();
      }}
    >
      <EnhancedTextArea
        name="note"
        placeholder="Share your answers below"
        inputClassName={"max-h-[50vh] min-h-[130px]"}
        inputProps={{
          value: note,
          onChange: (event: any) => {
            setNote((event?.target as HTMLInputElement)?.value);
          },
        }}
      />

      <div className="mt-6 flex justify-end gap-2">
        <div className="flex gap-2">
          <button type="button" className="btn-ae-text" onClick={handleClose}>
            Cancel
          </button>

          <button
            type="submit"
            className={clsx("btn-ae-default w-[125px]", {
              loading: isSubmitting,
            })}
            disabled={!note}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export const LeadQualityFeedbackModal: FC<LeadQualityFeedbackModalPropsI> = ({
  campaignId,
  onClose,
}) => (
  <Modal
    id={LEAD_QUALITY_FEEDBACK_MODAL_ID}
    title="Give lead feedback"
    description="Are there not enough leads? Do the lead titles match the qualification criteria? Does the lead data match the person who picks up the phone? We will be actively monitoring this feedback to improve lead quality on your campaigns."
    onClose={onClose}
  >
    {(props) => <ModalContent {...props} campaignId={campaignId} />}
  </Modal>
);
