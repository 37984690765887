import { clsxMerge } from "shared/lib/helpers";

import { CogsIcon } from "shared/ui/icons";
import { ControlledDropdown } from "shared/ui";

import Settings, {
  DialerSettingsConfigI,
} from "@/modules/calling/control-menu/v1/settings";
import { BasicButton } from "@/modules/calling/control-menu/v2/buttons/basic-button";

export const ConfigButton = ({
  className,
  dropdownClassName,

  isAutoDialSetting,
  isMusicSetting,
}: {
  className?: string;
  dropdownClassName?: string;
} & DialerSettingsConfigI) => {
  return (
    <ControlledDropdown className={dropdownClassName}>
      {(isDropdownVisible) => (
        <>
          <BasicButton tabIndex={1} className={className}>
            <span className="mb-[6px]">
              <CogsIcon className={clsxMerge("h-4 w-4")} />
            </span>
            <span className="brand-typography-detail3 text-black/90">More</span>
          </BasicButton>
          <div
            id="test1"
            tabIndex={1}
            className={clsxMerge(
              "dropdown-content translate-x-[-158px] translate-y-[-4px]"
            )}
          >
            <Settings
              isVisible={isDropdownVisible as boolean}
              isAutoDialSetting={isAutoDialSetting}
              isMusicSetting={isMusicSetting}
            />
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
