import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { LOG_CATEGORIES } from "@/constants/logs";
import { disconnectMic } from "@/helpers/calling";
import { asyncGet } from "@/helpers/context";
import { dd } from "@/helpers/datadog";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useBeforeTabClose } from "@/hooks/use-before-tab-close";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { useEffect } from "react";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

/**
 * NOTE we need to make sure if component is getting unmounted we need to kill the call
 */
export const CallState = ({
  onChange,
}: {
  onChange?: (status: ValueOfObjectFields<typeof DIALER_CALL_STATUS>) => void;
}) => {
  //NOTe cleanup context
  useBeforeTabClose();

  const context = useCallingContext();
  const { clear: clearInCallContext } = useInCallContext();

  const callStatus = useDialerCallStatus();

  useEffect(() => {
    return () => {
      (async () => {
        const call = await asyncGet(context.setCall);
        const device = await asyncGet(context.setDevice);

        clearInCallContext();

        call?.disconnect();
        device?.disconnectAll();

        dd.rum.log(
          `${LOG_CATEGORIES.TWILIO}[DISCONNECT] - manual disconnect widget on close`
        );

        disconnectMic();
      })();
    };
  }, []);

  useEffect(() => {
    onChange?.(callStatus);
  }, [callStatus]);

  return null;
};
