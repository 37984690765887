import { DIALER_MODES } from "@/constants/dialer";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge } from "shared/lib/helpers";

import { BGs } from "./backgrounds";

export const DialerDefaultContainer = ({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) => {
  const { mode, callingState } = useCallingContext();

  return (
    <div className={clsxMerge("bg-black p-2", className)}>
      <div
        className={clsxMerge(
          "relative",
          "flex flex-col",
          "h-full min-h-[calc(100vh-65px)] rounded-[8px]",
          "transition-[background] duration-1000",

          BGs[mode || DIALER_MODES.DEFAULT][callingState as string]
        )}
      >
        {children}
      </div>
    </div>
  );
};
