import { FC, useEffect, useState } from "react";
import { CogsIcon, CopyIcon } from "shared/ui/icons";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import Calling, {
  getWebSocketConnection,
  askPermissionMic,
} from "@/helpers/calling";

import { toast } from "react-hot-toast";
import { useEffectOnce } from "shared/lib/hooks";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import {
  GetStripeAccountCreationStatus,
  glencocoClientAPI,
} from "@/api/glencoco";
import { modal } from "@/components/modals";
import { STRIPE_CONNECT_MODAL_ID } from "@/components/modals/stripe-connect-modal";
import { RadioButton } from "shared/ui/ae-user-input";
import { DIALER_STATES } from "@/constants/dialer";
import Router from "@/helpers/router";

const SimpleRadioButton = RadioButton();

interface WelcomeCardI {
  className?: string;
}

const WelcomeCard: FC<WelcomeCardI> = ({ className }) => {
  const callingCtx = useCallingContext();
  const { callerProfile, campaign } = callingCtx;

  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingPermissions, setIsCheckingPermissions] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  useEffect(() => {
    if (callingCtx?.isTourComplete) {
      (async () => {
        const API = glencocoClientAPI();

        const GetStripeStatusRequest =
          await API.getStripeAccountCreationStatus().catch((e) => e);

        if (GetStripeStatusRequest.status === 200) {
          const status = (
            GetStripeStatusRequest?.data as GetStripeAccountCreationStatus
          )?.stripe_status;

          if (status !== "completed") {
            modal.trigger(STRIPE_CONNECT_MODAL_ID);
          }
        } else {
          toast.error(
            "Failed to check payouts configuration. Reload the page and try again."
          );
        }
      })();
    }
  }, [callingCtx?.isTourComplete]);

  const onSessionStartHandler = async () => {
    setIsCheckingPermissions(true);

    // Ask for permission to use mic and speaker
    const isMicAllowed = await askPermissionMic();

    if (isMicAllowed) {
      getWebSocketConnection(callingCtx);

      if (!callingCtx.campaignId) {
        toast.error(`Unknown Campaign`);
        return;
      }

      if (callingCtx.callerProfile.autoDial) {
        await Calling.startCallAndEnqueueCaller(callingCtx);
      } else {
        callingCtx.setCallingState(DIALER_STATES.LEAD_SELECTION);
      }
    } else {
      toast("Permission to use audio devices is required to make calls.");
    }

    setIsCheckingPermissions(false);
    setIsLoading(false);
  };

  const enterDialerTour = () => {
    window.document.dispatchEvent(
      new CustomEvent(CUSTOM_EVENTS.CALLING_MODULE.TOUR.START_TOUR)
    );
  };

  const toggleAutoDial = (bool: boolean) => {
    callerProfile.setAutoDial(bool);
  };

  return (
    <>
      <div
        className={twMerge(
          clsx(
            "relative w-[481px] rounded-[10px] bg-white px-8 py-6",
            className,
            "transition-[opacity,transform] duration-500",
            {
              "scale-50 opacity-0": !isVisible,
              "scale-100 opacity-100": isVisible,
            }
          )
        )}
      >
        <h1 className="ae-typography-h2 mb-8 mt-1">
          Are you ready to start calling?
        </h1>

        <div className="mb-7">
          <AcademicCapIcon className="mb-3 inline h-5 w-5" />
          <h2 className="ae-typography-h4 mb-2">
            Get familiar with the calling environment
          </h2>
          <p className="ae-typography-body2 text-black/60">
            Explore the environment made for engaging with leads and booking
            meetings. Take a moment to navigate the{" "}
            <button className="ae-link font-bold" onClick={enterDialerTour}>
              dialer walkthrough
            </button>
            .
          </p>
        </div>

        <div className="mb-7">
          <CogsIcon className="mb-3 inline h-4 w-4" />
          <h2 className="ae-typography-h4 mb-2">Verify your audio settings</h2>
          <p className="ae-typography-body2 text-black/60">
            Ensure the proper mic and speaker are selected and choose your
            autodial preference by clicking the gear icon below
          </p>
        </div>

        <div className="mb-7">
          <CopyIcon className="mb-3 inline h-4 w-4" />
          <h2 className="ae-typography-h4 mb-2">Review your resources</h2>
          <p className="ae-typography-body2 text-black/60">
            Prepare for your call by looking over scripts, objection handling,
            or personas provided in the resource tab on the right
          </p>
        </div>

        <div className="mb-7 grid grid-cols-2">
          <div>
            <SimpleRadioButton
              name="autodial"
              label="Auto-dial"
              inputProps={{
                disabled: campaign?.has_live_transfer,
                checked: callerProfile.autoDial,
                onChange: () => toggleAutoDial(true),
              }}
              variation="medium"
              className={clsx("border-none", {
                "[&_span]:text-black/20": campaign?.has_live_transfer,
              })}
            />
          </div>

          <div>
            <SimpleRadioButton
              name="targeteddial"
              label="Targeted dial"
              inputProps={{
                checked: !callerProfile.autoDial,
                onChange: () => toggleAutoDial(false),
              }}
              variation="medium"
              className="border-none"
            />
          </div>
        </div>
        <button
          className={clsx("ae-typography-button2 btn w-full bg-black", {
            loading: isLoading || isCheckingPermissions,
          })}
          onClick={() => {
            setIsLoading(true);
            setTimeout(() => {
              onSessionStartHandler();
            }, 1000);
          }}
          disabled={isLoading || isCheckingPermissions}
        >
          START SESSION
        </button>

        <div className="mt-2 flex justify-center">
          <a
            href={Router.createNewLead()}
            className="ae-typography-detail1 cursor-pointer p-2"
          >
            <span className="ae-link">I want to call my own lead</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default WelcomeCard;
