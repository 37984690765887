import { Dispatch, FC, SetStateAction, useState } from "react";
import clsx from "clsx";
import { glencocoClientAPI } from "@/api/glencoco";
import { LeadInfoI } from "@/interfaces/dialer/lead-info";
import { EditIcon } from "shared/ui/icons";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ADD_NEXT_LEAD_MODAL_ID } from "@/modules/calling/modals/add-next-lead";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { toast } from "react-hot-toast";
import { deleteActivityAddContactEvent } from "@/helpers/activity-log";

interface NextLeadCardI {
  nextLead: LeadInfoI;
  isVisible?: boolean;
  setVisibility?: Dispatch<SetStateAction<boolean>>;
  handleFormikReset: () => void;
}

export const DilaerNextLeadCard: FC<NextLeadCardI> = ({
  nextLead,
  isVisible,
  setVisibility,
  handleFormikReset,
}) => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();
  const callId = callingContext?.call?.customParameters.get(
    "call_id"
  ) as string;
  const [disabled, disable] = useState(false);

  const deleteLead = async () => {
    disable(true);

    setTimeout(() => {
      const API = glencocoClientAPI();

      if (!callingContext.campaignId) {
        toast.error("Error: couldn't find campaign ID.");
        disable(false);
        return;
      }

      API.deleteLead(callingContext.campaignId, callId, nextLead.id)
        .then((resp) => {
          if (resp.status == 200) {
            const leadQueue = callingContext.leadQueue;
            leadQueue.pop();
            callingContext.setLeadQueue([...leadQueue]);
            deleteActivityAddContactEvent(inCallContext);
            handleFormikReset();

            toast.success("Next lead deleted");
            setVisibility?.(false);
          }
        })
        .catch(() => toast.error("Error deleting next lead!"));

      disable(false);
    }, 1000);
  };

  return (
    <div
      className={clsx(
        "w-[342px]",
        "transition-[transform] duration-300 ease-in",
        {
          "translate-y-[-200px]": !isVisible,
          "translate-y-0": isVisible,
        }
      )}
    >
      <div className="rounded-lg bg-white p-4 shadow-[0px_4px_60px_rgba(0,0,0,0.1)]">
        <div className="flex items-center justify-between">
          <div className="w-[200px]">
            <label className="ae-typography-h3 mb-1 flex items-center">
              <span className="mr-2">{nextLead?.name || "First Last"}</span>
            </label>
            <p className="ae-typography-body3 text-black/40">
              <span>
                {nextLead?.title || "Title"} @{" "}
                {nextLead?.company_name || "Company"}
              </span>
            </p>
          </div>

          <div>
            <label
              htmlFor={ADD_NEXT_LEAD_MODAL_ID}
              className="btn btn-outline btn-sm mr-3 w-11 border-black/20"
            >
              <EditIcon className="h-4 w-4" />
            </label>
            <button
              className={clsx("btn btn-outline btn-sm w-11 border-black/20", {
                loading: disabled,
              })}
              onClick={deleteLead}
              disabled={disabled}
            >
              {!disabled && <TrashIcon className="h-4 w-4" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
