// TODO: Check if this can be deprecated
// first we used to ask the user which status tochange the account to if the meeting was canceled
// but the new close only asks for a confirmation (no more status-changing)

import { FC, MutableRefObject, useCallback, useRef } from "react";
import { Field, Form, Formik } from "formik";
import clsx from "clsx";
import { RadioButton, TextArea } from "shared/ui";
import { modal } from "@/modals/index";
import { TARGET_STATUS_TO_MODAL_ID_MAP } from "@/modules/pipeline/open/constants";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { ModalAccountDataI } from "@/context/accounts";
import * as Yup from "yup";
import {
  ACCOUNT_STATUSES,
  ACCOUNT_STATUSES_MAP,
} from "shared/lib/constants/account";
import { AccountStatusI } from "shared/lib/interfaces/account";

interface AccountCancelMeetingFormValuesI {
  status?: AccountStatusI;
  notes: string;
}

interface AccountChangeStatusModalPropsI {
  modalId?: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const ACCOUNT_CANCEL_MEETING_MODAL_ID = "account-cancel-meeting-modal";

const SUPPORTED_STATUS_TRANSITIONS = [
  ACCOUNT_STATUSES.BACKLOG,
  ACCOUNT_STATUSES.CALLBACK,
];

const STATUS_REQUIRING_ACTIONS = [ACCOUNT_STATUSES.CALLBACK];

const EnhancedRadioButton = RadioButton(Field);
const EnhancedTextArea = TextArea(Field);

const FormSchema = Yup.object().shape({
  notes: Yup.string()
    .max(1000, "Max 1000 characters")
    .min(2, "Please provide a note")
    .required("Please provide a note"),
  status: Yup.string().required("Please select a status"),
});

const checkIfStatusRequiresAction = (status: AccountStatusI) =>
  STATUS_REQUIRING_ACTIONS.includes(
    status as (typeof STATUS_REQUIRING_ACTIONS)[number]
  );

const AccountCancelMeetingModal: FC<AccountChangeStatusModalPropsI> = ({
  modalId = ACCOUNT_CANCEL_MEETING_MODAL_ID,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const {
    updateAccountWithCampaign,
    isAccountUpdating,
    modalAccount,
    setModalAccount,
    loadAccounts,
    onChangeStatusCancelRef,
  } = useAccountsContext();
  const { accountId, campaignId, accountStatus, targetAccountStatus } =
    modalAccount || {};
  const checkboxRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClose = () => {
    checkboxRef.current.click();

    if (onChangeStatusCancelRef.current) {
      onChangeStatusCancelRef.current();
    }

    onClose();
  };

  const handleUpdateAccountStatus = useCallback(
    (values: AccountCancelMeetingFormValuesI) => {
      if (!accountId || !campaignId || !values.status) {
        return;
      }

      if (checkIfStatusRequiresAction(values.status)) {
        setModalAccount(
          (currentModalAccount) =>
            ({
              ...currentModalAccount,
              cancelMeetingRequestNote: values.notes,
              targetAccountStatus: values.status,
            } as ModalAccountDataI)
        );

        setTimeout(
          () =>
            modal.trigger(
              TARGET_STATUS_TO_MODAL_ID_MAP[
                values.status as keyof typeof TARGET_STATUS_TO_MODAL_ID_MAP
              ]
            ),
          100
        );

        modal.trigger(ACCOUNT_CANCEL_MEETING_MODAL_ID);
      } else {
        if (accountStatus === ACCOUNT_STATUSES.BACKLOG) {
          return handleClose();
        }

        updateAccountWithCampaign(accountId, campaignId, {
          status: values.status,
          cancel_meeting_request: {
            note: values.notes,
          },
        }).then(() => {
          onSuccess();
          handleClose();
          loadAccounts();
        });
      }
    },
    [updateAccountWithCampaign]
  );

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxRef}
      />

      <div className="qualification-criteria-modal modal">
        <div className="modal-box relative min-w-[520px] rounded-md p-6">
          <p className="ae-typography-body1 mb-1 font-semibold">
            Cancel Meeting
          </p>
          <h2 className="ae-typography-body2 text-black/60">
            Update the status for your canceled meeting
          </h2>

          {!!accountStatus && (
            <Formik
              initialValues={{
                status: targetAccountStatus,
                notes: "",
              }}
              onSubmit={handleUpdateAccountStatus}
              validationSchema={FormSchema}
            >
              {({ values, errors, touched }) => (
                <Form className="mt-3 flex flex-col gap-3">
                  {!targetAccountStatus &&
                    Object.values(SUPPORTED_STATUS_TRANSITIONS).map((key) => (
                      <EnhancedRadioButton
                        key={key}
                        className="w-full p-1"
                        name="status"
                        label={
                          ACCOUNT_STATUSES_MAP[
                            key as keyof typeof ACCOUNT_STATUSES_MAP
                          ]
                        }
                        value={key}
                      />
                    ))}

                  <EnhancedTextArea
                    name="notes"
                    label="Reason for canceled meeting"
                    placeholder="Add cancelation reason"
                    errors={errors.notes}
                    touched={touched.notes}
                  />

                  <div className="mt-2 flex justify-end">
                    <button
                      className={clsx("btn-ae-text mr-2", {
                        disabled: isAccountUpdating,
                      })}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className={clsx("btn-ae-default", {
                        disabled: isAccountUpdating || !values.status,
                      })}
                    >
                      {values.status &&
                      checkIfStatusRequiresAction(values.status)
                        ? "Next"
                        : "Update"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountCancelMeetingModal;
