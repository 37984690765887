import { useCallback, useState } from "react";

import { fetchTranscript } from "../helpers/call";

const useFetchCallTranscript = (transcriptURL?: string) => {
  const [transcript, setTranscript] = useState<[string, string][]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadTranscript = useCallback(() => {
    if (!transcriptURL) {
      return;
    }

    setIsLoading(true);

    fetchTranscript(transcriptURL)
      .then((preparedTranscript) => {
        setTranscript(preparedTranscript);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    loadTranscript,
    transcript,
    isLoading,
  };
};

export default useFetchCallTranscript;
