import { useCampaigns } from "@/hooks/use-campaigns";
import { FC, useEffect, useMemo } from "react";
import { NotificationAvatar } from "shared/ui/notifications/notification-generic/base-parts/avatar";

export interface NotificationCampaignImageI {
  campaign_id?: string;
  campaign_name?: string;
}

export const NotificationCampaignImage: FC<NotificationCampaignImageI> = ({
  campaign_id,
  campaign_name,
}) => {
  const { campaigns, refillCampaignsByIds } = useCampaigns();

  const logo_s3_url = useMemo(() => {
    return campaigns?.find((c) => c.id === campaign_id)?.logo_s3_url;
  }, [campaigns]);

  useEffect(() => {
    if (campaign_id) {
      refillCampaignsByIds([campaign_id]);
    }
  }, [campaign_id]);

  return (
    <NotificationAvatar
      src={logo_s3_url}
      placeholderText={campaign_name}
      isLazy
    />
  );
};
