import { AxiosInstance, AxiosResponse } from "axios";

export interface GetTodoCountI {
  count?: number;
}

export interface CountApiI {
  getTodoCount: () => Promise<AxiosResponse<GetTodoCountI>>;
}

export const CountAPI = (API: AxiosInstance): CountApiI => ({
  getTodoCount: () => API.get(`/v1/todo/count`),
});
