import { useCallback, useMemo } from "react";

import { BaseControlButton } from "@/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { CalendarIcon } from "shared/ui/icons";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ACCOUNT_BOOK_MEETING_MODAL_ID } from "@/modals/account-book-meeting-modal";
import { AccountStatusI, ContactDetailI } from "shared/lib/interfaces/account";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  ACCOUNT_DISPOSITION_STATUSES,
  ACCOUNT_STATUSES,
} from "shared/lib/constants/account";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import ButtonMenu from "shared/ui/button-menu";
import { getFullEntityName } from "shared/lib/helpers";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useLiveTransfer } from "@/modules/pipeline/account-details/hooks/use-live-transfer";
import {
  LIVE_TRANSFER_BMB_MODAL_ID,
  LiveTransfer_BeforeMeetingBooked_Modal,
} from "@/components/modals/account-live-transfer-modal/before-meeting-booked";
import {
  LIVE_TRANSFER_AMB_MODAL_ID,
  LiveTransfer_AfterMeetingBooked_Modal,
} from "@/components/modals/account-live-transfer-modal/after-meeting-booked";

export const MeetingButton = () => {
  const callStatus = useDialerCallStatus();
  const {
    isLiveTransferMode,
    isLiveTransferIsMeetingBooked,
    handleLiveTransfer,
  } = useLiveTransfer();
  const { contact: callContact } = useInCallContext();
  const { setModalAccount } = useAccountsContext();

  const { account, accountStatus, campaign, contacts } =
    useAccountDetailsContext();

  // User can either book a meeting or reschedule it depending on the account status
  const actionLabel = useMemo(() => {
    if (isLiveTransferMode) {
      return "Live transfer";
    }

    if (
      accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED
    ) {
      return "Reschedule meeting";
    }

    return "Book meeting";
  }, [accountStatus?.status, isLiveTransferMode]);

  const handleOpenBookMeetingModal = ({
    title = "",
    email = "",
    first_name,
    last_name,
  }: ContactDetailI) => {
    const name = getFullEntityName({ first_name, last_name });

    setModalAccount({
      campaignId: campaign?.id || "",
      accountId: account?.id || "",
      accountName: account?.name || "",
      accountStatus: accountStatus?.status as AccountStatusI,
      targetAccountStatus: ACCOUNT_STATUSES.MEETING_SCHEDULED,
      contact: {
        title,
        email,
        name,
      },
      followUpTime: accountStatus?.meeting_time || "",
    });

    setTimeout(() => modalHelpers.open(ACCOUNT_BOOK_MEETING_MODAL_ID));
  };

  const items = useMemo(
    () =>
      contacts?.map((contact) => {
        let itemLabel = "Contact without email";
        const firstOrLastName = contact.first_name || contact.last_name;
        const { email } = contact;

        if (firstOrLastName) {
          itemLabel = email ? `${firstOrLastName} - ${email}` : firstOrLastName;
        }

        return (
          <ButtonMenuItem
            className="text-right"
            text={itemLabel}
            onClick={() => handleOpenBookMeetingModal(contact)}
          />
        );
      }) || [],
    [contacts]
  );

  /**
   * Note
   *
   * During the call you want to book the meeting for the person you are calling to
   */
  const isSignleOption = useMemo(() => {
    return contacts?.length === 1 || callStatus !== DIALER_CALL_STATUS.BEFORE;
  }, [contacts, callStatus]);

  const currentSingleContact = useMemo(() => {
    return callStatus === DIALER_CALL_STATUS.BEFORE
      ? contacts?.[0]
      : contacts?.find((c) => c.id === callContact.id);
  }, [callStatus, contacts, callContact]);

  const handleBookMeeting = useCallback(() => {
    handleOpenBookMeetingModal(currentSingleContact || {});
  }, [handleOpenBookMeetingModal, currentSingleContact]);

  const openModal = useCallback(() => {
    if (isLiveTransferMode && !isLiveTransferIsMeetingBooked) {
      return modalHelpers.open(LIVE_TRANSFER_BMB_MODAL_ID);
    }

    if (isLiveTransferMode && isLiveTransferIsMeetingBooked) {
      return modalHelpers.open(LIVE_TRANSFER_AMB_MODAL_ID);
    }

    return handleBookMeeting();
  }, [
    currentSingleContact,
    isLiveTransferMode,
    isLiveTransferIsMeetingBooked,
    handleBookMeeting,
  ]);

  const handleConfirmStartLiveTransfer = () => {
    handleLiveTransfer();
  };

  return (
    <>
      {isSignleOption && (
        <BaseControlButton className="btn-ae-default" onClick={openModal}>
          <CalendarIcon className="mb-1 mt-1 w-4" />
          <span>{actionLabel}</span>
        </BaseControlButton>
      )}

      {!isSignleOption && (
        <ButtonMenu
          items={items}
          className="p-0"
          position="end"
          containerClassName="dropdown-top"
          contentClassName="w-max max-w-[300px] mt-0 mb-2"
          renderTrigger={() => (
            <BaseControlButton className="btn-ae-default">
              <CalendarIcon className="mb-1 mt-1 w-4" />
              <span>{actionLabel}</span>
            </BaseControlButton>
          )}
        />
      )}

      {isLiveTransferMode && (
        <>
          <LiveTransfer_BeforeMeetingBooked_Modal
            onConfirm={handleBookMeeting}
          />
          <LiveTransfer_AfterMeetingBooked_Modal
            onConfirm={handleConfirmStartLiveTransfer}
          />
        </>
      )}
    </>
  );
};
