import { CATCHUP_TYPES } from "@/constants/catchup-todos";
import { CatchupCallbackActions as CallbackActions } from "@/modules/calling/leads-carousel/v2/actions/catchup-mode/callback";
import { clsxMerge } from "shared/lib/helpers";
import { CatchupMissedCallActions } from "./missed_calls";
import { CatchupTypesI } from "@/interfaces/dialer/catchups";
import { CatchupMeetingActions } from "@/modules/calling/leads-carousel/v2/actions/catchup-mode/meeting";
import { AddToListsButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/add-to-list-button";

const Actions = ({ type }: { type?: CatchupTypesI }) => {
  switch (type) {
    case CATCHUP_TYPES.CALLBACK:
      return <CallbackActions />;
    case CATCHUP_TYPES.MISSED_CALLS:
      return <CatchupMissedCallActions />;
    case CATCHUP_TYPES.MEETING:
      return <CatchupMeetingActions />;
    default:
      return null;
  }
};

export const CatchupActions = ({
  type,
  className,
}: {
  type?: CatchupTypesI;
  className?: string;
}) => {
  return (
    <div className={clsxMerge(className)}>
      <AddToListsButton className="mb-2 " />
      <Actions type={type} />
    </div>
  );
};
