import { FC, useEffect, useState } from "react";

import { InfiniteScrollWithObserver } from "shared/ui";
import { Notification } from "../notification";
import { NotificationI } from "@/interfaces/notification";
import { clsxMerge } from "shared/lib/helpers";

interface NotificationsInfiniteScrollPropsI {
  notifications?: NotificationI[];
  loadMore?: () => void;
  isReachedEnd?: boolean;
  isLoading?: boolean;
}

export const NotificationsInfiniteScroll: FC<
  NotificationsInfiniteScrollPropsI
> = ({ notifications, loadMore, isReachedEnd, isLoading }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (notifications && notifications?.length > 0 && !isInitialized) {
      setIsInitialized(true);
    }
  }, [notifications?.length, isInitialized]);

  return (
    <InfiniteScrollWithObserver
      isLoading={isLoading as boolean}
      isMore={!isReachedEnd}
      onMore={loadMore}
      isDefaultMessageHidden={true}
    >
      {notifications?.map((notification, idx) => (
        <Notification
          key={idx}
          {...notification}
          className={clsxMerge({
            "border-b border-b-black/20": idx < notifications.length - 1,
          })}
        />
      ))}
    </InfiniteScrollWithObserver>
  );
};
