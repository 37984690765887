import clsx from "clsx";
import { TRAINING_CARD_STATUSES } from "./constants";

const neutralClasses = "!bg-black/5 text-base-content/80";
const infoClasses = "!bg-[#014DFE1A] text-[#014DFE]";
const positiveClasses = "!bg-[#DFF4B499] text-[#0E6927]";
const negativeClasses = "!bg-[#FFBFB0] text-base-content/80";

export const TrainingCardStatusPill = ({
  statuses,
}: {
  statuses: Array<
    | (typeof TRAINING_CARD_STATUSES)[keyof typeof TRAINING_CARD_STATUSES]
    | undefined
  >;
}) => (
  <div className="flex">
    {statuses.includes(TRAINING_CARD_STATUSES.HIRING) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          infoClasses
        )}
      >
        Hiring Full-Time!
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.NEW) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          infoClasses
        )}
      >
        New
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.PENDING) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          neutralClasses
        )}
      >
        Pending Approval
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.TRAINING_COMPLETED) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          positiveClasses
        )}
      >
        Start Audition
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.APPROVED) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          positiveClasses
        )}
      >
        Approved
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.NOT_ELIGIBLE) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          negativeClasses
        )}
      >
        Not Eligible
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.RETRY) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          neutralClasses
        )}
      >
        Retry Assessment
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.CONTINUE) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          neutralClasses
        )}
      >
        Continue Assessment
      </div>
    )}

    {statuses.includes(TRAINING_CARD_STATUSES.PROGRESS) && (
      <div
        className={clsx(
          "badge ae-typography-detail1",
          "rounded-[4px] border-none",
          "ml-2",
          neutralClasses
        )}
      >
        In Progress
      </div>
    )}
  </div>
);
