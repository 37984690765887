import { FC, useEffect, useMemo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { ListPillI } from "@/api/routes/list";
import { ControlledDropdown } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";
import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";

interface AddUserToListSidebarListsDropdownPropsI
  extends ControllableComponentPropsI<ListPillI | null> {
  lists?: ListPillI[];
  disabled?: boolean;
}

export const AddUserToListSidebarListsDropdown: FC<
  AddUserToListSidebarListsDropdownPropsI
> = ({ lists, value, onChange, disabled }) => {
  const [selectedList, setSelectedList] = useState<ListPillI | null>(
    value || null
  );

  const options = useMemo(() => {
    if (!lists) {
      return [];
    }

    return [
      {
        name: "-None-",
        id: null,
      } as unknown as ListPillI,
      ...lists,
    ];
  }, [lists]);

  const dropdownLabel = selectedList ? selectedList.name : "Select list";

  const handleListChange = (list: ListPillI) => {
    setSelectedList(list === selectedList ? null : list);
  };

  useEffect(() => {
    // Update the selected list when the value prop changes
    setSelectedList(value as ListPillI | null);
  }, [value]);

  useEffect(() => {
    // Fire the onChange prop when the selected list changes
    onChange(selectedList);
  }, [selectedList]);

  return (
    <ControlledDropdown
      className={clsxMerge("dropdown-end w-full", {
        "disabled cursor-not-allowed opacity-60": disabled,
      })}
      closeOnContentClick
    >
      {() => (
        <>
          <label
            tabIndex={0}
            title={dropdownLabel}
            className="mr-1 block w-full text-start text-black/40"
          >
            <div
              className={clsxMerge(
                "flex justify-between",
                "b-typography-body3 truncate text-black/60",
                "rounded-lg border-2 bg-white",
                "w-full p-4"
              )}
            >
              {dropdownLabel}
              <ChevronDownIcon className="w-4" />
            </div>
          </label>

          <div
            tabIndex={0}
            className="dropdown-content menu w-full rounded-lg bg-base-100 shadow-lg"
          >
            {options.map((list, i) => (
              <label
                key={i}
                className={clsxMerge(
                  "label cursor-pointer hover:bg-black/5",
                  "w-full p-4",
                  "ae-typography-body2 label-text"
                )}
                onClick={() => handleListChange(list)}
              >
                <span className="flex">{list.name}</span>

                {selectedList === list && <CheckIcon className="w-4" />}
              </label>
            ))}
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
