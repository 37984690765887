import { FC } from "react";
import { Field } from "formik";
import { Dropdown } from "shared/ui";
import { Checkbox } from "shared/ui/ae-user-input";

const SimpleCheckbox = Checkbox();
const EnhancedDropdown = Dropdown(Field);

interface EmailFollowUpsSectionPropsI {
  className?: string;
  isSendFollowUps?: boolean;
  onChangeFollowUpsToggle?: (isSelected: boolean) => void;
}

export const EmailFollowUpsSection: FC<EmailFollowUpsSectionPropsI> = ({
  className,
  isSendFollowUps = false,
  onChangeFollowUpsToggle = () => {},
}) => {
  return (
    <section className={className}>
      <div className="mb-3 typography-body-3-medium">Follow-ups</div>

      <SimpleCheckbox
        name="send-follow-ups"
        label="Send a follow up message if the prospect does not reply?"
        value={isSendFollowUps}
        inputProps={{
          onChange: () => onChangeFollowUpsToggle(!isSendFollowUps),
        }}
      />

      {isSendFollowUps && (
        <div className="mt-5">
          <div className="typography-body-3-medium">Select frequency</div>

          <EnhancedDropdown
            name="max_follow_ups"
            label="How many follow up messages do you want to send:"
            labelContentClassName="typography-body-4"
            inputClassName="max-w-[243px]"
            options={Array(4)
              .fill(0)
              .map((v, i) => ({ label: (i + 1).toString(), value: i + 1 }))}
          />

          <EnhancedDropdown
            name="follow_up_interval_days"
            label="Number of days to wait before sending a follow up:"
            labelContentClassName="typography-body-4"
            inputClassName="max-w-[243px]"
            options={Array(7)
              .fill(0)
              .map((v, i) => ({ label: (i + 1).toString(), value: i + 1 }))}
          />
        </div>
      )}
    </section>
  );
};
