import { CloseIcon } from "shared/ui/icons";

import { widgets } from "@/widgets/index";
import { WIDGETS } from "@/constants/widgets";
import { clsxMerge } from "shared/lib/helpers";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS, DIALER_STATES } from "@/constants/dialer";

import { useDialerWidgetContext } from "@/context/dialer-v2/widget";
import { useFetcherAddNote } from "./use-fetcher-add-note";
import { Portal } from "react-portal";
import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { dd } from "@/helpers/datadog";
import { LOG_CATEGORIES } from "@/constants/logs";
import { useCountdownTimer } from "shared/lib/hooks/use-countdown-timer";
import { DayJs } from "shared/lib/helpers/date";

const CONFIRM_ACTION_MODAL_ID = "confirm-action-modal-close-widget";

export const DialerWidgetCloseButton = () => {
  const { notes } = useDialerWidgetContext();
  const { call, device, setCallingState } = useCallingContext();
  const { startAt } = useInCallContext();

  const countdown = useCountdownTimer({
    targetDate: DayJs(startAt).add(8, "seconds").toDate(),
    updateInterval: 1000,
  });

  const callStatus = useDialerCallStatus();

  const fetchAddNote = useFetcherAddNote();

  const handleCloseWidget = () => {
    if (notes) fetchAddNote(notes);

    call?.disconnect();
    device?.disconnectAll();

    setCallingState(DIALER_STATES.POST);

    dd.rum.log(
      `${LOG_CATEGORIES.TWILIO}[DISCONNECT] - manual disconnect DialerWidgetCloseButton`
    );

    setTimeout(() => {
      widgets.close({ id: WIDGETS.DIALER });
    }, 1000);
  };

  const openConfirmationModal = () => {
    modalHelpers.open(CONFIRM_ACTION_MODAL_ID);
  };

  return (
    <>
      <button
        className={clsxMerge(
          "btn btn-circle btn-ghost btn-sm",
          "absolute right-1 top-1"
        )}
        onClick={
          callStatus === DIALER_CALL_STATUS.DURING
            ? openConfirmationModal
            : handleCloseWidget
        }
      >
        <CloseIcon className="w-6 text-white/60" />
      </button>

      <Portal>
        <ConfirmActionModal
          title="End Call and Close Widget"
          description="Are you sure you want to end the call and close the dialer widget? This action cannot be undone, and any ongoing conversation will be terminated. Please confirm or cancel your action."
          id={CONFIRM_ACTION_MODAL_ID}
          onConfirm={handleCloseWidget}
          confirmButtonText={
            startAt && countdown !== "00s" ? countdown : "Confirm"
          }
          confirmButtonDisabled={!!(startAt && countdown !== "00s")}
        />
      </Portal>
    </>
  );
};
