import { FC } from "react";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";

import { HeartIcon } from "shared/ui/icons";
import { clsxMerge } from "shared/lib/helpers";

interface FavoriteButtonPropsI {
  className?: string;
  is_favorited?: boolean;
  onFavorite?: () => void;
}

const FavouriteButton: FC<FavoriteButtonPropsI> = ({
  className,
  is_favorited,
  onFavorite = () => {},
}) => {
  return (
    <button className={clsxMerge(className)} onClick={onFavorite}>
      {is_favorited ? (
        <HeartIcon
          className={clsxMerge("w-5 text-[#F88167] hover:animate-pulse")}
        />
      ) : (
        <HeartIconOutline className="hover:animate-wiggle w-5 hover:text-[#F88167]" />
      )}
    </button>
  );
};

export default FavouriteButton;
