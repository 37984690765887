import { FC, useMemo } from "react";
import dayjs from "dayjs";

import { clsxMerge, formatNumbers } from "shared/lib/helpers";
import { createKeyValuePair } from "shared/lib/helpers/utils";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { AccountSummarySubStatus } from "@/modules/pipeline/account-details/primary-content/sections/account-summary-section/sub-status";
import { ContactMetaFields } from "./contact-meta-fields";

interface AccountSummarySectionPropsI {
  className?: string;
}

const formatAccountSummaryData = (
  summaryPiece: undefined | string | number
) => {
  if (typeof summaryPiece === "string") {
    return summaryPiece;
  } else if (typeof summaryPiece === "number") {
    return formatNumbers(summaryPiece);
  }

  return "N/A";
};

export const AccountSummarySection: FC<AccountSummarySectionPropsI> = ({
  className,
}) => {
  const { accountDetailsApi, accountSummary: accountDetailsSummaryData } =
    useAccountDetailsContext();

  const accountDetailsSummary = useMemo(
    () => [
      createKeyValuePair(
        "Last conversation date",
        accountDetailsSummaryData?.last_conversation_date
          ? dayjs(accountDetailsSummaryData?.last_conversation_date).format(
              "M/DD/YY"
            )
          : "N/A"
      ),
      createKeyValuePair(
        "Number of dials",
        accountDetailsSummaryData?.number_of_dials
      ),
      createKeyValuePair(
        "Number of conversations",
        accountDetailsSummaryData?.number_of_conversations
      ),
    ],
    [accountDetailsSummaryData]
  );

  return (
    <div
      className={clsxMerge(
        "flex w-full flex-col gap-4 px-5 py-4",
        "animate-fadein rounded-xl bg-white",
        "@container", // Define CSS container for responsive queries
        className
      )}
    >
      <div className="flex flex-col justify-between gap-2 @xl:flex-row @xl:items-center @xl:gap-0">
        <h4 className="gradient-text typography-header-8-bold">
          Account summary
        </h4>

        <AccountSummarySubStatus
          missedCallContactName={
            accountDetailsSummaryData?.missed_call_contact_name
          }
          missedCallTime={accountDetailsSummaryData?.missed_call_time}
        />
      </div>

      <DataStatesWrapper
        viewName="account summary"
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[140px]" />}
      >
        <div className="flex animate-fadein flex-col gap-4">
          <div>
            <div className="grid grid-rows-3 gap-2.5 @xl:grid-cols-3 @xl:grid-rows-1">
              {accountDetailsSummary?.map((chunk, i) => (
                <div
                  key={i}
                  className="flex flex-col items-center justify-between rounded-xl bg-[#E8F3FF] py-2"
                >
                  <dd className="typography-body-3-bold @xl:typography-body-1-bold">
                    {formatAccountSummaryData(chunk.value)}
                  </dd>

                  <dt className="text-[#333] typography-body-4-semibold">
                    {chunk.key}
                  </dt>
                </div>
              ))}
            </div>
          </div>

          <ContactMetaFields />

          <div>
            <dt className="mb-1 typography-body-4-bold">Summary</dt>
            <dd className="leading-[1.5] typography-body-4">
              {accountDetailsSummaryData?.ai_summary ||
                "No AI summary available"}
            </dd>
          </div>
        </div>
      </DataStatesWrapper>
    </div>
  );
};
