import { useApiClient } from "@/context/api-client";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import toast from "react-hot-toast";

export const QUERY_KEY_START_CALLING_MODAL = "query_key_start_calling_modal";

export const useQueryStartCalling = () => {
  const api = useApiClient();

  const dataApi = useQuery({
    queryKey: [QUERY_KEY_START_CALLING_MODAL],
    queryFn: api.getStartCallingModalData,

    refetchOnMount: true, // This will refetch data on mount
  });

  useEffect(() => {
    if (dataApi.isError) {
      toast.error("Failed to load your lists, please try to reload the page.");
    }
  }, [dataApi.isError]);

  return dataApi;
};
