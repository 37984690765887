import { clsxMerge } from "shared/lib/helpers";

export const SIDEBAR_CONTAINER_ID = "sidebar_v2_container_id";

const LayoutRoot = ({
  children,
  isVisible,
  isFullPage,
  allowPageInteraction,
  onClickOverlay = () => {},
}: {
  children?: any;
  isVisible?: boolean;
  isFullPage?: boolean;
  allowPageInteraction?: boolean;
  onClickOverlay?: () => void;
}) => {
  return (
    <div
      className={clsxMerge(
        "left-0 top-0 z-50 h-full w-full",
        isFullPage ? "fixed" : "absolute",
        allowPageInteraction && "pointer-events-none"
      )}
    >
      {/* Show a background overlay if interaction with rest of page is not allowed */}
      {!allowPageInteraction && (
        <div
          className={clsxMerge(
            "h-full w-full",
            "overlay transition-colors duration-300",
            {
              "bg-black/0": !isVisible,
              "bg-black/30": isVisible,
            }
          )}
          onClick={onClickOverlay}
        />
      )}

      {children}
    </div>
  );
};

const LayoutDrawer = ({
  className,
  isVisible,
  children,
}: {
  className?: string;
  isVisible?: boolean;
  children?: any;
}) => {
  return (
    <div
      id={SIDEBAR_CONTAINER_ID}
      className={clsxMerge(
        "absolute right-0 top-0 h-full overflow-hidden",
        "rounded-lg shadow-xl transition-transform duration-500",
        "flex flex-col",
        "pointer-events-auto",
        isVisible ? "translate-x-[0]" : "translate-x-[100%]",
        className
      )}
    >
      {children}
    </div>
  );
};

export const SidebarLayout = LayoutRoot as typeof LayoutRoot & {
  Drawer: typeof LayoutDrawer;
};

SidebarLayout.Drawer = LayoutDrawer;
