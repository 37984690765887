import { useEffect, useMemo } from "react";
import { Portal } from "react-portal";

import ControlMenu from "@/components/modules/calling/control-menu/v2";
import MainContainer from "@/modules/calling/main-container";
import Sidebar from "@/modules/calling/sidebar";

import UnauthorisedCard from "@/modules/calling/cards/unathorized-card";
import ActivityLog from "@/modules/calling/activity-log";
import { DialerLeadsCarousel as LeadsCarouselV2 } from "@/components/modules/calling/leads-carousel/v2";

import { DIALER_CALL_STATUS, DIALER_STATES } from "@/constants/dialer";

import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import AddNextLeadModal from "@/modules/calling/modals/add-next-lead";
import FollowUpModal from "@/modules/calling/modals/follow-up-modal";
import { CallingModuleBookMeetingModal } from "@/modules/calling/modals/booking-meeting-modal";
import CallingDispositionModal, {
  CALLING_DISPOSITION_MODAL_ID,
} from "@/modules/calling/modals/disposition-modal";

import StripeConnectModal from "@/components/modals/stripe-connect-modal";
import { LiveTransferModal } from "@/modules/calling/modals/live-transfer-modal";
import { modal } from "@/components/modals";
import { CatchupsCompletedModal } from "@/modules/calling/modals/catchups-completed";
import {
  MarkDoneDisqualifyLeadModal,
  MarkDoneModal,
  MarkDoneSendEmailModal,
} from "@/modules/calling/modals/mark-done-modal";
import {
  CatchupsProvider,
  useCatchupsContext,
} from "@/context/dialer-v2/catchups";
import { DialerCancelMeetingModal } from "@/components/modals/dialer-cancel-meeting-modal";
import { CATCHUP_TYPES } from "@/constants/catchup-todos";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";

const DialerCatchupModeContent = () => {
  const { callingState: cState } = useCallingContext();
  const { endAt, callbackData } = useInCallContext();
  const { catchupType, fetchCatchupTodos, todosCount } = useCatchupsContext();
  const callStatus = useDialerCallStatus();

  const areTodosCompleted = useMemo(() => todosCount === 0, [todosCount]);

  useEffect(() => {
    /**
     * Note: if during the call user has set the Callback
     *       should confirm it after the the call ends
     *
     *      Exception is TODO type - Upcomming meeting
     *      When we cancel through the callback we don't want
     *      to show second confirmation
     */
    if (
      endAt &&
      callbackData?.isCallbackSet &&
      catchupType !== CATCHUP_TYPES.MEETING
    ) {
      modal.trigger(CALLING_DISPOSITION_MODAL_ID);
    }
  }, [endAt]);

  useEffect(() => {
    if (cState === DIALER_STATES.LEAD_SELECTION) {
      void fetchCatchupTodos();
    }
  }, [cState]);

  return (
    <section className="flex">
      <MainContainer className="grow">
        <div className="flex h-full w-full justify-between">
          <div className="flex grow flex-col items-center justify-center">
            {DIALER_STATES.UNAUTHORIZED === cState && <UnauthorisedCard />}

            {[
              DIALER_STATES.PRE as string,
              DIALER_STATES.LEAD_SELECTION,
              DIALER_STATES.DIALING,
              DIALER_STATES.CALL,
              DIALER_STATES.DISPOSITION,
              DIALER_STATES.POST,
            ].includes(cState as string) && <LeadsCarouselV2 />}

            {!areTodosCompleted && (
              <ControlMenu className="mb-18 caller control-bar" />
            )}
          </div>

          {!areTodosCompleted && (
            <div className="caller activity-log px-3 py-2">
              <ActivityLog />
            </div>
          )}
        </div>
      </MainContainer>

      {!areTodosCompleted && <Sidebar className="caller sidebar flex-none" />}

      {DIALER_STATES.PRE === cState && <StripeConnectModal />}

      {[
        DIALER_STATES.CALL,
        DIALER_STATES.DISPOSITION,
        DIALER_STATES.POST,
        DIALER_STATES.LEAD_SELECTION,
      ].includes(cState as keyof typeof DIALER_STATES) && (
        <Portal>
          <LiveTransferModal />
          <AddNextLeadModal />
          <FollowUpModal isNotes={callStatus === DIALER_CALL_STATUS.AFTER} />
          <CallingDispositionModal />
          <CallingModuleBookMeetingModal />
          <CatchupsCompletedModal />

          <DialerCancelMeetingModal />

          <MarkDoneModal />
          <MarkDoneSendEmailModal />
          <MarkDoneDisqualifyLeadModal />
        </Portal>
      )}
    </section>
  );
};

export const DialerCatchupMode = () => (
  <CatchupsProvider>
    <DialerCatchupModeContent />
  </CatchupsProvider>
);
