import { FC } from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";
import { safeGetDate } from "shared/lib/helpers";
import { DATE_FORMAT_SHORT } from "shared/lib/constants/time";

interface HistoryCardEventDateBasePropsI {
  className?: string;
  isBold?: boolean;
  date?: string;
}

interface HistoryCardEventDatePropsI extends HistoryCardEventDateBasePropsI {
  title: string;
}

const HistoryCardEventDate: FC<HistoryCardEventDatePropsI> = ({
  className,
  date,
  title,
  isBold,
}) => (
  <HistoryCardTextInfoItem
    icon={<CalendarDaysIcon className="h-6 w-6" />}
    subtitle={title}
    infoClassName={className}
    info={[
      {
        isBold,
        title: date ? safeGetDate(date, DATE_FORMAT_SHORT) : "N/A",
      },
    ]}
  />
);

export const HistoryCardCallbackBookedInfo: FC<
  HistoryCardEventDateBasePropsI
> = ({ date }) => <HistoryCardEventDate date={date} title="Callback date" />;

export const HistoryCardBookedMeetingInfo: FC<
  HistoryCardEventDateBasePropsI
> = ({ isBold, date }) => (
  <HistoryCardEventDate isBold={isBold} date={date} title="Meeting date" />
);

export const HistoryCardBookedMeetingOriginalInfo: FC<
  HistoryCardEventDateBasePropsI
> = ({ isBold, date }) => (
  <HistoryCardEventDate
    isBold={isBold}
    date={date}
    className="line-through"
    title="Original meeting date"
  />
);
