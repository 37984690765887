import { FC, useMemo } from "react";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardCallRecording } from "shared/ui/activity-log/cards-v2/base/call-recording";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import {
  DISPOSITION_SPECIFICS,
  DISPOSITION_SPECIFICS_MAP,
} from "shared/lib/constants/dispositions";

const ACTIVITY_TYPE_DEFAULT_CONFIG = {
  icon: PhoneIcon,
  title: "Call disposition",
};

const HistoryCardCallDispositionV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => {
  const callDisposition = activity.call_disposition;

  const activityTypeConfig = useMemo(() => {
    if (callDisposition) {
      return {
        icon:
          callDisposition === DISPOSITION_SPECIFICS.MORE_MATERIALS
            ? EnvelopeIcon
            : PhoneIcon,
        title: DISPOSITION_SPECIFICS_MAP[callDisposition],
      };
    }

    return ACTIVITY_TYPE_DEFAULT_CONFIG;
  }, [callDisposition]);

  return (
    <AccountHistoryCard createdAt={activity.created_at}>
      <div className="flex flex-col gap-4">
        <HistoryCardActivityType config={activityTypeConfig} />
        <HistoryCardContactInfo contact={activity.contact} />
        <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
      </div>

      <div className="flex flex-col gap-4">
        {activity.call_recording_url?.recording_url && (
          <HistoryCardCallRecording
            recordingURL={activity.call_recording_url?.recording_url}
            transcriptURL={activity.call_transcript_url}
            hadDiscussion={!!activity.call_has_discussion}
          />
        )}

        <HistoryCardCallNotes notes={activity.notes} />
      </div>
    </AccountHistoryCard>
  );
};

export default HistoryCardCallDispositionV2;
