import { useEffect, useState } from "react";
import clsx from "clsx";

import { useTimeout } from "shared/lib/hooks/use-timeout";

export const BG_URLS = {
  V1: `https://glencoco-assets.s3.amazonaws.com/platform/customer/signup/pre-approval-landing.png`,
  V2: `https://glencoco-assets.s3.amazonaws.com/platform/customer/signup/pre-approval-success.png`,
  V3: `https://glencoco-assets.s3.amazonaws.com/platform/customer/signup/post-approval-landing.png`,
  V4: `https://glencoco-assets.s3.amazonaws.com/platform/customer/signup/post-approval-success.png`,
};

interface SignUpBackgroundPropsI {
  url?: string;
  revealDelay?: number;
  className?: string;
}

const AnimatedBackground = ({
  url: _url = BG_URLS.V1,
  revealDelay = 300,
  className,
}: SignUpBackgroundPropsI) => {
  const [url, setUrl] = useState(_url);
  const [isVisible, setIsVisible] = useState(false);

  useTimeout(() => {
    setIsVisible(true);
  }, revealDelay);

  useEffect(() => {
    if (_url !== url) {
      setIsVisible(false);

      setTimeout(() => {
        setUrl(_url);
        setIsVisible(true);
      }, revealDelay);
    }
  }, [_url]);

  return (
    <div
      className={clsx(
        "fixed left-[calc(50%-300px)] top-[-500px] z-[-1] flex h-[2200px] w-[2200px] justify-center",
        "transition-[opacity,transform] duration-500",
        {
          "scale-50 opacity-0": !isVisible,
          "scale-100 opacity-100": isVisible,
        },
        className
      )}
    >
      {!!url && (
        <img
          src={url}
          alt="sign up abstract background"
          className="w-full"
          onLoad={() => setIsVisible(true)}
        />
      )}
    </div>
  );
};

export default AnimatedBackground;
