import {
  EditContactModal,
  EDIT_CONTACT_MODAL_ID,
} from "@/components/modals/edit-contact-modal";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import {
  convertContactDetailToContact,
  convertContactToContactDetail,
} from "@/helpers/contact";
import { dispatchCustomEvent } from "@/helpers/events";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import { Portal } from "react-portal";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ContactDetailI } from "shared/lib/interfaces/account";

const DIALER_WIDGET_EDIT_CONTACT_MODAL_ID = `dialer_widget_${EDIT_CONTACT_MODAL_ID}`;

export const EditLeadButton = ({ className }: { className?: string }) => {
  const { campaign } = useCallingContext();
  const { contact, setContact } = useInCallContext();

  const handleOnSuccessContactUpdate = (data: ContactDetailI) => {
    //TODO FIX this bs with the BE and use single interface for contacts
    setContact(convertContactDetailToContact(data));

    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
    });
  };

  const handleOpenModal = () =>
    modalHelpers.open(DIALER_WIDGET_EDIT_CONTACT_MODAL_ID);

  return (
    <>
      <button
        className={clsxMerge("btn-transparent", className)}
        onClick={handleOpenModal}
      >
        Edit
      </button>

      <Portal>
        <EditContactModal
          modalId={DIALER_WIDGET_EDIT_CONTACT_MODAL_ID}
          campaign={campaign}
          contact={convertContactToContactDetail(contact)}
          onSubmitSuccess={handleOnSuccessContactUpdate}
        />
      </Portal>
    </>
  );
};
