import { useRef, useState } from "react";

import { checkIfClient } from "shared/lib/helpers";
import CallingCalendarForm from "@/components/modules/calling/calendar-form";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

interface FollowUpModalI {
  modalId?: string;
  isFinal?: boolean;
  isNotes?: boolean;
  notes?: string;
  onSuccess?: () => void;
}

export const CALLING_FOLLOWUP_MODAL = "calling-follow-up-modal";

const CallingFollowUpModal = ({
  modalId = CALLING_FOLLOWUP_MODAL,
  isFinal,
  isNotes,
  notes,
  onSuccess: _onSuccess,
}: FollowUpModalI) => {
  const [isVisible, setIsVisible] = useState(false);
  const inCallContext = useInCallContext();
  const { setCallbackData } = inCallContext;
  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  const close = () => {
    if (checkIfClient()) {
      (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
    }
  };

  const onSuccess = () => {
    setCallbackData((current) => ({ ...current, isCallbackSet: true }));

    _onSuccess?.();
    close();
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) => {
          onToogleHandler((event.target as HTMLInputElement)?.checked);
        }}
      />

      <div className="modal cursor-pointer">
        <div className="set-callback-modal modal-box relative max-h-[calc(100vh-50px)] w-[520px] rounded-md p-6">
          <label
            htmlFor={modalId}
            className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 text-black/40"
          >
            ✕
          </label>
          {isVisible && (
            <CallingCalendarForm
              title="Set Callback"
              description="Specify date and time when to remind you to call back"
              isNotes={isNotes}
              notes={notes}
              cancelButton={{ type: "label", htmlFor: modalId, text: "Cancel" }}
              successButton={{
                type: "button",
                text: "Save",
              }}
              onBookingSuccess={onSuccess}
              isLocalSaveOnly={!isFinal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CallingFollowUpModal;
