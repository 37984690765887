export const processLink = (element: Element) => {
  if (element.tagName === "A") {
    element.setAttribute("target", "_blank");
    element.setAttribute("rel", "noopener noreferrer");
  }
};

export const processVideoEmbed = (element: Element) => {
  if (element.tagName === "FIGURE") {
    const oEmbedElement = element.children.item(0);
    const contentURL = oEmbedElement?.getAttribute("url");

    if (oEmbedElement?.tagName === "OEMBED" && contentURL) {
      const videoContainer = document.createElement("div");
      videoContainer.className = "video-container";
      videoContainer.setAttribute("data-video-url", contentURL);

      element.parentNode?.insertBefore(videoContainer, element);
      element.remove();
    }
  }
};
