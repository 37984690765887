import { useFrequentlyChangingCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useEffect, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";

export const CallConnectionQuality = () => {
  const [connectionQuality, setConnectionQuality] = useState(3);
  const { callQuality } = useFrequentlyChangingCallingContext();

  useEffect(() => {
    if (callQuality < 1) {
      setConnectionQuality(0);
    } else if (callQuality < 2) {
      setConnectionQuality(1);
    } else if (callQuality < 3.5) {
      setConnectionQuality(2);
    } else {
      setConnectionQuality(3);
    }
  }, [callQuality]);

  return (
    <div className="flex items-baseline gap-0.5">
      {[1, 2, 3].map((quality) => (
        <div
          key={quality}
          className={clsxMerge(
            "w-1",
            quality === 1 && "h-[6px]",
            quality === 2 && "h-[9px]",
            quality === 3 && "h-[12px]",
            quality <= connectionQuality ? "bg-white" : "bg-white/60"
          )}
        />
      ))}
    </div>
  );
};
