import { FC, ReactNode } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { CloseIcon } from "shared/ui/icons";

export const SIDEBAR_CLOSE_BTN_ID = "sidebar_v2_close_btn_id";

interface SidebarHeaderI {
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  className?: string;
  onClose?: () => void;
}

export const SidebarHeader: FC<SidebarHeaderI> = ({
  title,
  subTitle,
  className,
  onClose = () => {},
}) => {
  return (
    <div
      className={clsxMerge(
        "flex items-center justify-between",
        "b-typography-h3",
        "px-6 py-4",
        className
      )}
    >
      <div>
        {title}
        {subTitle && <p className="b-typography-body3 mt-1">{subTitle}</p>}
      </div>

      <button
        id={SIDEBAR_CLOSE_BTN_ID}
        className="btn-ghost btn-sm btn-circle btn"
        onClick={onClose}
      >
        <CloseIcon className="h-4 w-4 text-black/60" />
      </button>
    </div>
  );
};
