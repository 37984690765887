import { AxiosInstance, AxiosResponse } from "axios";
import { UserI } from "@/interfaces/user";
import { UserApplicationStatusTypeI } from "@/interfaces/signup";

export type UserReferralSourceTypeI =
  | "LinkedInSignUpForm"
  | "LinkedInDirectMessage"
  | "GoogleSponsoredAd"
  | "GoogleSearch"
  | "Discord"
  | "Craigslist"
  | "TikTok"
  | "Twitter"
  | "Instagram"
  | "YouTubeShorts"
  | "Reddit"
  | "DirectReferral"
  | "Other";

export interface GetUserResponseI {
  user: UserI;
  intercom_payload?: any;
  application_status?: UserApplicationStatusTypeI;
}

export interface UserUpdateResponseI {
  user: UserI;
}

export interface UserPatchParamsI {
  patch_user: {
    email?: string;
    first_name?: string;
    last_name?: string;
    about?: string;
    linkedin_profile?: string;
    phone_number?: string;
    address?: string;
    state_of_residence?: string;
    general_knowledge?: string[];
    current_situation?: string;
    job_experience_description?: string;
    gig_work_experience?: string;
    sales_experience?: boolean;
    cold_call_experience?: boolean;
    available_10_hours?: boolean;
    full_time_consideration?: boolean;
    referral_source?: UserReferralSourceTypeI;
    referral_source_other?: string;
  };
  final_submit: boolean;
}

export interface UserApiI {
  getUser(): Promise<AxiosResponse<GetUserResponseI>>;

  setUser(
    params: UserPatchParamsI
  ): Promise<AxiosResponse<UserUpdateResponseI>>;
}

// NOTE: whenever we need route strings explicitly
//       absolutely unique cases
export const API_ROUTES = {
  GET_USER: `/v2/me`,
  GET_CAMPAIGNS: `/v2/campaigns`,
} as const;

export const UserAPIEndpoints = (API: AxiosInstance): UserApiI => ({
  getUser: () => API.get(API_ROUTES.GET_USER),
  setUser: (params) => API.patch(`/v3/me`, { ...params }),
});
