import { BaseControlButton } from "@/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import { useAddUserToListSidebarContext } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";

export const ListButton = () => {
  const { onOpen } = useAddUserToListSidebarContext();

  return (
    <BaseControlButton onClick={onOpen}>
      <ListBulletIcon className="mb-1 w-5" />
      <span>Add to list</span>
    </BaseControlButton>
  );
};
