import { useEffect } from "react";

import { HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID } from "@/constants/element-ids";
import { checkIfClient } from "shared/lib/helpers";

export const getScrollElement = (customScrollElementId?: string) =>
  checkIfClient()
    ? document.querySelector(
        `#${customScrollElementId || HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID}`
      )
    : undefined;

export const useOnPageScroll = (
  handleScroll: () => void,
  useEffectDependencies: Array<any> = [],
  customScrollElementId?: string
) => {
  return useEffect(() => {
    if (checkIfClient()) {
      const container = getScrollElement(customScrollElementId);

      container?.addEventListener("scroll", handleScroll);

      return () => {
        container?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [useEffectDependencies]);
};
