import { FC, useMemo } from "react";

import { clsxMerge, getFullEntityName } from "shared/lib/helpers";
import { useLiveTime } from "shared/lib/hooks/use-live-time";

import { LeadCardAvatar } from "@/components/modules/calling/cards/lead-card/v2/lead-avatar";
import { LeadCardV2MetadataI } from "@/components/modules/calling/cards/lead-card/v2";

import { usePersistedCatchupsData } from "@/modules/calling/hooks/use-persisted-catchups-data";
import { DialerConnectionLabel } from "@/components/modules/calling/cards/lead-card/v2/lead-avatar/connection-label";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import {
  CopyToClipboard,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared/ui";
import { LinkedinBadge } from "../v1/linkedin-badge";

interface LeadGeneralInfoPropsI {
  className?: string;
  avatarClassName?: string;
  connectionLabelClassName?: string;
  contactNameClassName?: string;
  linkedinClassName?: string;
  primaryContactInfoClassName?: string;
  primaryContactInfoWrapperClassName?: string;
  contactTitleCompanyNameClassName?: string;
  localTimeClassName?: string;
  emailClassName?: string;

  hideMetadata?: boolean;
  hideDialerConnectionLabel?: boolean;
  /**
   * The inconsistency with boolean variables
   * is to enchance UI with additional values
   * without introducing regression
   */
  isShowEmail?: boolean;
  isShowLinkedIn?: boolean;
}

export const LeadGeneralInfo: FC<LeadGeneralInfoPropsI> = ({
  className,
  avatarClassName,
  contactNameClassName,
  linkedinClassName,
  connectionLabelClassName,
  primaryContactInfoClassName,
  primaryContactInfoWrapperClassName,
  contactTitleCompanyNameClassName,
  emailClassName,
  localTimeClassName,
  hideMetadata,
  hideDialerConnectionLabel,
  isShowEmail,
  isShowLinkedIn,
}) => {
  const callStatus = useDialerCallStatus();
  const { contact, account, metadata } = usePersistedCatchupsData();

  const fullContactName = useMemo(() => getFullEntityName(contact), [contact]);
  const currentTime = useLiveTime({
    dateFormat: "",
    timezone: (metadata as LeadCardV2MetadataI)?.contactLocalTimeZone,
  });
  const isIngestionDataAvailable =
    contact?.meta?.values && contact?.meta?.values.length > 0;

  const isCallInProgress = callStatus === DIALER_CALL_STATUS.DURING;

  return (
    <div className={clsxMerge("flex flex-col items-center", className)}>
      <div className="relative">
        <LeadCardAvatar
          contactName={fullContactName}
          className={avatarClassName}
        />

        {!hideDialerConnectionLabel && isCallInProgress && (
          <DialerConnectionLabel
            className={clsxMerge(
              "absolute left-1/2 top-[140px] z-10 -translate-x-1/2",
              connectionLabelClassName
            )}
          />
        )}
      </div>

      <div
        className={clsxMerge(
          "mt-4 flex w-full justify-center",
          primaryContactInfoWrapperClassName
        )}
      >
        <div
          className={clsxMerge(
            "flex w-[90%] flex-col gap-2 text-white",
            primaryContactInfoClassName
          )}
        >
          <h4
            className={clsxMerge(
              {
                "brand-typography-h4-bold":
                  !contactNameClassName?.includes("typography"),
              },
              contactNameClassName
            )}
          >
            <span
              className="overflow-hidden text-ellipsis"
              title={fullContactName}
            >
              {fullContactName}{" "}
              {isShowLinkedIn && (
                <span className=" relative top-[-2px] inline-block">
                  <LinkedinBadge className={linkedinClassName} />
                </span>
              )}
            </span>
          </h4>
          <p
            className={clsxMerge(
              "brand-typography-h6",
              contactTitleCompanyNameClassName
            )}
          >
            {contact?.title || "N/A"} @ {account?.name || "N/A"}
          </p>
          {isShowEmail && (
            <CopyToClipboard
              textToCopy={contact?.email}
              className={clsxMerge(
                "brand-typography-body3 flex items-center",

                emailClassName
              )}
            >
              <Tooltip placement="right">
                <TooltipTrigger>
                  <span className="badge relative mr-1 mt-[-2px] border-none bg-black/10 px-2 font-semibold text-black">
                    <span className="relative top-[-1px]">@</span>
                  </span>

                  <span
                    className="overflow-hidden text-ellipsis"
                    title={contact?.email}
                  >
                    {contact?.email}
                  </span>
                </TooltipTrigger>
                <TooltipContent className="z-[999]">
                  Click to Copy "{contact?.email}"
                </TooltipContent>
              </Tooltip>
            </CopyToClipboard>
          )}
          <p
            className={clsxMerge(
              "brand-typography-detail1 text-[#E3E3E3]",
              localTimeClassName
            )}
          >
            Local time for contact {currentTime}
          </p>
        </div>
      </div>

      {!hideMetadata && (
        <div
          className={clsxMerge(
            "mt-5 flex h-[152px] w-full flex-col",
            "rounded-[10px] bg-black/10 p-3 backdrop-blur-[25px]",
            "overflow-y-auto text-white",
            {
              "items-center justify-center": !isIngestionDataAvailable,
            }
          )}
        >
          {!isIngestionDataAvailable && (
            <span className="brand-typography-detail1">
              No additional context about this contact
            </span>
          )}

          {contact?.meta?.values?.map((meta, index) => (
            <div key={index} className="mt-4">
              <p className="brand-typography-detail1">{meta.label}</p>
              <p className="brand-typography-body2">{meta.value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
