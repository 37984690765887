import { useState } from "react";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { getTourSteps } from "./steps";

import CallingFollowUpModal from "@/modules/calling/modals/follow-up-modal";
import { DIALER_MODES, DIALER_STATES } from "@/constants/dialer";
import {
  CALLER_PHONE,
  EMPTY_ACCOUNT,
  MOCK_ACCOUNT,
  MOCK_ACTIVITY_LOG,
  MOCK_CONTACT,
} from "./mock-data";
import { LocalStorage } from "@/helpers/local-storage";
import StartDialerTourModal, {
  START_DIALER_TOUR_MODAL_ID,
} from "@/components/modals/start-dialer-tour-modal";
import { useEffectOnce } from "shared/lib/hooks";
import { triggerModal } from "@/helpers/utils";
import { CUSTOM_EVENTS } from "@/constants/custom-events";

const driverObj = driver({
  popoverClass: "driverjs-ae driverjs-theme",
  nextBtnText: "next",
  prevBtnText: "previous",
  disableActiveInteraction: true,
  allowKeyboardControl: false,
  allowClose: false,
});

const CallingTour = ({
  mode = DIALER_MODES.DEFAULT,
}: {
  mode?: (typeof DIALER_MODES)[keyof typeof DIALER_MODES];
}) => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();

  const { isCallOnDemand } = callingContext;

  const [isTourActive, setIsTourActive] = useState(false);

  const initiateTour = () => {
    setIsTourActive(true);
    setCallMockData();
    callingContext.setCallingState(DIALER_STATES.CALL);
    setTourSteps();
    startTour();

    setDialerTourVisitedDate();
  };

  const endTour = () => {
    if (mode !== DIALER_MODES.DEMO) {
      setIsTourActive(false);
      callingContext.setCallingState(DIALER_STATES.PRE);
      clearCallMockData();
    }
  };

  const setCallMockData = () => {
    inCallContext.setCallerPhone(CALLER_PHONE);
    inCallContext.setContact(MOCK_CONTACT);
    inCallContext.setAccount(MOCK_ACCOUNT);
    inCallContext.setActivityLog(MOCK_ACTIVITY_LOG);
  };

  const clearCallMockData = () => {
    inCallContext.setCallerPhone("");
    inCallContext.setContact(EMPTY_ACCOUNT);
    inCallContext.setAccount(EMPTY_ACCOUNT);
    inCallContext.setActivityLog([]);
  };

  const setTourSteps = () => {
    driverObj.setConfig({
      popoverClass: "driverjs-ae driverjs-theme",
      nextBtnText: "next",
      prevBtnText: "previous",
      disableActiveInteraction: true,
      allowClose: false,
      allowKeyboardControl: false,
      onPopoverRender(popover) {
        const doneButton = document.createElement("div");
        doneButton.innerText = "Skip tour";
        popover.footerButtons.prepend(doneButton);

        doneButton.addEventListener("click", () => {
          endTour();
          driverObj.destroy();
        });
      },
    });

    const steps = getTourSteps(driverObj, callingContext, endTour);

    driverObj.setSteps(steps);
  };

  const startTour = () => {
    setTimeout(() => {
      driverObj.drive();
    }, 1200);
  };

  const setDialerTourVisitedDate = () => {
    const LS = new LocalStorage();

    if (mode !== DIALER_MODES.DEMO) {
      callingContext.setIsTourComplete(true);
      LS.dialerTourCompletionDate = new Date().toString();
    }
  };

  useEffectOnce(() => {
    const LS = new LocalStorage();

    callingContext.setIsTourComplete(!!LS.dialerTourCompletionDate);

    if (mode === DIALER_MODES.DEMO || !LS.dialerTourCompletionDate) {
      // TODO why delay ???
      setTimeout(() => triggerModal(START_DIALER_TOUR_MODAL_ID), 1000);
    }
  });

  useEffectOnce(() => {
    window.document.addEventListener(
      CUSTOM_EVENTS.CALLING_MODULE.TOUR.START_TOUR,
      initiateTour
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.CALLING_MODULE.TOUR.START_TOUR,
        initiateTour
      );
    };
  });

  return (
    <>
      {!isCallOnDemand && (
        <StartDialerTourModal
          title="Welcome to the Dialer"
          description="Take a quick tour of the environment you'll be in after you get connected with a live call! This will familiarize you with the tools at your disposal to conduct successful sales calls."
          cancelButtonText="SKIP"
          mainButtonText="START TOUR"
          callback={initiateTour}
          onCancel={() => {
            setDialerTourVisitedDate();
          }}
        />
      )}

      {isTourActive && (
        <>
          <CallingFollowUpModal />
        </>
      )}
    </>
  );
};

export default CallingTour;
