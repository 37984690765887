import { DetailedHTMLProps, LabelHTMLAttributes } from "react";
import { clsxMerge } from "shared/lib/helpers";

export const BasicButton = ({
  className,
  children,
  onClick,
  ...props
}: {
  className?: string;
  children?: any;
  onClick?: () => void;
} & DetailedHTMLProps<
  LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>) => {
  return (
    <label
      className={clsxMerge(
        "cursor-pointer",
        "h-[58px] w-[100px] px-0",
        "flex flex-col items-center justify-center",
        {
          "btn btn-ghost bg-white text-black/30 hover:bg-white/60":
            !className?.includes("btn"),
        },
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </label>
  );
};
