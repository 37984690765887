import { useEffect, useState } from "react";
import clsx from "clsx";

import { glencocoClientAPI, GetDialerLeadResponseI } from "@/api/glencoco";

import { handleErrorMessage } from "@/helpers/error";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import LeadCard from "@/components/modules/calling/cards/lead-card/v1";
import { LeadsCarouselActionsSection as ActionsSection } from "@/components/modules/calling/leads-carousel/v1/actions-section";

import { LeadsCarouselSkipButton as SkipButton } from "@/modules/calling/leads-carousel/v1/skip-button";
import { AxiosError, AxiosResponse } from "axios";
import { LeadsCarouselDecorations as Decorations } from "@/components/modules/calling/leads-carousel/v1/decorations";
import { LoadingComponent } from "@/modules/loading";
import { ERROR_CODES } from "@/constants/errors";
import Calling from "@/helpers/calling";
import {
  DIALER_SKIP_LEAD_MODAL_ID,
  DialerSkipLeadModal,
} from "@/components/modals/dialer-skip-lead-modal";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

// FLOW FOR GETTING DIALER LEAD DATA:
// 1. POST /skip_current_lead (optional if caller wants to skip)
// 2. POST /get_working_contact
//    2.1. if 404 & error_code == 1096 (no working contact found), then POST /get_next_contact
//    2.2. POST /get_working_contact again to get the current working contact

export const DialerLeadsCarousel = () => {
  const context = useCallingContext();
  const inCallContext = useInCallContext();
  const { campaignId } = context;
  const { setAccount, setContact, setActivityLog } = inCallContext;

  const [isLoading, setIsLoading] = useState(true);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [transitionTriggerKey, setTransitionTriggerKey] = useState("");
  const [skipAvailableInSeconds, setSkipAvailableInSeconds] = useState(0);

  const getLeadData = async (
    campaignId: string
  ): Promise<AxiosResponse<GetDialerLeadResponseI | AxiosError>> => {
    const API = glencocoClientAPI();

    const startAt = new Date();

    setIsLoading(true);
    setIsShowLoading(true);

    const GetLeadResponse = await API.getDialerCurrentLead(campaignId).catch(
      (e) => e
    );

    const endAt = new Date();
    const timeElapsed = endAt.valueOf() - startAt.valueOf(); //in ms

    if (GetLeadResponse.status === 200) {
      const data = GetLeadResponse.data as GetDialerLeadResponseI;

      setAccount(data.account);
      setContact(data.contact);
      setSkipAvailableInSeconds(data.skip_cooldown_counter);

      if (data.activity_log) setActivityLog(data.activity_log);

      if (timeElapsed < 500) {
        setTimeout(() => {
          setIsLoading(false);
          setIsShowLoading(false);
        }, 500);
      } else {
        setIsLoading(false);
        setIsShowLoading(false);
      }

      return GetLeadResponse;
    } else {
      const data = GetLeadResponse?.data as GetDialerLeadResponseI;

      if (
        [
          data?.error_code,
          GetLeadResponse?.response?.data?.error_code as number,
        ].includes(ERROR_CODES.NO_CURRENT_WORKING_CONTACT) &&
        !data?.account &&
        !data?.contact
      ) {
        // Handle error for no current working contact -> Move to the next working contact in queue.
        moveToNextLead(campaignId);
      } else {
        // Handle all other errors (that we are not expecting)
        setIsShowLoading(false);
        handleErrorMessage(
          GetLeadResponse?.data?.error_code as number,
          "Failed to get lead data"
        );
      }

      if (
        data?.skip_cooldown_counter ||
        GetLeadResponse?.response?.data?.skip_cooldown_counter
      ) {
        setSkipAvailableInSeconds(
          data?.skip_cooldown_counter ||
            GetLeadResponse?.response?.data?.skip_cooldown_counter
        );
      }

      return GetLeadResponse;
    }
  };

  const moveToNextLead = async (campaignId: string) => {
    const API = glencocoClientAPI();

    const MoveToNextLeadResponse = await API.dialerMoveToNextLead(
      campaignId
    ).catch(() => ({ status: null, data: null }));

    if (MoveToNextLeadResponse.status === 200) {
      getLeadData(campaignId);
      return true;
    } else {
      handleErrorMessage(
        MoveToNextLeadResponse?.data?.error_code as number,
        "Failed to find next lead in queue"
      );

      Calling.cleanupAndExit({ context, inCallContext });
      return false;
    }
  };

  const handleSkipAndPrepareForNextLead = (
    note: string,
    shouldDisqualify: boolean
  ) => {
    (async () => {
      if (campaignId) {
        setTransitionTriggerKey(Math.random().toString());

        const API = glencocoClientAPI();
        setIsLoading(true);

        const skipLeadResonse = await API.dialerSkipCurrentLead(campaignId, {
          reason: note,
          should_disqualify: shouldDisqualify,
        }).catch(() => ({ status: null, data: null }));

        if (skipLeadResonse.status === 200) {
          getLeadData(campaignId);
        } else {
          handleErrorMessage(skipLeadResonse?.data?.error_code);
          Calling.cleanupAndExit({ context, inCallContext });
        }
      }
    })();
  };

  const triggerSkipLeadModal = () => {
    modalHelpers.trigger(DIALER_SKIP_LEAD_MODAL_ID);
  };

  const transitionToNextLead = () => {
    if (campaignId) {
      setTransitionTriggerKey(Math.random().toString());
      moveToNextLead(campaignId);
    }
  };

  useEffect(() => {
    if (campaignId) {
      getLeadData(campaignId);
    }
  }, [campaignId]);

  return (
    <>
      <section
        className={clsx("relative", "transition-opacity duration-300", {
          // "opacity-0": isLoading,
          // "opacity-100": !isLoading,
        })}
      >
        <Decorations uniqueKeyToTriggerTansition={transitionTriggerKey} />

        <div
          className={clsx("z-1 relative h-full w-full rounded-lg", "bg-white")}
        >
          <div
            className={clsx("relative min-w-[430px]", {
              "animate-swipeleft": isLoading,
              "left-0 opacity-100 ": !isLoading,
            })}
          >
            <div
              className={clsx("transition-opacity duration-300", {
                "opacity-0": isLoading,
                "opacity-100 ": !isLoading,
              })}
            >
              <LeadCard
                hideConnection
                hideNextLeadSection
                actionsSection={
                  <ActionsSection onAddToListSuccess={transitionToNextLead} />
                }
              />
            </div>
          </div>

          {isShowLoading && (
            <div className="absolute left-[calc(50%-25px)] top-[calc(50%-25px)]">
              <LoadingComponent />
            </div>
          )}
        </div>

        <div
          className={clsx(
            "absolute top-[calc(50%-40px)] transition-all duration-300",
            {
              "-right-[125px]": skipAvailableInSeconds,
              "-right-[20px]": !skipAvailableInSeconds,
            }
          )}
        >
          <SkipButton
            onClick={triggerSkipLeadModal}
            onEndCountdown={() => setSkipAvailableInSeconds(0)}
            skipAvailableInSeconds={skipAvailableInSeconds}
          />
        </div>
      </section>

      <DialerSkipLeadModal onSubmit={handleSkipAndPrepareForNextLead} />
    </>
  );
};
