import { WebsocketInstanceI } from "@/interfaces/websockets";

// TODO: extend this function to look thorugh multiple sockets that may be running and find the instance of the correct one
export const getWebsocket = (
  websocketType: string
): WebsocketInstanceI | undefined => {
  const ws = (<any>window).WSInstance as WebsocketInstanceI;

  if (ws?.url === websocketType) {
    return ws;
  }
};
