import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

import { LeadInfoI } from "@/interfaces/dialer/lead-info";
import { Ellipsis } from "@/components/base/ellipsis";
import { MapPinIcon } from "@heroicons/react/24/outline";

import { USA_STATES } from "@/constants/states";

const LeadData = (lead: LeadInfoI) => (
  <div className="flex flex-col">
    <span className="ae-typography-detail1 mb-1">{lead.name}</span>
    <span className="ae-typography-detail1 mb-1 text-white/50">
      {lead.title} @ {lead.company_name}
    </span>

    {(lead.timezone_info || lead.company_state) && (
      <span className="ae-typography-detail1 flex items-center text-white/50">
        <MapPinIcon className="w-4" />{" "}
        {lead.timezone_info ||
          USA_STATES.find((s) => s.abbr === lead.company_state?.toUpperCase())
            ?.label ||
          lead.company_state}
      </span>
    )}
  </div>
);

const DialingLead = ({
  className,
  lead: _lead,
  isAnimated,
}: {
  className?: string;
  lead: LeadInfoI;
  isAnimated?: boolean;
  index?: number;
}) => {
  const [lead, setLead] = useState(_lead);
  const [tempLead, setTempLead] = useState(_lead);
  const [isTransition, setIsTransition] = useState(false);

  useEffect(() => {
    setTempLead(lead);
    setLead(_lead);
    setIsTransition(true);

    setTimeout(() => {
      setTempLead(_lead);
      setIsTransition(false);
    }, 1000);
  }, [_lead]);

  return (
    <div
      className={twMerge(
        clsx("mb-3 flex w-full items-center justify-between py-3", className)
      )}
    >
      <div className="relative w-full">
        <div
          className={clsx(
            "relative",
            "transition-[opacity,left] duration-1000",
            {
              "left-2 opacity-0 transition-none": !isTransition,
              "left-0 opacity-100": isTransition,
            }
          )}
        >
          <LeadData {...lead} />
        </div>
        <div
          key={tempLead.id}
          className={clsx(
            "absolute left-0 top-0",
            "transition-opacity duration-1000",
            {
              "opacity-100  transition-none": !isTransition,
              "opacity-0": isTransition,
            }
          )}
        >
          <LeadData {...tempLead} />
        </div>
      </div>
      <div>
        <Ellipsis effect={isAnimated ? "bounce" : ""} />
      </div>
    </div>
  );
};

export default DialingLead;
