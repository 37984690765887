import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { DialerWidgetStateI } from "./interface";
import { PRIMARY_NAV_HIEGHT } from "./constants";
import toast from "react-hot-toast";

export const CLOSE_WIDGET_ERROR_MESSAGE =
  "Please complete the current call before closing this widget.";

export const dialerWidgetConditionalCloseAction = (
  state?: DialerWidgetStateI,
  closeWidget?: () => void
) => {
  if (state?.callStatus === DIALER_CALL_STATUS.DURING) {
    toast.error(CLOSE_WIDGET_ERROR_MESSAGE);

    return;
  }

  closeWidget?.();
};

export const dialerWidgetDetectIsScrollbar = (selector?: string) => {
  const element = window.document.querySelector(
    selector as string
  ) as HTMLElement;

  const viewportHeight =
    (window.visualViewport?.height || 0) - PRIMARY_NAV_HIEGHT;
  const contentHeight = element?.scrollHeight || 0;

  return contentHeight > viewportHeight;
};
