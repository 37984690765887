import { FC, useEffect, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import clsx from "clsx";
import _debounce from "lodash/debounce";

import TextFieldFactory from "shared/ui/user-input/text-field";
import TextAreaFactory from "shared/ui/user-input/text-area";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { getEmailsFromString, yupEmailListSchema } from "shared/lib/helpers";
import { ContactDetailI } from "@/interfaces/accounts";
import { useAccountSendEmail } from "@/api/routes/account/mutations";
import { useLocalStorage } from "@/context/local-storage";

export interface AccountSendEmailModalPropsI {
  modalId?: string;
  campaignId: string;
  contact: ContactDetailI;
  onSuccess: () => void;
}

interface AccountSendEmailFormValuesI {
  emailsRaw: string;
  notes: string;
}

export const ACCOUNT_SEND_EMAIL_MODAL_ID = "account-send-email-modal";

const Input = TextFieldFactory(Field);
const Textarea = TextAreaFactory(Field);

const sendAccountContactEmailValidationSchema = Yup.object().shape({
  emailsRaw: yupEmailListSchema,
  notes: Yup.string().required("Note is required"),
});

// TODO - Replace that with a more intuitive way to handle form changes. That
// should be considered as an anti-pattern.
const OnFormChange: FC<{ values: AccountSendEmailFormValuesI }> = ({
  values,
}) => {
  const storage = useLocalStorage();

  const debouncedPersistEmailContent = useMemo(
    () =>
      _debounce((value: string) => {
        storage.emailModalContent = value;
      }, 500),
    []
  );

  useEffect(() => {
    debouncedPersistEmailContent(values.notes);
  }, [values.notes]);

  return null;
};

const AccountSendEmailModalContent: FC<
  ModalChildrenFnPropsI & AccountSendEmailModalPropsI
> = ({ handleClose, onSuccess, campaignId, contact }) => {
  const { mutateAsync: requestSendEmail, isPending } = useAccountSendEmail();
  const storage = useLocalStorage();

  const formInitialValues: AccountSendEmailFormValuesI = useMemo(
    () => ({
      emailsRaw: contact.email || "",
      notes: storage.emailModalContent ?? "",
    }),
    [contact.email]
  );

  const handleSubmit = ({ notes, emailsRaw }: AccountSendEmailFormValuesI) => {
    if (!campaignId || !contact?.id) {
      toast.error("Campaign ID and Contact ID are required to send an email.");

      return;
    }

    return requestSendEmail(
      {
        campaignId,
        contactId: contact.id as string,
        request: {
          emails: getEmailsFromString(emailsRaw),
          notes,
        },
      },
      {
        onSuccess: () => {
          toast.success("Email has been requested successfully!");
          storage.emailModalContent = null;
          onSuccess();
          handleClose();
        },
        onError: () =>
          toast.error("Failed to send email. Please try again later."),
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={sendAccountContactEmailValidationSchema}
      validateOnMount
    >
      {({ values, errors, touched, isValid }) => (
        <Form className="flex flex-col">
          <OnFormChange values={values} />

          <Input
            name="emailsRaw"
            label="Send Followup Email(s) To (comma-separated):"
            placeholder="Enter email addresses for a followup email to be sent"
            errors={errors.emailsRaw}
            touched={touched.emailsRaw}
          />

          <Textarea
            name="notes"
            label="Share notes to the AE about what to put in the email"
            placeholder="Share any notes you have about what to put in the email"
            className="mb-4"
            errors={errors.notes}
            touched={touched.notes}
          />

          <div className="flex justify-end gap-2">
            <button type="button" className="btn-nofill" onClick={handleClose}>
              Cancel
            </button>

            <button
              type="submit"
              className={clsx("btn-ae-default w-[150px]", {
                disabled: isPending || !isValid,
              })}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const AccountSendEmailModal: FC<AccountSendEmailModalPropsI> = ({
  modalId,
  ...props
}) => (
  <Modal id={modalId || ACCOUNT_SEND_EMAIL_MODAL_ID} title="Email">
    {(modalContentProps) => (
      <AccountSendEmailModalContent {...modalContentProps} {...props} />
    )}
  </Modal>
);
