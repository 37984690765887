import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

export const Spinner: FC<PropsWithClassNameI> = ({ className }) => (
  <span
    className={clsxMerge(
      "btn loading btn-link cursor-default text-black",
      className
    )}
  />
);
