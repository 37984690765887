import toast from "react-hot-toast";

import { useLongPolling } from "shared/lib/hooks";
import { useGetEmailGenerationStatus } from "@/modules/email-templates-sidebar/queries";
import { GetEmailGenerationStatusResponseI } from "@/api/routes/email";

interface PollEmailGenerationStatusI {
  emailId: string;
  onComplete?: (generatedEmailData: GetEmailGenerationStatusResponseI) => void;
  onFailed?: () => void;
}

const PollEmailGenerationStatus = ({
  emailId,
  onComplete = () => {},
  onFailed = () => {},
}: PollEmailGenerationStatusI) => {
  const { mutateAsync: getEmailGenerationStatusAsync } =
    useGetEmailGenerationStatus();

  useLongPolling(
    async () => {
      if (emailId) {
        const resp = await getEmailGenerationStatusAsync(emailId);

        const data = resp?.data as GetEmailGenerationStatusResponseI;

        if (resp.status === 200) {
          if (data.is_complete) {
            onComplete(data);
          }
        } else {
          toast.error("Error getting email generation status");
          onFailed();
        }
      }
    },
    1000,
    [emailId]
  );

  return null;
};

export default PollEmailGenerationStatus;
