import { useRef, useState } from "react";
import clsx from "clsx";
import { FormikProps } from "formik";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { ChevronDownIcon } from "shared/ui/icons";

import { DilaerNextLeadCard } from "@/components/modules/calling/cards/lead-card/v1/next-lead/card";

import {
  AddNextLeadFormI,
  ADD_NEXT_LEAD_MODAL_ID,
} from "@/modules/calling/modals/add-next-lead";

export const DialerNextLeadSection = () => {
  const callingContext = useCallingContext();
  const addNextLeadFormikRef = useRef<FormikProps<AddNextLeadFormI>>();

  const [isNextLeadVisible, setIsNextLeadVisible] = useState(false);

  return (
    <div className="ae-typography-body3 mt-3 flex justify-center">
      {callingContext.leadQueue.length > 0 ? (
        <div>
          <div
            className="mb-3 flex cursor-pointer items-center justify-center"
            onClick={() => setIsNextLeadVisible(!isNextLeadVisible)}
          >
            <span className="mr-2 text-white">View Next Lead</span>
            <ChevronDownIcon
              className={clsx("h-2 w-3 text-white duration-300", {
                "rotate-180": isNextLeadVisible,
                "rotate-0": !isNextLeadVisible,
              })}
            />
          </div>
          <div
            className={clsx(
              `transition-[max-height, opacity] max-h-0 overflow-hidden duration-500 ease-in`,
              {
                "max-h-[200px] opacity-100": isNextLeadVisible,
                "max-h-0 opacity-0": !isNextLeadVisible,
              }
            )}
          >
            <DilaerNextLeadCard
              nextLead={callingContext.leadQueue[0]}
              isVisible={isNextLeadVisible}
              setVisibility={setIsNextLeadVisible}
              handleFormikReset={() =>
                addNextLeadFormikRef.current?.resetForm()
              }
            />
          </div>
        </div>
      ) : (
        <Tooltip placement="bottom">
          <TooltipTrigger className="mb-2">
            <label htmlFor={ADD_NEXT_LEAD_MODAL_ID} className="cursor-pointer">
              <span className="add-next-lead-btn text-white">
                + Create Newly Discovered Lead
              </span>
            </label>
          </TooltipTrigger>
          <TooltipContent>
            <div className="max-w-[300px] text-center">
              Sometimes the gatekeeper or person on the phone will pass you
              information about who the right person is to reach. You can use
              this to fill out this information and call them right away. You
              can also save this lead to work on your own. Email, Phone Number,
              Name, and Title are required fields for cleanliness of data. If
              you cannot find the email, you can input a generic email address
              with the business's email domain for now.
            </div>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};
