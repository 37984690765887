import { clsxMerge } from "shared/lib/helpers";
import { LeadInfoSection } from "./lead-info-section";
import { CampaignInfoSection } from "./campaign-info-section";

import { CallerNotes as Notes } from "@/modules/calling/modes/widget-mini-dialer/caller-notes";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useMemo } from "react";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { DispositionSection } from "./disposition-section";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

export const InfoSections = ({ className }: { className?: string }) => (
  <section
    className={clsxMerge(
      "w-full animate-fadein px-6",
      "bg-black/30 text-white",
      "mt-6 pt-3",
      className
    )}
  >
    <LeadInfoSection />
    <hr className="divider-horizontal mx-0 mb-2 mt-6 w-full border-t-white/10 p-0" />
    <CampaignInfoSection />

    {/*NOTE Notes should not reset when switching between navigations */}
    <Notes className="mb-10 h-[130px] text-black" label="Notes" />
  </section>
);

export const DialerWidgetExpandedView = () => {
  const { calendlyEvent, callbackData } = useInCallContext();
  const callStatus = useDialerCallStatus();

  const isCallEnded = useMemo(
    () => callStatus === DIALER_CALL_STATUS.AFTER,
    [callStatus]
  );

  return isCallEnded && !calendlyEvent && !callbackData?.isCallbackSet ? (
    <DispositionSection className="grow" />
  ) : (
    <InfoSections className="grow" />
  );
};
