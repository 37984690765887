import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { trimString } from "shared/lib/helpers/utils";

interface dialPadInputKey {
  numeric: number | string;
  alpha: string;
}

const WHITESPACE = "\u00A0";

const keys = [
  { numeric: 1, alpha: WHITESPACE },
  { numeric: 4, alpha: "GHI" },
  { numeric: 7, alpha: "PQRS" },
  { numeric: "*", alpha: "" },
  { numeric: 2, alpha: "ABC" },
  { numeric: 5, alpha: "JKL" },
  { numeric: 8, alpha: "TUV" },
  { numeric: 0, alpha: "+" },
  { numeric: 3, alpha: "DEF" },
  { numeric: 6, alpha: "MNO" },
  { numeric: 9, alpha: "WXYZ" },
  { numeric: "#", alpha: "" },
];

const allowedKeys = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "*",
  "#",
];

interface DialPadI {
  isOpen?: boolean;
  disabled?: boolean;
  onChange?: (input: dialPadInputKey) => void;
}

const DialPad: FC<DialPadI> = ({ isOpen, disabled, onChange }) => {
  const callingContext = useCallingContext();
  const [dialInput, setDialInput] = useState("");
  const dialInputRef = useRef("");

  const handleKeyClick = (input: dialPadInputKey) => {
    if (disabled) {
      return;
    }

    callingContext.call?.sendDigits(input.numeric.toString());
    setDialInput(`${dialInput}${input.numeric}`);
    onChange?.(input);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (disabled) {
      return;
    }

    if (allowedKeys.includes(e.key)) {
      callingContext.call?.sendDigits(e.key);
      setDialInput(`${dialInputRef.current}${e.key}`);
    }
  };

  useEffect(() => {
    dialInputRef.current = dialInput;
  }, [dialInput]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keypress", handleKeyPress);
    } else {
      window.removeEventListener("keypress", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [isOpen]);

  return (
    <div className="relative overflow-hidden rounded-[10px] bg-white/80 px-3 py-3 backdrop-blur-xl">
      <div className="input mb-3 flex h-10 items-center justify-center bg-black/10">
        {trimString(dialInput, 10, "left")}
      </div>

      <div className="grid grid-flow-col grid-rows-4 items-center justify-center gap-2 px-3">
        {keys.map((key, index) => (
          <div
            key={index}
            className={clsx(
              "flex h-10 w-10 cursor-pointer flex-col items-center justify-center rounded-full bg-black/5 p-2",
              {
                "cursor-not-allowed": disabled,
              }
            )}
            onClick={() => handleKeyClick(key)}
          >
            <div className="ae-typography-h3">{key.numeric}</div>
            <div className="ae-typography-detail3 text-black/40">
              {key.alpha}
            </div>
          </div>
        ))}
      </div>

      {disabled && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center bg-gradient-to-b from-white/80 via-[#d3d2d2] to-white/80">
          <p className="ae-typography-body2 px-4 text-center">
            Use the dial pad to dial extensions when the session starts
          </p>
        </div>
      )}
    </div>
  );
};

export default DialPad;
