import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";

import Avatar from "shared/ui/avatar";

import { CampaignCardDefaultI } from "./campaign-card-default";
export interface CampaignCardHighlightsI extends CampaignCardDefaultI {
  upcomingDates?: JSX.Element;
  performance?: JSX.Element;
  actions?: JSX.Element;
}

export const CampaignCardHighlights: FC<CampaignCardHighlightsI> = ({
  imgSrc,
  title,
  price,
  priceDescription,
  status,
  labels,
  stats,

  onClick,
  className,
}) => {
  return (
    <div
      className={clsxMerge(
        "card cursor-pointer rounded-lg bg-[white] p-6",
        className
      )}
      onClick={onClick}
    >
      <div className="mb-2 flex justify-between">
        <div>
          <Avatar
            src={imgSrc}
            placeholderText={title}
            className="h-[88px] w-[88px] rounded-lg border-[1px]"
          />
        </div>

        <div className="flex flex-col items-end gap-y-2">
          {status}
          {labels}
        </div>
      </div>

      <article className="w-full">
        <section className="mb-3">
          <div className="mb-4 flex items-center justify-between">
            <div className="b-typography-h2 line-clamp-1 font-medium">
              {title}
            </div>
          </div>
          <div className="flex items-center">
            <div className="ae-typography-h1">{price}</div>
          </div>
        </section>

        <section>{stats}</section>

        {priceDescription && <div className="mt-3">{priceDescription}</div>}
      </article>
    </div>
  );
};
