import ControlMenu from "@/components/modules/calling/control-menu/v2";
import MainContainer from "@/modules/calling/main-container";
import Sidebar from "@/modules/calling/sidebar";

import ActivityLog from "@/modules/calling/activity-log";
import { DialerLeadsCarousel as LeadsCarouselV2 } from "@/components/modules/calling/leads-carousel/v2";

import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import {
  CatchupsProvider,
  useCatchupsContext,
} from "@/context/dialer-v2/catchups";
import { CATCHUP_TYPES } from "@/constants/catchup-todos";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import {
  CALLER_PHONE,
  MOCK_ACCOUNT,
  MOCK_ACTIVITY_LOG,
  MOCK_CAMPAIGN,
  MOCK_CONTACT,
} from "@/modules/tours/calling/mock-data";
import { DIALER_STATES } from "@/constants/dialer";
import FeatureIntroTooltipGuide from "shared/ui/feature-intro/tooltip-guide";
import Router from "@/helpers/router";

export const CATCHUPS_WALKTHROUGH_ELEMENT_IDS = {
  TITLE: "title",
  START_CALL_BUTTON: "start-call-button",
  SEND_TO_BACK: "send-to-back",
  MARK_COMPLETED: "mark-completed",
};

export const MARK_DONE_BUTTON_ID = "mark-done-button";

const DialerCatchupWalkthroughModeContent = () => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();
  const { setContextForCatchup, setTodosCount } = useCatchupsContext();

  useEffectOnce(() => {
    callingContext.setCampaign(MOCK_CAMPAIGN);
    callingContext.setCallingState(DIALER_STATES.PRE);
    inCallContext.setCallerPhone(CALLER_PHONE);
    inCallContext.setContact(MOCK_CONTACT);
    inCallContext.setAccount(MOCK_ACCOUNT);
    inCallContext.setActivityLog(MOCK_ACTIVITY_LOG);

    setTodosCount(2);
    setContextForCatchup({
      id: "1",
      campaign_id: "1",
      type: CATCHUP_TYPES.CALLBACK,
      call_context: {
        contact: MOCK_CONTACT,
        account: MOCK_ACCOUNT,
        activity_log: MOCK_ACTIVITY_LOG,
        calendly_uri: "",
        caller_phone: CALLER_PHONE,
        skip_cooldown_counter: 0,
      },
      follow_up_date: new Date().toString(),
    });
  });

  return (
    <section className="flex">
      <MainContainer className="grow">
        <div className="flex h-full w-full justify-between">
          <div className="flex grow flex-col items-center justify-center">
            <LeadsCarouselV2 />

            <ControlMenu className="mb-18 caller control-bar pointer-events-none" />
          </div>

          <div className="caller activity-log px-3 py-2">
            <ActivityLog />
          </div>
        </div>
      </MainContainer>

      <Sidebar className="caller sidebar flex-none" />

      <FeatureIntroTooltipGuide
        isActive
        steps={{
          [CATCHUPS_WALKTHROUGH_ELEMENT_IDS.TITLE]: {
            title: "See both scheduled and system generated tasks",
            description:
              "Tasks can include missed calls, callbacks, scheduled meetings and email followups",
            placement: "bottom",
          },
          [CATCHUPS_WALKTHROUGH_ELEMENT_IDS.START_CALL_BUTTON]: {
            title: "Call a lead by clicking the Start Call button",
            description:
              "You can start a call, stop a call and call a lead again if needed ",
          },
          [CATCHUPS_WALKTHROUGH_ELEMENT_IDS.SEND_TO_BACK]: {
            title: "Send leads to the back of your to-do list",
            description:
              'Simply click the "Send to Back" button to send the lead to the back of your todo list.',
            buttons: {
              primary: {
                text: "Got it",
                actionHandler: () => {
                  inCallContext.setStartAt(new Date().toString());
                  inCallContext.setEndAt(new Date().toString());
                  callingContext.setCallingState(DIALER_STATES.POST);

                  setTimeout(() => {
                    document.getElementById(MARK_DONE_BUTTON_ID)?.focus();
                  }, 350);
                },
              },
            },
          },
          [CATCHUPS_WALKTHROUGH_ELEMENT_IDS.MARK_COMPLETED]: {
            title: "Once you’ve finished your task, mark it complete",
            description:
              "You can simply mark a task complete or assign an additional action such as send email or disqualify lead.",
            delayMs: 200,
            buttons: {
              primary: {
                text: "Finish",
                actionHandler: () => {
                  window.location.href = Router.dashboard();
                },
              },
            },
          },
        }}
      />
    </section>
  );
};

export const DialerCatchupWalkthroughMode = () => (
  <CatchupsProvider>
    <DialerCatchupWalkthroughModeContent />
  </CatchupsProvider>
);
