import { MutableRefObject, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import clsx from "clsx";
import { RadioButton, TextArea } from "shared/ui";

import * as Yup from "yup";
import {
  ACCOUNT_STATUSES,
  ACCOUNT_STATUSES_MAP,
} from "shared/lib/constants/account";
import { AccountStatusI } from "shared/lib/interfaces/account";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import {
  DIALER_CANCEL_MEETING_CALLBACK_MODAL_ID,
  DialerCancelMeetingSequentialModals,
} from "./sequential-modals";
import { cancelMeetingAndBacklog } from "./helper";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import toast from "react-hot-toast";

interface AccountCancelMeetingFormValuesI {
  status?: AccountStatusI;
  notes: string;
}

export const DIALER_CANCEL_MEETING_MODAL_ID = "dialer-cancel-meeting-modal";

const SUPPORTED_STATUS_TRANSITIONS = [
  ACCOUNT_STATUSES.BACKLOG,
  ACCOUNT_STATUSES.CALLBACK,
];

const STATUS_REQUIRING_ACTIONS = [ACCOUNT_STATUSES.CALLBACK];

const EnhancedRadioButton = RadioButton(Field);
const EnhancedTextArea = TextArea(Field);

const FormSchema = Yup.object().shape({
  notes: Yup.string()
    .max(1000, "Max 1000 characters")
    .min(2, "Please provide a note")
    .required("Please provide a note"),
  status: Yup.string().required("Please select a status"),
});

const checkIfStatusRequiresAction = (status?: AccountStatusI) =>
  STATUS_REQUIRING_ACTIONS.includes(
    status as (typeof STATUS_REQUIRING_ACTIONS)[number]
  );

/**
 * NOTE: recreated base on the logic of account-cancel-meeting-modal
 */
export const DialerCancelMeetingModal = ({
  modalId = DIALER_CANCEL_MEETING_MODAL_ID,
}: {
  modalId?: string;
}) => {
  const { campaign } = useCallingContext();
  const { account, contact, setIsMeetingCanceled, setCalendlyEvent } =
    useInCallContext();

  const [isVisible, setIsVisible] = useState(true);
  const [isAccountUpdating] = useState(false);
  const [notes, setNotes] = useState("");

  const checkboxRef = useRef() as MutableRefObject<HTMLInputElement>;

  const clearNotes = () => {
    setNotes("");
  };

  const handleClose = () => {
    clearNotes();
    checkboxRef.current.click();
  };

  const onToogleHandler = (event: any) => {
    setIsVisible((event?.target as HTMLInputElement)?.checked as boolean);
  };

  const handleUpdateAccountStatus = (
    values: AccountCancelMeetingFormValuesI
  ) => {
    setNotes(values?.notes || "");

    switch (values?.status) {
      case ACCOUNT_STATUSES.CALLBACK:
        modalHelpers.close(DIALER_CANCEL_MEETING_MODAL_ID);
        modalHelpers.open(DIALER_CANCEL_MEETING_CALLBACK_MODAL_ID);
        return;
      case ACCOUNT_STATUSES.BACKLOG:
        (async () => {
          const UpdateAccountStatusResponse = await cancelMeetingAndBacklog({
            notes: values?.notes,
            account,
            campaign,
          });

          if (UpdateAccountStatusResponse.status === 200) {
            setIsMeetingCanceled(true);
            setCalendlyEvent(undefined);
            toast.success(
              `Canceled meeting and updated ${contact?.first_name} to No Status`
            );
            modalHelpers.close(DIALER_CANCEL_MEETING_MODAL_ID);
          } else {
            toast.error(`Failed to cancel meeting`);
          }
        })();
        return;
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxRef}
        onChange={onToogleHandler}
      />

      <div className="qualification-criteria-modal modal">
        <div className="modal-box relative min-w-[520px] rounded-md p-6">
          <p className="ae-typography-body1 mb-1 font-semibold">
            Cancel Meeting
          </p>
          <h2 className="ae-typography-body2 text-black/60">
            Update the status for your canceled meeting
          </h2>

          {isVisible && (
            <Formik
              initialValues={{
                status: undefined,
                notes: "",
              }}
              onSubmit={handleUpdateAccountStatus}
              validationSchema={FormSchema}
              enableReinitialize
            >
              {({ values, errors, touched }) => (
                <Form className="mt-3 flex flex-col gap-3">
                  {Object.values(SUPPORTED_STATUS_TRANSITIONS).map((key) => (
                    <EnhancedRadioButton
                      key={key}
                      className="w-full p-1"
                      name="status"
                      label={
                        ACCOUNT_STATUSES_MAP[
                          key as keyof typeof ACCOUNT_STATUSES_MAP
                        ]
                      }
                      value={key}
                    />
                  ))}

                  <EnhancedTextArea
                    name="notes"
                    label="Reason for canceled meeting"
                    placeholder="Add cancelation reason"
                    errors={errors.notes}
                    touched={touched.notes}
                  />

                  <div className="mt-2 flex justify-end">
                    <button
                      type="button"
                      className={clsx("btn-ae-text mr-2", {
                        disabled: isAccountUpdating,
                      })}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className={clsx("btn-ae-default", {
                        disabled: isAccountUpdating || !values.status,
                      })}
                    >
                      {values.status &&
                      checkIfStatusRequiresAction(values.status)
                        ? "Next"
                        : "Update"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>

      <DialerCancelMeetingSequentialModals notes={notes} />
    </>
  );
};
