import { clsxMerge } from "shared/lib/helpers";

export const EndedLabel = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsxMerge(
        "w-max rounded-xl bg-error-content px-3 py-1",
        "brand-typography-h7 animate-fadein text-white",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <span>Ended</span>
      </div>
    </div>
  );
};
