import clsx from "clsx";
import { FC } from "react";
import { twMerge } from "tailwind-merge";
import Link from "next/link";

export interface WizardMenuItemI {
  label: string;
  active: boolean;
  href?: string;
  children?: WizardMenuItemI[];
}

interface SideNavI {
  menuData: WizardMenuItemI[];
  className?: string;
  onClick?: (event: any) => void;
}

const WizardSidebarNav: FC<SideNavI> = ({ menuData, className, onClick }) => {
  return (
    <div
      className={twMerge(clsx("relative m-8 h-fit", className))}
      onClick={onClick}
    >
      <div className="relative pb-1 pt-1">
        {menuData.map((category, i) => (
          <div key={i} className="ml-1">
            <div className={clsx(`flex min-w-fit`, { "mt-2": i > 0 })}>
              <div
                className={clsx(
                  `flex h-6 w-6 items-center justify-center rounded-xl text-xs font-bold text-white`,
                  {
                    "bg-black": category.active,
                    "bg-slate-300": !category.active,
                  }
                )}
              >
                {i + 1}
              </div>
              <div
                className={clsx(`ml-6 cursor-pointer font-bold`, {
                  "text-slate-900": category.active,
                  "text-slate-300": !category.active,
                })}
                data-index={i}
              >
                {category?.href ? (
                  <Link href={category.href} data-index={i}>
                    {category.label}
                  </Link>
                ) : (
                  category.label
                )}
              </div>
            </div>
            {category.active &&
              category.children?.map((item, j) => (
                <div key={j} className="mt-2 flex min-w-fit">
                  <div
                    className={clsx(
                      `flex h-6 w-6 items-center justify-center text-2xl`,
                      {
                        "text-slate-900": item.active,
                        "text-slate-400": !item.active,
                      }
                    )}
                  >
                    &#x2022;
                  </div>
                  <div
                    className={clsx(`ml-11 cursor-pointer whitespace-nowrap `, {
                      "font-bold text-slate-900": item.active,
                      "text-slate-400": !item.active,
                    })}
                    data-index={`${i}-${j}`}
                  >
                    {item.label}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="absolute top-0 -z-10 h-full w-8 rounded-2xl bg-slate-100" />
    </div>
  );
};

export default WizardSidebarNav;
