import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";
import Avatar from "shared/ui/avatar";

export interface CampaignCardLabelValueI {
  label: string;
  value: string | number;
  accent?: boolean;
}

export interface CampaignCardLabelBooleanI {
  label: string;
  value: boolean;
}

export interface CampaignCardDefaultI {
  imgSrc?: string;
  title?: string;
  price?: string;
  priceDescription?: JSX.Element;
  status?: JSX.Element;
  labels?: JSX.Element;
  description?: JSX.Element;
  stats?: JSX.Element;
  features?: JSX.Element;
  onClick?: () => void;
  upcomingDates?: JSX.Element;
  className?: string;
}

export const CampaignCardDefault: FC<CampaignCardDefaultI> = ({
  imgSrc,
  title,
  price,
  priceDescription,
  status,
  labels,
  description,
  stats,
  features,
  onClick,
  upcomingDates,
  className,
}) => {
  return (
    <div
      className={clsxMerge(
        "card cursor-pointer rounded-lg bg-[white] p-8",
        className
      )}
      onClick={onClick}
    >
      <div className="flex flex-row gap-4">
        <div>
          <Avatar
            src={imgSrc}
            placeholderText={title}
            className="h-[88px] w-[88px] rounded-lg border-[1px]"
          />
        </div>

        <article className="w-full pr-8">
          <section className="mb-4">
            <div className="flex items-center justify-between">
              <div className="b-typography-h2 mb-3 font-medium">{title}</div>
              <div className="flex items-center gap-5">
                {upcomingDates}
                {status}
              </div>
            </div>
            <div className="flex items-center">
              <div className="ae-typography-h1">{price}</div>

              {priceDescription && (
                <>
                  <div className="divider divider-horizontal" />
                  {priceDescription}
                </>
              )}
            </div>
          </section>

          <section className="mb-3">{labels}</section>

          <section className="mb-4">{description}</section>

          <section>{stats}</section>

          <div className="divider h-2" />

          <section>{features}</section>
        </article>
      </div>
    </div>
  );
};
