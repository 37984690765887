import { clsxMerge } from "shared/lib/helpers";

interface RadioButtonPropsI {
  name: string;
  value?: any;
  label: string;
  inputProps?: any;
  className?: string;
  labelClassName?: string;
  variation?: "default" | "medium";
}

const VARIATIONS = {
  MEDIUM: "medium",
};

const DefaultInput = (props: any) => <input {...props} />;

const RadioButton =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    value,
    label,
    className,
    labelClassName,
    inputProps = {},
    variation,
  }: RadioButtonPropsI) => {
    return (
      <>
        {VARIATIONS.MEDIUM === variation && (
          <div
            className={clsxMerge("form-control rounded-lg  px-2", className, {
              border: !className?.includes("border"),
            })}
          >
            <label className="label cursor-pointer justify-start">
              <Field
                type="radio"
                name={name}
                value={value}
                className={clsxMerge(
                  "radio h-[14px] w-[14px]",
                  "transition-[color,border,box-shadow] duration-300",
                  "checked:border-[#014DFE] checked:bg-white checked:shadow-[0_0_0_3px_#014DFE_inset,0_0_0_3px_#014DFE_inset]"
                )}
                {...inputProps}
              />

              <span
                title={label}
                className={clsxMerge(
                  "ae-typography-button3 text-base-content/60 ml-3",
                  labelClassName
                )}
              >
                {label}
              </span>
            </label>
          </div>
        )}

        {!Object.values(VARIATIONS).some((v) => v === variation) && (
          <div
            className={clsxMerge(
              "form-control w-56 rounded-lg px-2",
              className,
              {
                border: !className?.includes("border"),
              }
            )}
          >
            <label className="label cursor-pointer justify-start">
              <Field
                type="radio"
                name={name}
                value={value}
                className="radio"
                {...inputProps}
              />

              <span
                title={label}
                className={clsxMerge("label-text ml-4", labelClassName)}
              >
                {label}
              </span>
            </label>
          </div>
        )}
      </>
    );
  };

export default RadioButton;
