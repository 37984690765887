import { modal } from "@/components/modals";
import { CALLING_MODULE_BOOK_MEETING_MODAL_ID } from "@/modules/calling/modals/booking-meeting-modal";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge } from "shared/lib/helpers";
import { CalendarIcon } from "shared/ui/icons";
import { FC } from "react";

interface BookMeetingButtonPropsI {
  className?: string;
  isModifyMode?: boolean;
}

export const BookMeetingButton: FC<BookMeetingButtonPropsI> = ({
  className,
  isModifyMode,
}) => {
  const { calendlyEvent } = useInCallContext();

  const triggerBookMeetingModal = () => {
    if (!calendlyEvent) modal.trigger(CALLING_MODULE_BOOK_MEETING_MODAL_ID);
  };

  return (
    <button
      className={clsxMerge(
        "btn-ae-default btn-ae-l px-0",
        { disabled: calendlyEvent },
        className
      )}
      onClick={triggerBookMeetingModal}
    >
      <CalendarIcon className="mr-2 h-3" />{" "}
      {isModifyMode ? "Modify Meeting" : "Book Meeting"}
    </button>
  );
};
