import { AxiosResponse } from "axios";

import { WIDGETS, widgets } from "@/components/shared/widgets";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

import { GetCampaignResponseI, glencocoClientAPI } from "@/api/glencoco";
import { GetAccountDetailsDataI } from "@/interfaces/account-details";

import { ERROR_CODES } from "@/constants/errors";
import { CampaignI } from "@/interfaces/campaign";
import { queryClient } from "@/api/query-client";
import { getAccountDetailsQueryKey } from "@/api/routes/account/queries";
import { dd } from "./datadog";
import { LOG_CATEGORIES } from "@/constants/logs";
import { GetUserAccountDetailsResponseI } from "@/api/routes/account/interfaces";
import { ACCOUNT_DETAILS_TABS } from "shared/lib/constants/account";

export const openAccountDetails = ({
  campaignId,
  accountId,
  tab,
}: {
  campaignId?: string;
  accountId?: string;
  tab?: ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;
}) => {
  dd.rum.log(`${LOG_CATEGORIES.WIDGET} - triggered`, {
    data: {
      filePath: `/lib/helpers/account-details.ts`,
    },
  });

  widgets.trigger({
    id: WIDGETS.MAXED_DIALER,
    config: { campaignId, accountId, tab },
  });
};

export const getAccountDetailsData = async (
  campaignId: string,
  accountId: string
): Promise<GetAccountDetailsDataI> => {
  let isNotApprovedForCampaign = false;
  let isError = false;
  let campaign: CampaignI | undefined;

  const API = glencocoClientAPI();

  /**
   * Should be always fulfilled
   */
  const [settledCampaignResponse, settledAccountDetailsResponse] =
    await Promise.allSettled([
      API.getCampaign(campaignId).catch((e) => e),

      // As the query object may not yet be used as hook in some components,
      // we're getting data and storing it in the cache manually to make it available.
      queryClient
        .fetchQuery({
          queryKey: getAccountDetailsQueryKey(campaignId, accountId),
          queryFn: () => API.getAccountDetails(campaignId, accountId),
        })
        .catch((e) => e),
    ]);

  // Asserting settled values as fulfilled
  const campaignResponse = (
    settledCampaignResponse as PromiseFulfilledResult<
      AxiosResponse<GetCampaignResponseI>
    >
  ).value;

  const accountDetailsResponse = (
    settledAccountDetailsResponse as PromiseFulfilledResult<
      AxiosResponse<GetUserAccountDetailsResponseI>
    >
  ).value;
  console.debug("ACCOUNT DETAILS RESPONSE", accountDetailsResponse);

  if (campaignResponse.status === 200) {
    const data = campaignResponse.data as GetCampaignResponseI;
    campaign = data.campaign;
  } else {
    isError = true;
  }

  if (accountDetailsResponse?.status !== 200) {
    isError = true;
    const errorCode = accountDetailsResponse?.data?.error_code as number;

    // Don't call handleErrorMessage here, because it's handled globally by one of
    // the axios response interceptors. See the handleErrorMessage() utility.
    if (errorCode === ERROR_CODES.USER_NOT_APPROVED_FOR_CAMPAIGN) {
      isNotApprovedForCampaign = true;
    }
  }

  return {
    isNotApprovedForCampaign,
    isError,
    campaign,
    account: accountDetailsResponse?.data?.account,
    accountStatus: accountDetailsResponse?.data?.account_status,
    accountSummary: accountDetailsResponse?.data?.account_summary,
    accountExecutiveData: accountDetailsResponse?.data?.account_executive,
    accountUserLists: accountDetailsResponse?.data?.user_lists,
    contacts: accountDetailsResponse?.data?.contacts,
  };
};
