import { useMemo } from "react";

import { Tabs, createTab } from "shared/ui/tabs";
import { DefaultPill } from "shared/ui/pills";
import { clsxMerge } from "shared/lib/helpers";
import { useApi } from "shared/lib/hooks";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

import { ACCOUNT_DETAILS_TABS } from "shared/lib/constants/account";

export const AccountDetailsNavigation = ({
  selected,
  onChange,
}: {
  selected?: ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;
  onChange?: (tabId?: string) => void;
}) => {
  const { accountStatus } = useAccountDetailsContext();
  const accountUserDispositionId = accountStatus?.aud_id;

  const unreadMessagesCountFetcher = useMemo(
    () =>
      accountUserDispositionId
        ? (api: APII) => api.getMessagesUnreadCount(accountUserDispositionId)
        : null,
    [accountUserDispositionId]
  );

  const [{ data: unreadMessagesCountData }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: unreadMessagesCountFetcher,
    errorBuilder: () => "Failed to load number of unread messages.",
  });

  const tabs = useMemo(
    () => [
      createTab(
        ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY,
        <span className="whitespace-nowrap">Account History</span>
      ),

      createTab(
        ACCOUNT_DETAILS_TABS.MESSAGES,
        <span className="flex items-center gap-x-1">
          <span>AE Messages</span>

          {!!unreadMessagesCountData?.message_count && (
            <DefaultPill
              className={clsxMerge(
                "min-w-[10px] whitespace-nowrap px-2",
                "brand-typography-body2"
              )}
            >
              {unreadMessagesCountData?.message_count} Unread
            </DefaultPill>
          )}
        </span>
      ),
    ],
    []
  );

  return (
    <nav
      className={clsxMerge(
        "my-6",
        "bg-white",
        "flex items-center",
        "rounded-lg border border-black/5",
        "overflow-hidden"
      )}
    >
      <Tabs
        className=""
        tabClassName="brand-typography-body1 px-6 min-h-[56px] mr-0 mb-0 pb-0"
        tabSelectedClassName={clsxMerge(
          "border-b-[10px] bg-black/4",
          "border-t border-t-black/5",
          "border-l border-l-black/5",
          "border-r border-r-black/5"
        )}
        items={tabs}
        selected={selected || ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY}
        isSoftRouting
        onChange={onChange}
      />
    </nav>
  );
};
