import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface BetaPillPropsI {
  className?: string;
}

const BetaPill: FC<BetaPillPropsI> = ({ className }) => (
  <div
    className={clsxMerge(
      "brand-typography-detail4 text-center text-white",
      "w-[42px] rounded-full border border-[#F88167]",
      "bg-gradient-to-r from-[#E7401B] to-[#F88167]",
      "px-2 py-1",
      className
    )}
  >
    BETA
  </div>
);

export default BetaPill;
