import { CUSTOM_EVENTS } from "@/constants/custom-events";
import {
  ERROR_CATEGORIES,
  SOCKET_ERROR_CODES_GROUP_DIALER_LIST,
} from "@/constants/errors";
import { dd } from "@/helpers/datadog";
import { handleSockerErrorMessage_DialerListGroup } from "@/helpers/error";
import { dispatchCustomEvent } from "@/helpers/events";
import { WebsocketErrorI } from "@/interfaces/websockets";

export interface WSErrorListDialingI {
  errorCode?: number;
}

/**
 * Handles socket errors by logging the error data, checking the error code against known dialer list group errors,
 * and dispatching a custom event if the error code matches.
 *
 * @param {WebsocketErrorI} data - The error data received from the websocket.
 * The `error_code` property of this data is checked against known error codes.
 */
export const handleError = (data: WebsocketErrorI) => {
  dd.rum.error(`${ERROR_CATEGORIES.WS}`, { data });

  if (
    (Object.values(SOCKET_ERROR_CODES_GROUP_DIALER_LIST) as number[]).includes(
      data?.error_code
    )
  ) {
    handleSockerErrorMessage_DialerListGroup(data?.error_code);

    dispatchCustomEvent({
      data: {
        errorCode: data?.error_code,
      } as WSErrorListDialingI,
      eventType: CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.ERROR,
    });
  }
};
