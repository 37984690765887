import { clsxMerge } from "shared/lib/helpers";
import { CheckmarkIcon } from "shared/ui/icons";

const DoubleCheckMarkIcon = () => {
  return (
    <span className="animate-fadein relative flex items-center">
      <CheckmarkIcon className="w-9" />
      <CheckmarkIcon
        className={clsxMerge(
          "absolute right-2 w-9",
          "[&>path]:[stroke:white]",
          "scale-125"
        )}
      />
      <CheckmarkIcon className="absolute right-2 w-9" />
    </span>
  );
};

export const MarkAllReadButton = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <button
      className={clsxMerge(
        "btn-transparent",
        "ae-link brand-typography-body3 font-semibold",
        "flex items-center",
        className
      )}
      onClick={onClick}
    >
      <DoubleCheckMarkIcon />
      <span>Mark all as read</span>
    </button>
  );
};
