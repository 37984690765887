import ControlMenu from "@/components/modules/calling/control-menu/v2";
import MainContainer from "@/modules/calling/main-container";
import LeadCard from "@/components/modules/calling/cards/lead-card/widget";
import {
  DIALER_CALL_STATUS,
  DIALER_STATES,
  DIALER_WIDGET_VARIATIONS,
} from "@/constants/dialer";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { LoadingComponent } from "@/components/modules/loading";
import {
  CallerNotes as Notes,
  addStringToStringOnANewLine,
} from "@/modules/calling/modes/widget-mini-dialer/caller-notes";
import { DialerWidgetSecondaryActions as SecondaryActions } from "./secondary-actions";
import { useDialerWidgetContext } from "@/context/dialer-v2/widget";
import { DialerWidgetExpandedView as ExpandedView } from "./expanded-view";
import { clsxMerge } from "shared/lib/helpers";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useEffect } from "react";
import { DialerWidgetScrollIndicator as ScrollIndicator } from "./scroll-indicator";

export const WidgetDialerMiniMode = () => {
  const { callingState: dialerState } = useCallingContext();
  const { variation, setVariation, note, setNotes, setNote } =
    useDialerWidgetContext();
  const callStatus = useDialerCallStatus();

  useEffect(() => {
    if (DIALER_CALL_STATUS.AFTER === callStatus) {
      setVariation?.(DIALER_WIDGET_VARIATIONS.SIDEBAR);

      if (note) {
        setNotes?.((notes) => addStringToStringOnANewLine(note, notes));
        setNote?.("");
      }
    }
  }, [callStatus]);

  return (
    <MainContainer
      className={clsxMerge("grow", {
        "rounded-l-none": variation === DIALER_WIDGET_VARIATIONS.SIDEBAR,
      })}
    >
      <div className="flex h-full grow flex-col items-center justify-center">
        {/* NOTE to make Loading icon in the center of container
         *       otherwise loading will be pushed to bottom
         */}
        {![DIALER_STATES.PRE, DIALER_STATES.LOADING].includes(
          dialerState as string
        ) && <div className="mb-auto" />}

        {[DIALER_STATES.PRE, DIALER_STATES.LOADING].includes(
          dialerState as string
        ) && <LoadingComponent spinnerClassName="text-white" />}

        {[
          DIALER_STATES.DIALING,
          DIALER_STATES.CALL,
          DIALER_STATES.DISPOSITION,
          DIALER_STATES.POST,
        ].includes(dialerState as string) && (
          <LeadCard
            className={clsxMerge({
              "pb-10 pt-14": variation === DIALER_WIDGET_VARIATIONS.SIDEBAR,
            })}
          />
        )}

        {![DIALER_STATES.PRE, DIALER_STATES.LOADING].includes(
          dialerState as string
        ) && (
          <ControlMenu
            className={clsxMerge("control-bar mt-auto w-full px-6", {
              "mt-auto": variation !== DIALER_WIDGET_VARIATIONS.SIDEBAR,
            })}
            buttonClassName="w-[89px]"
            buttonsClassNames={{
              configButton: { dropdownClassName: "dropdown-bottom" },
            }}
            hideExtras
          />
        )}

        {![DIALER_STATES.PRE, DIALER_STATES.LOADING].includes(
          dialerState as string
        ) && <SecondaryActions />}

        {DIALER_WIDGET_VARIATIONS.SIDEBAR === variation &&
          ![DIALER_STATES.PRE, DIALER_STATES.LOADING].includes(
            dialerState as string
          ) && (
            <>
              <ScrollIndicator />
              <ExpandedView />
            </>
          )}

        {/*NOTE Notes should not reset when switching between navigations */}
        {DIALER_WIDGET_VARIATIONS.DEFAULT === variation &&
          ![DIALER_STATES.PRE, DIALER_STATES.LOADING].includes(
            dialerState as string
          ) && <Notes className="h-[130px]" wrapperClassName="px-6" />}
      </div>
    </MainContainer>
  );
};
