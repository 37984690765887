import { FC } from "react";

import { Spinner } from "shared/ui/spinners/spinner";
import { clsxMerge } from "shared/lib/helpers";

interface CenteredSpinnerPropsI {
  className?: string;
}

const CenteredSpinner: FC<CenteredSpinnerPropsI> = ({ className }) => (
  <div className={clsxMerge("relative w-full", className)}>
    <Spinner className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
  </div>
);

export default CenteredSpinner;
