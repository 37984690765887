import { useMemo } from "react";
import clsx from "clsx";
import { TRAINING_CARD_STATUSES } from "shared/ui/cards/campaign-card/constants";
import {
  isCampaignHiring,
  isCampaignNew,
} from "shared/ui/cards/campaign-card/shared/lib/helper";
import { CampaignV3I } from "shared/lib/interfaces/campaign";
import { CampaignCardTrainingPill } from "./campaign-card-labels/campaign-card-training-pill";
import { CAMPAIGN_USER_STATUS } from "shared/lib/constants/campaign";

const neutralClasses = "!bg-black/5 text-base-content/80";
const infoClasses = "!bg-[#014DFE1A] text-[#014DFE]";
const positiveClasses = "!bg-[#DFF4B499] text-[#0E6927]";
const negativeClasses = "!bg-[#FFBFB0] text-base-content/80";

export const CampaignCardStatusPills = ({
  created_at,
  campaign_user_status,
  job_description,
  steps_complete,
  total_steps,
}: {
  created_at?: string;
  campaign_user_status?: CampaignV3I["campaign_user_status"];
  job_description?: CampaignV3I["job_description"];
  steps_complete?: number;
  total_steps?: number;
}) => {
  const statuses = useMemo(
    () =>
      [
        isCampaignNew(created_at as string)
          ? TRAINING_CARD_STATUSES.NEW
          : undefined,
        isCampaignHiring(job_description)
          ? TRAINING_CARD_STATUSES.HIRING
          : undefined,
        CAMPAIGN_USER_STATUS.TRAINING_IN_PROGRESS === campaign_user_status
          ? TRAINING_CARD_STATUSES.TRAINING_IN_PROGRESS
          : undefined,
        CAMPAIGN_USER_STATUS.TRAINING_SUBMITTED === campaign_user_status
          ? TRAINING_CARD_STATUSES.TRAINING_COMPLETED
          : undefined,
        CAMPAIGN_USER_STATUS.AUDITION_IN_PROGRESS === campaign_user_status
          ? TRAINING_CARD_STATUSES.PROGRESS
          : undefined,
        CAMPAIGN_USER_STATUS.AUDITION_IN_REVIEW === campaign_user_status
          ? TRAINING_CARD_STATUSES.PENDING
          : undefined,
        CAMPAIGN_USER_STATUS.AUDITION_RETRY === campaign_user_status
          ? TRAINING_CARD_STATUSES.RETRY
          : undefined,
        CAMPAIGN_USER_STATUS.AUDITION_APPROVED === campaign_user_status
          ? TRAINING_CARD_STATUSES.APPROVED
          : undefined,
        CAMPAIGN_USER_STATUS.AUDITION_REJECTED === campaign_user_status
          ? TRAINING_CARD_STATUSES.NOT_ELIGIBLE
          : undefined,
      ].filter((s) => !!s),
    [created_at, campaign_user_status, job_description]
  );

  return statuses?.length ? (
    <div className="ml-2 flex flex-wrap justify-end gap-2">
      {statuses.includes(TRAINING_CARD_STATUSES.HIRING) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            infoClasses
          )}
        >
          Hiring Full-Time!
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.NEW) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            infoClasses
          )}
        >
          New
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.PENDING) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            neutralClasses
          )}
        >
          Pending Approval
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.TRAINING_COMPLETED) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            positiveClasses
          )}
        >
          Start Audition
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.APPROVED) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            positiveClasses
          )}
        >
          Approved
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.NOT_ELIGIBLE) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            negativeClasses
          )}
        >
          Not Eligible
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.RETRY) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            neutralClasses
          )}
        >
          Retry Assessment
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.PROGRESS) && (
        <div
          className={clsx(
            "badge ae-typography-detail1",
            "rounded-[4px] border-none",
            neutralClasses
          )}
        >
          In Progress
        </div>
      )}

      {statuses.includes(TRAINING_CARD_STATUSES.TRAINING_IN_PROGRESS) && (
        <CampaignCardTrainingPill
          steps_complete={steps_complete}
          total_steps={total_steps}
        />
      )}
    </div>
  ) : null;
};
