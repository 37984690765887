import clsx from "clsx";
import Label from "shared/ui/label";

const DescItem = ({
  isAccent,
  children,
  classNames,
  ...config
}: {
  isAccent?: boolean;
  children?: any;
  config?: any;
  classNames?: string;
}) => (
  <li className={clsx("mb-3", classNames)}>
    <Label variation={isAccent && "accent"} {...(config || {})}>
      <div className="flex items-center">{children}</div>
    </Label>
  </li>
);

export default DescItem;
