import { Dispatch, SetStateAction } from "react";

export interface AsyncGetI {
  <T>(func?: Dispatch<SetStateAction<T>>): Promise<T>;
}

interface AsyncWaitForConfigI {
  expectedValue?: any;
  isTruthful?: boolean;
  timeout?: number;
  interval?: number;
}

export interface AsyncWaitForI {
  <T>(
    func?: Dispatch<SetStateAction<T>>,
    config?: AsyncWaitForConfigI
  ): Promise<T>;
}

export const asyncGet: AsyncGetI = (setStateFunc) =>
  new Promise((resolve) => {
    setStateFunc?.((v) => {
      resolve(v as typeof v);
      return v;
    });
  });

export const asyncWaitFor: AsyncWaitForI = (
  setStateFunc,
  { expectedValue, isTruthful, timeout = 5000, interval: _interval = 24 } = {}
) =>
  new Promise((resolve, reject) => {
    let passed = 0;
    const delta = _interval;

    const interval = setInterval(() => {
      setStateFunc?.((v) => {
        console.log(`Attempt to get state value | passed: ${passed}ms`);

        if (isTruthful ? !!v : v === expectedValue) {
          console.log(`Success asyncWaitFor ${v}`);
          clearInterval(interval);
          resolve(v as typeof v);
        }

        if (passed >= timeout) {
          console.log(`Timeout asyncWaitFor ${v}`);
          clearInterval(interval);
          reject(v as typeof v);
        }

        return v;
      });

      passed += delta;
    }, delta);
  });
