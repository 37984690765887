import { FC, useMemo } from "react";
import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import _sortBy from "lodash/sortBy";
import dayjs from "dayjs";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";
import { safeGetDate } from "shared/lib/helpers";
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_WITHOUT_TZ,
} from "shared/lib/constants/time";
import { EmailMetadataOpensI } from "shared/lib/interfaces/account";

interface IHistoryCardEmailOpensProps {
  emailOpens: EmailMetadataOpensI[] | null;
}

export const HistoryCardEmailOpens: FC<IHistoryCardEmailOpensProps> = ({
  emailOpens,
}) => {
  const isDisplayed = emailOpens && emailOpens.length > 0;

  const opensSortedDesc = useMemo(
    () =>
      _sortBy(emailOpens || [], ({ opened_at }) =>
        dayjs(opened_at).unix()
      ).reverse(),
    [emailOpens]
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <HistoryCardTextInfoItem
        icon={<EnvelopeOpenIcon className="h-6 w-6" />}
        subtitle="Email opens"
        infoClassName={opensSortedDesc.length > 1 ? "text-error-content" : ""}
        info={[
          {
            title: `${opensSortedDesc.length}, Last on ${safeGetDate(
              opensSortedDesc[0].opened_at,
              DATE_FORMAT_SHORT
            )}`,
          },
        ]}
      />

      {opensSortedDesc.length > 1 && (
        <div className="brand-typography-detail2 @xl:flex ml-11 mt-2 hidden w-[200px] flex-col">
          <div className="flex w-full justify-between text-[#333333]">
            <span className="font-semibold">Action</span>
            <span className="font-semibold">When</span>
          </div>

          <div className="max-h-[100px] w-full flex-col overflow-y-auto">
            {opensSortedDesc.map(({ opened_at }, idx) => (
              <div key={idx} className="flex w-full justify-between">
                <span>Open</span>
                <span>{safeGetDate(opened_at, DATE_FORMAT_WITHOUT_TZ)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
