import { ReactElement } from "react";
import clsx from "clsx";

import CopyToClipboard from "shared/ui/copy-to-clipboard";
import LabelValue, { VARIATIONS } from "shared/ui/label-value";

// #### EXAMPLE INFO_RECORD: ####
//
// createInfoRecord(
//   <MailIcon className="mr-1 h-3" />,
//   "vik@test.com",
//   "email",
//    "link_highlight",
//   {
//     isAccent: true,
//     href: `mailto:${"vik@text.com"}`,
//     target: "_blank",
//     rel: "noreferrer",
//   }

export interface InfoRecordI {
  icon: ReactElement | null;
  label?: string;
  value?: string;
  variation?: (typeof VARIATIONS)[keyof typeof VARIATIONS];
  config?: any;
}

export const createInfoRecord = (
  icon: ReactElement | null,
  value?: string,
  label?: string,
  variation?: (typeof VARIATIONS)[keyof typeof VARIATIONS],
  config?: any
): InfoRecordI => ({
  icon,
  label,
  value,
  variation,
  config,
});

const LabelValueList = ({ infoList }: { infoList: Array<InfoRecordI> }) => {
  return (
    <ul>
      {infoList.map((info, i) => {
        return info?.value ? (
          <CopyToClipboard key={i} textToCopy={info.value}>
            <LabelValue
              info={info}
              valueClassName="ae-typography-h3 font-medium"
              className={clsx("", {
                "mb-4": i < infoList.length - 1 && infoList[i + 1].label, //dont want margin bottom if the next item in the list doesnt have a label (because then it's a list of grouped values)
              })}
            />
          </CopyToClipboard>
        ) : null;
      })}
    </ul>
  );
};

export default LabelValueList;
