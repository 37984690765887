import { FC } from "react";

import { ConfirmActionModal } from "shared/ui/modal/confirm-action";

export const CONFIRM_REMOVE_CONTACTS_FROM_LIST =
  "confirm-remove-contacts-from-list";

interface ConfirmDeleteContactsFromListPropsI {
  id?: string;
  title?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const ConfirmRemoveContactsFromList: FC<
  ConfirmDeleteContactsFromListPropsI
> = ({ id, title, onConfirm, onCancel = () => {}, onClose = () => {} }) => (
  <ConfirmActionModal
    id={id || CONFIRM_REMOVE_CONTACTS_FROM_LIST}
    showCloseButton={false}
    title={title || "Remove these leads from current list"}
    description="Are you sure you want to remove these leads from the current list?"
    confirmButtonText="Yes, remove"
    onConfirm={onConfirm}
    onCancel={onCancel}
    onClose={onClose}
  />
);
