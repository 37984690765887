import { AxiosResponse } from "axios";
import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";
import { GetUserResponseI } from "@/api/routes/user";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

/**
 * Set datadog user on each /me response
 */
export const meRoute_ResponseInterceptor = (
  response: AxiosResponse<any, any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  if (
    typeof window !== "undefined" &&
    response.config.url?.includes("/me") &&
    (response.data as GetUserResponseI)?.user
  ) {
    const user = (response.data as GetUserResponseI)?.user;

    if (user?.id) {
      const datadogUser = {
        id: user?.id,
        email: user?.email,
        name: `${user?.first_name} ${user?.last_name}`,
      };

      datadogLogs?.setUser?.(datadogUser);
      datadogRum?.setUser(datadogUser);
    } else {
      datadogLogs?.clearUser();
      datadogRum?.clearUser();
    }
  }
};
