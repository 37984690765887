import { DIALER_LIVE_TRANSFER_STATUSES } from "@/constants/dialer";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { useMemo } from "react";

export const useLiveTransfer = () => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();

  const { campaign } = callingContext;
  const { liveTransferStatus } = inCallContext;

  const isLiveTransfer = useMemo(
    () => !!campaign?.has_live_transfer,
    [campaign?.has_live_transfer]
  );

  const isLiveTransferComplete = useMemo(() => {
    return (
      campaign?.has_live_transfer &&
      DIALER_LIVE_TRANSFER_STATUSES.LIVE_TRANSFER_COMPLETE ===
        liveTransferStatus
    );
  }, [liveTransferStatus, campaign?.has_live_transfer]);

  return { isLiveTransfer, isLiveTransferComplete };
};
