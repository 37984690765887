import { FC, useMemo, useState } from "react";

import QualificationModal from "./qualification-modal";
import { useApi } from "shared/lib/hooks";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { modal } from "@/modals/index";
import AccountCalendlyModal, {
  ACCOUNT_CALENDLY_MODAL_ID,
} from "./calendly-modal";
import SubmitBookedMeetingModal, {
  ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID,
} from "@/modals/account-create-lead-book-meeting-modal/submit-booked-meeting-modal";

import {
  CampaignI,
  QualificationCriteriaI,
  SelectableQualificationCriteriaI,
} from "@/interfaces/campaign";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";

interface AccountBookMeetingModalPropsI {
  modalId?: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const ACCOUNT_BOOK_MEETING_MODAL_ID = "account_book_meeting_modal";

export const AccountBookMeetingModal: FC<AccountBookMeetingModalPropsI> = ({
  modalId = ACCOUNT_BOOK_MEETING_MODAL_ID,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const {
    modalAccount,
    clearModalAccount,
    accountCalendlyUri,
    updateAccountWithCampaign,
  } = useAccountsContext();

  const { campaignId, accountId } = modalAccount || {};

  const getCampaignFetcher = useMemo(
    () =>
      campaignId
        ? (api: APII) => api.getCampaign(modalAccount?.campaignId as string)
        : null,
    [modalAccount?.campaignId]
  );

  const [{ data }] = useApi({
    fetcher: getCampaignFetcher,
    apiFactory: glencocoClientAPI,
  });
  const [qualificationCriterias, setQualificationCriterias] = useState<
    Array<SelectableQualificationCriteriaI>
  >([]);

  // NEED THIS BECAUSE CALENDLY TRIGGERS onCalendlySuccess FOR ALL RENDERED CALENDLY MODALS
  // AND WE HAVE TWO ON ACCOUNT PAGE.
  // THIS VARIABLE HELPS US RENDER IT ONLY AFTER THE QUALIFICATION CRITERIA STEP FOR THIS MODAL IS SUCCESSFUL
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const [calendlyEvent, setCalendlyEvent] = useState<CalendlyEventI>();

  const onCalendlySuccess = (calendlyEvent: CalendlyEventI) => {
    setCalendlyEvent(calendlyEvent);

    // Close current modal
    modal.trigger(ACCOUNT_CALENDLY_MODAL_ID);
    modal.trigger(ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID);
  };

  const onSubmitBookedMeetingOverview = (notes: string) => {
    {
      if (!accountId || !campaignId) {
        return;
      }

      updateAccountWithCampaign(accountId, campaignId, {
        status: ACCOUNT_STATUSES.MEETING_SCHEDULED,
        modify_meeting_request: {
          event_uri: calendlyEvent?.id || "test",
          qualification_criteria: qualificationCriterias,
          notes,
        },
      })
        .then(() => {})
        .finally(() => {
          clearModalAccount();
          onSuccess();
        });
    }
  };

  return (
    <>
      <QualificationModal
        modalId={modalId}
        campaign={data?.campaign}
        accountName={modalAccount?.accountName as string}
        onClose={onClose}
        onSuccess={(qc) => {
          setQualificationCriterias(
            qc as Array<SelectableQualificationCriteriaI>
          );

          setShowCalendlyModal(true);

          setTimeout(() => modal.trigger(ACCOUNT_CALENDLY_MODAL_ID), 100);
        }}
      />

      {showCalendlyModal && (
        <AccountCalendlyModal
          accountName={modalAccount?.accountName as string}
          contact={modalAccount?.contact}
          calendlyUri={accountCalendlyUri}
          onSuccess={onCalendlySuccess}
          onClose={onClose}
        />
      )}

      <SubmitBookedMeetingModal
        calendlyUri={accountCalendlyUri}
        calendlyEvent={calendlyEvent as CalendlyEventI}
        campaign={data?.campaign as CampaignI}
        qualificationCriterias={
          data?.campaign
            ?.qualification_criterias as Array<QualificationCriteriaI>
        }
        selectedQualificationCriteria={qualificationCriterias}
        onSubmit={onSubmitBookedMeetingOverview}
        hideCloseAction
      />
    </>
  );
};
