import { FC, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { useLongPolling } from "shared/lib/hooks";
import { clsxMerge } from "shared/lib/helpers";
import { SECONDS_IN_HOUR } from "shared/lib/constants/time";
import { CallConnectionQuality } from "./call-connection-quality";

dayjs.extend(duration);

interface DialerConnectionLabelPropsI {
  className?: string;
}

export const ConnectedLabel: FC<DialerConnectionLabelPropsI> = ({
  className,
}) => {
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  useLongPolling(() => {
    setSecondsElapsed((prev) => prev + 1);
  }, 1000);

  return (
    <div
      className={clsxMerge(
        "w-max rounded-xl bg-success-content px-3 py-1",
        "brand-typography-h7 animate-fadein text-white",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <CallConnectionQuality />

        <span>
          {dayjs
            .duration(secondsElapsed, "seconds")
            .format(secondsElapsed >= SECONDS_IN_HOUR ? "HH:mm:ss" : "mm:ss")}
        </span>
      </div>
    </div>
  );
};
