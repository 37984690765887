import { useEffect, useRef, useState } from "react";
import { MeetingBookedCardContent } from "@/modules/calling/cards/call-disposition-card/meeting-booked-card";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { CampaignI, QualificationCriteriaI } from "@/interfaces/campaign";
import { glencocoClientAPI } from "@/api/glencoco";

import { toast } from "react-hot-toast";

import clsx from "clsx";
import { checkIfClient } from "shared/lib/helpers";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

export const SUBMIT_BOOKED_MEETING_MODAL_ID = "submit-booked-meeting-modal";

const SubmitBookMeetingModal = ({
  campaign,
  contact,
  account,
  calendlyEvent,
  calendlyUri,
  callId,
  qualificationCriterias,
  selectedQualificationCriteria,

  notes: _notes = "",
  modalId = SUBMIT_BOOKED_MEETING_MODAL_ID,

  onSuccess = () => {},
}: {
  calendlyUri: string;
  campaign: CampaignI;
  contact: CallContactI;
  account: CallAccountI;
  calendlyEvent: CalendlyEventI;
  callId: string;
  qualificationCriterias: Array<QualificationCriteriaI>;
  selectedQualificationCriteria: Array<SelectedQualificationCriteriaQuestionI>;

  notes?: string;
  modalId?: string;

  onSuccess?: () => void;
}) => {
  const { setIsMeetingCanceled } = useInCallContext();

  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState(_notes);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setNotes(_notes);
  }, [_notes]);

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  const close = () => {
    if (checkIfClient()) {
      (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
      setIsVisible(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!campaign?.id || !calendlyEvent?.id) {
      toast.error("Invalid Id.");
      setIsLoading(false);
      return;
    }

    const API = glencocoClientAPI();

    const UpdateBookingMeetingResponse = await API.updateBookedMeeting(
      campaign.id,
      callId,
      calendlyEvent?.id,
      {
        qualification_criteria: selectedQualificationCriteria,
        notes: notes,
      }
    ).catch((e) => e);

    if (UpdateBookingMeetingResponse.status === 200) {
      setIsMeetingCanceled(false);
      toast.success("Meeting booked!");
      onSuccess();
      close();
    } else {
      toast.error("Failed to book meeting");
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) => {
          onToogleHandler((event.target as HTMLInputElement)?.checked);
        }}
      />

      <div className="modal">
        {isVisible && calendlyUri ? (
          <div className="modal-box relative w-[520px] rounded-md p-6">
            <label
              htmlFor={modalId}
              className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 text-black/40"
            >
              ✕
            </label>

            <div
              className={clsx(
                "max-h-[calc(100vh-50px)] overflow-y-auto scroll-smooth"
              )}
            >
              <MeetingBookedCardContent
                isWrapperNoStyles
                isVisible={isVisible}
                contact={contact}
                account={account}
                calendlyEvent={calendlyEvent}
                qualificationCriterias={qualificationCriterias}
                selectedQualificationCriteria={selectedQualificationCriteria}
                notes={notes}
                loading={isLoading}
                onNotesUpdate={setNotes}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SubmitBookMeetingModal;
