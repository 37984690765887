import { AxiosInstance } from "axios";

import { ChatInboxItemI } from "@/api/routes/chats";
import { DashboardNotificationI } from "@/interfaces/notification";
import { LeaderboardItemI } from "@/interfaces/leaderboards";
import { GetForumPostsResponseI } from "shared/lib/interfaces/forum";
import { CampaignV3I } from "@/interfaces/campaign";

export interface DashboardActivityCallI {
  title: string;
  subtitle: string;
  account_id?: string;
  campaign_name: string;
  campaign_id?: string;
  timestamp: string;
}

export interface GetDashboardActivityResponseI {
  calls: DashboardActivityCallI[];
  messages: ChatInboxItemI[];
  certifications: DashboardNotificationI[];
}

export interface GetDashboardUserStatsResponseI {
  qualified_meetings_booked: number;
  hours_spent_on_campaign: number;
  calls_made: number;
  show_up_rate: number;
  total_earnings_in_usd: number;
}

export interface GetDashboardLeaderboardResponseI {
  leaderboard: Array<LeaderboardItemI>;
  my_standing: LeaderboardItemI;
  start_date: string;
  end_date: string;
}

export interface GetDashboardCampaignsResponseI {
  campaigns: Array<CampaignV3I>;
}

export const UserDashboardAPIEndpoints = (API: AxiosInstance) => ({
  getDashboardActivity: () =>
    API.get<GetDashboardActivityResponseI>("/v1/dashboard/activities"),
  getDashboardCampaigns: () =>
    API.get<GetDashboardCampaignsResponseI>(
      "/v1/dashboard/campaign_highlights"
    ),
  getDashboardUserStats: () =>
    API.get<GetDashboardUserStatsResponseI>("/v1/dashboard/my_stats"),
  getDashboardLeaderboard: () =>
    API.get<GetDashboardLeaderboardResponseI>("/v1/dashboard/leaderboard"),
  getDashboardGeneralDiscussions: () =>
    API.get<GetForumPostsResponseI>("/v1/dashboard/top_discussions"),
});
