import { FC, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import Link from "next/link";
import Router from "@/helpers/router";
import { useEffectOnce } from "shared/lib/hooks";

interface UnauthorisedCardI {
  className?: string;
}

const UnauthorisedCard: FC<UnauthorisedCardI> = ({ className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <div
      className={twMerge(
        clsx(
          "w-[481px] rounded-[10px] bg-white px-8 py-6",
          className,
          "transition-[opacity,transform] duration-500",
          {
            "scale-50 opacity-0": !isVisible,
            "scale-100 opacity-100": isVisible,
          }
        )
      )}
    >
      <div className="mb-7">
        <ExclamationTriangleIcon className="mb-3 inline h-6 w-6" />
        <h2 className="ae-typography-h4 mb-2">
          You are not yet approved to call
        </h2>
        <p className="ae-typography-body2 text-black/60">
          Please complete the related trainings and certification call to be
          able to call and book meetings on behalf of this campaign
        </p>
      </div>

      <div className="flex justify-end">
        <Link
          href={Router.home()}
          className={clsx("ae-typography-button2 btn bg-black", {
            loading: isLoading,
            disabled: isLoading,
          })}
          onClick={() => setIsLoading(true)}
        >
          Take me back home
        </Link>
      </div>
    </div>
  );
};

export default UnauthorisedCard;
