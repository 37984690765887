import { useEffect, useState } from "react";
import { duration } from "shared/lib/helpers/date";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { ChevronRightIcon, ClockIcon } from "shared/ui/icons";

export const LeadsCarouselSkipButton = ({
  skipAvailableInSeconds,
  onClick,
  onEndCountdown = () => {},
}: {
  skipAvailableInSeconds?: number;
  onClick?: () => void;
  onEndCountdown?: () => void;
}) => {
  const [currentSecondsLeft, setCurrentSecondsLeft] = useState(
    skipAvailableInSeconds
  );

  let interval: any;

  useEffect(() => {
    setCurrentSecondsLeft(skipAvailableInSeconds);

    if (skipAvailableInSeconds) {
      clearInterval(interval);
      interval = setInterval(() => {
        setCurrentSecondsLeft((currentSecondsLeft) => {
          if ((currentSecondsLeft as number) <= 1) {
            onEndCountdown();
          }

          if (!currentSecondsLeft) {
            clearInterval(interval);
          }

          return (currentSecondsLeft || 1) - 1 || 0;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [skipAvailableInSeconds]);

  return (
    <div>
      {!!currentSecondsLeft && (
        <div className="flex w-[120px] flex-col items-center rounded-lg bg-black px-3 py-2 text-center text-white">
          <ClockIcon className="mb-1 w-4" />

          <span className="ae-typography-body2">
            Lead skipping available in{" "}
            {duration(currentSecondsLeft, "seconds").format(`m:ss`)}
          </span>
        </div>
      )}

      {!currentSecondsLeft && (
        <Tooltip>
          <TooltipTrigger className="mt-4 w-full">
            <button
              className="btn-ae-default  flex h-10 w-10 items-center justify-center rounded-full p-0"
              onClick={onClick}
            >
              <ChevronRightIcon className="relative left-[1px] w-4" />
            </button>
          </TooltipTrigger>
          <TooltipContent>
            <div className="max-w-[300px] text-center">Skip this lead</div>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};
