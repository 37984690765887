import _isNumber from "lodash/isNumber";

export const formatNumbers = (
  value?: string | number,
  maximumSignificantDigits = 12,
  options: Intl.NumberFormatOptions = {}
) => {
  if (!["string", "number"].includes(typeof value) && !value) {
    return value;
  }

  const number = new Intl.NumberFormat("en-EN", {
    maximumSignificantDigits,
    ...options,
  }).format(value as number);

  return number === "NaN" ? value : number;
};

const getNumberSafe = (value: string | number) =>
  _isNumber(value) ? value : parseInt(value, 10);

export const convertCentsToDollars = (amount: number | string) =>
  getNumberSafe(amount) / 100;

export const formatMoneyDollars = (
  amount: number | string,
  options: Intl.NumberFormatOptions = {}
) =>
  getNumberSafe(amount).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  });

export const formatMoneyCents = (
  amount: number | string,
  options: Intl.NumberFormatOptions = {}
) => formatMoneyDollars(convertCentsToDollars(amount), options);

export const getPercentage = (
  value: number,
  min: number,
  max: number
): number => {
  // Ensure the value is within the range
  if (value < min) return 0;
  if (value > max) return 100;

  // Calculate the percentage
  return ((value - min) / (max - min)) * 100;
};

/**
 * @param numerator
 * @param denomenator
 *
 * @return the calculated percentage of numerator/denomenator, formatted to a whole number
 */
export const calculatePercentage = (
  numerator?: number,
  denomenator?: number
) => {
  if (!!numerator && !!denomenator) {
    const decimal = parseFloat((numerator / denomenator).toFixed(2));
    const percentage = (decimal * 100).toFixed(0);
    return `${percentage}%`;
  } else if (!numerator && denomenator && denomenator > 0) {
    return `0%`;
  }
};
