import { XMarkIcon } from "@heroicons/react/24/outline";

import { DISQUALIFY_MODAL_ID } from "@/components/modals/disqualify-modal";
import { BaseControlButton } from "@/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import {
  AccountUndisqualifyConfirmationModal,
  CONFIRM_UNDISQUALIFY_MODAL_ID,
} from "@/modals/account-undisqualify-confirmation-modal";

export const DisqualifyButton = () => {
  const { accountStatus, account, campaign, onAccountUpdate } =
    useAccountDetailsContext();
  const isAccountDisqualified =
    accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.DISQUALIFIED;

  const handleDisqualificationAction = () => {
    // Handle the "Undisqualify" flow
    if (isAccountDisqualified) {
      modalHelpers.lazyOpen(CONFIRM_UNDISQUALIFY_MODAL_ID);
      return;
    }

    modalHelpers.trigger(DISQUALIFY_MODAL_ID);
  };

  return (
    <>
      <BaseControlButton
        className={isAccountDisqualified ? "text-[#349538]" : "text-[#D71D24]"}
        onClick={handleDisqualificationAction}
      >
        <XMarkIcon className="w-6" />
        <span>{isAccountDisqualified ? "Undisqualify" : "Disqualify"}</span>
      </BaseControlButton>

      {isAccountDisqualified && (
        <AccountUndisqualifyConfirmationModal
          campaignId={campaign?.id}
          accountId={account?.id}
          onSuccess={onAccountUpdate}
        />
      )}
    </>
  );
};
