export const CampaignCardUpcomingDate = ({
  shouldShowEffectiveDates,
  dialerInfoMessage,
  directCallInfoMessage,
  isDirectCallInfoMessage,
  isDialerInfoMessage,
}: {
  shouldShowEffectiveDates?: boolean;
  dialerInfoMessage?: string;
  directCallInfoMessage?: string;
  isDialerInfoMessage?: boolean;
  isDirectCallInfoMessage?: boolean;
}) => {
  return shouldShowEffectiveDates ? (
    <div className="flex flex-col gap-1">
      {!!isDialerInfoMessage && (
        <p className="ae-typography-body4 text-error-content">
          {dialerInfoMessage}
        </p>
      )}

      {!!isDirectCallInfoMessage && (
        <p className="ae-typography-body4 text-error-content">
          {directCallInfoMessage}
        </p>
      )}
    </div>
  ) : null;
};
