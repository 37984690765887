import { twMerge } from "tailwind-merge";
import clsx from "clsx";

interface CheckboxI {
  name: string;
  value: string;
  label: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  isToggleCheckBox?: boolean;
  disabled?: boolean;
  inputProps?: any;
}

const DefaultInput = (props: any) => <input {...props} />;

const Checkbox =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    value,
    label,
    className,
    labelClassName,
    inputClassName,
    isToggleCheckBox,
    disabled,
    inputProps,
  }: CheckboxI) =>
    (
      <div
        className={clsx(
          twMerge("form-control w-56 rounded-lg border px-2", className)
        )}
      >
        <label
          className={clsx(
            twMerge("label cursor-pointer justify-start", labelClassName)
          )}
        >
          <Field
            type="checkbox"
            name={name}
            value={isToggleCheckBox ? undefined : value}
            className={clsx(twMerge("checkbox", inputClassName))}
            disabled={disabled}
            {...inputProps}
          />
          <span className="label-text ml-4">{label}</span>
        </label>
      </div>
    );

export default Checkbox;
