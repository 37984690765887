import { useRef, useCallback, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";
import { CloseIcon } from "shared/ui/icons";

export const DANGEROUS_ACTION_CONFIRMATION_MODAL_ID =
  "dangerous-action-confirmation-modal";

const DangerousActionConfirmationModal = ({
  modalId = DANGEROUS_ACTION_CONFIRMATION_MODAL_ID,
  title,
  description,
  isLoading,
  mainButtonText = "Accept",
  cancelButtonText = "Cancel",
  icon = <ExclamationTriangleIcon className="mb-4 h-6 w-6" />,
  callback = async () => {},
}: {
  modalId?: string;
  title?: string;
  description?: string;
  isLoading?: boolean;
  mainButtonText?: string;
  cancelButtonText?: string;
  icon?: any;
  callback?: () => any;
}) => {
  const [disabled, disable] = useState(false);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleOnClick = useCallback(() => {
    const node = checkboxref.current;
    if (node) {
      node?.focus();
      node?.click();
    }
  }, [checkboxref]);

  const handleConfirmation = async () => {
    disable(true);

    await callback();

    setTimeout(() => {
      disable(false);

      handleOnClick();
    }, 1000);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
      />

      <label htmlFor={modalId} className="modal cursor-pointer">
        <label htmlFor="" className="w-full min-w-[370px] max-w-[520px]">
          <div className="modal-box relative rounded-lg p-6">
            <div className="flex items-center justify-between">
              <div>{icon}</div>
              <div>
                <label htmlFor={modalId} className="cursor-pointer">
                  <CloseIcon className="relative -top-2 h-4 w-4 text-black/40" />
                </label>
              </div>
            </div>

            {title && <h3 className="typography-h2 mb-3">{title}</h3>}
            {description && <p className="typography-body">{description}</p>}

            <div className="mt-6 flex justify-end">
              <label htmlFor={modalId} className="btn-ae-text mr-2">
                {cancelButtonText}
              </label>

              <button
                onClick={handleConfirmation}
                disabled={disabled}
                className={clsx("btn-ae-default", {
                  loading: disabled || isLoading,
                })}
              >
                {mainButtonText}
              </button>
            </div>
          </div>
        </label>
      </label>
    </>
  );
};

export default DangerousActionConfirmationModal;
