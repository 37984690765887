import React, { FC } from "react";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";
import { getImageAssetPath } from "customer/lib/helpers/asset";

interface HistoryCardAEInfoPropsI {
  className?: string;
  aeName: string;
}

export const HistoryCardAEInfo: FC<HistoryCardAEInfoPropsI> = ({ aeName }) => (
  <HistoryCardTextInfoItem
    icon={
      <div className="flex w-6 justify-center">
        <img
          alt="Prospect Name & Title"
          src={getImageAssetPath("call-learnings/card/prospect.webp")}
          className="w-[18px]"
        />
      </div>
    }
    subtitle="AE Name"
    info={[
      {
        title: aeName,
      },
    ]}
  />
);
