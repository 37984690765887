export const CUSTOM_MEETINGS_TOASTER_ID = "custom-meetings-toaser-container";

export const TOAST_ACTIONS = {
  ADD: "add",
  DISMISS: "dismiss",
} as const;

export const MEETINGS_TOAST_TYPES = {
  BOOKED_MEETING: "booked_meeting",
  // QUALIFIED_MEETING: "qualified_meeting",
  QUALIFIED_MEETING: "qualified_meeting_v2",
} as const;
