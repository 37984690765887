import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "@/components/shared/widgets/constants";
import { WidgetI } from "@/components/shared/widgets/interface";
import { LOG_CATEGORIES } from "@/constants/logs";
import { WIDGETS } from "@/constants/widgets";
import { GLOBAL_STATE_VAR_ACTIVE_WIDGETS } from "@/constants/window-globals";
import { PipelineListContactI } from "@/interfaces/pipeline/list";
import queryString from "query-string";
import { dd } from "./datadog";

/**
 * Used in various async events and Interval functions
 * which are outside of the WidgetProvider context (DialerGlobal)
 *
 * @returns {boolean}
 */
export const isDialerWidgetOpen = (connectedLead?: PipelineListContactI) => {
  const activeWidgets = (window as any)[
    GLOBAL_STATE_VAR_ACTIVE_WIDGETS
  ] as WidgetI[];

  if (connectedLead) {
    const urlInstance = new URL(window.location.href);
    const queryParams = queryString.parse(urlInstance.search);
    const widgetType =
      queryParams?.[WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE];
    const accountId =
      queryParams?.[WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID];
    const campaignId =
      queryParams?.[WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID];

    return (
      widgetType === WIDGETS.MAXED_DIALER &&
      connectedLead.account_id === accountId &&
      connectedLead?.campaign_id === campaignId
    );
  }

  return activeWidgets?.some((w) => w.id === WIDGETS.MAXED_DIALER);
};

export const getDialerWidgetUrlParams = () => {
  const urlInstance = new URL(window.location.href);
  const queryParams = queryString.parse(urlInstance.search);
  const widgetType =
    queryParams?.[WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE];
  const accountId = queryParams?.[WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID];
  const campaignId =
    queryParams?.[WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID];

  return {
    widgetType,
    accountId,
    campaignId,
  };
};

export const isDialerWidgetUrlParamsSet = () => {
  const dialerWidgetUrlParams = getDialerWidgetUrlParams();
  return Object.values(dialerWidgetUrlParams).every((param) => !!param);
};

/*
 * There is a split second state when widget is opened on in activeWidgets
 * url is not updated
 **/
export const isDialerWidgeParamsEqualConnectedLead = (
  connectedLead?: PipelineListContactI
) => {
  const queryParams = getDialerWidgetUrlParams();
  const { widgetType, accountId, campaignId } = queryParams;

  dd.log(
    `${LOG_CATEGORIES.DIALER_LIST} - isDialerWidgeParamsEqualConnectedLead`,
    {
      data: {
        connectedLead,
        queryParams,
      },
    }
  );

  return (
    widgetType === WIDGETS.MAXED_DIALER &&
    connectedLead?.account_id === accountId &&
    connectedLead?.campaign_id === campaignId
  );
};
