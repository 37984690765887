import { clsxMerge } from "shared/lib/helpers";
import { EditCampaignButton } from "@/modules/calling/modes/widget-mini-dialer/expanded-view/buttons/edit-campaign-button";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import {
  LabelValue,
  createRecord,
} from "@/modules/pipeline/account-details/sidebar/components/label-value";
import { formatMoneyDollars, formatNumbers } from "shared/lib/helpers/numbers";

export const CampaignInfoSection = ({ className }: { className?: string }) => {
  const { account, contact } = useInCallContext();
  const { campaign } = useCallingContext();

  const accountInfoItems = [
    createRecord("Campaign Name", campaign?.name || null),
    createRecord(
      "Revenue",
      account?.revenue
        ? `${formatMoneyDollars(account.revenue, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`
        : null
    ),
    createRecord(
      "Employees",
      account?.employee_count
        ? `${formatNumbers(account?.employee_count)}`
        : null
    ),
    createRecord("HQ State", account?.hq_state || null),
    createRecord("Industry", account?.industry || null),
  ];

  const additionalItems =
    contact?.meta?.values?.map((meta) =>
      createRecord(meta.label, meta.value || null)
    ) || [];

  return (
    <section className={clsxMerge(className)}>
      <div className="flex items-center justify-between">
        <h6 className="brand-typgoraphy-body3">Campaign Info</h6>

        <EditCampaignButton className="pr-0" />
      </div>
      <div>
        <div className="mb-5 mt-4 grid w-full grid-cols-2">
          {accountInfoItems?.map((record, i) => (
            <LabelValue
              key={i}
              className="mb-5"
              labelClassName="text-white font-normal"
              {...record}
            />
          ))}
        </div>

        {!!additionalItems?.length && (
          <div className="mb-2">
            {additionalItems?.map((record, i) => (
              <LabelValue
                key={i}
                className="mb-5"
                labelClassName="text-white font-normal"
                isTruncate
                {...record}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
