import { createIcon } from "./icon-factory";

const VerifiedSalesRepIcon = createIcon(
  <>
    <path
      fill="currentColor"
      d="M13.526 4.705c0 2.585-2.12 5.71-4.76 5.71-2.635 0-4.76-3.125-4.76-5.71C4.006 2.11 6.14 0 8.765 0c2.625 0 4.76 2.11 4.76 4.705ZM8.72 14.334h.02c.076 0 .151-.01.23-.015l1.39 4.366-1.595 1.97-1.595-1.97 1.396-4.36c.05.004.1.009.154.009ZM5.55 11.585l-.214-.345h6.9l-.17.27a3.906 3.906 0 0 1-3.34 1.826c-1.315-.006-2.5-.661-3.175-1.751Z"
    />
    <path
      fill="currentColor"
      d="M17.53 23.41v.09c0 .28-.224.5-.5.5H.5c-.28 0-.5-.22-.5-.5v-7.93c0-2.309 1.845-4.204 4.16-4.324l.54.87a4.686 4.686 0 0 0 2.86 2.075l-1.424 4.45a.505.505 0 0 0 .09.465l2.155 2.66a.496.496 0 0 0 .774 0l2.155-2.66a.49.49 0 0 0 .085-.465l-1.42-4.46a4.897 4.897 0 0 0 2.936-2.135l.5-.794c.13.01.255.02.38.04a6.828 6.828 0 0 0-2.505 5.295 6.857 6.857 0 0 0 6.244 6.824Z"
    />
    <path
      fill="currentColor"
      d="M18.14 10.724c-3.23 0-5.855 2.63-5.855 5.86a5.863 5.863 0 0 0 5.855 5.855c3.23 0 5.86-2.63 5.86-5.855 0-3.23-2.63-5.86-5.86-5.86Zm3.607 4.02-4.385 4.384a.499.499 0 0 1-.707 0l-2.12-2.12a.5.5 0 1 1 .707-.707l1.767 1.766 4.032-4.03a.5.5 0 1 1 .706.706Z"
    />
  </>
);
export default VerifiedSalesRepIcon;
