import React, { FC, ReactElement, useState } from "react";
import clsx from "clsx";

import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { getS3AssetPath } from "shared/lib/helpers";
import { UserI } from "@/interfaces/user";
import { useLiveTime } from "shared/lib/hooks/use-live-time";

type TitlePropI = string | ReactElement;

interface TitlePropsI {
  user: UserI | undefined;
  title: TitlePropI;
}

interface LiveTimePropsI {
  title: TitlePropI;
  isVisible: boolean;
}

const Background = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  });

  return (
    <div
      className={clsx(
        "absolute left-0 top-0 z-0 flex h-[1500px] w-full justify-center",
        "transition-[opacity,transform] duration-500",
        {
          "scale-50 opacity-0": !isVisible,
          "scale-100 opacity-100": isVisible,
        }
      )}
    >
      <img
        src={getS3AssetPath("platform/homepage/gradient-bg.png")}
        loading="lazy"
        className="relative left-[100px] top-[-100px] z-0"
        alt="gradient background"
        onLoad={() => setIsVisible(true)}
      />
    </div>
  );
};

const Title: FC<TitlePropsI> = ({ title, user }) => {
  if (title) {
    return typeof title === "string" ? (
      <div>
        <div className="ae-typography-h2">{title}</div>
        <div className="mt-3 h-[1px] w-[80px] bg-[#666666]"></div>
      </div>
    ) : (
      title
    );
  }

  return `Welcome back, ${user?.first_name}!`;
};

const LiveTime: FC<LiveTimePropsI> = ({ title, isVisible }) => {
  const liveTime = useLiveTime();

  if (title || !isVisible) {
    return null;
  }

  return (
    <span className="ae-typography-body2 ml-3 text-base-content/60">
      {liveTime}
    </span>
  );
};

export const V3PageHeaderComponents = {
  Background,
  Title,
  LiveTime,
};
