import { FC } from "react";
import {
  IdentificationIcon,
  CircleStackIcon,
  TagIcon,
} from "@heroicons/react/24/outline";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";
import { fixUrl, getFullEntityName } from "shared/lib/helpers";
import { ContactDetailI } from "shared/lib/interfaces/account";

interface HistoryCardActivityTypePropsI {
  contact: ContactDetailI;
  withMeta?: boolean;
}

export const HistoryCardContactInfo: FC<HistoryCardActivityTypePropsI> = ({
  contact,
  withMeta = false,
}) => (
  <>
    {contact && (
      <HistoryCardTextInfoItem
        icon={<IdentificationIcon className="h-6 w-6" />}
        subtitle="Contact details"
        infoClassName="max-w-[220px] break-words"
        info={[
          {
            title: getFullEntityName(contact),
          },
          {
            title: contact?.title || "No title",
          },
          {
            href: contact?.email ? `mailto:${contact.email}` : "#",
            text: contact?.email || "No email",
          },
          {
            href: contact?.phone ? `tel:${contact.phone}` : "#",
            text: contact?.phone || "No phone number",
          },
          {
            href: fixUrl(contact?.linkedin_url) || "",
            text: contact?.linkedin_url || "",
          },
        ]}
      />
    )}

    {withMeta && (
      <>
        {contact?.source && (
          <HistoryCardTextInfoItem
            icon={<CircleStackIcon className="h-6 w-6" />}
            info={[{ title: contact.source }]}
            subtitle="Source"
          />
        )}

        {contact?.meta?.values && (
          <HistoryCardTextInfoItem
            icon={<TagIcon className="h-6 w-6" />}
            info={contact.meta.values
              .map((value) => [value.label, value.value])
              .flat()
              .map((title, idx) => ({
                title: title || "No meta",
                isBold: idx % 2 === 0,
              }))}
            subtitle="Meta"
          />
        )}
      </>
    )}
  </>
);
