import { FC, useMemo } from "react";
import {
  CampaignCardDefault,
  CampaignCardLabelBooleanI,
  CampaignCardLabelValueI,
} from "shared/ui/cards/campaign-card/campaign-card-default";

import { CampaignCardDescription } from "shared/ui/cards/campaign-card/shared/campaign-card-description";
import { CampaignCardPriceDescription } from "shared/ui/cards/campaign-card/shared/campaign-card-price-description";
import { CampaignCardStats } from "shared/ui/cards/campaign-card/shared/campaign-card-stats";
import { CampaignCardFeatures } from "shared/ui/cards/campaign-card/shared/campaign-card-features";
import { CampaignCardStatusPills } from "shared/ui/cards/campaign-card/shared/campaign-card-status-pills";
import { CampaignCardExtended } from "shared/ui/cards/campaign-card/campaign-card-extended";
import { CampaignCardPerformance } from "shared/ui/cards/campaign-card/shared/campaign-card-performance";
import { CampaignCardActions } from "shared/ui/cards/campaign-card/shared/campaign-card-actions";
import { CampaignCardMini } from "shared/ui/cards/campaign-card/campaign-card-mini";
import { CampaignCardHighlights } from "shared/ui/cards/campaign-card/campaign-card-highlights";

import {
  CampaignUserStatusI,
  CampaignV3I,
} from "shared/lib/interfaces/campaign";
import {
  DayJs,
  convertTimeToNormalizedDuration,
  safeGetDate,
} from "shared/lib/helpers/date";
import { STATUS_TYPES } from "shared/lib/constants/statuses";
import { CAMPAIGN_USER_STATUS } from "shared/lib/constants/campaign";

import { StatusPill } from "shared/ui/pills";
import { CampaignCardUpcomingDate } from "shared/ui/cards/campaign-card/shared/campaign-card-upcoming-date";

import { CampaignCardLabels } from "./shared/campaign-card-labels";
import { CampaignCardIndustryPill } from "./shared/campaign-card-labels/campaign-card-industry-pill";
import { CampaignCardTrainingPill } from "./shared/campaign-card-labels/campaign-card-training-pill";

const getFormattedTimeAuditionResponseTime = (numHours: number) => {
  const { days, hours, minutes } = convertTimeToNormalizedDuration({
    hours: numHours,
  });

  let res = "";

  res += days ? `${days} days ` : "";
  res += hours ? `${hours}h ` : "";
  res += minutes ? `${minutes}m` : "";

  return res.length > 0 ? res : "Less than 1 hour";
};

const EFFECTIVE_DATE_FORMAT = "MM/DD/YYYY";

export const CAMPAIGN_CARD_TYPES = {
  DEFAULT: "default",
  EXTENDED: "extended",
  MINI: "mini",
  HIGHLIGHTS: "highlights",
  OPEN_MARKETPLACE: "open_marketplace",
} as const;

export const CAMPAIGN_CARD_PERFORMANCE_TYPES = {
  QUALIFIED_MEETINGS_BOOKED: "qualified_meetings_booked",
  CALLS_MADE: "calls_made",
  HOURS_SPENT_ON_CAMPAIGN: "hours_spent_on_campaign",
  SHOW_UP_RATE: "show_up_rate",
} as const;

export const CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP = {
  [CAMPAIGN_CARD_PERFORMANCE_TYPES.QUALIFIED_MEETINGS_BOOKED]:
    "Qualified Meetings Booked",
  [CAMPAIGN_CARD_PERFORMANCE_TYPES.CALLS_MADE]: "Calls Made",
  [CAMPAIGN_CARD_PERFORMANCE_TYPES.HOURS_SPENT_ON_CAMPAIGN]:
    "Hours Spent On Campaign",
  [CAMPAIGN_CARD_PERFORMANCE_TYPES.SHOW_UP_RATE]: "Show Up Rate",
} as const;

export interface CampaignCardI {
  type?: (typeof CAMPAIGN_CARD_TYPES)[keyof typeof CAMPAIGN_CARD_TYPES];
  logo_s3_url?: string;
  name?: string;
  created_at?: string;

  lowest_payout_in_usd?: string | number;
  highest_payout_in_usd?: string | number;
  weekly_payout_in_usd?: number;
  all_time_payout_in_usd?: number;

  hold_rate_numerator?: number;
  hold_rate_denominator?: number;
  all_time_hold_rate_numerator?: number;
  all_time_hold_rate_denominator?: number;

  industry?: string;
  elevator_pitch?: string;
  eligible_lead_count?: number;
  weekly_active_caller_count?: number;
  is_self_sourced_banned?: boolean;
  campaign_user_status?: CampaignUserStatusI;
  job_description?: CampaignV3I["job_description"];

  dialer_toggle_off_time?: string;
  direct_call_toggle_off_time?: string;
  onClick?: () => void;
  startCallingUrl?: string;
  assessmentUrl?: string;
  salesFloorUrl?: string;
  onClickTraining?: () => Promise<void>;
  my_qualified_meetings_booked?: number;
  my_calls_made?: number;
  my_hours_spent_on_campaign?: number;
  my_show_up_rate?: number;
  average_audition_response_time_in_hours?: number;
  audition_acceptance_rate?: number;
  steps_complete?: number;
  total_steps?: number;

  className?: string;
}

const createLabelValueObject = (
  label: string,
  value: string | number,
  accent?: boolean
) => ({
  label,
  value,
  accent,
});

const createLabelBooleanObject = (label: string, value: boolean) => ({
  label,
  value,
});
/**
 * Example
 * const MOCK_CAMPAIGN_DATA: CampaignV3I = {
  name: "Redpanda",
  logo_s3_url: "",
  hold_rate_numerator: 0,
  hold_rate_denominator: 1,
  weekly_active_caller_count: 50,
  eligible_lead_count: 10000,
  industry: "SaaS",
  lowest_payout_in_usd: 500,
  highest_payout_in_usd: 500,
  weekly_payout_in_usd: 2000,
  elevator_pitch:
    "Redpanda is a simple, powerful, and cost-efficient streaming data platform that is compatible with Kafka® APIs while...",
  job_description: {
    id: "123",
    created_at: "",
    updated_at: "",
    url: "https://www.glencoco.com/",
  },
  is_self_sourced_banned: true,
};
 * @returns 
 */
export const CampaignCard: FC<CampaignCardI> = ({
  type = CAMPAIGN_CARD_TYPES.DEFAULT,
  logo_s3_url,
  name,
  created_at = "",
  lowest_payout_in_usd,
  highest_payout_in_usd,
  all_time_payout_in_usd,
  industry,
  elevator_pitch,
  weekly_active_caller_count,
  eligible_lead_count,
  all_time_hold_rate_numerator,
  all_time_hold_rate_denominator,
  is_self_sourced_banned,
  campaign_user_status,
  job_description,

  dialer_toggle_off_time,
  direct_call_toggle_off_time,
  onClick = () => {},
  startCallingUrl,
  assessmentUrl,
  salesFloorUrl,
  onClickTraining,
  my_qualified_meetings_booked,
  my_calls_made,
  my_hours_spent_on_campaign,
  my_show_up_rate,
  average_audition_response_time_in_hours,
  audition_acceptance_rate,
  steps_complete = 0,
  total_steps = 0,

  className,
}) => {
  const isDialerInfoMessage =
    !!dialer_toggle_off_time && DayJs().isBefore(dialer_toggle_off_time, "day");

  const isDirectCallInfoMessage =
    !!direct_call_toggle_off_time &&
    DayJs().isBefore(dialer_toggle_off_time, "day");

  const shouldShowEffectiveDates =
    isDialerInfoMessage || isDirectCallInfoMessage;

  const accentStats: Array<CampaignCardLabelValueI> = useMemo(
    () => [
      createLabelValueObject(
        "Audition Response Time",
        average_audition_response_time_in_hours &&
          average_audition_response_time_in_hours > 0
          ? getFormattedTimeAuditionResponseTime(
              average_audition_response_time_in_hours
            )
          : "-",
        true
      ),
      createLabelValueObject(
        "Acceptance Rate",
        audition_acceptance_rate && audition_acceptance_rate > 0
          ? `${Math.floor(audition_acceptance_rate * 100)}%`
          : "-",
        true
      ),
    ],
    [average_audition_response_time_in_hours, audition_acceptance_rate]
  );
  const basicStats: Array<CampaignCardLabelValueI> = useMemo(
    () => [
      createLabelValueObject(
        "Eligible Lead Count",
        eligible_lead_count as number
      ),
      createLabelValueObject(
        "Weekly Active Callers",
        weekly_active_caller_count as number
      ),
      createLabelValueObject(
        "Hold Rate",
        `${all_time_hold_rate_numerator}/${all_time_hold_rate_denominator}`
      ),
    ],
    [
      eligible_lead_count,
      weekly_active_caller_count,
      all_time_hold_rate_numerator,
      all_time_hold_rate_denominator,
    ]
  );

  const stats: Array<CampaignCardLabelValueI> = useMemo(
    () => [...accentStats, ...basicStats],
    [accentStats, basicStats]
  );

  const features: Array<CampaignCardLabelBooleanI> = useMemo(
    () => [
      createLabelBooleanObject(
        "Campaign provided leads",
        !dialer_toggle_off_time
      ),
      createLabelBooleanObject("Self sourced leads", !is_self_sourced_banned),
      createLabelBooleanObject("Hiring full-time", !!job_description),
    ],
    [is_self_sourced_banned, job_description, dialer_toggle_off_time]
  );

  const myPerformanceData = useMemo(
    () => [
      createLabelValueObject(
        CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP[
          CAMPAIGN_CARD_PERFORMANCE_TYPES.QUALIFIED_MEETINGS_BOOKED
        ],
        my_qualified_meetings_booked as number
      ),
      createLabelValueObject(
        CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP[
          CAMPAIGN_CARD_PERFORMANCE_TYPES.CALLS_MADE
        ],
        my_calls_made as number
      ),
      createLabelValueObject(
        CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP[
          CAMPAIGN_CARD_PERFORMANCE_TYPES.HOURS_SPENT_ON_CAMPAIGN
        ],
        my_hours_spent_on_campaign as number
      ),
      createLabelValueObject(
        CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP[
          CAMPAIGN_CARD_PERFORMANCE_TYPES.SHOW_UP_RATE
        ],
        my_show_up_rate as number
      ),
    ],
    [
      my_qualified_meetings_booked,
      my_calls_made,
      my_hours_spent_on_campaign,
      my_show_up_rate,
    ]
  );

  const price = useMemo(() => {
    return lowest_payout_in_usd === highest_payout_in_usd
      ? `$${lowest_payout_in_usd}`
      : `$${lowest_payout_in_usd} - $${highest_payout_in_usd}`;
  }, [lowest_payout_in_usd, highest_payout_in_usd]);

  const dialerInfoMessage = useMemo(() => {
    return `No dialer use will be allowed starting ${safeGetDate(
      dialer_toggle_off_time as string,
      EFFECTIVE_DATE_FORMAT
    )}`;
  }, [dialer_toggle_off_time]);

  const directCallInfoMessage = useMemo(() => {
    return `No callbacks will be allowed starting ${safeGetDate(
      direct_call_toggle_off_time as string,
      EFFECTIVE_DATE_FORMAT
    )}`;
  }, [direct_call_toggle_off_time]);

  return (
    <div>
      {type === CAMPAIGN_CARD_TYPES.DEFAULT && (
        <CampaignCardDefault
          imgSrc={logo_s3_url}
          title={name}
          status={
            <CampaignCardStatusPills
              created_at={created_at}
              campaign_user_status={campaign_user_status}
              job_description={job_description}
              steps_complete={steps_complete}
              total_steps={total_steps}
            />
          }
          upcomingDates={
            <CampaignCardUpcomingDate
              shouldShowEffectiveDates={shouldShowEffectiveDates}
              dialerInfoMessage={dialerInfoMessage}
              directCallInfoMessage={directCallInfoMessage}
              isDialerInfoMessage={isDialerInfoMessage}
              isDirectCallInfoMessage={isDirectCallInfoMessage}
            />
          }
          price={price}
          priceDescription={
            all_time_payout_in_usd ? (
              <CampaignCardPriceDescription price={all_time_payout_in_usd} />
            ) : undefined
          }
          labels={
            <CampaignCardLabels
              labels={[
                <CampaignCardIndustryPill industry={industry as string} />,
              ]}
            />
          }
          description={<CampaignCardDescription text={elevator_pitch} />}
          stats={<CampaignCardStats stats={stats} />}
          features={<CampaignCardFeatures features={features} />}
          onClick={onClick}
          className={className}
        />
      )}

      {type === CAMPAIGN_CARD_TYPES.EXTENDED && (
        <CampaignCardExtended
          imgSrc={logo_s3_url}
          title={name}
          status={
            <CampaignCardStatusPills
              created_at={created_at}
              campaign_user_status={campaign_user_status}
              job_description={job_description}
              steps_complete={steps_complete}
              total_steps={total_steps}
            />
          }
          upcomingDates={
            <CampaignCardUpcomingDate
              shouldShowEffectiveDates={shouldShowEffectiveDates}
              dialerInfoMessage={dialerInfoMessage}
              directCallInfoMessage={directCallInfoMessage}
              isDialerInfoMessage={isDialerInfoMessage}
              isDirectCallInfoMessage={isDirectCallInfoMessage}
            />
          }
          price={price}
          priceDescription={
            all_time_payout_in_usd ? (
              <CampaignCardPriceDescription price={all_time_payout_in_usd} />
            ) : undefined
          }
          labels={
            <CampaignCardLabels
              labels={[
                <CampaignCardIndustryPill industry={industry as string} />,
              ]}
            />
          }
          description={<CampaignCardDescription text={elevator_pitch} />}
          stats={<CampaignCardStats stats={stats} />}
          features={<CampaignCardFeatures features={features} />}
          performance={<CampaignCardPerformance stats={myPerformanceData} />}
          actions={
            <CampaignCardActions
              campaign_user_status={campaign_user_status}
              startCallingUrl={startCallingUrl}
              assessmentUrl={assessmentUrl}
              salesFloorUrl={salesFloorUrl}
              onClickTraining={onClickTraining}
            />
          }
          onClick={onClick}
          className={className}
        />
      )}

      {type === CAMPAIGN_CARD_TYPES.MINI && (
        <CampaignCardMini
          imgSrc={logo_s3_url}
          title={name}
          price={price}
          priceDescription={
            all_time_payout_in_usd ? (
              <CampaignCardPriceDescription price={all_time_payout_in_usd} />
            ) : undefined
          }
          labels={
            <CampaignCardLabels
              labels={[
                <CampaignCardIndustryPill industry={industry as string} />,
                CAMPAIGN_USER_STATUS.AUDITION_IN_REVIEW ===
                campaign_user_status ? (
                  <StatusPill status={STATUS_TYPES.PENDING} />
                ) : (
                  <></>
                ),
                steps_complete < total_steps ? (
                  <CampaignCardTrainingPill
                    steps_complete={steps_complete}
                    total_steps={total_steps}
                  />
                ) : (
                  <></>
                ),
              ]}
            />
          }
          actions={
            <CampaignCardActions
              stacked
              campaign_user_status={campaign_user_status}
              startCallingUrl={startCallingUrl}
              assessmentUrl={assessmentUrl}
              salesFloorUrl={salesFloorUrl}
              onClickTraining={onClickTraining}
            />
          }
          onClick={onClick}
          className={className}
        />
      )}

      {type === CAMPAIGN_CARD_TYPES.HIGHLIGHTS && (
        <CampaignCardHighlights
          imgSrc={logo_s3_url}
          title={name}
          status={
            <CampaignCardStatusPills
              created_at={created_at}
              campaign_user_status={campaign_user_status}
              job_description={job_description}
              steps_complete={steps_complete}
              total_steps={total_steps}
            />
          }
          price={price}
          priceDescription={
            all_time_payout_in_usd ? (
              <CampaignCardPriceDescription
                price={all_time_payout_in_usd}
                className="flex items-center justify-center rounded-lg bg-[#2CA83114]"
              />
            ) : undefined
          }
          labels={
            <CampaignCardLabels
              className="flex-col"
              labels={[
                <CampaignCardIndustryPill
                  industry={industry as string}
                  className={"mr-0"}
                />,
              ]}
            />
          }
          stats={<CampaignCardStats stats={accentStats} hideBasicStats />}
          onClick={onClick}
          className={className}
        />
      )}
    </div>
  );
};
