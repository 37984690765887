import { useState } from "react";
import {
  DATE_FORMAT_WITHOUT_TIME,
  DAYS,
  MONTHS,
} from "shared/lib/constants/time";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffectOnce } from "shared/lib/hooks";
import { AccountHistoryI } from "@/interfaces/accounts";
import { safeGetDate } from "shared/lib/helpers";
import { DISPOSITIONS_STRING_MAP } from "shared/lib/constants/dispositions";
import { XCircleIcon } from "@heroicons/react/24/solid";

const ActivityCardMeetingOutcome = ({
  activity,
}: {
  activity: AccountHistoryI;
}) => {
  const [isInit, setIsInit] = useState(false);

  useEffectOnce(() => {
    setIsInit(true);
  });

  return (
    <div>
      <div
        className={clsx(
          "relative flex gap-2",
          "items-center",
          "transition-[opacity,top] duration-300",
          {
            "-top-5 opacity-0": !isInit,
            "top-0 opacity-100": isInit,
          }
        )}
      >
        <div
          className={clsx(
            "flex h-8 w-8 items-center justify-center rounded-full border border-black/10 bg-white"
          )}
        >
          <XCircleIcon className="h-5 w-5 text-black/60" />
        </div>

        <div>
          <p className="ae-typography-detail1 text-black/40">
            <span className="uppercase">
              {`${DAYS[dayjs(activity.created_at).day()]}, 
            ${MONTHS[dayjs(activity.created_at).month()]} 
            ${dayjs(activity.created_at).date()}`}
            </span>
          </p>

          <p className="ae-typography-body2 mb-1">
            Meeting booked for{" "}
            {safeGetDate(activity.follow_up_time, DATE_FORMAT_WITHOUT_TIME)}
          </p>

          {activity.meeting_disposition && (
            <p className="ae-typography-body2">
              {DISPOSITIONS_STRING_MAP[activity.meeting_disposition]}
            </p>
          )}
        </div>
      </div>

      <div>
        {activity.notes && (
          <div className="ae-typography-detail1 mt-2 break-keep rounded-md bg-black/5 p-2 text-black/60">
            {activity.notes}
          </div>
        )}

        <div className="mt-1 flex items-center gap-1">
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full border border-black/10 bg-white text-[6px] text-xs">
            {activity.caller_name?.[0]}
            {activity.caller_name?.split(" ")?.[1]?.[0]}
          </div>
          <p className="ae-typography-detail1 text-black/40">
            {activity.caller_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActivityCardMeetingOutcome;
