import { useMutation, useQueryClient } from "@tanstack/react-query";

import { glencocoClientAPI } from "@/api/glencoco";
import { UpdateGoalTrackerRequestI } from "@/api/routes/performance/interfaces";
import { GOAL_TRACKER_PROGRESS_QUERY_KEY } from "@/api/routes/performance/queries";

export const useUpdateGoalTrackerMetrics = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateGoalTrackerRequestI) =>
      glencocoClientAPI().updateGoalTrackerMetrics(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GOAL_TRACKER_PROGRESS_QUERY_KEY],
      });
    },
  });
};
