import { useEffect, useRef, useState } from "react";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { DISPOSITIONS, DISPOSITION_DETAILS } from "@/constants/dispositions";
import MeetingBookedCard from "./meeting-booked-card";
import CallBackBooked from "./call-back-booked-card";
import { SetDispositionCardV2 } from "./set-disposition-card-v2";

import clsx from "clsx";

enum DISPOSITION_TYPES {
  NO_CALLBACK_NO_MEETING,
  CALLBACK_BOOKED,
  MEETING_BOOKED,
}

export interface DataI {
  notes?: string;
  callback?: boolean;
  disposition?: (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS];
  dispositionDetails?: keyof typeof DISPOSITION_DETAILS;
}

const CallDispositionCard = (props: {
  isWrapperNoStyles?: boolean;
  isHideSetCallbackQuestion?: boolean;
  isHideLeadDescription?: boolean;
  disposition?: (typeof DISPOSITION_TYPES)[keyof typeof DISPOSITION_TYPES];
  onSuccess?: () => void;
}) => {
  const inCallContext = useInCallContext();
  const [dispositionType, setDispositionType] = useState<DISPOSITION_TYPES>();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inCallContext.calendlyEvent) {
      setDispositionType(DISPOSITION_TYPES.MEETING_BOOKED);
    } else if (inCallContext.callbackData?.isCallbackSet) {
      setDispositionType(DISPOSITION_TYPES.CALLBACK_BOOKED);
    } else {
      setDispositionType(DISPOSITION_TYPES.NO_CALLBACK_NO_MEETING);
    }
  }, [inCallContext.calendlyEvent, inCallContext.callbackData?.isCallbackSet]);

  return (
    <div
      ref={scrollContainerRef}
      className={clsx(
        "max-h-[calc(100vh-50px)] w-auto overflow-y-auto overflow-x-hidden scroll-smooth",
        {
          "rounded-[12px]": !props?.isWrapperNoStyles,
          "p-6":
            DISPOSITION_TYPES.NO_CALLBACK_NO_MEETING !==
            (props.disposition || dispositionType),
        }
      )}
    >
      {DISPOSITION_TYPES.MEETING_BOOKED ===
        (props.disposition || dispositionType) && (
        <MeetingBookedCard {...props} />
      )}

      {DISPOSITION_TYPES.CALLBACK_BOOKED ===
        (props.disposition || dispositionType) && <CallBackBooked {...props} />}

      {DISPOSITION_TYPES.NO_CALLBACK_NO_MEETING ===
        (props.disposition || dispositionType) && (
        <SetDispositionCardV2 onSuccess={props?.onSuccess} />
      )}
    </div>
  );
};

export default CallDispositionCard;
