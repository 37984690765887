import { useFrequentlyChangingCallingContext } from "@/hooks/dialer/use-dialer-context";
import { ConnectionIcon, PhoneEndCallIcon } from "shared/ui/icons";

const ConnectionQuality = () => {
  const { callQuality } = useFrequentlyChangingCallingContext();

  return callQuality > 2.5 ? (
    <div className="flex items-center">
      <ConnectionIcon className="mr-1 h-3 text-[#00FF94]" />
      <span className="ae-typography-detail1 text-black/40">
        Connection <span className="text-black/80">Strong</span>
      </span>
    </div>
  ) : (
    <div className="flex items-center">
      <ConnectionIcon className="mr-1 h-3 text-[#ff1e00]" />
      <span className="ae-typography-detail1 text-black/40">
        Connection <span className="text-black/80">Weak</span>
      </span>
    </div>
  );
};

export const ConnectionQualitySection = ({
  isCallEnded,
}: {
  isCallEnded?: boolean;
}) =>
  isCallEnded ? (
    <div className="flex items-center">
      <PhoneEndCallIcon className="mr-2 w-[18px] text-base-content" />
      <span className="ae-typography-detail1 text-base-content/60">
        Call Ended
      </span>
    </div>
  ) : (
    <ConnectionQuality />
  );
