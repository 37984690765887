import { useEffect } from "react";

import { useQueryState } from "nuqs";
import { WIDGETS } from "@/constants/widgets";
import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "@/components/shared/widgets/constants";

export const useMaxedDialerSearchQueryParams = (
  campaignId: string,
  accountId: string
) => {
  const [, setWidgetType] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE
  );
  const [, setCampaignId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID
  );
  const [, setAccountId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID
  );

  useEffect(() => {
    setWidgetType(WIDGETS.MAXED_DIALER);

    return () => {
      setWidgetType(null);
      setCampaignId(null);
      setAccountId(null);
    };
  }, []);

  useEffect(() => {
    setCampaignId(campaignId);
    setAccountId(accountId);
  }, [campaignId, accountId]);
};
