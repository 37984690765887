import Router from "@/helpers/router";
import { PlusIcon } from "@heroicons/react/24/solid";
import { clsxMerge } from "shared/lib/helpers";

export const CreateLeadLink = () => {
  return (
    <div className="mb-4 mt-2 flex justify-center">
      <a
        href={Router.createNewLead()}
        target="_blank"
        rel="noreferrer"
        className={clsxMerge(
          "ae-typography-detail1",
          "cursor-pointer text-white",
          "flex items-center justify-center p-2"
        )}
      >
        <PlusIcon className="w-4" />{" "}
        <span className="ml-1">Create New Lead</span>
      </a>
    </div>
  );
};
