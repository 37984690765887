import { MetaI } from "@/interfaces/meta";

export const openGraph = (meta: MetaI) => {
  return {
    type: "website",
    locale: "en_US",
    siteName: "Glencoco",
    url: meta.canonical
      ? `${process.env.NEXT_PUBLIC_DOMAIN}${meta.canonical}`
      : `${process.env.NEXT_PUBLIC_DOMAIN}`,
    images: [
      {
        url: "https://glencoco-assets.s3.amazonaws.com/social/v2/share-facebook.png",
        width: 1200,
        height: 630,
        alt: "Glencoco company logo",
      },
    ],
    ...(meta.openGraph || {}),
  };
};
