import { AxiosInstance } from "axios";

import { handleOnResponseInterceptor } from "./handler-response";
import { handleOnErrorInterceptor } from "./handler-error";

export const interceptorResponse = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    handleOnResponseInterceptor,
    handleOnErrorInterceptor
  );
};
