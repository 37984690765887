import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

export interface ADPrimaryContentContextI {
  note?: string;
  setNote: Dispatch<SetStateAction<string | undefined>>;
}

export interface ADPrimaryContentProviderI {}

const ADPrimaryContentContext = createContext<ADPrimaryContentContextI>({
  note: "",
  setNote: () => {},
});

/**
 * Primary purpsose to share values between
 * Account Details Primary Content Sections
 */
export const ADPrimaryContentProvider: FC<
  ADPrimaryContentProviderI & PropsWithChildren
> = ({ children }) => {
  const [note, setNote] = useState<string>();

  return (
    <ADPrimaryContentContext.Provider
      value={{
        note,
        setNote,
      }}
    >
      {children}
    </ADPrimaryContentContext.Provider>
  );
};

export const useADPrimaryContentContext = (): ADPrimaryContentContextI =>
  useContext(ADPrimaryContentContext);
