import { useRef, useCallback, useState } from "react";
import { LightBulbIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

export const START_DIALER_TOUR_MODAL_ID = "start-dialer-tour-modal";

const StartDialerTourModal = ({
  modalId = START_DIALER_TOUR_MODAL_ID,
  title,
  description,
  isLoading,
  mainButtonText = "Accept",
  cancelButtonText = "Cancel",
  onCancel = () => {},
  callback = () => {},
}: {
  modalId?: string;
  title?: string;
  description?: string;
  isLoading?: boolean;
  mainButtonText?: string;
  cancelButtonText?: string;
  onCancel?: () => any;
  callback?: () => any;
}) => {
  const [disabled, disable] = useState(false);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleOnClick = useCallback(() => {
    const node = checkboxref.current;
    if (node) {
      node?.focus();
      node?.click();
    }
  }, [checkboxref]);

  const handleCancel = () => {
    onCancel();
    handleOnClick();
  };

  const handleConfirmation = () => {
    disable(true);

    setTimeout(() => {
      callback();

      disable(false);

      handleOnClick();
    }, 1000);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
      />

      <div className="modal">
        <div className="w-full min-w-[370px] max-w-[520px]">
          <div className="modal-box relative rounded-lg p-6">
            <LightBulbIcon className="mb-4 h-6 w-6" />

            {title && <h3 className="typography-h2 mb-3">{title}</h3>}
            {description && <p className="typography-body">{description}</p>}

            <div className="mt-6 flex justify-end">
              <button
                onClick={handleCancel}
                className={clsx("btn-ae-text mr-2")}
              >
                {cancelButtonText}
              </button>

              <button
                onClick={handleConfirmation}
                disabled={disabled}
                className={clsx("btn-ae-default", {
                  loading: disabled || isLoading,
                })}
              >
                {mainButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartDialerTourModal;
