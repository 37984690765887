import { FC } from "react";
import { Portal } from "react-portal";

import { clsxMerge } from "shared/lib/helpers";
import { CloseIcon } from "shared/ui/icons";
import SidebarV2 from "shared/ui/sidebarV2";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { EmailTemplatesSidebarContent } from "@/modules/email-templates-sidebar/content";

interface EmailTemplatesSidebarPropsI {
  isOpen: boolean;
  onClose?: () => void;
}

export const EmailTemplatesSidebar: FC<EmailTemplatesSidebarPropsI> = ({
  isOpen,
  onClose = () => {},
}) => {
  return (
    <Portal>
      <SidebarV2 isOpen={isOpen} onClose={onClose}>
        <SidebarSlide size="lg" contentClassName="p-0">
          <button
            className={clsxMerge(
              "btn btn-square btn-sm",
              "absolute right-2 top-2",
              "z-20"
            )}
            onClick={onClose}
          >
            <CloseIcon className="w-6 text-white" />
          </button>

          {isOpen && <EmailTemplatesSidebarContent />}
        </SidebarSlide>
      </SidebarV2>
    </Portal>
  );
};
