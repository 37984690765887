import { FC, memo } from "react";
import clsx from "clsx";
import isNumber from "lodash/isNumber";

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { pluralizeNoun } from "shared/lib/helpers/utils";
import { twMerge } from "tailwind-merge";
import { clsxMerge } from "../../../lib/helpers";

interface TextFieldI {
  name: string;
  label?: string;
  placeholder: string;
  errors?: string;
  touched?: boolean;
  inputProps?: any;
  showCharacterCounter?: boolean;
  contentLength?: number;
  textareaClassName?: string;
  className?: string;
  labelClassName?: string;
  labelContentClassName?: string;
  errorsClassName?: string;
}

const DefaultInput = (props: any) => <input {...props} />;

const CharactersCounter: FC<{
  contentLength?: number;
  maxLength?: number;
}> = memo(({ contentLength, maxLength }) => {
  if (!(isNumber(contentLength) && contentLength >= 0 && maxLength)) {
    return null;
  }

  const remainingCharacters = maxLength - (contentLength || 0);

  return (
    <div className="mb-2 mt-1 text-left text-xs">
      {remainingCharacters} {pluralizeNoun("character", remainingCharacters)}{" "}
      remaining
    </div>
  );
});

CharactersCounter.displayName = "CharactersCounter";

const TextArea =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    label,
    placeholder,
    errors,
    touched,
    inputProps,
    showCharacterCounter,
    contentLength,
    className,
    textareaClassName,
    labelClassName,
    labelContentClassName,
    errorsClassName,
  }: TextFieldI) =>
    (
      <div className={twMerge(clsx("form-control mb-4 w-full", className))}>
        {!!label && (
          <label className={twMerge(clsx("label", labelClassName))}>
            <span
              className={twMerge(
                clsx("label-text font-bold", labelContentClassName)
              )}
            >
              {label}
            </span>
          </label>
        )}

        <Field
          name={name}
          type="text"
          as="textarea"
          rows={4}
          placeholder={placeholder}
          className={clsx(
            `textarea textarea-bordered mb-1 grow`,
            {
              "border-error-content": errors && touched,
            },
            textareaClassName
          )}
          {...inputProps}
        />

        {showCharacterCounter && (
          <CharactersCounter
            contentLength={contentLength}
            maxLength={inputProps.maxLength}
          />
        )}

        {errors && touched && (
          <div
            className={clsxMerge("flex items-center text-xs", errorsClassName)}
          >
            <ExclamationCircleIcon className="text-error-content mr-1 h-5 w-5" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}
      </div>
    );

export default TextArea;
