import { CSSProperties } from "react";
import { clsxMerge } from "shared/lib/helpers";

export const DefaultPill = ({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) => (
  <span
    style={
      {
        "--pulse-alt-start-color": "rgba(248, 129, 103, 1)",
        "--pulse-alt-end-color": "rgba(0,0,0,0)",
      } as CSSProperties
    }
    className={clsxMerge(
      "bg-[#F88167] text-white",
      "ae-typography-detail1 badge",
      "rounded-[4px] border-[transparent]",
      className
    )}
  >
    {children}
  </span>
);
