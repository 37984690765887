import { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";

import { LeadCardV2MetadataI } from "@/modules/calling/cards/lead-card/v2";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";

// This hook is used to persist the catchups data to be non-empty to prevent
// lead cards to drop on clearing call data and switching to the next card
export const usePersistedCatchupsData = () => {
  const { account = {}, contact = {}, metadata } = useInCallContext();

  const [persistedContact, setPersistedContact] =
    useState<CallContactI>(contact);
  const [persistedAccount, setPersistedAccount] =
    useState<CallAccountI>(account);
  const [persistedMetadata, setPersistedMetadata] = useState(
    (metadata || {}) as LeadCardV2MetadataI
  );

  useEffect(() => {
    if (metadata) {
      setPersistedMetadata(metadata as LeadCardV2MetadataI);
    }
  }, [metadata]);

  useEffect(() => {
    if (!_isEmpty(contact) && !_isEmpty(account)) {
      setPersistedContact(contact);
      setPersistedAccount(account);
    }
  }, [contact, account]);

  return {
    contact: persistedContact,
    account: persistedAccount,
    metadata: persistedMetadata,
  };
};
