import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import { AnchorHTMLAttributes } from "react";

const VARIATIONS = {
  ACCENT: "accent",
};

const Label = ({
  className,
  children,
  variation,
  ...linkProps
}: {
  className?: string;
  children: any;
  variation?: "accent" | boolean | null | undefined;
} & AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <div
    className={twMerge(
      clsx(
        "ae-typography-detail1 inline-block rounded bg-white px-2 py-1",
        {
          "text-base-content bg-white": !Object.values(VARIATIONS).includes(
            variation as string
          ),
          "bg-[#0F35FF]/10 text-[#0F35FF]": variation === VARIATIONS.ACCENT,
        },
        className
      )
    )}
  >
    {linkProps?.href ? <a {...linkProps}>{children}</a> : children}
  </div>
);

export default Label;
