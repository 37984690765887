import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "@/components/shared/widgets/constants";
import { WIDGETS } from "@/constants/widgets";
import { GENERAL_FORUM_POST_TYPES } from "shared/lib/constants/forum";

export const QUERY_PARAMS = {
  LIST: "list",
  NEW_LEAD: "new_lead",
} as const;

const CUSTOMER_DOMAIN = process.env.NEXT_PUBLIC_CUSTOMER_DOMAIN;

const getLandingDomain = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case "local":
      return "http://localhost:3004/";
    case "staging":
      return "https://landing-staging.glencoco.com/";
    default:
      return "https://www.glencoco.com/";
  }
};

const Router = {
  home: () => "/",
  dashboard: () => "/dashboard",
  onboarding: () => "/onboarding",
  verify: () => `/verify`,
  coaching: () => "/coaching",
  meetings: () => "/meetings",
  callHistory: () => "/history",
  messages: () => "/messages",
  performanceDashboard: () => "/performance",
  leaderboard: () => "/leaderboard",
  trophies: () => "/trophies",
  // NOTE in stack overflow people ask how to auth0 logout without context
  //      help them and tell about the workaround
  profileInactive: () => "/profile-inactive",
  logout: () => "/logout",
  signup: () => "/signup",
  session: (campaignId = "") =>
    campaignId ? `/session/${campaignId}` : `/session`,
  settingsProfile: () => `/settings/profile`,
  settingsCallForwarding: () => `/settings/call-forwarding`,
  publicProfile: () => `/settings/profile/public`,
  settingsPayouts: () => `/settings/payouts`,
  settingsAvailability: () => `/settings/availability`,
  settingsReferrals: () => `/settings/referrals`,
  marketplace: () => `/discover`,
  marketplaceCampaign: (campaignId = "", query = "") =>
    encodeURI(`/discover/${campaignId}${query ? `?${query}` : ""}`),
  training: (campaignId = "") => `/campaign/${campaignId}/training`,
  trainingModule: (campaignId = "", trainingModuleId = "") =>
    `/campaign/${campaignId}/training/${trainingModuleId}`,
  myCampaigns: () => `/my-campaigns`,
  mySalesFloors: () => `/my-sales-floors`,

  //TODO all account links rename to pipeline
  accounts: (listId?: string) => `/accounts${listId ? `?list=${listId}` : ""}`,
  lists: (listId?: string) => `/lists${listId ? `?list=${listId}` : ""}`,

  accountDetailsResources: (campaignId: string, accountId: string) =>
    `/accounts/${campaignId}/${accountId}?tab=resources`,

  accountDetailsMessages: (campaignId: string, accountId: string) =>
    `/accounts/${campaignId}/${accountId}?tab=messages`,

  bonuses: () =>
    `https://help.glencoco.com/en/articles/8876957-glencoco-top-callers-reward-program`,

  customerCampaignBillingSettings: (campaignID: string) =>
    `${CUSTOMER_DOMAIN}/campaign/${campaignID}/billing`,

  createNewLead: () => `${Router.accounts()}?${QUERY_PARAMS.NEW_LEAD}=1`,
  beginAssessment: (campaignId = "") => `/assessment/${campaignId}`,
  salesFloor: () => `/sales-floor`,
  salesFloorGeneralByCategory: (category?: string) =>
    category ? `/sales-floor/general/${category}` : `/sales-floor`,
  salesFloorGeneralByCategoryPost: (category?: string, postId?: string) =>
    category
      ? `/sales-floor/general/${category}/${postId}`
      : `/sales-floor/general/${GENERAL_FORUM_POST_TYPES.DEFAULT}/${postId}`,
  salesFloorByCampaign: ({
    campaignId = "",
    category,
  }: {
    campaignId: string;
    category?: string;
  }) => `/sales-floor/campaign/${campaignId}${category ? `/${category}` : ""}`,
  salesFloorByCampaignPost: ({
    campaignId = "",
    postId = "",
    category,
  }: {
    campaignId: string;
    postId: string;
    category?: string;
  }) =>
    `/sales-floor/campaign/${campaignId}/${
      category ? `${category}/` : ""
    }${postId}`,
  catchupsWalkthrough: () => "/demo/catchups",

  glencocoOpenMarketplace: () => `${getLandingDomain()}/openings`,

  searchQueryParams: {
    accountDetails: (campaignId: string, accountId: string) =>
      `${WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE}=${WIDGETS.MAXED_DIALER}&${WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID}=${accountId}&${WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID}=${campaignId}`,
  },
};

export default Router;
