import Calendar from "react-calendar";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

const DatePicker = ({
  value,
  placeholder = "MM / DD / YYYY",
  onChange = () => {},
}: {
  value?: string | Date;
  placeholder?: string;
  onChange?: (e: any) => void;
}) => {
  const datepickerRef = useRef<HTMLInputElement>(null);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const triggerVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  const handleHideEvent = (event: any) => {
    const target = event?.target as Node;
    if (target && !datepickerRef?.current?.contains(target)) {
      setIsCalendarVisible(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("click", handleHideEvent);

      return () => {
        window.removeEventListener("click", handleHideEvent);
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setInputValue(
        value ? dayjs(value).format("MM/DD/YYYY") : (value as string)
      );
    }
  }, [value]);

  return (
    <div className="w-full rounded-lg" ref={datepickerRef}>
      <div
        className={clsx(
          "input ae-typography-body1 grow bg-black/5 focus:bg-black/10",
          "flex items-center justify-start",
          "cursor-pointer",
          {
            "text-base-content/40": !value,
          }
        )}
        onClick={triggerVisibility}
      >
        <span>{inputValue ? inputValue : placeholder}</span>
      </div>

      {isCalendarVisible && (
        <div className="relative">
          <div className="absolute z-[1] bg-white">
            <Calendar
              calendarType="gregory"
              view="month"
              value={value ? new Date(value) : new Date()}
              onChange={onChange}
              goToRangeStartOnSelect={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
