import { FC } from "react";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const ACTIVITY_TYPE_CONFIG = {
  icon: CheckCircleIcon,
  title: "Marked to-do complete",
};

const HistoryCardMarkTodoCompletedV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />

      <HistoryCardContactInfo contact={activity.contact} />

      <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardCallNotes notes={activity.notes} />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardMarkTodoCompletedV2;
