import { useMemo } from "react";

import { isUserApprovedToCall } from "@/helpers/campaign";
import { useGlobalContext } from "@/hooks/use-global-context";
import { clsxMerge } from "shared/lib/helpers";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

import {
  START_CALLING_MODAL_ID,
  StartCallingModal,
} from "@/components/modals/start-calling-modal";

export const PrimaryNavCampaignsSelector = ({
  className,
}: {
  className?: string;
}) => {
  const globalContext = useGlobalContext();
  const { campaigns } = globalContext;

  const isApprovedToCall = useMemo(
    () => campaigns?.some((c) => isUserApprovedToCall(c.campaign_user_status)),
    [campaigns]
  );

  return (
    <div className={clsxMerge("", className)}>
      <StartCallingModal />
      <div className="dropdown">
        {isApprovedToCall ? (
          <label
            htmlFor={START_CALLING_MODAL_ID}
            className={clsxMerge(
              "btn-ae-default min-w-[138px] bg-white",
              "text-[14px] font-bold text-black",
              "hover:bg-[#ECECEC]",
              {
                "disabled !text-black": !campaigns?.some((c) =>
                  isUserApprovedToCall(c.campaign_user_status)
                ),
              }
            )}
          >
            Start Calling
          </label>
        ) : (
          <Tooltip>
            <TooltipContent className="mt-2 w-[280px]">
              You'll need to complete one of the training modules for a company
              before you can start dialing! Check out the "Discover Campaigns"
              tab under "Campaigns" to select a campaign.
            </TooltipContent>
            <TooltipTrigger>
              <label
                className={clsxMerge(
                  "btn-ae-default min-w-[138px]",
                  "text-[14px] font-bold text-black",
                  {
                    "disabled !bg-white !text-black opacity-30":
                      !campaigns?.some((c) =>
                        isUserApprovedToCall(c.campaign_user_status)
                      ),
                  }
                )}
                tabIndex={1}
              >
                Start Calling
              </label>
            </TooltipTrigger>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
