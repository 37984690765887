import { FC } from "react";

import { ConfirmActionModal } from "shared/ui/modal/confirm-action";

interface AccountEditNextTouchTimeConfirmationModalPropsI {
  formattedNextTouchTime?: string;
  onConfirm: () => void;
}

export const ACCOUNT_CONFIRM_EDIT_NEXT_TOUCH_TIME_MODAL_ID =
  "account-edit-next-touch-time-confirmation-modal";

export const AccountConfirmEditNextTouchTimeConfirmationModal: FC<
  AccountEditNextTouchTimeConfirmationModalPropsI
> = ({ onConfirm, formattedNextTouchTime }) => (
  <ConfirmActionModal
    id={ACCOUNT_CONFIRM_EDIT_NEXT_TOUCH_TIME_MODAL_ID}
    title="Update next touch time"
    description={`Are you sure you want to set next touch time to ${formattedNextTouchTime}?`}
    confirmButtonText="Yes, update"
    onConfirm={onConfirm}
  />
);
