import { FC } from "react";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import CalendarForm from "shared/ui/calendar-form";

interface DateTimeCalendarModalPropsI {
  title?: string;
  descritpion?: string;
  modalId?: string;
  dateTime?: string;
  onSuccess?: (date: string, time: string, timezone: string) => void;
  onClose?: () => void;
}

interface DateTimeCalendarModalContentPropsI {
  dateTime?: string;
  onSubmit?: (date: string, time: string, timezone: string) => void;
  onClose?: () => void;
}

export const DATE_TIME_CALENDAR_MODAL_ID = "date-time-calendar-modal";

const ModalContent: FC<
  ModalChildrenFnPropsI & DateTimeCalendarModalContentPropsI
> = ({ dateTime, onSubmit, onClose }) => {
  return (
    <CalendarForm
      bookingDeadlineInMonths={6}
      isNotes={false}
      isNotesRequired={false}
      initialDate={dateTime}
      initialTime={dateTime}
      cancelButton={{
        type: "button",
        text: "Cancel",
        onClick: onClose,
      }}
      successButton={{
        type: "button",
        text: "Save",
      }}
      onFormSubmit={onSubmit}
      isLoading={false}
    />
  );
};

export const DateTimeCalendarModal: FC<DateTimeCalendarModalPropsI> = ({
  modalId = DATE_TIME_CALENDAR_MODAL_ID,
  dateTime = "",
  title,
  descritpion,
  onSuccess,
  onClose,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  const handleOnSubmit = (date: string, time: string, timezone: string) => {
    onSuccess?.(date, time, timezone);
    handleClose();
  };

  return (
    <Modal
      id={modalId}
      onClose={handleClose}
      title={title}
      description={descritpion}
    >
      {(props) => (
        <ModalContent
          {...props}
          dateTime={dateTime}
          onSubmit={handleOnSubmit}
          onClose={props.handleClose}
        />
      )}
    </Modal>
  );
};
