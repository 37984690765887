import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { clsxMerge } from "shared/lib/helpers";
import { MouseEventHandler } from "react";

export const NOTIFICATION_ACTION_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
} as const;

export const createNotificationAction = (
  type?: ValueOfObjectFields<typeof NOTIFICATION_ACTION_TYPES>,
  text?: string,
  onClick?: () => void
) => ({ type, text, onClick });

export interface NotificationActionItemI {
  type?: ValueOfObjectFields<typeof NOTIFICATION_ACTION_TYPES>;
  text?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const NotificationActions = ({
  actions,
}: {
  actions?: Array<NotificationActionItemI>;
}) => {
  return (
    <div className="flex gap-x-3">
      {actions?.map((action, i) => {
        const { type = NOTIFICATION_ACTION_TYPES.PRIMARY } = action || {};

        return (
          <button
            key={i}
            className={clsxMerge("btn-ae-s", {
              "btn-ae-default": type === NOTIFICATION_ACTION_TYPES.PRIMARY,
              "btn-nofill": type === NOTIFICATION_ACTION_TYPES.SECONDARY,
            })}
            onClick={action.onClick}
          >
            {action?.text}
          </button>
        );
      })}
    </div>
  );
};
