import { FC } from "react";
import { BarsArrowUpIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";

const ACTIVITY_TYPE_CONFIG = {
  icon: BarsArrowUpIcon,
  title: "Unarchive",
};

const HistoryCardUnarchiveV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardUnarchiveV2;
