import Router from "@/helpers/router";

export const APP_LAYOUT_SUPPORTED_ROUTES = [
  Router.dashboard(),
  Router.coaching(),
  Router.accounts(),
  Router.lists(),
  Router.callHistory(),
  Router.marketplace(),
  decodeURI(Router.marketplaceCampaign("[campaign_id]")),
  Router.performanceDashboard(),
  Router.messages(),
];

export const RESPONSIVE_PAGE_ROUTES = [
  // Router.accountDetails("[campaign_id]", "[account_id]"),
];
