import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

import { APII, glencocoClientAPI } from "@/api/glencoco";

type ApiClientContextI = APII;

const ApiClientContext = createContext<ApiClientContextI>({} as APII);

export const useApiClient = () => {
  const context = useContext(ApiClientContext);

  if (!context) {
    throw new Error("useApiClient must be used within ApiClientProvider");
  }

  return context;
};

// There is no reason to create instances of glencocoClientAPI every time we need to
// use it. This context aims to resolve this issue by creating a single instance
// and sharing as a stable state variable across the app.
export const ApiClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const [apiClient] = useState(glencocoClientAPI());

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
};
