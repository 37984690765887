import { clsxMerge } from "shared/lib/helpers";

export const DialingLabel = ({ className }: { className?: string }) => {
  // const [isCountdown, setIsCountdown] = useState(true);

  // const handleOnIsCountdownChange = (isCountdown?: boolean) => {
  //   setIsCountdown(!!isCountdown);
  // };

  return (
    <div
      className={clsxMerge(
        "w-max rounded-xl bg-[#F3AD25] px-3 py-1",
        "brand-typography-h7 animate-fadein text-[#695200]",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <span>Dialing</span>
      </div>

      {/* {isCountdown && (
        <div
          className={clsxMerge(
            "flex flex-col items-center justify-center text-white/60 typography-body-5",
            "animate-fadein"
          )}
        >
          <span className="mr-1">You can hangup in</span>
          <EndCallCountdown
            className={`[&::before]:[content:":"]`}
            onChange={handleOnIsCountdownChange}
          />
        </div>
      )} */}
    </div>
  );
};
