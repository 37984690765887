import { FC, RefObject, useRef } from "react";
import { ActivityI } from "@/interfaces/dialer/activity";
import Activity from "./activity";
import { useEffectOnce } from "shared/lib/hooks";

interface LocalActivityI {
  activityGroups: ActivityI[];
  setLocalActivityScrollRef: (el: RefObject<HTMLDivElement>) => void;
}

const LocalActivity: FC<LocalActivityI> = ({
  activityGroups,
  setLocalActivityScrollRef,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }, 100);
  };

  useEffectOnce(() => {
    setLocalActivityScrollRef(scrollContainerRef);
    scrollToBottom();
  });

  if (activityGroups.length < 1) {
    return <div className="h-full w-full overflow-y-auto px-4"></div>;
  }

  return (
    <div
      ref={scrollContainerRef}
      className="absolute h-full w-full overflow-y-auto px-4"
    >
      <div className="flex min-h-full flex-col justify-end">
        <div className="flex h-fit w-full flex-col rounded-lg bg-white/80">
          {activityGroups.map((activity, i) => (
            <div key={i} className="p-4">
              <Activity activity={activity} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocalActivity;
