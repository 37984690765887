import { clsxMerge } from "shared/lib/helpers";

import { SendEmailButton } from "./buttons/send-email-button";
import { AddToListButton } from "./buttons/add-to-list-button";
import { SetCallbackButton } from "./buttons/set-callback-button";
import { BookMeetingButton } from "./buttons/book-meeting-buttons";

export const DialerWidgetSecondaryActions = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <div className="w-full px-6">
      <hr className="divider-horizontal mx-0 my-3 w-full border-t-white/20 p-0" />

      <div
        className={clsxMerge(
          "flex flex-nowrap items-center justify-between gap-x-3",
          className
        )}
      >
        <SendEmailButton />
        <AddToListButton />
        <SetCallbackButton />
      </div>

      <hr className="divider-horizontal mx-0 my-3 w-full border-t-white/20 p-0" />

      <div>
        <BookMeetingButton className="w-full" />
      </div>
    </div>
  );
};
