import { DIALER_STATES } from "@/constants/dialer";
import Calling, { askPermissionMic } from "@/helpers/calling";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { DialerConfigI } from "@/interfaces/dialer/config";
import { config } from "process";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useDialerGlobalContext } from "./use-dialer-global-context";

export const useDialerDirectCall = () => {
  const context = useCallingContext();
  const inCallContext = useInCallContext();
  const dialerGlobalContext = useDialerGlobalContext();

  const startDirectCall = useCallback(
    async (config?: DialerConfigI) => {
      /**
       * NOTE verify that microphone is set
       */
      const isMicAllowed = await askPermissionMic();
      if (!isMicAllowed) {
        toast.error(
          "Permission to use audio devices is required to make calls."
        );

        context.setCallingState(DIALER_STATES.PRE);
        return;
      }

      // /**
      //  * NOTE setup websocket connection
      //  * TODO verify use of context campaign id
      //  */
      // getWebSocketConnection(context);

      let params: { itemId?: string; contactId?: string } = {};

      if (config?.activity?.event?.id)
        params = { itemId: config?.activity?.event?.id as string };

      if (config?.contact?.id)
        params = { contactId: config?.contact?.id as string };

      if (config?.activity?.event?.id || config?.contact?.id) {
        await Calling.startCall({
          context,
          inCallContext,
          dialerGlobalContext,
          ...params,
        });
      }
    },
    [config]
  );

  const memo = useMemo(() => ({ startDirectCall }), [startDirectCall]);

  return memo;
};
