import QualificationModal from "./qualification-modal";
import CalendlyModal, { CALENDLY_MODAL_ID } from "./calendly-modal";
import SubmitBookedMeetingModal, {
  SUBMIT_BOOKED_MEETING_MODAL_ID,
} from "./submit-booked-meeting-modal";

import {
  CampaignI,
  QualificationCriteriaI,
  SelectableQualificationCriteriaI,
} from "@/interfaces/campaign";
import { useState } from "react";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";

import { Portal } from "react-portal";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { useEffectOnce } from "shared/lib/hooks";
import { checkIfClient } from "shared/lib/helpers";

export const BOOK_MEETING_MODAL_ID = "book_meeting_modal";

export const triggerBookedMeetingModal = (modalId?: string) => {
  if (checkIfClient()) {
    const id = modalId || BOOK_MEETING_MODAL_ID;

    (window?.document?.querySelector(`#${id}`) as HTMLElement)?.click();
  }
};

export const BookMeetingModal = ({
  campaign,
  account,
  contact,
  calendlyUri,
  callId,
  modalId,
  notes,

  isAutoTriggerSubmitBookedMeetingModal = true,

  onQualificationCriteraSubmitSuccess = () => {},
  onCalendlySubmitSuccess = () => {},
  onFinalSubmitSuccess = () => {},
}: {
  campaign: CampaignI;
  account: CallAccountI;
  contact: CallContactI;
  calendlyUri: string;
  callId: string;

  notes?: string;
  modalId?: string;

  isAutoTriggerSubmitBookedMeetingModal?: boolean;

  onQualificationCriteraSubmitSuccess?: (
    qc: Array<SelectableQualificationCriteriaI>
  ) => void;
  onCalendlySubmitSuccess?: (calendlyEvent: CalendlyEventI) => void;
  onFinalSubmitSuccess?: () => void;
}) => {
  const [isAvailable, setIsAvailable] = useState(false);

  const [calendlyEvent, setCalendlyEvent] = useState<
    CalendlyEventI | undefined
  >();

  const [qualificationCriterias, setQualificationCriterias] = useState<
    Array<SelectableQualificationCriteriaI>
  >([]);

  useEffectOnce(() => {
    setIsAvailable(true);
  });

  return isAvailable ? (
    <Portal>
      <QualificationModal
        modalId={modalId || BOOK_MEETING_MODAL_ID}
        campaign={campaign}
        account={account}
        onSuccess={(qc) => {
          setQualificationCriterias(
            qc as Array<SelectableQualificationCriteriaI>
          );

          onQualificationCriteraSubmitSuccess(
            qc as Array<SelectableQualificationCriteriaI>
          );

          (
            window?.document?.querySelector(
              `#${CALENDLY_MODAL_ID}`
            ) as HTMLElement
          )?.click();
        }}
      />

      <CalendlyModal
        calendlyUri={calendlyUri}
        campaign={campaign}
        account={account}
        contact={contact}
        callId={callId}
        onSuccess={(calendlyEvent) => {
          setCalendlyEvent(calendlyEvent);

          if (isAutoTriggerSubmitBookedMeetingModal) {
            (
              window?.document?.querySelector(
                // `#${CALENDLY_MODAL_ID}`
                `#${SUBMIT_BOOKED_MEETING_MODAL_ID}`
              ) as HTMLElement
            )?.click();
          }

          onCalendlySubmitSuccess(calendlyEvent);
        }}
      />

      <SubmitBookedMeetingModal
        calendlyUri={calendlyUri}
        calendlyEvent={calendlyEvent as CalendlyEventI}
        campaign={campaign}
        callId={callId}
        account={account}
        contact={contact}
        notes={notes}
        qualificationCriterias={
          campaign?.qualification_criterias as Array<QualificationCriteriaI>
        }
        selectedQualificationCriteria={qualificationCriterias}
        onSuccess={onFinalSubmitSuccess}
      />
    </Portal>
  ) : null;
};
