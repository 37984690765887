import { glencocoClientAPI } from "@/api/glencoco";
import SBActiveState from "@/components/modules/calling/sidebar/active-state";
import { CampaignI } from "@/interfaces/campaign";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useEffect, useRef, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";

import { ResourcesToggleButton as ToggleButton } from "./toggle-button";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const SidebarExtentionResources = () => {
  const callStatus = useDialerCallStatus();
  const { campaign } = useAccountDetailsContext();

  const [isActive, setIsActive] = useState(false);
  const [isHideWidth, setIsHideWidth] = useState(false);

  useEffect(() => {
    setIsActive(callStatus === DIALER_CALL_STATUS.DURING);
  }, [callStatus]);

  const timeoutIdRef = useRef<number>();
  useEffect(() => {
    if (!isActive) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = window.setTimeout(() => {
        setIsHideWidth(true);
      }, 600);
    } else {
      setIsHideWidth(false);
    }
  }, [isActive]);

  const [campaignWithResouces, setCampaignWithResources] =
    useState<CampaignI>();

  const toggle = () => setIsActive((isActive) => !isActive);

  //TODO use dialer context campaign that has resources
  useEffect(() => {
    if (campaign?.id) {
      (async () => {
        const API = glencocoClientAPI();
        const GetCampaignResponse = await API.getCampaign(
          campaign?.id as string
        ).catch((e) => e);

        if (GetCampaignResponse.status === 200)
          setCampaignWithResources(GetCampaignResponse.data.campaign);
      })();
    }
  }, [campaign?.id]);

  return (
    <div
      className={clsxMerge(
        "relative mb-0 h-screen max-h-[100vh] overflow-hidden bg-transparent px-0",
        "flex",
        "transition-all duration-500",
        "w-full",
        {
          "max-w-[400px] translate-x-0": isActive,
          "max-w-[40px] translate-x-[calc(100%-40px)]": !isActive,
          "max-w-[40px] translate-x-0": !isActive && isHideWidth,
        }
      )}
      data-theme="dark"
    >
      <ToggleButton onClick={toggle} isActive={isActive} />
      <SBActiveState
        isVisible={isActive}
        activeIndex={0}
        hideToggle
        campaign={campaignWithResouces as CampaignI}
        className={clsxMerge(
          "w-[360px] bg-black/80 px-0 opacity-100 backdrop-blur-sm",
          {
            "bg-white": !isActive,
          }
        )}
        topGradientClassName={clsxMerge({ "from-transparent": !isActive })}
        bottomGradientClassName={clsxMerge({ "from-transparent": !isActive })}
        contentContainerClassName={clsxMerge(
          "px-2 lg:pl-6 lg:pr-4",
          "h-screen",
          "max-h-[calc(100vh-80px)] h-[calc(100vh-80px)]",
          "min-[1154px]:max-h-[calc(100vh-47px)] min-[1154px]:h-[calc(100vh-47px)]"
        )}
        tabsContainerClassName="pl-2 flex flex-wrap"
      />
    </div>
  );
};
