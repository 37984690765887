import toast, { Toast } from "react-hot-toast";

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

/**
 * USAGE
 *   alert({
        type: "success",
        title: "Your campaign has been successfully deleted",
        description:
          "If you would like to reinstate your campaign, please reach out to Glencoco",
      });
 */

export interface AlertDataI {
  type: "success" | "warning" | "error" | "info";
  title: string;
  description?: string;
  duration?: number;
  className?: string;
}

interface AlertContentI extends AlertDataI {
  toastData?: Toast;
}

export const AlertSuccess = ({
  title,
  description,
  className,
}: AlertContentI) => {
  return (
    <div
      className={twMerge(
        clsx("alert alert-success text-neutral mb-2 shadow-lg", className)
      )}
    >
      <div>
        <CheckCircleIcon className="text-success-content h-6 w-11" />

        <div>
          <h3 className="font-bold">{title}</h3>
          {description && (
            <div
              className="text-xs"
              dangerouslySetInnerHTML={{ __html: description as string }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const AlertWarning = ({
  title,
  description,
  className,
}: AlertContentI) => {
  return (
    <div
      className={twMerge(
        clsx("alert alert-warning text-neutral mb-2 shadow-lg", className)
      )}
    >
      <div>
        <ExclamationTriangleIcon className="text-warning-content h-6 w-11" />

        <div>
          <h3 className="font-bold">{title}</h3>

          {description && (
            <div
              className="text-xs"
              dangerouslySetInnerHTML={{ __html: description as string }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const AlertError = ({
  title,
  description,
  className,
}: AlertContentI) => {
  return (
    <div
      className={twMerge(
        clsx("alert alert-error text-neutral mb-2 shadow-lg", className)
      )}
    >
      <div>
        <XCircleIcon className="text-error-content h-6 w-11" />

        <div>
          <h3 className="font-bold">{title}</h3>
          {description && (
            <div
              className="text-xs"
              dangerouslySetInnerHTML={{ __html: description as string }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const AlertInfo = ({ title, description, className }: AlertContentI) => {
  return (
    <div
      className={twMerge(
        clsx("alert alert-info text-neutral mb-2 shadow-lg", className)
      )}
    >
      <div>
        <CheckCircleIcon className="text-info-content h-6 w-6" />

        <div>
          <h3 className="font-bold">{title}</h3>
          {description && (
            <div
              className="text-xs"
              dangerouslySetInnerHTML={{ __html: description as string }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const Alert = ({ toastData, ...props }: AlertContentI) => (
  <div
    className={`${
      toastData?.visible ? "animate-enter" : "animate-leave"
    } pointer-events-auto`}
  >
    {props.type === "success" && (
      <AlertSuccess toastData={toastData} {...props} />
    )}

    {props.type === "warning" && (
      <AlertWarning toastData={toastData} {...props} />
    )}

    {props.type === "error" && <AlertError toastData={toastData} {...props} />}

    {props.type === "info" && <AlertInfo toastData={toastData} {...props} />}
  </div>
);

const alert = (props: AlertDataI) => {
  toast.custom((t) => <Alert toastData={t} {...props} />, {
    duration: props.duration || 4000,
  });
};

export default alert;
