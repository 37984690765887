import { FC, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import UICheckbox from "shared/ui/ae-user-input/checkbox";
import { combineActivityNotes } from "@/helpers/activity-log";
import { glencocoClientAPI } from "@/api/glencoco";
import { toast } from "react-hot-toast";
import Calling from "@/helpers/calling";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { DATE_FORMAT } from "shared/lib/constants/time";
import { safeGetDate } from "shared/lib/helpers";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { QualificationCriteriaI } from "@/interfaces/campaign";
import { useBeforeTabClose } from "@/hooks/use-before-tab-close";
import { useEffectOnce } from "shared/lib/hooks";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const Checkbox = UICheckbox();

interface MeetingBookedCardI {
  className?: string;
  isWrapperNoStyles?: boolean;
}

interface MeetingBookedCardContentI {
  isVisible: boolean;
  contact: CallContactI;
  account: CallAccountI;
  calendlyEvent: CalendlyEventI;
  qualificationCriterias: Array<QualificationCriteriaI>;
  selectedQualificationCriteria: Array<SelectedQualificationCriteriaQuestionI>;
  notes: string;
  loading?: boolean;
  onNotesUpdate: (notes: string) => void;
  onSubmit: () => void;
}

export const MeetingBookedCardContent = ({
  className,
  isWrapperNoStyles,
  isVisible,
  contact,
  account,
  calendlyEvent,
  qualificationCriterias,
  selectedQualificationCriteria,
  notes,
  loading,
  onNotesUpdate,
  onSubmit,
}: MeetingBookedCardI & MeetingBookedCardContentI) => {
  useBeforeTabClose(
    "You are required to submit AE notes after the call ends. Are you sure you want to close the window and lose the progress with the prospect?"
  );

  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitClickedOnce, setIsSubmitClickedOnce] = useState(false);

  useEffect(() => {
    if (isSubmitClickedOnce) {
      const condition = notes?.length < 1;

      setIsError(condition);
      setIsDisabled(condition);
    }
  }, [notes, isSubmitClickedOnce]);

  const handleSubmit = () => {
    setIsSubmitClickedOnce(true);

    if (notes?.length < 1) {
      setIsError(true);
    } else {
      onSubmit();
    }
  };

  return (
    <div
      className={twMerge(
        isWrapperNoStyles
          ? ""
          : clsx(
              "w-[729px] rounded-[10px] bg-white px-8 py-6",
              className,
              "transition-[opacity,transform] duration-500",
              {
                "scale-50 opacity-0": !isVisible,
                "scale-100 opacity-100": isVisible,
              }
            )
      )}
    >
      <div>
        <section>
          <h2 className="ae-typography-h4 mb-2">
            Meeting notes for the Account Executive
          </h2>
          <p className="ae-typography-body2 mb-6 text-black/60">
            Please leave notes for the Account Executive to review before the
            meeting.
          </p>
        </section>

        <section className="mb-6 flex gap-8">
          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1">Lead Name</div>
            <div className="ae-typography-detail1">Lead Title</div>
            <div className="ae-typography-detail1">Company Name</div>
            <div className="ae-typography-detail1">Meeting Time</div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1 text-black/60">
              {contact?.first_name || "-"} {contact?.last_name}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {contact?.title || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {account?.name || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {safeGetDate(calendlyEvent?.start_time, DATE_FORMAT)}
            </div>
          </div>
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">Disposition</span>
          </label>
          <div className="ae-typography-detail1 w-full rounded-lg border-[1px] border-black/20 bg-[#DFF4B4] p-3 px-4 text-black">
            Meeting booked
          </div>
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Qualification criteria you have checked off
            </span>
          </label>
          {qualificationCriterias?.map((qc, i) => (
            <Checkbox
              key={i}
              name="qc"
              label={qc.question || ""}
              disabled
              inputProps={{
                checked:
                  selectedQualificationCriteria.find((_qc) => qc.id === _qc.id)
                    ?.is_checked || false,
              }}
            />
          ))}
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Notes for Account Executive
            </span>
          </label>
          <textarea
            name="notes"
            value={notes}
            onChange={(ev) => onNotesUpdate(ev.target.value)}
            rows={5}
            className={clsx(
              "ae-typography-body1 textarea w-full resize-none rounded-lg bg-black/5 p-4 focus:outline-none",
              {
                "textarea-bordered border-error-content": isError,
              }
            )}
          ></textarea>
        </section>

        <section className="flex w-full justify-end">
          <button
            onClick={handleSubmit}
            className={clsx("btn-ae-default w-[140px]", {
              loading: loading,
            })}
            disabled={isDisabled}
          >
            Send
          </button>
        </section>
      </div>
    </div>
  );
};

const MeetingBookedCard: FC<MeetingBookedCardI> = ({
  className,
  isWrapperNoStyles,
}) => {
  const callingContext = useCallingContext();
  const { campaign, campaignId, call } = callingContext;
  const inCallContext = useInCallContext();
  const {
    account,
    contact,
    calendlyEvent,
    qualificationCriteria,
    setIsMeetingCanceled,
  } = inCallContext;

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");

  const { qualification_criterias } = campaign || {};

  const handleSubmit = async () => {
    setLoading(true);

    if (!campaignId || !calendlyEvent?.id) {
      toast.error("Invalid Id.");
      setLoading(false);
      return;
    }

    const API = glencocoClientAPI();

    const resp = await API.updateBookedMeeting(
      campaignId,
      call?.customParameters.get("call_id") as string,
      calendlyEvent?.id,
      {
        qualification_criteria: qualificationCriteria,
        notes: notes,
      }
    ).catch(() => ({ status: null, data: null }));

    if (resp.status === 200) {
      setIsMeetingCanceled(false);
      toast.success("Meeting booked!");
      Calling.exit({
        context: callingContext,
        inCallContext,
      });
    } else {
      toast.error("Failed to book meeting");
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffectOnce(() => {
    setNotes(combineActivityNotes(inCallContext).join("\r\n"));

    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <MeetingBookedCardContent
      className={className}
      isWrapperNoStyles={isWrapperNoStyles}
      isVisible={isVisible}
      contact={contact}
      account={account}
      calendlyEvent={calendlyEvent as CalendlyEventI}
      qualificationCriterias={
        qualification_criterias as Array<QualificationCriteriaI>
      }
      selectedQualificationCriteria={qualificationCriteria}
      notes={notes}
      loading={loading}
      onNotesUpdate={setNotes}
      onSubmit={handleSubmit}
    />
  );
};

export default MeetingBookedCard;
