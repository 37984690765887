import { FC, useMemo } from "react";

import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import {
  CALLING_MODULE_LIVE_TRANSFER_FINAL_ID,
  CALLING_MODULE_LIVE_TRANSFER_MODAL_ID,
} from "@/modules/calling/modals/live-transfer-modal";
import { clsxMerge } from "shared/lib/helpers";

import { CalendarIcon } from "shared/ui/icons";
import { DIALER_LIVE_TRANSFER_STATUSES } from "@/constants/dialer";

interface LiveTransferButtonPropsI {
  className?: string;
}

export const LiveTransferButton: FC<LiveTransferButtonPropsI> = ({
  className,
}) => {
  const { calendly_uri, liveTransferStatus, endAt } = useInCallContext();
  const { CALENDLY_BOOKING_COMPLETE, LIVE_TRANSFER_COMPLETE } =
    DIALER_LIVE_TRANSFER_STATUSES;

  const modalId = useMemo(() => {
    switch (liveTransferStatus) {
      case CALENDLY_BOOKING_COMPLETE:
        return CALLING_MODULE_LIVE_TRANSFER_FINAL_ID;
      default:
        return CALLING_MODULE_LIVE_TRANSFER_MODAL_ID;
    }
  }, [liveTransferStatus]);

  return (
    <>
      <label
        htmlFor={calendly_uri ? modalId : ""}
        className={clsxMerge("btn-ae-default book-meeting-btn btn", className, {
          disabled:
            endAt ||
            !calendly_uri ||
            LIVE_TRANSFER_COMPLETE === liveTransferStatus,
        })}
      >
        <CalendarIcon className="mr-3 h-4" /> Live Transfer
      </label>
    </>
  );
};
