import { FC, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/primary-navigation/v3/interfaces";
import { clsxMerge } from "shared/lib/helpers";
import Router from "@/helpers/router";
import { ChevronDownIcon } from "shared/ui/icons";
import { NotificationLabel } from "@/components/primary-navigation/shared/notification-label";
import { NavButtonWrapper } from "@/components/primary-navigation/v3/nav-bar/nav-items-row/components/nav-button-wrapper";

interface NavButtonPrimaryI {
  navItem: PrimaryNavigationItemCollapsableWithIconI;
  disabled?: boolean;
  isBtnIconOnly?: boolean;
  containerClassName?: string;
  btnClassName?: string;
  isActive?: boolean;
}

const NavButtonLink: FC<NavButtonPrimaryI> = ({
  navItem,
  isBtnIconOnly,
  isActive,
}) => (
  <Link
    href={navItem.href as string}
    className={clsxMerge(
      "flex h-full items-center justify-between gap-2 p-2",
      !isBtnIconOnly && (isActive ? "opacity-100" : "opacity-80")
    )}
  >
    <span
      className={clsxMerge("w-4 text-white group-hover:animate-wiggle", {
        "w-auto": isBtnIconOnly,
      })}
    >
      {navItem.icon}
    </span>

    {!isBtnIconOnly && <span>{navItem.text}</span>}
  </Link>
);

const NavButtonDropdownTrigger: FC<NavButtonPrimaryI> = ({
  navItem,
  isBtnIconOnly,
  isActive,
}) => (
  <div
    className={clsxMerge(
      isActive ? "opacity-100" : "opacity-80",
      "flex h-full items-center justify-between gap-2 p-3"
    )}
  >
    <span
      className={clsxMerge("w-4 text-white group-hover:animate-wiggle", {
        "w-auto": isBtnIconOnly,
      })}
    >
      {navItem.icon}
    </span>

    {!isBtnIconOnly && (
      <>
        <span>{navItem.text}</span>
        <ChevronDownIcon className="mt-0.5 w-3" />
      </>
    )}
  </div>
);

/**
 * The parent-level button in the navbar. Can behave like a link or a dropdown trigger based on if `navItem.children` exists.
 * @param navItem data to populate this nav item with
 * @returns a link button to go directly to a href (with no dropdown children), or a div if `navItem` has children and should therefore behave as a dropdown trigger.
 */
export const NavButtonPrimary: FC<NavButtonPrimaryI> = ({
  navItem,
  disabled,
  isBtnIconOnly,
  containerClassName,
  btnClassName,
}) => {
  const router = useRouter();

  const isDropdown =
    navItem.disabled || navItem?.children?.length || !navItem?.href;

  // TODO: Test for long routes with query params in the url
  const isActive = useMemo(
    () =>
      [
        navItem.href === Router.dashboard() && router.route === "/",
        navItem.href === router.route,
        navItem.children?.some((child) =>
          router.route.includes(child.href as string)
        ),
      ].some(Boolean),
    [router.route, navItem]
  );

  return (
    <NavButtonWrapper
      containerClassName={containerClassName}
      btnClassName={btnClassName}
      isActive={isActive}
    >
      {isDropdown ? (
        <NavButtonDropdownTrigger
          isActive={isActive}
          navItem={navItem}
          disabled={disabled}
          isBtnIconOnly={isBtnIconOnly}
        />
      ) : (
        <NavButtonLink
          isActive={isActive}
          navItem={navItem}
          disabled={disabled}
          isBtnIconOnly={isBtnIconOnly}
        />
      )}

      <NotificationLabel
        id={navItem.id}
        className="absolute bottom-[11px] right-0 border-white"
        isPulsating
      />
    </NavButtonWrapper>
  );
};
