import { Field } from "formik";
import { useMemo } from "react";
import {
  NEXT_TOUCH_TIME_OPTIONS,
  NEXT_TOUCH_TIME_OPTIONS_MAP,
} from "shared/lib/constants/dispositions";
import { DATE_FORMAT } from "shared/lib/constants/time";
import { DayJs } from "shared/lib/helpers/date";

import { Dropdown } from "shared/ui";
import { DropdownOptionI } from "shared/ui/user-input/dropdown";

const EnhancedDropdown = Dropdown(Field);

/**
 * Custom Date Selection is handled in disposition-section file
 * in DropdownsValueSetter component
 */
export const NextTouchTimeDropdown = ({
  name,
  errors,
  touched,
  customNextTouchTime,
}: {
  name: string;
  errors?: string;
  touched?: boolean;
  customNextTouchTime?: string;
}) => {
  const nextTouchTimeDropdownOptions: DropdownOptionI[] = useMemo(() => {
    return Object.values(NEXT_TOUCH_TIME_OPTIONS).map((nttKey) => ({
      label:
        nttKey === NEXT_TOUCH_TIME_OPTIONS.CUSTOM && customNextTouchTime
          ? `${DayJs(customNextTouchTime).format(DATE_FORMAT)}`
          : NEXT_TOUCH_TIME_OPTIONS_MAP[nttKey],
      value: nttKey,
    }));
  }, [customNextTouchTime]);

  return (
    <EnhancedDropdown
      name={name}
      label="Set next touch time"
      placeholder="Select"
      options={nextTouchTimeDropdownOptions}
      errors={errors}
      touched={touched}
      className="relative mb-0 w-full  max-w-[240px] animate-fadein"
      labelContentClassName="brand-typography-body2"
      inputClassName="text-black"
      errorsClassName="absolute -bottom-[22px] whitespace-nowrap"
    />
  );
};
