import { clsxMerge } from "shared/lib/helpers";

import { useDialerWidgetContext } from "@/context/dialer-v2/widget";

import { DIALER_WIDGET_VARIATIONS } from "@/constants/dialer";
import { MinimizeIcon } from "shared/ui/icons";

export const DialerWidgetExpandButton = () => {
  const { setVariation } = useDialerWidgetContext();

  const toggle = () => {
    setVariation?.((variation) =>
      variation === DIALER_WIDGET_VARIATIONS.DEFAULT
        ? DIALER_WIDGET_VARIATIONS.SIDEBAR
        : DIALER_WIDGET_VARIATIONS.DEFAULT
    );
  };

  return (
    <button
      className={clsxMerge(
        "btn btn-circle btn-ghost btn-sm",
        "absolute right-8 top-1"
      )}
      onClick={toggle}
    >
      <MinimizeIcon className="w-4 text-white/60" />
    </button>
  );
};
