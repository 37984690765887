import { useCallingContext } from "@/hooks/dialer/use-dialer-context";

import { MuteIcon } from "shared/ui/icons";
import { MicrophoneIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { BasicButton } from "@/modules/calling/control-menu/v2/buttons/basic-button";

export const MuteButton = ({ className }: { className?: string }) => {
  const { isMuted, setMuted, call } = useCallingContext();

  const handleOnMute = () => {
    const newMuteValue = !isMuted;
    setMuted(newMuteValue);

    if (call) {
      call.mute(newMuteValue);
    }
  };
  return (
    <BasicButton onClick={handleOnMute} className={className}>
      <span className="mb-[6px]">
        {isMuted ? (
          <MuteIcon className={clsxMerge("h-4 w-4")} />
        ) : (
          <MicrophoneIcon className={clsxMerge("h-4 w-4")} />
        )}
      </span>

      <span className="brand-typography-detail3  text-black/90">
        {isMuted ? (
          <span className="animate-fadein">Unmute</span>
        ) : (
          <span className="animate-fadein">Mute</span>
        )}
      </span>
    </BasicButton>
  );
};
