import { useQuery } from "@tanstack/react-query";

import { useApiClient } from "@/context/api-client";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

export const getAccountDetailsQueryKey = (
  campaignId: string | undefined,
  accountId: string | undefined
) => ["account-details", campaignId, accountId];

export const ACCOUNT_DETAILS_QUERY_STALE_TIME = 10000;

export const useFetchAccountDetails = (
  campaignId: string | undefined,
  accountId: string | undefined,
  isEnabled = true
) => {
  const apiClient = useApiClient();

  return useQuery({
    enabled: !!campaignId && !!accountId && isEnabled,
    queryKey: getAccountDetailsQueryKey(campaignId, accountId),
    queryFn: () =>
      apiClient.getAccountDetails(campaignId as string, accountId as string),
    staleTime: ACCOUNT_DETAILS_QUERY_STALE_TIME,
  });
};

export type UseFetchAccountDetailsApiI = ReturnType<
  typeof useFetchAccountDetails
>;

export const useFetchContactLists = (
  campaignId: string | undefined,
  contactId: string | undefined
) => {
  const apiClient = useApiClient();

  const fetchContactListsApi = useQuery({
    enabled: !!campaignId && !!contactId,
    queryKey: ["contact-lists", campaignId, contactId],
    queryFn: () =>
      apiClient.getContactLists(campaignId as string, contactId as string),
  });

  useEffect(() => {
    if (fetchContactListsApi.isError) {
      toast.error("Failed to fetch selected contact lists.");
    }
  }, [fetchContactListsApi.isError]);

  return fetchContactListsApi;
};

export type UseFetchContactListsApiI = ReturnType<typeof useFetchContactLists>;
