import { clsxMerge } from "shared/lib/helpers";
import { FC } from "react";

interface ContainerPropsI {
  children: any;
  className?: string;
  id?: string;
}

export const Container: FC<ContainerPropsI> = ({ children, className, id }) => (
  <div
    id={id}
    className={clsxMerge("mx-auto min-w-[1085px] px-4 lg:container", className)}
  >
    {children}
  </div>
);
