import toast from "react-hot-toast";

import {
  ERRORS,
  GLOBALLY_HANDLED_ERRORS,
  SOCKET_ERROR_CODES_GROUP_DIALER_LIST,
  SOCKET_ERRORS,
} from "@/constants/errors";
import { checkIfClient } from "shared/lib/helpers";

export const handleErrorMessage = (
  errorCode: number,
  defaultErrorMessage = "Failed Request"
) => {
  if (ERRORS[errorCode]) {
    toast.error(ERRORS[errorCode]);
  } else {
    if (checkIfClient()) {
      toast?.error(defaultErrorMessage);
    }
  }

  return errorCode;
};

export const handleGlobalErrorMessage = (
  errorCode: number,
  defaultErrorMessage = "Failed Request"
) => {
  if (Object.hasOwn(GLOBALLY_HANDLED_ERRORS, errorCode)) {
    toast.error(
      GLOBALLY_HANDLED_ERRORS[errorCode as keyof typeof GLOBALLY_HANDLED_ERRORS]
    );
  } else {
    if (checkIfClient()) {
      toast?.error(defaultErrorMessage);
    }
  }

  return errorCode;
};

interface SocketErrorConfigI {
  isErrorLogOnly?: boolean;
}

export const handleSocketErrorMessage = (
  errorCode: number,
  defaultErrorMessage?: string,
  config?: SocketErrorConfigI
) => {
  const _defaultErrorMessage = defaultErrorMessage || "Error";

  if (!checkIfClient()) return errorCode;

  if (config?.isErrorLogOnly) {
    console.error(
      `ERROR DIALING LIST ${errorCode} ${
        SOCKET_ERRORS[errorCode] || _defaultErrorMessage
      }`
    );
    return errorCode;
  }

  toast.error(SOCKET_ERRORS[errorCode] || _defaultErrorMessage);

  return errorCode;
};

export const handleSockerErrorMessage_DialerListGroup = (
  errorCode: number
  // defaultErrorMessage = "Error"
) => {
  if (
    (Object.values(SOCKET_ERROR_CODES_GROUP_DIALER_LIST) as number[]).includes(
      errorCode
    )
  ) {
    /**
     * We want to suppress error messages for the user as it is not usefull
     * but we still want to track errors in logs
     */
    handleSocketErrorMessage(errorCode, undefined, { isErrorLogOnly: true });
  }

  return errorCode;
};
