import { useCallback, useEffect, useRef } from "react";

import { checkIfClient } from "shared/lib/helpers/utils";

interface InfiniteScrollPropsI {
  isLoading: boolean;
  fetchMore: () => void;
  hasMore: boolean;
  rootMargin?: string;
  threshold?: number;
  scrollIndicatorId?: string;
}

export const INFINITE_SCROLL_WITH_OBSERVER_INDICATOR =
  "infinite-scroll-indicator";

const getScrollIndicator = (
  indicatorId: string = INFINITE_SCROLL_WITH_OBSERVER_INDICATOR
) => (checkIfClient() ? document.getElementById(indicatorId) : null);

export const useInfiniteScrollWithObserver = ({
  isLoading,
  fetchMore,
  hasMore,
  rootMargin = "0px",
  threshold = 0.5,
  scrollIndicatorId = INFINITE_SCROLL_WITH_OBSERVER_INDICATOR,
}: InfiniteScrollPropsI) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];

      if (target.isIntersecting && !isLoading && hasMore) {
        fetchMore();
      }
    },
    [isLoading, hasMore, fetchMore]
  );

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, {
      rootMargin,
      threshold,
    });

    const scrollIndicator = getScrollIndicator(scrollIndicatorId);

    if (observer.current && scrollIndicator) {
      observer.current.observe(scrollIndicator);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [handleObserver, rootMargin, threshold]);

  useEffect(() => {
    const scrollIndicator = getScrollIndicator(scrollIndicatorId);

    if (!isLoading && observer.current && scrollIndicator) {
      observer.current.disconnect();
      observer.current.observe(scrollIndicator);
    }
  }, [isLoading]);
};
