export const modal = {
  trigger: (modalId: string) => {
    if (typeof window !== "undefined") {
      (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
    }
  },
};

const GLOBAL_OPEN_MODALS = "GLOBAL_OPEN_MODALS";
/**
 * NOTE - The issue is that we can have multiple Calendly modals on the page.
 *        The modal also has a webhook that listens to Calendly event.
 *        We don't know which modal requested Calendly booking
 *        BUT webhook will be triggered on all of them.
 *        We need to control which attached listener will be executed.
 *        We have to check which modal is actually open.
 * @param list
 */
export const setGlobalOpenModals = (modalIds: Array<string>) => {
  (window as any)[GLOBAL_OPEN_MODALS] = modalIds || [];
};

/**
 * NOTE - The issue is that we can have multiple Calendly modals on the page.
 *        The modal also has a webhook that listens to Calendly event.
 *        We don't know which modal requested Calendly booking
 *        BUT webhook will be triggered on all of them.
 *        We need to control which attached listener will be executed.
 *        We have to check which modal is actually open.
 * @returns arrayOpenModalIds
 */
export const getGlobalOpenModals = (): Array<string> => {
  return (window as any)[GLOBAL_OPEN_MODALS] || [];
};
