import { ReactElement } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { useTimeAgo } from "shared/lib/hooks";

import capitalize from "lodash/capitalize";

const dotBeforeClassName = clsxMerge(
  `before:content-["·"]`,
  "before:absolute",
  "before:top-[-1px]",
  "before:left-[-18px]",
  "before:text-[40px]",
  "before:text-black"
);

export interface NotificationDescriptionI {
  text?: string | ReactElement;
  date?: string;
  status?: string;
  campaignName?: string;
}

export const NotificationDescription = ({
  text,
  date,
  status,
  campaignName,
}: NotificationDescriptionI) => {
  const dateFormatted = useTimeAgo(date as string);

  return (
    <div
      className={clsxMerge(
        "flex flex-col justify-center",
        "brand-typography-body3 font-light"
      )}
    >
      <span className="mb-2">
        {text && <span className="[&_b]:font-semibold">{text}</span>}
      </span>
      <span className="text-black/60">
        {dateFormatted && <span>{capitalize(dateFormatted)}</span>}
        {status && (
          <span className={clsxMerge("relative ml-6", dotBeforeClassName)}>
            {status}
          </span>
        )}
        {campaignName && (
          <span className={clsxMerge("relative ml-6", dotBeforeClassName)}>
            {campaignName}
          </span>
        )}
      </span>
    </div>
  );
};
