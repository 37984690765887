import { useMemo } from "react";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import Calling from "@/helpers/calling";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

export const useLiveTransfer = () => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();

  const { campaign, accountStatus } = useAccountDetailsContext();
  const callStatus = useDialerCallStatus();

  const isLiveTransferMode = useMemo(
    () =>
      !!campaign?.has_live_transfer && callStatus === DIALER_CALL_STATUS.DURING,
    [campaign?.has_live_transfer, callStatus]
  );

  const isLiveTransferIsMeetingBooked = useMemo(() => {
    return campaign?.has_live_transfer && !!accountStatus?.meeting_time;
  }, [accountStatus?.meeting_time]);

  const handleLiveTransfer = async (onSuccess?: () => void) => {
    const LiveTransferResponse = await Calling.liveTransfer({
      context: callingContext,
      inCallContext,
    });

    // NOTE: Error Logic is already handled in liveTransfer function
    if (LiveTransferResponse?.status === 200) {
      onSuccess?.();
    }
  };

  return {
    isLiveTransferMode,
    isLiveTransferIsMeetingBooked,
    handleLiveTransfer,
  };
};
