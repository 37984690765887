import Modal from "shared/ui/modal";
import { getS3AssetPath } from "shared/lib/helpers";
import { Confetti } from "shared/ui/confetti";
import Router from "@/helpers/router";

export const CATCHUPS_COMPLETED_MODAL_ID = "catchups-completed-modal";

export const CatchupsCompletedModal = () => {
  const handleClose = () => {
    window.location.href = Router.dashboard();
  };

  return (
    <Modal
      id={CATCHUPS_COMPLETED_MODAL_ID}
      onClose={handleClose}
      modalBoxClassName="w-[514px] rounded-lg px-8"
      titleImage={
        <div className="flex justify-center">
          <img
            alt="Congratulations! You’ve completed your to-do list for the day"
            src={getS3AssetPath(
              "platform/calling/catchups-completed-icon.webp"
            )}
            className="w-[156px]"
          />
        </div>
      }
      titleClassName="brand-typography-h1"
      title="Congratulations! You’ve completed your to-do list for the day"
      description="Completing your to-do list increases your opportunities for success and landing qualified meetings."
      descriptionClassName="text-black mt-5"
    >
      {() => (
        <>
          <Confetti />

          <div className="flex w-full justify-end">
            <button onClick={handleClose} className="btn-ae-default w-[158px]">
              DONE
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};
