import axios from "axios";
import { toast } from "react-hot-toast";

type PreparedTranscriptI = [string, string][];

export const fetchTranscript = async (transcriptURL: string) => {
  try {
    const response = await axios(transcriptURL);

    if (response.status !== 200) {
      toast.error("Failed to fetch transcript");

      return [];
    }

    return (response.data as string).split("\n").map((line: string) => {
      if (line.includes("caller:")) {
        return ["Caller: ", line.replace("caller:", "")];
      } else if (line.includes("prospect:")) {
        return ["Prospect: ", line.replace("prospect:", "")];
      }

      return ["", line];
    }) as PreparedTranscriptI;
  } catch (e) {
    return [
      [
        "Error: ",
        "Call transcript is unavailable, please try reloading the page.",
      ],
    ] as PreparedTranscriptI;
  }
};
