import clsx from "clsx";
import { CampaignCardLabelBooleanI } from "shared/ui/cards/campaign-card/campaign-card-default";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

export const CampaignFeatureLabel = ({
  isEnabled,
  label,
  className,
}: {
  isEnabled?: boolean;
  label?: string;
  className?: string;
}) => (
  <span
    className={twMerge(
      clsx("b-typography-detail2 inline-flex items-center gap-0.5", className)
    )}
  >
    {isEnabled ? (
      <CheckIcon className="text-success-content -ml-1 h-4 w-4" />
    ) : (
      <XMarkIcon className="text-error-content -ml-1 h-4 w-4" />
    )}
    {label}
  </span>
);

export const CampaignCardFeatures = ({
  features,
  className,
}: {
  features?: Array<CampaignCardLabelBooleanI>;
  className?: string;
}) => (
  <div className={clsx("flex gap-6", className)}>
    {features?.map((f, i) => (
      <div key={i} className="flex items-center">
        <CampaignFeatureLabel label={f.label} isEnabled={f.value} />
      </div>
    ))}
  </div>
);
