import { MetaI } from "@/interfaces/meta";
import { basicSEO } from "lib/seo/basic";
import { openGraph } from "lib/seo/og";
import { twitter } from "lib/seo/twitter";
import { NextSeoProps } from "next-seo";
import { getFaviconLinks, getFaviconMetaTags } from "shared/lib/helpers/seo";

export const seo = (meta: MetaI): NextSeoProps => {
  const blockRobots = { nofollow: true, noindex: true };

  return {
    ...blockRobots,
    ...basicSEO(meta),
    openGraph: openGraph(meta),
    additionalMetaTags: [...twitter(meta), ...getFaviconMetaTags()],
    additionalLinkTags: [
      ...getFaviconLinks(),
      {
        rel: "manifest",
        href: "/manifest.json",
      },
    ],
  };
};
