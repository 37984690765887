import { useEffect, useRef } from "react";

// This hook is used to get ref with always up-to-date value from state
// It may be useful in some cases when value is changing, but you want to get it in some callback
// any is used because anything may be passed to this hook
export const useLatestValue = <T>(stateValue: T) => {
  const valueRef = useRef<T>(stateValue);

  useEffect(() => {
    valueRef.current = stateValue;
  }, [stateValue]);

  return valueRef;
};
