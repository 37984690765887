import { useState } from "react";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { BasicButton } from "@/modules/calling/control-menu/v2/buttons/basic-button";

import { PhoneEndCallIcon } from "shared/ui/icons";
import { clsxMerge } from "shared/lib/helpers";
import { EndCallCountdown } from "./end-call-countdown";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";
import { DIALER_STATES } from "@/constants/dialer";

export const EndCallButton = ({
  className,
  isDisabled,
}: {
  className?: string;
  isDisabled?: boolean;
}) => {
  const callContext = useCallingContext();
  const { call, device, setCallingState } = callContext || {};

  const [isCountdown, setIsCountdown] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const handleOnIsCountdownChange = (isCountdown?: boolean) => {
    setIsCountdown(!!isCountdown);
  };

  const handleOnEndCall = () => {
    setLoading(true);

    call?.disconnect();
    device?.disconnectAll();

    setCallingState(DIALER_STATES.DISPOSITION);

    dd.rum.log(
      `${LOG_CATEGORIES.TWILIO}[DISCONNECT] - manual disconnect EndCallButton`
    );

    setLoading(false);
  };

  return (
    <BasicButton
      className={clsxMerge(
        "btn-ae-error btn w-[85px] text-white",
        {
          "pointer-events-none opacity-50":
            isLoading || isCountdown || isDisabled,
        },
        className
      )}
      onClick={handleOnEndCall}
    >
      <span className="mb-[6px]">
        <PhoneEndCallIcon className="h-5 w-5" />
      </span>

      <span>
        <span className="brand-typography-detail3">End Call</span>
        <EndCallCountdown
          className="absolute right-4 top-4 animate-fadein"
          onChange={handleOnIsCountdownChange}
        />
      </span>
    </BasicButton>
  );
};
