import { DIALER_MODES, DIALER_STATES } from "@/constants/dialer";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import { ActivityI } from "@/interfaces/dialer/activity";
import { RefObject, useContext, useEffect, useState } from "react";
import { ToggleField } from "shared/ui/ae-user-input";
import AccountActivity from "./account-activity";
import LocalActivity from "./local-activity";
import GlobalContext from "@/context/global";
import clsx from "clsx";
import { addActivityLogNote, formatCallerName } from "@/helpers/activity-log";

const EnhancedToggleField = ToggleField();

const ActivityLogDisabled = () => {
  const callingContext = useCallingContext();

  return (
    <div
      className={clsx(
        "flex h-full max-h-[calc(100vh_-_80px)] w-[320px] flex-col items-center justify-center rounded-xl transition-colors",
        {
          "bg-white/80": DIALER_STATES.PRE === callingContext.callingState,
          "bg-white/40": DIALER_STATES.PRE !== callingContext.callingState,
        }
      )}
    >
      <h3
        className={clsx(
          "ae-typography-body2 w-[150px] text-center transition-colors",
          {
            "text-black/60": callingContext.callingState === DIALER_STATES.PRE,
            "text-white/60": callingContext.callingState !== DIALER_STATES.PRE,
          }
        )}
      >
        Lead activity will show here when a lead picks up
      </h3>
    </div>
  );
};

const ActivityLogActive = () => {
  const inCallContext = useInCallContext();
  const callingContext = useCallingContext();
  const { mode } = callingContext;
  const { contact, activity_log } = inCallContext;
  const [loading, setLoading] = useState(false);
  const [localActivityGroups, setLocalActivityGroups] = useState<ActivityI[]>(
    []
  );
  const [accountActivityGroups, setAccountActivityGroups] = useState<
    ActivityI[]
  >([]);
  const [isShowAccountActivity, setShowAccountActivity] = useState(true);
  const [notesValue, setNotesValue] = useState("");
  const { glencocoUser } = useContext(GlobalContext);
  const [localActivityScrollRef, setLocalActivityScrollRef] =
    useState<RefObject<HTMLDivElement>>();
  const [accountActivityScrollRef, setAccountActivityScrollRef] =
    useState<RefObject<HTMLDivElement>>();

  const scrollToBottom = (target: HTMLDivElement | undefined | null) => {
    setTimeout(() => {
      if (target) {
        target.scrollTop = target.scrollHeight;
      }
    }, 100);
  };

  const handleShowAccountActivityToggle = () => {
    setShowAccountActivity(!isShowAccountActivity);
  };

  const submitNotes = () => {
    if (notesValue.length < 1) {
      return;
    }

    const currentDate = new Date().toLocaleString("en");

    addActivityLogNote(inCallContext, {
      contact: {
        id: contact?.id,
        first_name: contact?.first_name,
        last_name: contact?.last_name,
        title: contact?.title,
        phone: contact?.phone,
        linkedin_url: contact?.linkedin,
      },
      timestamp: currentDate,
      notes: notesValue,
      caller_name: formatCallerName(
        glencocoUser?.first_name || "",
        glencocoUser?.last_name || ""
      ),
    });

    setNotesValue("");
  };

  const handleNotesKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitNotes();
    }
  };

  useEffect(() => {
    if (DIALER_STATES.DISPOSITION === callingContext.callingState) {
      // when the call ends, remaining text inside notes textarea should be included in activity log notes
      submitNotes();
    }
  }, [callingContext.callingState]);

  useEffect(() => {
    setAccountActivityGroups(activity_log);

    scrollToBottom(localActivityScrollRef?.current);
    scrollToBottom(accountActivityScrollRef?.current);
  }, [activity_log]);

  useEffect(() => {
    if (callingContext.callingState === DIALER_STATES.CALL) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [callingContext.callingState]);

  useEffect(() => {
    const filteredActivity = accountActivityGroups.filter(
      (activity) => activity?.contact?.id === contact?.id
    );

    setLocalActivityGroups(filteredActivity);
  }, [accountActivityGroups]);

  return (
    <div className="flex h-full max-h-[calc(100vh_-_80px)] w-[320px] flex-col overflow-hidden rounded-xl bg-white/80">
      <div className="flex h-[52px] items-center justify-between p-4 pb-0">
        <h1 className="ae-typography-h4">Activity</h1>

        {mode !== DIALER_MODES.CATCHUP && (
          <EnhancedToggleField
            className="ae-typograhy-body2"
            label="Show account activity"
            checked={isShowAccountActivity}
            onChange={handleShowAccountActivityToggle}
          />
        )}
      </div>

      <div className="divider mb-4 mt-2" />

      {loading ? (
        <div className="btn loading btn-ghost btn-lg h-full"> </div>
      ) : (
        <>
          <div
            className={clsx(
              "relative mb-4 flex h-full transition-transform duration-300 ease-out",
              {
                "-translate-x-full": isShowAccountActivity,
                "translate-x-0": !isShowAccountActivity,
              }
            )}
          >
            <LocalActivity
              activityGroups={localActivityGroups}
              setLocalActivityScrollRef={(el) => setLocalActivityScrollRef(el)}
            />

            <AccountActivity
              activityGroups={accountActivityGroups}
              activeContact={contact}
              setAccountActivityScrollRef={(el) =>
                setAccountActivityScrollRef(el)
              }
            />
          </div>

          {![DIALER_STATES.LEAD_SELECTION].includes(
            callingContext.callingState as string
          ) && (
            <div className="w-full px-4 pb-4">
              <textarea
                value={notesValue}
                className="textarea h-[168px] w-full resize-none rounded-lg bg-white/80"
                placeholder="Type here to take notes"
                onChange={(event) => setNotesValue(event.target.value)}
                onKeyDown={handleNotesKeyPress}
              ></textarea>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ActivityLog = () => {
  const callingContext = useCallingContext();

  return [
    DIALER_STATES.CALL,
    DIALER_STATES.DISPOSITION,
    DIALER_STATES.LEAD_SELECTION,
  ].includes(callingContext.callingState as string) ? (
    <ActivityLogActive />
  ) : (
    <ActivityLogDisabled />
  );
};

export default ActivityLog;
