import clsx from "clsx";
import DescItem from "shared/ui/desc-list/desc-item";
import CopyToClipboard from "../copy-to-clipboard";

export interface InfoRecordI {
  icon: JSX.Element;
  data?: string;
  config?: any;
}

const DescList = ({
  infoList,
  listItemClassNames,
}: {
  infoList: Array<InfoRecordI>;
  listItemClassNames?: string;
}) => {
  return (
    <ul>
      {infoList.map((info, i) => {
        const icon = info.icon;

        return info?.data ? (
          <CopyToClipboard key={i} textToCopy={info.data}>
            <DescItem
              {...info?.config}
              classNames={clsx("listItem", listItemClassNames)}
            >
              <div className=" flex h-4 w-4 items-center justify-center">
                {icon}
              </div>

              <div
                className={clsx({
                  "break-words": info.data?.includes?.(" "),
                  "break-all": !info.data?.includes?.(" "),
                })}
              >
                {info.data}
              </div>
            </DescItem>
          </CopyToClipboard>
        ) : null;
      })}
    </ul>
  );
};

export default DescList;
