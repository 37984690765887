import { DriveStep, Driver } from "driver.js";
import { triggerModal } from "@/helpers/utils";
import { CALLING_FOLLOWUP_MODAL } from "@/modules/calling/modals/follow-up-modal";
import { CALENDLY_MODAL_ID } from "@/components/modals/book-meeting-modal/calendly-modal";
import { DefaultCallingContextI } from "@/interfaces/contexts/calling";
import { DIALER_STATES } from "@/constants/dialer";
import { CALLING_DISPOSITION_MODAL_ID } from "@/modules/calling/modals/disposition-modal";
import { CALLING_MODULE_BOOK_MEETING_MODAL_ID } from "@/modules/calling/modals/booking-meeting-modal";

export const getTourSteps = (
  driver: Driver,
  callingContext: DefaultCallingContextI,
  onDone = () => {}
): DriveStep[] => [
  {
    element: ".caller.control-bar",
    popover: {
      title: "Control Bar",
      side: "top",
      align: "center",
      description: `
        <div style='margin-bottom: 8px'>
          Here you'll see the number you're calling from, and a few other controls you may use throughout the calling journey:
        </div> 
        <p style='font-weight: bold;'>Microphone</p> 
        <p style='margin-bottom: 8px'>Toggle your microphone's mute/unmute function to control the flow of audio into your input device.</p>
        <p style='font-weight: bold;'>Dialpad</p> 
        <p style='margin-bottom: 8px'>Use this to navigate through any automated phone systems and to dial extension numbers.</p>
        <p style='font-weight: bold;'>Settings</p> 
        <p style='margin-bottom: 8px'>Here you can configure the microphone and speaker devices. You can also toggle the Autodial feature, which automates dialing the next lead after your current call ends. Lastly, you can toggle the dialer's background music.</p>
      `,
    },
  },
  {
    element: ".caller.activity-log",
    popover: {
      title: "Activity Log",
      description: `
        <p style='margin-bottom: 8px'>This section displays an event log providing insights into previous activities associated with the lead or company you're engaging with. You can enter notes any time you want to leave valuable information for yourself or others to reference later.</p> 
        <p style='font-weight: bold;'>Account Activity [ON]</p> 
        <p style='margin-bottom: 8px'>View the complete log of activity across all contacts within the account.</p>
        <p style='font-weight: bold;'>Account Activity [OFF]</p> 
        <p style='margin-bottom: 8px'>Display only the activity relevant to the currently engaged lead.</p>
        `,
      side: "left",
      align: "center",
    },
  },
  {
    element: ".caller.sidebar",
    popover: {
      title: "Resources Panel",
      description: `
      <div style='margin-bottom: 8px'>
        These resources can help you get through a successful call:
      </div> 
      <p style='font-weight: bold;'>Scripts</p> 
      <p style='margin-bottom: 8px'>A script crafted by the Campaign creator. You can utilize this to guide your conversation during the call or take it as a foundation to develop your unique version!</p>
      <p style='font-weight: bold;'>Personas</p> 
      <p style='margin-bottom: 8px'>Description regarding the lead's characteristics, behaviors, needs, and goals, enabling you to provide more tailored support.</p>
      <p style='font-weight: bold;'>Objections</p> 
      <p style='margin-bottom: 8px'>These are frequently asked questions that might arise during your call. Refer here for quick access to FAQs you might not be familiar with. If you don't find what you're looking for here, feel free to ask in the campaign-specific sales floor for additional support.</p>
      <p style='font-weight: bold;'>Qualifications</p> 
      <p style='margin-bottom: 8px'>A list of prerequisites that a lead must meet to be deemed eligible. If you book a meeting with a lead that doesn't fulfill the qualification criteria, your meeting might not be eligible for a payout.</p>
    `,
      side: "left",
      align: "center",
    },
  },
  {
    element: ".caller.lead-card",
    popover: {
      title: "Lead Card",
      description:
        "Prospect details, and details about the company they are in, will be here.",
      side: "right",
      align: "center",
    },
  },
  {
    element: ".lead-card-metas-block",
    popover: {
      title: "Custom labels",
      description:
        "Look here to view custom labels added by the campaign creator. This additional information might provide valuable insights about the lead, enhancing your ability to conduct a successful call.",
      side: "right",
      align: "center",
    },
  },
  // {
  //   element: ".about-company",
  //   popover: {
  //     title: "About the Company",
  //     description:
  //       "Custom labels added by the campaign creator show here. This may offer extra useful information about the lead to help you make a successful call!",
  //     side: "right",
  //     align: "center",
  //   },
  // },
  // {
  //   element: ".contact-info",
  //   popover: {
  //     title: "About the Lead",
  //     description:
  //       "Custom labels added by the campaign creator show here. This may offer extra useful information about the lead to help you make a successful call!",
  //     side: "right",
  //     align: "center",
  //   },
  // },
  {
    element: ".save-lead-btn",
    popover: {
      title: "Save Lead/Set Callback",
      description:
        "Save a lead so you have their details and can re-dial them later at a time you specify. This will pull the lead out of the general pool of leads that anyone can call into so that you can work them from your home dashboard. You can set a callback date and no one else will be able to call into the account or lead until two days after the callback date expires. We'll be adding functionality to snooze and edit callback dates in the future.",
      side: "right",
      align: "center",
      onNextClick: () => {
        triggerModal(CALLING_FOLLOWUP_MODAL);
        driver.moveNext();
      },
    },
  },
  {
    element: ".set-callback-modal",
    popover: {
      title: "Save Lead/Set Callback",
      description:
        "To set a time to call the contact back, simply select a date and time from the calendar.",
      side: "right",
      align: "center",
      onNextClick: () => {
        triggerModal(CALLING_FOLLOWUP_MODAL);
        driver.moveNext();
      },
      onPrevClick: () => {
        triggerModal(CALLING_FOLLOWUP_MODAL);
        driver.movePrevious();
      },
    },
  },
  {
    element: ".book-meeting-btn",
    popover: {
      title: "Book Meeting",
      description:
        "Schedule a time for the lead and the account executive to meet. Both parties will be notified.",
      side: "right",
      align: "center",
      onNextClick: () => {
        triggerModal(CALLING_MODULE_BOOK_MEETING_MODAL_ID);
        driver.moveNext();
      },
      onPrevClick: () => {
        triggerModal(CALLING_FOLLOWUP_MODAL);
        driver.movePrevious();
      },
    },
  },
  {
    element: ".qualification-criteria-modal .modal-box",
    popover: {
      title: "Qualification Criteria",
      description:
        "You should run through the qualification criteria with the prospect if they are interested in taking a meeting. Remember: if the prospect fails to meet the qualification criteria, the meeting you book may not be eligible for a payout.",
      side: "right",
      align: "center",
      onNextClick: () => {
        (async () => {
          const checkboxes = document.querySelectorAll(
            ".qualification-criteria-modal input"
          );

          checkboxes.forEach((checkbox) =>
            (checkbox as HTMLInputElement)?.click()
          );

          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(true);
            }, 300);
          });

          const modalNextBtn = document.querySelector(
            ".qualification-criteria-modal .btn-ae-default"
          ) as HTMLButtonElement;

          modalNextBtn?.click();
          driver.moveNext();
        })();
      },
      onPrevClick: () => {
        triggerModal(CALLING_MODULE_BOOK_MEETING_MODAL_ID);
        driver.movePrevious();
      },
    },
  },
  {
    element: "iframe",
    popover: {
      title: "Schedule a meeting time",
      description:
        "Select the time that the prospect is able to meet. A meeting invite will automatically be sent to necessary participants. It's recommended (if you're able to) to confirm that they've received the invite during the call. REMEMBER TO BE CAREFUL ABOUT TIMEZONES.",
      side: "right",
      align: "center",
      onNextClick: () => {
        triggerModal(CALENDLY_MODAL_ID);
        driver.moveNext();
      },
      onPrevClick: () => {
        triggerModal(CALENDLY_MODAL_ID);
        triggerModal(CALLING_MODULE_BOOK_MEETING_MODAL_ID);
        driver.movePrevious();
      },
    },
  },
  {
    element: ".add-next-lead-btn",
    popover: {
      title: "Create Newly Discovered Lead",
      description:
        "During the call, if the prospect provides you with another person's details, you can enter them here, and they will be saved as the next contact to be dialed after the current call ends.",
      side: "right",
      align: "center",
      onPrevClick: () => {
        triggerModal(CALENDLY_MODAL_ID);
        driver.movePrevious();
      },
    },
  },
  {
    element: ".end-call-btn",
    popover: {
      title: "End Call",
      description:
        "When you've completed your conversation with the lead, click here to end the call.",
      side: "top",
      align: "center",
      onNextClick: () => {
        callingContext.setCallingState(DIALER_STATES.DISPOSITION);
        driver.moveNext();
        triggerModal(CALLING_DISPOSITION_MODAL_ID);
      },
      onPrevClick: () => {
        driver.movePrevious();
      },
    },
  },
  {
    element: `.call-disposition-modal`,
    popover: {
      title: "Call Disposition",
      description:
        "Finalize your notes and explain how the call went so other callers can reference it in the future.",
      side: "right",
      align: "center",
      onNextClick: () => {
        triggerModal(CALLING_DISPOSITION_MODAL_ID);
        driver.moveNext();
      },
      onPrevClick: () => {
        triggerModal(CALLING_DISPOSITION_MODAL_ID);
        callingContext.setCallingState(DIALER_STATES.CALL);

        setTimeout(() => {
          driver.movePrevious();
        }, 100);
      },
    },
  },
  {
    popover: {
      title: "You've Completed the Dialer Walkthrough!",
      description:
        "With your increased familiarity of the dialer, and your super powers as an elite salesperson, you are going to crush it out there! 💰 💸",
      onNextClick: () => {
        onDone();
        driver.moveNext();
      },
      onPrevClick: () => {
        triggerModal(CALLING_DISPOSITION_MODAL_ID);
        driver.movePrevious();
      },
    },
  },
];
