import { useMutation } from "@tanstack/react-query";

import { AccountDispositionNoteI } from "@/modules/pipeline/account-details/interfaces";
import {
  DisqualifyAccountOrContactRequestParamsI,
  SendAccountContactEmailRequestParamsI,
} from "@/api/routes/account/interfaces";
import { useApiClient } from "@/context/api-client";
import { ProspectDispositionTypeI } from "shared/lib/interfaces/dispositions";

interface BaseAccountMutationParamsI {
  campaignId: string;
  accountId: string;
}

type UseAddAccountDispositionNoteParamsI = AccountDispositionNoteI &
  BaseAccountMutationParamsI;

export const useAddAccountDispositionNote = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      accountId,
      campaignId,
      notes,
      noteType,
      accountContactId,
    }: UseAddAccountDispositionNoteParamsI) =>
      api.sendAccountDispositionNote(campaignId, accountId, {
        notes,
        accountContactId:
          accountContactId === "account" ? null : accountContactId,
        noteType: noteType,
      }),
  });
};

type UseDisqualifyAccountOrContactParamsI =
  DisqualifyAccountOrContactRequestParamsI & BaseAccountMutationParamsI;

export const useDisqualifyAccountOrContact = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      ...params
    }: UseDisqualifyAccountOrContactParamsI) =>
      api.disqualifyAccountOrContact(campaignId, accountId, params),
  });
};

interface UseRequestSendEmailParamsI {
  campaignId: string;
  contactId: string;
  request: SendAccountContactEmailRequestParamsI;
}

export const useAccountSendEmail = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      contactId,
      request,
    }: UseRequestSendEmailParamsI) =>
      api.sendAccountEmail(campaignId, contactId, request),
  });
};

interface UseEditNextTouchTimeParamsI extends BaseAccountMutationParamsI {
  nextTouchTime: string;
}

export const useEditNextTouchTime = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: async ({
      campaignId,
      accountId,
      nextTouchTime,
    }: UseEditNextTouchTimeParamsI) =>
      api.editNextTouchTime(campaignId, accountId, nextTouchTime),
  });
};

interface UseSetContactAsPrimaryParamsI extends BaseAccountMutationParamsI {
  contactId: string;
  isPrimary: boolean;
}

export const useSetUnsetContactAsPrimary = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      contactId,
      isPrimary,
    }: UseSetContactAsPrimaryParamsI) =>
      api.setUnsetContactAsPrimary(campaignId, accountId, contactId, isPrimary),
  });
};

interface UseSetContactTypeParamsI extends BaseAccountMutationParamsI {
  contactId: string;
  contactType: ProspectDispositionTypeI;
}

export const useSetContactType = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      contactId,
      contactType,
    }: UseSetContactTypeParamsI) =>
      api.setContactType(campaignId, accountId, contactId, contactType),
  });
};

interface UseRemoveAccountFromListParamsI extends BaseAccountMutationParamsI {
  listId: string;
}

export const useRemoveListFromAccount = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      listId,
    }: UseRemoveAccountFromListParamsI) =>
      api.removeListFromAccount(campaignId, accountId, listId),
  });
};

type UseCancelMeetingParamsI = BaseAccountMutationParamsI;

export const useCancelMeeting = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ campaignId, accountId }: UseCancelMeetingParamsI) =>
      api.cancelMeeting(campaignId, accountId),
  });
};

type UndisqualifyAccountParamsI = BaseAccountMutationParamsI;

export const useUndisqualifyAccount = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ campaignId, accountId }: UndisqualifyAccountParamsI) =>
      api.undisqualifyAccount(campaignId, accountId),
  });
};
