import { forwardRef, ReactNode, Ref } from "react";

import { SvgProps } from "./interfaces";

export const createIcon = (svgContent: ReactNode, viewBox = "0 0 24 24") => {
  const IconWithForwardedRef = (props: SvgProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox={viewBox}
      {...props}
      ref={ref}
    >
      {svgContent}
    </svg>
  );

  return forwardRef<SVGSVGElement, SvgProps>(IconWithForwardedRef);
};
