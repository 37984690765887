import {
  MOVE_TO_NURTURE_MODAL_ID,
  MoveToNurtureModal,
} from "@/components/modals/move-to-nurture-modal";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

export const ActionMoveToNurture = () => {
  const { contacts, campaign, account, onAccountUpdate, accountHistoryData } =
    useAccountDetailsContext();

  const openModal = () => {
    modalHelpers.open(MOVE_TO_NURTURE_MODAL_ID);
  };

  const reloadAccount = () => {
    onAccountUpdate();
    accountHistoryData.reloadData();
  };

  return (
    <>
      <button className="ae-link brand-typography-body6" onClick={openModal}>
        Move to nurture
      </button>

      <MoveToNurtureModal
        contacts={contacts}
        campaign={campaign}
        account={account}
        onSuccess={reloadAccount}
      />
    </>
  );
};
