import { FC } from "react";
import { Modal } from "shared/ui";
import { CalendarOutlineIcon } from "shared/ui/icons";

export const LIVE_TRANSFER_AMB_MODAL_ID =
  "live-transfer-after-meeting-booked-modal";

interface LiveTransferModalI {
  onConfirm?: () => void;
}

const LiveTransfer_AfterMeetingBooked_ModalContent = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
} & LiveTransferModalI) => {
  const handleConfirm = () => {
    onClose();
    onConfirm?.();
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center"></div>
      <div className="flex justify-end gap-3">
        <button className="btn-ae-default" onClick={handleConfirm}>
          Start Live Transfer
        </button>
      </div>
    </div>
  );
};

export const LiveTransfer_AfterMeetingBooked_Modal: FC<LiveTransferModalI> = ({
  onConfirm,
}) => {
  return (
    <Modal
      id={LIVE_TRANSFER_AMB_MODAL_ID}
      titleImage={<CalendarOutlineIcon className="h-5 w-5" />}
      unmountOnClose
      title={`You’re ready to transfer this lead to Homefix`}
      description={`Your lead will be transferred to the Homefix call center.`}
    >
      {({ handleClose }) => {
        return (
          <LiveTransfer_AfterMeetingBooked_ModalContent
            onClose={handleClose}
            onConfirm={onConfirm}
          />
        );
      }}
    </Modal>
  );
};
