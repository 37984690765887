import { ShortListI } from "@/api/routes/dialer";
import { LoadingComponent } from "@/components/modules/loading";
import { useFetchDataStartCallingModal as useFetchData } from "@/modals/start-calling-modal/queries";
import { useEffect, useMemo, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { ContactsBookIcon } from "shared/ui/icons";

const getWord = (amount: number) => (amount === 1 ? "lead" : "leads");

export const useStartCallingModalListDialerSection = () => {
  const queryApi = useFetchData();

  const data = useMemo(
    () => queryApi.data?.data?.list_dialing_campaigns,
    [queryApi.data?.data?.list_dialing_campaigns]
  );

  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (data) {
      setSelected(data?.[0].list_id);
    }
  }, [data]);

  return {
    isLoading: queryApi?.isLoading,
    data,
    selected,
    setSelected,
  };
};

export const StartCallingModalListDialerSection = ({
  data,
  isLoading,
  selected,
  onChange,
}: {
  data?: ShortListI[];
  isLoading?: boolean;
  selected?: string;
  onChange?: (listId: string) => void;
}) => {
  const handleSeletion = (listId: string) => () => {
    onChange?.(listId);
  };

  return (
    <div className="flex flex-grow animate-fadein flex-col">
      <div>
        <h6 className="mb-1 typography-body-5-medium">Exclusive lists</h6>
        <p className="text-[#999999] typography-body-5">
          Every night we pull 50-1000 leads for you to work exclusively based on
          your performance on the campaign and the availability of leads. Jump
          into our list autodialer to start working these leads!
        </p>
      </div>

      {isLoading && (
        <div className="flex-grow">
          <LoadingComponent delay={1000} />
        </div>
      )}

      {!isLoading && !data?.length && (
        <div className="mt-3 animate-fadein rounded-[4px] bg-[#F0F0F0] px-3 py-3">
          <p className="typography-body-4-bold">No exclusive lists available</p>
        </div>
      )}

      {!!data?.length && (
        <div className="mt-4 flex max-h-[250px] flex-grow flex-col gap-y-[2px] overflow-y-auto">
          {data?.map((list, i) => (
            <button
              key={i}
              className={clsxMerge(
                "btn rounded-[4px] pl-2",
                "flex w-full flex-nowrap items-center justify-start",
                {
                  "btn-ghost border-[#4474E3] bg-[#E8F3FF]":
                    selected === list.list_id,
                  "btn-ghost": selected !== list.list_id,
                }
              )}
              onClick={handleSeletion(list.list_id)}
            >
              <span className="mr-2">
                <ContactsBookIcon className="min-w-[14px]" />
              </span>
              <span className="flex flex-col items-start justify-center">
                <span
                  className={clsxMerge("text-start", {
                    "typography-body-4-bold": selected === list.list_id,
                    "typography-body-4": selected !== list.list_id,
                  })}
                >
                  {list.campaign_name}
                </span>
                <span className="typography-body-5-light">
                  Nurture {list.nurture_count} {getWord(list.nurture_count)} /
                  Cold {list.cold_count} {getWord(list.cold_count)}
                </span>
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
