import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { BookMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/book-meeting-button";
import { CancelMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/cancel-meeting-button";

export const ActionsDuringCall = () => {
  const { isMeetingCanceled } = useInCallContext();

  return (
    <div className="grid grid-cols-1 gap-y-2">
      <CancelMeetingButton />
      <BookMeetingButton isModifyMode={!isMeetingCanceled} />
    </div>
  );
};
