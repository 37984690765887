import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import clsx from "clsx";

export interface TextFieldI {
  name?: string;
  label?: string;
  labelClassName?: string;
  placeholder: string;
  className?: string;
  errors?: string;
  touched?: boolean;
  disabled?: boolean;
  inputProps?: any;
  inputClassName?: string;
}

const DefaultInput = (props: any) => <input {...props} />;

const TextField =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    label,
    labelClassName,
    placeholder,
    errors,
    touched,
    className,
    disabled,
    inputProps = {},
    inputClassName = "",
  }: TextFieldI) => {
    return (
      <div className={twMerge(clsx("form-control mb-3 w-full", className))}>
        {!!label && (
          <div className="mb-2">
            <label
              className={clsx("text-base-content/60", labelClassName, {
                "ae-typography-detail1":
                  !labelClassName?.includes("typography"),
              })}
            >
              {label}
            </label>
          </div>
        )}

        <Field
          name={name}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          className={clsx(
            `input ae-typography-body1 grow bg-black/5 focus:bg-black/10 disabled:text-black/60`,
            {
              "border-error-content": errors && touched,
            },
            inputClassName
          )}
          {...inputProps}
        />

        {errors && touched && (
          <div className="mt-1 flex items-center text-xs ">
            <ExclamationCircleIcon className="text-error-content bg-neutral-content mr-1 h-5 w-5" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}
      </div>
    );
  };

export default TextField;
