export const SIDEBAR_SIZE = {
  SM: "sm",
  MD: "md",
  LG: "lg",
} as const;

export const SIZE_CLASSNAMES = {
  [SIDEBAR_SIZE.SM]: "w-[420px]",
  [SIDEBAR_SIZE.MD]: "w-[524px]",
  [SIDEBAR_SIZE.LG]: "w-[1000px]",
};
