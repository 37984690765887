const EMPTY_NAME_PLACEHOLDER = "G";

export const getInitialsFromUsername = (
  userName: string | null | undefined
) => {
  if (!userName || !userName.trim()) {
    return EMPTY_NAME_PLACEHOLDER;
  }

  const nameParts = userName.split(" ");

  if (nameParts.length && nameParts[0] === "undefined") {
    return EMPTY_NAME_PLACEHOLDER;
  }

  if (nameParts.length === 1) {
    return nameParts[0][0];
  }

  const [firstName, lastName] = nameParts;

  return `${firstName[0]}${lastName[0]}`;
};
