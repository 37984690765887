import { clsxMerge } from "shared/lib/helpers";

interface RadioButtonI {
  name: string;
  value?: string;
  label: string;
  inputProps?: any;
  disabled?: any;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  textClassName?: string;
}

const DefaultInput = (props: any) => <input {...props} />;

const RadioButton =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    value,
    label,
    disabled,
    className,
    labelClassName,
    inputClassName,
    textClassName,
    inputProps = {},
  }: RadioButtonI) => {
    return (
      <div
        className={clsxMerge(
          "form-control w-56 rounded-lg border px-2",
          className
        )}
      >
        <label
          className={clsxMerge(
            "label cursor-pointer justify-start",
            labelClassName
          )}
        >
          <Field
            type="radio"
            disabled={disabled}
            name={name}
            value={value}
            className={clsxMerge(
              "radio bg-white checked:bg-black",
              inputClassName
            )}
            {...inputProps}
          />
          <span
            className={clsxMerge(
              "label-text ml-4",
              { "opacity-30": disabled },
              textClassName
            )}
          >
            {label}
          </span>
        </label>
      </div>
    );
  };

export default RadioButton;
