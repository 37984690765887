import { ASSESSMENT_STATUS_TYPES } from "@/constants/assessment";
import { NOTIFICATION_TYPES } from "@/constants/notifications";
import { NotificationI } from "@/interfaces/notification";

import { DISPOSITIONS } from "shared/lib/constants/dispositions";
import { clsxMerge } from "shared/lib/helpers";

import {
  EnvelopeIcon,
  EnvelopeOpenIcon,
  HandThumbUpIcon,
  ArrowPathIcon,
  StarIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/solid";
import { PhoneRejectIcon, CloseIcon, CalendarIcon } from "shared/ui/icons";

const IconCircleWrapper = ({
  isGreen,
  isYellow,
  isRed,
  isFire,
  isPurpleGradient,
  children,
}: {
  isGreen?: boolean;
  isYellow?: boolean;
  isRed?: boolean;
  isFire?: boolean;
  isPurpleGradient?: boolean;
  children?: any;
}) => {
  return (
    <div
      className={clsxMerge(
        "h-6 w-6 rounded-full",
        "bg-black text-white",
        "flex items-center justify-center",
        {
          "bg-[#6BC327]": isGreen,
          "bg-[#E99E2E]": isYellow,
          "bg-[#CA3C27]": isRed,
          "bg-[#F44B43]": isFire,
          "bg-gradient-to-r from-[#CB36AD] via-[#7E46CA] to-[#4C6192]":
            isPurpleGradient,
        }
      )}
    >
      {isFire && <span className="absolute top-[-18px] text-[20px]">🔥</span>}
      <span className="z-[1]">{children}</span>
    </div>
  );
};

const NotificationIcon = (props: NotificationI) => {
  const { notification_type, audition_status, meeting_feedback } = props || {};

  switch (notification_type) {
    case NOTIFICATION_TYPES.CUSTOMER_FORUM_POST:
      return (
        <IconCircleWrapper isGreen>
          <ChatBubbleLeftEllipsisIcon className="w-4" />
        </IconCircleWrapper>
      );
    case NOTIFICATION_TYPES.EMAIL_OPENED:
      return (
        <IconCircleWrapper isYellow>
          <EnvelopeOpenIcon className="w-3" />
        </IconCircleWrapper>
      );
    case NOTIFICATION_TYPES.EMAIL_RECEIVED:
      return (
        <IconCircleWrapper isFire>
          <EnvelopeIcon className="w-3" />
        </IconCircleWrapper>
      );
    case NOTIFICATION_TYPES.MISSED_CALL:
      return (
        <IconCircleWrapper isRed>
          <PhoneRejectIcon className="bottom-[-3px] left-[-3px] w-[14px]" />
        </IconCircleWrapper>
      );
    case NOTIFICATION_TYPES.AUDITION:
      switch (audition_status) {
        case ASSESSMENT_STATUS_TYPES.SUCCESS:
          return (
            <IconCircleWrapper isGreen>
              <HandThumbUpIcon className="w-3" />
            </IconCircleWrapper>
          );
        case ASSESSMENT_STATUS_TYPES.BLOCKED:
          return (
            <IconCircleWrapper isRed>
              <CloseIcon className="w-4" />
            </IconCircleWrapper>
          );
        case ASSESSMENT_STATUS_TYPES.RETRY:
          return (
            <IconCircleWrapper isYellow>
              <ArrowPathIcon className="w-4" />
            </IconCircleWrapper>
          );
      }
      break;
    case NOTIFICATION_TYPES.LIST_RECEIVED:
      return (
        <IconCircleWrapper isPurpleGradient>
          <StarIcon className="w-4" />
        </IconCircleWrapper>
      );
    case NOTIFICATION_TYPES.MEETING:
      switch (meeting_feedback) {
        case DISPOSITIONS.MEETING_QUALIFIED:
          return (
            <IconCircleWrapper isGreen>
              <HandThumbUpIcon className="w-3" />
            </IconCircleWrapper>
          );
        case DISPOSITIONS.MEETING_NOT_QUALIFIED:
          return (
            <IconCircleWrapper isRed>
              <CloseIcon className="w-4" />
            </IconCircleWrapper>
          );
        case DISPOSITIONS.MEETING_RESCHEDULED:
          return (
            <IconCircleWrapper isYellow>
              <CalendarIcon className="w-3" />
            </IconCircleWrapper>
          );
        case DISPOSITIONS.CANCELLED_MEETING:
          return (
            <IconCircleWrapper isRed>
              <CloseIcon className="w-4" />
            </IconCircleWrapper>
          );
        case DISPOSITIONS.MEETING_NO_SHOW:
          return (
            <IconCircleWrapper isRed>
              <CloseIcon className="w-4" />
            </IconCircleWrapper>
          );
      }
      break;
  }

  return null;
};

export const NotificationTypeIcon = (props: NotificationI) => {
  return (
    <div className={clsxMerge("absolute -bottom-1 -right-1")}>
      <NotificationIcon {...props} />
    </div>
  );
};
