import { useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";

import { DAYS, MONTHS } from "shared/lib/constants/time";
import { useEffectOnce } from "shared/lib/hooks";
import { AccountHistoryI } from "@/interfaces/accounts";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

const ActivityCardTodoFollowupEmail = ({
  activity,
}: {
  activity: AccountHistoryI;
}) => {
  const [isInit, setIsInit] = useState(false);

  useEffectOnce(() => {
    setIsInit(true);
  });

  return (
    <div>
      <div
        className={clsx(
          "relative flex gap-2",
          "items-center",
          "transition-[opacity,top] duration-300",
          {
            "-top-5 opacity-0": !isInit,
            "top-0 opacity-100": isInit,
          }
        )}
      >
        <div
          className={clsx(
            "flex h-8 w-8 items-center justify-center rounded-full border border-black/10 bg-white"
          )}
        >
          <EnvelopeIcon className="h-4 w-4 text-black/60" />
        </div>

        <div>
          <p className="ae-typography-detail1 text-black/40">
            <span className="uppercase">
              {`${DAYS[dayjs(activity.created_at).day()]}, 
            ${MONTHS[dayjs(activity.created_at).month()]} 
            ${dayjs(activity.created_at).date()}`}
            </span>
          </p>

          <p className="ae-typography-body2">Follow-up Email Sent</p>
        </div>
      </div>
    </div>
  );
};

export default ActivityCardTodoFollowupEmail;
