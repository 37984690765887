import { FC, useCallback, useMemo } from "react";

import { EmailTemplateI } from "@/api/routes/email";
import { clsxMerge } from "shared/lib/helpers";
import { EMAIL_TEMPLATE_DELETE_MODAL_ID } from "@/components/modals/email-template-delete-modal";
import { modal } from "@/components/modals";
import { EmailTemplatesListItem } from "@/modules/email-templates-sidebar/content/email-templates-list/list-item";

const TEMPLATE_TO_IMAGE_MAP: Record<string, string> = {
  "1": "https://glencoco-assets.s3.amazonaws.com/image-icons/page-image.webp",
  "2": "https://glencoco-assets.s3.amazonaws.com/image-icons/user-page-hearts.webp",
  "3": "https://glencoco-assets.s3.amazonaws.com/image-icons/search-page.webp",
};

const DEFAULT_LIST_IMAGE =
  "https://glencoco-assets.s3.amazonaws.com/image-icons/user-page.webp";

interface EmailTemplatesListPropsI {
  className?: string;
  templates?: EmailTemplateI[];
  activeTemplateId?: string;

  setEditMode?: (isEditing?: boolean) => void;
  setActiveTemplateId?: (templateId?: string) => void;
}

export const EmailTemplatesList: FC<EmailTemplatesListPropsI> = ({
  className,
  templates,
  activeTemplateId,

  setEditMode = () => {},
  setActiveTemplateId = () => {},
}) => {
  const getListImgSrc = useCallback((templateId?: string) => {
    if (templateId && Object.keys(TEMPLATE_TO_IMAGE_MAP).includes(templateId)) {
      return TEMPLATE_TO_IMAGE_MAP[templateId];
    }

    return DEFAULT_LIST_IMAGE;
  }, []);

  const sortedTemplates = useMemo(() => {
    return templates?.sort((a, b) => {
      const aVal = a.is_custom ? 1 : 0;
      const bVal = b.is_custom ? 1 : 0;

      return aVal - bVal;
    });
  }, [templates]);

  // actions to handle displaying the correct view
  const viewActions = useMemo(
    () => ({
      createTemplate: () => {
        setActiveTemplateId(undefined);
        setEditMode(true);
      },
      selectTemplate: (templateId: string) => {
        setActiveTemplateId(templateId);
        setEditMode(false);
      },
      editTemplate: (templateId: string) => {
        setActiveTemplateId(templateId);
        setEditMode(true);
      },
      deleteTemplate: (templateId: string) => {
        setActiveTemplateId(templateId);
        setEditMode(false);
        modal.trigger(EMAIL_TEMPLATE_DELETE_MODAL_ID);
      },
    }),
    [setActiveTemplateId, setEditMode]
  );

  return (
    <div className={clsxMerge("bg-white px-4 py-7", className)}>
      <div className="mb-6 typography-header-6">Email</div>

      <div className="mb-3 flex items-center justify-between">
        <div className="typography-body-3-medium">Select email template</div>
        <button
          className="ae-link typography-body-4-medium"
          onClick={viewActions.createTemplate}
        >
          + Add new template
        </button>
      </div>

      <div className="flex flex-col gap-2">
        {sortedTemplates?.map((t, i) => (
          <EmailTemplatesListItem
            key={i}
            {...t}
            imgSrc={getListImgSrc(t.id)}
            isActive={t.id === activeTemplateId}
            onClick={() => t.id && viewActions.selectTemplate(t.id)}
            onEdit={() => t.id && viewActions.editTemplate(t.id)}
            onDelete={() => t.id && viewActions.deleteTemplate(t.id)}
          />
        ))}
      </div>
    </div>
  );
};
