import { useState } from "react";

import { CloseIcon } from "shared/ui/icons";
import clsx from "clsx";

import { meetingsToastEmitter } from "..";
import { CustomMeetingsToastI } from "@/interfaces/custom-toasts/meetings";

import { CampaignLogo } from "@/components/base/campaign-logo";
import Link from "next/link";
import { twMerge } from "tailwind-merge";

interface BookedMeetingToastI {
  toastData: CustomMeetingsToastI;
}

const BookedMeetingToast = ({
  toastData,
  className,
}: BookedMeetingToastI & { className?: string }) => {
  const [renderToast, setRenderToast] = useState(true);

  const handleClose = () => {
    setRenderToast(false);
    meetingsToastEmitter.dismiss();
  };

  return renderToast ? (
    <div
      className={twMerge(
        clsx(
          " w-[600px]",
          "p-5",
          "transition-opacity duration-500 ease-out",
          "animate-in fade-in",
          "gap-0 overflow-hidden rounded-xl border-2 text-white shadow-[0px_4px_88px_rgba(0,0,0,0.06)]",
          "bg-[linear-gradient(273deg,_#000_82.66%,_#535353_99.29%)]",

          className
        )
      )}
    >
      <div className="flex">
        <div className="flex items-center">
          <CampaignLogo
            campaignId={toastData.data?.campaign_id}
            defaultBgColor="bg-white"
            className="min-h-[60px] min-w-[60px] rounded-xl"
          />
          <div className="relative top-1 ml-6 flex flex-col justify-center">
            <h4 className="ae-typography-h4 mb-1">
              {toastData.data?.campaign_name}
            </h4>

            <p className="ae-typography-body2 mb-3 line-clamp-2">
              <b>{`${toastData.data?.prospect_name} booked a meeting`}</b> with{" "}
              {toastData.data?.contact_title} from{" "}
              {toastData.data?.account_name} account
            </p>
          </div>

          <Link
            href={toastData.data?.recording_url || ""}
            target="_blank"
            className="btn-b-white ml-4 w-[120px]"
          >
            Listen
          </Link>

          <div>
            <button
              className={clsx("btn btn-circle btn-ghost btn-sm")}
              onClick={handleClose}
            >
              <CloseIcon className="ml-6 min-w-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default BookedMeetingToast;
