import { useEffectOnce } from "shared/lib/hooks";
import { checkIfClient } from "shared/lib/helpers";

export const useBeforeTabClose = (text?: string) => {
  const eventHandler = (e: any) => {
    const confirmationMessage =
      typeof text === "string" ? text : "Are you sure?";

    alert(confirmationMessage);

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };

  const addEvent = () => {
    window.addEventListener("beforeunload", eventHandler);
  };

  const removeEvent = () => {
    window.removeEventListener("beforeunload", eventHandler);
  };

  return useEffectOnce(() => {
    if (checkIfClient()) {
      addEvent();
    }

    return () => {
      removeEvent();
    };
  });
};
