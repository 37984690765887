import { FC } from "react";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { BookMeetingButton } from "./book-meeting-button";
import { LiveTransferButton } from "./live-transfer-button";
import { CallAgainButton } from "./call-again-button";
import { AddToListsButton } from "./add-to-list-button";

interface LeadCardActionsSectionPropsI {
  isCallEnded?: boolean;
}

export const LeadCardActionsSection: FC<LeadCardActionsSectionPropsI> = ({
  isCallEnded = false,
}) => {
  const { campaign } = useCallingContext();

  return (
    <div className="flex flex-col">
      <div className="mt-4 flex gap-4">
        {campaign?.has_live_transfer ? (
          <LiveTransferButton className="flex-1 p-0" />
        ) : (
          <BookMeetingButton className="flex-1 p-0" />
        )}
      </div>

      <div className="mt-4 flex w-full gap-4">
        <AddToListsButton containerClassName="flex-1" />
        {isCallEnded && <CallAgainButton className="flex-1 p-0" />}
      </div>
    </div>
  );
};
