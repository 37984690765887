import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { FC } from "react";

import Link from "next/link";

export interface MenuItemI {
  label: string;
  active: boolean;
  href?: string;
  children?: MenuItemI[];
}

interface SideNavI {
  menuData: MenuItemI[];
  className?: string;
  onClick?: (event: any) => void;
}

const SidebarNav: FC<SideNavI> = ({ menuData, className, onClick }) => {
  return (
    <div
      className={twMerge(clsx("relative h-fit", className))}
      onClick={onClick}
    >
      {menuData.map((category, i) => (
        <div key={i}>
          <div className={clsx(`mb-5 flex`)}>
            <div
              className={clsx(`cursor-pointer hover:text-slate-400`, {
                "font-bold ": category.active,
              })}
              data-index={i}
            >
              {category?.href ? (
                <Link
                  href={category.href}
                  data-index={i}
                  className="typography-subtitle"
                >
                  {category.label}
                </Link>
              ) : (
                category.label
              )}
            </div>
          </div>
          {category.active &&
            category.children?.map((item, j) => (
              <div key={j} className="mt-2 flex">
                <div
                  className={clsx(
                    `ml-11 cursor-pointer whitespace-nowrap hover:text-slate-400`,
                    {
                      "font-bold text-slate-900": item.active,
                      "text-slate-500": !item.active,
                    }
                  )}
                >
                  {item?.href ? (
                    <Link href={item.href} data-index={`${i}-${j}`}>
                      {item.label}
                    </Link>
                  ) : (
                    item.label
                  )}
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default SidebarNav;
