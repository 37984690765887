import { FC, useState } from "react";
import clsx from "clsx";

import { DescList } from "shared/ui";
import { InfoRecordI } from "shared/ui/desc-list";

import {
  PersonasIcon,
  PhoneIcon,
  MailIcon,
  LogoLinkedInIcon,
} from "shared/ui/icons";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { useEffectOnce } from "shared/lib/hooks";

const createInfoRecord = (
  icon: JSX.Element,
  data?: string,
  config?: any
): InfoRecordI => ({
  icon,
  data,
  config,
});

interface SidebarContentI {
  account: CallAccountI;
  contact: CallContactI;
}

const SidebarContent: FC<SidebarContentI> = ({ account, contact }) => {
  const employee_count = account?.employee_count || "-";
  const revenue = account?.revenue || "-";
  const industry = account?.industry || "-";
  const hq_state = account?.hq_state || "-";

  const phone = contact?.phone || "-";
  const email = contact?.email || "-";
  const linkedin = contact?.linkedin || "-";

  const [isVisible, setIsVisible] = useState(false);

  const accountInfo = [
    createInfoRecord(<PersonasIcon className="mr-1 h-2" />, employee_count),
    createInfoRecord(<BuildingOfficeIcon className="mr-1 h-3" />, industry),
    createInfoRecord(<BanknotesIcon className="mr-1 h-3" />, revenue as string),
    createInfoRecord(<MapPinIcon className="mr-1 h-3" />, hq_state),
  ];

  const contactInfo = [
    createInfoRecord(<PhoneIcon className="mr-1 h-2" />, phone),
    createInfoRecord(<MailIcon className="mr-1 h-3" />, email, {
      isAccent: true,
      href: `mailto:${email}`,
      target: "_blank",
      rel: "noreferrer",
    }),

    createInfoRecord(<LogoLinkedInIcon className="mr-1 h-3" />, linkedin, {
      isAccent: true,
      href: linkedin,
      target: "_blank",
      rel: "noreferrer",
    }),
  ];

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <article
      className={clsx("transition-opacity duration-300", {
        "opacity-0": !isVisible,
        "opacity-100": isVisible,
      })}
    >
      <h2 className="ae-typography-h2 mt-5">
        {contact?.first_name} {contact?.last_name}
      </h2>
      <p className="ae-typography-body3 mt-2 text-black/40">
        {contact?.title} @ {account?.name}
      </p>

      <section className="mt-9">
        <p className="ae-typography-detail1 mb-3 text-black/40">
          About {account?.name}
        </p>
        <DescList infoList={accountInfo} />
      </section>

      <section className="mt-9">
        <p className="ae-typography-detail1 mb-3 text-black/40">
          About {contact?.first_name} {contact?.last_name}
        </p>
        <DescList infoList={contactInfo} />
      </section>
    </article>
  );
};

export default SidebarContent;
