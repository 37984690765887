import { useRef, useState } from "react";

import CallDispositionCard from "@/modules/calling/cards/call-disposition-card";
import { checkIfClient } from "shared/lib/helpers";

interface FollowUpModalI {
  modalId?: string;
}

export const CALLING_DISPOSITION_MODAL_ID = "calling-disposition-modal";

const ConditionalCloseIcon = ({ modalId }: { modalId: string }) => {
  return (
    <label
      htmlFor={modalId}
      className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 bg-white/60 text-black/40"
    >
      ✕
    </label>
  );
};

const CallingDispositionModal = ({
  modalId = CALLING_DISPOSITION_MODAL_ID,
}: FollowUpModalI) => {
  const [isVisible, setIsVisible] = useState(false);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  const close = () => {
    if (checkIfClient()) {
      (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) => {
          onToogleHandler((event.target as HTMLInputElement)?.checked);
        }}
      />

      <div className="modal cursor-pointer">
        <div className="call-disposition-modal min-[520px] modal-box relative w-auto max-w-[640px] rounded-[12px] p-0">
          <ConditionalCloseIcon modalId={modalId} />

          <div>
            {isVisible && (
              <CallDispositionCard
                isWrapperNoStyles
                isHideSetCallbackQuestion
                isHideLeadDescription
                onSuccess={() => {
                  close();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CallingDispositionModal;
