export const EVENT_PILL_TYPES = {
  OVERDUE_CALLBACK: "overdue_callback",
  COMPLETED_CALLBACK: "completed_callback",
  UPCOMING_CALLBACK: "upcoming_callback",
  ALERT_RETURNED_CALL: "alert_returned_call",
  UPCOMING_MEETING: "upcoming_meeting",
  PENDING_FEEDBACK: "pending_feedback",
  NO_SHOW_MEETING: "no_show_meeting",
  UNQUALIFIED_MEETING: "unqualified_meeting",
  QUALIFIED_MEETING: "qualified_meeting",
  RESCHEDULED_MEETING: "rescheduled_meeting",
  CANCELLED_MEETING: "meeting_cancelled",
};

export const EVENT_PILL_STRING_MAP = {
  [EVENT_PILL_TYPES.OVERDUE_CALLBACK]: "Overdue callback",
  [EVENT_PILL_TYPES.COMPLETED_CALLBACK]: "Completed callback",
  [EVENT_PILL_TYPES.UPCOMING_CALLBACK]: "Upcoming callback",
  [EVENT_PILL_TYPES.ALERT_RETURNED_CALL]: "Alert returned call",
  [EVENT_PILL_TYPES.UPCOMING_MEETING]: "Upcoming meeting",
  [EVENT_PILL_TYPES.PENDING_FEEDBACK]: "Pending feedback",
  [EVENT_PILL_TYPES.NO_SHOW_MEETING]: "No show meeting",
  [EVENT_PILL_TYPES.UNQUALIFIED_MEETING]: "Unqualified meeting",
  [EVENT_PILL_TYPES.QUALIFIED_MEETING]: "Qualified meeting",
  [EVENT_PILL_TYPES.RESCHEDULED_MEETING]: "Rescheduled meeting",
  [EVENT_PILL_TYPES.CANCELLED_MEETING]: "Meeting Canceled",
} as const;
