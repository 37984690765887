import { FC } from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { HistoryCardBookedMeetingInfo } from "shared/ui/activity-log/cards-v2/base/event-date";
import { HistoryCardAEInfo } from "shared/ui/activity-log/cards-v2/base/ae-info";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";

const ACTIVITY_TYPE_CONFIG = {
  icon: PhoneIcon,
  title: "Meeting canceled",
};

const HistoryCardCanceledMeetingV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardBookedMeetingInfo date={activity.follow_up_time} />
      <HistoryCardContactInfo contact={activity.contact} />

      {activity.is_customer ? (
        <HistoryCardAEInfo aeName={activity.ae_name || "N/A"} />
      ) : (
        <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
      )}
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardCallNotes
        isCustomerNotes={activity.is_customer}
        notes={activity.notes}
      />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardCanceledMeetingV2;
