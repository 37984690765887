import { FC } from "react";
import { AudioPlayerPropsI } from "./wavesurfer-audio-player";

import clsx from "clsx";

const DefaultAudioPlayer: FC<
  AudioPlayerPropsI & { disableCacheBreaker?: boolean }
> = ({ url, className }) => {
  return (
    <audio controls className={clsx("w-full", className)}>
      <source src={url} />
    </audio>
  );
};

export default DefaultAudioPlayer;
