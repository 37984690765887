import { FC } from "react";

import { clsxMerge, getS3AssetPath } from "shared/lib/helpers";
import { LeadAvatarVolumeVisualizer } from "@/components/modules/calling/cards/lead-card/v2/lead-avatar/volume";

import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

interface LeadCardAvatarPropsI {
  className?: string;
  avatarClassName?: string;
  ripplesClassName?: string;
  contactName?: string;
}

export const LeadCardAvatar: FC<LeadCardAvatarPropsI> = ({
  className,
  avatarClassName,
  ripplesClassName,
  contactName,
}) => {
  const callStatus = useDialerCallStatus();
  const { startAt } = useInCallContext();

  const isCommunicationInProgress =
    callStatus === DIALER_CALL_STATUS.DURING && !!startAt;

  return (
    <div className={clsxMerge("relative p-10", className)}>
      <LeadAvatarVolumeVisualizer
        isCallInProgress={isCommunicationInProgress}
        className={clsxMerge(ripplesClassName)}
      />

      <div className={clsxMerge(avatarClassName)}>
        <img
          alt={contactName}
          className="w-full"
          src={getS3AssetPath("platform/calling/lead-card-caller-avatar.webp")}
        />
      </div>
    </div>
  );
};
