import clsx from "clsx";
import { FC } from "react";

import {
  DISPOSITIONS,
  DISPOSITIONS_STRING_MAP,
} from "shared/lib/constants/dispositions";

export const DISPOSITION_BADGE_CSS_PROPERTIES = {
  [DISPOSITIONS.BOOKED_MEETING as string]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
  [DISPOSITIONS.TENTATIVE_INTEREST]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
  [DISPOSITIONS.NO_PICK_UP]: {
    color: "text-black/80",
    bg: "bg-[#F2FBE1]",
  },
  [DISPOSITIONS.CALLBACK_BOOKED]: {
    color: "text-black/80",
    bg: "bg-[#F2FBE1]",
  },
  [DISPOSITIONS.NOT_INTERESTED]: {
    color: "text-black/80",
    bg: "bg-[#FFEFEB]",
  },
  [DISPOSITIONS.NOT_QUALIFIED]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [DISPOSITIONS.BAD_DATA]: {
    color: "text-black/80",
    bg: "bg-[#FF9E89]",
  },
  [DISPOSITIONS.OTHER]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [DISPOSITIONS.AE_NOTE]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [DISPOSITIONS.CANCELLED_MEETING]: {
    color: "text-black/80",
    bg: "bg-black/5",
  },
  [DISPOSITIONS.ACCOUNT_DNC]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [DISPOSITIONS.MEETING_NO_SHOW]: {
    color: "text-black/80",
    bg: "bg-[#FFEFEB]",
  },
  [DISPOSITIONS.MEETING_RESCHEDULED]: {
    color: "text-black/80",
    bg: "bg-[rgba(0,0,0,.08)]",
  },
  [DISPOSITIONS.MEETING_NOT_QUALIFIED]: {
    color: "text-black/80",
    bg: "bg-[#FFBFB0]",
  },
  [DISPOSITIONS.MEETING_QUALIFIED]: {
    color: "text-black/80",
    bg: "bg-[#DFF4B4]",
  },
} as const;

interface DispositionPillI {
  disposition: (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS];
  className?: string;
}

const DispositionPill: FC<DispositionPillI> = ({ disposition, className }) => {
  return (
    <div
      className={clsx(
        "ae-typography-detail1 w-fit rounded px-2 py-1 text-center",
        DISPOSITION_BADGE_CSS_PROPERTIES[disposition]?.bg,
        DISPOSITION_BADGE_CSS_PROPERTIES[disposition]?.color,
        className
      )}
    >
      {DISPOSITIONS_STRING_MAP[disposition]}
    </div>
  );
};

export default DispositionPill;
