import { SendEmailButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/send-email-button";
import { CallbackButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/callback-button";
import { BookMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/book-meeting-button";
import { MarkDoneButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/mark-done-button";
import { CATCHUPS_WALKTHROUGH_ELEMENT_IDS } from "@/modules/calling/modes/catchup/walkthrough";

export const ActionsAfterCall = () => (
  <div className="grid grid-cols-2 gap-x-1">
    <div
      id={CATCHUPS_WALKTHROUGH_ELEMENT_IDS.MARK_COMPLETED}
      className="relative z-10 flex flex-col gap-2"
    >
      <SendEmailButton />
      <MarkDoneButton />
    </div>

    <div className="flex flex-col gap-2">
      <CallbackButton />
      <BookMeetingButton />
    </div>
  </div>
);
