import {
  NOTIFICATION_ACTION_TYPES,
  createNotificationAction,
} from "shared/ui/notifications/notification-generic/base-parts/actions";
import { NotificationBase } from "@/modules/notifications/notification/base";
import { NotificationI } from "@/interfaces/notification";
import { openAccountDetails } from "@/helpers/account-details";

export const NotificationMeetingRescheduled = (
  props: NotificationI & { className?: string }
) => {
  const {
    id,
    campaign_id,
    account_id,

    campaign_name,
    contact_name,

    is_seen,
    created_at,
    className,
  } = props;

  return (
    <NotificationBase
      id={id}
      isUnread={!is_seen}
      className={className}
      avatar={props}
      description={{
        text: (
          <>
            <b>{campaign_name}</b> rescheduled your meeting with{" "}
            <b>{contact_name}</b>!
          </>
        ),
        date: created_at,
        campaignName: campaign_name,
      }}
      actions={[
        createNotificationAction(
          NOTIFICATION_ACTION_TYPES.PRIMARY,
          "View account",
          () => {
            openAccountDetails({
              campaignId: campaign_id,
              accountId: account_id,
            });
          }
        ),
      ]}
    />
  );
};
