import { useDialerDirectCall } from "@/hooks/dialer/use-dialer-direct-call";
import { DialerConfigI } from "@/interfaces/dialer/config";
import { useEffect } from "react";
import wait from "wait";

export const DirectCallInitialization = ({
  config,
}: {
  config?: DialerConfigI;
}) => {
  const { startDirectCall } = useDialerDirectCall();

  useEffect(() => {
    (async () => {
      /** NOTE - timeout workaround is to let other providers from v1 finish initialization
       *        specifically Twillio Provider
       */
      await wait(1000);

      startDirectCall(config);
    })();
  }, []);

  return null;
};
