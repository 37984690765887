import Link from "next/link";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

export interface ReviewElementPropsI {
  label: string;
  value?: string | number;
  href?: string;
  className?: string;
  onEditButtonClick?: () => void;
  readOnly?: boolean;
}

const ReviewElement = ({
  label,
  value,
  href,
  className,
  onEditButtonClick,
  readOnly,
}: ReviewElementPropsI) => {
  return (
    <div
      className={twMerge(
        clsx(
          "flex items-center justify-between rounded-lg border p-4",
          className,
          {
            "bg-black/5": readOnly,
          }
        )
      )}
    >
      <div className="column mr-6 flex flex-col justify-center gap-1">
        <div className="typography-h3">{label}</div>
        <div className="typography-body text-black/60">{value}</div>
      </div>

      {!readOnly &&
        (href ? (
          <Link
            className="btn btn-nofill typography-tiny flex items-center gap-2 "
            href={href}
          >
            <PencilSquareIcon width={16} />
            Edit
          </Link>
        ) : (
          <button
            className="btn btn-nofill typography-tiny gap-2"
            onClick={onEditButtonClick}
          >
            <PencilSquareIcon width={16} />
            Edit
          </button>
        ))}
    </div>
  );
};

export default ReviewElement;
