import React, { ComponentProps, FunctionComponent, forwardRef } from "react";

type SvgProps = ComponentProps<"svg">;

const PinIcon = (props: SvgProps, svgRef: any) => (
  <svg
    ref={svgRef}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M23.605 8.396 15.607.393a1.333 1.333 0 0 0-1.449-.294 1.343 1.343 0 0 0-.844 1.183V4.5L8.826 9l-6.86 1.174a1.335 1.335 0 0 0-.729 2.258l4.221 4.224-5.065 5.069a1.323 1.323 0 0 0 0 1.885c.521.52 1.363.52 1.884 0l5.065-5.069 4.222 4.224c.25.248.588.388.942.392.139-.004.275-.025.409-.063.467-.163.808-.569.888-1.058l1.129-6.865 4.496-4.49h3.235a1.342 1.342 0 0 0 1.235-.827c.208-.5.091-1.077-.293-1.458Zm-4.71-.392c-.353 0-.692.141-.942.392l-5.19 5.184a1.34 1.34 0 0 0-.373.729l-.773 4.722-6.656-6.696 4.719-.774c.275-.045.53-.175.729-.373l5.145-5.193c.253-.248.394-.588.391-.943v-.551l3.466 3.468-.516.035Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(PinIcon) as FunctionComponent<SvgProps>;

export default ForwardRef;
