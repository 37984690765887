import { useQuery } from "@tanstack/react-query";
import { glencocoClientAPI } from "@/api/glencoco";

const SIDEBAR_UPLOAD_LEADS_QUERY_KEY = "sidebar_upload_leads";
const LISTS = "lists";

export const useFetchPastIngestions = () =>
  useQuery({
    queryKey: [SIDEBAR_UPLOAD_LEADS_QUERY_KEY],
    queryFn: glencocoClientAPI().getIngestions,
    staleTime: 0,
  });

export const useFetchLists = () =>
  useQuery({
    queryKey: [LISTS],
    queryFn: glencocoClientAPI().getLists,
  });
