import { useEffect, useRef } from "react";

type FunctionType = (...args: any[]) => any;

const DEFAULT_TIMEOUT_DELAY = 1000;

export const useTimeout = (
  callback: FunctionType,
  delay: number = DEFAULT_TIMEOUT_DELAY
) => {
  // Save callback as a ref to avoid re-running timeout every time accidentally
  const callbackRef = useRef<FunctionType>(callback);

  useEffect(() => {
    const timeout = setTimeout(() => {
      callbackRef.current();
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);
};
