import React from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";

import CalendarForm, {
  CallingCalendarFormButtonI,
} from "shared/ui/calendar-form";
import { CallContactI } from "@/interfaces/dialer";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

export const CallbackCalendarForm = ({
  title = "",
  description = "",

  bookingDeadlineInMonths = 2,

  initialTime,
  initialDate,
  initialTimezone,

  isNotes = true,
  isNotesRequired,
  notes = "",

  cancelButton,
  successButton,

  isLoading,

  onNotesChange = () => {},
  onSubmit = async () => true,
}: {
  title?: string;
  description?: string;

  bookingDeadlineInMonths?: number | boolean;

  initialTime?: string;
  initialDate?: string;
  initialTimezone?: string;

  isNotes?: boolean;
  isNotesRequired?: boolean;
  notes?: string;

  cancelButton?: CallingCalendarFormButtonI;
  successButton?: CallingCalendarFormButtonI;

  isLoading?: boolean;

  onNotesChange?: (notesValue: string) => void;
  onSubmit?: (date: string, notes?: string) => Promise<boolean>;

  contact?: CallContactI;
}) => {
  const handleFormSubmit = async (
    date: string,
    time: string,
    timezone: string,
    notes: string,
    onSuccessCleanUp: () => void
  ) => {
    const isSuccess = await onSubmit?.(dayjs(date).format(), notes);

    isSuccess && onSuccessCleanUp();
  };

  return (
    <div>
      <CalendarForm
        bookingDeadlineInMonths={bookingDeadlineInMonths}
        title={title}
        description={description}
        isNotes={isNotes}
        isNotesRequired={isNotesRequired}
        notes={notes}
        initialDate={initialDate}
        initialTimezone={initialTimezone}
        initialTime={initialTime}
        cancelButton={cancelButton}
        successButton={successButton}
        onNotesChange={onNotesChange}
        onFormSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};
