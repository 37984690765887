import { FC, useEffect, useMemo } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import History from "@tiptap/extension-history";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { HTTPS_REGEX } from "shared/lib/constants/urls";
import { COMMON_TIPTAP_EXTENSIONS } from "./constants";
import { EditorMenu } from "./editor-menu";

export interface EmailEditorContentI {
  plainText?: string;
  htmlText?: string;
}

export interface EmailEditorPropsI {
  className?: string;
  menuClassName?: string;
  initialContent?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onChange?: (content: EmailEditorContentI) => void;
}

// Note: this component only has a `initialContent` prop, and no `value` prop because the live value of it is controlled interally by the Editor
export const EmailEditor: FC<EmailEditorPropsI> = ({
  className,
  menuClassName,
  initialContent,
  placeholder,
  error,
  disabled,
  onChange = () => {},
}) => {
  const editorExtensions = useMemo(
    () => [
      ...COMMON_TIPTAP_EXTENSIONS,
      Link.extend({ inclusive: false }).configure({
        protocols: ["https"],
        openOnClick: false,
        validate: (url) => HTTPS_REGEX.test(url),
        HTMLAttributes: {
          rel: null,
          target: null,
        },
      }),
      History.configure({
        depth: 10,
      }),
      Placeholder.configure({
        placeholder,
      }),
      CharacterCount.configure({
        mode: "textSize",
        limit: 2000,
      }),
    ],
    [placeholder]
  );

  const editor = useEditor({
    content: initialContent,
    // TODO: fix this type issue and remove ts-ignore
    // @ts-ignore
    extensions: editorExtensions,
    editorProps: {
      attributes: {
        class: "focus:outline-none b-typography-body3 [&>p]:mb-4",
      },
    },
    onUpdate: ({ editor }) => {
      onChange({ plainText: editor.getText(), htmlText: editor.getHTML() });
    },
  });

  useEffect(() => {
    editor?.commands.setContent(initialContent || "", true);
  }, [initialContent, editor]);

  useEffect(() => {
    editor?.setEditable(!disabled);
  }, [disabled, editor]);

  return (
    <div>
      <div className={clsxMerge("rounded-lg bg-white", className)}>
        <EditorMenu editor={editor} className={menuClassName} />

        <div className="p-4">
          <EditorContent editor={editor} className="min-h-[60px]" />
        </div>
      </div>

      {error && (
        <div className="text-error-content mt-1 flex items-center gap-1 text-xs">
          <ExclamationCircleIcon className="w-5" />
          {error}
        </div>
      )}
    </div>
  );
};
