import {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Field as FormikField, FieldProps } from "formik";
import _keyBy from "lodash/keyBy";
import _findIndex from "lodash/findIndex";
import _map from "lodash/map";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers/styles";
import { useOnClickOutside } from "shared/lib/hooks/use-on-click-outside";

export interface MultiselectOptionI<T extends string> {
  value: T;
  label: string;
}

interface MultiselectPropsI<T extends string> {
  value: T[];
  onChange: (value: T[]) => void;
  name?: string;
  label?: string;
  options: MultiselectOptionI<T>[];
  placeholder?: string;
  touched?: boolean;
  errors?: string | string[];
  isDisabled?: boolean;
  isRadio?: boolean;
  className?: string;
  selectWrapperClassName?: string;
  labelClassName?: string;
  labelContentClassName?: string;
  menuClassName?: string;
  selectedValueLabelClassName?: string;
  itemClassName?: string;
  itemCheckboxClassName?: string;

  // if shouldConfirm is true, a confirm button will be shown at the bottom of the
  // dropdown, and onConfirm will be called when the button is clicked. onChange
  // behavior will not change.
  shouldConfirm?: boolean;
  onConfirm?: (value: T[]) => void;

  // Adds Select all option to the top
  canSelectAll?: boolean;

  noOptionsMessage?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  getLabel?: (selectedOptions: T[] | undefined) => ReactNode;
  isOptionDisabled?: (
    selectedOptions: T[],
    option: T,
    optionIndex: number
  ) => boolean;
  sortOptions?: (options: MultiselectOptionI<T>[]) => MultiselectOptionI<T>[];
}

const VALUES_TO_DISPLAY_IN_LABEL = 2;

const NO_OPTIONS_DEFAULT_MESSAGE = "No options available";

/**
 * Basic Multiselect component
 * @param value - selected values for a controlled version of Multiselect
 * @param onChange - onChange handler for a controlled version of Multiselect
 * @param name - field name
 * @param label - label for the field (displayed above the select)
 * @param options
 * @param placeholder
 * @param touched
 * @param errors
 * @param isDisabled
 * @param isRadio
 * @param className
 * @param labelClassName
 * @param selectWrapperClassName
 * @param labelContentClassName
 * @param menuClassName
 * @param selectedValueLabelClassName
 * @param itemClassName
 * @param itemCheckboxClassName
 * @param shouldConfirm - if true, a confirm button will be shown at the bottom of the dropdown
 * @param onConfirm - callback to be called when the confirm button is clicked, should be used in pair with shouldConfirm
 * @param canSelectAll - if true, a "Select all" checkbox will be shown at the top of the dropdown
 * @param disabled
 * @param noOptionsMessage - if no options are available, this message will be shown
 * @param inputProps
 * @param getLabelProp
 * @param isOptionDisabled
 * @param sortOptions - function to sort the options
 */
export const BasicMultiselect = <T extends string>({
  value,
  onChange,
  name,
  label,
  options,
  placeholder,
  touched,
  errors,
  isDisabled,
  isRadio,
  className,
  labelClassName,
  selectWrapperClassName,
  labelContentClassName,
  menuClassName,
  selectedValueLabelClassName,
  itemClassName,
  itemCheckboxClassName,

  shouldConfirm,
  onConfirm,

  canSelectAll = false,

  noOptionsMessage,
  inputProps,
  getLabel: getLabelProp,
  isOptionDisabled = () => false,
  sortOptions,
}: MultiselectPropsI<T>) => {
  const [isSelectAllMode, setIsSelectAllMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const mayBeClosedRef = useRef(false);

  const hasError = useMemo(() => Boolean(touched && errors), [touched, errors]);

  const sortedOptions = useMemo(
    () => (sortOptions ? sortOptions(options) : options),
    [options, sortOptions]
  );

  const allValues = useMemo(
    () => _map(sortedOptions, "value"),
    [sortedOptions]
  );

  const selectableValues = useMemo(() => {
    if (!isOptionDisabled) {
      return allValues;
    }

    return allValues.filter((v, index) => !isOptionDisabled(value, v, index));
  }, [allValues, value, isOptionDisabled]);

  const valueToLabelMap = useMemo(() => _keyBy(options, "value"), [options]);

  const handleOpenOptionsMenu = () => {
    if (isDisabled) {
      return;
    }

    if (!isOpen) {
      setIsOpen(true);

      setTimeout(() => {
        mayBeClosedRef.current = true;
      }, 200);
    }
  };

  const getLabel = (values: T[] | undefined) => {
    if (getLabelProp) {
      return getLabelProp(values || []);
    }

    if (!values || values.length === 0) {
      return placeholder;
    }

    const valuesToConsider = values.filter(
      (value) =>
        !isOptionDisabled(values, value, _findIndex(options, ["value", value]))
    );

    let labelValue = valuesToConsider
      .map((value) => valueToLabelMap[value]?.label)
      .slice(0, VALUES_TO_DISPLAY_IN_LABEL)
      .join(", ");

    if (valuesToConsider.length > 2) {
      labelValue += ` and ${
        valuesToConsider.length - VALUES_TO_DISPLAY_IN_LABEL
      } more`;
    }

    return labelValue;
  };

  const handleSelectOption = (option: MultiselectOptionI<T>) => {
    if (
      isDisabled ||
      isOptionDisabled(value, option.value, options.indexOf(option))
    ) {
      return;
    }

    if (isRadio) {
      onChange([option.value]);
      return;
    }

    const newValue = value.includes(option.value)
      ? value.filter((v) => v !== option.value)
      : [...value, option.value];

    onChange(newValue);
  };

  const toggleSelectAll = () => {
    setIsSelectAllMode((value) => !value);

    onChange(
      isSelectAllMode
        ? value.filter((value) => !selectableValues.includes(value))
        : allValues
    );
  };

  const handleConfirm = () => {
    if (shouldConfirm && onConfirm) {
      onConfirm(value);
    }
  };

  // Enable or disable Select all checkbox based on the current selection of
  // selectable items.
  useEffect(() => {
    const selectedSelectableValues = value.filter((value) =>
      selectableValues.includes(value)
    );

    if (
      selectedSelectableValues.length < selectableValues.length &&
      isSelectAllMode
    ) {
      setIsSelectAllMode(false);
    } else if (
      selectedSelectableValues.length === selectableValues.length &&
      !isSelectAllMode
    ) {
      setIsSelectAllMode(true);
    }
  }, [isSelectAllMode, selectableValues, value]);

  useOnClickOutside(
    dropdownRef,
    () => {
      if (isOpen && mayBeClosedRef.current) {
        mayBeClosedRef.current = false;
        setIsOpen(false);
      }
    },
    "click"
  );

  return (
    <div className={clsxMerge("form-control relative", className)}>
      {!!label && (
        <label className={clsxMerge("label", labelClassName)}>
          <span
            className={clsxMerge("label-text font-bold", labelContentClassName)}
          >
            {label}
          </span>
        </label>
      )}

      <div
        onClick={handleOpenOptionsMenu}
        className={clsxMerge(
          {
            "select select-bordered": !selectWrapperClassName,
            disabled: isDisabled,
            "border-error-content": hasError,
          },
          isDisabled ? "cursor-not-allowed" : "cursor-pointer",
          selectWrapperClassName
        )}
      >
        <div
          className={clsxMerge(
            "flex items-center truncate",
            selectedValueLabelClassName
          )}
        >
          {getLabel(value)}
        </div>

        <input
          type="hidden"
          name={name}
          value={value.join(",")}
          {...inputProps}
        />
      </div>

      {isOpen && (
        <ul
          ref={dropdownRef}
          className={clsxMerge(
            "absolute top-full z-10 rounded-lg bg-white",
            "mt-2 max-h-[200px] w-[calc(100%+60px)] w-max max-w-[300px]",
            "overflow-y-auto overflow-x-hidden p-2 shadow",
            menuClassName
          )}
        >
          {sortedOptions.length > 0 ? (
            <>
              {canSelectAll && (
                <li
                  className={clsxMerge(
                    "flex w-full items-center",
                    "mb-1 rounded px-2 py-1",
                    "hover:bg-black/3 cursor-pointer",
                    itemClassName
                  )}
                  onClick={toggleSelectAll}
                >
                  <input
                    type={isRadio ? "radio" : "checkbox"}
                    name={isRadio ? "multiselect-ratio-variation" : ""}
                    readOnly
                    className={clsxMerge(
                      "mr-2",
                      {
                        "checkbox checkbox-sm": !isRadio,
                        "radio radio-sm": isRadio,
                      },
                      itemCheckboxClassName
                    )}
                    checked={isSelectAllMode}
                  />
                  <span className="brand-typography-body3">Select all</span>
                </li>
              )}

              {sortedOptions.map((option) => {
                const isOptionDisabledValue = !selectableValues.includes(
                  option.value
                );

                return (
                  <li
                    key={option.value}
                    className={clsxMerge(
                      "flex w-full items-center",
                      "mb-1 rounded px-2 py-1",
                      isOptionDisabledValue
                        ? "cursor-not-allowed opacity-80"
                        : "hover:bg-black/3 cursor-pointer",
                      itemClassName
                    )}
                    onClick={() => handleSelectOption(option)}
                  >
                    <input
                      type={isRadio ? "radio" : "checkbox"}
                      readOnly
                      name={isRadio ? "multiselect-ratio-variation" : ""}
                      className={clsxMerge(
                        "mr-2",
                        {
                          "checkbox checkbox-sm": !isRadio,
                          "radio radio-sm": isRadio,
                        },
                        itemCheckboxClassName
                      )}
                      disabled={isOptionDisabledValue}
                      checked={value.includes(option.value)}
                    />

                    <span
                      title={option.label}
                      className="brand-typography-body3 truncate text-nowrap"
                    >
                      {option.label}
                    </span>
                  </li>
                );
              })}

              {shouldConfirm && (
                <li className="mt-2">
                  <button className="btn-nofill w-full" onClick={handleConfirm}>
                    Apply
                  </button>
                </li>
              )}
            </>
          ) : (
            <span>{noOptionsMessage || NO_OPTIONS_DEFAULT_MESSAGE}</span>
          )}
        </ul>
      )}

      {hasError && (
        <div className="text-error-content mt-1 flex items-center gap-1">
          <ExclamationCircleIcon className="h-5 w-5" />

          <span className="brand-typography-detail2">
            {Array.isArray(errors) ? errors.join(", ") : errors}
          </span>
        </div>
      )}
    </div>
  );
};

// Formik Wrapper
interface FormikMultiselectPropsI<T extends string>
  extends Omit<MultiselectPropsI<T>, "value" | "onChange"> {
  name: string;
}

const Multiselect = <T extends string>({
  name,
  ...props
}: FormikMultiselectPropsI<T>) => (
  <FormikField name={name}>
    {({ field, form }: FieldProps) => (
      <BasicMultiselect
        {...props}
        name={name}
        value={field.value}
        onChange={(value) => form.setFieldValue(name, value)}
        touched={form.touched[name] as boolean}
        errors={form.errors[name] as string}
      />
    )}
  </FormikField>
);

export default Multiselect;
