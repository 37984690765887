import { FC } from "react";

import { clsxMerge, getS3AssetPath } from "shared/lib/helpers";
import { LeadAvatarVolumeVisualizer } from "@/components/modules/calling/cards/lead-card/v2/lead-avatar/volume";

import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

interface LeadCardAvatarPropsI {
  className?: string;
  avatarClassName?: string;
  ripplesClassName?: string;
  contactName?: string;
}

export const LeadCardAvatar: FC<LeadCardAvatarPropsI> = ({
  className,
  avatarClassName,
  ripplesClassName,
  contactName,
}) => {
  const callStatus = useDialerCallStatus();
  const { startAt } = useInCallContext();

  const isCommunicationInProgress =
    callStatus === DIALER_CALL_STATUS.DURING && !!startAt;

  const isCallInProgress = callStatus === DIALER_CALL_STATUS.DURING;

  return (
    <div
      className={clsxMerge(
        "relative flex h-[200px] w-full items-center justify-center",
        className
      )}
    >
      <LeadAvatarVolumeVisualizer
        isCallInProgress={isCommunicationInProgress}
        className={clsxMerge(
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          ripplesClassName
        )}
      />

      <div
        className={clsxMerge(
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          "flex items-center justify-center",
          "transition-all",
          isCallInProgress ? "w-[115px]" : "w-[140px]",
          avatarClassName
        )}
      >
        <img
          alt={contactName}
          className="w-full"
          src={getS3AssetPath("platform/calling/lead-card-caller-avatar.webp")}
        />
        <div
          className={clsxMerge(
            "absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2",
            "hover:cursor-grab",
            "active:cursor-grabbing"
          )}
        />
      </div>
    </div>
  );
};
