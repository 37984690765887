import { clsxMerge } from "shared/lib/helpers";

export const CampaignCardLabels = ({
  labels,
  className,
}: {
  labels?: Array<JSX.Element>;
  className?: string;
}) => (
  <div className={clsxMerge("flex gap-1", className)}>
    {labels?.map((label, i) => (
      <span key={i}>{label}</span>
    ))}
  </div>
);
