import {
  ChevronLeftIcon,
  ScriptIcon,
  PersonasIcon,
  ObjectionsIcon,
} from "shared/ui/icons";

import { DocumentCheckIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";

const btnClass = (isVisible: boolean) =>
  clsxMerge(
    "btn-ghost btn-circle btn mb-2 hover:bg-white/10",
    "transition-[transform] delay-500 duration-500 ease-in-out",
    {
      "scale-0": !isVisible,
      "scale-100": !isVisible,
    }
  );

const SidebarHiddenState = ({
  isVisible = true,
  className,
  onClick = () => {},
}: {
  isVisible?: boolean;
  className?: string;
  onClick?: (index?: number) => void;
}) => {
  return (
    <div
      className={clsxMerge(
        "absolute left-0 top-0",
        "flex w-14 flex-col bg-transparent pt-3 text-white/60 opacity-0 transition-opacity duration-500",
        {
          "opacity-100": isVisible,
          "z-[-1]": !isVisible,
        },
        className
      )}
    >
      <div className={btnClass(isVisible)} onClick={() => onClick()}>
        <ChevronLeftIcon className="h-3" />
      </div>
      <div className={btnClass(isVisible)} onClick={() => onClick(0)}>
        <ScriptIcon className="h-3" />
      </div>
      <div className={btnClass(isVisible)} onClick={() => onClick(1)}>
        <PersonasIcon className="h-3" />
      </div>
      <div className={btnClass(isVisible)} onClick={() => onClick(2)}>
        <ObjectionsIcon className="h-3" />
      </div>
      <div className={btnClass(isVisible)} onClick={() => onClick(3)}>
        <DocumentCheckIcon className="h-4" />
      </div>
    </div>
  );
};

export default SidebarHiddenState;
