import { ReactNode, useState } from "react";

import { clsxMerge } from "shared/lib/helpers";

const LINE_CLAMP_MAP = {
  1: "line-clamp-1",
  2: "line-clamp-2",
  3: "line-clamp-3",
  4: "line-clamp-4",
  5: "line-clamp-5",
  6: "line-clamp-6",
};

const CollapsibleText = ({
  visibleLines,

  disableButton,
  className,
  buttonClassName,
  buttonContainerClassName,

  children,
}: {
  visibleLines: 1 | 2 | 3 | 4 | 5 | 6;

  className?: string;
  buttonContainerClassName?: string;
  buttonClassName?: string;
  disableButton?: boolean;

  children: ReactNode;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const spanRefInitializer = (spanElement: HTMLSpanElement | null) => {
    if (spanElement && spanElement.clientHeight < spanElement.scrollHeight) {
      setIsButtonVisible(true);
    }
  };

  return (
    <div className={"flex flex-col items-start"}>
      <span
        ref={spanRefInitializer}
        className={clsxMerge(
          "relative",
          {
            [LINE_CLAMP_MAP[visibleLines]]: isCollapsed,
            "line-clamp-none": !isCollapsed,
          },
          className
        )}
      >
        {children}
      </span>

      {isButtonVisible && (
        <div className={clsxMerge("w-full", buttonContainerClassName)}>
          <button
            className={clsxMerge(
              "mt-1 font-semibold text-black",
              buttonClassName
            )}
            onClick={disableButton ? undefined : toggleCollapse}
          >
            View {isCollapsed ? "More" : "Less"}
          </button>
        </div>
      )}
    </div>
  );
};

export default CollapsibleText;
