import { glencocoClientAPI } from "@/api/glencoco";
import { CampaignI } from "@/interfaces/campaign";
import { CallAccountI } from "@/interfaces/dialer";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";

export const cancelMeetingAndSetCallback = async ({
  date,
  notes,
  account,
  campaign,
}: {
  date?: string;
  notes?: string;
  account?: CallAccountI;
  campaign?: CampaignI;
}) => {
  const API = glencocoClientAPI();

  const UpdateAccountStatusResponse = await API.updateAccountWithCampaign(
    account?.id as string,
    campaign?.id as string,
    {
      status: ACCOUNT_STATUSES.CALLBACK,
      modify_callback_request: {
        notes: notes || "",
        follow_up_time: date as string,
      },

      cancel_meeting_request: {
        note: notes || "",
      },
    }
  ).catch((e) => e);

  return UpdateAccountStatusResponse;
};

export const cancelMeetingAndBacklog = async ({
  notes,
  account,
  campaign,
}: {
  notes?: string;
  account?: CallAccountI;
  campaign?: CampaignI;
}) => {
  const API = glencocoClientAPI();

  const UpdateAccountStatusResponse = await API.updateAccountWithCampaign(
    account?.id as string,
    campaign?.id as string,
    {
      status: ACCOUNT_STATUSES.BACKLOG,
      cancel_meeting_request: {
        note: notes || "",
      },
    }
  ).catch((e) => e);

  return UpdateAccountStatusResponse;
};
