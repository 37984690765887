import * as Yup from "yup";

export const getEmailsFromString = (emails: string | undefined) =>
  emails
    ? emails
        .split(",")
        .map((email: string) => email.trim())
        .filter((email) => email)
    : [];

const emailSchema = Yup.string().email("Invalid email");

export const yupEmailListSchema = Yup.string().test(
  "test-comma-separated-emails",
  "One or more emails are invalid or the list is empty.",
  // @ts-ignore
  (emailsString: string) => {
    const emails = getEmailsFromString(emailsString);

    return (
      emails.length > 0 &&
      emails.every((email) => emailSchema.isValidSync(email))
    );
  }
);
