import { WEBSOCKET_EVENT_TYPES } from "./constants";
import { socketEventHandlers } from "./event-handlers";

export const websocketEvents = ({
  eventType,
  data,
}: {
  eventType: (typeof WEBSOCKET_EVENT_TYPES)[keyof typeof WEBSOCKET_EVENT_TYPES];
  data: any;
}) => {
  switch (eventType) {
    case WEBSOCKET_EVENT_TYPES.AUTH_SUCCESS:
      socketEventHandlers.websocket.authSuccess();
      break;
    case WEBSOCKET_EVENT_TYPES.GLOBAL_MEETINGS_BOOKED:
      socketEventHandlers.globalToasts.meetings(data);
      break;
    case WEBSOCKET_EVENT_TYPES.GLOBAL_MEETINGS_QUALIFIED:
      socketEventHandlers.globalToasts.meetings(data);
      break;
    case WEBSOCKET_EVENT_TYPES.NOTIFICATIONS_NEW:
      socketEventHandlers.notifications.update();
      break;
    case WEBSOCKET_EVENT_TYPES.CHAT:
      socketEventHandlers.messaging.receiveMessage(data);
      break;
    case WEBSOCKET_EVENT_TYPES.DIALER_LIST_RECEIVE_CALL_STATUS_UPDATE:
      socketEventHandlers.dialer.list.updateCallStatus(data);
      break;
    case WEBSOCKET_EVENT_TYPES.ERROR:
      socketEventHandlers.websocket.handleError(data);
      break;
  }
};
