import { ACCOUNT_ACTIVITY_TYPES } from "shared/lib/constants/account";
import ActivityCallDisposition from "@/components/base/cards/activity-log/call-disposition";
import { AccountHistoryI } from "@/interfaces/accounts";
import ActivityCardCall from "@/components/base/cards/activity-log/call";
import ActivityCardContactAdded from "@/components/base/cards/activity-log/contact-added";
import ActivityCardBookedMeeting from "@/components/base/cards/activity-log/meeting-booked";
import ActivityCardCallbackBooked from "@/components/base/cards/activity-log/callback-booked";
import ActivityCardMeetingOutcome from "@/components/base/cards/activity-log/meeting-outcome";
import ActivityCardSetBacklog from "@/components/base/cards/activity-log/set-backlog";
import ActivityCardUserNote from "@/components/base/cards/activity-log/user-note";
import ActivityCardMeetingCancelled from "@/components/base/cards/activity-log/meeting-cancelled";
import ActivityCardAENote from "@/components/base/cards/activity-log/ae-note";
import ActivityCardAccountDNCOn from "@/components/base/cards/activity-log/account-dnc";
import ActivityCardAccountDNCOff from "@/components/base/cards/activity-log/account-dnc-off";
import ActivityCardMarkTentativeInterestDone from "@/components/base/cards/activity-log/mark-tentative-interest-done";
import ActivityCardArchive from "@/components/base/cards/activity-log/archive";
import ActivityCardDisqualifyLead from "@/components/base/cards/activity-log/disqualify-lead";
import ActivityCardSkipLead from "@/components/base/cards/activity-log/skip-lead";
import ActivityCardTodoFollowupEmail from "@/components/base/cards/activity-log/todo-followup-email";
import ActivityCardMarkTodoDone from "@/components/base/cards/activity-log/mark-todo-done";
import ActivityCardUnarchive from "@/components/base/cards/activity-log/unarchive";
import ActivityCardEmailRequest from "@/components/base/cards/activity-log/email-request";
import ActivityCardContactDeleted from "@/components/base/cards/activity-log/contact-deleted";

const ACTIVITY_CARD_MAP = (activity: AccountHistoryI) => ({
  [ACCOUNT_ACTIVITY_TYPES.CALL_DISPOSITION]: (
    <ActivityCallDisposition activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.BOOKED_MEETING]: activity.meeting_disposition ? (
    <ActivityCardMeetingOutcome activity={activity} />
  ) : (
    <ActivityCardBookedMeeting activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.CALLBACK_BOOKED]: (
    <ActivityCardCallbackBooked activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.CONTACT_ADDED]: (
    <ActivityCardContactAdded activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.HIDE_CONTACT]: (
    <ActivityCardContactDeleted activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.CALLED_BACK]: (
    <ActivityCardCallbackBooked activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.AE_NOTE]: <ActivityCardAENote activity={activity} />,
  [ACCOUNT_ACTIVITY_TYPES.USER_NOTE]: (
    <ActivityCardUserNote activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.ACCOUNT_DNC]: (
    <ActivityCardAccountDNCOn activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.ACCOUNT_UN_DNC]: (
    <ActivityCardAccountDNCOff activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.CALL]: <ActivityCardCall activity={activity} />,
  [ACCOUNT_ACTIVITY_TYPES.SET_BACKLOG]: (
    <ActivityCardSetBacklog activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.CANCEL_MEETING]: (
    <ActivityCardMeetingCancelled activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.MARK_TENTATIVE_INTEREST_DONE]: (
    <ActivityCardMarkTentativeInterestDone activity={activity} />
  ),

  [ACCOUNT_ACTIVITY_TYPES.EMAIL_REQUEST]: (
    <ActivityCardEmailRequest activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.MARK_DONE]: (
    <ActivityCardMarkTodoDone activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.ARCHIVE]: <ActivityCardArchive activity={activity} />,
  [ACCOUNT_ACTIVITY_TYPES.UNARCHIVE]: (
    <ActivityCardUnarchive activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.DISQUALIFY_LEAD]: (
    <ActivityCardDisqualifyLead activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.SKIP_LEAD]: (
    <ActivityCardSkipLead activity={activity} />
  ),
  [ACCOUNT_ACTIVITY_TYPES.TODO_FOLLOWUP_EMAIL]: (
    <ActivityCardTodoFollowupEmail activity={activity} />
  ),

  // TODO - cover all activity items
  [ACCOUNT_ACTIVITY_TYPES.SYSTEM_DQ]: null,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_SENT]: null,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_RECEIVED]: null,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_REQUEST_REJECTED]: null,
});

const Activity = ({ activity }: { activity: AccountHistoryI }) => {
  return <div>{ACTIVITY_CARD_MAP(activity)[activity.activity_type]}</div>;
};

export default Activity;
