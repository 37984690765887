import { useMemo, useRef, useState } from "react";
import _truncate from "lodash/truncate";
import { CallContactMetaI } from "@/interfaces/dialer/contact";
import { CopyToClipboard } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";

const MAX_LABEL_LENGTH = 20;

export const LeadCardMetaDataBlock = ({
  meta,
}: {
  meta?: CallContactMetaI;
}) => {
  const metaValueContainerRef = useRef<HTMLDivElement>(null);
  const [isViewingMore, setIsViewingMore] = useState(false);

  const isContentLengthy = useMemo(() => {
    const contentHeight = metaValueContainerRef.current?.scrollHeight || 0;
    return contentHeight > 50;
  }, [meta]);

  return (
    <div>
      <div className="flex items-center justify-start">
        {/*  */}
        {(meta?.label || "").length > MAX_LABEL_LENGTH ? (
          <div className="tooltip" data-tip={meta?.label}>
            <span className="ae-typography-detail1 w-50 break-all text-base-content/40">
              {_truncate(meta?.label, {
                omission: "...",
              })}
            </span>
          </div>
        ) : (
          <div>
            <span className="ae-typography-detail1 w-50 break-all text-base-content/40">
              {_truncate(meta?.label, {
                length: MAX_LABEL_LENGTH,
                omission: "...",
              })}
            </span>
          </div>
        )}
        {/*  */}
      </div>
      <CopyToClipboard textToCopy={meta?.value} className="leading-4">
        <div
          ref={metaValueContainerRef}
          className={clsxMerge("", {
            "max-h-[100px] overflow-auto": isViewingMore,
            "max-h-[50px] overflow-hidden": !isViewingMore,
          })}
        >
          <span className="ae-typography-body2 w-50 break-all leading-4 text-base-content/60">
            {meta?.value}
          </span>
        </div>
      </CopyToClipboard>

      {isContentLengthy && (
        <div
          className="ae-typography-body2 ae-link mt-1 cursor-pointer"
          onClick={() => setIsViewingMore(!isViewingMore)}
        >
          {isViewingMore ? "View less" : "View more"}
        </div>
      )}
    </div>
  );
};
