import { useCallback, useState } from "react";

import { checkIfClient, safeGetDate } from "shared/lib/helpers";
import { useLongPolling } from "shared/lib/hooks/use-longpolling";
import { useEffectOnce } from "./use-effect-once";

interface UseLiveTimePropsI {
  dateFormat?: string;
  hourFormat?: string;
  timezone?: string;
}

type UseLiveTimeI = (props?: UseLiveTimePropsI) => string;

const DATE_FORMAT = `ddd, MMM D`;
const HOUR_FORMAT = `h:mm A`;

const getCurrentDateTime = (
  dateFormat: string = DATE_FORMAT,
  hourFormat: string = HOUR_FORMAT,
  timezone?: string
) => {
  const date = dateFormat ? safeGetDate("", dateFormat, timezone) : "";
  const hour = hourFormat
    ? `${dateFormat ? " • " : ""}${safeGetDate("", hourFormat, timezone)}`
    : "";

  return `${date}${hour}`;
};

export const useLiveTime: UseLiveTimeI = (
  { dateFormat, hourFormat, timezone } = {
    dateFormat: DATE_FORMAT,
    hourFormat: HOUR_FORMAT,
    timezone: undefined,
  }
) => {
  const [liveTime, setLiveTime] = useState("");

  const updateLiveTime = useCallback(() => {
    if (checkIfClient()) {
      setLiveTime(getCurrentDateTime(dateFormat, hourFormat, timezone));
    }
  }, [dateFormat, hourFormat, timezone]);

  useEffectOnce(() => {
    updateLiveTime();
  });

  useLongPolling(updateLiveTime, 1000, [updateLiveTime]);

  return liveTime;
};
