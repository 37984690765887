import { FC } from "react";
import { Modal } from "shared/ui";
import { CalendarOutlineIcon } from "shared/ui/icons";

export const LIVE_TRANSFER_BMB_MODAL_ID =
  "live-transfer-before-meeting-booked-modal";

interface LiveTransferModalI {
  onConfirm?: () => void;
}

const LiveTransfer_BeforeMeetingBooked_ModalContent = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
} & LiveTransferModalI) => {
  const handleConfirm = () => {
    onClose();
    onConfirm?.();
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center"></div>
      <div className="flex justify-end gap-3">
        <button className="btn-ae-default" onClick={handleConfirm}>
          Book Calendly Event
        </button>
      </div>
    </div>
  );
};

export const LiveTransfer_BeforeMeetingBooked_Modal: FC<LiveTransferModalI> = ({
  onConfirm,
}) => {
  return (
    <Modal
      id={LIVE_TRANSFER_BMB_MODAL_ID}
      titleImage={<CalendarOutlineIcon className="h-5 w-5" />}
      unmountOnClose
      title={`Book Calendly Event Before Live Transfer`}
      description={`Homefix requires you to book the live meeting before accepting the transfer.`}
    >
      {({ handleClose }) => {
        return (
          <LiveTransfer_BeforeMeetingBooked_ModalContent
            onClose={handleClose}
            onConfirm={onConfirm}
          />
        );
      }}
    </Modal>
  );
};
