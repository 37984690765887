export const PITCH_MAX_LENGTH = 200;

export const TRAINING_CARD_STATUSES = {
  NEW: "new",
  PENDING: "pending",
  TRAINING_COMPLETED: "training_completed",
  HIRING: "hiring",
  APPROVED: "approved",
  NOT_ELIGIBLE: "not_eligible",
  RETRY: "retry",
  CONTINUE: "continue",
  PROGRESS: "in_progress",
} as const;
