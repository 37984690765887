import dayjs from "dayjs";

import {
  LastOnlineStatusI,
  UserIsOnlineMapI,
} from "shared/lib/interfaces/chat";
import { IDLE_ONLINE_TIME_MINUTES } from "shared/lib/constants/chat";

export const buildUserIsOnlineMap = (
  onlineStatuses: LastOnlineStatusI[]
): UserIsOnlineMapI =>
  onlineStatuses.reduce((onlineMap, currentEntry) => {
    const onlineAt = currentEntry.last_online_time;
    const isOnline =
      dayjs().diff(dayjs(onlineAt), "minute") < IDLE_ONLINE_TIME_MINUTES;

    onlineMap[currentEntry.id] = {
      isOnline,
      lastOnlineTimeString: isOnline
        ? null
        : `Active ${dayjs(onlineAt).fromNow()}`,
    };

    return onlineMap;
  }, {} as UserIsOnlineMapI);
