import { DIALER_MODES } from "@/constants/dialer";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";

import { DialerDefaultContainer } from "./default";
import { DialerWidgetContainer } from "./widget-container";

const DialerMainContainer = ({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) => {
  const { mode } = useCallingContext();

  return (
    <>
      {DIALER_MODES.WIDGET_MINI_DIALER !== mode && (
        <DialerDefaultContainer className={className}>
          {children}
        </DialerDefaultContainer>
      )}

      {DIALER_MODES.WIDGET_MINI_DIALER === mode && (
        <DialerWidgetContainer className={className}>
          {children}
        </DialerWidgetContainer>
      )}
    </>
  );
};

export default DialerMainContainer;
