import { createIcon } from "./icon-factory";

const EmailAutomationIcon = createIcon(
  <>
    <path
      fill="currentColor"
      d="m23.35 9.454-1.496-.374a10.173 10.173 0 0 0-.823-1.982l.794-1.324a.857.857 0 0 0-.128-1.047l-2.424-2.423a.857.857 0 0 0-1.047-.129l-1.324.794a10.238 10.238 0 0 0-1.982-.823L14.546.65A.857.857 0 0 0 13.714 0h-3.428a.857.857 0 0 0-.832.65L9.08 2.145a10.17 10.17 0 0 0-1.982.823l-1.324-.795a.859.859 0 0 0-1.047.129L2.304 4.726a.856.856 0 0 0-.129 1.047l.794 1.324a10.236 10.236 0 0 0-.823 1.982L.65 9.453a.86.86 0 0 0-.649.833v3.428c0 .394.268.736.65.832l1.496.374c.204.688.479 1.35.823 1.982l-.794 1.324a.857.857 0 0 0 .129 1.047l2.423 2.424a.857.857 0 0 0 1.047.128l1.756-1.054a.857.857 0 1 0-.883-1.47l-1.18.711-1.48-1.478.71-1.183a.856.856 0 0 0-.004-.89 8.526 8.526 0 0 1-1.018-2.452.86.86 0 0 0-.626-.631l-1.335-.333v-2.09l1.336-.335c.31-.077.551-.32.626-.631a8.484 8.484 0 0 1 1.018-2.453.858.858 0 0 0 .004-.889l-.71-1.18 1.478-1.48 1.183.71a.856.856 0 0 0 .89-.004A8.526 8.526 0 0 1 9.99 3.675a.86.86 0 0 0 .631-.626l.333-1.335h2.09l.335 1.336c.077.31.32.551.631.626.866.209 1.692.55 2.453 1.018.273.167.615.17.889.004l1.183-.71 1.477 1.478-.709 1.183a.856.856 0 0 0 .004.89 8.525 8.525 0 0 1 1.018 2.452.86.86 0 0 0 .626.631l1.334.333V12A.857.857 0 1 0 24 12v-1.714a.857.857 0 0 0-.65-.832Z"
    />
    <path
      fill="currentColor"
      d="M12 6.857A5.148 5.148 0 0 1 17.143 12a.857.857 0 1 0 1.714 0A6.865 6.865 0 0 0 12 5.143c-3.668 0-6.662 2.896-6.84 6.52l-.268-.268a.857.857 0 0 0-1.212 1.212l1.714 1.714a.857.857 0 0 0 1.212 0l1.715-1.714a.857.857 0 0 0-1.212-1.212l-.233.232A5.146 5.146 0 0 1 12 6.857Z"
    />
    <path
      fill="currentColor"
      d="M21.429 13.714H11.143a2.575 2.575 0 0 0-2.572 2.572v5.143A2.575 2.575 0 0 0 11.143 24h10.286A2.575 2.575 0 0 0 24 21.43v-5.143a2.575 2.575 0 0 0-2.571-2.572Zm-.688 1.715-3.36 2.24c-.65.434-1.54.434-2.19 0l-3.36-2.24h8.91Zm.688 6.857H11.143a.858.858 0 0 1-.857-.857v-4.97l3.954 2.635c.607.406 1.315.62 2.046.62.73 0 1.439-.214 2.045-.619l3.955-2.637v4.97a.858.858 0 0 1-.857.858Z"
    />
  </>
);

export default EmailAutomationIcon;
