import { useState } from "react";

export const useDisclosure = (isInitiallyOpen = false) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);

    setIsOpen(false);
  };

  const onToggle = () => setIsOpen((prev) => !prev);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};
