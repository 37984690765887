import { FC, KeyboardEvent } from "react";
import toast from "react-hot-toast";

import { clsxMerge } from "shared/lib/helpers";

import { useDialerWidgetContext } from "@/context/dialer-v2/widget";

interface CallerNotesI {
  label?: string;
  className?: string;
  wrapperClassName?: string;
  onSubmitSuccess?: (notes: string) => void;
}

/**
 *
 * Horrible name I know!!!
 *
 * @param note
 * @param notes
 * @returns
 */
export const addStringToStringOnANewLine = (note?: string, notes?: string) =>
  notes ? `${notes}\n\n${note}` : `${note}`;

export const CallerNotes: FC<CallerNotesI> = ({
  label,
  className,
  wrapperClassName,
  onSubmitSuccess = () => {},
}) => {
  /**
   * NOTE
   * Unfinished note is handled in parent component
   */
  const { setNotes, note, setNote } = useDialerWidgetContext();

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();

      handleSubmitNote();
    }
  };

  const handleSubmitNote = () => {
    if (note) {
      setNotes?.((notes) => {
        return addStringToStringOnANewLine(note, notes);
      });

      toast.success(
        "Temporary notes are taken, and all notes will be provided after the call ends."
      );

      handleNoteSuccess();
    }
  };

  const handleNoteSuccess = () => {
    onSubmitSuccess(note as string);
    setNote?.("");
  };

  return (
    <div className={clsxMerge("w-full p-2", wrapperClassName)}>
      {label && (
        <label className="brand-typography-body2 mb-2 block">{label}</label>
      )}
      <textarea
        value={note}
        className={clsxMerge(
          "textarea h-[168px] w-full resize-none rounded-lg bg-white/80",
          className
        )}
        placeholder="Type here to take notes - hit enter to submit"
        onChange={(event) => setNote?.(event.target.value)}
        onKeyDown={handleKeyPress}
        // disabled={isLoading}
      ></textarea>
    </div>
  );
};
