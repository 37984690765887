import {
  EcommerceIcon,
  FinanceIcon,
  HealthcareIcon,
  PaperShieldIcon,
} from "shared/ui/icons";
import { LockClosedIcon } from "@heroicons/react/24/outline";

export const INDUSTRIES_MAP = {
  FINANCE: "Finance",
  SAAS: "SaaS",
  SECURITY: "Security",
  LEGAL: "Legal",
  HEALTHCARE: "HealthCare",
  CYBERSECURITY: "Cybersecurity",
  MANUFACTURING: "Manufacturing",
  EDUCATION: "Education",
  RETAIL_ECOMMERCE: "RetailECommerce",
  FOOD_AND_BEVERAGE: "FoodAndBeverage",
  SUPPLY_CHAIN: "SupplyChain",
  ACCOUNTING: "Accounting",
  CONSTRUCTION: "Construction",
  EVENTS_TICKETING: "EventsTicketing",
  HOME_IMPROVEMENT: "HomeImprovement",
  HOSPITALITY: "Hospitality",
  HIGH_TICKET_AGENCY: "HighTicketAgency",
  INSURANCE: "Insurance",
  LOGISTICS: "Logistics",
  MAR_TECH: "MarTech",
  PROCUREMENT: "Procurement",
  REAL_ESTATE: "RealEstate",
  RECRUITING_STAFFING: "RecruitingStaffing",
  SALES_TECH: "SalesTech",
  SOLAR: "Solar",
  OTHER: "Other",
  NONE: "None",
};

// not all industries have the "id" field (yet).
// Backend has only started using them so far for the
// industries that need to have unique icons associated with them
// on the glencoco open marketplace.
export const INDUSTRIES = [
  {
    id: "e58526b1-dc51-4422-a4dc-52aaf220f5c3",
    value: INDUSTRIES_MAP.FINANCE,
    label: "Finance",
    icon: <FinanceIcon />,
  },
  { value: INDUSTRIES_MAP.SAAS, label: "SaaS" },
  { value: INDUSTRIES_MAP.SECURITY, label: "Security" },
  {
    id: "6e29354a-af81-406a-8841-a5f62872ce01",
    value: INDUSTRIES_MAP.LEGAL,
    label: "Legal",
    icon: <PaperShieldIcon />,
  },
  {
    id: "51c60d81-7ae1-4330-8430-d47f66e9d8b5",
    value: INDUSTRIES_MAP.HEALTHCARE,
    label: "Healthcare",
    icon: <HealthcareIcon />,
  },
  {
    id: "6c9e5178-6685-4ff0-9e31-0da292de1a70",
    value: INDUSTRIES_MAP.CYBERSECURITY,
    label: "Cybersecurity",
    icon: <LockClosedIcon />,
  },
  { value: INDUSTRIES_MAP.MANUFACTURING, label: "Manufacturing" },
  { value: INDUSTRIES_MAP.EDUCATION, label: "Education" },
  {
    id: "7ee291bc-86c4-4034-b7fc-d1ccd8b77bf3",
    value: INDUSTRIES_MAP.RETAIL_ECOMMERCE,
    label: "Retail/eCommerce",
    icon: <EcommerceIcon />,
  },
  { value: INDUSTRIES_MAP.FOOD_AND_BEVERAGE, label: "Food & Beverage" },
  { value: INDUSTRIES_MAP.SUPPLY_CHAIN, label: "Supply Chain/Logistics" },

  { value: INDUSTRIES_MAP.ACCOUNTING, label: "Accounting" },
  { value: INDUSTRIES_MAP.EVENTS_TICKETING, label: "Events Ticketing" },
  { value: INDUSTRIES_MAP.HOME_IMPROVEMENT, label: "Home Improvement" },
  { value: INDUSTRIES_MAP.HOSPITALITY, label: "Hospitality" },
  { value: INDUSTRIES_MAP.HIGH_TICKET_AGENCY, label: "High Ticket Agency" },
  { value: INDUSTRIES_MAP.INSURANCE, label: "Insurance" },
  { value: INDUSTRIES_MAP.LOGISTICS, label: "Logistics" },
  { value: INDUSTRIES_MAP.MAR_TECH, label: "MarTech" },
  { value: INDUSTRIES_MAP.PROCUREMENT, label: "Procurement" },
  { value: INDUSTRIES_MAP.REAL_ESTATE, label: "Real Estate" },
  { value: INDUSTRIES_MAP.RECRUITING_STAFFING, label: "Recruiting Staffing" },
  { value: INDUSTRIES_MAP.SALES_TECH, label: "SalesTech" },
  { value: INDUSTRIES_MAP.SOLAR, label: "Solar" },

  { value: INDUSTRIES_MAP.OTHER, label: "Other" },
  { value: INDUSTRIES_MAP.NONE, label: "None of the above" },
];
