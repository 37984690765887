import { createIcon } from "./icon-factory";

const ActiveSalesRepIcon = createIcon(
  <>
    <path
      fill="currentColor"
      d="M15.227 8.969a3.385 3.385 0 1 1-6.769 0 3.385 3.385 0 0 1 6.77 0Z"
    />
    <path
      fill="currentColor"
      d="m17.223 9.524-.632 2.552a.66.66 0 0 1-.278.405c.253.48.455.934.581 1.313-.353-.05-.758-.152-1.111-.227-.606-.126-1.187-.278-1.743-.278H9.796c-1.112 0-1.971.556-2.553 1.642l-.126.253c-.581 1.036-1.137 2.147-1.364 2.955-.227.859-.707 3.285-.732 3.41-.126.709.329 1.39 1.01 1.517a1.295 1.295 0 0 0 1.516-1.01c0-.027.48-2.451.682-3.235.076-.303.303-.783.531-1.263l-.176 5.608c-.026.455.353.834.808.834h4.774c.455 0 .834-.379.808-.834l-.202-7.15c.126.026.278.05.405.101.934.227 1.995.455 2.981.253a1.813 1.813 0 0 0 1.289-1.01c.581-1.314-.505-3.563-1.516-5.23a1.052 1.052 0 0 0-.708-.606Z"
    />
    <path
      fill="currentColor"
      d="m15.38 12.076.454.101c.152.026.303-.05.33-.202l.958-3.89c.026-.152-.05-.303-.202-.329l-.455-.1c-.152-.026-.303.05-.329.202l-.96 3.89c-.024.126.077.278.204.328ZM18.613 0h-3.385c-.834 0-1.542.682-1.542 1.541v2.022c0 .833.682 1.541 1.542 1.541h.505c0 .278-.05.657-.202 1.06-.076.178-.026.354.126.48.076.077.177.101.278.101a.484.484 0 0 0 .203-.05c.732-.405 1.364-1.137 1.692-1.566h.784c.834 0 1.541-.682 1.541-1.542v-2.02C20.13.681 19.447 0 18.613 0Zm-.278 3.613H15.48a.335.335 0 0 1-.328-.329c0-.176.152-.328.328-.328h2.855c.177 0 .329.152.329.328a.335.335 0 0 1-.329.329Zm0-1.542H15.48a.335.335 0 0 1-.328-.328c0-.177.152-.329.328-.329h2.855c.177 0 .329.152.329.329a.334.334 0 0 1-.329.328Z"
    />
  </>
);

export default ActiveSalesRepIcon;
