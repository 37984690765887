import { ReactElement } from "react-markdown/lib/react-markdown";

export const NotificationAuditionNote = ({
  text,
  author,
}: {
  text?: string | ReactElement;
  author?: string;
}) =>
  text ? (
    <>
      <span>“{text}”</span>
      {author && (
        <span className="text-black/60"> ~ {author}, Account Executive</span>
      )}
    </>
  ) : undefined;
