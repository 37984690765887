import clsx from "clsx";

export interface RadioButtonDetailedI {
  name: string;
  value: string;
  label: string;
  description?: string;
  badgeLabel?: string;
  inputProps?: any;
  containerClassName?: string;
}

const DefaultInput = (props: any) => <input {...props} />;

const RadioButtonDetailed =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    value,
    label,
    description,
    badgeLabel,
    inputProps = {},
    containerClassName,
  }: RadioButtonDetailedI) => {
    return (
      <>
        <div
          className={clsx(
            "form-control rounded-lg border p-4",
            containerClassName
          )}
        >
          <label className="label cursor-pointer justify-start p-0">
            <Field
              type="radio"
              name={name}
              value={value}
              className={clsx(
                "radio h-[14px] w-[14px]",
                "transition-[color,border,box-shadow] duration-300",
                "checked:border-[#014DFE] checked:bg-white checked:shadow-[0_0_0_3px_#014DFE_inset,0_0_0_3px_#014DFE_inset]"
              )}
              {...inputProps}
            />
            <div className="ml-4">
              <div className="ae-typography-h3 mb-2">
                {label}
                <span className="ae-typography-detail2 text-base-content/60 ml-3">
                  {badgeLabel}
                </span>
              </div>

              <div className="ae-typography-body1">{description}</div>
            </div>
          </label>
        </div>
      </>
    );
  };

export default RadioButtonDetailed;
