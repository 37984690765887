import { usePersistedCatchupsData } from "@/components/modules/calling/hooks/use-persisted-catchups-data";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { clsxMerge } from "shared/lib/helpers";

export const LeadLabels = () => {
  const { metadata } = usePersistedCatchupsData();

  const { isMissedCall } = metadata || {};

  return (
    <div className="absolute left-5 top-5">
      {isMissedCall && (
        <div
          className={clsxMerge(
            "flex w-fit items-center rounded px-2 py-1",
            "brand-typography-body2 gap-1 bg-error-content text-white"
          )}
        >
          <XMarkIcon className="w-4" />
          Missed Call
        </div>
      )}
    </div>
  );
};
