// NOTE: workaround for losing new lines from textarea
// Solution find repeatable /n
// should be at least 2 -> \n\n
// transforming to \n\n&nbsp;\n\n
// for any subsequent newline repeating transformation for the step 1
// TODO needs improvement
export const transformNewLines = (markdown: string) =>
  markdown
    .replace(/\n\n\n/g, "\n\n&nbsp;\n\n\n")
    .replace(/\n\n/g, "\n\n&nbsp;\n\n");
