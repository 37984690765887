import { useState } from "react";
import clsx from "clsx";

import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import { PhoneIcon } from "shared/ui/icons";

import Calling from "@/helpers/calling";

export const CallAgainButton = ({ className }: { className?: string }) => {
  const context = useCallingContext();
  const inCallContext = useInCallContext();
  const { contact } = inCallContext;

  const [isCallLoading, setIsCallLoading] = useState(false);

  const onCallHandler = async () => {
    setIsCallLoading(true);
    inCallContext.setStartAt("");
    inCallContext.setEndAt("");

    await Calling.startCall({
      context,
      inCallContext,
      contactId: contact?.id,
    });

    setIsCallLoading(false);
  };

  return (
    <button
      className={clsx(
        "btn-ae-default",
        {
          "disabled: loading": isCallLoading,
        },
        className
      )}
      onClick={onCallHandler}
    >
      <PhoneIcon className="mr-3 h-4" /> Call Again
    </button>
  );
};
