import {
  NOTIFICATION_ACTION_TYPES,
  createNotificationAction,
} from "shared/ui/notifications/notification-generic/base-parts/actions";
import { NotificationBase } from "@/modules/notifications/notification/base";

import { NotificationI } from "@/interfaces/notification";
import { NotificationAuditionNote } from "@/modules/notifications/notification/note/audition";
import Router from "@/helpers/router";

export const NotificationAuditionRetry = (
  props: NotificationI & { className?: string }
) => {
  const {
    id,
    campaign_id,
    campaign_name,
    is_seen,
    created_at,
    audition_notes,
    audition_approver_name,

    className,
  } = props;

  return (
    <NotificationBase
      id={id}
      isUnread={!is_seen}
      className={className}
      avatar={props}
      description={{
        text: (
          <>
            <b>{campaign_name}</b> asked you to retry your audition
          </>
        ),
        date: created_at,
        campaignName: campaign_name,
      }}
      message={{
        text: (
          <NotificationAuditionNote
            text={audition_notes}
            author={audition_approver_name}
          />
        ),
      }}
      actions={[
        createNotificationAction(
          NOTIFICATION_ACTION_TYPES.PRIMARY,
          "Retry audition",
          () => {
            window.location.href = Router.beginAssessment(campaign_id);
          }
        ),
        createNotificationAction(
          NOTIFICATION_ACTION_TYPES.SECONDARY,
          "Review training",
          () => {
            window.location.href = Router.training(campaign_id);
          }
        ),
      ]}
    />
  );
};
