import { FC } from "react";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardAEInfo } from "shared/ui/activity-log/cards-v2/base/ae-info";
import { UserMinusIcon } from "@heroicons/react/24/outline";

const ACTIVITY_TYPE_CONFIG = {
  icon: UserMinusIcon,
  title: "Customer Disqualify Leads",
};

const HistoryCardCustomerDisqualifyLeadV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardAEInfo aeName={activity.ae_name || "N/A"} />
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardCallNotes isCustomerNotes notes={activity.notes} />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardCustomerDisqualifyLeadV2;
