import { MutableRefObject, useRef, useState } from "react";
import clsx from "clsx";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

import LeadDescription from "@/components/modules/calling/cards/lead-card/v1/lead-description";
import { ModalAccountDataI } from "@/context/accounts";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import {
  GetAccountCalendlyStartTimeResponseI,
  glencocoClientAPI,
} from "@/api/glencoco";
import { useAccountsContext } from "@/hooks/use-accounts-context";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const ACCOUNT_CALENDLY_MODAL_ID = "account_calendly_modal";

interface CalendlyModalI {
  calendlyUri: string;
  accountName: string;
  contact?: ModalAccountDataI["contact"];
  modalId?: string;
  onSuccess: (event: CalendlyEventI) => void;
  onClose?: () => void;
}

const AccountCalendlyModal = ({
  calendlyUri,
  accountName,
  contact,
  modalId = ACCOUNT_CALENDLY_MODAL_ID,
  onSuccess = () => {},
  onClose = () => {},
}: CalendlyModalI) => {
  const checkboxRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { onChangeStatusCancelRef } = useAccountsContext();
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    if (onChangeStatusCancelRef.current) {
      onChangeStatusCancelRef.current();
      onChangeStatusCancelRef.current = null;
    }

    onClose();
  };

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      (async () => {
        const API = glencocoClientAPI();

        const GetStartTimeReponse = await API.getAccountCalendlyStartTime(
          e.data.payload.event.uri
        ).catch((e) => e);

        if (GetStartTimeReponse.status === 200) {
          const data =
            GetStartTimeReponse.data as GetAccountCalendlyStartTimeResponseI;

          onSuccess({
            id: e.data.payload.event.uri,
            start_time: data.start_time,
          });
        }
      })();
    },
  });

  const onToggleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  const onFailedBooking = () => {
    toast.error("Failed to book a meeting due to internal error.");
  };

  return (
    <>
      <div className="w-full">
        <input
          type="checkbox"
          id={modalId}
          className="modal-toggle"
          ref={checkboxRef}
          onClick={(event) => {
            onToggleHandler((event.target as HTMLInputElement)?.checked);
          }}
        />

        <div className="calendly-modal modal w-full">
          {isVisible && calendlyUri ? (
            <div className="relative z-[1] h-full w-full max-w-[1000px] overflow-auto rounded-md bg-transparent shadow-none scrollbar-none">
              <label
                htmlFor={modalId}
                className="btn btn-circle btn-ghost btn-sm absolute left-6 top-4 text-[20px] text-white/90"
                onClick={() => handleClose()}
              >
                ✕
              </label>

              <InlineWidget
                url={calendlyUri}
                prefill={{
                  name: contact?.name,
                  email: contact?.email,
                }}
                styles={{
                  width: "100%",
                  height: "750px",
                  zIndex: "2",
                }}
              />
            </div>
          ) : (
            <div className="modal-box relative min-w-[520px] rounded-md p-6">
              <h3 className="ae-typography-h3 mb-2">
                Unfortunately we failed to provide you with Calendar link
              </h3>

              <p className="ae-typography-detail1 mb-6 text-base-content/60">
                Please{" "}
                <a
                  href="mailto:help@glencoco.io"
                  target="_blank"
                  rel="noreferrer"
                  className="ae-link"
                >
                  contact Glencoco
                </a>{" "}
                with the following data
              </p>

              <div className={clsx("rounded-lg bg-black/5 px-3 py-6")}>
                <div className="mb-8">
                  <label className="ae-typography-h2 mb-1 flex items-center">
                    <span className="mr-2 flex items-center">
                      {contact?.name}
                    </span>
                  </label>

                  <p className="ae-typography-body3 text-black/60">
                    <span>
                      {contact?.title} @ {accountName}
                    </span>
                  </p>
                </div>

                <LeadDescription />
              </div>

              <div className="mt-8 flex justify-end">
                <label
                  htmlFor={modalId}
                  className="btn-ae-default"
                  onClick={onFailedBooking}
                >
                  Understood
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountCalendlyModal;
