import { FC } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardEmailDetails } from "shared/ui/activity-log/cards-v2/base/email-details";
import { HistoryCardEmailOpens } from "shared/ui/activity-log/cards-v2/base/email-opens";

const ACTIVITY_TYPE_CONFIG = {
  icon: EnvelopeIcon,
  title: "Email received back",
  isHot: true,
};

const HistoryCardEmailReceivedV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
  onActionClick = () => {},
}) => {
  const emailMetadata = activity.email_metadata;

  return (
    <AccountHistoryCard createdAt={activity.created_at}>
      <div className="flex flex-col gap-4">
        <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />

        {emailMetadata && (
          <HistoryCardEmailOpens emailOpens={emailMetadata.email_opens} />
        )}

        <HistoryCardContactInfo contact={activity.contact} />
      </div>

      {emailMetadata && (
        <div className="@xl:flex-row flex flex-col gap-4">
          <HistoryCardEmailDetails
            isSenderHighlighted
            emailMetadata={emailMetadata}
          />

          <button className="btn-nofill" onClick={onActionClick}>
            Reply
          </button>
        </div>
      )}
    </AccountHistoryCard>
  );
};

export default HistoryCardEmailReceivedV2;
