import ContainerDimensions from "react-container-dimensions";

export const BackgroundBlur = () => (
  <div className="absolute h-full w-full overflow-hidden rounded-[10px] backdrop-blur-sm">
    <div className="relative h-full w-full bg-white opacity-20" />
  </div>
);

export const SVGBorderElement = () => {
  return (
    <ContainerDimensions>
      {({ width }) => (
        <svg width="100%" height="100%" className="absolute left-0 top-0">
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#FFFFFF33" opacity="0" />
              <stop offset="100%" stopColor="white" />
            </linearGradient>
          </defs>

          <rect
            x={0.5}
            y={0.5}
            fill="none"
            width={width - 1}
            height="98%"
            stroke="url(#linear)"
            strokeWidth="1px"
            rx="10px"
            ry="10px"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </ContainerDimensions>
  );
};
