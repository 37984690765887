import { clsxMerge } from "shared/lib/helpers";
import { ChevronRightIcon } from "shared/ui/icons";

export const ResourcesToggleButton = ({
  isActive,
  onClick,
}: {
  isActive?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={clsxMerge(
        "rounded-l-lg",
        "flex items-center justify-center ",
        "absolute lg:relative",
        "z-10",
        "top-0 min-[859px]:max-lg:top-12 lg:top-20",

        "transition-[width,opacity] duration-300",
        "h-10 min-w-10 max-w-10",
        "lg:opacity-100",

        "bg-white lg:bg-black/80 lg:backdrop-blur-sm",

        {
          "right-0": isActive,
          "left:0 relative": !isActive,
        }
      )}
    >
      <ChevronRightIcon
        className={clsxMerge(
          "w-2  text-black lg:text-white",
          "transition-transform duration-500",
          {
            "rotate-180": !isActive,
            "rotate-0": isActive,
          }
        )}
      />
    </button>
  );
};
