import { CampaignI } from "@/interfaces/campaign";
import { AccountI } from "shared/lib/interfaces/account";

const MaxedDialerErrorState = ({
  onReload = () => {},
  onClose = () => {},
}: {
  onReload?: () => void;
  onClose?: () => void;
}) => {
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-2 bg-white/10 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="w-[326px] rounded-lg bg-white px-4 py-6 text-center"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="mb-4 typography-body-4-bold">
          We weren't able to load this data
        </p>
        <p className="mb-5 text-black/60 typography-body-4">
          Unfortunately, the info you requested wasn't able to load. Select the
          reload button below to try the request again.
        </p>

        <button
          className="btn-ae-default"
          onClick={(e) => {
            e.stopPropagation();
            onReload();
          }}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

const MaxedDialerEmptyState = ({
  campaign,
  account,
}: {
  campaign?: CampaignI;
  account?: AccountI;
}) => {
  return (
    <div className="flex h-full min-h-[120px] w-full flex-col items-center justify-center rounded-xl bg-[#F4F4F4]">
      <span className="text-center text-[#666] typography-body-4-bold">
        {!campaign && "Missing campaign data"}
        {!account && "Missing account data"}
      </span>
    </div>
  );
};

export const MaxedDialerDataStates = {
  ErrorState: MaxedDialerErrorState,
  EmptyState: MaxedDialerEmptyState,
};
