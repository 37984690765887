import { SALES_EXPERIENCE_OPTIONS } from "shared/lib/constants/user";
import { ResumeI } from "./interfaces/resume";

/**
 * Backend sends us `years_of_experience` value from 1-5,
 * which are keys that each map to certain years of experience
 * @param value - key received by backend, usually called `years_of_experience`
 * @returns the actual years of experience string that the `value` key maps to
 */
export const getYearsExperienceByValue = (value?: number) => {
  return SALES_EXPERIENCE_OPTIONS.find(
    (val) => val.value === value
  )?.label?.toLocaleLowerCase();
};

export const getProfileURL = (username: string) => {
  return `glencoco.com/sr/${username}`;
};

export const getPublicResumeName = (resume: ResumeI) =>
  `${resume?.first_name} ${
    resume?.last_name ? resume.last_name.charAt(0) + "." : ""
  }`;
