// these are the types of events that can exist inside the activity log.
// they describe the outcome of some call that occured in the past.
export const EVENTS = {
  NO_SHOW: "no_show",
  GATEKEEPER_REJECT: "gatekeeper_reject",
  PITCH_REJECT: "pitch_reject",
  INTRO_REJECT: "intro_reject",
  DQ_TIMING: "dq_timing",
  DQ_AUTHORITY: "dq_authority",
  NOT_QUALIFIED_NEED: "not_qualified_need",
  NOT_QUALIFIED_BUDGET: "not_qualified_budget",
  NOT_QUALIFIED_TIMING: "not_qualified_timing",
  NOT_QUALIFIED_AUTHORITY: "not_qualified_authority",
  MEETING_BOOKED: "meeting_booked",
  CALLBACK_BOOKED: "callback_booked",
  CONTACT_ADDED: "contact_added",
  MORE_MATERIALS: "more_materials",
  VOICEMAIL_LEFT: "voicemail_left",
  DISPOSITION_OTHER: "disposition_other",
  NOTES: "notes",
  AE_NOTE: "ae_note",
  DO_NOT_CALL: "do_not_call",
  BAD_INFO: "bad_info",
  BAD_PHONE_NUMBER: "bad_phone_number",
  PERSON_DATA_WRONG: "person_data_wrong", //TODO delete this if it's not being used
  DATA_WRONG: "data_wrong",
};
