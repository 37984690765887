import { useMemo } from "react";

import { Avatar } from "shared/ui";

import { AccountExecutiveI } from "@/interfaces/accounts";
// import { buildUserIsOnlineMap } from "shared/lib/helpers/chat";

const AccountMessagesEmptyState = ({
  accountExecutive,
}: {
  accountExecutive?: AccountExecutiveI | null;
}) => {
  // const [isAeOnline, setIsAeOnline] = useState(false);

  const aeFullName = useMemo(
    () =>
      accountExecutive?.first_name
        ? `${accountExecutive?.first_name} ${accountExecutive?.last_name}`
        : "Account Executive",
    [accountExecutive]
  );

  // const fetchOnlineStatuses = async () => {
  //   if (accountExecutive?.id) {
  //     const API = glencocoClientAPI();

  //     const resp = await API.getChatInboxOnlineStatuses([
  //       accountExecutive.id,
  //     ]).catch(() => ({
  //       status: null,
  //       data: null,
  //     }));

  //     if (resp.status === 200) {
  //       // const data = resp.data.online_statuses;
  //       // const onlineMap = buildUserIsOnlineMap(data);
  //       // TODO: waiting for backend fix to finish implementing online status.

  //       setIsAeOnline(false);
  //     } else {
  //       console.log("Failed to get AE's online statuses.");
  //     }
  //   }
  // };

  // useLongPolling(fetchOnlineStatuses, CHATS_ONLINE_STATUS_REFRESH_INTERVAL);

  return (
    <div className="absolute top-0 rounded-lg bg-black/5 p-4">
      <p className="b-typography-body3 mb-2">
        Start a conversation with your Account Executive
      </p>

      <div className="flex items-center gap-3">
        <Avatar
          className="h-[34px] w-[34px]"
          placeholderText={aeFullName}
          showActiveStatus
          isOnline={false}
        />
        <p className="b-typography-h5">{aeFullName}</p>
      </div>
    </div>
  );
};

export default AccountMessagesEmptyState;
