import { MetaI } from "@/interfaces/meta";
import { NextSeoProps } from "next-seo";

export const defaultMeta: MetaI = {
  title: "Glencoco",
  description:
    "A turnkey platform for both cold callers to learn about companies, set meetings and get paid, and for companies to generate low-overhead, measurable, cost-effective top-funnel pipeline",
  canonical: process.env.NEXT_PUBLIC_DOMAIN as string,
};

export const basicSEO = (props: MetaI): NextSeoProps => {
  return {
    title: props.title || defaultMeta.title,
    description: props.description || defaultMeta.description,
    canonical: props.canonical
      ? `${process.env.NEXT_PUBLIC_DOMAIN}${props.canonical}`
      : defaultMeta.canonical,
  };
};
