import { EventHandler, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { glencocoClientAPI } from "@/api/glencoco";
import {
  MessagesWebsocketMessageDataI,
  MessagingChatMember,
  MessagingChatMessageI,
} from "@/interfaces/messaging";

import { getSenderNameFromSenderId } from "@/helpers/messaging";
import { messagingSocketActions } from "lib/websockets/messaging/socket-actions";

interface UseWSMessagingParamsI {
  accountUserDispositionId: string;
}

export const useWSMessaging = ({
  accountUserDispositionId,
}: UseWSMessagingParamsI) => {
  const [audId, setAudId] = useState<string>(accountUserDispositionId);
  useEffect(
    () => setAudId(accountUserDispositionId),
    [accountUserDispositionId]
  );

  const [isLoading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessagingChatMessageI[]>();
  const [isConnected, setIsConnected] = useState<boolean>();
  const enterChatTimer = useRef<NodeJS.Timer>();

  const [chatMembersMap, setChatMembersMap] = useState<
    Record<string, MessagingChatMember>
  >({});

  const [isMore, setIsMore] = useState(true);
  const [nextToken, setNextToken] = useState<string>();

  const enterChat = () => {
    clearInterval(enterChatTimer.current);

    return new Promise((resolve) => {
      if (!audId) {
        console.log("Failed to enter chat - missing AUD id..");
        resolve(false);
      }

      // need to try entering chat on an interval because on the first attempt the websocket may still be in CONNECTING state.
      // need to keep trying until the websocket is READY and chat is finally entered.
      enterChatTimer.current = setInterval(() => {
        const isConnected = messagingSocketActions.enterChat(audId);

        if (isConnected) {
          clearInterval(enterChatTimer.current);
          setIsConnected(true);

          console.log("Successfully entered chat...");
          resolve(true);
        }
      }, 1000);
    });
  };

  const handleDisconnect = () => {
    setIsConnected(false);

    // any ongoing attempts to enter a chat should be cancelled because the websocket is closed
    clearInterval(enterChatTimer.current);
  };

  const handleWebsocketReady = async () => {
    // enter chat with newly authenticated socket connection
    const enterChatSuccess = await enterChat();

    if (enterChatSuccess) {
      setIsConnected(true);
    }
  };

  const fetchOlderMessages = async () => {
    if (!audId) {
      toast.error("Failed to fetch chat history. Missing id.");
      return;
    }

    const API = glencocoClientAPI();
    setLoading(true);

    const isGettingNextPage = !!nextToken;

    const resp = await API.getMessagingChatHistory(audId, {
      next_token: nextToken,
    }).catch(() => ({
      status: null,
      data: null,
    }));

    if (resp.status === 200) {
      const chatMembers = [
        ...(resp.data.callers_in_chat || []),
        ...(resp.data.customers_in_chat || []),
      ];

      const formattedMessages = resp?.data?.chat_history?.map((msg) => {
        const sender = getSenderNameFromSenderId(chatMembers, msg.sender_id);

        const formattedMsg: MessagingChatMessageI = {
          sender_id: sender?.id as string,
          first_name: sender?.first_name as string,
          last_name: sender?.last_name as string,
          profile_pic_url: sender?.profile_pic_url,
          message: msg.message as string,
          timestamp: msg.timestamp as string,
          is_seen: !!msg.is_seen,
        };

        return formattedMsg;
      });

      // reverse the message because we want the most recent ones at the bottom of the chat
      formattedMessages?.reverse();

      if (isGettingNextPage) {
        setMessages((prevMessages) => [
          ...(formattedMessages || []),
          ...(prevMessages || []),
        ]);
      } else {
        setMessages(formattedMessages);
      }

      const updatedChatMembersMap = chatMembersMap;
      chatMembers.map((member) => (updatedChatMembersMap[member.id] = member));

      setChatMembersMap({ ...updatedChatMembersMap });
      setIsMore(!!resp?.data?.next_token);
      setNextToken(resp?.data?.next_token);
      setLoading(false);

      return true;
    } else {
      toast.error("Failed to fetch older messages");
      setLoading(false);

      return false;
    }
  };

  const handleIncomingMessage = (
    data: CustomEvent<MessagesWebsocketMessageDataI>
  ) => {
    const messageData = data.detail;
    const messengerId = messageData.messenger_id as string;

    const newMessage: MessagingChatMessageI = {
      ...messageData,
      sender_id: messengerId,
      timestamp: new Date().toString(),
      is_seen: true,
      profile_pic_url: chatMembersMap[messengerId]?.profile_pic_url,
    };

    setMessages((prevMessages) => [...(prevMessages || []), newMessage]);
  };

  useEffect(() => {
    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.MESSAGING.MESSAGE_RECEIVE,
      handleIncomingMessage as EventHandler<any>
    );

    // cleanup
    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.MESSAGING.MESSAGE_RECEIVE,
        handleIncomingMessage as EventHandler<any>
      );
    };
  }, [chatMembersMap]);

  useEffect(() => {
    window.addEventListener("offline", handleDisconnect);

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
      handleWebsocketReady
    );

    return () => {
      clearInterval(enterChatTimer.current);

      window.removeEventListener("offline", handleDisconnect);

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
        handleWebsocketReady
      );
    };
  }, []);

  return {
    messages,
    chatMembersMap,
    enterChat,
    isConnected,
    fetchOlderMessages,
    isLoading,
    isMore,
  };
};
