import { getCookies, getCookie, setCookie, deleteCookie } from "cookies-next";
import { TmpCookiesObj, CookieValueTypes } from "cookies-next/lib/types";

export const ServerSideCookies = (req: any, res: any) => ({
  getCookie: (key: string, options?: any) =>
    getCookie(key, { req, res, ...options }),
  getCookies: (...options: any) => getCookies({ req, res, ...options }),
  setCookie: (key: string, value: string, options?: any) =>
    setCookie(key, value, { req, res, ...options }),
  deleteCookie: (key: string, options?: any) =>
    deleteCookie(key, { req, res, ...options }),
});

export interface CookieMessageI {
  type?: "success" | "error" | "warning" | "info";
  text: string;
  description?: string;
}

export const createCookieMessage = ({
  type,
  text,
  description,
}: CookieMessageI) => ({
  type,
  text,
  description,
});

interface CookiesI {
  getUser: () => object;
  setUser: (obj: object) => void;
  clearUser: () => void;
  setOnLaunchMessages: (messages: Array<CookieMessageI>) => void;
  getOnLaunchMessages: () => Array<CookieMessageI>;
  addOnLaunchMessage: (message: CookieMessageI) => void;
  getCookie: (key: string, options?: any) => CookieValueTypes;
  getCookies: (...options: any) => TmpCookiesObj;
  setCookie: (key: string, value: string, options?: any) => void;
  deleteCookie: (key: string, options?: any) => void;
}

export const COOKIE_MESSAGES_KEY = "cmessages";
export const USER_BASE_KEY = "glccSession";
export const GLENCOCO_COOKIE_TOKEN = "glccToken";

const Cookies = (ctx?: { req: any; res: any }): CookiesI => {
  let cookies: any;

  if (ctx && ctx.req && ctx.res) {
    cookies = ServerSideCookies(ctx.req, ctx.res);
  } else {
    cookies = { getCookies, getCookie, setCookie, deleteCookie };
  }

  const methods = {
    getUser: () => {
      let debugKey = "";
      let debugValue = "";

      try {
        console.log(`Try get User from cookies`);
        debugKey = `${USER_BASE_KEY}_keys`;
        debugValue = cookies.getCookie(`${USER_BASE_KEY}_keys`);

        const cookieKeys = cookies.getCookie(`${USER_BASE_KEY}_keys`);
        if (!cookieKeys) {
          console.log(`Failed to get User from cookies`);
          return null;
        }

        const keys = JSON.parse(cookieKeys);
        if (!keys) {
          console.log(`Failed to get User from cookies`);
          return null;
        }

        if (keys?.length && keys?.length >= 20) {
          console.error(`Cookie keys count ${keys?.length}`);
        }

        let user = {} as any;

        keys.forEach((key: string) => {
          debugKey = `${USER_BASE_KEY}_${key}`;
          debugValue = cookies.getCookie(`${USER_BASE_KEY}_${key}`);

          const objProps = JSON.parse(
            cookies.getCookie(`${USER_BASE_KEY}_${key}`)
          );

          user = {
            ...user,
            ...objProps,
          };
        });

        console.log(`{id: ${user?.id}, email: ${user?.email}, ...props }`);

        return user;
      } catch (e) {
        //TODO Sentr sentry alert and submit data
        console.log(`Tried to parse ${debugKey}`);
        console.log(debugValue);
        console.log(e);

        return null;
      }
    },
    setUser: (obj: any) => {
      const HOURS_10 = 60 * 60 * 10;

      const setCookieFor10Hours = (key: string, txt: string, time?: number) => {
        cookies.setCookie(key, txt, {
          maxAge: time || HOURS_10,
        });
      };

      //Set up keys for each User prop including all key values
      const keys = Object.keys(obj);

      setCookieFor10Hours(
        `${USER_BASE_KEY}_keys`,
        JSON.stringify(keys),
        HOURS_10 + 10
      );
      keys.forEach((key) =>
        setCookieFor10Hours(
          `${USER_BASE_KEY}_${key}`,
          JSON.stringify({ [key]: obj[key] })
        )
      );
    },

    clearUser: () => {
      const safeDeleteCookie = (key: string) => {
        try {
          cookies.deleteCookie(key);
        } catch (e) {
          console.log(`Tried to clear cookie ${key}`);
          console.log(e);
        }
      };

      // Keep for now
      safeDeleteCookie(USER_BASE_KEY);

      try {
        const keysCookie = cookies.getCookie(`${USER_BASE_KEY}_keys`);
        if (!keysCookie) return;

        const keys = JSON.parse(keysCookie);

        keys.forEach((key: string) => {
          safeDeleteCookie(`${USER_BASE_KEY}_${key}`);
        });

        safeDeleteCookie(`${USER_BASE_KEY}_keys`);
      } catch (e) {
        console.log(`Failed to parse cookies for`);
        console.log(
          `${USER_BASE_KEY}_keys: ${cookies.getCookie(`${USER_BASE_KEY}_keys`)}`
        );
        console.log(e);
      }
    },

    setOnLaunchMessages: (messages: Array<CookieMessageI>) => {
      cookies.setCookie(COOKIE_MESSAGES_KEY, JSON.stringify(messages));
    },

    getOnLaunchMessages: () => {
      let data;

      try {
        data = JSON.parse(cookies.getCookie(COOKIE_MESSAGES_KEY));
      } catch {
        data = [];
      }

      return data;
    },

    addOnLaunchMessage: (message: CookieMessageI) => {
      const messages = methods.getOnLaunchMessages() || [];

      messages.push(message);

      methods.setOnLaunchMessages(messages);
    },
  };

  return { ...cookies, ...methods } as CookiesI;
};

export default Cookies;
