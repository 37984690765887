import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { LOG_CATEGORIES } from "@/constants/logs";
import { getWebsocket } from "@/helpers/websockets";
import { useWebsocketConnection } from "@/hooks/use-websocket-connection";
import { WebsocketInstanceI } from "@/interfaces/websockets";
import { WEBSOCKET_CONNECTION_TYPES } from "lib/websockets/constants";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { checkIfClient } from "shared/lib/helpers";
import { DayJs } from "shared/lib/helpers/date";

export interface WebsocketsContextI {
  isAuthorized?: boolean;
  authRequestedAt?: string;
}

const WebsocketsContext = createContext({});

const getWSInstance = () => getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

const safeGetWebscoket = () => {
  if (!checkIfClient()) return;

  const ws = getWSInstance();
  return ws;
};

export const WebsocketsProvider = ({ children }: { children: any }) => {
  useWebsocketConnection();

  const [isAuthorized, setIsAuthorized] = useState(
    safeGetWebscoket()?.isAuthorized || false
  );
  const [authRequestedAt, setAuthRequestedAt] = useState<string | undefined>(
    safeGetWebscoket()?.authRequestedAt
  );

  useEffect(() => {
    const notAuthorized = () => {
      const ws = getWSInstance() as WebsocketInstanceI;
      ws.isAuthorized = false;

      setIsAuthorized(ws.isAuthorized);
    };

    const authorized = () => {
      const ws = getWSInstance() as WebsocketInstanceI;
      if (ws) ws.isAuthorized = true;

      setIsAuthorized(true);
    };

    const requestAuth = () => {
      const ws = getWSInstance() as WebsocketInstanceI;

      ws.isAuthorized = false;
      ws.authRequestedAt = DayJs().toISOString();

      setIsAuthorized(ws.isAuthorized);
      setAuthRequestedAt(ws.authRequestedAt);
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.AUTH_REQUESTED,
      requestAuth
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      notAuthorized
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
      authorized
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.AUTH_REQUESTED,
        notAuthorized
      );

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
        notAuthorized
      );

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
        authorized
      );
    };
  }, []);

  const interval = useRef<number>();
  useEffect(() => {
    console.log(
      `${LOG_CATEGORIES.WS}[AUTH] - requested at:${authRequestedAt} | isAuthorized:${isAuthorized}`
    );

    if (!isAuthorized) {
      clearInterval(interval.current);
      interval.current = window.setInterval(() => {
        console.log(
          `${LOG_CATEGORIES.WS}[AUTH] - requested at:${authRequestedAt} | isAuthorized:${isAuthorized}`
        );

        const secondsPassed = DayJs().diff(authRequestedAt, "seconds");

        if (isAuthorized) {
          clearInterval(interval.current);
        }

        if (secondsPassed > 10 && !isAuthorized) {
          clearInterval(interval.current);
          toast.error(
            "Failed to authorize socket connection. Please try logout and login."
          );
        }
      }, 1000);
    } else {
      clearInterval(interval.current);
    }

    if (!authRequestedAt) {
      clearInterval(interval.current);
    }
  }, [isAuthorized, authRequestedAt]);

  return (
    <WebsocketsContext.Provider value={{ isAuthorized, authRequestedAt }}>
      {children}
    </WebsocketsContext.Provider>
  );
};

export const useWebsocketsContext = (): WebsocketsContextI =>
  useContext(WebsocketsContext);

export default WebsocketsContext;
