import { useCampaigns } from "@/hooks/use-campaigns";
import { useEffect, useMemo, useState } from "react";
import { Avatar } from "shared/ui";

import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const CampaignLogo = ({
  campaignId,
  defaultBgColor = "bg-black",
  className,
}: {
  campaignId: string;
  defaultBgColor?: string;
  className?: string;
}) => {
  const { campaigns } = useCampaigns();
  const [bgColor, setBgColor] = useState(defaultBgColor);

  const campaign = useMemo(
    () => campaigns?.find((c) => c.id === campaignId),
    [campaignId, campaigns]
  );

  useEffect(() => {
    if (campaign && !campaign.logo_s3_url) {
      setBgColor("bg-black");
    }
  }, [campaign]);

  return (
    <Avatar
      src={campaign?.logo_s3_url}
      alt={campaign?.name}
      className={twMerge(
        clsx("mr-1 h-4 min-h-[16px] w-4 min-w-[16px]", bgColor, className)
      )}
    />
  );
};
