import { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import slugify from "slugify";

import { clsxMerge } from "shared/lib/helpers/styles";

export interface AvatarI {
  src?: string;
  alt?: string;
  placeholderText?: string;
  shouldTrimPlaceholderText?: boolean;
  className?: string;
  imageClassName?: string;
  showActiveStatus?: boolean;
  isOnline?: boolean;
  onlineStatusIconClassName?: string;
  isLazy?: boolean;

  onClick?: (event: any) => void;
}

// TODO tech debt - if image loading fails AND we have a placeholder text, it should
// be displayed instead of the "broken image"
const Avatar: FC<AvatarI> = ({
  src,
  alt,
  placeholderText,
  shouldTrimPlaceholderText = true,
  className,
  imageClassName,
  showActiveStatus,
  isOnline,
  onlineStatusIconClassName,
  isLazy = false,

  onClick,
}) => {
  const [isImageLoading, setIsImageLoading] = useState(
    isLazy && src && src.length > 0
  );

  const placeholder = useMemo(() => {
    if (!shouldTrimPlaceholderText) {
      return placeholderText;
    }

    const cleanText = slugify(placeholderText || "");
    const splitPlaceholder = cleanText?.split("-");

    let text = splitPlaceholder?.map((word) => word?.[0])?.join("");

    text =
      (text?.length || 0) > 2
        ? text?.slice(0, 2)
        : (text?.length || 0) < 2
        ? cleanText?.slice(0, 2)
        : text;

    return (
      <div className="animate-fadein" title={alt}>
        {text && text?.toUpperCase()}
      </div>
    );
  }, [placeholderText, shouldTrimPlaceholderText]);

  useEffect(() => {
    if (!isLazy) {
      return;
    }

    let image: HTMLImageElement;

    if (src) {
      image = new Image();
      image.src = src;

      image.addEventListener("load", () => {
        setIsImageLoading(false);
      });
    }

    return () => {
      if (src && image && isLazy) {
        image.src = "";
      }
    };
  }, [src, isLazy]);

  return (
    <div className="relative">
      <div
        className={clsxMerge(
          "flex h-6 w-6 items-center justify-center overflow-hidden rounded-full text-white",
          { "bg-black p-1": !src || (!!src && isLazy && isImageLoading) },
          { "ae-typography-detail1": !className?.includes("typography") },
          { "cursor-pointer": !!onClick },
          className
        )}
        onClick={onClick}
      >
        {src ? (
          <>
            {isLazy ? (
              <>
                {isImageLoading ? (
                  placeholder
                ) : (
                  <img
                    className={clsxMerge(
                      "animate-fadein h-full w-full object-cover",
                      imageClassName
                    )}
                    src={src}
                    alt={alt}
                  />
                )}
              </>
            ) : (
              <img
                className={clsxMerge(
                  "animate-fadein h-full w-full object-cover",
                  imageClassName
                )}
                src={src}
                alt={alt}
                loading="lazy"
              />
            )}
          </>
        ) : (
          placeholder
        )}
      </div>

      {showActiveStatus && (
        <div
          className={clsx(
            "h-3 w-3 rounded-full",
            "absolute bottom-[-2px] right-[-2px]",
            {
              "bg-[#74DB64]": isOnline,
              "bg-[#D9D9D9]": !isOnline,
            },
            onlineStatusIconClassName
          )}
        />
      )}
    </div>
  );
};

export default Avatar;
