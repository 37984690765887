import { FC, useMemo } from "react";

import { CATCHUP_TYPES } from "@/constants/catchup-todos";
import { clsxMerge, safeGetDate } from "shared/lib/helpers";
import { DATE_FORMAT_ONLY_TIME } from "shared/lib/constants/time";
import { CatchupTypesI } from "@/interfaces/dialer/catchups";
import { usePersistedCatchupsData } from "@/modules/calling/hooks/use-persisted-catchups-data";

interface CatchupTitlePropsI {
  className?: string;
}

type CatchupTitleI = FC<CatchupTitlePropsI>;

const CatchUpTitleCallback: CatchupTitleI = ({ className }) => {
  const { contact, metadata } = usePersistedCatchupsData();

  const callbackTime = useMemo(
    () =>
      metadata?.meetingTime
        ? safeGetDate(metadata.meetingTime, "hh:mm A")
        : null,
    [metadata?.meetingTime]
  );

  return (
    <div
      className={clsxMerge(
        "mt-6",
        "flex w-full flex-col items-center justify-center",
        "text-white",
        className
      )}
    >
      <h2 className="b-typography-h3 mb-4">Callback</h2>
      <p className="brand-typography-body3">
        You set a reminder to call {contact?.first_name} back at {callbackTime},
        today.
      </p>
    </div>
  );
};

const CatchUpTitleMissedCall: CatchupTitleI = ({ className }) => {
  const { contact, metadata } = usePersistedCatchupsData();

  const meetingDateAndTime = useMemo(
    () =>
      metadata?.meetingTime
        ? safeGetDate(metadata.meetingTime, "hh:mm A, on M/DD/YY")
        : null,
    [metadata?.meetingTime]
  );

  return (
    <div
      className={clsxMerge(
        "mt-6",
        "flex w-full flex-col items-center justify-center",
        "text-white",
        className
      )}
    >
      <h2 className="b-typography-h3 mb-4">Missed Call</h2>
      <p className="brand-typography-body3 max-w-[440px] text-center">
        You had a missed call from {contact?.first_name} at {meetingDateAndTime}
        . <b className="font-semibold">Call them back</b>. Missed calls are
        often easier to convert.
      </p>
    </div>
  );
};

const CatchUpTitleMeeting: CatchupTitleI = ({ className }) => {
  const { metadata, contact } = usePersistedCatchupsData();

  const meetingTime = useMemo(
    () =>
      metadata?.meetingTime
        ? safeGetDate(metadata.meetingTime, DATE_FORMAT_ONLY_TIME)
        : null,
    [metadata?.meetingTime]
  );

  return (
    <div
      className={clsxMerge(
        "mt-6",
        "flex flex-col items-center justify-center",
        "text-white",
        className
      )}
    >
      <h2 className="b-typography-h3 mb-4">Meeting Coming Up Today</h2>

      <p className="brand-typography-body3 max-w-[450px] text-center">
        You set a meeting for {meetingTime} today with {contact?.first_name}.{" "}
        <b>Call to confirm the appointment.</b> Reminder calls are proven to
        reduce no show rates.
      </p>
    </div>
  );
};

export const LeadsCarouselTitle = ({
  type,
  className,
}: {
  type?: CatchupTypesI;
  className?: string;
}) => {
  switch (type) {
    case CATCHUP_TYPES.CALLBACK:
      return <CatchUpTitleCallback className={className} />;
    case CATCHUP_TYPES.MISSED_CALLS:
      return <CatchUpTitleMissedCall className={className} />;
    case CATCHUP_TYPES.MEETING:
      return <CatchUpTitleMeeting className={className} />;
    default:
      return null;
  }
};
