import { FC, useState, ReactNode } from "react";
import throttle from "lodash/throttle";

import { useEffectOnce } from "shared/lib/hooks/use-effect-once";

interface MetaViewportPropsI {
  head: ({ children }: { children: ReactNode }) => ReactNode;
  responsivePageRoutes?: string[];
}

const THROTTLE_INTERVAL = 1000;
const MOBILE_DEVICE_SITE_WIDTH = 1500;

export const checkIfMobile = (userAgent: string) => {
  const mobileRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  return mobileRegex.test(userAgent);
};

const MetaViewport: FC<MetaViewportPropsI> = ({
  head: Head,
  responsivePageRoutes = [],
}) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [shouldApplyViewport, setShouldApplyViewport] = useState(false);

  useEffectOnce(() => {
    if (responsivePageRoutes.length > 0) {
      setShouldApplyViewport(
        responsivePageRoutes.some((responsivePageRoute) =>
          window.location.pathname.includes(responsivePageRoute)
        )
      );
    }

    setIsMobileDevice(checkIfMobile(window.navigator.userAgent));

    const handleResize = throttle(() => {
      setIsMobileDevice(checkIfMobile(window.navigator.userAgent));
    }, THROTTLE_INTERVAL);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  if (!shouldApplyViewport) {
    return null;
  }

  return (
    <Head>
      <meta
        name="viewport"
        content={`width=${
          isMobileDevice
            ? MOBILE_DEVICE_SITE_WIDTH
            : "device-width, initial-scale=1"
        }`}
      />
    </Head>
  );
};

export default MetaViewport;
