import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useAccountDetailsContext } from "@/components/modules/pipeline/account-details/context";
import { useMemo } from "react";
import {
  clsxMerge,
  formatUSPhoneNumber,
  getFullEntityName,
} from "shared/lib/helpers";

import { Avatar, CopyToClipboard } from "shared/ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { useGlobalContext } from "@/hooks/use-global-context";

export const CurrentCallerBadge = ({
  className,
  optionalTextClassName,
}: {
  className?: string;
  optionalTextClassName?: string;
}) => {
  const { contacts } = useAccountDetailsContext();
  const { caller_phone } = useInCallContext();
  const { glencocoUser: user } = useGlobalContext();

  const contact = contacts?.[0];

  const phoneNumber = useMemo(
    () => formatUSPhoneNumber(caller_phone),
    [caller_phone]
  );

  const contactFullName = useMemo(() => getFullEntityName(contact), [contact]);

  return (
    <Tooltip placement="bottom">
      <TooltipTrigger className={className}>
        <CopyToClipboard
          textToCopy={phoneNumber}
          className={clsxMerge(
            "cursor-pointer",
            "rounded-xl bg-black/70",
            "flex items-center px-2 py-2",
            "scale-100 active:scale-90",

            "transition-transform duration-500"
          )}
        >
          <div className="brand-typography-body2 flex items-center whitespace-nowrap text-white">
            <Avatar
              placeholderText={contactFullName}
              className="mr-2"
              src={user?.profile_image_url}
            />

            <span className={clsxMerge("mr-1", optionalTextClassName)}>
              Your # is
            </span>

            <span>{phoneNumber}</span>
          </div>
        </CopyToClipboard>
      </TooltipTrigger>
      <TooltipContent className="z-[100]">
        <div className="brand-typography-body2  text-white">
          Click to Copy "{phoneNumber}"
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
