import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

interface ToggleFieldI {
  label?: string;
  checked?: boolean;
  onChange?: () => void;
  className?: string;
  labelClassName?: string;
  name?: string;
  errors?: string;
  touched?: boolean;
  inputProps?: any;
}

const DefaultInput = (props: any) => <input {...props} />;

const ToggleField =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    label,
    checked,
    onChange,
    className,
    labelClassName,
    name,
    errors,
    touched,
    inputProps = {},
  }: ToggleFieldI) => {
    return (
      <div className={twMerge(clsx("form-control", className))}>
        <label className="label cursor-pointer px-0">
          <span
            className={twMerge(
              clsx("label-text ae-typography-body1", labelClassName)
            )}
          >
            {label}
          </span>

          {checked === undefined ? (
            <Field
              name={name}
              type="checkbox"
              className="toggle toggle-ae-primary ml-3"
              {...inputProps}
            />
          ) : (
            <Field
              name={name}
              type="checkbox"
              checked={checked}
              className="toggle toggle-ae-primary ml-3"
              onChange={onChange}
              {...inputProps}
            />
          )}
        </label>

        {errors && touched && (
          <div className="mt-1 flex items-center text-xs ">
            <ExclamationCircleIcon className="text-error-content bg-neutral-content mr-1 h-5 w-5" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}
      </div>
    );
  };

export default ToggleField;
