import React, { useState, useContext } from "react";

import {
  SaveContactCallbackResponseI,
  glencocoClientAPI,
} from "@/api/glencoco";
import { toast } from "react-hot-toast";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import {
  addActivityLogCallbackBooked,
  formatCallerName,
} from "@/helpers/activity-log";
import globalContext from "@/context/global";

import CalendarForm, {
  CallingCalendarFormButtonI,
} from "shared/ui/calendar-form";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

const CallingCalendarForm = ({
  title = "",
  description = "",

  bookingDeadlineInMonths = 2,

  initialTime,
  initialDate,
  initialTimezone,

  isNotes = true,
  notes = "",
  onNotesChange = () => {},

  cancelButton,
  successButton,
  //Predefined default booking during calls,
  onBookingSuccess = () => {},

  isLocalSaveOnly,
}: {
  title?: string;
  description?: string;

  bookingDeadlineInMonths?: number | boolean;

  initialTime?: string;
  initialDate?: string;
  initialTimezone?: string;

  isNotes?: boolean;
  notes?: string;
  onNotesChange?: (notesValue: string) => void;

  cancelButton?: CallingCalendarFormButtonI;
  successButton?: CallingCalendarFormButtonI;
  onBookingSuccess?: (date: string) => void;

  isLocalSaveOnly?: boolean;
}) => {
  const inCallContext = useInCallContext();
  const { campaignId = "", call } = useCallingContext();
  const { glencocoUser } = useContext(globalContext);
  const { contact, callbackData, setCallbackData } = inCallContext;

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (
    date: string,
    time: string,
    timezone: string,
    notes: string,
    onSuccessCleanUp: () => void
  ) => {
    const API = glencocoClientAPI();
    setLoading(true);

    if (isLocalSaveOnly) {
      setCallbackData({
        ...callbackData,
        isCallbackSet: true,
        selectedCallbackDate: date,
        selectedCallbackTime: time,
        selectedCallbackTimezone: timezone,
      });

      toast.success(
        `${contact?.first_name} ${contact?.last_name} temporarily saved! You will need to confirm it when the call is finished.`
      );

      onSuccessCleanUp();
      setLoading(false);

      onBookingSuccess(dayjs(date).format());
    } else {
      const resp = await API.saveContactCallback(
        campaignId,
        call?.customParameters.get("call_id") as string,
        {
          contact_id: contact?.id as string,
          notes: notes || "",
          follow_up_time: date,
        }
      ).catch(() => ({ status: null, data: null }));

      setTimeout(() => {
        if (resp?.status === 200) {
          const data = resp?.data as SaveContactCallbackResponseI;

          toast.success(
            `${contact?.first_name} ${contact?.last_name} was saved for later`
          );

          setCallbackData({
            ...callbackData,
            isCallbackSet: true,
            selectedCallbackDate: date,
            selectedCallbackTime: time,
            selectedCallbackTimezone: timezone,
            activityLogId: data.save_contact_activity_log_id,
          });

          addActivityLogCallbackBooked(inCallContext, {
            contact: {
              first_name: contact?.first_name,
              last_name: contact?.last_name,
              phone: contact?.phone,
              title: contact?.title,
              linkedin_url: contact?.linkedin,
              email: contact?.email,
            },
            timestamp: new Date().toLocaleString("en"),
            follow_up_time: dayjs(date).format(),
            notes: notes || "",
            caller_name: formatCallerName(
              glencocoUser?.first_name || "",
              glencocoUser?.last_name || ""
            ),
          });

          onSuccessCleanUp();
          setLoading(false);

          onBookingSuccess(dayjs(date).format());
        } else {
          toast.error("Failed to save the lead");
        }

        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div>
      <CalendarForm
        bookingDeadlineInMonths={bookingDeadlineInMonths}
        title={title}
        description={description}
        isNotes={isNotes}
        notes={notes}
        onNotesChange={onNotesChange}
        initialDate={initialDate}
        initialTimezone={initialTimezone}
        initialTime={initialTime}
        cancelButton={cancelButton}
        successButton={successButton}
        onFormSubmit={handleFormSubmit}
        isLoading={loading}
      />
    </div>
  );
};

export default CallingCalendarForm;
