import { useState } from "react";

import { useLongPolling } from "./use-longpolling";
import { DayJs } from "shared/lib/helpers/date";

interface UseTimeAgoI {
  targetDate: string | Date;
  updateInterval?: number;
}

const DEFAULT_UPDATE_INTERVAL = 1000;

const calculateFormattedDifference = (targetDate: string | Date) => {
  const difference = DayJs.duration(DayJs(targetDate).diff(DayJs()));

  if (difference.asSeconds() <= 0) {
    return "00s";
  }

  if (difference.asDays() >= 1) {
    return difference.format("D[d]");
  }

  if (difference.asMinutes() > 1) {
    return difference.format("HH[h] mm[m]");
  } else {
    return difference.format("ss[s]");
  }
};

// Live countdown timer showing time from now to the target date
export const useCountdownTimer = ({
  targetDate,
  updateInterval = DEFAULT_UPDATE_INTERVAL,
}: UseTimeAgoI) => {
  const [timeAgo, setTimeAgo] = useState(
    calculateFormattedDifference(targetDate)
  );

  useLongPolling(
    async (invervalId) => {
      if (DayJs().isAfter(DayJs(targetDate))) {
        clearInterval(invervalId);
      } else {
        setTimeAgo(calculateFormattedDifference(targetDate));
      }
    },
    DEFAULT_UPDATE_INTERVAL,
    [targetDate, updateInterval]
  );

  return timeAgo;
};
