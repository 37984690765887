import React, { FC, useContext, useState } from "react";
import { MicrophoneIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import { CogsIcon, DialPadIcon, MuteIcon } from "shared/ui/icons";
import DialPad from "./dial-pad";
import Settings from "./settings";
import {
  useCallingContext,
  useFrequentlyChangingCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { DIALER_STATES } from "@/constants/dialer";
import { toast } from "react-hot-toast";
import GlobalContext, { DefaultGlobalContextI } from "@/context/global";
import { endSession } from "@/helpers/calling";
import { defaultLeadInfo } from "@/context/dialer-v1/default-context";
import { ControlledDropdown } from "@/components/base/controlled-dropdown";

import { BackgroundBlur, SVGBorderElement } from "./shared";
import ControlMenuDispositionState from "./disposition-state";
import { useEffectOnce } from "shared/lib/hooks";
import { formatUSPhoneNumber } from "shared/lib/helpers";
import { ControlMenuEndCallButton as EndCallButton } from "./buttons/end-call";
import { DialingWarningBlock } from "@/modules/calling/dialing-state/dialing-warning-block";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";

const TalkingAnimationElement = () => {
  const callContext = useFrequentlyChangingCallingContext();
  const isTalking = Math.round(callContext.inputVol * 10 || 0) > 1;
  const randomValues = Array(3)
    .fill(0)
    .map(() => (isTalking ? Math.floor(Math.random() * 10) + 1 : 0));

  return (
    <span className="row absolute bottom-[-1px] right-0 flex h-2 w-4 items-center justify-center gap-[2px] rounded-full">
      {[0, 1, 2].map((v, i) => (
        <div
          key={i}
          className={`h-[${randomValues[i]}px] w-[2px] rounded-md bg-[#00FF94] transition-[height] duration-200 ease-out`}
        />
      ))}
    </span>
  );
};

interface ControlMenuI {
  className?: string;
}

const ControlMenu: FC<ControlMenuI> = ({ className }) => {
  const callContext = useCallingContext();
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDialPadOpen, setDialPadOpen] = useState(false);
  const { caller_phone } = useInCallContext();

  const globalContext = useContext(GlobalContext) as DefaultGlobalContextI;

  const user = globalContext?.glencocoUser;

  useEffectOnce(() => {
    setIsVisible(true);
  });

  const handleMuteClick = () => {
    const newMuteValue = !callContext.isMuted;
    callContext.setMuted(newMuteValue);

    if (callContext.call) {
      callContext.call.mute(newMuteValue);
    }
  };

  const handleEndCall = () => {
    setLoading(true);

    if (callContext.call) {
      callContext.call.disconnect();
      dd.rum.log(
        `${LOG_CATEGORIES.TWILIO}[DISCONNECT] - manual disconnect handleEndCall`
      );
    } else {
      toast.error("You are not connected to a call yet");
    }

    if (callContext.device) {
      callContext.device.disconnectAll();
    }

    callContext.setLeadInfo(defaultLeadInfo);

    setLoading(false);
  };

  const handleEndSession = async () => {
    setLoading(true);

    setTimeout(async () => {
      await endSession(callContext, () => {
        setTimeout(() => {
          callContext.setCallingState(DIALER_STATES.PRE);
        }, 500);
      });
      setLoading(false);
    }, 1000);
  };

  return (
    <div className={twMerge(clsx("relative w-fit", className))}>
      <BackgroundBlur />
      <SVGBorderElement />

      <div
        className={twMerge(
          clsx(
            "relative",
            "px-[2px] py-[1px]",
            "h-[50px] transition-[width,border-radius] delay-100 duration-500",
            {
              "w-[44px] rounded-full": !isVisible,
              "w-[450px] rounded-[10px]": isVisible,
            }
          )
        )}
      >
        {DIALER_STATES.DIALING === callContext.callingState && (
          <DialingWarningBlock className="absolute top-[-160px] w-full animate-fadein" />
        )}

        <div
          className={clsx(
            "flex justify-between",
            "h-full opacity-0 transition-opacity delay-500 duration-1000",
            {
              "opacity-100": isVisible,
            }
          )}
        >
          <div className="flex items-center gap-2 py-2">
            <div className="avatar relative ml-3">
              <div className="h-7 w-7 rounded-full">
                <div className="typography-body flex h-full w-full items-center justify-center bg-black text-white">
                  {user?.first_name?.substring(0, 1)}
                  {user?.last_name?.substring(0, 1)}
                </div>
              </div>

              {callContext.callingState === DIALER_STATES.CALL && (
                <TalkingAnimationElement />
              )}
            </div>

            <div className="flex max-h-[100%] flex-col">
              <span
                className={clsx(
                  "ae-typography-body2 relative font-bold text-black/40",
                  {
                    "text-white/60":
                      callContext.callingState !== DIALER_STATES.PRE,
                  },
                  "transition-[opacity,left] delay-1000 duration-500",
                  {
                    "-left-5 opacity-0": !isVisible,
                    "left-0 opacity-100": isVisible,
                  }
                )}
              >
                You
              </span>

              <span
                className={clsx(
                  "ae-typography-detail1 font-normal text-white/60"
                )}
              >
                {formatUSPhoneNumber(caller_phone)}
              </span>
            </div>
          </div>

          <div className="relative flex items-center gap-2">
            <button
              className="btn btn-circle btn-ghost btn-sm"
              onClick={handleMuteClick}
            >
              {callContext.isMuted ? (
                <MuteIcon
                  className={clsx("h-4 w-4 cursor-pointer text-black/40", {
                    "text-white/60":
                      callContext.callingState !== DIALER_STATES.PRE,
                  })}
                />
              ) : (
                <MicrophoneIcon
                  className={clsx("h-4 w-4 cursor-pointer text-black/40", {
                    "text-white/60":
                      callContext.callingState !== DIALER_STATES.PRE,
                  })}
                />
              )}
            </button>

            <ControlledDropdown
              onOpen={() => setDialPadOpen(true)}
              onClose={() => setDialPadOpen(false)}
            >
              {() => (
                <>
                  <label
                    tabIndex={0}
                    className="btn btn-circle btn-ghost btn-sm focus-visible:outline-0"
                  >
                    <DialPadIcon
                      className={clsx("h-4 w-4 cursor-pointer text-black/40", {
                        "text-white/60":
                          callContext.callingState !== DIALER_STATES.PRE,
                      })}
                    />
                  </label>
                  <div
                    tabIndex={0}
                    className="dropdown-content translate-x-[-76px] translate-y-[-4px]"
                  >
                    <DialPad
                      isOpen={isDialPadOpen}
                      disabled={
                        callContext.callingState !== DIALER_STATES.DIALING &&
                        callContext.callingState !== DIALER_STATES.CALL
                      }
                    />
                  </div>
                </>
              )}
            </ControlledDropdown>

            <ControlledDropdown>
              {(isDropdownVisible) => (
                <>
                  <label
                    tabIndex={1}
                    className={clsx("btn btn-circle btn-ghost btn-sm", {
                      "mr-2":
                        callContext.callingState !== DIALER_STATES.DIALING &&
                        callContext.callingState !== DIALER_STATES.CALL,
                    })}
                  >
                    <CogsIcon
                      className={clsx("h-4 w-4 cursor-pointer text-black/40", {
                        "text-white/60":
                          callContext.callingState !== DIALER_STATES.PRE,
                      })}
                    />
                  </label>
                  <div
                    id="test1"
                    tabIndex={1}
                    className={clsx(
                      "dropdown-content translate-x-[-158px] translate-y-[-4px]"
                    )}
                  >
                    <Settings isVisible={isDropdownVisible as boolean} />
                  </div>
                </>
              )}
            </ControlledDropdown>

            {callContext.callingState === DIALER_STATES.CALL && (
              <EndCallButton isLoading={isLoading} onClick={handleEndCall} />
            )}
            {[DIALER_STATES.DIALING, DIALER_STATES.LEAD_SELECTION].includes(
              callContext.callingState as string
            ) && (
              <button
                className={clsx("btn-ae-error h-full min-w-[128px]", {
                  loading: isLoading,
                })}
                onClick={handleEndSession}
              >
                END SESSION
              </button>
            )}
            {callContext.callingState === DIALER_STATES.POST && (
              <button
                className={clsx("btn-ae-error loading h-full min-w-[128px]")}
                disabled
              >
                CALL ENDED
              </button>
            )}
          </div>
        </div>
      </div>

      {callContext.callingState === DIALER_STATES.CALL && (
        <span
          className={clsx(
            "ae-typography-detail1",
            "absolute bottom-[-32px] left-[-25px] w-[516px]",
            "animate-fadein",
            "font-normal text-white opacity-90"
          )}
        >
          We do an 8 second hold before ending a call to comply with partner &
          FCC requirements.
        </span>
      )}
    </div>
  );
};

const ControlMenuStateSwitcher = (props: any) => {
  const { callingState } = useCallingContext();

  return callingState === DIALER_STATES.DISPOSITION ? (
    <ControlMenuDispositionState {...props} />
  ) : (
    <ControlMenu {...props} />
  );
};

export default ControlMenuStateSwitcher;
