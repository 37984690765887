import { clsxMerge } from "shared/lib/helpers";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { modal } from "@/components/modals";
import { DIALER_CANCEL_MEETING_MODAL_ID } from "@/components/modals/dialer-cancel-meeting-modal";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

export const CancelMeetingButton = ({ className }: { className?: string }) => {
  const { isMeetingCanceled } = useInCallContext();

  const handleCancelMeeting = () => {
    modal.trigger(DIALER_CANCEL_MEETING_MODAL_ID);
  };

  return (
    <button
      className={clsxMerge(
        "btn-nofill btn-ae-l px-0",

        { disabled: isMeetingCanceled },
        className
      )}
      disabled={isMeetingCanceled}
      onClick={handleCancelMeeting}
    >
      <XCircleIcon className="mr-2 h-4 w-4" /> Cancel Meeting
    </button>
  );
};
