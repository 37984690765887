import { ListPillI } from "@/api/routes/list";
import { ACCOUNT_LIST_TYPES } from "@/modules/pipeline/constants";

export const checkIfNurtureList = (list: ListPillI | undefined) => {
  if (!list) {
    return null;
  }

  /**
   * ACCOUNT_LIST_TYPES.CALLBACKS & ACCOUNT_LIST_TYPES.MISSED_CALLS are basically
   * the new nurture, not including because of that.
   */
  return ![
    ACCOUNT_LIST_TYPES.BOOKED_MEETINGS,
    ACCOUNT_LIST_TYPES.FEEDBACK_RECEIVED,
    ACCOUNT_LIST_TYPES.EMAIL_ACTIONS,
  ].includes(list.list_type);
};

export const getSortedLists = (lists: ListPillI[] | undefined) => {
  if (lists) {
    return [...lists].sort((a, b) =>
      a.is_exclusive_list ? -1 : a.order - b.order
    );
  }

  return [];
};
