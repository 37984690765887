import { AxiosInstance, AxiosResponse } from "axios";
import { DefaultResponseI } from "@/api/glencoco";
import {
  NotificationCountersI,
  NotificationI,
} from "@/interfaces/notification";

export interface GetNotificationsResponseI {
  next_token?: string;
  notifications: NotificationI[];
}

export type GetNotificationsCountResponseI = NotificationCountersI;

export interface NotificationsApiI {
  getNotifications({
    nextToken,
  }: {
    nextToken?: string;
  }): Promise<AxiosResponse<GetNotificationsResponseI>>;

  getNotificationsCount(): Promise<
    AxiosResponse<GetNotificationsCountResponseI>
  >;

  markReadNotification(
    notificationId: string
  ): Promise<AxiosResponse<DefaultResponseI>>;

  markReadAllNotifications(): Promise<AxiosResponse<DefaultResponseI>>;
}

export const NotificationsAPIEndpoints = (
  API: AxiosInstance
): NotificationsApiI => ({
  getNotifications: ({ nextToken }) =>
    API.post(`v2/notifications`, { next_token: nextToken }),

  getNotificationsCount: () => API.get(`/v2/notifications/count`),

  markReadNotification: (notificationId) =>
    API.post(`/v1/notification/${notificationId}/mark_read`, {}),

  markReadAllNotifications: () =>
    API.post(`/v1/notifications/mark_all_read`, {}),
});
