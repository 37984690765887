import { useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { createKeyValuePair } from "shared/lib/helpers/utils";
import { KeyValuePairI } from "shared/lib/interfaces/utils";
import { ChevronRightIcon } from "shared/ui/icons";
import { MODAL_DIALING_SECTIONS } from "./constants";

const _sections = [
  createKeyValuePair(MODAL_DIALING_SECTIONS.LIST_DIALER, "List Dialer"),
  createKeyValuePair(MODAL_DIALING_SECTIONS.GENERAL_DIALER, "General dialer"),
];

export const useStartCallingSectionsList = () => {
  const [selected, setSelected] = useState<string>(
    MODAL_DIALING_SECTIONS.LIST_DIALER
  );

  return { sections: _sections, selected, setSelected };
};

export const StartCallingSectionsList = ({
  sections = _sections,
  selected = MODAL_DIALING_SECTIONS.LIST_DIALER,
  onChange,
}: {
  selected?: string;
  sections?: KeyValuePairI<string>[];
  onChange?: (selected: string) => void;
}) => {
  const handleSelection = (key: string) => () => {
    onChange?.(key);
  };

  return (
    <div className="flex flex-col">
      {sections?.map(({ key, value }) => (
        <button
          key={key}
          className={clsxMerge(
            "btn btn-sm mb-[2px] rounded-md border-none pr-2",
            "flex w-full justify-between",
            "typography-body-5",
            "transition-all duration-300",
            {
              "bg-black": selected === key,
              "btn-ghost": selected !== key,
            }
          )}
          onClick={handleSelection(key)}
        >
          <span>{value}</span>
          <ChevronRightIcon className="w-2" />
        </button>
      ))}
    </div>
  );
};
