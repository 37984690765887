import { useEffect, useState } from "react";
import clsx from "clsx";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { glencocoClientAPI } from "@/api/glencoco";
import { LeadInfoI } from "@/interfaces/dialer/lead-info";
import { checkIfClient, rotate } from "shared/lib/helpers/utils";

import DialingLead from "./dialing-lead";
import DialingTitle from "./dialing-title";
import { useEffectOnce } from "shared/lib/hooks";
import { getS3AudioPath } from "shared/lib/helpers";

const AUDIO_FILE_PATHS = [
  getS3AudioPath("cat_funky.mp3"),
  getS3AudioPath("jazzy.mp3"),
  getS3AudioPath("dialing.mp3"),
];

const CallingDialingState = ({
  isAboutToUnmount = false,
}: {
  isAboutToUnmount?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [leads, setLeads] = useState<LeadInfoI[]>([]);
  const [audioSrc] = useState(
    AUDIO_FILE_PATHS[Math.floor(Math.random() * AUDIO_FILE_PATHS.length)]
  );

  const context = useCallingContext();

  // First fetch leads
  useEffectOnce(() => {
    if (checkIfClient()) {
      const API = glencocoClientAPI();

      let interval: any = null;

      (async (API) => {
        if (context.leadQueue?.length <= 0) {
          const LeadsResponse = await API.getUnmatchedCallLeads(
            context.campaignId ?? ""
          ).catch(() => ({ status: null, data: null }));

          if (LeadsResponse.status === 200) {
            setLeads(LeadsResponse.data.contacts || []);
          }
        } else {
          setLeads(context.leadQueue);
        }

        // NOTE: animation purposes
        setIsVisible(true);
        setTimeout(() => {
          interval = setInterval(() => {
            setLeads((current) => {
              return rotate(current);
            });
          }, 2000);
        }, 2000);
      })(API);

      return () => {
        clearInterval(interval);
      };
    }
  });

  // Refresh leads
  useEffect(() => {
    if (checkIfClient()) {
      let interval: any = null;

      if (!leads?.length || leads?.length === 1) {
        interval = setInterval(() => {
          (async () => {
            const API = glencocoClientAPI();

            if (context.leadQueue?.length <= 0) {
              const LeadsResponse = await API.getUnmatchedCallLeads(
                context.campaignId ?? ""
              ).catch(() => ({ status: null, data: null }));

              if (LeadsResponse.status === 200) {
                setLeads(LeadsResponse.data.contacts || []);
              }
            } else {
              setLeads(context.leadQueue);
            }
          })();
        }, 5000);
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [leads?.length]);

  return (
    <div
      className={clsx(
        "w-full max-w-[400px] text-white",
        "transition-[opacity,transform] duration-1000 ease-in-out",
        "flex flex-col items-center",
        {
          "scale-100 opacity-100": isVisible && leads?.length,
          "scale-50 opacity-0": !isVisible,
        }
      )}
    >
      {context?.isMusic && (
        <audio src={audioSrc} autoPlay loop className="hidden" />
      )}
      <div className="mb-8 text-center">
        <DialingTitle />
      </div>

      <div
        className={clsx(
          "w-[310px] max-w-[310px] transition-[filter] duration-1000",
          {
            "blur-xl": isAboutToUnmount,
          }
        )}
      >
        {leads?.slice(0, 5).map((lead, idx, leads) => {
          const blur = Math.abs(idx - Math.floor(leads.length / 2));

          return (
            <DialingLead
              key={idx}
              className={`blur-[${blur}px]`}
              lead={lead}
              isAnimated={blur === 0}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CallingDialingState;
