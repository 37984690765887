import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import { FC, useState } from "react";

import CallingCalendarForm from "@/modules/calling/calendar-form";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { combineActivityNotes } from "@/helpers/activity-log";
import Calling from "@/helpers/calling";
import { CALLING_DISPOSITION_MODAL_ID } from "@/modules/calling/modals/disposition-modal";
import { useEffectOnce } from "shared/lib/hooks";

export interface CallBackBookedCardI {
  className?: string;
  isWrapperNoStyles?: boolean;
  onSuccess?: () => void;
}

const CallBackBookedCard: FC<CallBackBookedCardI> = ({
  className,
  isWrapperNoStyles,
  onSuccess,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inCallContext = useInCallContext();
  const { contact, callbackData } = inCallContext;
  const callingContext = useCallingContext();

  const handleSave = () => {
    onSuccess?.();

    Calling.exit({
      context: callingContext,
      inCallContext: inCallContext,
    });
  };

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <div
      className={twMerge(
        isWrapperNoStyles
          ? ""
          : clsx(
              "w-[530px] rounded-[10px] bg-white px-8 py-6",
              className,
              "transition-[opacity,transform] duration-500",
              {
                "scale-50 opacity-0": !isVisible,
                "scale-100 opacity-100": isVisible,
              }
            )
      )}
    >
      <CallingCalendarForm
        title={`Review when you want to callback ${contact?.first_name}?`}
        description={`Please review your notes and your call back to ${contact?.first_name} ${contact?.last_name}`}
        initialDate={callbackData?.selectedCallbackDate}
        initialTime={callbackData?.selectedCallbackTime}
        initialTimezone={callbackData?.selectedCallbackTimezone}
        isNotes={true}
        notes={combineActivityNotes(inCallContext).join("\r\n")}
        successButton={{
          type: "button",
          text: "Save",
        }}
        cancelButton={{
          type: "label",
          htmlFor: CALLING_DISPOSITION_MODAL_ID,
          text: "Cancel",
        }}
        onBookingSuccess={() => {
          // Toasts handled already inside

          handleSave();
        }}
      />
    </div>
  );
};

export default CallBackBookedCard;
