import { FC } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import CrownIcon from "shared/ui/icons/crown-icon";

interface CustomerCrownLabelPropsI {
  className?: string;
}

const CustomerCrownLabel: FC<CustomerCrownLabelPropsI> = ({ className }) => (
  <span
    className={clsx(
      twMerge("flex items-center rounded-md bg-black px-2 py-[2px]", className)
    )}
  >
    <CrownIcon className="mr-1 h-3 -rotate-45 text-[#D4CB00]" />

    <span className="text-white">Customer</span>
  </span>
);

export default CustomerCrownLabel;
