import { FC, MouseEvent, useCallback, useMemo } from "react";
import toast from "react-hot-toast";

import {
  NotificationDescription,
  NotificationDescriptionI,
} from "shared/ui/notifications/notification-generic/base-parts/description";
import {
  NotificationGeneric,
  NotificationBaseGenericI,
} from "shared/ui/notifications/notification-generic";
import {
  NotificationActionItemI,
  NotificationActions,
} from "shared/ui/notifications/notification-generic/base-parts/actions";
import {
  NotificationMessageI,
  NotificationMessage,
} from "shared/ui/notifications/notification-generic/base-parts/message";

import { glencocoClientAPI } from "@/api/glencoco";
import { useLocalNotificationsContext } from "@/modules/notifications/context";
import { useGlobalNotificationsContext } from "@/context/notifications-context";
import { NotificationImage, NotificationImageI } from "./image";

export interface NotificationBaseI extends NotificationBaseGenericI {
  id: string;
  avatar?: NotificationImageI;
  description?: NotificationDescriptionI;
  actions?: Array<NotificationActionItemI>;
  message?: NotificationMessageI;
}

/**
 *
 * @param avatar - For extention to adopt different approaches to notification imgs
 *                 we would need to replace CampaignLogo component with
 *                 more variation inclusive NotificationImg component
 * @param actions - With the change of requirements
 *                  the first element is primary action which is triggered on row click,
 *                  but we need to show buttons only if there are 2 or more
 */
export const NotificationBase: FC<NotificationBaseI> = ({
  avatar,
  description = {},
  message = {},
  actions: _actions,
  ...props
}) => {
  const { setNotifications, closePanel } = useLocalNotificationsContext();
  const { reload: reloadNotificationsCount } = useGlobalNotificationsContext();

  const { id, isUnread, className } = props;

  const onBeforeAction = useCallback(async () => {
    const API = glencocoClientAPI();

    const MarkReadResponse = await API.markReadNotification(id).catch((e) => e);

    if (MarkReadResponse.status !== 200) {
      toast.error("Failed to mark notification as read");
      return;
    }

    setNotifications?.((notifications) =>
      notifications?.map((n) => (n?.id === id ? { ...n, is_seen: true } : n))
    );

    reloadNotificationsCount?.();
  }, [id]);

  const onAfterAction = useCallback(() => {
    closePanel?.();
  }, []);

  const actions = useMemo(() => {
    return _actions?.map((actionItem) => {
      const newOnClick = async (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (isUnread && onBeforeAction) {
          await onBeforeAction();
        }

        actionItem.onClick?.(event);

        onAfterAction?.();
      };

      return { ...actionItem, onClick: newOnClick };
    });
  }, [_actions, onBeforeAction, isUnread]);

  return (
    <NotificationGeneric
      onClick={actions?.[0]?.onClick}
      className={className}
      avatarReact={<NotificationImage {...(avatar as NotificationImageI)} />}
      descriptionReact={<NotificationDescription {...description} />}
      actionsReact={
        (actions?.length || 0) >= 2 ? (
          <NotificationActions actions={actions} />
        ) : undefined
      }
      messageReact={<NotificationMessage {...message} />}
      {...props}
    />
  );
};
