import { useMemo } from "react";

import { useGlobalContext } from "./use-global-context";

export const useOnboarding = () => {
  const { glencocoUser: user } = useGlobalContext();
  const { user_onboarding_status } = user || {};
  const { watched_intro_video, picked_campaign } = user_onboarding_status || {};

  const step = useMemo(() => {
    return [watched_intro_video, picked_campaign].reduce(
      (acc: number, cur: any) => acc + (cur ? 1 : 0),
      0
    );
  }, [watched_intro_video, picked_campaign]);

  const isOnboarded = useMemo(() => {
    return [watched_intro_video, picked_campaign].every((check) => !!check);
  }, [watched_intro_video, picked_campaign]);

  return { step, isOnboarded };
};
