import React, {
  CSSProperties,
  FC,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import _throttle from "lodash.throttle";

interface CursorFollowerPropsI {
  children: ReactNode;
  startX?: number;
  startY?: number;
}

const BASE_WIDTH = 320;

// TODO switch sides when element is too close to one of the screen sides
export const CursorFollower: FC<CursorFollowerPropsI> = ({
  children,
  startX,
  startY,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [documentWidth, setDocumentWidth] = useState(1400);
  const [documentHeight, setDocumentHeight] = useState(800);
  const [elementHeight, setElementHeight] = useState(0);

  const [isXSwitchSides, setIsXSwitchSides] = useState(false);
  const [isYSwitchSides, setIsYSwitchSides] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [x, setX] = useState(startX || 0);
  const [y, setY] = useState(startY || 0);

  const setXY = (e: any) => {
    setX(e.clientX);
    setY(e.clientY);
  };

  useLayoutEffect(() => {
    document.onmousemove = setXY;
  }, []);

  useLayoutEffect(() => {
    setIsYSwitchSides(y + elementHeight > documentHeight);
  }, [y, documentHeight, elementHeight]);

  useLayoutEffect(() => {
    setIsXSwitchSides(x + BASE_WIDTH > documentWidth);
  }, [x, documentWidth]);

  useLayoutEffect(() => {
    setElementHeight(containerRef.current?.offsetHeight || 0);

    setTimeout(() => {
      setIsVisible(true);
    }, 200);

    if (typeof window !== "undefined") {
      const getDocumentWidth = () =>
        Math.max(
          document.documentElement["clientWidth"],
          document.body["scrollWidth"],
          document.documentElement["scrollWidth"],
          document.body["offsetWidth"],
          document.documentElement["offsetWidth"]
        );

      const getDocumentHeight = () =>
        Math.min(
          document.documentElement["clientHeight"],
          document.body["scrollHeight"],
          document.documentElement["scrollHeight"],
          document.body["offsetWidth"],
          document.documentElement["offsetHeight"]
        );

      setDocumentWidth(getDocumentWidth());
      setDocumentHeight(getDocumentHeight());

      const onResize = _throttle(() => {
        setDocumentWidth(getDocumentWidth());
        setDocumentHeight(getDocumentHeight());
      }, 200);

      document.addEventListener("resize", onResize);

      return () => {
        document.removeEventListener("resize", onResize);
      };
    }
  }, []);

  const styles: CSSProperties = {
    width: `${BASE_WIDTH}px`,
    height: "auto",

    top: isYSwitchSides ? y - elementHeight : y,
    left: isXSwitchSides ? x - 20 - BASE_WIDTH : x + 20,
    userSelect: "none",
    transitionTimingFunction: "cubic-bezier(0.18, 0.89, 0.32, 1.28)",
    transitionDuration: "0.4s",
    position: "fixed",
    zIndex: 100,
    backgroundColor: "white",
    opacity: isVisible ? 100 : 0,
    borderRadius: "8px",
  };

  return (
    <div ref={containerRef} id="cursor" style={styles}>
      {children}
    </div>
  );
};
