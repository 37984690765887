import { FC } from "react";
import clsx from "clsx";

import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { BarsArrowDownIcon } from "@heroicons/react/24/outline";

interface LeadsCarouselSendToBackPropsI {
  isLoading?: boolean;
  onSendToBack?: () => void;
}

export const LeadsCarouselSendToBackButton: FC<
  LeadsCarouselSendToBackPropsI
> = ({ isLoading, onSendToBack }) => {
  const handleOnClick = () => {
    (document?.querySelector(":focus") as HTMLElement)?.blur();
    onSendToBack?.();
  };

  return (
    <Tooltip showDelay={0}>
      <TooltipTrigger
        className={clsx(
          "btn-ae-default flex h-10 w-10 text-white",
          "items-center justify-center rounded-full p-0",
          {
            "loading pointer-events-none pl-2 opacity-80": isLoading,
          }
        )}
        onClick={handleOnClick}
      >
        {!isLoading && (
          <BarsArrowDownIcon className="relative left-[1px] w-5" />
        )}
      </TooltipTrigger>

      <TooltipContent className="z-10 max-w-[113px] rounded-lg px-4 py-2">
        <div className="flex max-w-[300px] flex-col items-center gap-1.5 text-white">
          <span className="brand-typography-body5 text-center">
            Send to back of to-do list
          </span>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
