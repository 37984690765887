import { ReactElement } from "react";

export interface NotificationMessageI {
  text?: string | ReactElement;
}

export const NotificationMessage = ({ text }: NotificationMessageI) => {
  return text ? (
    <p className="brand-typography-body3 font-light">{text}</p>
  ) : null;
};
