import { clsxMerge } from "shared/lib/helpers";

import {
  DISPOSITION_SPECIFICS,
  DISPOSITION_SPECIFICS_MAP,
} from "shared/lib/constants/dispositions";

import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useMemo } from "react";
import { Dropdown, TextArea } from "shared/ui";
import { DropdownOptionI } from "shared/ui/user-input/dropdown";
import { glencocoClientAPI } from "@/api/glencoco";
import toast from "react-hot-toast";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { dd } from "@/helpers/datadog";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { useGlobalContext } from "@/hooks/use-global-context";
import { AxiosResponse } from "axios";
import { WIDGETS } from "@/constants/widgets";
import { widgets } from "@/components/shared/widgets";

import { useDialerWidgetContext } from "@/context/dialer-v2/widget";

const EnhancedDropdown = Dropdown(Field);
const EnchancedTextArea = TextArea(Field);

interface FormValidationSchemaI {
  disposition?: string;
  note?: string;
}

const FormValidationSchema = Yup.object().shape({
  disposition: Yup.string().required(
    "Disposition is required to send feedback"
  ),
});

const DISPOSITION_OPTIONS: DropdownOptionI[] = Object.values(
  DISPOSITION_SPECIFICS
).map((dispoKey) => ({
  label: DISPOSITION_SPECIFICS_MAP[dispoKey],
  value: dispoKey,
}));

export const DispositionSection = ({ className }: { className?: string }) => {
  const { glencocoUser: user } = useGlobalContext();
  const { campaign, call } = useCallingContext();

  const { notes } = useDialerWidgetContext();

  const formInitialValues: FormValidationSchemaI = useMemo(
    () => ({
      disposition: "",
      // TODO transfer note from the temporal notes
      note: notes,
    }),
    [notes]
  );

  const onSuccessDispositionSubmission = () => {
    widgets.close({ id: WIDGETS.DIALER });
  };

  const onErrorDispositionSubmission = (
    resp: AxiosResponse,
    requestBody: any
  ) => {
    const errorMessage = "Failed to submit call disposition info";
    const callId = call?.customParameters.get("call_id") as string;

    toast.error(errorMessage);

    dd.error(errorMessage, {
      user,
      call: {
        status: resp?.status,
        error: resp,
        call: call,
        customParameters: Object.fromEntries(call?.customParameters || []),
        campaignId: campaign?.id,
        callId,
        requestBody,
      },
    });
  };

  const handleDipositionSubmission = async (data: FormValidationSchemaI) => {
    const API = glencocoClientAPI();

    const callId = call?.customParameters.get("call_id") as string;
    const requestBody = {
      notes: data.note || "",
      call_disposition: data.disposition as ValueOfObjectFields<
        typeof DISPOSITION_SPECIFICS
      >,
    };

    const resp = await API.addCallDisposition(
      campaign?.id as string,
      callId,
      requestBody
    ).catch((e) => e);

    if (resp.status === 200) onSuccessDispositionSubmission();
    else onErrorDispositionSubmission(resp, requestBody);
  };

  const handleSkipDisposition = () => {
    handleDipositionSubmission({
      note: notes,
      disposition: DISPOSITION_SPECIFICS.VOICEMAIL_NOT_LEFT,
    });
  };

  return (
    <section
      className={clsxMerge(
        "relative h-full w-full animate-fadein px-6",
        "bg-black/30 text-white",
        "mt-6 p-6 pt-12",
        "flex flex-col",
        className
      )}
    >
      <h6 className="brand-typography-body3 mb-1 font-semibold">
        What happened on the call?
      </h6>
      <p className="brand-typography-body2 mb-5">
        Please select a disposition that best matches the outcome of the call
      </p>

      <Formik
        enableReinitialize
        validationSchema={FormValidationSchema}
        initialValues={formInitialValues}
        onSubmit={handleDipositionSubmission}
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form className="flex grow flex-col">
            <EnhancedDropdown
              name="disposition"
              label="Disposition"
              placeholder="Select"
              options={DISPOSITION_OPTIONS}
              errors={errors.disposition}
              touched={touched.disposition}
              className="relative mb-5"
              labelContentClassName="text-white brand-typography-body2"
              inputClassName="text-black"
              errorsClassName="absolute -bottom-[22px]"
            />

            <EnchancedTextArea
              name="note"
              label="Note"
              placeholder="Add your note here"
              errors={errors.note}
              touched={touched.note}
              className="relative mb-5"
              labelContentClassName="text-white brand-typography-body2"
              textareaClassName="text-black"
              errorsClassName="absolute -bottom-[22px]"
            />

            <div className="flex grow items-end justify-between py-6">
              <button
                className="btn-b-white-text"
                type="button"
                onClick={handleSkipDisposition}
              >
                Skip Disposition
              </button>
              <button
                className={clsxMerge("btn-ae-default w-[200px]", {
                  "disabled:bg-black disabled:text-white/30":
                    !isValid || isSubmitting,
                  loading: isSubmitting,
                })}
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};
