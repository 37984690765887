export const SALES_FLOOR_ENDPOINT_TYPES = {
  TOP_DISCUSSIONS: "top_discussions",
  GENERAL: "general",
  CAMPAIGN_SPECIFIC: "campaign-specific",
} as const;

export const GENERAL_SALES_FLOOR_CAMPAIGN_ID =
  process.env.NEXT_PUBLIC_ENV === "production"
    ? "7fa92440-9e5f-43d6-8d43-2fa1afe3776e"
    : "c8eeb60c-9bee-41aa-b719-11ff33e6c44a";
