import { FC, useCallback, useState, useEffect, useMemo } from "react";
import clsx from "clsx";

import { clsxMerge } from "shared/lib/helpers/styles";
import { SidebarCloseButton } from "./close-button";

interface SidebarPropsI {
  children?: any;

  /**
   * Content
   */
  title?: string;
  description?: string;

  /**
   * Decorations
   */
  className?: string;
  headerClassName?: string;
  descriptionClassName?: string;
  closeButtonClassName?: string;
  titleClassName?: string;
  titleWrapperClassName?: string;
  containerClassName?: string;
  backgroundBlurClassName?: string;
  overlayClassName?: string;

  /**
   * Flags
   */
  isFullPage?: boolean;
  isParentSidebar?: boolean;
  isOverlayClickable?: boolean;
  hideTitle?: boolean;
  hideBg?: boolean;
  hideCloseIcon?: boolean;
  disableAnimation?: boolean;

  /**
   * Custom Extensions
   */
  customCloseButton?: FC<Partial<{ onClick: () => void }>>;

  handleClose?: () => void;
}

export const SIDEBAR_CONTAINER_ID = "sidebar_container_id";
export const SIDEBAR_CLOSE_BTN_ID = "sidebar_close_btn_id";

const BackgroundBlur = ({
  showBlur,
  className,
}: {
  showBlur?: boolean;
  className?: string;
}) => (
  <div
    className={clsxMerge(
      "absolute left-0 top-0 -z-10 h-full w-full bg-[#F2F2F2]",
      {
        "backdrop-blur-lg": showBlur,
      },
      className
    )}
  />
);

const Sidebar: FC<SidebarPropsI> = ({
  children,

  title,
  description,

  /**
   * Decorations
   */
  className,
  headerClassName,
  descriptionClassName,
  closeButtonClassName,
  titleClassName,
  titleWrapperClassName,
  containerClassName,
  overlayClassName,
  backgroundBlurClassName,

  /**
   * Flags
   */
  isFullPage = true,
  isParentSidebar = true,
  isOverlayClickable = true,
  hideTitle,
  hideBg,
  hideCloseIcon,
  disableAnimation,

  /**
   * Custom Extensions
   */
  customCloseButton,

  handleClose,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const prepareForExit = () => {
    setVisible(false);
    setTimeout(() => handleClose?.(), 500);
  };

  const handleClickOnOverlay = useCallback(() => {
    if (isOverlayClickable) {
      prepareForExit();
    }
  }, [isOverlayClickable]);

  const CustomCloseButton = useMemo(
    () => customCloseButton,
    [customCloseButton]
  );

  return (
    <div
      className={clsxMerge(
        "left-0 top-0 z-50 h-full w-full",
        isFullPage ? "fixed" : "absolute"
      )}
    >
      {isParentSidebar && (
        <div
          className={clsx(
            "overlay h-full w-full",
            {
              "animate-fadein transition-all duration-300": !disableAnimation,
              "bg-black/30": visible,
              "bg-black/0 opacity-0": !visible,
            },
            overlayClassName
          )}
          onClick={handleClickOnOverlay}
        />
      )}

      <div
        id={SIDEBAR_CONTAINER_ID}
        className={clsxMerge(
          "absolute right-0 top-0 h-full w-[320px] overflow-hidden",
          "rounded-lg shadow-xl",
          {
            "transition-transform duration-500": !disableAnimation,
            "translate-x-[0]": visible,
            "translate-x-[100%]": !visible,
          },
          containerClassName
        )}
      >
        <div className="flex h-full flex-col">
          <div
            className={clsxMerge(
              "flex px-6 py-4",
              {
                "justify-end": isParentSidebar && !title && !description,
                "items-center justify-between":
                  !isParentSidebar || title || description,
              },
              headerClassName
            )}
          >
            <div className={clsxMerge("flex flex-col", titleWrapperClassName)}>
              {title && (
                <h3 className={clsxMerge("b-typography-h3", titleClassName)}>
                  {title}
                </h3>
              )}

              {description && (
                <p
                  className={clsxMerge("b-typography-b2", descriptionClassName)}
                >
                  {description}
                </p>
              )}
            </div>

            {CustomCloseButton && (
              <CustomCloseButton onClick={prepareForExit} />
            )}

            {!CustomCloseButton && (
              <SidebarCloseButton
                id={SIDEBAR_CLOSE_BTN_ID}
                className={closeButtonClassName}
                onClick={prepareForExit}
                isParentSidebar={isParentSidebar}
                hideCloseIcon={hideCloseIcon}
              />
            )}
          </div>

          <div
            className={clsxMerge(
              "grow overflow-y-auto overflow-x-hidden px-6 py-4",
              {
                "h-[calc(100vh-64px)]": isFullPage && !hideTitle,
                "h-[100vh]": isFullPage && hideTitle,
              },
              className
            )}
          >
            {children}
          </div>
        </div>

        {!hideBg && (
          <BackgroundBlur
            showBlur={isParentSidebar}
            className={backgroundBlurClassName}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
