import { BanknotesIcon } from "@heroicons/react/24/outline";
import { clsxMerge, formatNumbers } from "shared/lib/helpers";

export const CampaignCardPriceDescription = ({
  price,
  className,
}: {
  price?: number;
  className?: string;
}) => {
  return (
    <div className={clsxMerge("flex h-8 items-center", className)}>
      <div className="flex gap-2">
        <BanknotesIcon className="w-5 text-[#2EBA3C]" />
        <p className="ae-typography-body1 font-light">
          Over <b className="font-semibold">${formatNumbers(price)} paid</b> out
          all time
        </p>
      </div>
    </div>
  );
};
