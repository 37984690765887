import { LeadDescriptionContactItem } from "@/components/modules/calling/cards/lead-card/v2/lead-description/content";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { AddNewLeadButton } from "@/modules/calling/modes/widget-mini-dialer/expanded-view/buttons/add-new-lead-button";
import { EditLeadButton } from "@/modules/calling/modes/widget-mini-dialer/expanded-view/buttons/edit-lead-button";
import {
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { clsxMerge, fixUrl, formatUSPhoneNumber } from "shared/lib/helpers";
import { LogoLinkedInIcon, PhoneIcon } from "shared/ui/icons";

export const LeadInfoSection = ({ className }: { className?: string }) => {
  const { account, contact } = useInCallContext();
  const { website } = account || {};

  const { phone, mobile, email, linkedin } = contact || {};

  return (
    <section className={clsxMerge(className)}>
      <div className="flex items-center justify-between">
        <h6 className="brand-typgoraphy-body3">Lead Info</h6>

        <div className="flex items-center">
          <AddNewLeadButton className="relative z-[2] mr-0" />
          <EditLeadButton className="relative z-[2] pr-0" />
        </div>
      </div>
      <div>
        <LeadDescriptionContactItem
          isLink={!!email}
          url={`mailto:${email}`}
          icon={<AtSymbolIcon className="h-3 w-3" />}
          content={email || "No email"}
          className="brand-typography-body3 mb-1"
          theme="light"
        />

        <LeadDescriptionContactItem
          icon={<DevicePhoneMobileIcon className="h-3 w-3" />}
          content={
            mobile ? (formatUSPhoneNumber(mobile) as string) : "No mobile phone"
          }
          className="brand-typography-body3 mb-1"
          theme="light"
        />

        <LeadDescriptionContactItem
          icon={<PhoneIcon className="h-3 w-3" />}
          content={phone ? (formatUSPhoneNumber(phone) as string) : "No phone"}
          className="brand-typography-body3 mb-1"
          theme="light"
        />

        <LeadDescriptionContactItem
          isLink={!!linkedin}
          url={fixUrl(linkedin)}
          icon={<LogoLinkedInIcon className="h-3 w-3" />}
          content={linkedin || "No linkedin"}
          className="brand-typography-body3 mb-1"
          theme="light"
        />

        <LeadDescriptionContactItem
          isLink={!!website}
          url={fixUrl(website)}
          icon={<GlobeAltIcon className="h-3 w-3" />}
          content={website || "No website"}
          className="brand-typography-body3"
          theme="light"
        />
      </div>
    </section>
  );
};
