import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(duration);

export interface JwtI {
  iss?: string;
  sub?: string;
  aud?: Array<string>;
  iat?: number;
  exp?: number;
  azp?: string;
  scope?: string;
}

export const parseJwt = (token: string) => {
  if (!token) return {};

  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
};

export const getExpirationTime = (parsedJwt: JwtI) => {
  const now = dayjs.utc().valueOf();

  const date = dayjs.unix(parsedJwt.exp as number).toISOString();
  const millisecondsLeft = dayjs.unix(parsedJwt.exp as number).valueOf() - now;

  const hhmmss = dayjs
    .duration(millisecondsLeft, "millisecond")
    .format("DD[d] HH[h] mm[m] ss[s]");

  return { formated: hhmmss, millisecondsLeft: millisecondsLeft, date };
};
