import { PROSPECT_DISPOSITION_TYPES } from "shared/lib/constants/dispositions";
import { ContactDetailI } from "shared/lib/interfaces/account";

export const getLeadStateFlags = (contact: ContactDetailI) => {
  if (!contact?.contact_disposition_type) {
    return {
      isLeadDisqualified: false,
      isLeadMarkedDNC: false,
    };
  }

  return {
    isLeadDisqualified: [
      PROSPECT_DISPOSITION_TYPES.BAD_DATA,
      PROSPECT_DISPOSITION_TYPES.NOT_RELEVANT,
    ].includes(contact?.contact_disposition_type),
    isLeadMarkedDNC:
      contact?.contact_disposition_type ===
      PROSPECT_DISPOSITION_TYPES.DO_NOT_CALL,
  };
};
