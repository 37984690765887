import { AxiosResponse } from "axios";
import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";

import { meRoute_ResponseInterceptor } from "./me";

/**
 * Special handling for the specific routes
 */
export const routeResponseInterceptor = (
  response: AxiosResponse<any, any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  meRoute_ResponseInterceptor(response);
};
