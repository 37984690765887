import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge } from "shared/lib/helpers";

import { CALLING_FOLLOWUP_MODAL } from "@/modules/calling/modals/follow-up-modal";

import { HeartIcon } from "@heroicons/react/24/outline";
import { modal } from "@/components/modals";
import { CALLING_DISPOSITION_MODAL_ID } from "@/components/modules/calling/modals/disposition-modal";

export const CallbackButton = ({ className }: { className?: string }) => {
  const { callbackData, endAt, calendlyEvent, setCallbackData } =
    useInCallContext();

  const triggerSetCallbackModel = () => {
    if (!callbackData?.isCallbackSet && !endAt) {
      modal.trigger(CALLING_FOLLOWUP_MODAL);
    }

    if (!callbackData?.isCallbackSet && endAt) {
      setCallbackData({ isCallbackSet: true });

      setTimeout(() => {
        modal.trigger(CALLING_DISPOSITION_MODAL_ID);
      }, 300);
    }
  };

  return (
    <button
      className={clsxMerge(
        // For the tracking purposes
        "save-lead-btn",
        "btn-b-white btn-ae-l btn px-0",
        { disabled: callbackData?.isCallbackSet || calendlyEvent?.id },
        className
      )}
      onClick={triggerSetCallbackModel}
    >
      <HeartIcon className="mr-2 h-5" /> Set Callback
    </button>
  );
};
