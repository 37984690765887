import _isObjectLike from "lodash/isObjectLike";

import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { clsxMerge } from "shared/lib/helpers";

const formatValue = (value: any[] | string | number | null | undefined) => {
  if (!value) {
    return "N/A";
  }

  if (Array.isArray(value)) {
    // Format array values to a sequence string
    return value.join(", ");
  } else if (_isObjectLike(value)) {
    // Format object values to a JSON string
    return JSON.stringify(value);
  }

  return value;
};

export const ContactMetaFields = ({ className }: { className?: string }) => {
  const { contacts } = useAccountDetailsContext();
  const { contact } = useInCallContext();

  const metaFields = contacts?.find((c) => c.id === contact.id)?.meta?.values;

  if (!metaFields || metaFields.length === 0) {
    return null;
  }

  return (
    <div className={clsxMerge(className, "animate-fadein")}>
      <h6 className="mb-2 typography-body-4-bold">Additional details</h6>

      <div className="flex w-full flex-col gap-y-2 rounded-xl bg-[#F2F2F2] p-3">
        {metaFields?.map((meta, i) => (
          <div key={i}>
            <label className="typography-body-4-medium ">{meta.label}</label>

            <p className="break-words typography-body-4">
              {formatValue(meta.value)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
