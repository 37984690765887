import { FC } from "react";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardCallRecording } from "shared/ui/activity-log/cards-v2/base/call-recording";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

const ACTIVITY_TYPE_CONFIG = {
  icon: EnvelopeIcon,
  title: "User request email be sent",
};

const HistoryCardEmailRequestV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={ACTIVITY_TYPE_CONFIG} />
      <HistoryCardContactInfo contact={activity.contact} />
      <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
    </div>

    <div className="flex flex-col gap-4">
      {activity.call_recording_url?.recording_url && (
        <HistoryCardCallRecording
          recordingURL={activity.call_recording_url?.recording_url}
          transcriptURL={activity.call_transcript_url}
          hadDiscussion={!!activity.call_has_discussion}
        />
      )}

      <HistoryCardCallNotes notes={activity.notes} />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardEmailRequestV2;
