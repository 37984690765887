import { ReactNode, useMemo } from "react";
import { Editor } from "@tiptap/react";
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
} from "@heroicons/react/20/solid";
import { clsxMerge } from "shared/lib/helpers";

const ACTIVE_CLASSNAME = "opacity-100";
const INACTIVE_CLASSNAME = "opacity-100";

interface EditorMenuOptionI {
  label?: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const EditorMenu = ({
  className,
  editor,
}: {
  className?: string;
  editor: Editor | null;
}) => {
  const editorOptionsLeft: EditorMenuOptionI[] = useMemo(
    () => [
      {
        label: <BoldIcon className="w-4" />,
        onClick: () => editor?.chain().focus().toggleBold().run(),
        disabled: !editor?.can().chain().focus().toggleBold().run(),
        className: editor?.isActive("bold")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },
      {
        label: <ItalicIcon className="w-4" />,
        onClick: () => editor?.chain().focus().toggleItalic().run(),
        disabled: !editor?.can().chain().focus().toggleItalic().run(),
        className: editor?.isActive("italic")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },
      {
        label: <StrikethroughIcon className="w-4" />,
        onClick: () => editor?.chain().focus().toggleMark("strike").run(),
        disabled: !editor?.can().chain().focus().toggleMark("strike").run(),
        className: editor?.isActive("strike")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },
    ],
    [editor]
  );

  if (!editor) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "control-group rounded-t-lg border bg-black/5 p-2 px-3",
        className
      )}
    >
      <div className="button-group flex items-center gap-2">
        {editorOptionsLeft.map((opt, i) => (
          <button
            key={i}
            type="button"
            className={clsxMerge("", opt.className)}
            onClick={opt.onClick}
          >
            {opt.label}
          </button>
        ))}
      </div>
    </div>
  );
};
