import { FC, FormEvent, useMemo, useState } from "react";
import clsx from "clsx";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { TextArea, RadioButton } from "shared/ui/ae-user-input";

const EnhancedTextArea = TextArea();
const EnhancedRadioButton = RadioButton();

const SKIP_REASON_TYPES = {
  BAD_TITLE: "bad_title",
  BAD_COMPANY: "bad_company",
  BAD_TIMEZONE: "bad_timezone",
  OTHER: "other",
} as const;

type SkipReasonTypesI =
  (typeof SKIP_REASON_TYPES)[keyof typeof SKIP_REASON_TYPES];

interface SkipReasonPresetI {
  reason_type: SkipReasonTypesI;
  label: string;
  shouldDisqualify: boolean;
}

const SKIP_REASONS_PRESETS: Array<SkipReasonPresetI> = [
  {
    reason_type: SKIP_REASON_TYPES.BAD_TITLE,
    label: "Looks like a bad title based on qualification criteria",
    shouldDisqualify: true,
  },
  {
    reason_type: SKIP_REASON_TYPES.BAD_COMPANY,
    label: "Looks like a bad company based on qualification criteria",
    shouldDisqualify: true,
  },
  {
    reason_type: SKIP_REASON_TYPES.BAD_TIMEZONE,
    label: "Bad time of day to call this prospect",
    shouldDisqualify: false,
  },
  {
    reason_type: SKIP_REASON_TYPES.OTHER,
    label: "Other",
    shouldDisqualify: true,
  },
];

interface DialerSkipLeadModalPropsI {
  onSubmit?: (note: string, shouldDisqualify: boolean) => void;
  onClose?: () => void;
}

export const DIALER_SKIP_LEAD_MODAL_ID = "dialer_skip_lead_modal";

const ModalContent: FC<ModalChildrenFnPropsI & DialerSkipLeadModalPropsI> = ({
  handleClose,
  onSubmit = () => {},
}) => {
  const [skipReason, setSkipReason] = useState<SkipReasonPresetI>();
  const [note, setNote] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isNoteRequired = useMemo(
    () => skipReason?.reason_type === SKIP_REASON_TYPES.OTHER,
    [skipReason]
  );

  const handleSubmit = () => {
    let noteToSubmit = note;

    if (SKIP_REASON_TYPES.OTHER !== skipReason?.reason_type) {
      // set pre-defined note value for preset option type
      noteToSubmit = skipReason?.label;
    }

    if (noteToSubmit) {
      setIsSubmitting(true);
      handleClose();
      onSubmit(noteToSubmit, skipReason?.shouldDisqualify || false);
    }
  };

  return (
    <form
      onSubmit={(ev: FormEvent) => {
        ev.preventDefault();
        handleSubmit();
      }}
    >
      <div className="mb-2 flex flex-col gap-2">
        {SKIP_REASONS_PRESETS.map((reason) => (
          <EnhancedRadioButton
            name="skip_reason"
            label={reason.label}
            className="w-full"
            inputProps={{
              onChange: () => setSkipReason(reason),
              checked: skipReason?.reason_type === reason.reason_type,
            }}
          />
        ))}
      </div>

      {skipReason?.reason_type === SKIP_REASON_TYPES.OTHER && (
        <EnhancedTextArea
          name="note"
          placeholder="Tell us why :) 'N/A' is fine, but the more detailed, the better we can train the algorithms 🙏"
          inputClassName={"max-h-[50vh] min-h-[130px]"}
          inputProps={{
            value: note,
            onChange: (event: any) => {
              setNote((event?.target as HTMLInputElement)?.value);
            },
          }}
        />
      )}

      <div className="mt-6 flex justify-end gap-2">
        {/* <div className="max-w-[280px]">
          <EnhancedCheckbox
            name="skip_and_disqualify"
            label="Skip and disqualify"
            labelTextClassName="brand-typography-detail2 text-black/60"
            disabled
            inputProps={{
              checked: true, //we are keeping this always checked as per design
            }}
          />
        </div> */}

        <div className="flex gap-2">
          <button type="button" className="btn-ae-text" onClick={handleClose}>
            Cancel
          </button>

          <button
            type="submit"
            className={clsx("btn-ae-default w-[125px]", {
              loading: isSubmitting,
            })}
            disabled={!skipReason || (isNoteRequired && !note)}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export const DialerSkipLeadModal: FC<DialerSkipLeadModalPropsI> = ({
  onSubmit,
  onClose,
}) => (
  <Modal
    id={DIALER_SKIP_LEAD_MODAL_ID}
    title="Skip Prospect"
    description="Help us understand why you're skipping so we can improve our algorithms"
    onClose={onClose}
  >
    {(props) => <ModalContent {...props} onSubmit={onSubmit} />}
  </Modal>
);
