import { FC } from "react";

import {
  HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID,
  PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID,
} from "@/constants/element-ids";
import { clsxMerge } from "shared/lib/helpers";
import { V3PageHeaderComponents } from "@/components/primary-navigation/v3/page-header/components";
import { PrimaryNavigationV3NavBar } from "@/components/primary-navigation/v3/nav-bar";
import { PrimaryNavigationV3PageHeader } from "@/components/primary-navigation/v3/page-header";

interface PrimaryNavigationComponentPropsI {
  title?: any;
  children?: any;
  isBackgroundVisible?: boolean;
  contentContainerClassName?: string;
  pageContainerClassName?: string;
  headerClassName?: string;
  isCurrentTimeVisible?: boolean;
  hideNavbar?: boolean;
}

/**
 *
 * @param title page title to be placed in the page header bar (below nav bar). Omitting the `title` results in header bar not being rendered.
 * @param hideNavbar removes nav bar from the page.
 * @param children
 * @param isBackgroundVisible
 * @param contentContainerClassName
 * @param pageContainerClassName
 * @param headerClassName used for styling the header bar (the bar that appears below the nav bar).
 * @param isCurrentTimeVisible shows current time in the page header bar
 * @returns
 */
const PrimaryNavigationComponent: FC<PrimaryNavigationComponentPropsI> = ({
  title,
  hideNavbar,
  children,
  isBackgroundVisible = true,
  contentContainerClassName,
  pageContainerClassName,
  headerClassName,
  isCurrentTimeVisible = true,
}) => (
  <div
    className={clsxMerge(
      "relative z-0 h-[100vh] max-h-[100vh] min-w-[768px]",
      "overflow-x-auto overflow-y-hidden lg:overflow-x-hidden"
    )}
  >
    {/* ---------------- Background ---------------- */}
    {isBackgroundVisible && <V3PageHeaderComponents.Background />}

    <div className="z-1 relative flex h-[100vh] max-h-[100vh]">
      <div
        id={HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID}
        className={clsxMerge(
          "relative z-20 flex flex-grow flex-col overflow-y-auto overflow-x-hidden",
          pageContainerClassName
        )}
      >
        {/* ---------------- TOP NAVIGATION ---------------- */}
        {!hideNavbar && <PrimaryNavigationV3NavBar />}

        {/* ---------------- PAGE HEADER BAR ---------------- */}
        {title && (
          <PrimaryNavigationV3PageHeader
            className={headerClassName}
            title={title}
            isCurrentTimeVisible={isCurrentTimeVisible}
          />
        )}

        {/* ---------------- CONTENT ---------------- */}
        <div
          id={PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID}
          className={clsxMerge("w-full flex-grow", contentContainerClassName)}
        >
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default PrimaryNavigationComponent;
