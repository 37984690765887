import { AxiosInstance } from "axios";

import { checkIfClient } from "shared/lib/helpers";
import { interceptorRequest } from "./request";
import { interceptorResponse } from "./response";

export const interceptors = (instance: AxiosInstance) => {
  if (checkIfClient()) {
    interceptorRequest(instance);
    interceptorResponse(instance);
  }
};
