import { FC } from "react";
import clsx from "clsx";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip, TooltipTrigger, TooltipContent } from "shared/ui/tooltip";
import { PerformanceCardI } from "../interface";

const PerformanceCardV1: FC<PerformanceCardI> = ({
  subText,
  mainText,
  icon,
  className,
  loading,
  tooltipText,
}) => (
  <div className={clsx("card h-[116px] w-[230px] bg-white/70 p-4", className)}>
    {loading ? (
      <div className="flex h-full w-full items-center justify-center">
        <button
          className={clsx("btn btn-ghost bg-transparent", {
            loading: loading,
          })}
        ></button>
      </div>
    ) : (
      <>
        {icon && (
          <div className="mb-2 flex h-8 w-8 items-center justify-center rounded-full bg-black/5">
            {icon}
          </div>
        )}
        <h2 className="ae-typography-h2 mb-2">{mainText}</h2>
        <div className="flex gap-1">
          <p className="ae-typography-body3 text-black/40">{subText}</p>

          {tooltipText && (
            <Tooltip placement="right" colorScheme="light">
              <TooltipTrigger>
                <InformationCircleIcon className="h-4 w-4 text-black/40" />
              </TooltipTrigger>
              <TooltipContent className="max-w-[300px] p-4 text-sm">
                {tooltipText}
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </>
    )}
  </div>
);

export default PerformanceCardV1;
