import { RefObject, useEffect } from "react";
import { checkIfClient } from "shared/lib/helpers/utils";

type Handler = (event: MouseEvent) => void;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" | "click" = "mousedown"
): void => {
  useEffect(() => {
    if (!checkIfClient()) {
      return;
    }

    const eventHandler = (event: MouseEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(mouseEvent, eventHandler);

    return () => {
      document.removeEventListener(mouseEvent, eventHandler);
    };
  }, [handler]);
};
