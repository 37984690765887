import { CampaignV3I as CampaignI } from "shared/lib/interfaces/campaign";
import dayjs from "dayjs";

export const isCampaignNew = (created_at: string) => {
  const WEEKS_DELTA = 4;
  const now = dayjs();

  const diffWeeks = Math.abs(now.diff(created_at, "weeks"));

  return diffWeeks < WEEKS_DELTA;
};

export const isCampaignHiring = (
  job_description?: CampaignI["job_description"]
) => !!job_description?.url;
