import { TwilioProvider } from "./twilio-provider";
import {
  FrequentlyChangingCallingContextI,
  DefaultCallingContextI,
  InCallContextI,
} from "@/interfaces/contexts/calling";
import { useEffect, useState } from "react";
import {
  CallingContext,
  defaultContextObject,
  frequentlyChangingObject,
  inCallContextObject,
} from "./default-context";
import CallingFrequentlyChangingContentProvider from "./frequently-changing";

import InCallContextProvider from "./in-call-context";
import { DirectCallProvider } from "./direct-call";
import { DialerConfigI } from "@/interfaces/dialer/config";
import { LocalStorage } from "@/helpers/local-storage";
import { useEffectOnce } from "shared/lib/hooks";
import { glenSocketClientAPI } from "@/api/glen-socket";
import toast from "react-hot-toast";
import { DIALER_STATES, DIALER_VERSIONS } from "@/constants/dialer";
import { GetCampaignResponseI, glencocoClientAPI } from "@/api/glencoco";
import { asyncGet } from "@/helpers/context";

export interface DialerV1ProviderI {
  context?: DefaultCallingContextI;
  frequentlyChangingContext?: FrequentlyChangingCallingContextI;
  inCallContext?: InCallContextI;

  config?: DialerConfigI;
  children: any;
}

export const CallingContentProvider = ({
  context = defaultContextObject,
  frequentlyChangingContext = frequentlyChangingObject,
  inCallContext = inCallContextObject,

  config,
  children,
}: DialerV1ProviderI) => {
  const [version, setVersion] = useState(config?.version || context?.version);
  const [mode, setMode] = useState(config?.mode || context?.mode);

  // Define set functions
  const [device, setDevice] = useState(context.device);
  const [call, setCall] = useState(context.call);

  //Required for CallOnDemand and is expected to get from LeadInfo request
  const [callId, setCallId] = useState("");

  const [identity, setIdentity] = useState(context.identity);
  const [socket, setSocket] = useState(context.socket);
  const [inputDevices, setInputDevices] = useState(context.inputDevices);
  const [outputDevices, setOutputDevices] = useState(context.outputDevices);
  const [isMuted, setMuted] = useState(context.isMuted);
  const [isMusic, setIsMusic] = useState(context.isMusic);
  const [isCallOnDemand, setIsCallOnDemand] = useState(config?.isCallOnDemand);

  const [campaignId, setCampaignID] = useState(context.campaignId);
  const [campaign, setCampaign] = useState(context.campaign);

  const [leadInfo, setLeadInfo] = useState(context.leadInfo);
  const [callingState, setCallingState] = useState(context.callingState);

  const [autoDial, setAutoDial] =
    useState<boolean>(/*context.callerProfile.autoDial*/);

  const [leadQueue, setLeadQueue] = useState(context.leadQueue);

  const [isTourComplete, setIsTourComplete] = useState(context.isTourComplete);

  const [ctx, setCallingContext] = useState<DefaultCallingContextI>({
    version,
    setVersion,
    mode,
    setMode,

    device,
    setDevice,
    call,
    setCall,
    identity,
    setIdentity,
    socket,
    setSocket,
    inputDevices,
    setInputDevices,
    outputDevices,
    setOutputDevices,
    isMuted,
    setMuted,
    isMusic,
    setIsMusic,
    leadQueue,
    setLeadQueue,

    campaignId,
    setCampaignID,
    campaign,
    setCampaign,
    callingState,
    setCallingState,
    leadInfo,
    setLeadInfo,
    callerProfile: {
      autoDial,
      setAutoDial,
    },

    isCallOnDemand,
    setIsCallOnDemand,

    callOnDemandConfig: {
      callId,
      setCallId,
    },

    isTourComplete,
    setIsTourComplete,

    asyncGet: asyncGet,

    // clear: () => {
    //   setVersion(undefined);
    //   setMode(undefined);

    //   //TODO the cycle of life for the device is required to be investigated
    //   // setDevice(undefined);

    //   setCall(undefined);
    //   setIdentity(undefined);
    //   setSocket(undefined);
    //   setInputDevices(undefined);
    //   setOutputDevices(undefined);

    //   setLeadQueue([]);
    //   setCampaignID(undefined);
    //   setCampaign(undefined);
    //   setCallingState(undefined);
    //   setLeadInfo(undefined);
    //   setAutoDial(undefined);
    //   setIsCallOnDemand(undefined);
    //   setCallId("");
    //   setIsTourComplete(undefined);
    // },
  } as DefaultCallingContextI);

  useEffect(() => {
    setCampaignID(context.campaignId);
  }, [context?.campaignId]);

  useEffect(() => {
    if (!!campaignId && campaignId !== campaign?.id) {
      (async () => {
        const API = glencocoClientAPI();

        const GetCampaignResponse = await API.getCampaign(campaignId).catch(
          (e) => e
        );

        if (GetCampaignResponse?.status === 200) {
          const { campaign } =
            GetCampaignResponse?.data as GetCampaignResponseI;

          setCampaign(campaign);
        } else {
          toast.error("Failed to retrieve campaign data");
          setCallingState(DIALER_STATES.UNAUTHORIZED);
        }
      })();
    }
  }, [campaignId, campaign?.id]);

  // useEffect(() => {
  //   setAutoDial(context?.callerProfile?.autoDial);
  // }, [context?.callerProfile?.autoDial]);

  useEffect(() => {
    const API = glenSocketClientAPI();

    if (typeof autoDial === "boolean") {
      API.setAutoDial(autoDial).catch((err) => {
        toast.error("Failed to toggle auto dialing");
        console.log(err);
      });
    }
  }, [autoDial]);

  /**
   * NOTE: IF campaign has live transfer disable auto dialer
   */
  useEffect(() => {
    if (campaign && campaign?.has_live_transfer && autoDial) {
      setAutoDial(false);
    }
  }, [autoDial, campaign]);

  useEffect(() => {
    setCallingContext({
      ...ctx,
      version,
      mode,
      device,
      call,
      identity,
      socket,
      inputDevices,
      outputDevices,
      isMuted,
      isMusic,
      isCallOnDemand,

      campaignId,
      campaign,
      callingState,
      leadQueue,
      leadInfo,
      callerProfile: {
        ...ctx.callerProfile,
        autoDial,
      },
      callOnDemandConfig: {
        ...ctx.callOnDemandConfig,
        callId,
      },
      isTourComplete,
    });
  }, [
    device,
    call,
    identity,
    socket,
    inputDevices,
    outputDevices,
    isMuted,
    isMusic,
    isCallOnDemand,
    campaignId,
    campaign,
    callingState,
    leadInfo,
    autoDial,
    leadQueue,
    callId,
    isTourComplete,
  ]);

  useEffectOnce(() => {
    const LS = new LocalStorage();

    const settings = LS.callingModuleSettings;

    if (typeof settings?.isMusic === "boolean") {
      setIsMusic(settings?.isMusic);
    }
  });

  useEffect(() => {
    const LS = new LocalStorage();

    const settings = LS.callingModuleSettings;

    if (typeof isMusic === "boolean") {
      LS.callingModuleSettings = { ...settings, isMusic };
    }
  }, [isMusic]);

  // ONLY TO TEST DIALING
  // useEffect(() => {
  //   setTimeout(() => {
  //     setCallingState(DIALER_STATES.DIALING);
  //   }, 1000);
  // }, []);

  return (
    <CallingContext.Provider value={ctx as DefaultCallingContextI}>
      <CallingFrequentlyChangingContentProvider
        context={frequentlyChangingContext}
      >
        <InCallContextProvider context={inCallContext}>
          {!config?.disableTwilioProvider && (
            <TwilioProvider campaignId={campaignId} />
          )}

          {/* NOTE Legacy approach which will be replaced */}
          {!version || version === DIALER_VERSIONS.V1 ? (
            <DirectCallProvider config={config}>{children}</DirectCallProvider>
          ) : (
            children
          )}
        </InCallContextProvider>
      </CallingFrequentlyChangingContentProvider>
    </CallingContext.Provider>
  );
};

export default CallingContentProvider;
