import { FC } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";

import { clsxMerge } from "shared/lib/helpers";
import { CloseIcon } from "shared/ui/icons";
import { BorderWithGradient } from "shared/ui/border-with-gradient";
import { EmailTemplateI } from "@/api/routes/email";

interface EmailTemplatesListItemPropsI extends EmailTemplateI {
  className?: string;
  imgSrc?: string;
  isActive?: boolean;

  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const EmailTemplatesListItem: FC<EmailTemplatesListItemPropsI> = ({
  className,
  name,
  is_custom,
  imgSrc,
  isActive,

  onClick = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  return (
    <BorderWithGradient
      className={clsxMerge(
        "p-1",
        "rounded-[12px] transition-colors hover:bg-[#eee]",
        className
      )}
      containerClassName={clsxMerge("rounded-[14px]", {
        "bg-gradient-to-r from-[#ccc] via-[#ccc] to-[#ccc]": !isActive,
      })}
    >
      <div
        className="flex cursor-pointer items-center justify-between rounded-[12px] px-2 py-3"
        onClick={onClick}
      >
        <div className="flex items-center">
          <div className="ml-3 mr-2 h-[24px] w-[24px] shrink-0">
            <img
              className="m-auto h-full w-fit object-contain"
              src={imgSrc}
              alt=""
            />
          </div>

          <div
            className={clsxMerge("mx-2 typography-header-8-semibold", {
              "text-black/60": !isActive,
            })}
          >
            {name}
          </div>
        </div>

        {is_custom && (
          <div className="flex">
            <button
              className="btn-ae-s btn-ae-text px-1.5"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <PencilIcon className="w-4" />
            </button>

            <button
              className="btn-ae-s btn-ae-text px-1.5"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <CloseIcon className="w-5" />
            </button>
          </div>
        )}
      </div>
    </BorderWithGradient>
  );
};
