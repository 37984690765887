import { clsxMerge } from "shared/lib/helpers";
import { RadioButton } from "shared/ui";

const SimpleRadioButton = RadioButton();

export const DispositionConversationRow = ({
  value,
  onChange = () => {},

  className,
}: {
  value?: boolean;
  onChange?: (value: boolean) => void;

  className?: string;
}) => {
  const handleSelectOption = (bool: boolean) => () => {
    onChange(bool);
  };

  return (
    <div
      className={clsxMerge(
        "flex w-full flex-wrap items-center gap-x-10",
        className
      )}
    >
      <div className="min-w-[316px]">
        <label className="typography-body-4">
          Did you have a conversation with the prospect?
        </label>
      </div>
      <div className="flex items-center">
        <SimpleRadioButton
          label="Yes"
          name="disposition_conversation"
          inputProps={{
            checked: typeof value === "boolean" && !!value,
            onChange: handleSelectOption(true),
          }}
          className="w-32 border-none"
          inputClassName="scale-75"
          textClassName="ml-1"
        />

        <SimpleRadioButton
          label="No"
          name="disposition_conversation"
          inputProps={{
            checked: typeof value === "boolean" && !value,
            onChange: handleSelectOption(false),
          }}
          className="w-32 border-none"
          inputClassName="scale-75"
          textClassName="ml-1"
        />
      </div>
    </div>
  );
};
