import { createIcon } from "./icon-factory";

const AccountExecutive = createIcon(
  <path
    fill="currentColor"
    d="M24 7.714c-.001 1.42-1.24 2.57-2.77 2.572v.764c0 .473-.413.858-.922.858-.51 0-.923-.385-.923-.857v-.765h-1.847c-.509 0-.923-.385-.923-.857 0-.473.414-.858.924-.858h3.692c.509 0 .923-.384.923-.857 0-.472-.414-.857-.923-.857h-1.846c-1.53 0-2.77-1.152-2.77-2.571 0-1.42 1.24-2.572 2.77-2.572V.857c0-.473.414-.857.923-.857.509 0 .923.384.923.857v.857h1.846c.51 0 .923.385.923.857 0 .473-.414.858-.923.858h-3.692c-.51 0-.924.384-.924.857 0 .472.415.857.924.857h1.846c1.529.001 2.768 1.152 2.769 2.571ZM11.077 24H3.692C1.654 23.999.002 22.464 0 20.571v-5.365c.001-1.23.711-2.365 1.862-2.976l2.775-1.473a5.93 5.93 0 0 1 5.495 0l2.777 1.473c1.15.611 1.859 1.747 1.86 2.976v5.365C14.768 22.464 13.115 24 11.077 24ZM7.385 11.796a3.915 3.915 0 0 0-1.832.449l-2.777 1.473c-.575.306-.93.873-.93 1.488v5.365c.002.947.827 1.713 1.846 1.714h7.385c1.02 0 1.845-.767 1.846-1.714v-5.365c0-.615-.355-1.182-.929-1.488l-2.778-1.473a3.916 3.916 0 0 0-1.831-.449Zm0-3.225c-1.493 0-2.84-.835-3.411-2.116-.572-1.281-.256-2.756.8-3.736 1.056-.98 2.644-1.274 4.024-.743 1.379.53 2.279 1.78 2.279 3.167-.002 1.892-1.654 3.427-3.692 3.428Zm0-5.143c-.747 0-1.42.418-1.707 1.059-.285.64-.127 1.378.401 1.868.528.49 1.323.637 2.012.372.69-.266 1.14-.89 1.14-1.584-.002-.947-.827-1.713-1.846-1.714Z"
  />
);

export default AccountExecutive;
