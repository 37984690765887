import { ActionsBeforeCall } from "./before-call";
import { ActionsDuringCall } from "./during-call";
import { ActionsAfterCall } from "./after-call";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";

export const CatchupMissedCallActions = () => {
  const callStatus = useDialerCallStatus();

  return (
    <>
      {DIALER_CALL_STATUS.BEFORE === callStatus && <ActionsBeforeCall />}
      {DIALER_CALL_STATUS.DURING === callStatus && <ActionsDuringCall />}
      {DIALER_CALL_STATUS.AFTER === callStatus && <ActionsAfterCall />}
    </>
  );
};
