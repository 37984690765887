import { useState } from "react";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { ControlledDropdown } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";
import { DIALER_STATES } from "@/constants/dialer";

import { DialPadIcon } from "shared/ui/icons";
import DialPad from "@/modules/calling/control-menu/v1/dial-pad";
import { BasicButton } from "../basic-button";

export const DialpadButton = ({ className }: { className?: string }) => {
  const { callingState } = useCallingContext();

  const [isDialPadOpen, setDialPadOpen] = useState(false);

  return (
    <ControlledDropdown
      onOpen={() => setDialPadOpen(true)}
      onClose={() => setDialPadOpen(false)}
    >
      {() => (
        <>
          <BasicButton tabIndex={0} className={className}>
            <span className="mb-[6px]">
              <DialPadIcon className={clsxMerge("h-4 w-4")} />
            </span>

            <span className="brand-typography-detail3 whitespace-nowrap  text-black/90">
              Dial Pad
            </span>
          </BasicButton>

          <div
            tabIndex={0}
            className="dropdown-content translate-x-[-56px] translate-y-[-4px]"
          >
            <DialPad
              isOpen={isDialPadOpen}
              disabled={
                callingState !== DIALER_STATES.DIALING &&
                callingState !== DIALER_STATES.CALL
              }
            />
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
