import { AccountHistoryItemI } from "shared/lib/interfaces/account";
import { ACCOUNT_HISTORY_AI_PROCESS_MAP } from "shared/lib/constants/account";

export const aiIsGeneratingEmailCondition = (
  activityLog: AccountHistoryItemI
) =>
  !!activityLog.ai_email_generation_status &&
  activityLog.ai_email_generation_status ===
    ACCOUNT_HISTORY_AI_PROCESS_MAP.IN_PROGRESS;

export const aiEmailGenerationIsCompletedCondition = (
  activityLog: AccountHistoryItemI
) =>
  !!activityLog.ai_email_generation_status &&
  activityLog.ai_email_generation_status ===
    ACCOUNT_HISTORY_AI_PROCESS_MAP.DONE;
