import { useEffect, useMemo, useState } from "react";
import _debounce from "lodash/debounce";

import { useLatestValue } from "./use-latest-value";

const DEFAULT_DEBOUNCE_INTERVAL = 300;

export const useDebouncedValue = <T>(
  value: T,
  interval = DEFAULT_DEBOUNCE_INTERVAL
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debouncedValueSetterRaw = useMemo(
    () => _debounce(setDebouncedValue, interval),
    [interval]
  );

  // We don't want to put debouncedValueSetterRaw into useEffect dependencies
  // and get an extra run of setValue, so moving it to ref
  const debouncedValueSetterRef = useLatestValue(debouncedValueSetterRaw);

  useEffect(() => {
    if (debouncedValueSetterRef.current) {
      debouncedValueSetterRef.current(value);
    }
  }, [value]);

  return debouncedValue;
};
