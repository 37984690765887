import { FC, ReactNode } from "react";

import { clsxMerge } from "shared/lib/helpers";

/**
 * @param {string} containerClassName - The class name for the container which created the gradient effect.
 * @param {string} className - The class name for the component (children).
 * @param {ReactNode} children - The children of the component.
 * @param {boolean} isBorderDisabled - The flag to disable the border and turn the component into a regular wrapper for any ReactNode.
 */
interface BorderWithGradientPropsI {
  containerClassName?: string;
  className?: string;
  children: ReactNode;
  isBorderDisabled?: boolean;
  borderWidth?: keyof typeof BORDER_WIDTH_CLASS_NAMES;
}

const BORDER_WIDTH_CLASS_NAMES = {
  "0.5": "p-0.5",
  "1": "p-1",
  "2": "p-2",
};

// Use this component to implement blocks gradient border, which became pretty common in the latest designs.
export const BorderWithGradient: FC<BorderWithGradientPropsI> = ({
  containerClassName,
  className,
  children,
  isBorderDisabled,
  borderWidth = "0.5",
}) => (
  <div
    className={clsxMerge(
      !isBorderDisabled &&
        "overflow-hidden rounded-[6px] bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]",
      BORDER_WIDTH_CLASS_NAMES[borderWidth],
      containerClassName
    )}
  >
    <div
      className={clsxMerge(
        !isBorderDisabled && "rounded bg-white px-3 py-1",
        className
      )}
    >
      {children}
    </div>
  </div>
);
