import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { DIALER_WIDGET_VARIATIONS } from "@/constants/dialer";

interface DialerWidgetContextI {
  variation?: ValueOfObjectFields<typeof DIALER_WIDGET_VARIATIONS>;
  setVariation?: Dispatch<
    SetStateAction<ValueOfObjectFields<typeof DIALER_WIDGET_VARIATIONS>>
  >;
  isScrollbar?: boolean;
  notes?: string;
  note?: string;
  setIsScrollbar?: Dispatch<SetStateAction<boolean>>;
  setNote?: Dispatch<SetStateAction<string>>;
  setNotes?: Dispatch<SetStateAction<string>>;
}

interface DialerWidgetProviderPropsI {
  children?: ReactNode;
}

const DialerWidgetContext = createContext<DialerWidgetContextI>({
  variation: DIALER_WIDGET_VARIATIONS.DEFAULT,
});

export const useDialerWidgetContext = (): DialerWidgetContextI =>
  useContext(DialerWidgetContext);

export const DialerWidgetProvider: FC<DialerWidgetProviderPropsI> = ({
  children,
}) => {
  const [variation, setVariation] = useState<
    ValueOfObjectFields<typeof DIALER_WIDGET_VARIATIONS>
  >(DIALER_WIDGET_VARIATIONS.SIDEBAR);

  /**
   * ALL internally submitted notes
   */
  const [notes, setNotes] = useState("");

  /**
   * Current note that has not been submitted
   */
  const [note, setNote] = useState("");

  /**
   * This property is used to point out scroll for users
   */
  const [isScrollbar, setIsScrollbar] = useState(false);

  useEffect(() => {
    console.log(notes);
  }, [notes]);

  return (
    <DialerWidgetContext.Provider
      value={{
        variation,
        setVariation,
        notes,
        setNotes,
        note,
        setNote,
        isScrollbar,
        setIsScrollbar,
      }}
    >
      {children}
    </DialerWidgetContext.Provider>
  );
};
