import {
  addActivityLogMeetingBooked,
  formatCallerName,
} from "@/helpers/activity-log";

import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useGlobalContext } from "@/hooks/use-global-context";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";

export const useCalendly = () => {
  const { glencocoUser } = useGlobalContext();

  const inCallContext = useInCallContext();

  const {
    contact,

    setCalendlyEvent,
  } = inCallContext;

  const handleCalendlyBookingSuccess = (calendlyEvent: CalendlyEventI) => {
    setCalendlyEvent(calendlyEvent);

    addActivityLogMeetingBooked(inCallContext, {
      eventId: calendlyEvent.id,
      contact: {
        first_name: contact?.first_name,
        last_name: contact?.last_name,
        phone: contact?.phone,
        email: contact?.email,
        linkedin_url: contact?.linkedin,
        title: contact?.title,
      },
      timestamp: new Date().toLocaleString("en"),
      follow_up_time: calendlyEvent.start_time,
      notes: "",
      caller_name: formatCallerName(
        glencocoUser?.first_name || "",
        glencocoUser?.last_name || ""
      ),
    });
  };

  return { handleCalendlyBookingSuccess };
};
