import { FC } from "react";

import {
  DIALER_CALL_STATUS,
  DIALER_MODES,
  DIALER_VERSIONS,
} from "@/constants/dialer";
import { DialerV2Provider } from "@/context/dialer-v2";

import { defaultContextObject } from "@/context/dialer-v1//default-context";

import { FreeDnD } from "./free-dnd";

import { DialerWidgetConfigI } from "./interface";
import { DialerWidgetCard } from "./dialer-card";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
export * from "./interface";
export * from "./utils";

const DND_HANDLER_CLASSNAME = "dnd-handle";

export const WidgetDialer: FC<DialerWidgetConfigI> = ({
  contactId,
  campaignId,
  onChange,
}) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();

  const handleCallStatusChange = (
    callStatus?: ValueOfObjectFields<typeof DIALER_CALL_STATUS>
  ) => {
    onChange?.({
      callStatus,
    });
  };

  return isGlobalDialer ? (
    <FreeDnD handlerName={DND_HANDLER_CLASSNAME}>
      <DialerWidgetCard onChangeCallStatus={handleCallStatusChange} />
    </FreeDnD>
  ) : (
    //In the scenario when Global Dialer is not enabled
    //Initializing Dialer Provider Locally
    <DialerV2Provider
      context={{
        //TODO Make it option it include spread of defaultContextObject
        ...defaultContextObject,
        campaignId,
      }}
      config={{
        version: DIALER_VERSIONS.V2,
        mode: DIALER_MODES.WIDGET_MINI_DIALER,

        contact: {
          id: contactId,
        },
      }}
    >
      <FreeDnD handlerName={DND_HANDLER_CLASSNAME}>
        <DialerWidgetCard onChangeCallStatus={handleCallStatusChange} />
      </FreeDnD>
    </DialerV2Provider>
  );
};
