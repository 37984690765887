import { useRef, useState } from "react";

import { CallbackCalendarForm } from "@/components/modals/generic-callback-modal/calendar-form";
import { CallContactI } from "@/interfaces/dialer";

export const GENETIC_CALLBACK_MODAL_ID = "generic-callback-modal";

export const GenericCallbackModal = ({
  modalId = GENETIC_CALLBACK_MODAL_ID,
  follow_up_time,
  contact,
  notes,
  isLoading,
  onSubmit = async () => true,
}: {
  modalId?: string;
  follow_up_time?: string;
  contact?: CallContactI;
  notes?: string;
  isLoading?: boolean;
  onSubmit?: (date?: string, notes?: string) => Promise<boolean>;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const checkboxref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) => {
          onToogleHandler((event.target as HTMLInputElement)?.checked);
        }}
      />

      <div className="modal cursor-pointer">
        <div className="set-callback-modal modal-box relative max-h-[calc(100vh-50px)] w-[520px] rounded-md p-6">
          <label
            htmlFor={modalId}
            className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 text-black/40"
          >
            ✕
          </label>

          {isVisible && (
            <CallbackCalendarForm
              isLoading={isLoading}
              initialDate={follow_up_time}
              initialTime={follow_up_time}
              title="Set Callback"
              description="Specify date and time when to remind you to call back"
              isNotes={true}
              notes={notes}
              isNotesRequired={true}
              cancelButton={{ type: "label", htmlFor: modalId, text: "Cancel" }}
              successButton={{
                type: "button",
                text: "Save",
              }}
              onSubmit={onSubmit}
              contact={contact}
            />
          )}
        </div>
      </div>
    </>
  );
};
