import { FC, useMemo } from "react";
import _max from "lodash/max";

import { ListPillI } from "@/api/routes/list";
import { clsxMerge } from "shared/lib/helpers";
import { ListSelectorMenuCategory } from "./category";

interface ListSelectorMenuPropsI {
  className?: string;
  items: ListPillI[] | undefined;
  activeListId: string | undefined | null;
  setActiveListId: (newActiveListId: string) => void;
  onClose: () => void;
}

export const ListSelectorMenu: FC<ListSelectorMenuPropsI> = ({
  className,
  items,
  activeListId,
  setActiveListId,
  onClose,
}) => {
  const { topCallerLists, fixedLists, customLists } = useMemo(() => {
    const topCallerLists: ListPillI[] = [];
    const fixedLists: ListPillI[] = [];
    const customLists: ListPillI[] = [];

    items?.forEach((item) => {
      if (item.is_exclusive_list) {
        topCallerLists.push(item);
      } else if (item.is_default_list) {
        fixedLists.push(item);
      } else {
        customLists.push(item);
      }
    });

    return { topCallerLists, fixedLists, customLists };
  }, [items]);

  const isDataAvailable = useMemo(
    () =>
      [topCallerLists, fixedLists, customLists].some((list) => list.length > 0),
    [topCallerLists, fixedLists, customLists]
  );

  const listHeightPx = useMemo(() => {
    const maxListsInAnyColumn =
      _max(
        [topCallerLists, fixedLists, customLists].map(
          (collection) => collection.length
        )
      ) || 0;

    return (maxListsInAnyColumn + 1) * 50;
  }, [topCallerLists, fixedLists, customLists]);

  const handleSelectList = (listId: string) => {
    onClose();

    if (listId !== activeListId) {
      setActiveListId(listId);
    }
  };

  return (
    <div
      className={clsxMerge(
        "absolute left-0 top-full z-50",
        "animate-fadein rounded bg-white shadow-[0px_0px_4px_4px_rgba(0,0,0,0.06)]",
        isDataAvailable
          ? "grid max-h-[850px] w-[850px] grid-cols-3 grid-rows-1"
          : "w-max px-3 py-2",
        className
      )}
      style={{ height: isDataAvailable ? `${listHeightPx}px` : "auto" }}
    >
      {isDataAvailable ? (
        <>
          <ListSelectorMenuCategory
            categoryName="Top caller lists"
            items={topCallerLists}
            activeListId={activeListId}
            onSelect={handleSelectList}
          />

          <ListSelectorMenuCategory
            categoryName="Fixed lists"
            items={fixedLists}
            activeListId={activeListId}
            onSelect={handleSelectList}
          />

          <ListSelectorMenuCategory
            categoryName="Custom lists"
            items={customLists}
            activeListId={activeListId}
            onSelect={handleSelectList}
          />
        </>
      ) : (
        <span className="text-[#666] typography-body-5-medium">
          You don't have any lists yet.
        </span>
      )}
    </div>
  );
};
