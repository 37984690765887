import { useEffect } from "react";

import { DIALER_STATES } from "@/constants/dialer";
import { DialerConfigI } from "@/interfaces/dialer/config";
import Calling, {
  askPermissionMic,
  getWebSocketConnection,
} from "@/helpers/calling";

import { toast } from "react-hot-toast";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

export const DirectCallProvider = ({
  children,
  config,
}: {
  children: any;
  config?: DialerConfigI;
}) => {
  const context = useCallingContext();
  const inCallContext = useInCallContext();

  useEffect(() => {
    (async () => {
      if (context.campaign?.id) {
        // TODO unite API calls just make input parameters different eventId, contactId
        if (
          config?.isCallOnDemand &&
          context.campaignId &&
          (config?.activity?.event?.id || config?.contact?.id)
        ) {
          /**
           * START CALLING ON DEMAND FROM THE DASHBOARD
           */

          const isMicAllowed = await askPermissionMic();
          if (isMicAllowed) {
            getWebSocketConnection(context);
          } else {
            toast("Permission to use audio devices is required to make calls.");

            context.setCallingState(DIALER_STATES.PRE);
            return;
          }

          context.callerProfile.setAutoDial(false);
          // context.setCallingState(DIALER_STATES.DIALING);

          // NOTE - timeout workaround is to let other providers finish initialization
          //        specifically Twillio Provider
          //        if solution will continue to get more complicated
          //        the whole module should be split.
          //        The default behavior and on demand should become a separate calling modules
          //        Right now it is a single module which handles both flows.

          setTimeout(() => {
            let params: { itemId?: string; contactId?: string } = {};

            if (config?.activity?.event?.id)
              params = { itemId: config?.activity?.event?.id as string };

            if (config?.contact?.id)
              params = { contactId: config?.contact?.id as string };

            Calling.startCall({
              context,
              inCallContext,
              ...params,
            });
          }, 1000);
        } else {
          /**
           * START CALLING BY DEFAULT
           */
          context.setCallingState(DIALER_STATES.PRE);
        }
      } else {
        context.setCallingState(DIALER_STATES.LOADING);
      }
    })();
  }, [context.campaign?.id]);

  return <>{children}</>;
};
