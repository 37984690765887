import { CampaignCardLabelValueI } from "shared/ui/cards/campaign-card/campaign-card-default";
import {
  CAMPAIGN_CARD_PERFORMANCE_TYPES,
  CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP,
} from "shared/ui/cards/campaign-card";
import { formatNumbers } from "shared/lib/helpers/numbers";

export const CampaignCardPerformance = ({
  stats,
}: {
  stats?: Array<CampaignCardLabelValueI>;
}) => {
  return (
    <div>
      <p className="b-typography-h5 mb-3">Your Performance</p>

      <div className="flex justify-between">
        {stats?.map((stat, i) => (
          <span key={i} className="min-w-[144px] border-b-2 pb-4">
            <p className="b-typography-detail3">{stat.label}</p>

            <p className="b-typography-h5">
              {stat.label ===
              CAMPAIGN_CARD_PERFORMANCE_TYPES_MAP[
                CAMPAIGN_CARD_PERFORMANCE_TYPES.SHOW_UP_RATE
              ]
                ? `${formatNumbers(stat.value || 0, 2, { style: "percent" })}`
                : formatNumbers(stat.value)}
            </p>
          </span>
        ))}
      </div>
    </div>
  );
};
