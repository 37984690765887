import clsx from "clsx";
import { useEffect, useState } from "react";

export const LeadsCarouselDecorations = ({
  uniqueKeyToTriggerTansition,
}: {
  uniqueKeyToTriggerTansition?: string;
}) => {
  const [isTransition, setIsTransition] = useState(false);
  const [isTransitionVisible, setIsTransitionVisible] = useState(false);

  useEffect(() => {
    setIsTransition(isTransitionVisible);

    if (isTransitionVisible) {
      setTimeout(() => {
        setIsTransitionVisible(false);
      }, 1000);
    }
  }, [isTransitionVisible]);

  useEffect(() => {
    if (uniqueKeyToTriggerTansition) {
      setIsTransitionVisible(true);
    }
  }, [uniqueKeyToTriggerTansition]);

  return (
    <>
      <div
        className={clsx(
          "absolute",
          "top-0",
          "h-full w-full",
          "rounded-lg",
          "[backdrop-filter:blur(25px)]",

          {
            "transition-all duration-300": isTransitionVisible,
            "left-[calc(4px*24)]  scale-[0.7] bg-white/0 ": !isTransition,
            "left-[calc(4px*18)] scale-[0.8] bg-white/20 ": isTransition,
          }
        )}
      />

      <div
        className={clsx(
          "absolute",
          "top-0",
          "h-full w-full",
          "rounded-lg",
          "[backdrop-filter:blur(25px)]",

          {
            "transition-all duration-300": isTransitionVisible,
            "left-[calc(4px*18)] scale-[0.8] bg-white/20 ": !isTransition,
            "left-10 scale-[0.9] bg-white/50 ": isTransition,
          }
        )}
      />

      <div
        className={clsx(
          "absolute",
          "top-0",
          "h-full w-full",
          "rounded-lg",
          "[backdrop-filter:blur(25px)]",
          {
            "transition-all duration-300": isTransitionVisible,
            "left-10 scale-[0.9] bg-white/50 ": !isTransition,
            "left-[0] scale-[1]  bg-white/100 ": isTransition,
          }
        )}
      />
    </>
  );
};
