import { useContext } from "react";
import {
  CallingContext,
  FrequentlyChangingCallingContext,
  InCallContext,
} from "@/context/dialer-v1/default-context";
import {
  FrequentlyChangingCallingContextI,
  DefaultCallingContextI,
  InCallContextI,
} from "@/interfaces/contexts/calling";

export const useCallingContext = (): DefaultCallingContextI => {
  return useContext(CallingContext);
};

export const useFrequentlyChangingCallingContext =
  (): FrequentlyChangingCallingContextI => {
    return useContext(FrequentlyChangingCallingContext);
  };

export const useInCallContext = (): InCallContextI => {
  return useContext(InCallContext);
};
