import { DIALER_MODES, DIALER_VERSIONS } from "@/constants/dialer";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { DialerDefaultMode } from "@/modules/calling/modes/default";
import { DialerCatchupMode } from "@/modules/calling/modes/catchup";

import { WidgetDialerMiniMode } from "@/modules/calling/modes/widget-mini-dialer";
import { DialerCatchupWalkthroughMode } from "@/modules/calling/modes/catchup/walkthrough";

export const DialerModule = () => {
  const { version, mode } = useCallingContext();

  return (
    <>
      {/** Handles both DEFAULT (TARGETED DIAL OR AUTODIALER as a single mode) and DEMO modes */}
      {(DIALER_VERSIONS.V1 === version || !version) && <DialerDefaultMode />}

      {DIALER_VERSIONS.V2 === version && (
        <>
          {DIALER_MODES.CATCHUP === mode && <DialerCatchupMode />}

          {DIALER_MODES.CATCHUP_WALKTHROUGH === mode && (
            <DialerCatchupWalkthroughMode />
          )}

          {/* Widget modes */}
          {DIALER_MODES.WIDGET_MINI_DIALER === mode && <WidgetDialerMiniMode />}
        </>
      )}
    </>
  );
};
