import clsx from "clsx";
import { twMerge } from "tailwind-merge";

const EFFECTS = { BOUNCE: "bounce" };

export const Ellipsis = ({
  className,
  elClassName,
  effect,
}: {
  className?: string;
  elClassName?: string | Array<string>;
  effect?: "bounce" | "";
}) => {
  let animationClasses = [""];

  if (effect === EFFECTS.BOUNCE) {
    animationClasses = [
      "animate-bounce",
      "animate-bounce animation-delay-100",
      "animate-bounce animation-delay-300",
    ];
  }

  return (
    <div
      className={twMerge(
        clsx("flex w-4 items-center justify-between", className)
      )}
    >
      {[1, 2, 3].map((v, i) => (
        <div
          key={v}
          className={twMerge(
            clsx(
              "h-1 w-1 rounded-full bg-white",
              typeof elClassName === "string" ? elClassName : elClassName?.[i],
              animationClasses[i]
            )
          )}
        />
      ))}
    </div>
  );
};
