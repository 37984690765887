import { FC, createContext, useContext, ReactNode } from "react";
import _noop from "lodash/noop";

import { useDisclosure } from "shared/lib/hooks/use-disclosure";

type EmailTemplatesSidebarContextI = Omit<
  ReturnType<typeof useDisclosure>, // isOpen, onOpen, onClose
  "onToggle" // no need for onToggle
>;

interface EmailTemplatesProviderPropsI {
  children: ReactNode;
}

export const EmailTemplatesSidebarContext =
  createContext<EmailTemplatesSidebarContextI>({
    isOpen: false,
    onOpen: _noop,
    onClose: _noop,
  });

export const useEmailTemplatesSidebarContext = () =>
  useContext(EmailTemplatesSidebarContext);

export const EmailTemplatesSidebarProvider: FC<
  EmailTemplatesProviderPropsI
> = ({ children }) => {
  const disclosure = useDisclosure();

  return (
    <EmailTemplatesSidebarContext.Provider value={{ ...disclosure }}>
      {children}
    </EmailTemplatesSidebarContext.Provider>
  );
};
