import { APII, glencocoClientAPI } from "@/api/glencoco";
import { NotificationI } from "@/interfaces/notification";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useInfinitePagination } from "shared/lib/hooks";

import uniqBy from "lodash/uniqBy";
import { GetNotificationsResponseI } from "@/api/routes/notifications";

export interface LocalNotificationsContextI {
  notifications?: Array<NotificationI>;
  unreadNotifications?: Array<NotificationI>;

  isReachedEnd?: boolean;
  isLoading?: boolean;

  loadMore?: () => void;
  /**
   * Request the latest notification.
   * Used when there are indicators that
   * current data is outdated.
   */
  update?: () => void;

  /** So developer could update notification on demand */
  setNotifications?: Dispatch<SetStateAction<Array<NotificationI>>>;
  closePanel?: () => void;
}

const LocalNotificationsContext = createContext({});

const notificationsFetcher = (nextToken?: string) => {
  const api = glencocoClientAPI();

  return api.getNotifications({ nextToken });
};

const selectorUnread = (notifications?: Array<NotificationI>) =>
  notifications?.filter((n) => !n.is_seen) || [];

/**
 *
 * @param close() - External function that should act as close panel mechanism.
 *                  Internally panel doesn't control it's open or closed state
 */
export const LocalNotificationsProvider = ({
  onClosePanel: closePanel,
  children,
}: {
  onClosePanel?: () => void;
  children?: any;
}) => {
  const { data, loadMore, isReachedEnd, isLoading } = useInfinitePagination<
    NotificationI,
    APII
  >({
    isInitiallyLoading: true,
    apiFactory: glencocoClientAPI,
    fetchMore: (nextToken) => notificationsFetcher(nextToken),
    collectionKeyInResponse: "notifications",
    errorMessage:
      "Failed to load notifications history. Please contact Glencoco.",
  });

  useEffect(() => {
    loadMore();
  }, []);

  const [notifications, setNotifications] = useState(data);
  const [unreadNotifications, setUnreadNotifications] = useState(
    selectorUnread(data)
  );

  useEffect(() => {
    setNotifications(data);
    setUnreadNotifications(selectorUnread(data));
  }, [data]);

  useEffect(() => {
    setUnreadNotifications(selectorUnread(notifications));
  }, [notifications]);

  const update = async () => {
    const LatestNotificationsResp = await notificationsFetcher().catch(
      (e) => e
    );

    if (LatestNotificationsResp.status === 200) {
      const latest =
        (LatestNotificationsResp.data as GetNotificationsResponseI)
          ?.notifications || [];

      if (latest.length)
        setNotifications(uniqBy([...latest, ...notifications], "id"));
    }
  };

  return (
    <LocalNotificationsContext.Provider
      value={{
        notifications,
        unreadNotifications,

        isReachedEnd,
        isLoading,

        loadMore,
        update,
        closePanel,
        setNotifications,
      }}
    >
      {children}
    </LocalNotificationsContext.Provider>
  );
};

export const useLocalNotificationsContext = (): LocalNotificationsContextI =>
  useContext(LocalNotificationsContext);
