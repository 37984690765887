import { ComponentType, FC } from "react";

import { HistoryCardTextInfoItem } from "shared/ui/activity-log/cards-v2/base";
import { clsxMerge } from "shared/lib/helpers";

interface HistoryCardActivityTypeConfigI {
  icon: ComponentType<{ className?: string }>;
  title: string;
  isHot?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
}

interface HistoryCardActivityTypePropsI {
  config: HistoryCardActivityTypeConfigI;
}

export const HistoryCardActivityType: FC<HistoryCardActivityTypePropsI> = ({
  config: { icon: Icon, title, isHot, isWarning, isSuccess },
}) => (
  <HistoryCardTextInfoItem
    icon={
      <div className="relative">
        {isHot && (
          <span className="absolute left-1/2 -mt-4 -translate-x-1/2">🔥</span>
        )}

        <Icon
          className={clsxMerge("h-6 w-6", {
            "text-error-content": isHot,
          })}
        />
      </div>
    }
    subtitle="Type"
    infoClassName={clsxMerge(
      "max-w-[220px]",
      (isHot || isWarning) && "text-error-content",
      isSuccess && "text-success-content"
    )}
    info={[{ title, isBold: true }]}
  />
);
