import { createIcon } from "./icon-factory";

const BillIcon = createIcon(
  <path
    fill="currentColor"
    d="M3 0v23.95l2.78-2.011 2.062 2.03 2.064-2.03L12 24l2.095-2.061 2.063 2.03 2.062-2.03L21 23.995V0H3Zm16.63 21.096-1.409-1.07-2.062 2.03-2.063-2.03L12 22.087l-2.095-2.061-2.063 2.03-2.062-2.03-1.409 1.07V1.356h15.258v19.74ZM16.46 5.32H7.54v-.904h8.921l.001.904ZM12 14.534H7.539v-.904H12v.904Zm4.461-4.607H7.54v-.904h8.921l.001.904Z"
  />
);

export default BillIcon;
