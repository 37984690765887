import { FC, useMemo } from "react";
import { PhoneIcon, QueueListIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardCallRecording } from "shared/ui/activity-log/cards-v2/base/call-recording";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import {
  HistoryCardBookedMeetingInfo,
  HistoryCardBookedMeetingOriginalInfo,
} from "shared/ui/activity-log/cards-v2/base/event-date";
import { HistoryCardAEInfo } from "shared/ui/activity-log/cards-v2/base/ae-info";
import {
  DISPOSITIONS,
  DISPOSITIONS_STRING_MAP_V2,
} from "shared/lib/constants/dispositions";
import { AccountHistoryCardQualificationCriteria } from "shared/ui/activity-log/cards-v2/base/qualification-criteria";
import { clsxMerge, getS3AssetPath } from "shared/lib/helpers";

const MeetingQualifiedIcon: FC<{ className?: string }> = ({ className }) => (
  <img
    alt="Meeting Qualified"
    className={clsxMerge(className, "h-8 w-8")}
    src={getS3AssetPath("platform/activity-logs/meeting-qualified.webp")}
  />
);

const PMS_TYPES_TO_SHOW_QC_SUMMARY = [
  DISPOSITIONS.NOT_QUALIFIED,
  DISPOSITIONS.MEETING_QUALIFIED,
  DISPOSITIONS.MEETING_NOT_QUALIFIED,
] as const;

const ACTIVITY_TYPE_DEFAULT_CONFIG = {
  icon: PhoneIcon,
  title: "Booked meeting",
};

const HistoryCardBookedMeetingV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => {
  const meetingDisposition = activity?.meeting_disposition;
  const shouldShowQualificationCriteria =
    meetingDisposition &&
    PMS_TYPES_TO_SHOW_QC_SUMMARY.includes(activity.meeting_disposition as any);

  const isQualifiedMeetings =
    meetingDisposition === DISPOSITIONS.MEETING_QUALIFIED;

  const activityTypeConfig = useMemo(() => {
    if (meetingDisposition) {
      return {
        icon: isQualifiedMeetings ? MeetingQualifiedIcon : QueueListIcon,
        isSuccess: isQualifiedMeetings,
        isWarning: meetingDisposition === DISPOSITIONS.MEETING_NO_SHOW,
        title: DISPOSITIONS_STRING_MAP_V2[meetingDisposition],
      };
    }

    return ACTIVITY_TYPE_DEFAULT_CONFIG;
  }, [meetingDisposition]);

  const isRescheduled = useMemo(
    () => meetingDisposition === DISPOSITIONS.MEETING_RESCHEDULED,
    [meetingDisposition]
  );

  return (
    <AccountHistoryCard
      createdAt={activity.created_at}
      className={clsxMerge({
        "border-success-content": isQualifiedMeetings,
      })}
    >
      <div className="flex flex-col gap-4">
        <HistoryCardActivityType config={activityTypeConfig} />

        <HistoryCardBookedMeetingInfo
          isBold={!meetingDisposition || isRescheduled}
          date={activity.follow_up_time}
        />

        {isRescheduled && (
          <HistoryCardBookedMeetingOriginalInfo
            date={activity.original_meeting_time}
          />
        )}

        <HistoryCardContactInfo contact={activity.contact} />
        <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
        <HistoryCardAEInfo aeName={activity.ae_name || "N/A"} />
      </div>

      <div className="flex flex-col gap-4">
        {!!activity.call_recording_url?.recording_url && (
          <HistoryCardCallRecording
            recordingURL={activity.call_recording_url?.recording_url}
            transcriptURL={activity.call_transcript_url}
            hadDiscussion={!!activity.call_has_discussion}
          />
        )}

        {shouldShowQualificationCriteria && (
          <AccountHistoryCardQualificationCriteria
            qualificationCriteria={activity?.qualification_criteria}
          />
        )}

        <HistoryCardCallNotes
          className={clsxMerge(shouldShowQualificationCriteria && "mt-2")}
          isCustomerNotes={activity.is_customer}
          notes={activity.notes}
        />
      </div>
    </AccountHistoryCard>
  );
};

export default HistoryCardBookedMeetingV2;
