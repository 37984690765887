import React, { FC } from "react";

import { CenteredSpinner } from "../spinners";
import useFetchCallTranscript from "shared/lib/hooks/use-fetch-call-transcript";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { clsxMerge } from "shared/lib/helpers";

interface HistoryCardCallRecordingPropsI {
  className?: string;
  titleClassName?: string;
  transcriptClassName?: string;
  transcriptURL?: string;
}

const CallRecordingTranscript: FC<HistoryCardCallRecordingPropsI> = ({
  className,
  titleClassName,
  transcriptClassName,
  transcriptURL,
}) => {
  const {
    loadTranscript,
    isLoading: isTranscriptLoading,
    transcript,
  } = useFetchCallTranscript(transcriptURL);

  useEffectOnce(() => {
    loadTranscript();
  });

  if (!transcriptURL) {
    return null;
  }

  return (
    <div className={className}>
      <h5 className={clsxMerge("brand-typography-h5", titleClassName)}>
        Transcript
      </h5>

      {isTranscriptLoading && <CenteredSpinner />}

      {transcript.length > 0 && (
        <div
          className={clsxMerge(
            "mt-1 flex max-h-[215px] flex-col overflow-y-auto",
            transcriptClassName
          )}
        >
          {transcript.map(([prefix, line], index) => (
            <p key={index} className="-tracking-[0.28px] text-[#333]">
              <span className="brand-typography-h3">{prefix}</span>
              <span className="brand-typography-body3">{line}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CallRecordingTranscript;
