import { modal } from "@/components/modals";
import DangerousActionConfirmationModal from "@/components/modals/dangerous-action-confirmation-modal";
import { CampaignI } from "@/interfaces/campaign";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import CalendlyModal, {
  CALENDLY_MODAL_ID,
} from "@/modals/book-meeting-modal/calendly-modal";
import { useMemo, useState } from "react";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { DIALER_LIVE_TRANSFER_STATUSES } from "@/constants/dialer";

import Calling from "@/helpers/calling";
import { useCalendly } from "@/modules/calling/hooks/use-calendly";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";

export const CALLING_MODULE_LIVE_TRANSFER_MODAL_ID = "live-transfer-modal";
export const CALLING_MODULE_LIVE_CALENDLY_MODAL_ID = `live-transfer-${CALENDLY_MODAL_ID}`;
export const CALLING_MODULE_LIVE_TRANSFER_FINAL_ID = `live-transfer-final`;

export const LiveTransferModal = ({
  modalId = CALLING_MODULE_LIVE_TRANSFER_MODAL_ID,
}: {
  modalId?: string;
}) => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();
  const { handleCalendlyBookingSuccess } = useCalendly();
  const { setLiveTransferStatus, calendly_uri, contact, account } =
    inCallContext;
  const { campaign, call } = callingContext;

  const [isLoading, setIsLoading] = useState(false);

  const campaignName = useMemo(
    () => (campaign?.name ? campaign.name : "Campaign"),
    [campaign?.name]
  );

  const handleOnCalendlyMeetingBooked = (calendlyEvent: CalendlyEventI) => {
    handleCalendlyBookingSuccess(calendlyEvent);

    setLiveTransferStatus(
      DIALER_LIVE_TRANSFER_STATUSES.CALENDLY_BOOKING_COMPLETE
    );
    modal.trigger(CALLING_MODULE_LIVE_TRANSFER_FINAL_ID);
  };

  const handleOnLiveTransfer = async () => {
    setIsLoading(true);

    const LiveTransferResponse = await Calling.liveTransfer({
      context: callingContext,
      inCallContext,
    });

    // NOTE: Error Logic is already handled in liveTransfer function
    if (LiveTransferResponse?.status === 200) {
      setLiveTransferStatus(
        DIALER_LIVE_TRANSFER_STATUSES.LIVE_TRANSFER_COMPLETE
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      <DangerousActionConfirmationModal
        modalId={modalId}
        title={`Book Calendly Event Before Live Transfer`}
        description={`${campaignName} requires you to book the live meeting before accepting the transfer.`}
        icon={<CalendarDaysIcon className="mb-4 h-6 w-6" />}
        mainButtonText="Book Calendly Event"
        callback={() => modal.trigger(CALLING_MODULE_LIVE_CALENDLY_MODAL_ID)}
      />

      <CalendlyModal
        campaign={campaign as CampaignI}
        callId={call?.customParameters.get("call_id") as string}
        calendlyUri={calendly_uri}
        contact={contact}
        account={account}
        modalId={CALLING_MODULE_LIVE_CALENDLY_MODAL_ID}
        onSuccess={handleOnCalendlyMeetingBooked}
      />

      <DangerousActionConfirmationModal
        modalId={CALLING_MODULE_LIVE_TRANSFER_FINAL_ID}
        title={`You’re ready to transfer this lead to ${campaignName}`}
        description={`Your lead will be transferred to the ${campaignName} call center.`}
        icon={<CalendarDaysIcon className="mb-4 h-6 w-6" />}
        mainButtonText="Start Live Transfer"
        isLoading={isLoading}
        callback={handleOnLiveTransfer}
      />
    </>
  );
};
