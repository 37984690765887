import { FC } from "react";
import { useRouter } from "next/router";

import { NotificationBase } from "@/modules/notifications/notification/base";
import { NotificationI } from "@/interfaces/notification";
import RenderHTML from "shared/ui/render-html";
import Router from "@/helpers/router";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

type NotificationCustomerForumPostPropsI = NotificationI & PropsWithClassNameI;

export const NotificationCustomerForumPost: FC<
  NotificationCustomerForumPostPropsI
> = (props) => {
  const {
    className,

    id,
    campaign_id,

    is_seen,
    created_at,

    campaign_name,
    customer_name,
    post_id,
    post_title,
    post_body,
  } = props;

  const { push } = useRouter();

  const openPost = () => {
    if (!campaign_id || !post_id) {
      return;
    }

    push(
      Router.salesFloorByCampaignPost({
        campaignId: campaign_id,
        postId: post_id,
      })
    );
  };

  return (
    <NotificationBase
      id={id}
      isUnread={!is_seen}
      className={className}
      avatar={props}
      onClick={openPost}
      description={{
        text: (
          <>
            <b>{customer_name}</b> made a post in <b>{campaign_name}'s</b> sales
            floor.
          </>
        ),
        date: created_at,
        campaignName: campaign_name,
      }}
      message={{
        text: (
          <div className="flex flex-col gap-1 pt-1.5">
            <span className="typography-body-4-bold">
              {post_title || "Title is not available"}
            </span>

            {post_body ? (
              <div className="line-clamp-3">
                <RenderHTML className="typography-body-4" html={post_body} />
              </div>
            ) : (
              <span className="typography-body-4">
                Post body is not available
              </span>
            )}
          </div>
        ),
      }}
    />
  );
};
