import { SelectHTMLAttributes } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers/styles";

export interface DropdownOptionI {
  value: string | number | undefined;
  label: string;
}

interface DropdownI {
  options: DropdownOptionI[];
  name?: string;
  label?: string;
  selectedValue?: string;
  placeholder?: string;
  touched?: boolean;
  errors?: string;
  className?: string;
  labelClassName?: string;
  labelContentClassName?: string;
  inputProps?: SelectHTMLAttributes<HTMLSelectElement>;
  inputClassName?: string;
  errorsClassName?: string;
}

const Dropdown =
  (Field?: any) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    label,
    options,
    selectedValue,
    placeholder,
    touched,
    errors,
    className,
    labelClassName,
    labelContentClassName,
    inputProps,
    inputClassName,
    errorsClassName,
  }: DropdownI) => {
    return (
      <div className={clsxMerge("form-control mb-4 w-full", className)}>
        {!!label && (
          <label className={clsxMerge("label", labelClassName)}>
            <span
              className={clsxMerge(
                "label-text font-bold",
                labelContentClassName
              )}
            >
              {label}
            </span>
          </label>
        )}

        <Field
          as="select"
          name={name}
          className={clsxMerge(
            "select select-bordered font-normal",
            {
              "border-error-content": errors && touched,
            },
            inputClassName
          )}
          {...inputProps}
        >
          {!selectedValue && placeholder && (
            <option value="" hidden>
              {placeholder}
            </option>
          )}

          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Field>

        {errors && touched && (
          <div
            className={clsxMerge(
              "mt-1 flex items-center text-xs",
              errorsClassName
            )}
          >
            <ExclamationCircleIcon className="text-error-content mr-1 h-5 w-5 bg-none" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}
      </div>
    );
  };

export default Dropdown;
