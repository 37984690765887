import { widgets } from "@/components/shared/widgets";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { WIDGETS } from "@/constants/widgets";
import { useDialerWidgetContext } from "@/context/dialer-v2/widget";
import { dispatchCustomEvent } from "@/helpers/events";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

import {
  CallingModuleBookMeetingModal,
  CALLING_MODULE_BOOK_MEETING_MODAL_ID,
} from "@/modules/calling/modals/booking-meeting-modal";
import { Portal } from "react-portal";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { CalendarIcon } from "shared/ui/icons";

export const BookMeetingButton = ({ className }: { className?: string }) => {
  const { notes } = useDialerWidgetContext();
  const { calendlyEvent } = useInCallContext();

  const handleOpenModal = () => {
    modalHelpers.open(CALLING_MODULE_BOOK_MEETING_MODAL_ID);
  };

  const handleOnSuccessBookMeeting = () => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
    });
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
    });

    widgets.close({ id: WIDGETS.DIALER });
  };

  return (
    <>
      <button
        className={clsxMerge(
          "btn-ae-default flex items-center",

          {
            "disabled:bg-black disabled:text-white/30": calendlyEvent,
          },

          className
        )}
        disabled={!!calendlyEvent}
        onClick={handleOpenModal}
      >
        <CalendarIcon className="mr-2 w-4" />
        Book Meeting
      </button>

      <Portal>
        <CallingModuleBookMeetingModal
          notes={notes}
          disableCleanup
          ignoreActivityLog
          onSuccess={handleOnSuccessBookMeeting}
        />
      </Portal>
    </>
  );
};
