import { AxiosInstance } from "axios";

import {
  FirstTimeCallTimeResponseI,
  GoalTrackerResponseI,
  LifetimePerformanceResponseI,
  PerformanceCampaignStatsResponseI,
  PerformanceDashboardResponseI,
  TodayPerformanceResponseI,
  UpdateGoalTrackerRequestI,
} from "@/api/routes/performance/interfaces";

export const PerformanceAPIEndpoints = (API: AxiosInstance) => ({
  fetchPerformanceDashboard: (
    campaignId: string,
    startDate: string,
    endDate: string | undefined
  ) =>
    API.get<PerformanceDashboardResponseI>("/v1/performance/main", {
      params: {
        campaign_id: campaignId,
        start_date: startDate,
        end_date: endDate,
      },
    }),

  fetchPerformanceCampaignStats: (campaignId: string) =>
    API.get<PerformanceCampaignStatsResponseI>(
      "/v1/performance/campaign_stats",
      {
        params: { campaign_id: campaignId },
      }
    ),

  fetchLifetimePerformance: () =>
    API.get<LifetimePerformanceResponseI>(
      "/v1/performance/lifetime_performance"
    ),

  fetchTodayPerformance: () =>
    API.get<TodayPerformanceResponseI>("/v1/performance/today_performance"),

  fetchGoalTrackerMetrics: () =>
    API.get<GoalTrackerResponseI>("/v1/performance/goal/current"),

  updateGoalTrackerMetrics: (metrics: UpdateGoalTrackerRequestI) =>
    API.post("/v1/performance/goal/update", metrics),

  fetchFirstCallTime: () =>
    API.get<FirstTimeCallTimeResponseI>("/v1/performance/first_call_time"),
});
