import { useBeforeTabClose } from "@/hooks/use-before-tab-close";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { QualificationCriteriaI } from "@/interfaces/campaign";
import { safeGetDate } from "shared/lib/helpers";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { twMerge } from "tailwind-merge";
import UICheckbox from "shared/ui/ae-user-input/checkbox";
import { DATE_FORMAT } from "shared/lib/constants/time";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";

const Checkbox = UICheckbox();

interface MeetingBookedCardContentI {
  className?: string;
  isWrapperNoStyles?: boolean;
  isVisible: boolean;
  calendlyEvent: CalendlyEventI;
  qualificationCriterias: Array<QualificationCriteriaI>;
  selectedQualificationCriteria: Array<SelectedQualificationCriteriaQuestionI>;
  notes: string;
  loading?: boolean;
  onNotesUpdate: (notes: string) => void;
  onSubmit: () => void;
}

export const MeetingBookedCardContent = ({
  className,
  isWrapperNoStyles,
  isVisible,
  calendlyEvent,
  qualificationCriterias,
  selectedQualificationCriteria,
  notes,
  loading,
  onNotesUpdate,
  onSubmit,
}: MeetingBookedCardContentI) => {
  useBeforeTabClose(
    "You are required to submit AE notes after the call ends. Are you sure you want to close the window and lose the progress with the prospect?"
  );

  const { modalAccount } = useAccountsContext();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitClickedOnce, setIsSubmitClickedOnce] = useState(false);

  useEffect(() => {
    if (isSubmitClickedOnce) {
      const condition = notes?.length < 1;

      setIsError(condition);
      setIsDisabled(condition);
    }
  }, [notes, isSubmitClickedOnce]);

  const handleSubmit = () => {
    setIsSubmitClickedOnce(true);

    if (notes?.length < 1) {
      setIsError(true);
    } else {
      onSubmit();
    }
  };

  return (
    <div
      className={twMerge(
        isWrapperNoStyles
          ? ""
          : clsx(
              "w-[729px] rounded-[10px] bg-white px-8 py-6",
              className,
              "transition-[opacity,transform] duration-500",
              {
                "scale-50 opacity-0": !isVisible,
                "scale-100 opacity-100": isVisible,
              }
            )
      )}
    >
      <div>
        <section>
          <h2 className="ae-typography-h4 mb-2">
            Meeting notes for the Account Executive
          </h2>
          <p className="ae-typography-body2 mb-6 text-black/60">
            Please review your booked meeting and notes before sending to the
            Account Executive
          </p>
        </section>

        <section className="mb-6 flex gap-8">
          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1">Lead Name</div>
            <div className="ae-typography-detail1">Lead Title</div>
            <div className="ae-typography-detail1">Company Name</div>
            <div className="ae-typography-detail1">Meeting Time</div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1 text-black/60">
              {modalAccount?.contact?.name || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {modalAccount?.contact?.title || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {modalAccount?.accountName || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {safeGetDate(calendlyEvent?.start_time, DATE_FORMAT)}
            </div>
          </div>
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">Disposition</span>
          </label>
          <div className="ae-typography-detail1 w-full rounded-lg border-[1px] border-black/20 bg-[#DFF4B4] p-3 px-4 text-black">
            Meeting booked
          </div>
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Qualification criteria you have checked off
            </span>
          </label>
          {qualificationCriterias?.map((qc, i) => (
            <Checkbox
              key={i}
              name="qc"
              label={qc.question || ""}
              disabled
              inputProps={{
                checked:
                  selectedQualificationCriteria.find((_qc) => qc.id === _qc.id)
                    ?.is_checked || false,
              }}
            />
          ))}
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Notes for Account Executive
            </span>
          </label>
          <textarea
            name="notes"
            value={notes}
            onChange={(ev) => onNotesUpdate(ev.target.value)}
            rows={5}
            className={clsx(
              "ae-typography-body1 textarea w-full resize-none rounded-lg bg-black/5 p-4 focus:outline-none",
              {
                "textarea-bordered border-error-content": isError,
              }
            )}
          ></textarea>
        </section>

        <section className="flex w-full justify-end">
          <button
            onClick={handleSubmit}
            className={clsx("btn-ae-default w-[140px]", {
              loading: loading,
            })}
            disabled={isDisabled}
          >
            Send
          </button>
        </section>
      </div>
    </div>
  );
};
