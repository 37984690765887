import { FC } from "react";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardCallNotes } from "shared/ui/activity-log/cards-v2/base/notes";
import { HistoryCardCallerInfo } from "shared/ui/activity-log/cards-v2/base/caller-info";
import { UserMinusIcon } from "@heroicons/react/24/outline";
import { HistoryCardContactInfo } from "./base/contact-info";
import { AccountHistoryItemI } from "shared/lib/interfaces/account";

const getActivityTypeConfig = (activity: AccountHistoryItemI) => ({
  icon: UserMinusIcon,
  title: activity.contact ? "Lead disqualified" : "Account disqualified",
});

const HistoryCardUserDisqualifyLeadV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => (
  <AccountHistoryCard createdAt={activity.created_at}>
    <div className="flex flex-col gap-4">
      <HistoryCardActivityType config={getActivityTypeConfig(activity)} />
      <HistoryCardContactInfo contact={activity.contact} />
      <HistoryCardCallerInfo callerName={activity.caller_name || "N/A"} />
    </div>

    <div className="flex flex-col gap-4">
      <HistoryCardCallNotes notes={activity.notes} />
    </div>
  </AccountHistoryCard>
);

export default HistoryCardUserDisqualifyLeadV2;
