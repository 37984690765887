import React, { FC, useState } from "react";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";

import CalendarForm, {
  CallingCalendarFormButtonI,
} from "shared/ui/calendar-form";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { useEditNextTouchTime } from "@/api/routes/account/mutations";

interface EditCallbackCalendarFormPropsI {
  title?: string;
  description?: string;

  bookingDeadlineInMonths?: number | boolean;

  initialTime?: string;
  initialDate?: string;
  initialTimezone?: string;

  isNotes?: boolean;
  isNotesRequired?: boolean;
  notes?: string;
  onNotesChange?: (notesValue: string) => void;

  cancelButton?: CallingCalendarFormButtonI;
  successButton?: CallingCalendarFormButtonI;
  onBookingSuccess?: (date: string) => void;
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

const EditCallbackCalendarForm: FC<EditCallbackCalendarFormPropsI> = ({
  title = "",
  description = "",

  bookingDeadlineInMonths = 2,

  initialTime,
  initialDate,
  initialTimezone,

  isNotes = true,
  isNotesRequired,
  notes = "",
  onNotesChange = () => {},

  cancelButton,
  successButton,
  onBookingSuccess = () => {},
}) => {
  const { modalAccount } = useAccountsContext();
  const { mutateAsync: editNextTouchTime } = useEditNextTouchTime();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (
    date: string,
    time: string,
    timezone: string,
    notes: string,
    onSuccessCleanUp: () => void
  ) => {
    if (!modalAccount) {
      return;
    }

    setLoading(true);

    editNextTouchTime({
      accountId: modalAccount.accountId,
      campaignId: modalAccount.campaignId,
      nextTouchTime: date,
    })
      .then(() => {
        toast.success(`${modalAccount.accountName} next touch time updated!`);

        onSuccessCleanUp();
        setLoading(false);

        onBookingSuccess(dayjs(date).format());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CalendarForm
      bookingDeadlineInMonths={bookingDeadlineInMonths}
      title={title}
      description={description}
      isNotes={isNotes}
      isNotesRequired={isNotesRequired}
      notes={notes}
      onNotesChange={onNotesChange}
      initialDate={initialDate}
      initialTimezone={initialTimezone}
      initialTime={initialTime}
      cancelButton={cancelButton}
      successButton={successButton}
      onFormSubmit={handleFormSubmit}
      isLoading={loading}
    />
  );
};

export default EditCallbackCalendarForm;
