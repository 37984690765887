import { LocalStorage } from "@/helpers/local-storage";
import { findAudioDeviceByLabel } from "@/helpers/media-devices";
import { Device } from "@twilio/voice-sdk";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { checkIfClient } from "shared/lib/helpers";

const getDefaultDevice = (
  inputDevices?: [string, MediaDeviceInfo][]
): MediaDeviceInfo | undefined => {
  if (!checkIfClient()) return undefined;

  const LS = new LocalStorage();
  const defaultDevice = inputDevices?.[0]?.[1];

  if (!LS.preferredAudioInput) return defaultDevice;

  return (
    findAudioDeviceByLabel(inputDevices, LS.preferredAudioInput) ||
    defaultDevice
  );
};

/**
 *
 *
 * NOTE
 *
 * You can also pass properties that are not specifically Twilio Device
 * but original implementation implied that you use Twilio Device after initiaing
 * it with access token
 *
 * @param inputDevices - Property from Twilio Device
 * @param currentDevice - Twilio Device. Allows registration for incoming calls, and placing outgoing calls.
 */
export const useMicrophone = (
  inputDevices?: [string, MediaDeviceInfo][],
  currentDevice?: Device
) => {
  const [selectedMicrophone, setSelectedMicrophone] = useState<
    MediaDeviceInfo | undefined
  >(getDefaultDevice(inputDevices));

  const microphoneLabels = useMemo(
    () => inputDevices?.map((device) => device[1].label),
    [inputDevices]
  );

  /**
   * NOTE
   *
   * As soon as input Devices are available set default value
   * if is already selected
   */
  useEffect(() => {
    const LS = new LocalStorage();

    if (LS.preferredAudioInput && !selectedMicrophone) {
      setSelectedMicrophone(getDefaultDevice(inputDevices));
    }
  }, [inputDevices]);

  useEffect(() => {
    const prevDeviceLabel = currentDevice?.audio?.inputDevice?.label;

    if (
      selectedMicrophone?.deviceId &&
      prevDeviceLabel !== selectedMicrophone.label
    ) {
      currentDevice?.audio
        ?.setInputDevice(selectedMicrophone?.deviceId)
        .then(() => {
          if (prevDeviceLabel) toast.success("Microphone input device updated");
        })
        .catch((err: any) => console.error("err: ", err));
    }
  }, [selectedMicrophone?.deviceId, currentDevice]);

  const handleMicrophoneChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const LS = new LocalStorage();

    const selectedInputDevice = inputDevices?.find(
      (d) => d[1].label === ev.target.value
    );

    if (selectedInputDevice) {
      const mediaDeviceInfo = selectedInputDevice[1];

      LS.preferredAudioInput = mediaDeviceInfo.label;
      setSelectedMicrophone(mediaDeviceInfo);
    }
  };

  return {
    selectedMicrophone,
    microphoneLabels,

    setSelectedMicrophone,
    handleMicrophoneChange,
  };
};
