import { useRef, MutableRefObject } from "react";
import clsx from "clsx";
import Link from "next/link";
import Router from "@/helpers/router";

import { CreditCardIcon } from "@heroicons/react/24/outline";

export const STRIPE_CONNECT_MODAL_ID = "stripe-connect-modal";

const StripeConnectModal = ({
  modalId = STRIPE_CONNECT_MODAL_ID,
}: {
  modalId?: string;
}) => {
  const checkboxref = useRef() as MutableRefObject<HTMLInputElement>;

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
      />

      <div className="qualification-criteria-modal modal">
        <div className="modal-box relative min-w-[520px] rounded-md p-6">
          <div>
            <div className="mb-6">
              <CreditCardIcon className="h-6 w-6" />
            </div>

            <h3 className="ae-typography-h2 mb-6">
              Configure Payouts Before Making Calls
            </h3>

            <p className="ae-typography-body1 mb-6 text-base-content/60">
              Welcome to our platform! Before you can start making calls, it’s
              essential to configure your payouts. We integrate with Stripe for
              secure payouts -{" "}
              <b>your bank account information never touches our server.</b>{" "}
              <br /> <br />
              When you set a qualified meeting, the payment will automatically
              go to your bank account. You can check on the status of your
              payments in your profile settings. Click on the button below to
              start integrating your bank account!
            </p>

            <div className="flex items-center justify-center">
              <button
                className="btn-ae-text mr-2"
                onClick={() => {
                  checkboxref.current.click();
                }}
              >
                Remind me Later
              </button>
              <Link
                className={clsx("btn-ae-default ")}
                href={Router.settingsPayouts()}
              >
                Connect Payouts
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StripeConnectModal;
