import { ActivityI } from "@/interfaces/dialer/activity";
import { InCallContextI } from "@/interfaces/contexts/calling";
import { ContactDetailI } from "@/interfaces/accounts";
import { ACCOUNT_ACTIVITY_TYPES } from "shared/lib/constants/account";

export interface InSessionActivityI extends ActivityI {
  isSessionActivity: boolean;
}

export const addActivityLogEvent = (
  inCallContext: InCallContextI,
  newActivity: ActivityI
) => {
  const { activity_log, setActivityLog } = inCallContext;
  setActivityLog([...activity_log, newActivity]);
};

// --------------- CREATE ACTIVITY LOG - NOTE ---------------
interface ActivityNoteI {
  contact: ContactDetailI;
  timestamp: string;
  notes: string;
  caller_name: string;
}
const createActivityNote = ({
  contact,
  timestamp,
  notes,
  caller_name,
}: ActivityNoteI): InSessionActivityI => ({
  id: "",
  contact: contact,
  created_at: timestamp,
  activity_type: ACCOUNT_ACTIVITY_TYPES.USER_NOTE,
  notes,
  caller_name,
  isSessionActivity: true,
});

export const addActivityLogNote = (
  inCallContext: InCallContextI,
  data: ActivityNoteI
) => {
  const newActivity: ActivityI = createActivityNote(data);

  addActivityLogEvent(inCallContext, newActivity);
};

// --------------- RETURNS ALL THE NOTES MADE DURING THE LAST CALL AS ARRAY OF STRINGS ---------------
export const combineActivityNotes = (inCallContext: InCallContextI) => {
  const { activity_log, contact } = inCallContext;
  const combinedNotes: string[] = [];

  activity_log
    .filter((activity) => activity?.contact?.id === contact?.id)
    .map((_activity) => {
      const activity = _activity as InSessionActivityI;
      if (activity.isSessionActivity && activity.notes?.length) {
        combinedNotes.push(activity.notes);
      }
    });

  return combinedNotes;
};

// --------------- CREATE, EDIT AND DELETE ACTIVITY LOG - ADD CONTACT ---------------
export const deleteActivityAddContactEvent = (
  inCallContext: InCallContextI
) => {
  const { activity_log, setActivityLog } = inCallContext;

  const cleanedActivityLog = activity_log.filter((_activity) => {
    const activity = _activity as InSessionActivityI;

    return !(
      activity.isSessionActivity &&
      activity.activity_type === ACCOUNT_ACTIVITY_TYPES.CONTACT_ADDED
    );
  });

  setActivityLog(cleanedActivityLog);
};

// --------------- CREATE ACTIVITY LOG - CALLBACK_BOOKED ---------------
interface ActivityCallbackBookedI {
  contact: ContactDetailI;
  timestamp: string;
  notes: string;
  caller_name: string;
  follow_up_time: string;
}

const createActivityCallbackBooked = ({
  contact,
  timestamp,
  notes,
  caller_name,
  follow_up_time,
}: ActivityCallbackBookedI): ActivityI => ({
  id: "",
  contact: contact || {},
  created_at: timestamp,
  activity_type: ACCOUNT_ACTIVITY_TYPES.CALLBACK_BOOKED,
  notes,
  caller_name,
  follow_up_time,
});

export const addActivityLogCallbackBooked = (
  inCallContext: InCallContextI,
  data: ActivityCallbackBookedI
) => {
  const newActivity: ActivityI = createActivityCallbackBooked(data);

  addActivityLogEvent(inCallContext, newActivity);
};

export const formatCallerName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName[0]}.`;
};

// --------------- CREATE ACTIVITY LOG - MEETING_BOOKED ---------------
interface ActivityMeetingBookedI {
  eventId?: string;
  contact: ContactDetailI;
  timestamp: string;
  notes: string;
  caller_name: string;
  follow_up_time: string;
}

const createActivityMeetingBooked = ({
  eventId,
  contact,
  timestamp,
  notes,
  caller_name,
  follow_up_time,
}: ActivityMeetingBookedI): InSessionActivityI => ({
  id: eventId || "",
  contact: contact || {},
  created_at: timestamp,
  activity_type: ACCOUNT_ACTIVITY_TYPES.BOOKED_MEETING,
  notes,
  caller_name,
  follow_up_time,
  isSessionActivity: true,
});

export const addActivityLogMeetingBooked = (
  inCallContext: InCallContextI,
  data: ActivityMeetingBookedI
) => {
  const newActivity: ActivityI = createActivityMeetingBooked(data);

  addActivityLogEvent(inCallContext, newActivity);
};
