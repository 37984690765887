import { MetaI } from "@/interfaces/meta";
import { defaultMeta } from "./basic";

// NEXT SEO decided not to include all props into seo object
// We will follow official documentation and add all required props
// as additioanl props in NextSeo additionalMetaTags
export const twitter = (meta: MetaI) => {
  return [
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:title",
      content: (meta?.twitter?.title || defaultMeta.title) as string,
    },
    {
      property: "twitter:description",
      content: (meta?.twitter?.description ||
        defaultMeta.description) as string,
    },
    {
      property: "twitter:image",
      content: (meta?.twitter?.image ||
        "https://glencoco-assets.s3.amazonaws.com/social/v2/share-twitter.png") as string,
    },
    {
      property: "twitter:image:alt",
      content:
        "Glencoco logo with the letters in the middle and avatars of people around it",
    },
  ];
};
