import Countdown, { CountdownRendererFn } from "react-countdown";
import { useEffect, useState } from "react";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge } from "shared/lib/helpers";

const renderer: CountdownRendererFn = ({ seconds }) => {
  return <>{seconds < 10 ? `0${seconds}` : seconds}</>;
};

const MIN_SECONDS = 8;
const MAX_SECONDS = 30;

export const EndCallCountdown = ({
  className,
  onChange,
}: {
  className?: string;
  onChange?: (isCountdown?: boolean) => void;
}) => {
  const { startAt } = useInCallContext();

  const [isCountdown, setIsCountdown] = useState(true);

  const onComplete = () => setIsCountdown(false);

  useEffect(() => {
    onChange?.(isCountdown);
  }, [isCountdown]);

  return (
    isCountdown && (
      <span className={clsxMerge("animate-fadein", className)}>
        {startAt ? (
          <Countdown
            date={new Date(startAt as string).getTime() + MIN_SECONDS * 1000}
            renderer={renderer}
            onComplete={onComplete}
          />
        ) : (
          <Countdown
            date={Date.now() + MAX_SECONDS * 1000}
            renderer={renderer}
            onComplete={onComplete}
          />
        )}
      </span>
    )
  );
};
