import { Portal } from "react-portal";
import { GenericCallbackModal } from "@/modals/generic-callback-modal";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import toast from "react-hot-toast";
import {
  addActivityLogCallbackBooked,
  formatCallerName,
} from "@/helpers/activity-log";
import { useGlobalContext } from "@/hooks/use-global-context";
import { DayJs } from "shared/lib/helpers/date";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { cancelMeetingAndSetCallback } from "./helper";

export const DIALER_CANCEL_MEETING_CALLBACK_MODAL_ID =
  "dialer-cancel-meeting-callback-modal";

/**
 * NOTE - used in the CATCHUP mode for upcomming meeting type todo item
 *
 */
export const DialerCancelMeetingSequentialModals = ({
  notes,
}: {
  notes?: string;
}) => {
  const { glencocoUser: user } = useGlobalContext();
  const { campaign } = useCallingContext();
  const inCallContext = useInCallContext();
  const {
    contact,
    account,
    setCallbackData,
    setIsMeetingCanceled,
    setCalendlyEvent,
  } = inCallContext;

  const onSetCallbackSubmit = async (date?: string, notes?: string) => {
    const UpdateAccountStatusResponse = await cancelMeetingAndSetCallback({
      date,
      notes,
      account,
      campaign,
    });

    if (UpdateAccountStatusResponse.status === 200) {
      setIsMeetingCanceled(true);
      setCalendlyEvent(undefined);
      setCallbackData((callbackData = {}) => ({
        ...callbackData,
        isCallbackSet: true,
      }));

      addActivityLogCallbackBooked(inCallContext, {
        contact: {
          first_name: contact.first_name,
          last_name: contact.last_name,
          phone: contact.phone,
          title: contact.title,
          linkedin_url: contact.linkedin,
          email: contact.email,
        },
        timestamp: new Date().toLocaleString("en"),
        follow_up_time: DayJs(date).format(),
        notes: notes || "",
        caller_name: formatCallerName(
          user?.first_name || "",
          user?.last_name || ""
        ),
      });

      toast.success(
        `Meeting was canceled and ${contact?.first_name} ${contact?.last_name} was saved for later`
      );

      modalHelpers.close(DIALER_CANCEL_MEETING_CALLBACK_MODAL_ID);

      return true;
    }

    toast.error(`Failed to cancel meeting and set the callback.`);

    return false;
  };

  return (
    <Portal>
      <GenericCallbackModal
        modalId={DIALER_CANCEL_MEETING_CALLBACK_MODAL_ID}
        contact={contact}
        notes={notes}
        onSubmit={onSetCallbackSubmit}
      />
    </Portal>
  );
};
