import Link from "next/link";
import { FC } from "react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

interface ActionCardI {
  className?: string;
  title?: string;
  description?: string;
  icon?: JSX.Element;
  href?: string;
  onClick?: () => void;
}

const ActonCard: FC<ActionCardI> = ({
  className,
  title,
  description,
  icon,
  href,
  onClick,
}) => {
  if (href) {
    return (
      <Link href={href} className="flex">
        <div
          className={twMerge(
            clsx(
              "border-base-content border-primary flex items-center rounded-xl border-[1px] px-7 py-5",
              className
            )
          )}
        >
          <div className="bg-accent mr-4 flex h-[30px] w-[30px] items-center justify-center rounded-sm text-white">
            {icon}
          </div>

          <div className="flex flex-col justify-center">
            <span className="typography-h3">{title}</span>
            <span className="typography-tiny">{description}</span>
          </div>
        </div>
      </Link>
    );
  } else {
    return (
      <div
        className={twMerge(
          clsx(
            "border-base-content border-primary flex cursor-pointer items-center rounded-xl border-[1px] px-7 py-5",
            className
          )
        )}
        onClick={onClick}
      >
        <div className="bg-accent mr-4 flex h-[30px] w-[30px] items-center justify-center rounded-sm text-white">
          {icon}
        </div>

        <div className="flex flex-col justify-center">
          <span className="typography-h3">{title}</span>
          <span className="typography-tiny">{description}</span>
        </div>
      </div>
    );
  }
};

export default ActonCard;
