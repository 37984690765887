import uniq from "lodash/uniq";
import clsx from "clsx";
import { useCampaigns } from "@/hooks/use-campaigns";
import { useState } from "react";

import BookedMeetingToastV2 from "./meetings-toasts-v2/booked-meeting-toast";
import QualifiedMeetingToastV2 from "./meetings-toasts-v2/qualified-meeting-toast";

import { MeetingToastEventI } from "@/interfaces/custom-events";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import {
  CUSTOM_MEETINGS_TOASTER_ID,
  MEETINGS_TOAST_TYPES,
  TOAST_ACTIONS,
} from "@/constants/custom-toasts";
import {
  CustomMeetingsToastI,
  CustomQualifiedMeetingsToastI,
} from "@/interfaces/custom-toasts/meetings";
import { LocalStorage } from "@/helpers/local-storage";
import { useEffectOnce } from "shared/lib/hooks";
import { checkIfClient } from "shared/lib/helpers";
import { twMerge } from "tailwind-merge";

// import {
//   testBroadcastMockedBookedMeetingSocketMessage,
//   testBroadcastMockedQualifiedMeetingSocketMessage,
// } from "./utils";

const eventData = (data: any) => ({ detail: data });

const getAnimationClassName = (i: number, toastsState: Array<any>) =>
  twMerge(
    clsx("relative", "transition-all duration-500", {
      "left-0 opacity-100": i === toastsState.length - 1,
      "left-[75px] scale-[0.8] opacity-80": i === toastsState.length - 2,
      "left-[145px] scale-[0.6] opacity-40": i === toastsState.length - 3,
      "scale-[0.1] opacity-0": i < toastsState.length - 3,
    })
  );

export const meetingsToastEmitter = {
  show: () => {
    const customToastEvent: MeetingToastEventI = new CustomEvent(
      CUSTOM_EVENTS.MEETING,
      eventData({ action: TOAST_ACTIONS.ADD })
    );

    if (checkIfClient()) {
      document.dispatchEvent(customToastEvent);
    }
  },
  dismiss: () => {
    const customToastEvent = new CustomEvent(
      CUSTOM_EVENTS.MEETING,
      eventData({ action: TOAST_ACTIONS.DISMISS })
    );

    if (checkIfClient()) {
      document.dispatchEvent(customToastEvent);
    }
  },
};

const CustomMeetingsToaster = () => {
  const { refillCampaignsByIds } = useCampaigns();

  const [toastsState, setToastsState] = useState<
    Array<CustomMeetingsToastI | CustomQualifiedMeetingsToastI>
  >([]);

  const syncCampaigns = (toasts: Array<CustomMeetingsToastI>) => {
    const campaignIds = uniq(
      toasts?.map((t) => t?.data?.campaign_id).filter((cid) => !!cid)
    );

    refillCampaignsByIds(campaignIds);
  };

  useEffectOnce(() => {
    const LS = new LocalStorage();
    const storedToasts = (LS.customMeetingsToasts ||
      []) as Array<CustomMeetingsToastI>;

    syncCampaigns(storedToasts);

    if (storedToasts) {
      setToastsState([...storedToasts]);
    }

    if (checkIfClient()) {
      window.document.addEventListener(
        CUSTOM_EVENTS.MEETING,
        customMeetingsToastHandler
      );
    }

    return () => {
      if (checkIfClient()) {
        window.document.removeEventListener(
          CUSTOM_EVENTS.MEETING,
          customMeetingsToastHandler
        );
      }
    };
  });

  const customMeetingsToastHandler = (ev: any) => {
    const event = ev as MeetingToastEventI;
    const { action } = event.detail;

    switch (action) {
      case TOAST_ACTIONS.ADD:
        showMeetingToasts();
        break;
      case TOAST_ACTIONS.DISMISS:
        deleteFirstToastFromStore();
        break;
      default:
        break;
    }
  };

  const showMeetingToasts = () => {
    const LS = new LocalStorage();
    const toasts = (LS.customMeetingsToasts ||
      []) as Array<CustomMeetingsToastI>;

    syncCampaigns(toasts);

    setToastsState([...toasts]);
  };

  const deleteFirstToastFromStore = () => {
    const LS = new LocalStorage();
    const toasts = LS.customMeetingsToasts;
    toasts?.pop();
    LS.customMeetingsToasts = toasts;
    setToastsState([...toasts]);
  };

  const deleteAllToastsFromStore = () => {
    const LS = new LocalStorage();
    LS.customMeetingsToasts = [];
    setToastsState([]);
  };

  // FUNCTION TO TRIGGER A GLOBAL TOAST EVENT. DELETE AFTER TESTING IS COMPLETE.
  // useEffectOnce(() => {
  //   new Array(5).fill(1).forEach(() => {
  //     testBroadcastMockedBookedMeetingSocketMessage();
  //     testBroadcastMockedQualifiedMeetingSocketMessage();
  //   });
  // });

  return (
    <>
      {(toastsState?.length || 0) > 1 && (
        <button
          className={twMerge(
            clsx(
              "btn-b-white !btn-sm",
              "border-solid border-black",
              "min-w-[100px]",
              "absolute right-0 top-[70px]",
              "z-[100]",
              "animate-fadein",
              "transition-all duration-500",
              {
                "right-[20px]": toastsState.length === 1,
                "right-[40px]": toastsState.length === 2,
                "right-[50px]": toastsState.length >= 3,
              }
            )
          )}
          onClick={deleteAllToastsFromStore}
        >
          Clear All
        </button>
      )}

      <div
        id={CUSTOM_MEETINGS_TOASTER_ID}
        className={twMerge(
          clsx(
            "fixed right-[620px] top-[220px] z-[100]",
            "flex items-end",
            "pointer-events-none",
            "animate-fadein",
            {
              "right-[620px]": toastsState.length === 1,
              "right-[640px]": toastsState.length === 2,
              "right-[650px]": toastsState.length >= 3,
            }
          )
        )}
      >
        {toastsState.map((toast, i) => (
          <div
            key={i}
            // style={{
            //   marginLeft: (toastsState.length - i) * 10,
            //   marginBottom: (toastsState.length - i) * 10,
            // }}
            className={clsx(
              "absolute left-0",
              "pointer-events-auto transition-[margin]"
            )}
          >
            {toast.type === MEETINGS_TOAST_TYPES.BOOKED_MEETING && (
              <BookedMeetingToastV2
                toastData={toast as CustomMeetingsToastI}
                className={getAnimationClassName(i, toastsState)}
              />
            )}
            {toast.type === MEETINGS_TOAST_TYPES.QUALIFIED_MEETING && (
              <QualifiedMeetingToastV2
                toastData={toast as CustomQualifiedMeetingsToastI}
                className={getAnimationClassName(i, toastsState)}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomMeetingsToaster;
