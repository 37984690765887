import { ENV } from "shared/lib/constants/env";

export const USER_PLATFORM_URL = {
  [ENV.LOCAL]: "http://localhost:3000",
  [ENV.STAGING]: "https://user-staging.glencoco.com",
  [ENV.PRODUCTION]: "https://user.glencoco.com",
};

export const CUSTOMER_PLATFORM_URL = {
  [ENV.LOCAL]: "http://localhost:3001",
  [ENV.STAGING]: "https://customer-staging.glencoco.com",
  [ENV.PRODUCTION]: "https://customer.glencoco.com",
};

export const HTTPS_REGEX = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

export const LINKEDIN_REGEX =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|public-profile\/in|public-profile\/pub)\/[\w-]+\/?$/;
