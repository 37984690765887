import { APII, glencocoClientAPI } from "@/api/glencoco";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

import { useMemo } from "react";
import { useApi } from "shared/lib/hooks";

export const useFetcherAddNote = () => {
  const { campaign } = useCallingContext();
  const { account, contact } = useInCallContext();

  const addNoteFetcher = useMemo(() => {
    if (campaign?.id && account?.id && contact?.id) {
      return (api: APII, notes: string) =>
        api.sendAccountDispositionNote(
          campaign.id as string,
          account.id as string,
          {
            accountContactId: contact.id as string,
            noteType: "note",
            notes: notes,
          }
        );
    }

    return null;
  }, [account, contact, campaign]);

  const [, fetchAddNote] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: addNoteFetcher,
    shouldCallAutomatically: false,
    onSuccess: () => {
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
      });
    },
    errorBuilder: () =>
      `Failed to add note to ${account?.name}, please try again.`,
  });

  return fetchAddNote;
};
