import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";
import { ERROR_CODES } from "@/constants/errors";
import Router from "@/helpers/router";
import { checkIfClient } from "shared/lib/helpers";
import { useGlobalContext } from "@/hooks/use-global-context";
import { LoadingModule } from "@/components/modules/loading";
import { USER_APPLICATION_STATUS } from "@/constants/signup";

/**
 * Use in Axios error interceptors but logic should be stored
 * in context relevant environment
 *
 * @param e
 */
export const redirectOnAxiosErrorInterceptor = (
  e: AxiosError<any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  if (
    e?.response?.data?.error_code === ERROR_CODES.PROFILE_BLOCKED &&
    !window.location.href.includes(Router.profileInactive())
  ) {
    window.location.href = Router.profileInactive();
  }
};

export const RedirectsProvider = ({ children }: { children?: any }) => {
  const { user: auth0User } = useAuth0();
  const { glencocoUser, isUserLoaded, userApplicationStatus } =
    useGlobalContext();

  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
    if (checkIfClient()) {
      const url = new URL(window.location.href);

      //User is not Verified
      if (
        !auth0User?.email_verified &&
        !url.pathname.includes(Router.verify())
      ) {
        setIsRedirecting(true);

        const search = window.location.search
          ? `${window.location.search}`
          : "";

        window.location.href = `${Router.verify()}${search}`;
        return;
      }

      // Handle ATS redirect if user has not applied yet through the landing page
      if (auth0User?.email_verified && userApplicationStatus) {
        if (USER_APPLICATION_STATUS.NO_APPLICATION === userApplicationStatus) {
          setIsRedirecting(true);
          window.location.href = Router.glencocoOpenMarketplace();
          return;
        }
      }

      // If isUserLoaded is true and glencocoUser is undefined, this means the user is blocked from the platform.
      // Refer to /lib/api/glencoco.ts response interceptors to see how blocked users are handled.

      if (isUserLoaded && glencocoUser) {
        //User is not Registered, or they've been waitlisted: send user to signup flow to complete registration.
        // If they've been waitlisted, the signup flow will take care of showing the appropriate "waitlisted" UI
        if (
          (!glencocoUser?.registered ||
            USER_APPLICATION_STATUS.WAITLISTED === userApplicationStatus) &&
          [Router.signup(), Router.verify()].every(
            (route) => !url.pathname.includes(route)
          )
        ) {
          setIsRedirecting(true);
          window.location.href = Router.signup();
          return;
        }

        //User is registered but trying to access signup page
        if (
          glencocoUser?.registered &&
          USER_APPLICATION_STATUS.ACCEPTED === userApplicationStatus &&
          url.pathname.includes(Router.signup())
        ) {
          setIsRedirecting(true);
          window.location.href = Router.home();
          return;
        }

        setIsRedirecting(false);
      }
    }
  }, [
    auth0User?.email_verified,
    glencocoUser?.registered,
    isUserLoaded,
    userApplicationStatus,
  ]);

  return isRedirecting ? <LoadingModule /> : children;
};
