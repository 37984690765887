import { Dispatch, SetStateAction, useEffect } from "react";
import { produce } from "immer";

import { AccountHistoryItemI } from "shared/lib/interfaces/account";
import { useApiClient } from "@/context/api-client";

interface UsePollActivityLogsParamsI {
  config: Array<{
    activityLogId: string;
    until: (activityLog: AccountHistoryItemI) => boolean;
  }>;
  interval?: number;
  reducer: Dispatch<SetStateAction<AccountHistoryItemI[]>>;
}

const CANCEL_POLLING_REQUEST_TIMEOUT = 20_000;

/**
 * Use this hook as a way to poll for activity logs which state may change over
 * time. For example, generating email may take a while, and we want to poll for
 * its actual status to then perform an update of a card.
 *
 * @param config
 * @param interval
 * @param reducer
 */
export const usePollActivityLogs = ({
  config,
  interval = 2000,
  reducer,
}: UsePollActivityLogsParamsI) => {
  const api = useApiClient();

  useEffect(() => {
    if (!config || config.length === 0) {
      return;
    }

    const pollingIntervals = config.map(
      ({ activityLogId, until }, intervalIndex) => {
        return setInterval(() => {
          api.getActivityLog(activityLogId).then(({ status, data }) => {
            if (status !== 200 || !data || !data.activity_log) {
              // Don't proceed with polling if the request for activity log fails
              return clearInterval(pollingIntervals[intervalIndex]);
            }

            const activityLog = data.activity_log;

            // If the condition is met, clear the interval and update the state
            if (until(activityLog)) {
              clearInterval(pollingIntervals[intervalIndex]);

              reducer((originalAccountHistory) => {
                return produce(
                  originalAccountHistory,
                  (draftAccountHistory) => {
                    const index = draftAccountHistory.findIndex(
                      (activity) => activity.id === activityLogId
                    );

                    if (index >= 0) {
                      draftAccountHistory[index] = activityLog;
                    } else {
                      /**
                       * If the activity log is not found in the list, that's an
                       * edge case, we should cancel the polling
                       */
                      clearInterval(pollingIntervals[intervalIndex]);
                    }
                  }
                );
              });
            }
          });
        }, interval);
      }
    );

    /**
     * Set a timeout to clear all intervals after a fixed period of time to avoid
     * eternal polling and memory leaks
     */
    const pollingIntervalTimeouts = config.map((_, intervalIndex) => {
      return setTimeout(() => {
        if (pollingIntervals[intervalIndex]) {
          clearInterval(pollingIntervals[intervalIndex]);
        }
      }, CANCEL_POLLING_REQUEST_TIMEOUT);
    });

    return () => {
      pollingIntervals.forEach((interval) => clearInterval(interval));
      pollingIntervalTimeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [config, interval]);
};
