import Countdown, { CountdownRendererFn } from "react-countdown";

import clsx from "clsx";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

const renderer: CountdownRendererFn = ({ seconds }) => {
  return <>{seconds < 10 ? `0${seconds}` : seconds}</>;
};

const MIN_SECONDS = 8;
const MAX_SECONDS = 30;

export const ControlMenuEndCallButton = ({
  isLoading,

  onClick,
}: {
  isLoading?: boolean;
  onClick?: () => void;
}) => {
  const { startAt } = useInCallContext();

  const [isCountdown, setIsCountdown] = useState(true);

  const onComplete = () => setIsCountdown(false);

  return (
    <button
      className={twMerge(
        clsx("end-call-btn btn-ae-error h-full min-w-[128px]", {
          loading: isLoading,
          disabled: isCountdown,
          "min-w-[140px]": isCountdown,
        })
      )}
      onClick={onClick}
      disabled={isCountdown}
    >
      <span>END CALL {isCountdown ? "IN :" : ""}</span>
      {isCountdown && (
        <span>
          {startAt ? (
            <Countdown
              date={new Date(startAt as string).getTime() + MIN_SECONDS * 1000}
              renderer={renderer}
              onComplete={onComplete}
            />
          ) : (
            <Countdown
              date={Date.now() + MAX_SECONDS * 1000}
              renderer={renderer}
              onComplete={onComplete}
            />
          )}
        </span>
      )}
    </button>
  );
};
