import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";

interface SalesResumeAboutPropsI {
  title?: string;
  description?: string;
  className?: string;
  bodyClassName?: string;
}

const SalesResumeAbout: FC<SalesResumeAboutPropsI> = ({
  title,
  description,
  className,
  bodyClassName,
}) => (
  <section
    className={clsxMerge(
      "mo:w-full mo:px-0 mo:py-7 mo:bg-transparent relative rounded-[20px] bg-[white] px-6 py-8 lg:w-[790px]",
      className
    )}
  >
    <h3 className="mb-3 text-[18px] font-bold leading-none">{title}</h3>
    <pre
      className={clsxMerge(
        "ae-typography-body1 whitespace-break-spaces font-normal",
        bodyClassName
      )}
    >
      {description}
    </pre>
  </section>
);

export default SalesResumeAbout;
