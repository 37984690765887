import { DATE_FORMAT_MONTH_DATE_YEAR } from "shared/lib/constants/time";
import { safeGetDate } from "shared/lib/helpers";

export const DateSeperator = ({ timestamp }: { timestamp: string }) => {
  return (
    <div className="flex w-full flex-col border-opacity-50">
      <div className="divider b-typography-body3 m-1 uppercase text-black/60">
        {safeGetDate(timestamp, DATE_FORMAT_MONTH_DATE_YEAR)}
      </div>
    </div>
  );
};
