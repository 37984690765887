import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import {
  BackgroundBlur,
  SVGBorderElement,
} from "@/components/modules/calling/control-menu/v1/shared";

import { CALLING_DISPOSITION_MODAL_ID } from "@/modules/calling/modals/disposition-modal";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import Calling from "@/helpers/calling";

import { useLiveTransfer } from "@/modules/calling/hooks/use-live-transfer";

const ControlMenuDispositionState = ({ className }: { className?: string }) => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();
  const { isLiveTransferComplete } = useLiveTransfer();

  /**
   * NOTE: If campaign has live transfer and caller actually transfered lead
   *       we want to end the call and don't show any other modals
   */
  const exit = () => {
    if (isLiveTransferComplete) {
      Calling.exit({ context: callingContext, inCallContext });
    }
  };

  return (
    <>
      <div className={twMerge(clsx("relative w-fit", className))}>
        <BackgroundBlur />
        <SVGBorderElement />

        <div className="relative w-[450px] rounded-[10px] ">
          <label
            htmlFor={isLiveTransferComplete ? "" : CALLING_DISPOSITION_MODAL_ID}
            className="btn btn-ghost flex w-full items-center justify-center bg-transparent hover:bg-transparent"
            onClick={exit}
          >
            <span className="finish-call-btn ae-typography-button1 text-white">
              Finish Call
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default ControlMenuDispositionState;
