import { LeadCardAvatar } from "./avatar";
import { LeadInfo } from "./lead-info";
import { clsxMerge } from "shared/lib/helpers";
import { DialerConnectionLabel } from "@/components/modules/calling/cards/lead-card/v2/lead-avatar/connection-label";

export const LeadCard = () => {
  return (
    <div className="flex h-full items-center justify-start">
      <div className={clsxMerge("relative flex justify-center")}>
        <LeadCardAvatar
          className={clsxMerge("relative justify-start")}
          avatarClassName="relative w-[50px]"
          ripplesClassName="z-[10] relative top-[25px] scale-75"
        />

        <DialerConnectionLabel
          className={clsxMerge("absolute top-[calc(50%+18px)] z-10")}
        />
      </div>

      <LeadInfo />
    </div>
  );
};
