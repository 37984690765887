import { FC, useEffect, useState } from "react";

import CircularLoading from "@/components/base/circular-loading";
import Router from "@/helpers/router";
import { clsxMerge } from "shared/lib/helpers";

interface LoadingComponentPropsI {
  className?: string;
  spinnerClassName?: string;
  delay?: number;
  isTimedoutLogout?: boolean;
}

interface LoadingModulePropsI {
  className?: string;
}

const LOGOUT_VISIBLE_TIMEOUT = 10 * 1000;

const LogoutButton = () => (
  <a
    href={Router.logout()}
    className={clsxMerge(
      "animate-fadein",
      "btn-ae-default",
      "absolute right-[calc(50vw-40px)] top-[calc(50vh+30px)]"
    )}
  >
    Logout
  </a>
);

export const LoadingComponent: FC<LoadingComponentPropsI> = ({
  className,
  spinnerClassName,
  delay,
  isTimedoutLogout,
}) => {
  const [isVisible, setIsVisible] = useState(typeof delay !== "number");
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  let interval: any = null;
  useEffect(() => {
    setIsVisible(false);
    clearInterval(interval);

    interval = setInterval(() => setIsVisible(true), delay);

    return () => {
      clearInterval(interval);
    };
  }, [delay]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLogoutVisible(true);
    }, LOGOUT_VISIBLE_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isVisible ? (
    <div
      className={clsxMerge(
        "hero-content flex animate-fadein items-center justify-center",
        className
      )}
    >
      {isTimedoutLogout && isLogoutVisible && <LogoutButton />}
      <CircularLoading className={spinnerClassName} />
    </div>
  ) : null;
};

export const LoadingModule: FC<LoadingModulePropsI> = ({ className }) => (
  <div className={clsxMerge("hero min-h-screen", className)}>
    <LoadingComponent isTimedoutLogout />
  </div>
);
