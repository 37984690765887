import { Label } from "shared/ui";
import { LogoLinkedInIcon } from "shared/ui/icons";

import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { clsxMerge, fixUrl } from "shared/lib/helpers";

export const LinkedinBadge = ({
  url,
  className,
  isShowUrl,
}: {
  url?: string;
  className?: string;
  isShowUrl?: boolean;
}) => {
  const { contact } = useInCallContext();

  const linkedin = url || contact?.linkedin;

  return linkedin ? (
    <>
      <Label
        variation="accent"
        href={fixUrl(linkedin)}
        target="_blank"
        rel="noreferrer"
        className={clsxMerge("ml-1 flex items-center", className)}
      >
        <LogoLinkedInIcon className="h-3" />
      </Label>
      {isShowUrl && (
        <a
          href={fixUrl(linkedin)}
          className="ml-1 overflow-hidden text-ellipsis typography-body-4"
          target="_blank"
          rel="noreferrer"
        >
          {linkedin}
        </a>
      )}
    </>
  ) : null;
};
