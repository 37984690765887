import { useMemo } from "react";
import _throttle from "lodash/throttle";

import { useDialerDirectCall } from "@/hooks/dialer/use-dialer-direct-call";
import { CallButton as MiniDialerCallButton } from "@/modules/calling/control-menu/v2/buttons/call-button";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { getFullEntityName } from "shared/lib/helpers";
import { PhoneIcon } from "shared/ui/icons";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import { ButtonMenu } from "shared/ui";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

// Make it easy to disable double-clicking on a button with a throttle higher
// order function.
const withDoubleClickDisabled = (fn: (...args: any[]) => any) =>
  _throttle(fn, 1000, {
    leading: true,
    trailing: false,
  });

export const CallButton = () => {
  const status = useDialerCallStatus();
  const { campaign, contactsForContextMenu } = useAccountDetailsContext();

  const { startDirectCall } = useDialerDirectCall();
  const { contact: callingContact } = useInCallContext();
  const primaryLead = useMemo(
    () => contactsForContextMenu.find((contact) => contact.is_primary_lead),
    [contactsForContextMenu]
  );

  const startCallWithPrimaryLeadIfAvailable = useMemo(
    () =>
      withDoubleClickDisabled(() => {
        /**
         * This branch is responsible for handling the "Call Again" case
         */
        if (status === DIALER_CALL_STATUS.AFTER && callingContact) {
          return startDirectCall({
            contact: {
              id: callingContact.id as string,
            },
          });
        }

        /**
         * Start call with primary lead if available, if not - user will pick
         * a necessary contact from the expanded contact picker.
         */
        if (contactsForContextMenu.length > 0 && primaryLead) {
          return startDirectCall({
            contact: {
              id: primaryLead.id as string,
            },
          });
        }

        /**
         * If there is only one contact
         */
        if (contactsForContextMenu.length === 1) {
          return startDirectCall({
            contact: {
              id: contactsForContextMenu[0]?.id,
            },
          });
        }
      }),
    [
      contactsForContextMenu,
      startDirectCall,
      callingContact,
      status,
      primaryLead,
    ]
  );

  const startCall = useMemo(
    () =>
      withDoubleClickDisabled((contactId?: string) => {
        // That should not be the case. Leaving this check here to avoid
        // any unexpected calling behaviors.
        if (!contactId) {
          return;
        }

        startDirectCall({
          contact: {
            id: contactId,
          },
        });
      }),
    [startDirectCall]
  );

  const addButtonMenuItems = useMemo(
    () =>
      contactsForContextMenu?.map((contact) => (
        <ButtonMenuItem
          text={getFullEntityName(contact)}
          onClick={() => startCall(contact?.id)}
          icon={<PhoneIcon className="mr-1 h-3 w-3" />}
        />
      )) || [],
    [contactsForContextMenu, campaign]
  );

  if (DIALER_CALL_STATUS.DURING === status) {
    return <MiniDialerCallButton />;
  }

  return contactsForContextMenu.length === 1 ||
    DIALER_CALL_STATUS.AFTER === status ? (
    // Either if there's only one contact in the context menu (just call it) or
    // if we're in the post-call state (call again), we should render a single
    // call button.
    <MiniDialerCallButton onStartCall={startCallWithPrimaryLeadIfAvailable} />
  ) : (
    <div className="btn-group">
      <ButtonMenu
        containerClassName="dropdown-top"
        contentClassName="mb-2"
        position="end"
        className="p-0"
        // Showing items on hover enabled for a case when clicking the button
        // fires calling a primary lead if there is any
        isHoverMode={!!primaryLead && contactsForContextMenu.length > 1}
        renderTrigger={() => (
          <MiniDialerCallButton
            isDisabled={contactsForContextMenu.length === 0}
            onStartCall={startCallWithPrimaryLeadIfAvailable}
          />
        )}
        items={addButtonMenuItems}
      />
    </div>
  );
};
