import { BookMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/book-meeting-button";
import { MarkDoneButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/mark-done-button";
import { clsxMerge } from "shared/lib/helpers";
import { CancelMeetingButton } from "@/modules/calling/leads-carousel/v2/actions/basic-buttons/cancel-meeting-button";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

export const ActionsAfterCall = () => {
  const { isMeetingCanceled } = useInCallContext();

  return (
    <div className={clsxMerge("grid grid-cols-2 grid-rows-2 gap-x-1 gap-y-2")}>
      <CancelMeetingButton />
      <BookMeetingButton
        isModifyMode={!isMeetingCanceled}
        className="btn-b-white btn-ae-l btn px-0"
      />

      <MarkDoneButton withEmail className="col-span-2" />
    </div>
  );
};
