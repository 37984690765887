import { SparklesIcon } from "@heroicons/react/24/solid";
import { clsxMerge } from "shared/lib/helpers";
import { BorderWithGradient } from "shared/ui/border-with-gradient";

export const ComposeWithAiButton = ({
  className,
  onClick = () => {},
}: {
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <BorderWithGradient
      className="rounded-md p-0"
      containerClassName="rounded-lg"
    >
      <button
        type="button"
        className={clsxMerge(
          "btn-ae border-none text-transparent typography-header-8-semibold",
          "gradient-text",
          className
        )}
        onClick={onClick}
      >
        <SparklesIcon className="mb-1 mr-2 w-4 text-[#714CC9]" /> Compose with
        AI
      </button>
    </BorderWithGradient>
  );
};
