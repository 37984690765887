import { useState } from "react";
import clsx from "clsx";
import random from "lodash/random";
import { useEffectOnce } from "shared/lib/hooks";

const DIALING_SLOGANS_MULTIPLE_LEADS = [
  "Dialing leads during their business hours (8am - 6pm)",
  "Skipping leads with existing meetings and callbacks",
  "Prioritizing mobile numbers to ensure higher connect rates",
  "Hot tip: Set a callback if you want to call a lead back later",
  "Did you know: Leads who have called you back will show up as alerts on your dashboard",
  "If a lead gives you someone’s number, you can add them directly with “Create a New Lead”",
  "Got questions or want some tips around messaging? Visit the sales floor to ask a question or see responses to common questions",
  "Our scripts are mere guidelines, feel free to put your own twist on your script",
  "Feel free to reach out anytime via Slack or email, we’re here to help!",
];

const getSlogans = () => {
  return DIALING_SLOGANS_MULTIPLE_LEADS;
};

const getRandomSloganIndex = (slogans: Array<any>, notIndex?: number) => {
  if (notIndex) {
    let index = random(0, slogans.length - 1);

    while (notIndex === index) {
      index = random(0, slogans.length - 1);
    }

    return index;
  } else {
    return random(0, slogans.length - 1);
  }
};

const DialingTitle = () => {
  const slogans = getSlogans();

  const [index, setIndex] = useState(getRandomSloganIndex(slogans, undefined));
  const [isVisible, setIsVisible] = useState(true);

  useEffectOnce(() => {
    const interval = setInterval(() => {
      setIsVisible(false);

      setTimeout(() => {
        setIndex(getRandomSloganIndex(slogans, index));
        setIsVisible(true);
      }, 300);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <h3
      className={clsx("ae-typography-h3", "transition duration-500", {
        "scale-90 opacity-0": !isVisible,
        "scale-100 opacity-100": isVisible,
      })}
    >
      {slogans[index]}...
    </h3>
  );
};

export default DialingTitle;
