import { FC } from "react";
import { NotificationAvatar } from "shared/ui/notifications/notification-generic/base-parts/avatar";

export interface NotificationIndividualImageI {
  contact_name?: string;
}

export const NotificationIndividualImage: FC<NotificationIndividualImageI> = ({
  contact_name,
}) => {
  const logo_s3_url =
    "https://glencoco-assets.s3.amazonaws.com/platform/avatars/individual-default.png";

  return (
    <NotificationAvatar
      src={logo_s3_url}
      placeholderText={contact_name}
      isLazy
    />
  );
};
