import { clsxMerge } from "shared/lib/helpers";

export const BaseControlButton = ({
  className,
  onClick,
  children,
}: {
  className?: string;
  onClick?: () => void;
  children?: any;
}) => (
  <button
    onClick={onClick}
    className={clsxMerge(
      "flex h-[56px] flex-col items-center justify-center",
      { "btn-b-white": !className?.includes("btn") },
      className
    )}
  >
    {children}
  </button>
);
