import { FC } from "react";

import { AttachmentI } from "@/api/routes/email";
import { clsxMerge } from "shared/lib/helpers";
import { ButtonMenu } from "shared/ui";
import { CloseIcon } from "shared/ui/icons";
import { DefaultPill } from "shared/ui/pills";

interface EmailAttachmentsSectionPropsI {
  className?: string;
  attachmentOptions?: AttachmentI[];
  selectedAttachments?: AttachmentI[];
  onChange?: (attachment: AttachmentI) => void;
}

export const EmailAttachmentsSection: FC<EmailAttachmentsSectionPropsI> = ({
  className,
  attachmentOptions = [],
  selectedAttachments = [],
  onChange = () => {},
}) => {
  return (
    <section>
      <div
        className={clsxMerge("flex items-center justify-between", className)}
      >
        <div className="typography-body-3-medium">Attachments</div>

        <ButtonMenu
          className={clsxMerge(
            "ae-link cursor-pointer typography-body-4-medium",
            {
              "cursor-not-allowed text-black opacity-30":
                !attachmentOptions?.length,
            }
          )}
          text="+ Add attachment"
          isArrowVisible={false}
          items={attachmentOptions?.map((a, idx) => (
            <div
              key={idx}
              className="flex items-center gap-1.5"
              onClick={() => onChange(a)}
            >
              {a.name}
            </div>
          ))}
        />
      </div>

      <div className="flex min-h-[60px] flex-wrap gap-2 rounded-lg border bg-white p-4">
        {!selectedAttachments?.length && (
          <p className="text-[#ccc] typography-body-3-medium">No attachments</p>
        )}

        {selectedAttachments.map((attachment, idx) => (
          <DefaultPill
            key={idx}
            className="border border-[#ccc] bg-white py-3 text-black typography-body-5-medium"
          >
            {attachmentOptions.find((opt) => opt.id === attachment.id)?.name}

            <button onClick={() => onChange(attachment)}>
              <CloseIcon className="ml-2 w-3" />
            </button>
          </DefaultPill>
        ))}
      </div>
    </section>
  );
};
