import { MARK_DONE_SEND_EMAIL_MODAL_ID } from "@/components/modules/calling/modals/mark-done-modal";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { clsxMerge } from "shared/lib/helpers";

export const SendEmailButton = ({ className }: { className?: string }) => {
  return (
    <label
      htmlFor={MARK_DONE_SEND_EMAIL_MODAL_ID}
      className={clsxMerge("btn-nofill btn-ae-l px-0 !text-black ", className)}
    >
      <EnvelopeIcon className="mr-2 w-4 text-black" /> Send Email
    </label>
  );
};
