import { ACCOUNT_EDIT_CALLBACK_MODAL_ID } from "@/modals/account-edit-callback-modal";
import { ACCOUNT_BOOK_MEETING_MODAL_ID } from "@/modals/account-book-meeting-modal";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import { PIPELINE_SYSTEM_LISTS } from "@/modules/pipeline/lists/list/activities/selector";

// Backlog used to be in the kanban board but we removed it.
// Only commented it out for now incase we end up adding it back in.
export const COLUMN_TITLES_MAP = {
  // [ACCOUNT_STATUSES.BACKLOG]: "Backlog",
  [ACCOUNT_STATUSES.CALLBACK]: "Nurture",
  [ACCOUNT_STATUSES.MEETING_SCHEDULED]: "Meeting Scheduled",
  [ACCOUNT_STATUSES.FEEDBACK_RECEIVED]: "Feedback",
} as const;

export const COLUMN_POTENTIAL_EARNINGS_TEXT =
  "This is your potential earnings amount.";
export const COLUMN_CONFIRMED_PAYOUTS_TEXT = "Your confirmed payouts.";

// TODO
// Add
// - Meeting Canceled
// - Meeting Qualified
// - Meeting Unqualified
// - Meeting Rescheduled

export const ACTION_TITLES_MAP = {
  [ACCOUNT_STATUSES.BACKLOG]: "Last touched",
  [ACCOUNT_STATUSES.CALLBACK]: "Callback time",
  [ACCOUNT_STATUSES.MEETING_SCHEDULED]: "Meeting time",
  [ACCOUNT_STATUSES.FEEDBACK_RECEIVED]: "Feedback time",
  [ACCOUNT_STATUSES.NO_SHOW]: "No show",
} as const;

export const CALLBACK_MISSED_ACTION_TITLE = "Callback missed";
export const CALLBACK_DONE_ACTION_TITLE = "Callback done";
export const PENDING_FEEDBACK_ACTION_TITLE = "Requested time";

export const TARGET_STATUS_TO_MODAL_ID_MAP = {
  [ACCOUNT_STATUSES.CALLBACK]: ACCOUNT_EDIT_CALLBACK_MODAL_ID,
  [ACCOUNT_STATUSES.MEETING_SCHEDULED]: ACCOUNT_BOOK_MEETING_MODAL_ID,
};

export const ACCOUNT_VIEWS_MAP = {
  KANBAN: "kanban",
  LIST: "list",
} as const;

export const IS_LISTS_MODE_ACTIVE = true;

export const VIEW_OPTIONS = {
  [ACCOUNT_VIEWS_MAP.KANBAN]: "Kanban",
  [ACCOUNT_VIEWS_MAP.LIST]: IS_LISTS_MODE_ACTIVE ? "Lists" : "List",
} as const;

export const SORT_BY_VALUES_MAP = {
  DEFAULT: "default",
  ALPHABETICAL_ASC: "alphabetical_asc",
  ALPHABETICAL_DESC: "alphabetical_desc",
} as const;

export const SORT_BY_LABELS_MAP = {
  [SORT_BY_VALUES_MAP.DEFAULT]: "Default",
  [SORT_BY_VALUES_MAP.ALPHABETICAL_ASC]: "Alphabetical (A-Z)",
  [SORT_BY_VALUES_MAP.ALPHABETICAL_DESC]: "Alphabetical (Z-A)",
} as const;

export const PIPELINE_LIST_TYPES = {
  DEFAULT: "default",
  CUSTOM: "custom",
  SYSTEM_LIST: "system_list",
  NURTURE_LIST: "nurture_list",
  COLD_LIST: "cold_list",
  DIALER: "dialer",
} as const;

export const PIPELINE_SYSTEM_LISTS_STRING_MAP = {
  [PIPELINE_SYSTEM_LISTS.BOOKED_MEETINGS]: "Booked Meetings",
  [PIPELINE_SYSTEM_LISTS.CALLBACKS]: "Callbacks",
  [PIPELINE_SYSTEM_LISTS.EMAIL_ACTIONS]: "Email Actions",
  [PIPELINE_SYSTEM_LISTS.FEEDBACK_RECEIVED]: "Feedback Received",
  [PIPELINE_SYSTEM_LISTS.MISSED_CALLS]: "Missed Calls",
};

export const LIST_TABLE_COLUMNS = {
  NAME: "contact_name",
  TITLE: "contact_title",
  NUMBER_OF_DIALS: "number_of_dials",
  CAMPAIGN: "campaign_name",
  ACCOUNT: "account_name",
  STATE: "account_state",
  STATUS: "status",
  LAST_CALLED: "last_called",
  NEXT_TOUCH_TIME: "callback_time",
  DIALS: "number_of_calls",
  LAST_DIALED: "last_called",
  LAST_CONVERSATION: "last_conversation",
  TIMEZONE: "timezone",
  ACCOUNT_CITY: "account_city",
  HEADCOUNT: "headcount",
  REVENUE: "revenue",
  NUMBER_OF_CALLS: "number_of_calls",
  NUMBER_OF_CONVERSATIONS: "number_of_conversations",
  CALLBACK_TIME: "callback_time",
  MEETING_TIME: "meeting_time",
};
