import { AxiosInstance, AxiosResponse } from "axios";

import { CampaignV3I } from "@/interfaces/campaign";
import { UserOnboardingI } from "@/interfaces/user";

export interface GetOnboardingCampaignRecommendationsResponseI {
  campaigns: Array<CampaignV3I>;
}

export interface UserOnboardingApiI {
  getOnboardingCampaignRecommendations: () => Promise<
    AxiosResponse<GetOnboardingCampaignRecommendationsResponseI>
  >;
  updateOnboardingStatus: (params?: UserOnboardingI) => Promise<AxiosResponse>;
}

export const UserOnboardingAPIEndpoints = (
  API: AxiosInstance
): UserOnboardingApiI => ({
  getOnboardingCampaignRecommendations: () =>
    API.get("/v1/campaign_recommendations"),

  updateOnboardingStatus: (params = {}) =>
    API.patch(`/v1/onboarding`, {
      patch_user_onboarding_status: params,
    }),
});
