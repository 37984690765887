import { ACCOUNT_ACTIVITY_TYPES } from "shared/lib/constants/account";
import {
  HistoryCardAENoteV2,
  HistoryCardArchiveV2,
  HistoryCardBacklogV2,
  HistoryCardBookedMeetingV2,
  HistoryCardCallbackBookedV2,
  HistoryCardCallDispositionV2,
  HistoryCardCallerNoteV2,
  HistoryCardCallV2,
  HistoryCardCanceledMeetingV2,
  HistoryCardContactAddedV2,
  HistoryCardCustomerDisqualifyLeadV2,
  HistoryCardDNCV2,
  HistoryCardEmailReceivedV2,
  HistoryCardEmailRequestRejectedV2,
  HistoryCardEmailRequestV2,
  HistoryCardEmailSentV2,
  HistoryCardMarkTentativeInterestDoneV2,
  HistoryCardMarkTodoCompletedV2,
  HistoryCardMissedCallV2,
  HistoryCardSkipLeadV2,
  HistoryCardUnarchiveV2,
  HistoryCardUnDNCV2,
  HistoryCardUserDisqualifyLeadV2,
  HistoryCardContactDeletedV2,
} from "../cards-v2";

export const ACCOUNT_HISTORY_CARDS_MAP = {
  [ACCOUNT_ACTIVITY_TYPES.BOOKED_MEETING]: HistoryCardBookedMeetingV2,
  [ACCOUNT_ACTIVITY_TYPES.AE_NOTE]: HistoryCardAENoteV2,
  [ACCOUNT_ACTIVITY_TYPES.USER_NOTE]: HistoryCardCallerNoteV2,
  [ACCOUNT_ACTIVITY_TYPES.CALLBACK_BOOKED]: HistoryCardCallbackBookedV2,
  [ACCOUNT_ACTIVITY_TYPES.CALL_DISPOSITION]: HistoryCardCallDispositionV2,
  [ACCOUNT_ACTIVITY_TYPES.CONTACT_ADDED]: HistoryCardContactAddedV2,
  [ACCOUNT_ACTIVITY_TYPES.ACCOUNT_DNC]: HistoryCardDNCV2,
  [ACCOUNT_ACTIVITY_TYPES.ACCOUNT_UN_DNC]: HistoryCardUnDNCV2,
  [ACCOUNT_ACTIVITY_TYPES.CALLED_BACK]: HistoryCardMissedCallV2,
  [ACCOUNT_ACTIVITY_TYPES.CALL]: HistoryCardCallV2,
  [ACCOUNT_ACTIVITY_TYPES.SET_BACKLOG]: HistoryCardBacklogV2,
  [ACCOUNT_ACTIVITY_TYPES.CANCEL_MEETING]: HistoryCardCanceledMeetingV2,
  [ACCOUNT_ACTIVITY_TYPES.MARK_TENTATIVE_INTEREST_DONE]:
    HistoryCardMarkTentativeInterestDoneV2,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_REQUEST]: HistoryCardEmailRequestV2,
  [ACCOUNT_ACTIVITY_TYPES.MARK_DONE]: HistoryCardMarkTodoCompletedV2,
  [ACCOUNT_ACTIVITY_TYPES.ARCHIVE]: HistoryCardArchiveV2,
  [ACCOUNT_ACTIVITY_TYPES.UNARCHIVE]: HistoryCardUnarchiveV2,
  [ACCOUNT_ACTIVITY_TYPES.DISQUALIFY_LEAD]: HistoryCardUserDisqualifyLeadV2,
  [ACCOUNT_ACTIVITY_TYPES.SYSTEM_DQ]: HistoryCardCustomerDisqualifyLeadV2,
  [ACCOUNT_ACTIVITY_TYPES.SKIP_LEAD]: HistoryCardSkipLeadV2,
  [ACCOUNT_ACTIVITY_TYPES.TODO_FOLLOWUP_EMAIL]: () => null,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_SENT]: HistoryCardEmailSentV2,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_RECEIVED]: HistoryCardEmailReceivedV2,
  [ACCOUNT_ACTIVITY_TYPES.EMAIL_REQUEST_REJECTED]:
    HistoryCardEmailRequestRejectedV2,
  [ACCOUNT_ACTIVITY_TYPES.HIDE_CONTACT]: HistoryCardContactDeletedV2,
} as const;
