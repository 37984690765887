import { useCallback, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import _uniqBy from "lodash/uniqBy";

import { useApi } from "shared/lib/hooks/use-api";

type ResponseI<T> = {
  // TODO extend this type to include other generic keys
  [key in "items" | "activity_logs"]?: T[];
} & {
  next_token?: string;
};

interface UseInfinitePaginationParamsI<T, APII> {
  isInitiallyLoading?: boolean;
  apiFactory: () => APII;
  errorMessage?: string;
  fetchMore: (nextToken?: string) => Promise<AxiosResponse<ResponseI<T>>>;
  collectionKeyInResponse?: string;
}

export interface UseInfinitePaginationReturnI<T> {
  isReachedEnd: boolean;
  data: T[];
  isLoading: boolean;
  reloadData: () => void;
  loadMore: () => void;
}

const RELOAD_DATA_REQUEST_DELAY_MS = 200;

/**
 * @param collectionKeyInResponse - Every new response will have different key name
 *                                  for it's primary data.
 */
const useInfinitePagination = <T, APII>({
  isInitiallyLoading = false,
  apiFactory,
  fetchMore,
  errorMessage,
  collectionKeyInResponse,
}: UseInfinitePaginationParamsI<T, APII>): UseInfinitePaginationReturnI<T> => {
  const [data, setData] = useState<T[]>([]);
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const nextTokenRef = useRef<string | undefined>();

  const fetcher = useCallback(() => fetchMore(nextTokenRef.current), []);

  const [{ isLoading }, loadData] = useApi<ResponseI<T>, APII>({
    apiFactory,
    fetcher,
    shouldCallAutomatically: false,
    shouldResetDataOnRequest: false,
    isInitiallyLoading,
    onSuccess: ({ next_token, ...rest }) => {
      /**
       * NOTE
       * Initially it was only items
       * but to make it reusable we need an ability
       * to use arbitrary propery from the response
       * based on the requirements
       */

      // @ts-ignore
      const items = rest[collectionKeyInResponse] as T[];

      if (!next_token || items?.length === 0) {
        setIsReachedEnd(true);
        nextTokenRef.current = undefined;
      } else {
        setData((currentData) =>
          _uniqBy([...currentData, ...(items || [])], "id")
        );
        nextTokenRef.current = next_token;
      }
    },
    onError: () => {
      setIsReachedEnd(true);
      setData([]);
      nextTokenRef.current = undefined;
    },
    errorBuilder: () =>
      errorMessage || "Failed to load data, please try again later.",
  });

  const reloadData = () => {
    setData([]);
    setIsReachedEnd(false);
    nextTokenRef.current = undefined;

    setTimeout(() => {
      void loadData();
    }, RELOAD_DATA_REQUEST_DELAY_MS);
  };

  return {
    isLoading,
    isReachedEnd,
    data,
    reloadData,
    loadMore: loadData,
  };
};

export default useInfinitePagination;
