import { useState } from "react";
import clsx from "clsx";

import { PhoneIcon } from "shared/ui/icons";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import Calling from "@/helpers/calling";
import { AddToListsButton } from "./add-to-list-button";

export const LeadsCarouselActionsSection = ({
  onAddToListSuccess = () => {},
}: {
  onAddToListSuccess?: () => void;
}) => {
  const context = useCallingContext();
  const inCallContext = useInCallContext();
  const { contact } = inCallContext;

  const [isCallLoading, setIsCallLoading] = useState(false);

  const onCallHandler = async () => {
    setIsCallLoading(true);

    await Calling.startCall({
      context,
      inCallContext,
      contactId: contact?.id,
    });

    setIsCallLoading(false);
  };

  return (
    <div className="grid grid-cols-2 gap-x-4">
      <AddToListsButton className="mt-4" onSuccess={onAddToListSuccess} />

      <div>
        <button
          className={clsx("btn-ae-default mt-4 w-full", {
            "disabled: loading": isCallLoading,
          })}
          onClick={onCallHandler}
        >
          <PhoneIcon className="mr-3 h-4" /> Call
        </button>
      </div>
    </div>
  );
};
