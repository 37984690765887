import { FC, ReactNode, useRef, useState } from "react";

import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { clsxMerge } from "shared/lib/helpers/styles";

interface ControlledDropdownPropsI {
  children: (isDropdownVisible?: boolean) => ReactNode;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnContentClick?: boolean;
}

const ControlledDropdown: FC<ControlledDropdownPropsI> = ({
  children,
  onOpen,
  onClose,
  className,
  closeOnContentClick = false,
}) => {
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffectOnce(() => {
    const wrapper = dropdownRef.current as HTMLDivElement;
    const label = dropdownRef?.current?.childNodes?.[0] as ChildNode;
    const content = dropdownRef?.current?.childNodes?.[1] as ChildNode;

    const blur = () => {
      if (wrapper.matches(":focus-within")) {
        setTimeout(() => {
          (document.activeElement as any).blur();
          setIsVisible(false);

          if (onClose) {
            onClose();
          }
        }, 50);
      }
    };

    const open = () => {
      if (!wrapper.matches(":focus-within")) {
        setTimeout(() => {
          setIsVisible(true);
          if (onOpen) {
            onOpen();
          }
        }, 50);
      }
    };

    const mouseDownHandler = () => {
      blur();
      open();
    };

    const handleLabelBlur = (ev: any) => {
      if (!ev.relatedTarget) {
        setIsVisible(false);
        if (onClose) {
          onClose();
        }
      }
    };

    const handleContentBlur = () => {
      blur();
      setIsVisible(false);
      if (onClose) {
        onClose();
      }
    };

    label.addEventListener("mousedown", mouseDownHandler);
    // remove touch events because "mousedown" already handles touch devices. Having both events causes the events to get triggered twice.
    // label.addEventListener("touchend", mouseDownHandler);
    label.addEventListener("blur", handleLabelBlur);

    if (closeOnContentClick) {
      content.addEventListener("click", handleContentBlur);
    }

    return () => {
      label.removeEventListener("mousedown", mouseDownHandler);
      // label.removeEventListener("touchend", mouseDownHandler);
      label.removeEventListener("blur", handleLabelBlur);
      content.removeEventListener("blur", handleContentBlur);

      if (closeOnContentClick) {
        content.removeEventListener("click", handleContentBlur);
      }
    };
  });

  return (
    <div
      ref={dropdownRef}
      className={clsxMerge("dropdown", className, {
        "dropdown-top": !className?.includes("dropdown-"),
      })}
    >
      {children(isVisible)}
    </div>
  );
};

export default ControlledDropdown;
