export const TRAINING_STATUS = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
} as const;

export const CAMPAIGN_USER_STATUS = {
  NOT_STARTED: "not_started",
  TRAINING_IN_PROGRESS: "training_in_progress",
  TRAINING_SUBMITTED: "training_submitted",
  AUDITION_IN_PROGRESS: "audition_in_progress",
  AUDITION_IN_REVIEW: "audition_in_review",
  AUDITION_APPROVED: "audition_approved",
  AUDITION_REJECTED: "audition_rejected",
  AUDITION_RETRY: "audition_retry",
};
