import { useEffect, useState } from "react";

// This hook is for keeping track of which step you are on and
// safely incrementing/decrementing it to move prev/next in a multi-step flow

export const useStepsManager = ({
  totalStepCount = 1,
}: {
  totalStepCount?: number;
}) => {
  const [totalSteps, setTotalSteps] = useState<number>(1);
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    setTotalSteps(totalStepCount);
  }, [totalStepCount]);

  const incrementStep = () => {
    if (activeStep < totalSteps - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const decrementStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  return { activeStep, setActiveStep, incrementStep, decrementStep };
};

export default useStepsManager;
